import React, { Fragment } from 'react';
import Dropdown from 'react-dropdown';
import { ProgressDot } from '../../assets/icons';
import InfectionsSvg from '../../assets/icons/infectionsSvg';
import RadioButtons from '../shared/forms/radiobuttons';
import ProgressBar from '../shared/ProgressBar';
import SkinTrackerProcess from './SkinTrackerProcess';

const SYMPTOMS = [
  {
    value: 'dryness',
    label: 'dryness',
  },
  {
    value: 'redness',
    label: 'redness',
  },
  {
    value: 'swelling',
    label: 'swelling',
  },
  {
    value: 'oozing_scabs',
    label: 'oozing scabs',
  },
  {
    value: 'scratch_marks',
    label: 'scratch marks',
  },
  {
    value: 'thickening_skin',
    label: 'thickening skin',
  },
];

const SkinTrackerContentWrapper = props => {
  let { title, instructions, location } = contentFromPage(
    props.page,
    props.subPage,
  );
  return (
    <div className="tracker-body tracker-body__scroll">
      <div className="skin-progress">
        <ProgressBar
          className="skin-progress"
          totalSteps={4}
          currentStep={props.page}
          dot="number"
        />
      </div>
      <div className="skin-tracker__halves">
        <div className="skin-tracker__half-content">
          <SkinTrackerProcess
            title={title}
            instructions={instructions}
            location={location}
            page={props.page}>
            {props.page === 2 && props.selected ? (
              <ProgressBar
                className="skin-progress__mobile"
                totalSteps={6}
                currentStep={props.subPage}
              />
            ) : null}
            {childrenFromPage(
              props.page,
              props.subPage,
              props.data,
              props.checkBoxHandler,
              props.radioHandler,
              props.updateInput,
              props.skinPictureLocation,
              props.changeSkinPictureLocation,
            )}
          </SkinTrackerProcess>
        </div>

        <div
          className={
            props.data.areas_selected
              ? `skin-tracker__half-graphic`
              : `skin-tracker__half-graphic skin-areas__disabled`
          }>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SkinTrackerContentWrapper;

const contentFromPage = (page, subPage) => {
  let title, instructions, location;

  switch (page) {
    case 1:
      title = 'Affected Areas';
      instructions = 'Tap to select the areas with eczema';
      break;
    case 2:
      title = 'Intensity of Skin Symptoms';
      location =
        subPage === 1
          ? 'On your skin NOT affected by eczema'
          : 'On your skin affected by eczema';
      instructions = `0 = none, 1 = mild, 2 = moderate, 3 = extreme`;

      break;
    case 3:
      title = 'Additional Details';
      location = 'Do you have any infection(s)?';
      break;
    case 4:
      title = 'Upload Images';
      instructions = `Select the region you'd like to upload for (maximum of 8)`;
      break;
    default:
      console.log('No actions');
  }
  return {
    title,
    instructions,
    location,
  };
};

const childrenFromPage = (
  page,
  subPage,
  data,
  checkBoxHandler,
  radioHandler,
  updateInput,
  skinPictureLocation,
  changeSkinPictureLocation,
) => {
  let component;
  switch (page) {
    case 1:
      component = (
        <div className="skin-tracker-checkbox__affected">
          <input
            className="form-input form-input-checkbox"
            type="checkbox"
            checked={!data.areas_selected}
            onChange={checkBoxHandler}
          />{' '}
          <span className="paragraph">I have no affected areas</span>
        </div>
      );
      break;
    case 2:
      let listItems = SYMPTOMS.map((symptom, index) => {
        let styleName;
        if (index === subPage - 1) {
          styleName =
            'paragraph paragraph__skin paragraph__semibold process__current';
        } else if (index < subPage - 1) {
          styleName = 'paragraph process__complete';
        } else {
          styleName = 'paragraph paragraph__disabled process__pending';
        }
        return (
          <li key={index} className={styleName}>
            <ProgressDot />
            <span className="process__symptom paragraph paragraph__capitalized">
              {symptom.label}
            </span>
          </li>
        );
      });
      component = (
        <ul className="skin-tracker__list">
          {subPage === 1 ? listItems[0] : listItems.splice(1)}
        </ul>
      );
      break;
    case 3:
      component = (
        <Fragment>
          <InfectionsSvg />
          <h3 className="headers headers__skin infections-title">Infections</h3>
          <div className="form skin-tracker-form">
            <div className="skin-tracker__yes-no form-item">
              <div className="form-item__row">
                <RadioButtons
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  id="infections"
                  selected={
                    typeof data.infections === 'boolean'
                      ? data.infections
                        ? 'yes'
                        : 'no'
                      : undefined
                  }
                  changeHandler={radioHandler}
                />
              </div>
              {data.infections ? (
                <div className="form-item">
                  <h4 className="subtext subtext__uppercase">
                    Infection Notes
                  </h4>
                  <input
                    className="form-input-field"
                    value={data.infections_notes}
                    placeholder="Describe your symptoms"
                    onChange={e => {
                      let value = e.target.value;
                      updateInput({
                        variables: {
                          name: 'infections_notes',
                          value: value,
                        },
                      });
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Fragment>
      );
      break;
    case 4:
      component = (
        <Fragment>
          <div className="form skin-tracker-form">
            <div className="skin-tracker__yes-no form-item">
              <div className="form-item__row">
                <Dropdown
                  className={'skin-data-dropdown'}
                  options={[
                    { label: 'All', value: 'all' },
                    ...data.areas_affected.map(area_affected => ({
                      label: area_affected
                        .split('_')
                        .map(s => s[0].toUpperCase() + s.substr(1))
                        .join(' '),
                      value: area_affected,
                    })),
                  ]}
                  onChange={c => changeSkinPictureLocation(c.value)}
                  value={skinPictureLocation}
                  placeholder="Select an option"
                />
              </div>
            </div>
          </div>
        </Fragment>
      );
      break;
    default:
      console.log('No actions');
  }

  return component;
};
