import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { withRouter } from 'react-router-dom';

function PrivateRoute({ render, me, history, ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        me.id ? (
          !me.profileFlag ? (
            <Redirect
              to={{
                pathname: '/signup/profile',
              }}
            />
          ) : (
            render()
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
  me: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(PrivateRoute);
