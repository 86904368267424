import { flowRight as compose } from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Eye } from '../../assets/icons/';
import analytics from '../../libs/analytics';
import { signUpUser } from '../../libs/graphql/mutations/user';
import passwordValidation from '../../libs/passwordValidation';
import MixpanelService, { MixpanelEvents, MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT } from '../../libs/mixpanel';
import moment from 'moment-timezone';
import Hero from '../../assets/images/home/ew-set.png';

const passwordRulesMessages = {
  length: '8 characters',
  upper: '1 uppercase letter',
  lower: '1 lowercase letter',
  special: '1 special character',
  num: '1 number',
};

const maxInputLength = '50';
const defaultUserRole = 'User';

const withSignUpUser = graphql(signUpUser, {
  name: 'signUpUser',
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //keeping password check state in local state instead of GraphQL.
      rulesTracker: { match: true },
      showPassword: false,
      showPasswordConfirm: false,
      emailValidationFailure: false,
      signUpFailed: false,
      errorMessage: 'Registration Failed',
    };
    this.focusElement = React.createRef();
  }

  componentDidMount() {
    analytics().logEvent('open_signup', {});
    analytics().setCurrentScreen('open_signup_view');
    this.focusElement.current?.focus();
  }

  render() {
    let { loading, userName, signUpUser, signUpForm } = this.props;
    if (loading) return <div>loading..........</div>;
    return (
      <div className="content-wrapper">
        <div className="login">
          <div className="login-title">
            <span
              className="login-title-text title__black100"
              tabindex="0"
              ref={this.focusElement}>
              Sign Up
            </span>
          </div>
          {this.state.signUpFailed ? (
            <div className="alert alert-negative" role="alert">
              <h3 className="headers headers__semibold">Sign Up Failed</h3>
              <p className="paragraph">
                {this.state.errorMessage}
                {this.state.errorMessage ===
                  'An account with the given email already exists.' && (
                  <span>
                    {' '}
                    <NavLink
                      className={'link link__underline'}
                      to={'/forgot-password'}>
                      Forgot password?
                    </NavLink>
                  </span>
                )}
              </p>
            </div>
          ) : null}
          <form className="login-form form">
            <div className="form-item">
              <label className="form-input-label-login text__left">
                Email<span className="red-asterisk">*</span>
              </label>
              <input
                autoFocus
                className={`form-input-field ${
                  this.state.emailValidationFailure
                    ? 'form-input-field__error'
                    : ''
                }`}
                name="email"
                type={'email'}
                onChange={this._updateInput.bind(this, 'email')}
                placeholder="Enter your email"
                value={this.state.email}
                maxLength={maxInputLength}
              />
            </div>
            <div className="form-item">
              <label className="form-input-label-login text__left">
                Username<span className="red-asterisk">*</span>
              </label>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'userName')}
                placeholder="Enter username"
                value={this.state.userName}
                maxLength={maxInputLength}
              />
            </div>
            <div className="form-item">
              <div className="label-wrapper__password">
                <label className="form-input-label-login">
                  Set Password<span className="red-asterisk">*</span>
                </label>
                <button
                  className={`button password__visible ${
                    this.state.showPassword
                      ? 'password__visible__show'
                      : 'password__visible__hide'
                  }`}
                  onClick={this._togglePassword.bind(this, 'password')}>
                  <Eye />
                </button>
              </div>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'password')}
                placeholder="Type password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                maxLength={maxInputLength}
              />
              <p className="form-pass-validation">
                Password must have at least: {this._rulesCheck()}
              </p>
            </div>
            <div className="form-item">
              <div className="label-wrapper__password">
                <label className="form-input-label-login">
                  Verify Password<span className="red-asterisk">*</span>
                </label>
                <button
                  className={`button password__visible ${
                    this.state.showPasswordConfirm
                      ? 'password__visible__show'
                      : 'password__visible__hide'
                  }`}
                  onClick={this._togglePassword.bind(this, 'password_confirm')}>
                  <Eye />
                </button>
              </div>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'password_confirm')}
                placeholder="Retype password"
                type={this.state.showPasswordConfirm ? 'text' : 'password'}
                value={this.state.password_confirm}
                maxLength={maxInputLength}
              />
              <p className="form-pass-validation">
                {this.state.rulesTracker.match ? '' : "Passwords don't match."}
              </p>
            </div>
            <br />
            <div className="form-item form-checkbox-wrapper">
              <input
                className="form-input form-input-checkbox"
                type="checkbox"
                checked={this.state.policyCheck}
                onChange={this._updateCheckInput.bind(this, 'policyCheck')}
              />
              <span className="login-link login-link-info-text text__left">
                I have read and agree to the{' '}
                <Link
                  className="link link__secondary-100"
                  to="/privacy-policy"
                  target={'_blank'}>
                  Privacy policy
                </Link>
                .
              </span>
            </div>
            <div className="form-item form-checkbox-wrapper">
              <input
                className="form-input form-input-checkbox"
                type="checkbox"
                checked={this.state.emailConsent}
                onChange={this._updateCheckInput.bind(this, 'emailConsent')}
              />
              <span className="login-link login-link-info-text text__left">
                I want to receive emails regarding the latest eczema news and
                research.
              </span>
            </div>
            <br/>
            <div className="form-item">
              <button
                className="button button__secondary100 button-rectangle-large"
                disabled={
                  this._checkButtonState() ||
                  !signUpForm.userName ||
                  !signUpForm.policyCheck
                }
                onClick={this.signUpUser.bind(this)}>
                Sign up
              </button>
            </div>
            <br/>
            <span className="login-link">
              Already have account? &nbsp;
              <Link className="link link__secondary-100" to="/login">
                Login
              </Link>
            </span>
          </form>
        </div>
        <div className="image-section">
          <img src={Hero} alt="" />
        </div>
      </div>
    );
  }

  signUpUser(e) {
    e.preventDefault();
    if (this._validateEmail(this.props.signUpForm.email)) {
      return this.props
        .signUpUser({
          variables: {
            email: this.props.signUpForm.email,
            password: this.props.signUpForm.password,
            name: this.props.signUpForm.userName,
            email_consent: this.props.signUpForm.emailConsent,
          },
        })
        .then(({ data: { signUpUser: { id } } }) => {
          const signupDate = moment.utc().format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`);
          MixpanelService.identify(id);
          MixpanelService.track(MixpanelEvents.AccountCreated, {
            'EmailOpt-In': this.props.signUpForm.emailConsent,
            ProfileCreated: false,
            SignupDate: signupDate,
          });

            MixpanelService.registerSuperProps({ UserId: id });
            MixpanelService.setProfileProperty('UserId', id);
            MixpanelService.setProfileProperty(
              'EmailOpt-In',
              this.props.signUpForm.emailConsent,
            );
            MixpanelService.setProfileProperty('SignupDate', signupDate);
            MixpanelService.setProfileProperty('UserRole', defaultUserRole);

            analytics().logEvent('complete_signup', {});
            this.props.history.push({
              pathname: '/signup/confirmation',
              state: { email: this.props.signUpForm.email },
            });
          },
        )
        .catch(err => {
          // Need something better than an alert
          this.setState({
            signUpFailed: true,
            errorMessage: String(err).replace('Error: GraphQL error: ', ''),
          });
        });
    } else {
      this.setState({
        emailValidationFailure: true,
      });
    }
  }

  _updateInput(name, e) {
    //client side mutation to update form inputs for signup form
    let { updateInputSignUp } = this.props;
    e.preventDefault();

    this.setState({ signUpFailed: false });

    updateInputSignUp({
      variables: {
        name,
        value: e.target.value,
      },
    }).then(() => {
      if (name === 'password' || name === 'password_confirm') {
        this._validatePassword();
      }
      if (name === 'email' && this.state.emailValidationFailure) {
        this.setState({
          signUpFailed: false,
          emailValidationFailure: false, //reset validation failure when user starts editing input field
        });
      }
    });
  }

  _updateCheckInput(name, e) {
    //client side mutation to update form inputs for signup form
    let { updateInputSignUp } = this.props;
    updateInputSignUp({
      variables: {
        name: name,
        value: e.target.checked,
      },
    });
  }

  _checkButtonState() {
    let { rulesTracker } = this.state;
    return !(
      rulesTracker.match &&
      rulesTracker.length &&
      rulesTracker.num &&
      rulesTracker.special &&
      rulesTracker.upper &&
      rulesTracker.lower
    );
  }

  _togglePassword(field, e) {
    e.preventDefault();
    let currentState;
    if (field === 'password') {
      currentState = this.state.showPassword;
      this.setState({
        showPassword: !currentState,
      });
    } else if (field === 'password_confirm') {
      currentState = this.state.showPasswordConfirm;
      this.setState({
        showPasswordConfirm: !currentState,
      });
    }
  }

  _validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  _validatePassword() {
    let { password, password_confirm } = this.props.signUpForm;
    let rulesTestResults = passwordValidation(password, password_confirm);
    this.setState({
      rulesTracker: rulesTestResults,
    });

    return (
      rulesTestResults.match &&
      rulesTestResults.length &&
      rulesTestResults.num &&
      rulesTestResults.special &&
      rulesTestResults.upper &&
      rulesTestResults.lower
    );
  }

  _rulesCheck() {
    const rules = Object.keys(passwordRulesMessages).map(rule => {
      const isSuccess = this.state.rulesTracker[rule];
      const className = isSuccess
        ? 'form-pass-validation__passing'
        : 'form-pass-validation-item';
      return (
        <span className={className}>
          {isSuccess ? '✓ ' : '• '}
          {passwordRulesMessages[rule]}
        </span>
      );
    });

    return rules.reduce((acc, rule, index) => {
      acc.push(rule);
      if (index !== rules.length - 1) {
        acc.push(', ');
      }
      return acc;
    }, []);
  }
}

export default compose(withRouter, withSignUpUser)(SignUp);
