import React from 'react';
import DragScale from '../shared/DragScale';

const SkinTrackerScaleTracker = props => {
  return (
    <div className="skin-tracker-scale-wrapper">
      <h4 className="headers headers__neutral-black headers__capitalized">
        {props.symptom.label}
      </h4>
      <DragScale
        onIncrease={incrementFunction.bind(
          null,
          props.symptom.value,
          props.store[props.symptom.value],
          props.updateSkinScaleValue,
        )}
        onDecrease={decrementFunction.bind(
          null,
          props.symptom.value,
          props.store[props.symptom.value],
          props.updateSkinScaleValue,
        )}
        onSetDirectly={setDirectly.bind(
          null,
          props.symptom.value,
          props.updateSkinScaleValue,
        )}
        editingMode={true}
        level={props.level}
        scaleWidth={2.5}
        maxValue={3}
        tabDistance={2.2}
      />
    </div>
  );
};

export default SkinTrackerScaleTracker;

const setDirectly = (symptom, storeHandler, currentValue) => {
  let value = currentValue + 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: value,
    },
  }).catch(e => {
    console.log(e);
  });
};

const incrementFunction = (symptom, currentValue, storeHandler) => {
  let incrementedValue = currentValue === 3 ? 3 : currentValue + 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: incrementedValue,
    },
  }).catch(e => {
    console.log(e);
  });
};

const decrementFunction = (symptom, currentValue, storeHandler) => {
  let incrementedValue = currentValue === 0 ? 0 : currentValue - 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: incrementedValue,
    },
  }).catch(e => {
    console.log(e);
  });
};
