import React from 'react';

export default function SkinOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64719C15.8834 9.62353 15.8428 9.62082 15.8111 9.63975L11.2265 12.3624L11.6436 5.69406C11.6456 5.65011 11.6152 5.61022 11.5719 5.60211C11.5273 5.59399 11.4854 5.61901 11.4712 5.66093L7.86086 16.492C7.84936 16.5265 7.86086 16.565 7.88925 16.5887C7.91765 16.6117 7.95754 16.6144 7.98999 16.5954L12.1365 14.1338L11.6849 21.9609C11.6822 22.0049 11.7133 22.0441 11.7565 22.0529C11.7619 22.0542 11.7673 22.0549 11.7727 22.0549C11.8106 22.0549 11.8444 22.0306 11.8566 21.9941L15.9402 9.74387C15.9524 9.70871 15.9402 9.67018 15.9118 9.64719Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2012 12.3995C20.1641 12.3675 20.1084 12.3683 20.0721 12.4009L15.1435 16.9405C15.1146 16.9672 15.1042 17.0073 15.1176 17.0444C15.1302 17.0816 15.1643 17.106 15.2037 17.109L17.322 17.2359L14.7427 20.9516C14.7145 20.9924 14.7219 21.0474 14.7591 21.08C14.7769 21.0964 14.7999 21.1038 14.8229 21.1038C14.8459 21.1038 14.8696 21.0949 14.8882 21.0778L19.816 16.539C19.8449 16.513 19.8553 16.4722 19.842 16.4351C19.8293 16.3987 19.7952 16.3734 19.7566 16.3712L17.6382 16.2443L20.2168 12.5279C20.2457 12.4878 20.2383 12.4321 20.2012 12.3995Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6197 10.5045C20.2514 10.3893 19.8493 10.56 19.7093 10.8918L17.9714 14.9987C17.7445 14.7846 17.4624 14.5888 17.1286 14.4106L18.4576 8.90635C18.541 8.56064 18.3093 8.2162 17.9327 8.12867C17.5576 8.04114 17.1727 8.24201 17.0651 8.58205L15.4106 13.7878C15.2368 13.7456 15.063 13.7034 14.8726 13.6663C14.7189 13.6367 14.5768 13.6172 14.4313 13.5945L15.4016 7.84278C15.4603 7.4933 15.2044 7.16333 14.823 7.0991C14.4416 7.03424 14.0733 7.25716 13.9906 7.60349L12.5774 13.5007C12.2574 13.5278 11.9657 13.58 11.7015 13.6562L11.9801 8.57891C11.9995 8.22438 11.7091 7.91961 11.3222 7.88749C10.9346 7.85601 10.5898 8.10978 10.5394 8.46115L9.52145 15.6851C9.51939 15.6908 9.51732 15.6971 9.51456 15.7027L6.29998 13.2507C5.99376 13.0177 5.54547 13.0303 5.25581 13.2803C4.96821 13.5284 4.92752 13.9339 5.16132 14.2255L9.85871 20.0855C10.5463 21.003 11.6284 21.6975 12.9436 21.9532C15.574 22.4633 18.1383 21.0225 18.6707 18.7341L21.0797 11.3181C21.1901 10.9781 20.9873 10.6191 20.6197 10.5045Z"
        fill="white"
      />
    </svg>
  );
}
