import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import {
  AngleLeftIcon,
  AngleRightIcon,
  DateIcon,
  DoubleAngleRightIcon,
  HelperIcon,
  Instructions,
  UserBlueIcon,
  UserIcon,
} from '../assets/icons';
import Tutorial from './shared/Tutorial';

//TODO: We will change this GIFs from assets folder when the url is available
// GIFs
import calendarGif from '../assets/GIFs/calendar.gif';
import calendarSecondGif from '../assets/GIFs/calendarTwo.gif';

const formatDate = date => moment(date).format('MMM. D, YYYY');
const isToday = date => moment().diff(date, 'days') < 1;
const isLastDayEditable = date => moment().diff(date, 'days') >= 7;

const DateBar = ({ date, changeTrackerDate }) => {
  const [activeTutorialId, setActiveTutorialId] = useState(null);

  const trackerDateUpdate = (direction, today) => {
    return changeTrackerDate({
      variables: {
        direction,
        today: !!today,
      },
    });
  };

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  const TrackingDateTutorial = {
    titles: [
      'Track past dates',
      'Track past dates ',
      'What’s the skin tracker?',
      'What’s the skin tracker?',
    ],
    texts: [
      `How was your eczema this week? Even if you forgot to track one day, you can still go back and add information. Tap the arrows or the calendar icon to select the day you would like to track.`,
      `Quickly choose the day you would like to track up to 7 days ago.`,
      `Select any date within the past seven days to add your symptom tracking information.`,
      `View your date-specific tracking information. Tap “Select” to edit your information or “View more days” to visit the Learn tab and see more about your tracking trends. `,
    ],
    subtitles: ['', '', `Select the date`, `View your information`],
    urlsGif: ['', '', calendarGif, calendarSecondGif],
  };

  return (
    <div className="date-bar">
      <div className="main-dashboard__sidebar">
        <div className="sidebar-title">
          <UserBlueIcon />
          <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
            TRACKING FOR
          </h1>
          <Tutorial
            titles={TrackingDateTutorial.titles}
            texts={TrackingDateTutorial.texts}
            subtitles={TrackingDateTutorial.subtitles}
            urlsGif={TrackingDateTutorial.urlsGif}
            onClickNext={activeTutorialUpdate.bind(this)}>
            <div className="tooltip-trigger">
              <span className="icon-instructions">
                <HelperIcon />
              </span>
            </div>
          </Tutorial>
        </div>
      </div>
      <div
        className={`sidebar-title sidebar-margins ${
          activeTutorialId === TrackingDateTutorial.titles[2]
            ? ' exception-element'
            : ''
        }`}>
        <div
          className={`button__wrapper ${
            activeTutorialId === TrackingDateTutorial.titles[1]
              ? ' exception-element'
              : ''
          }`}>
          <button
            title="Go back one day"
            className="transparent-button"
            onClick={trackerDateUpdate.bind(null, 'back', false)}
            disabled={isLastDayEditable(date)}>
            <AngleLeftIcon />
          </button>
        </div>
        <div className="container-date-info">
          {isToday(date) ? (
            <>
              <DateIcon />
              <span className="headers headers__cobalt headers__bold headers__capitalized">
                Today{' '}
              </span>
            </>
          ) : (
            <DateIcon />
          )}
          <time className="title__cobalt title__bold" dateTime={date}>
            {formatDate(date)}
          </time>
        </div>
        <div className="buttons__forward">
          {isToday(date) ? null : (
            <Fragment>
              <button
                title="Go forward one day"
                className="transparent-button button-size"
                onClick={trackerDateUpdate.bind(null, 'forward', false)}
                disabled={isToday(date)}>
                <AngleRightIcon />
              </button>
              <button
                title="Go to today"
                className="transparent-button button-size"
                onClick={trackerDateUpdate.bind(null, 'forward', true)}
                disabled={isToday(date)}>
                <DoubleAngleRightIcon />
              </button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

DateBar.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateBar;
