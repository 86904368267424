import React from 'react';

export default function PainOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64719C15.8834 9.62353 15.8428 9.62082 15.8111 9.63975L11.2265 12.3624L11.6436 5.69406C11.6456 5.65011 11.6152 5.61022 11.5719 5.60211C11.5273 5.59399 11.4854 5.61901 11.4712 5.66093L7.86086 16.492C7.84936 16.5265 7.86086 16.565 7.88925 16.5887C7.91765 16.6117 7.95754 16.6144 7.98999 16.5954L12.1365 14.1338L11.6849 21.9609C11.6822 22.0049 11.7133 22.0441 11.7565 22.0529C11.7619 22.0542 11.7673 22.0549 11.7727 22.0549C11.8106 22.0549 11.8444 22.0306 11.8566 21.9941L15.9402 9.74387C15.9524 9.70871 15.9402 9.67018 15.9118 9.64719Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3995C20.1646 12.3675 20.1089 12.3683 20.0725 12.4009L15.144 16.9405C15.1151 16.9672 15.1047 17.0073 15.118 17.0444C15.1307 17.0816 15.1648 17.106 15.2041 17.109L17.3225 17.2359L14.7432 20.9516C14.715 20.9924 14.7224 21.0474 14.7595 21.08C14.7774 21.0964 14.8004 21.1038 14.8234 21.1038C14.8464 21.1038 14.8701 21.0949 14.8887 21.0778L19.8165 16.539C19.8454 16.513 19.8558 16.4722 19.8424 16.4351C19.8298 16.3987 19.7957 16.3734 19.7571 16.3712L17.6387 16.2443L20.2173 12.5279C20.2462 12.4878 20.2388 12.4321 20.2017 12.3995Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <rect
        width="18.6667"
        height="18.6667"
        transform="translate(4.6665 4.66699)"
        fill="#FF9672"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64719C15.8834 9.62353 15.8428 9.62082 15.8111 9.63975L11.2265 12.3624L11.6436 5.69406C11.6456 5.65011 11.6152 5.61022 11.5719 5.60211C11.5273 5.59399 11.4854 5.61901 11.4712 5.66093L7.86086 16.492C7.84936 16.5265 7.86086 16.565 7.88925 16.5887C7.91765 16.6117 7.95754 16.6144 7.98999 16.5954L12.1365 14.1338L11.6849 21.9609C11.6822 22.0049 11.7133 22.0441 11.7565 22.0529C11.7619 22.0542 11.7673 22.0549 11.7727 22.0549C11.8106 22.0549 11.8444 22.0306 11.8566 21.9941L15.9402 9.74387C15.9524 9.70871 15.9402 9.67018 15.9118 9.64719Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3995C20.1646 12.3675 20.1089 12.3683 20.0725 12.4009L15.144 16.9405C15.1151 16.9672 15.1047 17.0073 15.118 17.0444C15.1307 17.0816 15.1648 17.106 15.2041 17.109L17.3225 17.2359L14.7432 20.9516C14.715 20.9924 14.7224 21.0474 14.7595 21.08C14.7774 21.0964 14.8004 21.1038 14.8234 21.1038C14.8464 21.1038 14.8701 21.0949 14.8887 21.0778L19.8165 16.539C19.8454 16.513 19.8558 16.4722 19.8424 16.4351C19.8298 16.3987 19.7957 16.3734 19.7571 16.3712L17.6387 16.2443L20.2173 12.5279C20.2462 12.4878 20.2388 12.4321 20.2017 12.3995Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
