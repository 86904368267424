import React from 'react';
import { Link } from 'react-router-dom';

const TrackerInstructions = ({ instructions, tracker }) => {
  return (
    <div
      className={`tracker-instructions ${
        tracker === 'skin' ? 'skin-instructions' : ''
      }`}>
      <p>{instructions}</p>
      <p>
        For more info see <Link to="/about">About</Link>
      </p>
    </div>
  );
};

export default TrackerInstructions;
