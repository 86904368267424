import React from 'react';

export default function Instructions() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-instructions instructions-svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="rgba(42, 41, 44, 0.25)" />
      <path
        className="svg-instructions-q"
        d="M15.9714 9.89584C15.9714 9.09326 15.7065 8.31311 15.2179 7.67638C14.7294 7.03966 14.0443 6.58195 13.2691 6.37423C12.4938 6.16651 11.6717 6.22039 10.9302 6.52753C10.1887 6.83466 9.56932 7.37787 9.16802 8.07292C9.03005 8.31222 8.99276 8.59651 9.06434 8.86329C9.13593 9.13007 9.31053 9.35751 9.54975 9.4956C9.78898 9.63369 10.0732 9.67112 10.3401 9.59967C10.6069 9.52823 10.8344 9.35375 10.9726 9.11459C11.11 8.87728 11.3072 8.68021 11.5447 8.5431C11.7821 8.40599 12.0514 8.33366 12.3256 8.33334C12.74 8.33334 13.1374 8.49796 13.4304 8.79099C13.7234 9.08401 13.8881 9.48144 13.8881 9.89584C13.8881 10.3102 13.7234 10.7077 13.4304 11.0007C13.1374 11.2937 12.74 11.4583 12.3256 11.4583H12.323C12.2561 11.465 12.19 11.4786 12.1259 11.4987C12.0557 11.5059 11.9864 11.5201 11.919 11.541C11.8616 11.5722 11.8074 11.6087 11.757 11.6502C11.6972 11.6828 11.6406 11.721 11.5882 11.7645C11.5414 11.8204 11.5005 11.8811 11.4663 11.9455C11.4283 11.9925 11.3946 12.0427 11.3656 12.0957C11.343 12.1674 11.328 12.2414 11.321 12.3164C11.3028 12.3763 11.2904 12.4378 11.2839 12.5V13.5417L11.2863 13.5535L11.2875 14.0651C11.2882 14.3409 11.3982 14.6051 11.5935 14.7999C11.7888 14.9947 12.0533 15.1041 12.3291 15.1042H12.3317C12.6079 15.1035 12.8726 14.9931 13.0675 14.7973C13.2623 14.6014 13.3715 14.3362 13.3708 14.06L13.3692 13.371C14.1187 13.1482 14.7766 12.6899 15.2453 12.064C15.714 11.438 15.9686 10.6778 15.9714 9.89584ZM11.5937 16.9688C11.4474 17.1138 11.3474 17.299 11.3064 17.5008C11.2654 17.7027 11.2851 17.9122 11.3631 18.1028C11.4411 18.2935 11.5739 18.4567 11.7447 18.5719C11.9154 18.6871 12.1165 18.7491 12.3225 18.75C12.5988 18.7478 12.8636 18.6396 13.0626 18.4479C13.2559 18.2503 13.3642 17.9848 13.3642 17.7084C13.3642 17.4319 13.2559 17.1664 13.0626 16.9688C12.8632 16.783 12.6007 16.6797 12.3281 16.6796C12.0555 16.6796 11.7931 16.7829 11.5937 16.9688Z"
        fill="rgba(247, 247, 254, 1)"
      />
      <g
        className="svg-instructions-x"
        transform="translate(7.5,7.5) scale(.7)"
        strokeLinecap="round"
        strokeWidth="2.5">
        <path
          className="svg-action__x-left-right svg-action__delete-lines"
          d="M0,0 L15.8788891,15.8788891"
        />
        <path
          className="svg-action__x-right-left svg-action__delete-lines"
          d="M0,0 L15.8788891,15.8788891"
          transform="translate(7.939445, 7.939445) scale(-1, 1) translate(-7.939445, -7.939445)"
        />
      </g>
    </svg>
  );
}
