import React from 'react';

import config from '../../config';
import Complete from '../../assets/images/complete.png';
import { SkinIcon, ItchIcon, SleepIcon } from '../../assets/icons/trackers';
import {
  CompleteTrackerIcon,
  ItchOrangeIcon,
  PoscoradIndicator,
  SkinOrangeIcon,
  SleepOrangeIcon,
} from '../../assets/icons';

const SEVERITIES = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

const MAX_POSCORAD_VALUE = 103;

const PoScoradScale = props => {
  const { dateText, skinData, itchData, sleepData, poscoradData } = props;
  const poscoradPercentage =
    poscoradData != null ? (poscoradData.level * 103) / MAX_POSCORAD_VALUE : 0;

  return (
    <div className="poscorad-tracker-element">
      {poscoradData ? (
        <div className="poscorad-tracker-text poscorad-history">
          <div className="poscorad-item poscorad-item-scale">
            <h4 className="headers headers__cobalt-dark">PO-SCORAD scale</h4>
            <div className="poscorad-scales" style={{ marginTop: '18px' }}>
              <div
                className="poscorad-scales-indicator"
                style={{ left: poscoradPercentage + '%', bottom: '5px' }}>
                <PoscoradIndicator percentage={poscoradPercentage} />
              </div>
              <div className="poscorad-scales-item"></div>
              <div className="poscorad-scales-item Moderate"></div>
              <div className="poscorad-scales-item Severe"></div>
              <div className="poscorad-scales-item Severe"></div>
            </div>
            <div className="poscorad-labels">
              <label>0</label>
              <label className={`severity ${SEVERITIES.Mild}`}>
                {SEVERITIES.Mild}
              </label>
              <label className="number-center">25</label>
              <label className={`severity ${SEVERITIES.Moderate}`}>
                {SEVERITIES.Moderate}
              </label>
              <label className="number-center">50</label>
              <label className={`severity ${SEVERITIES.Severe}`}>
                {SEVERITIES.Severe}
              </label>
              <label className="number-right">103</label>
            </div>
          </div>
        </div>
      ) : (
        <div className="poscorad-tracker-text">
          <h4 className="headers headers__black100">
            Completed trackers {dateText}
          </h4>
          <div className="poscorad-trackers-icon">
            <div
              className={`tracker-icon
                          tracker-progress-${
                            skinData ? 'complete' : 'incomplete'
                          }  
                        ${config.trackerTheme.skin}`}>
              <span className="tracker-icon-wrapper">
                <span className="tracker-badge-complete badge-complete">
                  <CompleteTrackerIcon />
                </span>
                <span className="icon-heading-poscorad">
                  <SkinOrangeIcon />
                </span>
              </span>
            </div>
            <div
              className={`tracker-icon
                        tracker-progress-${itchData ? 'complete' : 'incomplete'}
                        ${config.trackerTheme.itch}`}>
              <span className="tracker-icon-wrapper">
                <span className="tracker-badge-complete badge-complete">
                  <CompleteTrackerIcon />
                </span>
                <span className="icon-heading-poscorad">
                  <ItchOrangeIcon />
                </span>
              </span>
            </div>
            <div
              className={`tracker-icon
                        tracker-progress-${
                          sleepData ? 'complete' : 'incomplete'
                        }
                        ${config.trackerTheme.sleep}`}>
              <span className="tracker-icon-wrapper">
                <span className="tracker-badge-complete badge-complete">
                  <CompleteTrackerIcon />
                </span>
                <span className="icon-heading-poscorad">
                  <SleepOrangeIcon />
                </span>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PoScoradScale;
