import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import Resizer from 'react-image-file-resizer';
import moment from 'moment-timezone';
import { useMutation } from 'react-apollo';

import { UPLOAD_IMAGES, assignImagesToTrackingData } from '../../libs/graphql/mutations/image';

import Modal from '../shared/Modal';
import Button from '../shared/button';
import { UilPlus, UilMultiply } from '@iconscout/react-unicons';
import { BodyAreas } from '../../utils/constants'

const UploadImage = ({ me, isModalOpen, onClose, onUpload }) => {
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedBodyPart, setSelectedBodyPart] = useState(null);
  const [newImages, setNewImages] = useState([]);

  const [uploadImg] = useMutation(UPLOAD_IMAGES);
  const [assignImages] = useMutation(assignImagesToTrackingData);

  useEffect(() => {
    setSelectedBodyPart(null);
  }, [selectedDate]);

  useEffect(() => {
      setSelectedBodyPart(null);
      setNewImages([]);
  }, [isModalOpen]);

  const handleUploadClick = () => {
    document.getElementById('upload-skin-images').click();
  };

  const onUpdateImage = (e) => {
    const formData = new FormData();
    Promise.all(
      Object.keys(e.target.files)
        .map((key) => e.target.files[key])
        .map((file) => {
          formData.append(file.name, file);
          return new Promise((res, rej) => {
            Resizer.imageFileResizer(
              file,
              600,
              600,
              'JPEG',
              100,
              0,
              (blob) => {
                res(new File([blob], file.name));
              },
              'blob'
            );
          });
        })
    ).then(async (files) => {
      try {
        const result = await uploadImg({
          variables: {
            userid: +me.id,
            files,
          },
        });

        const images = result.data.uploadImages;
        setNewImages([...images, ...newImages]);
      } catch (error) {
        console.error('Error updating favorite:', error);
      }
    });
  };

  const removeImage = (indexToRemove) => {
    setNewImages(newImages.filter((_, index) => index !== indexToRemove));
  };

  const addImagesToSkinTracker = async () => {
    try {
      const payload = {
        userid: +me.id,
        client_date: selectedDate,
        imagesIds: newImages.map((img) => +img.id),
        body_part: selectedBodyPart.value,
      };

      await assignImages({
        variables: {
          payload,
        },
      });

      onUpload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      header={'Upload photos'}
      footer={
        <div className="button-row-right">
          <Button
            className="button button__secondary100-inverted button__btn-14-600 button-rectangle-medium button-row-space"
            onClick={onClose}
            text={'Cancel'}
          />
          <Button
            onClick={addImagesToSkinTracker}
            className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
            text={'Add'}
            disabled={newImages.length === 0 || !selectedDate || !selectedBodyPart}
          />
        </div>
      }
      onClose={onClose}
    >
      <input
        type="file"
        name="file1"
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
        onChange={onUpdateImage}
        id="upload-skin-images"
        multiple
      />
      <div className="upload-photos">
        <div className="images-container">
          <div className="image-input" onClick={handleUploadClick}>
            <UilPlus size="50" className="plus-sign" />
          </div>
          {newImages.map((item, index) => (
            <div key={index} className="image-preview">
              <img src={item.uri} alt={item.filename} />
              <div onClick={() => removeImage(index)} className="photo-delete">
                <UilMultiply size="25" className="x-sign" />
              </div>
            </div>
          ))}
        </div>
        <div>
          <span className="title">Photos details</span>
          <br />
        </div>
        <form className="form">
          <div className="form-item date-body-part">
            <div className="form-item">
              <label className="form-input-label text__left">
                Select date <span className="red-asterisk">*</span>
              </label>
              <DatePicker
                className={`gallery-day-picker`}
                selected={selectedDate ? moment(selectedDate).toDate() : null}
                onChange={(date) => setSelectedDate(moment(date).format('YYYY-MM-DD'))}
                maxDate={new Date()}
                dateFormat="MM/dd/yyyy"
                placeholderText="Select Date"
              />
            </div>
            <div className="form-item">
              <label className="form-input-label text__left">
                Select body part <span className="red-asterisk">*</span>
              </label>
              <Dropdown
                options={BodyAreas}
                className="gallery-body-part-picker"
                onChange={(bodyPart) => setSelectedBodyPart(bodyPart)}
                value={selectedBodyPart}
                placeholderText="Select"
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UploadImage;
