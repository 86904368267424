import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-dropdown';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import no_image from '../assets/images/no_image.svg';
import sad_face_svg from '../assets/images/np-frown-538401-000000.svg';
import FadeIn from '../components/shared/FadeIn';
import config from '../config';
import analytics from '../libs/analytics';
import mixpanel from 'mixpanel-browser';
import MixpanelService, { MixpanelEvents } from '../libs/mixpanel';
import _ from 'lodash';
import { NewsBlueIcon, HelperIcon } from '../assets/icons';
import Tutorial from '../components/shared/Tutorial';

const useTopics = () => {
  const [_data, setData] = useState({ data: [], loading: true, error: null });
  useEffect(() => {
    setData({ data: [], loading: true, error: null });
    fetch(`${config.wordpress.URL}/taxonomy/topic?per_page=15`, {})
      .then(res => res.json())
      .then(data => {
        setData({
          data: data.items.map(({ name, id }) => ({ name, id })),
          loading: false,
          error: null,
        });
      })
      .catch(err => {
        setData({ data: [], loading: false, error: err });
      });
  }, []);
  return _data;
};

const useArticles = ({ topic_id, persona = null, per_page, search, page, resourceType }) => {
  const [_data, setData] = useState({ data: null, loading: true, error: null });
  useEffect(() => {
    setData({ data: null, loading: true, error: null });
    fetch(
      `${config.wordpress.URL}/post?` +
        qs.stringify({
          per_page,
          search,
          page,
          order: 'DESC',
          orderby: 'date',
          taxonomy: {
            topic:
              !persona && !topic_id ? { terms: [] } : { terms: [topic_id] },
            persona: { terms: persona },
          },
        }),
    )
      .then(res => res.json())
      .then(data => {
        setData({
          data,
          loading: false,
          error: null,
        });
      })
      .catch(err => {
        setData({ data: null, loading: false, error: err });
      });
  }, [topic_id, persona, per_page, search, page, resourceType]);
  return _data;
};

const useArticle = ({ id }) => {
  const [_data, setData] = useState({ data: null, loading: true, error: null });
  useEffect(() => {
    setData({ data: null, loading: true, error: null });
    fetch(`${config.wordpress.URL}/post/${id}`)
      .then(res => res.json())
      .then(data => {
        setData({ data, loading: false, error: null });
      })
      .catch(err => {
        setData({ data: null, loading: false, error: err });
      });
  }, [id]);
  return _data;
};

const Articles = ({
  resourceType,
  search,
  topic_id,
  persona,
  per_page,
  learnTopics,
}) => {
  const [articles, setArticles] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
    setArticles([]);
  }, [search, topic_id, resourceType]);
  
  const { loading: loadingArticles, data: articleData } = useArticles({
    topic_id: resourceType === 'topics' ? topic_id : null,
    persona: resourceType === 'recommended' ? persona : null,
    per_page,
    search,
    page,
    resourceType,
  });

  useEffect(() => {
    if (articleData) {
      setArticles(articles.concat(articleData.items));
    }
  }, [articleData]);

  if (resourceType === 'articles') {
    // we're viewing an article here and topic_id is the article_id
    // e.g. /articles/123
    return <Article id={topic_id} learnTopics={learnTopics} />;
  }

  if (page === 1 && !loadingArticles && articles.length === 0) {
    return (
      <div className={'no-results'}>
        <div className={'no-results-title'}>No Results Found</div>
        <img className={'no-results-img'} src={sad_face_svg} alt="no-results-img" />
        <div className={'no-results-description'}>
          Sorry, we could not find any results matching the criteria
        </div>
      </div>
    );
  }

  return (
    <div className={`articles`}>
      {articles.map((article, i) => (
        <NavLink
          to={`/resources/articles/${article.id}`}
          className={'article-thumbnail'}
          key={article.id}>
          <div
            className={'article-thumbnail-image'}
            style={{
              backgroundImage: `url(${
                article.large_image ? article.large_image.src : no_image
              })`,
            }}
          />
          <div className={'description'}>
            <h1
              className={'article-thumbnail-title'}
              dangerouslySetInnerHTML={{ __html: article.title }}
            />
            <p
              className={'article-thumbnail-excerpt'}
              dangerouslySetInnerHTML={{ __html: article.excerpt }}
            />
            <h4 className={'article-thumbnail-author'}>
              <span className={'article-thumbnail-author-by'}>By</span> National
              Eczema Association
            </h4>
            <div className={'article-thumbnail-date'}>
              {moment(article.date).format('MMMM DD, YYYY')}
            </div>
          </div>
        </NavLink>
      ))}
      <button
        className={'load-more-button'}
        onClick={e => {
          e.target.blur();
          analytics().logEvent('load_more_articles', {});
          setPage(page + 1);
        }}>
        Load more
      </button>
    </div>
  );
};

const Article = ({ id, learnTopics, topic_id, articles }) => {
  const article = useRef(null);

  // useEffect(() => {
  //   if (article.current) {
  //     const res = article.current.querySelectorAll('iframe');
  //     // res.forEach(el => {
  //     //   requestAnimationFrame(() => {
  //     //     el.parentElement.style.paddingTop = '56.25%';
  //     //   });
  //     // });
  //   }
  // }, [article.current]);

  const { data, loading, error } = useArticle({ id });
  useEffect(() => {
    if (!loading && !error) {
      analytics().setCurrentScreen('article_open');
      const dataToTrack = {
        ArticleTitle: data.title,
        Topic: data.taxonomy.topic.map(topic => topic.name).join(', '),
      };
      MixpanelService.track(MixpanelEvents.ArticleOpened, dataToTrack);
    }
  }, [data, loading, error]);

  if (loading || error) return null;

  return (
    <div className={'article'}>
      <div className={'description'}>
        <h1
          className="article-name"
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
        <h4 className={'article-thumbnail-author'}>
          <span className={'article-thumbnail-author-by'}>By</span> National
          Eczema Association
        </h4>
        <div className={'article-date'}>
          {moment(article.date).format('MMMM DD, YYYY')}
        </div>

        {data.large_image && (
          <img
            className={'header-image'}
            src={data.large_image.src}
            alt={data.large_image.src}
          />
        )}
        <h2
          className={'article-sub-name'}
          dangerouslySetInnerHTML={{ __html: data.excerpt }}
        />

        <div
          ref={article}
          className="article-info"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </div>
  );
};

let timeout = null;
const Resources = ({ me, match, history }) => {
  const {
    loading: loadingTopics,
    data: learnTopics,
    error: errorTopics,
  } = useTopics();

  const { resourceType, topicId, topicName } = match.params;
  const [topic_id, setTopicID] = useState(topicId || 'all');
  const [topic_name, setTopicName] = useState(topicName || 'All');

  const params = new URLSearchParams(window.location.search);
  const _search = params.get('search');

  const [search, setSearch] = useState(_search || '');
  const [actualSearch, setActualSearch] = useState(_search || '');
  const _setSearch = e => {
    const val = e.target.value;
    clearTimeout(timeout);
    setSearch(val);
    timeout = setTimeout(() => {
      MixpanelService.track('SearchViaHomepage', { SearchTerm: val });
      setActualSearch(val);
      history.push({
        search: `?search=${val}`,
      });
    }, 1000);
  };

  const [activeTutorialId, setActiveTutorialId] = useState(null);
  if (!me || !me.id) return <Redirect to={'/'} />;

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  const NewsTutorial = {
    titles: ['News', 'News', 'News'],
    subtitles: [``, `Navigate news`, `Search topics`],
    texts: [
      'You live with your eczema every day, but how well do you really know it? Get a new understanding of it and how to manage it.',
      'Easily find the most recommended or recent articles.',
      `Browse topics and search content.`,
    ],
    urlsGif: ['', '', ''],
  };

  return (
    <FadeIn>
      <header>
        <div className="date-bar date-bar__filters">
          {resourceType !== 'articles' ? (
            <>
              <header>
                <div className="main-dashboard__sidebar">
                  <div className="sidebar-title">
                    <NewsBlueIcon />
                    <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                      News
                    </h1>
                    <Tutorial
                      titles={NewsTutorial.titles}
                      texts={NewsTutorial.texts}
                      subtitles={NewsTutorial.subtitles}
                      urlsGif={NewsTutorial.urlsGif}
                      onClickNext={activeTutorialUpdate.bind(this)}>
                      <div className="tooltip-trigger">
                        <span className="icon-instructions">
                          <HelperIcon />
                        </span>
                      </div>
                    </Tutorial>
                  </div>
                </div>
              </header>
              <div className={'filters-container'}>
                <NavLink
                  to={'/resources/recommended'}
                  exact
                  className={'recommended'}>
                  Recommended
                </NavLink>
                <NavLink to={'/resources/latest'} exact className={'latest'}>
                  Latest
                </NavLink>

                <div className="stretch" />

                <Dropdown
                  options={[
                    { label: 'All', value: 'all' },
                    ...(learnTopics || []).map(topic => ({
                      label: topic.name,
                      value: `${topic.id}`,
                    })),
                  ]}
                  onChange={c => {
                    setTopicID(c.value);
                    setTopicName(c.label);
                    if (c.value === 'all') history.push(`/resources/latest`);
                    else history.push(`/resources/topics/${c.value}/${c.label}`);
                  }}
                  value={`${topic_name ? topic_name : 'All'}`}
                />
                <label htmlFor="search-input">
                  <input
                    className={'search filter-input'}
                    type="search"
                    name="search-input"
                    aria-label="search-input"
                    value={search}
                    onChange={_setSearch}
                    placeholder="Search"
                  />
                </label>
              </div>
            </>
          ) : (
            <div className={'filters-container'}>
              <button
                onClick={e => {
                  history.goBack();
                }}
                className={'recommended back sidebar-button'}>
                <i className="material-icons icon-back">arrow_back</i> Back to
                news
              </button>
            </div>
          )}
        </div>
      </header>
      <div className="main-content content-body page-home no-bg">
        <section className="content-section content-section__large content__center home-page__articles">
          <Articles
            resourceType={resourceType}
            search={actualSearch}
            persona={me.profile.persona}
            topic_id={match.params.topicId}
            learnTopics={learnTopics}
            per_page={10}
          />
        </section>
      </div>
    </FadeIn>
  );
};

export default compose(withRouter)(Resources);
