import React from 'react';

export const PainIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__pain">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__pain tracker-icon-image"
        transform="translate(11, 8) scale(.9)">
        <path
          d="M13.5727008,6.50341838 C13.5270643,6.46538797 13.4618693,6.46104164 13.4107999,6.49146597 L6.04268004,10.867136 L6.71310181,0.150167088 C6.71636156,0.0795391872 6.66746532,0.0154307853 6.597924,0.00239178826 C6.52620952,-0.0106472088 6.45884137,0.0295563654 6.43602312,0.0969245166 L0.633669451,17.5039855 C0.615197538,17.5594013 0.633669451,17.6213365 0.67930594,17.6593669 C0.72494243,17.6963107 0.789050832,17.7006571 0.84120682,17.6702327 L7.50522088,13.7139837 L6.77938338,26.2933561 C6.77503704,26.363984 6.82501987,26.4270058 6.89456118,26.4411314 C6.90325385,26.4433046 6.91194651,26.4443911 6.92063918,26.4443911 C6.98148783,26.4443911 7.03581698,26.4052742 7.05537548,26.3465987 L13.6183373,6.65879976 C13.6378958,6.60229744 13.6183373,6.54036221 13.5727008,6.50341838"
          id="Fill-1"
        />
        <path
          d="M20.466766,10.9266258 C20.4071211,10.8753313 20.3176539,10.8765242 20.259202,10.9290116 L12.3383688,18.224767 C12.2918458,18.2677112 12.2751453,18.3321277 12.2966174,18.3917725 C12.3168967,18.4514173 12.3717699,18.4907829 12.4349934,18.4955545 L15.8395202,18.6995398 L11.6942047,24.67118 C11.6488746,24.7367893 11.6608035,24.8250636 11.7204484,24.8775511 C11.7490779,24.9037948 11.7860577,24.9157238 11.8230375,24.9157238 C11.8600173,24.9157238 11.89819,24.901409 11.9280124,24.8739724 L19.8476526,17.5794099 C19.8941756,17.5376586 19.9108762,17.4720492 19.889404,17.4124044 C19.8691248,17.3539525 19.8142515,17.313394 19.7522209,17.3098153 L16.3476941,17.10583 L20.4918168,11.1329969 C20.5383398,11.0685805 20.5264108,10.9791133 20.466766,10.9266258"
          id="Fill-4"
        />
      </g>
    </svg>
  );
};
