import { scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withTrendsView } from '../../apollo/stores/trendsView';
// Queries
import PageButton from '../shared/PageButton';

const dateArray = (startDate, endDate) => {
  let result = [];
  let currentDate = new Date(startDate.getTime());

  while (currentDate && currentDate <= endDate) {
    result.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return result;
};

class DayBrowser extends PureComponent {
  constructor(props) {
    super(props);
    this.rootNode = React.createRef();
    this.mediaQuery = window.matchMedia('(max-width: 1023px)'); // large-down
    this.height = 50;
    this.standardRadius = 10.5;
    this.state = {
      mode: 'desktop',
      dayBrowserStartDate: this.props.trendsView.startDate,
      dayBrowserEndDate: this.props.trendsView.endDate,
    };
  }

  updateDimensions() {
    if (this.rootNode.current) {
      this.componentDidUpdate();
    }
  }

  selectDate(d) {
    // clear any currently selected days
    this.chart.selectAll('.day.selected').classed('selected', false);

    // select this day
    this.chart
      .selectAll('.day')
      .filter(day => {
        return day === d;
      })
      .classed('selected', true);

    // if the selected date isn't on the displayed chart, change the startDate/endDate
    let startDate = this.props.trendsView.startDate;
    let endDate = this.props.trendsView.endDate;

    let sign = null;
    if (d < startDate) sign = -1;
    else if (d > endDate) sign = 1;

    if (sign) {
      let increment = this.props.trendsView.value === 'week' ? 7 * sign : sign;
      let units = this.props.trendsView.value === 'week' ? 'days' : 'months';
      startDate = moment(startDate).add(increment, units).format('YYYY-MM-DD');
      endDate = moment(endDate).add(increment, units).format('YYYY-MM-DD');
    }

    // save to the store
    return this.props.client.writeData({
      //todo: gotta get to this.props.client instead
      data: {
        trendsView: {
          __typename: 'trendsView',
          selectedDate: d,
          startDate: startDate,
          endDate: endDate,
        },
      },
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.mediaQuery.addListener(this.mediaQueryHandler);
    this.mediaQueryHandler(this.mediaQuery);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    this.mediaQuery.removeListener(this.mediaQueryHandler);
  }

  mediaQueryHandler = e => {
    if (e.matches) {
      this.setState({ mode: 'tablet' });
    } else {
      this.setState({ mode: 'desktop' });
    }
  };

  componentDidUpdate() {
    this.width = this.rootNode.current.parentNode.offsetWidth;

    select(this.rootNode.current).selectAll('*').remove(); //clear
    this.chart = select(this.rootNode.current)
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', 'translate(50,50)');

    // Build SVG
    this.adjustNumberOfDays();
    this.drawDayBrowser();
  }

  adjustNumberOfDays() {
    let selectedDate = this.props.trendsView.selectedDate;
    let dayBrowserStartDate = this.state.dayBrowserStartDate;
    let dayBrowserEndDate = this.state.dayBrowserEndDate;
    let todayDayOfWeek = moment().day();
    let todayDayOfMonth = moment().date();

    // bound the week or month surrounding the selectedDate
    // round endDate up to the standard endofweek/month, then adjust startDate for the period
    if (this.props.trendsView.value === 'week' || this.state.mode === 'tablet') {
      if (moment(selectedDate).day() > todayDayOfWeek) todayDayOfWeek += 7;
      dayBrowserEndDate = moment(selectedDate).day(todayDayOfWeek).format('YYYY-MM-DD');
      dayBrowserStartDate = moment(dayBrowserEndDate).subtract(6, 'days').format('YYYY-MM-DD');
    } else {
      if (moment(selectedDate).date() > todayDayOfMonth) todayDayOfMonth += moment(selectedDate).daysInMonth();
      dayBrowserEndDate = moment(selectedDate).date(todayDayOfMonth).format('YYYY-MM-DD');
      dayBrowserStartDate = moment(dayBrowserEndDate).subtract(1, 'months').add(1, 'day').format('YYYY-MM-DD');
    }
  }

  page(direction) {
    let sign = direction === 'back' ? -1 : 1;
    let increment = this.props.trendsView.value === 'week' ? 7 : 1;
    let units = this.props.trendsView.value === 'week' ? 'days' : 'months';

    let selectedDate = this.props.trendsView.selectedDate;
    let dayBrowserStartDate = this.state.dayBrowserStartDate;
    let dayBrowserEndDate = this.state.dayBrowserEndDate;
    let startDate = this.props.trendsView.startDate;
    let endDate = this.props.trendsView.endDate;

    // accounting for the case where the day browser and chart have different time scales
    if (this.props.trendsView.value === 'month' && this.state.mode === 'tablet') {
      dayBrowserEndDate = moment(dayBrowserEndDate).add(7 * sign, 'days').format('YYYY-MM-DD');
      selectedDate = moment(selectedDate).add(7 * sign, 'days').format('YYYY-MM-DD');
      if (dayBrowserEndDate > moment().format('YYYY-MM-DD')) {
        // cap end date at today
        dayBrowserEndDate = moment().format('YYYY-MM-DD');
        selectedDate = dayBrowserEndDate;
      }
      dayBrowserStartDate = moment(dayBrowserEndDate).subtract(increment, units).add(1, 'day').format('YYYY-MM-DD');

      // adjust chart only if we're paging out of it
      if (dayBrowserStartDate < startDate || dayBrowserEndDate > endDate) {
        endDate = moment(endDate).add(increment * sign, units).format('YYYY-MM-DD');
        startDate = moment(endDate).subtract(increment, units).add(1, 'day').format('YYYY-MM-DD');
      }
    } else {
      dayBrowserEndDate = moment(dayBrowserEndDate).add(increment * sign, units).format('YYYY-MM-DD');
      endDate = moment(endDate).add(increment * sign, units).format('YYYY-MM-DD');
      selectedDate = moment(selectedDate).add(increment * sign, units).format('YYYY-MM-DD');
      if (dayBrowserEndDate > moment().format('YYYY-MM-DD')) {
        // cap end date at today
        dayBrowserEndDate = moment().format('YYYY-MM-DD');
        endDate = dayBrowserEndDate;
        selectedDate = dayBrowserEndDate;
      }
      dayBrowserStartDate = moment(dayBrowserEndDate).subtract(increment, units).add(1, 'day').format('YYYY-MM-DD');
      startDate = moment(endDate).subtract(increment, units).add(1, 'day').format('YYYY-MM-DD');
    }

    return this.props.client.writeData({
      data: {
        trendsView: {
          startDate: startDate,
          endDate: endDate,
          selectedDate: selectedDate,
          __typename: 'trendsView',
        },
      },
    });
  }

  // Generate day labels for selection, not necessarily the same scale as the day columns in LineChart - always week view on mobile
  drawDayBrowser() {
    if (this.props.data.length) {
      const xScale = scaleTime()
        .domain([
          moment(this.state.dayBrowserStartDate).toDate(),
          moment(this.state.dayBrowserEndDate).add(1, 'days').toDate(),
        ])
        .range([ 0, this.width - this.props.yLabelWidth * 2 ]);

      const dayWidth =
        xScale(moment(this.state.dayBrowserStartDate).add(1, 'days').toDate()) -
        xScale(moment(this.state.dayBrowserStartDate).toDate());
      const dates = dateArray(
        moment(this.state.dayBrowserStartDate).toDate(),
        moment(this.state.dayBrowserEndDate).toDate()
      );
      const days = this.chart.append('g').attr('class', 'days').selectAll('.days').data(dates).enter();
      const xaxisdots = days
        .append('g')
        .attr('class', d => (d === this.props.trendsView.selectedDate ? 'numberdot selected' : 'numberdot'))
        .attr('transform', d => {
          return 'translate(' + (xScale(moment(d).toDate()) + dayWidth / 2) + ',-25)';
        })
        .on('click', (e, date) => {
          this.selectDate(date);
        });
      xaxisdots.append('circle').attr('r', this.standardRadius);
      xaxisdots
        .append('text')
        .text(d => {
          return moment(d).format('D');
        })
        .attr('y', 4);
    }
  }

  render() {
    return (
      <div>
        <PageButton direction="back" onClick={this.page.bind(this)} />
        <PageButton direction="forward" onClick={this.page.bind(this)} />
        <svg className="linechart__svg" ref={this.rootNode} />
      </div>
    );
  }
}

export default compose(withRouter, withTrendsView, withApollo)(DayBrowser);
