import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

const CustomOption = ({ children, data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className={'option-content'} >
        {children}
        {data.title && (
          <Fragment>
            <ReactTooltip
              type={'dark'}
              multiline={true}
              className={'shadow-default'}
            />
            <svg
              data-tip={data.title}
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 512 512"
              height="1em"
              width="1em">
              <path d="M256 48C141.2 48 48 141.2 48 256s93.2 208 208 208 208-93.2 208-208S370.8 48 256 48zm21 333h-42v-42h42v42zm-.2-63h-41.6c0-67 62.4-62.2 62.4-103.8 0-22.9-18.7-41.7-41.6-41.7S214.4 192 214.4 214h-41.6c0-46 37.2-83 83.2-83s83.2 37.1 83.2 83.1c0 52-62.4 57.9-62.4 103.9z" />
            </svg>
          </Fragment>
        )}
      </div>
    </components.Option>
  );
};

export const PersonalDetailsForm = props => {
  return (
    <Fragment>
      <label className="form-input-label-login text__left">{props.type} <span className="red-asterisk">*</span></label>

      <Select
        className="form-select-wrapper"
        classNamePrefix="form-select"
        aria-required="true"
        instanceId={props.type}
        value={props.value}
        onChange={props.changeHandler}
        options={props.options}
        placeholder={props.placeholder}
        components={{ Option: CustomOption }}
      />
    </Fragment>
  );
};
