import gql from 'graphql-tag';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';

const AdminLogs = props => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(50);

  const {
    data: _data,
    loading,
    error,
    refetch,
  } = useQuery(
    gql`
      query ($limit: Int) {
        adminGetLogs(limit: $limit) {
          id
          level
          message
          created_date
        }
      }
    `,
    {
      variables: { limit },
    },
  );

  useEffect(() => {
    if (_data) setData(_data.adminGetLogs);
  }, [_data]);

  useEffect(() => {
    const i = setInterval(() => {
      refetch();
    }, 15000);
    return () => {
      clearInterval(i);
    };
  });

  return (
    <Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '0fr 0fr 0fr 1fr',
          gap: '8px',
          fontFamily: 'monospace',
          fontSize: '12px',
          backgroundColor: '#181818',
          color: 'white',
          padding: '12px',
          whiteSpace: 'wrap',
          maxHeight: '600px',
          overflowY: 'scroll',
          overflowX: 'scroll',
          borderRadius: '8px',
        }}>
        {data.map((log, i) => {
          const title = moment(log.created_date).fromNow();
          return (
            <Fragment key={log.id}>
              <div
                title={title}
                id={log.id}
                style={{
                  padding: '2px 0',
                  color: '#666',
                  fontWeight: 600,
                  fontSize: '11px',
                }}>
                [{log.id}]
              </div>
              <div
                title={title}
                style={{
                  padding: '2px 7px',
                  color: 'seagreen',
                  whiteSpace: 'nowrap',
                }}>
                {moment(log.created_date).format('MM/DD/YY hh:mm:ss')}
              </div>
              <div
                title={title}
                style={{
                  borderRadius: '4px',
                  backgroundColor: (() => {
                    let c = 'seagreen';
                    switch (log.level) {
                      case 'ERROR':
                        c = 'firebrick';
                        break;
                      case 'WARN':
                        c = '#eed202';
                        break;
                      case 'INFO':
                      case 'SYSTEM':
                        c = 'transparent';
                        break;
                      case 'SURVEY':
                        c = '#eb5202';
                        break;
                      case 'NOTIFICATION':
                        c = '#553592';
                        break;
                      case 'ADMIN':
                        c = '#007dbd';
                        break;
                      default:
                        break;
                    }
                    return c;
                  })(),
                  width: 'auto',
                  fontWeight: 600,
                  color: 'white',
                  padding: '2px 7px',
                  justifySelf: 'start',
                  alignSelf: 'start',
                }}>
                {log.level}
              </div>
              <pre
                title={title}
                style={{
                  padding: '2px 7px',
                  margin: 0,
                }}>
                {log.message}
              </pre>
            </Fragment>
          );
        })}
        <pre
          style={{
            whiteSpace: 'prewrap',
            margin: 0,
            gridColumn: '4/5',
            fontFamily: 'monospace',
          }}>
          <button
            onClick={() => {
              setLimit(limit + 50);
            }}
            style={{
              padding: '12px 7px 36px 7px',
              outline: 'none',
              cursor: 'pointer',
              color: 'seagreen',
              fontWeight: 600,
              backgroundColor: 'transparent',
              border: 'none',
            }}>
            LOAD MORE
          </button>
        </pre>
      </div>
    </Fragment>
  );
};

export default AdminLogs;
