import React from 'react';

export default function Edit() {
  return (
    <svg className="icon icon-edit" viewBox="0 0 30 29">
      <g
        className="icon-fill"
        stroke="none"
        strokeWidth="1"
        fill="#434255"
        fillRule="evenodd"
        transform="translate(0.2, -1)">
        <path
          d="M2.20641818,21.8087182 C2.08464545,21.6869455 1.8696,21.6869455 1.74782727,21.8087182 L1.03532727,22.5225136 C0.9861,22.5717409 0.953713636,22.6365136 0.94335,22.7051727 L0.00285,29.2977409 C-0.0114,29.3987864 0.0222818182,29.4998318 0.0948272727,29.5723773 C0.155713636,29.6332636 0.238622727,29.6669455 0.324122727,29.6669455 C0.339668182,29.6669455 0.353918182,29.66565 0.369463636,29.6630591 L6.96203182,28.7225591 C7.03198636,28.7134909 7.09546364,28.6811045 7.14598636,28.6318773 L7.85719091,27.9180818 C7.98414545,27.7911273 7.98414545,27.5864455 7.85719091,27.4594909 L2.20641818,21.8087182 Z"
          id="Fill-6"
        />
        <path
          d="M17.9884227,6.02671364 C17.8614682,5.89975909 17.6567864,5.89975909 17.5298318,6.02671364 L3.73453636,19.8233045 C3.60758182,19.9502591 3.60758182,20.1549409 3.73453636,20.2818955 L9.38530909,25.9326682 C9.44878636,25.9961455 9.53169545,26.0272364 9.61460455,26.0272364 C9.69751364,26.0272364 9.78042273,25.9961455 9.84260455,25.9326682 L23.6404909,12.1360773 C23.7013773,12.0751909 23.7350591,11.9922818 23.7350591,11.9067818 C23.7350591,11.8212818 23.7013773,11.7383727 23.6404909,11.6774864 L17.9884227,6.02671364 Z"
          id="Fill-1"
        />
        <path
          d="M27.6373568,4.47437045 L25.1915386,2.02984773 C24.2134705,1.04918864 22.5047659,1.05048409 21.5279932,2.02984773 L19.5161523,4.04168864 C19.3891977,4.16864318 19.3891977,4.373325 19.5161523,4.50027955 L25.166925,10.1510523 C25.2304023,10.2145295 25.3133114,10.2456205 25.3962205,10.2456205 C25.4791295,10.2456205 25.5620386,10.2145295 25.6255159,10.1510523 L27.6373568,8.13921136 C28.6465159,7.12875682 28.6465159,5.484825 27.6373568,4.47437045"
          id="Fill-3"
        />
      </g>
    </svg>
  );
}
