import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withAppointments } from '../../apollo/stores/appointments';
import { Edit } from '../../assets/icons';
import { MIXPANEL_DAY_FORMAT } from '../../libs/mixpanel';
import AppointmentForm from '../forms/Appointment';
import AppointmentProgress from './AppointmentProgress';

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  _handleEditButtonClick(e) {
    e.preventDefault();
    let appointmentId = this.props.details.id;
    let appointment = this.props.client.readFragment({
      id: `appt:${appointmentId}`,
      fragment: gql`
        fragment thisAppt on Appointment {
          date
          time
          notes
          provider_first_name
          provider_last_name
          provider_phone
          provider_address_1
          provider_address_2
          provider_city
          provider_state
          provider_zip
        }
      `,
    });
    let {
      date,
      time,
      notes,
      provider_first_name,
      provider_last_name,
      provider_phone,
      provider_address_1,
      provider_address_2,
      provider_city,
      provider_state,
      provider_zip,
    } = appointment;
    this.props
      .hydrateMutation({
        variables: {
          date,
          time,
          notes,
          provider_first_name,
          provider_last_name,
          provider_phone,
          provider_address_1,
          provider_address_2,
          provider_city,
          provider_state,
          provider_zip,
        },
      })
      .then(() => {
        this.setState({
          edit: true,
        });
        this.props.setEditId(appointmentId);
      });
  }

  handleCancel() {
    this.setState({
      edit: false,
    });
    this.props.resetAppointmentForm();
    this.props.setEditId(undefined);
  }

  render() {
    const date = moment.utc(this.props.details.date);
    const time = moment.utc(this.props.details.time);

    return (
      <div
        className={
          this.state.edit
            ? 'appointment-item appointment-edit card card__active'
            : 'appointment-item card card__inactive'
        }
        key={this.props.details.id}>
        <div className="card-header">
          <h2 className="title title__black100 title__semibold">
            <span className="card-header__date">
              <span className="date__date">{date.format('MMM. D, YYYY')}</span>
              <span className="date__at">at</span>
              <span className="date__time">{time.format('h:mm A')} </span>
            </span>
            <span className="text__capitalize card-header__name">
              {this.props.details.provider_first_name +
                ' ' +
                this.props.details.provider_last_name}
            </span>
          </h2>

          <div className="header-item__wrapper">
            <button
              className="button button-action button-row"
              onClick={this._handleEditButtonClick.bind(this)}
              disabled={
                this.state.edit ||
                (this.props.appointmentInEdit &&
                  this.props.appointmentInEdit !== this.props.details.id)
              }>
              <span className="subtext subtext__cobalt">Edit</span>
              <Edit />
            </button>
          </div>
        </div>
        <div className="card-inactive__details">
          <div className="card-inactive__phone">
            <h4 className="subtext subtext__black100 subtext__capitalized">
              Phone Number
            </h4>
            <p className="paragraph">
              {this.props.details.provider_phone || ''}
            </p>
          </div>
          <div className="card-inactive__address">
            <h4 className="subtext subtext__black100 subtext__capitalized">
              Address
            </h4>
            <p className="paragraph">
              <span className="paragraph__capitalize">
                {this.props.details.provider_address_1.toLowerCase() || ''}
              </span>
              <span className="paragraph__capitalize">
                {this.props.details.provider_address_2 ? ', ' : ''}
                {this.props.details.provider_address_2.toLowerCase() || ''}
              </span>

              <span className="paragraph__capitalize">
                {this.props.details.provider_city ? ', ' : ''}
                {this.props.details.provider_city.toLowerCase() || ''}
              </span>
              <span className="paragraph__capitalize">
                {this.props.details.provider_state ? ', ' : ''}
                {this.props.details.provider_state || ''}
              </span>
              <span className="paragraph__capitalize">
                {this.props.details.provider_zip
                  ? ` ${this.props.details.provider_zip}`
                  : ''}
              </span>
            </p>
          </div>
          <div className="card-inactive__notes">
            <h4 className="subtext subtext__black100 subtext__capitalized">
              Appointment Notes
            </h4>
            <p className="paragraph">{this.props.details.notes || 'none'}</p>
          </div>
        </div>
        <div className="tracker-body">
          <div className="skin-progress">
            {!this.state.edit ? (
              <AppointmentProgress
                appointmentId={this.props.details.id}
                appointmentDate={date.format(MIXPANEL_DAY_FORMAT)}
                metadata_added={this.props.details.metadata_added}
                disableLink={
                  this.props.appointmentInEdit &&
                  this.props.appointmentInEdit !== this.props.details.id
                }
              />
            ) : (
              <AppointmentForm
                userid={this.props.userid}
                createAppointment={this.props.createAppointmentMutation}
                deleteAppointment={this.props.deleteAppointment}
                formData={this.props.appointmentForm}
                inputChangeHandler={this.props.updateInputAppointment}
                resetForm={this.handleCancel.bind(this)}
                edit={true}
                appointmentId={this.props.details.id}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withApollo, withAppointments)(Appointment);
