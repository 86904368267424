import PropTypes from 'prop-types';
import React from 'react';

export default function Button({
  imageBefore,
  imageAfter,
  disabled = false,
  onClick,
  text,
  className = 'button button-regular button__white',
}) {
  return (
    <button
      disabled={disabled}
      className={className}
      onClick={event => onClick && onClick({ event })}>
      {imageBefore}
      {text}
      {imageAfter}
    </button>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
