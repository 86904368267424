import React from 'react';

export default function verticalLine() {
  return (
    <svg className="svg-vertical-line" viewBox="0 0 400 600">
      <path
        className="stroke-line"
        d="M275,200 v-150"
        stroke="black"
        strokeWidth="1"
      />
    </svg>
  );
}
