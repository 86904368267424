import React from 'react';

export default function HamburgerMenuBars() {
  return (
    <svg
      width="112"
      height="115"
      viewBox="0 0 112 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1226 22.5569L2.15767 73.3245C1.9179 74.3284 1.73382 75.3493 1.58547 76.3713C0.657222 80.7198 0.612569 85.2481 1.66648 89.6053C2.71187 93.9061 4.63071 97.7072 7.1645 100.94C10.7062 105.99 15.8045 109.912 22.4102 111.628C24.3873 112.143 26.4371 112.449 28.5127 112.579C31.8483 112.945 43.2993 105.992 46.7184 105.283C62.6724 101.98 70.6121 105.52 86.5711 102.217C105.97 98.2062 115.54 74.6465 106.233 57.7994C100.885 48.1242 87.5564 45.2261 80.2877 37.0351C74.126 30.0879 71.9134 17.5085 65.72 10.5961C51.0049 -5.82686 19.4145 0.0784025 14.1226 22.5569Z"
        fill="#C7EAF7"
      />
      <path
        d="M56.7264 51.5953L44.1924 64.1586L39.3716 59.3378C39.1096 59.032 38.7873 58.7836 38.4248 58.6082C38.0624 58.4328 37.6676 58.3343 37.2652 58.3187C36.8628 58.3032 36.4616 58.371 36.0866 58.5179C35.7117 58.6648 35.3712 58.8876 35.0865 59.1723C34.8017 59.457 34.5789 59.7976 34.432 60.1725C34.2852 60.5474 34.2174 60.9486 34.2329 61.351C34.2484 61.7534 34.347 62.1482 34.5224 62.5107C34.6977 62.8732 34.9461 63.1955 35.252 63.4574L42.118 70.3526C42.391 70.6234 42.7147 70.8376 43.0707 70.983C43.4267 71.1284 43.8078 71.2021 44.1924 71.1999C44.9588 71.1967 45.6933 70.8924 46.2375 70.3526L60.846 55.7441C61.1198 55.4725 61.3372 55.1494 61.4855 54.7934C61.6338 54.4373 61.7102 54.0554 61.7102 53.6697C61.7102 53.284 61.6338 52.9022 61.4855 52.5461C61.3372 52.1901 61.1198 51.867 60.846 51.5953C60.2986 51.0512 59.5581 50.7457 58.7862 50.7457C58.0143 50.7457 57.2738 51.0512 56.7264 51.5953ZM48.7794 31.7571C43.0008 31.7571 37.3521 33.4706 32.5474 36.681C27.7427 39.8914 23.9979 44.4545 21.7865 49.7931C19.5752 55.1318 18.9966 61.0064 20.1239 66.6739C21.2512 72.3414 24.0339 77.5474 28.1199 81.6334C32.206 85.7195 37.4119 88.5021 43.0795 89.6295C48.747 90.7568 54.6215 90.1782 59.9602 87.9669C65.2989 85.7555 69.862 82.0107 73.0723 77.206C76.2827 72.4013 77.9963 66.7525 77.9963 60.974C77.9963 57.1372 77.2406 53.3379 75.7723 49.7931C74.304 46.2484 72.1519 43.0275 69.4389 40.3145C66.7258 37.6015 63.505 35.4494 59.9602 33.9811C56.4155 32.5128 52.6162 31.7571 48.7794 31.7571ZM48.7794 84.3475C44.1566 84.3475 39.6375 82.9766 35.7938 80.4083C31.95 77.84 28.9542 74.1896 27.1851 69.9186C25.416 65.6477 24.9531 60.948 25.855 56.414C26.7569 51.88 28.983 47.7152 32.2518 44.4464C35.5207 41.1776 39.6854 38.9514 44.2195 38.0496C48.7535 37.1477 53.4531 37.6106 57.7241 39.3797C61.995 41.1487 65.6454 44.1446 68.2138 47.9883C70.7821 51.8321 72.1529 56.3511 72.1529 60.974C72.1529 67.173 69.6904 73.1182 65.307 77.5015C60.9236 81.8849 54.9784 84.3475 48.7794 84.3475Z"
        fill="#00A1D9"
      />
      <path
        d="M59.013 51.5953L46.479 64.1586L41.6582 59.3378C41.3963 59.032 41.0739 58.7836 40.7115 58.6082C40.349 58.4328 39.9542 58.3343 39.5518 58.3187C39.1494 58.3032 38.7482 58.371 38.3733 58.5179C37.9983 58.6648 37.6578 58.8876 37.3731 59.1723C37.0884 59.457 36.8656 59.7976 36.7187 60.1725C36.5718 60.5474 36.504 60.9486 36.5195 61.351C36.5351 61.7534 36.6336 62.1482 36.809 62.5107C36.9844 62.8732 37.2328 63.1955 37.5386 63.4574L44.4046 70.3526C44.6776 70.6234 45.0013 70.8376 45.3573 70.983C45.7133 71.1284 46.0945 71.2021 46.479 71.1999C47.2454 71.1967 47.98 70.8924 48.5242 70.3526L63.1326 55.7441C63.4064 55.4725 63.6238 55.1494 63.7721 54.7934C63.9205 54.4373 63.9968 54.0554 63.9968 53.6697C63.9968 53.284 63.9205 52.9022 63.7721 52.5461C63.6238 52.1901 63.4064 51.867 63.1326 51.5953C62.5852 51.0512 61.8447 50.7457 61.0728 50.7457C60.3009 50.7457 59.5604 51.0512 59.013 51.5953ZM51.066 31.7571C45.2875 31.7571 39.6387 33.4706 34.834 36.681C30.0293 39.8914 26.2845 44.4545 24.0731 49.7931C21.8618 55.1318 21.2832 61.0064 22.4105 66.6739C23.5379 72.3414 26.3205 77.5474 30.4066 81.6334C34.4926 85.7195 39.6986 88.5021 45.3661 89.6295C51.0336 90.7568 56.9082 90.1782 62.2468 87.9669C67.5855 85.7555 72.1486 82.0107 75.359 77.206C78.5694 72.4013 80.2829 66.7525 80.2829 60.974C80.2829 57.1372 79.5272 53.3379 78.0589 49.7931C76.5906 46.2484 74.4385 43.0275 71.7255 40.3145C69.0124 37.6015 65.7916 35.4494 62.2468 33.9811C58.7021 32.5128 54.9028 31.7571 51.066 31.7571ZM51.066 84.3475C46.4432 84.3475 41.9241 82.9766 38.0804 80.4083C34.2366 77.84 31.2408 74.1896 29.4717 69.9186C27.7026 65.6477 27.2398 60.948 28.1416 56.414C29.0435 51.88 31.2696 47.7152 34.5385 44.4464C37.8073 41.1776 41.9721 38.9514 46.5061 38.0496C51.0401 37.1477 55.7397 37.6106 60.0107 39.3797C64.2816 41.1487 67.9321 44.1446 70.5004 47.9883C73.0687 51.8321 74.4395 56.3511 74.4395 60.974C74.4395 67.173 71.977 73.1182 67.5936 77.5015C63.2102 81.8849 57.2651 84.3475 51.066 84.3475Z"
        fill="#092A6D"
      />
    </svg>
  );
}
