import React from 'react';

const findSavedTreatment = (id, formattedOptions) => {
  let treatment = null;
  const findTreatment = options => {
    options.forEach(option => {
      if (+option.selectionId === +id) {
        treatment = option;
        return;
      }
      if (option.children) {
        findTreatment(option.children);
      }
    });
  };
  findTreatment(formattedOptions);
  return treatment;
};

const buildCostLabel = cost => {
  if (!cost) return '0';
  return Array(cost).fill('$').join('');
}

export const buildSavedTreatments = (
  savedTreatments,
  availableTreatmentOptions,
  stringOnly,
  savedTreatmentsNames,
  availableTreatmentOptionsMetadata = []
) => {
  let treatmentObjects = [];

  if (savedTreatmentsNames && savedTreatmentsNames.length > 0) {
    savedTreatmentsNames.forEach((treatmentName, index) => {
      treatmentObjects.push({
        name: treatmentName,
        id: savedTreatments[index]
      });
    });
  } else {
    let formattedOptions = formatOptions(availableTreatmentOptions);
    if (!savedTreatments.length) {
      return null;
    }
    savedTreatments.forEach(treatmentId => {
      let t = { ...findSavedTreatment(treatmentId, formattedOptions) };
      if (t.group === 'Topicals' && t.class === 'Steroids')
        t.name = `${t.formulation} ${t.class}`;
      treatmentObjects.push(t);
    });
  }

  // Add metadata_id to treatment objects
  treatmentObjects = treatmentObjects.map(treatmentObject => {
    const treatmentOption = availableTreatmentOptions.find(
      treatmentOption => +treatmentOption.id === treatmentObject.id
    );
    if (treatmentOption) {
      return { ...treatmentObject, metadata_id: treatmentOption.metadata_id };
    }
    return treatmentObject;
  });

  // Add cost to treatment objects
  treatmentObjects = treatmentObjects.map(treatmentObject => {
    const metadata = availableTreatmentOptionsMetadata.find(
      metadata => metadata.id === treatmentObject.metadata_id
    );
    if (metadata) {
      return {
        ...treatmentObject,
        cost: metadata.cost,
        costLabel: buildCostLabel(metadata.cost)
      };
    }
    return treatmentObject;
  });

  return treatmentObjects.map((trt, index) => {
    return stringOnly ? (
      trt.name
    ) : (
      <li key={index}>
        <span className="paragraph">
          <strong class="cost_label">{trt.costLabel}</strong>
          {trt.name}
        </span>
      </li>
    );
  });
};

/*
  input is an array of treatment options
  [
      {
        "id": "3",
        "name": "Ultra-High Potency",
        "pronunciation": null,
        "generic": true,
        "drug_names": [
          "Clobetasol propionate .05% (klo-BAY-ta-sol)",
          "Diflorasone diacetate .05% (dye-FLOR-a-sone)",
          "Clobetasol propionate .05%  (klo-BAY-ta-sol)",
          "Fluocinonide 0.1% (FLOO-oh-SIN-oh-nide)"
        ],
        "brand_names": [
          "Clobex, Olux, Temovate",
          "ApexiCon, Florone",
          "Ultravate",
          "Lidex"
        ],
        "group_id": "2",
        "class_id": "1",
        "group": "Topicals",
        "class": "Steroids",
        "metadata_id": "1",
        "formulation": "Ultra-High Potency"
      },....
  ]

  returns an array of option trees that treatment options component consumes
*/
export const formatOptions = options => {
  let groups = {}; //placeholder object for treatment groups
  let classes = {}; //placehold object for treatment classes

  options.forEach(option => {
    // create json of groups [id]
    if (!groups[option.group_id]) {
      groups[option.group_id] = {
        name: option.group,
        id: option.group_id,
        children: [],
      };
    }

    // create json of classes [id] belongs to group
    if (option.class_id && !classes[option.class_id]) {
      classes[option.class_id] = {
        name: option.class,
        id: option.class_id,
        group_id: option.group_id,
        children: [],
      };
    }

    // if the option has a class, it belongs to class of group, not group
    if (option.class_id) {
      classes[option.class_id].children.push(option);
    } else {
      groups[option.group_id].children.push(option);
    }
  });

  // group options by class and group
  let groupedClasses = Object.values(classes).reduce((acc, val) => {
    if (!acc[val.group_id]) {
      acc[val.group_id] = {
        [val.name]: val,
      };
    } else if (acc[val.group_id] && !acc[val.group_id][val.name]) {
      acc[val.group_id][val.name] = val;
    } else {
      acc[val.group_id][val.name].children = acc[val.group_id][
        val.name
      ].children.concat(val.children);
    }
    return acc;
  }, {});

  //add grouped options into the group object that will be flattened for return
  Object.keys(groupedClasses).forEach(grp_id => {
    Object.values(groupedClasses[grp_id]).forEach(subclass => {
      groups[grp_id].children.push(subclass);
    });
  });

  return Object.values(groups);
};

export const profileActiveTreatmentCategories = userTreatments => {
  const activeTreatmentTypes = userTreatments
    .filter(treatment => treatment.active)
    .map(treatment => treatment.type);
  
  return {
    TopicalsInProfile: activeTreatmentTypes.includes('Topicals'),
    PhototherapyInProfile: activeTreatmentTypes.includes('Phototherapy'),
    SystemicsInProfile: activeTreatmentTypes.includes('Systemics'),
    BiologicInProfile: activeTreatmentTypes.includes('Biologic'),
    OTCInProfile: activeTreatmentTypes.includes('OTC'),
    OtherInProfile: activeTreatmentTypes.includes('Other'),
  }
}

/* example output
[ { name: 'No Treatment',
    id: '1',
    children:
     [ { id: '23',
         name: 'No Treatment',
         pronunciation: null,
         generic: false,
         drug_names: null,
         brand_names: null,
         group_id: '1',
         class_id: null,
         group: 'No Treatment',
         class: null,
         metadata_id: '14',
         formulation: null } ] },
  { name: 'Topicals',
    id: '2',
    children:
     [ { name: 'Steroids',
         id: '1',
         group_id: '2',
         children:
          [ { id: '3',
              name: 'Ultra-High Potency',
              pronunciation: null,
              generic: true,
              drug_names:
               [ 'Clobetasol propionate .05% (klo-BAY-ta-sol)',
                 'Diflorasone diacetate .05% (dye-FLOR-a-sone)',
                 'Clobetasol propionate .05%  (klo-BAY-ta-sol)',
                 'Fluocinonide 0.1% (FLOO-oh-SIN-oh-nide)' ],
              brand_names:
               [ 'Clobex, Olux, Temovate',
                 'ApexiCon, Florone',
                 'Ultravate',
                 'Lidex' ],
              group_id: '2',
              class_id: '1',
              group: 'Topicals',
              class: 'Steroids',
              metadata_id: '1',
              formulation: 'Ultra-High Potency' },
            { id: '4',
              name: 'High Potency',
              pronunciation: null,
              generic: true,
              drug_names:
               [ 'Betamethasone dipropionate .05% (bay-ta-METH-a-sone)',
                 'Amcinonide .1% (am-SIN-oh-nide)',
                 'Desoximetasone .25% (des-ox-ih-MEH-ta-sone)',
                 'Diflorasone diacetate .05% (dye-FLOR-a-sone)',
                 'Fluocinonide .05% (FLOO-oh-SIN-oh-nide)',
                 'Clobetasol propionate .05%  (klo-BAY-ta-sol)',
                 'Halocinide .1% (hallo-SIN-oh-nide)',
                 'Triamcinolone acetonide .1% (try-am-SIN-oh-lone a-SEET-oh-nide)' ],
              brand_names:
               [ 'Diprolene, Luxiq, Sernivo',
                 'Cyclocort',
                 'Topicort',
                 'ApexiCon, Maxiflor, Florone',
                 'Lidex, Vanos',
                 'Ultravate, Elocon',
                 'Halog',
                 'Aristocort A, Cinolar, Dermasorb, Kenalog, Pediaderm TA, Triacet, Triamcort, Trianex, Triderm, Zytopic' ],
              group_id: '2',
              class_id: '2',
              group: 'Topicals',
              class: 'Steroids',
              metadata_id: '1',
              formulation: 'High Potency' },
            { id: '5',
              name: 'Moderate Potency',
              pronunciation: null,
              generic: true,
              drug_names:
               [ 'Betamethasone dipropionate .02% (bay-ta-METH-a-sone)',
                 'Betamethasone valerate .1% (bay-ta-METH-a-sone)',
                 'Desoximetasone .05% (des-ox-ih-MEH-ta-sone)',
                 'Fluocinonide .025% (FLOO-oh-SIN-oh-nide)',
                 'Hydrocortisone butyrate .1% (HYE-droe-KOR-ti-sone)',
                 'Hydrocortisone valerate .2% (HYE-droe-KOR-ti-sone)',
                 'Triamcinolone acetonide .1% (try-am-SIN-oh-lone)' ],
              brand_names:
               [ 'Diprolene, Luxiq, Sernivo',
                 'Betamethacot, Beta-Val, Qualisone, Valisone',
                 'Topicort',
                 'Lidex, Vanos',
                 'Licoid',
                 'Westcort',
                 'Aristocort A, Cinolar, Dermasorb, Kenalog, Pediaderm TA, Triacet, Triamcot, Trianex, Triderm, Zytopic' ],
              group_id: '2',
              class_id: '3',
              group: 'Topicals',
              class: 'Steroids',
              metadata_id: '1',
              formulation: 'Moderate Potency' },
            { id: '6',
              name: 'Low Potency',
              pronunciation: null,
              generic: true,
              drug_names:
               [ 'Betamethasone valerate .05% (bay-ta-METH-a-sone)',
                 'Desonide .05% (DES-oh-nide)',
                 'Fluocinolone acetonide .01% (FLOO-oh-SIN-oh-lone)' ],
              brand_names:
               [ 'Betamethacot, Beta-Val, Luxiq, Qualisone, Valisone',
                 'Desonate, Desowen, LoKara, Tridesilon, Verdeso',
                 'Capex, Derma-SmootheFS, Synalar' ],
              group_id: '2',
              class_id: '4',
              group: 'Topicals',
              class: 'Steroids',
              metadata_id: '1',
              formulation: 'Low Potency' } ] },
       { name: 'No Steroids',
         id: '5',
         group_id: '2',
         children:
          [ { id: '9',
              name: 'Crisaborole',
              pronunciation: 'kris-a-BOR-ole',
              generic: true,
              drug_names: null,
              brand_names: [ 'Eucrisa' ],
              group_id: '2',
              class_id: '5',
              group: 'Topicals',
              class: 'No Steroids',
              metadata_id: '3',
              formulation: null },
            { id: '7',
              name: 'Pimecrolimus .1%',
              pronunciation: 'pim-eck-row-LIE-muss',
              generic: false,
              drug_names: null,
              brand_names: [ 'Elidel' ],
              group_id: '2',
              class_id: '6',
              group: 'Topicals',
              class: 'No Steroids',
              metadata_id: '2',
              formulation: 'Topical calcineurin inhibitors (TCIs)' },
            { id: '8',
              name: 'Tacrolimus',
              pronunciation: 'tack-row-LIE-muss',
              generic: false,
              drug_names: null,
              brand_names: [ 'Protopic' ],
              group_id: '2',
              class_id: '6',
              group: 'Topicals',
              class: 'No Steroids',
              metadata_id: '2',
              formulation: 'Topical calcineurin inhibitors (TCIs)' } ] } ] },
  { name: 'Systemics',
    id: '3',
    children:
     [ { id: '10',
         name: 'Azathioprine',
         pronunciation: 'AY-za-THYE-oh-prin',
         generic: true,
         drug_names: null,
         brand_names: [ 'Azasan, Imuran' ],
         group_id: '3',
         class_id: null,
         group: 'Systemics',
         class: null,
         metadata_id: '4',
         formulation: null },
       { id: '11',
         name: 'Cyclosporine',
         pronunciation: 'SYE-kloe-SPOR-in',
         generic: true,
         drug_names: null,
         brand_names: [ 'Gengraf, Neoral, Sandimmune' ],
         group_id: '3',
         class_id: null,
         group: 'Systemics',
         class: null,
         metadata_id: '5',
         formulation: null },
       { id: '12',
         name: 'Methotrexate',
         pronunciation: 'METH-oh-TREX-ate',
         generic: true,
         drug_names: null,
         brand_names: [ 'Trexall, Otrexup, Rasuvo, Xatmep' ],
         group_id: '3',
         class_id: null,
         group: 'Systemics',
         class: null,
         metadata_id: '6',
         formulation: null },
       { id: '13',
         name: 'Mycophenolate mofetil',
         pronunciation: 'my-coh-FEN-oh-late MOW-fet-ill',
         generic: true,
         drug_names: null,
         brand_names: [ 'CellCept' ],
         group_id: '3',
         class_id: null,
         group: 'Systemics',
         class: null,
         metadata_id: '7',
         formulation: null },
       { name: 'Steroids',
         id: '7',
         group_id: '3',
         children:
          [ { id: '14',
              name: 'Prednisone',
              pronunciation: 'PRED-ni-sone',
              generic: true,
              drug_names: null,
              brand_names: [ 'Prednisone Intensol' ],
              group_id: '3',
              class_id: '7',
              group: 'Systemics',
              class: 'Steroids',
              metadata_id: '8',
              formulation: null },
            { id: '15',
              name: 'Prednisolone',
              pronunciation: 'pred-NISS-oh-lone',
              generic: true,
              drug_names: null,
              brand_names:
               [ 'Pediapred, FloPred, Orapred, Millipred, Prelone, Veripred' ],
              group_id: '3',
              class_id: '7',
              group: 'Systemics',
              class: 'Steroids',
              metadata_id: '8',
              formulation: null },
            { id: '16',
              name: 'Triamcinolone acetonide',
              pronunciation: 'try-am-SIN-oh-lone',
              generic: true,
              drug_names: null,
              brand_names: [ 'Kenalog' ],
              group_id: '3',
              class_id: '7',
              group: 'Systemics',
              class: 'Steroids',
              metadata_id: '8',
              formulation: null } ] } ] },
  { name: 'Phototherapy',
    id: '4',
    children:
     [ { id: '17',
         name: 'Narrow band UVB phototherapy',
         pronunciation: null,
         generic: false,
         drug_names: null,
         brand_names:
          [ 'Daavlin, National Biological Corp., SolarC Systems Inc.,  UVBioTek Phototherapy, XTRAC' ],
         group_id: '4',
         class_id: null,
         group: 'Phototherapy',
         class: null,
         metadata_id: '9',
         formulation: null },
       { id: '18',
         name: 'UVA1 phototherapy',
         pronunciation: null,
         generic: false,
         drug_names: null,
         brand_names:
          [ 'Daavlin, National Biological Corp., SolarC Systems Inc.,  UVBioTek Phototherapy, XTRAC' ],
         group_id: '4',
         class_id: null,
         group: 'Phototherapy',
         class: null,
         metadata_id: '10',
         formulation: null } ] },
  { name: 'Biologics',
    id: '5',
    children:
     [ { id: '19',
         name: 'Dupilumab',
         pronunciation: 'doo-PIL-you-mab',
         generic: false,
         drug_names: null,
         brand_names: [ 'Dupixent' ],
         group_id: '5',
         class_id: null,
         group: 'Biologics',
         class: null,
         metadata_id: '11',
         formulation: null } ] },
  { name: 'Other',
    id: '6',
    children:
     [ { id: '21',
         name: 'Wet Wrap Therapy',
         pronunciation: null,
         generic: false,
         drug_names: null,
         brand_names: null,
         group_id: '6',
         class_id: null,
         group: 'Other',
         class: null,
         metadata_id: '12',
         formulation: null },
       { id: '22',
         name: 'Bleach Bath',
         pronunciation: null,
         generic: false,
         drug_names: null,
         brand_names: null,
         group_id: '6',
         class_id: null,
         group: 'Other',
         class: null,
         metadata_id: '13',
         formulation: null } ] } ]
*/
