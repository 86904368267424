import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import AdminEditTreatmentsPage from '../components/admin/AdminEditTreatments';
import AdminLogs from '../components/admin/AdminLogs';
import AdminSetSettings from '../components/admin/AdminSetSettings';
import AdminStatsPage from '../components/admin/AdminStats';
import AdminSurveyEditor from '../components/admin/AdminSurveyEditor';
// Queries
import AdminUsers from '../components/admin/AdminUsersPage';
import rt from 'react-table/react-table.css';

class AdminContainer extends Component {
  render() {
    if (this.props.loading || this.props.loadingTreatmentOptions) return null;
    return this.props.me.admin ? (
      <div className={'admin_statistics'}>
        <nav className={'admin_nav'}>
          <NavLink
            exact
            className={'admin_nav_link'}
            to={'/admin/users'}
            activeClassName={'active'}>
            Users
          </NavLink>
          <NavLink
            exact
            className={'admin_nav_link'}
            to={'/admin/stats'}
            activeClassName={'active'}>
            Stats
          </NavLink>
          <NavLink
            className={'admin_nav_link'}
            to={'/admin/treatments'}
            activeClassName={'active'}>
            Treatments
          </NavLink>
          <NavLink
            className={'admin_nav_link'}
            to={'/admin/surveys'}
            activeClassName={'active'}>
            Surveys
          </NavLink>
          <NavLink
            className={'admin_nav_link'}
            to={'/admin/settings'}
            activeClassName={'active'}>
            Settings
          </NavLink>
          <NavLink
            className={'admin_nav_link'}
            to={'/admin/logs'}
            activeClassName={'active'}>
            Logs
          </NavLink>
        </nav>
        <Switch>
          <Route path={'/admin/users'} exact>
            <AdminUsers me={this.props.me} />
          </Route>
          <Route path={'/admin/stats'} exact>
            <AdminStatsPage />
          </Route>
          <Route path={'/admin/treatments'}>
            <AdminEditTreatmentsPage />
          </Route>
          <Route path={'/admin/surveys'}>
            <AdminSurveyEditor me={this.props.me} />
          </Route>
          <Route path={'/admin/surveys'}>
            <AdminSurveyEditor me={this.props.me} />
          </Route>
          <Route path={'/admin/settings'}>
            <AdminSetSettings me={this.props.me} />
          </Route>
          <Route path={'/admin/logs'}>
            <AdminLogs me={this.props.me} />
          </Route>
        </Switch>
      </div>
    ) : (
      <div className="admin_statistics">
        <h1>Access Denied</h1>
      </div>
    );
  }
}

export default AdminContainer;
