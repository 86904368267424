import React from 'react';
import FavoriteIcon from '../../assets/icons/favoriteIcon.svg';
import { UilCheck } from '@iconscout/react-unicons';

const ImageItem = ({ 
  uri, 
  area_affected, 
  favorite, 
  onClick, 
  multiSelect,
  selected
}) => {
  return (
    <div className='photo-gallery'>
        <div className='photo-container' onClick={onClick}>
          <img
            src={uri}
            alt={area_affected}
          />
          { favorite && (
            <div className='photo-favorite'>
              <img src={FavoriteIcon} alt="" />
            </div>
          )}
          { multiSelect && (
            <div className={`photo-selector ${selected && 'photo-selector-active'}`}>
              <UilCheck size="18" className='selected-icon' />
            </div>
          )}

        </div>
        <h6>{area_affected}</h6>
    </div>
  );
}

export default ImageItem;