import React from 'react';

export const StressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__stress">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__stress tracker-icon-image"
        transform="translate(8, 7) scale(.95)">
        <path d="M20.9028075,5.351292 C20.9248395,5.328612 20.9531664,5.316732 20.9731001,5.290812 L23.2906567,2.331612 C23.3682933,2.234412 23.3714407,2.091852 23.2906567,1.990332 C23.1993813,1.873692 23.0336167,1.855332 22.9203093,1.949292 L20.046707,4.336092 C20.0225767,4.355532 20.0015938,4.380372 19.9795618,4.404132 C20.3604007,4.635252 20.6761927,4.959252 20.9028075,5.351292" />
        <path d="M19.302445,4.115232 C19.3034942,4.083912 19.3139856,4.054752 19.3108382,4.022352 L18.9174096,0.242352 C18.9037707,0.118152 18.8082987,0.015552 18.6824016,0.001512 C18.5376199,-0.014688 18.4075262,0.093312 18.3928382,0.242352 L17.9994096,4.022352 C17.9962622,4.052592 17.9983605,4.086072 17.9994096,4.117392 C18.2102873,4.062312 18.4274599,4.022352 18.6551239,4.022352 C18.8796405,4.022352 19.0947147,4.060152 19.302445,4.115232" />
        <path d="M16.3364133,5.290704 C16.356347,5.314464 16.3794281,5.336064 16.4025093,5.358744 C16.6270258,4.966704 16.9417687,4.641624 17.3226075,4.409424 C17.3016247,4.385664 17.2879858,4.356504 17.2638555,4.335984 L14.3892041,1.949184 C14.2947813,1.870344 14.1562944,1.868184 14.057675,1.949184 C13.9454167,2.044224 13.9265321,2.214864 14.0178075,2.331504 L16.3364133,5.290704 Z" />
        <path d="M24.9498761,6.45246 L21.2778761,6.04746 C21.2485001,6.04422 21.2159767,6.04638 21.1845024,6.04746 C21.240107,6.26454 21.2778761,6.4881 21.2778761,6.72246 C21.2778761,6.95358 21.2411561,7.17498 21.1876498,7.38882 C21.218075,7.3899 21.2464018,7.4007 21.2778761,7.39746 L24.9498761,6.99246 C25.0705275,6.9795 25.1701961,6.88122 25.183835,6.75162 C25.1995721,6.60258 25.0946578,6.46866 24.9498761,6.45246" />
        <path d="M20.9735198,8.154648 C20.9535861,8.130888 20.9294558,8.108208 20.9074238,8.086608 C20.6829072,8.478648 20.3671152,8.803728 19.9873255,9.035928 C20.0083083,9.059688 20.0208981,9.088848 20.0460775,9.109368 L22.9196798,11.495088 C23.0151518,11.573928 23.1536386,11.577168 23.2522581,11.495088 C23.3645163,11.401128 23.3834009,11.230488 23.2910763,11.113848 L20.9735198,8.154648 Z" />
        <path d="M8.91760937,11.616156 C6.16990423,11.616156 3.9341808,13.917636 3.9341808,16.746156 C3.9341808,17.193276 3.5816688,17.556156 3.14732366,17.556156 C2.71297851,17.556156 2.36046651,17.193276 2.36046651,16.746156 C2.36046651,13.024476 5.30226309,9.996156 8.91760937,9.996156 C9.35195451,9.996156 9.70446651,10.359036 9.70446651,10.806156 C9.70446651,11.253276 9.35195451,11.616156 8.91760937,11.616156 M18.0986585,6.149196 L16.1126311,8.193636 C15.8020848,7.945236 15.4841945,7.735716 15.1788939,7.600716 C14.6711088,7.377156 14.3007614,7.385796 14.1108665,7.582356 L13.0910997,8.632116 C9.7212528,6.793956 5.44809394,7.333956 2.61121166,10.254276 C-0.870893486,13.839876 -0.870893486,19.652436 2.61121166,23.236956 C6.09541509,26.822556 11.7408528,26.822556 15.2240071,23.236956 C18.0608894,20.316636 18.5854608,15.918876 16.7998197,12.449916 L17.8195865,11.400156 C18.0094814,11.204676 18.0189237,10.823436 17.8017511,10.300716 C17.6706082,9.986436 17.4660254,9.658116 17.2257717,9.338436 L19.2117991,7.296156 C19.5181488,6.979716 19.5181488,6.465636 19.2117991,6.149196 C18.9044002,5.833836 18.4050082,5.833836 18.0986585,6.149196" />
      </g>
    </svg>
  );
};
