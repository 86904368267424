import queryString from 'query-string';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ClassEditor = ({ id, name, isActive, onCancel }) => {
  /** Only editable values + id */

  const [localValues, setNewValue] = useState({
    name: 'Jane Doe',
    zip: '00000',
    birth_year: '1990',
    gender: 'non_binary',
    ethnicity: 'white',
    eczema_relation: 'self',
    test: 1,
    persona: 'other',
    hispanic: 'false',
    referred: 'false',
  });

  const relationOptions = [
    { value: 'self', label: 'Mine' },
    { value: 'caregiver_child', label: 'My child' },
    { value: 'caregiver_other', label: 'Someone else' },
  ];

  const genderOptions = [
    { label: 'Non-binary', value: 'non_binary' },
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
  ];

  const ethnicityOptions = [
    {
      value: 'white',
      label: 'White',
    },
    {
      value: 'black',
      label: 'Black or African American',
    },
    {
      value: 'asian',
      label: 'Asian or Asian American',
    },
    {
      value: 'native',
      label: 'Native',
    },

    {
      value: 'islander',
      label: 'Islander',
    },
    {
      value: 'other',
      label: 'Other',
    },
    {
      value: 'multiple',
      label: 'Multiracial',
    },
    {
      value: 'none',
      label: 'Unknown',
    },
  ];

  const wasReferred = [
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ];

  const hispanicOptions = [
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ];

  const persona = [
    {
      value: 'nea',
      label: 'nea all the way',
    },
    {
      value: 'parent',
      label: 'passionate parent',
    },
    {
      value: 'help',
      label: 'theres help',
    },
    {
      value: 'point',
      label: 'whats the point',
    },
    {
      value: 'other',
      label: 'other',
    },
  ];

  const updateValue = e => {
    const newVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  return (
    <div className={'card card__active treatment-tile'}>
      <h2>[TEST] {name}</h2>
      <h2>Test Survey As:</h2>
      <ul>
        <li>
          <label>Name</label>
          <input
            type={'text'}
            value={localValues.name}
            name={'name'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Zip</label>
          <input
            type={'text'}
            pattern="\d{5}"
            maxLength="5"
            value={localValues.zip}
            name={'zip'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Birth Year</label>
          <input
            type={'text'}
            pattern="\d{4}"
            maxLength="4"
            value={localValues.birth_year}
            name={'birth_year'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Relationship</label>
          <div className={'multi-option'}>
            {relationOptions.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'eczema_relation'}
                  name={'eczema_relation'}
                  value={option.value}
                  type="radio"
                  id={`eczema_relation:${option.value}`}
                  checked={localValues.eczema_relation === option.value}
                />
                <label htmlFor={`eczema_relation:${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </li>
        <li>
          <label>Gender</label>
          <div className={'multi-option'}>
            {genderOptions.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'gender'}
                  name={'gender'}
                  value={option.value}
                  type="radio"
                  id={`gender:${option.value}`}
                  checked={localValues.gender === option.value}
                />
                <label htmlFor={`gender:${option.value}`}>{option.label}</label>
              </div>
            ))}
          </div>
        </li>
        <li>
          <label>Ethnicity</label>
          <div className={'multi-option'}>
            {ethnicityOptions.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'ethnicity'}
                  name={'ethnicity'}
                  value={option.value}
                  type="radio"
                  id={`ethnicity:${option.value}`}
                  checked={localValues.ethnicity === option.value}
                />
                <label htmlFor={`ethnicity:${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </li>
        <li>
          <label>Hispanic</label>
          <div className={'multi-option'}>
            {hispanicOptions.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'hispanic'}
                  name={'hispanic'}
                  value={option.value}
                  type="radio"
                  id={`hispanic:${option.value}`}
                  checked={localValues.hispanic === option.value}
                />
                <label htmlFor={`hispanic:${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </li>
        <li>
          <label>Was Referred</label>
          <div className={'multi-option'}>
            {wasReferred.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'referred'}
                  name={'referred'}
                  value={option.value}
                  type="radio"
                  id={`referred:${option.value}`}
                  checked={localValues.referred === option.value}
                />
                <label htmlFor={`referred:${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </li>
        <li>
          <label>Persona</label>
          <div className={'multi-option'}>
            {persona.map((option, i) => (
              <div key={i} className={'option'}>
                <input
                  onChange={updateValue}
                  htmlFor={'persona'}
                  name={'persona'}
                  value={option.value}
                  type="radio"
                  id={`persona:${option.value}`}
                  checked={localValues.persona === option.value}
                />
                <label htmlFor={`persona:${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </li>
      </ul>
      <div className={'treatment_actions'}>
        <button
          className={'button button-regular button__gray'}
          onClick={onCancel}>
          Back
        </button>
        <div className="stretch" />
        <Link
          target="_blank"
          to={`/surveys/${id}?${queryString.stringify(localValues)}`}
          className="button button-regular button__cobalt">
          Begin Survey as {localValues.name}
        </Link>
      </div>
    </div>
  );
};

export default ClassEditor;
