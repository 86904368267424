import React from 'react';
import ImageItem from './ImageItem';
import moment from 'moment';

const ImageGallery = ({ 
  images, 
  multiSelectActive, 
  selectedImages, 
  imagesOnClick, 
  sortCriteria, 
  severityFilters,
  favoriteFilter,
  bodyPartFilter, }) => {

  const sortImages = (filteredImages) => {  

    if(!filteredImages) return [];
    
    const compareDates = (a, b) => new Date(a.tracker_date) - new Date(b.tracker_date);
    const compareLevels = (a, b) => {
      const levelA = a?.level ?? Number.NEGATIVE_INFINITY;
      const levelB = b?.level ?? Number.NEGATIVE_INFINITY;
      return levelA - levelB;
    };
  
    switch (sortCriteria) {
      case 'newest':
        return filteredImages.sort((a, b) => compareDates(b, a));
      case 'oldest':
        return filteredImages.sort(compareDates);
      case 'highestPoScorad':
        return filteredImages.sort((a, b) => compareLevels(b, a));
      case 'lowestPoScorad':
        return filteredImages.sort(compareLevels);
      default:
        return filteredImages;
    }
  };

  const getPoScoradPerDay = (PoScorad) => {
    if (!PoScorad) return '';
    return (
      <>
        <span className="poscorad-item-severity-text"> - </span>
        <span className={`poscorad-item-severity ${getSeverityLabel(PoScorad)}`}></span>
        <span className="poscorad-item-severity-text">{PoScorad}</span>
        <span className="poscorad-item-severity-text">{getSeverityLabel(PoScorad)}</span>
      </>
    );
  };

  const getSeverityLabel = (PoScorad) => {
    if (!PoScorad) {
      return '';
    }
    if (PoScorad <= 25) {
      return 'Mild';
    }
    if (PoScorad <= 50) {
      return 'Moderate';
    }
    return 'Severe';
  };

  const filterImages = (images) => {
    const allFiltersFalse = !severityFilters.Mild && !severityFilters.Moderate && !severityFilters.Severe;

    if (allFiltersFalse && !favoriteFilter && bodyPartFilter.size === 0) {
      return images;
    }
  
    const severityFilter = (image) => {
      const severity = getSeverityLabel(image.level);
      return allFiltersFalse || severityFilters[severity];
    };
  
    const favoriteFilterFunc = (image) => {
      return !favoriteFilter || image.favorite;
    };
  
    const bodyPartFilterFunc = (image) => {
      return bodyPartFilter.size === 0 || bodyPartFilter.has(image.area_affected);
    };
  
    return images
      .filter(severityFilter)
      .filter(favoriteFilterFunc)
      .filter(bodyPartFilterFunc);
  };
  

  const sortedFilteredImages = sortImages();

  const groupImagesByDate = () => {
    const filteredImages = filterImages(images);
    const sortedImages = sortImages(filteredImages);
    return sortedImages.reduce((acc, image) => {
      const date = image.tracker_date.split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(image);
      return acc;
    }, {});
  };
  
  const groupedImages = groupImagesByDate(sortedFilteredImages);

  return (
    <>
      {Object.entries(groupedImages).map(([date, images]) => (
        <div key={date} className='imagesPerDay'>
          <div className='poscorad-history'>
            <span className="poscorad-item-severity-text">
              {moment(date).format('MMMM DD')}
            </span>
            {images.length > 0 && getPoScoradPerDay(images[0].level)}
          </div>
          <div className='image-container'>
            {images.map(image => (
              <ImageItem 
                key={image.imageid}
                uri={`/img/${image.imageid}`}
                area_affected={image.area_affected.split('_').map(s => s[0].toUpperCase() + s.substr(1)).join(' ')}
                favorite={image.favorite}
                onClick={() => imagesOnClick(image)}
                multiSelect={multiSelectActive}
                selected={selectedImages.has(image.imageid)}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default ImageGallery;
