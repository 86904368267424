import React from 'react';

export default function BodyPart(props) {
  const { id, className, drawn, onSelect, areas_affected, side} = props;
  return (
    <path
      role="checkbox"
      tabIndex="0"
      id={id}
      aria-label={id.replaceAll('_', ' ')}
      onClick={onSelect}
      onKeyDown={event => handleKeyDown(onSelect, event)}
      aria-checked={checkSelected(id, areas_affected)}
      className={`svg-skin__body-part ${checkSelected(id, areas_affected)
        ? 'svg-skin__body-part-selected'
        : ''
        } svg-skin__body-part-${side} svg-skin__${side}-${className}`}
      d={drawn}
    />
  )
}

const handleKeyDown = (onSelect, event) => {
    if (event.key === 'Enter' && onSelect) {
        onSelect(event)
    }
};

const checkSelected = (id, selectedArray) => {
    return selectedArray.includes(id);
};