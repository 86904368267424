import React from 'react';

export default function AngleRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M11.1225 8.46752L7.94252 5.28752C7.8728 5.21723 7.78985 5.16143 7.69845 5.12335C7.60706 5.08528 7.50903 5.06567 7.41002 5.06567C7.31101 5.06567 7.21298 5.08528 7.12159 5.12335C7.03019 5.16143 6.94724 5.21723 6.87752 5.28752C6.73783 5.42804 6.65942 5.61813 6.65942 5.81627C6.65942 6.01441 6.73783 6.2045 6.87752 6.34502L9.53252 9.00002L6.87752 11.655C6.73783 11.7955 6.65942 11.9856 6.65942 12.1838C6.65942 12.3819 6.73783 12.572 6.87752 12.7125C6.9476 12.782 7.03071 12.837 7.12209 12.8744C7.21347 12.9117 7.31131 12.9306 7.41002 12.93C7.50872 12.9306 7.60657 12.9117 7.69795 12.8744C7.78933 12.837 7.87244 12.782 7.94252 12.7125L11.1225 9.53252C11.1928 9.4628 11.2486 9.37985 11.2867 9.28846C11.3248 9.19706 11.3444 9.09903 11.3444 9.00002C11.3444 8.90101 11.3248 8.80298 11.2867 8.71159C11.2486 8.6202 11.1928 8.53724 11.1225 8.46752Z"
        fill="#092A6D"
      />
    </svg>
  );
}
