import React from 'react';
import { Link } from 'react-router-dom';
import {
  CompleteTrackerIcon,
  DietOrangeIcon,
  Instructions,
  ItchOrangeIcon,
  PainOrangeIcon,
  Settings,
  SkinOrangeIcon,
  SleepOrangeIcon,
  StressOrangeIcon,
  TreatmentsOrangeIcon,
  TriggersOrangeIcon,
  Write,
} from '../../assets/icons';
import {
  DietIcon,
  ItchIcon,
  PainIcon,
  SkinIcon,
  SleepIcon,
  StressIcon,
  TreatmentIcon,
  TriggersIcon,
} from '../../assets/icons/trackers/';
import Complete from '../../assets/images/complete.png';

const renderIcon = icon => {
  switch (icon) {
    case 'Itch':
      return <ItchOrangeIcon />;
    case 'Pain':
      return <PainOrangeIcon />;
    case 'Skin':
      return <SkinOrangeIcon />;
    case 'Sleep':
      return <SleepOrangeIcon />;
    case 'Stress':
      return <StressOrangeIcon />;
    case 'Treatment':
      return <TreatmentsOrangeIcon />;
    case 'Triggers':
      return <TriggersOrangeIcon />;
    case 'Diet':
      return <DietOrangeIcon />;
    default:
      return null;
  }
};

const TrackerHeader = ({
  title,
  write,
  icon,
  settings,
  edit,
  instructions,
  instructionsClass,
}) => (
  <div
    className={`tracker-header ${
      write ? 'tracker-header__active' : ''
    } ${instructionsClass}`}>
    <span className="tracker-icon-wrapper">
      <span className="tracker-badge-complete badge-complete">
        <CompleteTrackerIcon />
      </span>
      <span className="tracker-icon-header">{renderIcon(icon)}</span>
    </span>
    <h2 className="tracker-header-title title title__capitalized">{title}</h2>
    <div className="header-icons__right">
      {settings && (
        <Link to="/settings">
          <Settings />
        </Link>
      )}
      <span onClick={instructions} className="icon-instructions">
        <Instructions />
      </span>
      <span onClick={edit} className="icon-write">
        <Write />
      </span>
    </div>
  </div>
);

export default TrackerHeader;
