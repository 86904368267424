import React from 'react';

const Directions = ({ directionText, helpText, helpInstructionText, alignLeftText }) => (
  <div className="tracker-directions">
    <h3 className={`headers headers__black100  headers__14p600 ${alignLeftText ? 'headers__left' : 'headers__centered'}`}>
      {directionText}
    </h3>
    {helpText ? (
      <h5 className="help-tracker-text">
        {helpText}
        <br />
        <span>{helpInstructionText}</span>
      </h5>
    ) : null}
  </div>
);

export default Directions;
