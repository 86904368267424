import abbvie from './abbvie/abbvie-transparent.png';
import lilly from './LillyMaster 2/Red/LillyLogo_RGB_Red.png';
import pfizer from './Pfizer/pfizer_rgb_pos.png';
import sanofi from './Sanofi-RGN/Sanofi-Regeneron-Logo_vertical.png';

const sponsors = [
    {
        name: 'Lilly',
        img: lilly,
    },
    {
        name: 'Sanofi Genzyme Regeneron',
        img: sanofi,
    },
    {
        name: 'Pfizer',
        img: pfizer,
    },
    {
        name: 'abbvie',
        img: abbvie,
    },
];

export default sponsors;
