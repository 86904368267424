import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dietDailyData: [],
  loading: false,
  error: null,
};

const dietTrackerSlice = createSlice({
  name: 'dietTracker',
  initialState,
  reducers: {
    setDietTracker(state, action) {
      state.dietDailyData = action.payload;
    },
    updateDietTracker(state, action) {
      state.dietDailyData = state.dietDailyData.map(ddd => {
        if (ddd.id === action.payload.id) {
          return {
            ...ddd,
            ...action.payload,
          };
        }
        return ddd;
      });
    },
    deleteDietTracker(state, action) {
      state.dietDailyData = state.dietDailyData.filter(
        ddd => ddd.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const dietTrackerActions = dietTrackerSlice.actions;

export default dietTrackerSlice.reducer;
