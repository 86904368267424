import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';

// Defaults for setting initial state
const registrationDefaults = {
  userInformation: {
    __typename: 'userInformation',
    relation: '',
    gender: '',
    birthYear: '',
    zip: '',
    race: '',
    race_specified: '',
    feelings: '',
    nea_familiarity: '',
    was_referred: '',
    hispanic: '',
    eczemaTypes: [],
    providerFirstName: '',
    providerLastName: '',
    clinicName: '',
    accessCode: '',
    // this treatments default does not work because of this https://github.com/apollographql/apollo-client/issues/3517
    treatments: [
      {
        __typename: 'treatment',
        canDelete: false,
        names: [ 'Bathing' ],
        treatment: 'bathing',
        selected: true,
      },
      {
        __typename: 'treatment',
        canDelete: false,
        names: [ 'Moisturizing' ],
        treatment: 'moisturizing',
        selected: true,
      },
    ],
  },
  signUpForm: {
    __typename: 'signUpForm',
    email: '',
    userName: '',
    password: '',
    password_confirm: '',
    policyCheck: false,
    emailConsent: false,
  },
};

//Queries
const signUpData = gql`
  query userName {
    signUpForm @client {
      email
      userName
      password
      password_confirm
      policyCheck
      emailConsent
    }
  }
`;

const signUpFormQuery = gql`
  query {
    signUpForm @client {
      email
      userName
      password
      password_confirm
      policyCheck
      emailConsent
    }
  }
`;

const essentialInformationQuery = gql`
  query {
    userInformation @client {
      gender
      birthYear
      zip
      relation
      race
      race_specified
      feelings
      nea_familiarity
      was_referred
      hispanic
      providerFirstName
      providerLastName
      clinicName
      accessCode
      eczemaTypes
      treatments {
        canDelete
        names
        treatment
        selected
      }
    }
  }
`;

//Mutations

const saveEssentialInformationQuery = gql`
  mutation saveEssentialInformation(
    $gender: String!
    $birthYear: String!
    $race: String!
    $race_specified: String!
    $feelings: String!
    $nea_familiarity: String!
    $was_referred: Boolean!
    $hispanic: Boolean!
    $zip: String!
    $providerLastName: String
    $providerFirstName: String
    $accessCode: String
    $clinicName: String
    $relation: String
  ) {
    saveEssentialInformation(
      gender: $gender
      birthYear: $birthYear
      race: $race
      race_specified: $race_specified
      feelings: $feelings
      nea_familiarity: $nea_familiarity
      was_referred: $was_referred
      hispanic: $hispanic
      zip: $zip
      providerFirstName: $providerFirstName
      providerLastName: $providerLastName
      accessCode: $accessCode
      clinicName: $clinicName
      relation: $relation
    ) @client
  }
`;

const updateInputMutation = gql`
  mutation UpdateInput($name: String!, $value: String!) {
    updateInput(name: $name, value: $value) @client
  }
`;

//Resolvers

const updateInput = (_, { name, value }, { cache }) => {
  let currenState = cache.read({ query: signUpFormQuery });
  let updatedState = Object.assign({}, currenState);
  updatedState.signUpForm[name] = value;
  cache.writeData({ data: updatedState });

  return null;
};

const saveEssentialInformation = (
  _,
  {
    gender,
    birthYear,
    race,
    race_specified,
    feelings,
    nea_familiarity,
    was_referred,
    hispanic,
    zip,
    providerFirstName,
    providerLastName,
    accessCode,
    clinicName,
    relation,
  },
  { cache }
) => {
  let updatedData = {
    gender,
    birthYear,
    zip,
    race,
    race_specified,
    feelings,
    nea_familiarity,
    was_referred,
    hispanic,
    providerLastName,
    providerFirstName,
    accessCode,
    clinicName,
    relation,
    __typename: 'userInformation',
  };
  cache.writeData({
    data: {
      userInformation: updatedData,
    },
  });
  return null;
};

//store
const store = {
  defaults: registrationDefaults,
  mutations: {
    saveEssentialInformation,
    updateInput,
  },
};

//wrappers
const withRegistration = compose(
  graphql(signUpData, {
    props: ({ data: { loading, error, userName, signUpForm } }) => ({
      loading,
      error,
      userName,
      signUpForm,
    }),
  }),
  graphql(essentialInformationQuery, {
    props: ({ data: { loading, error, userInformation } }) => ({
      loading,
      error,
      userInformation,
    }),
  }),
  graphql(updateInputMutation, { name: 'updateInputSignUp' }),
  graphql(saveEssentialInformationQuery, {
    name: 'saveEssentialInformationMutation',
  })
);

export { store, withRegistration };
