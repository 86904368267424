import PropTypes from 'prop-types';
import React from 'react';

const TextArea = props => (
  <div className="form-group text-area">
    {props.validation ? <span className="msg-req">Required Field</span> : null}
    <textarea
      className={`form-input ` + props.className}
      style={props.resize ? null : { resize: 'none' }}
      name={props.name}
      rows={props.rows}
      value={props.content}
      onChange={props.controlFunc}
      placeholder={props.placeholder}
      disabled={props.disabled}
      maxLength={props.maxLength}
    />
  </div>
);

TextArea.propTypes = {
  rows: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  resize: PropTypes.bool,
  placeholder: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validation: PropTypes.bool,
  maxLength: PropTypes.string,
};

export default TextArea;
