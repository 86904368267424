import { flowRight as compose } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { Link, NavLink, withRouter } from 'react-router-dom';
import {
  HamburgerMenuBars,
  TrackIcon,
  LearnIcon,
  ManageIcon,
  NewSurveyIcon,
  NewsIcon,
} from '../assets/icons';
import * as Unicons from '@iconscout/react-unicons';
import LogoNEA from '../assets/logos/NEALogo.png';
import logout from '../helpers/auth/logout';
import MixpanelService, {
  MIXPANEL_DAY_FORMAT,
  MIXPANEL_TIME_FORMAT,
  MixpanelEvents,
} from '../libs/mixpanel';
import { logout as logoutMutation } from '../libs/graphql/mutations/user';
import { User } from '../libs/graphql/queries/user';
import analytics from '../libs/analytics';
import {
  WhatsappShareDiv,
  CopyLinkShareDiv,
  FacebookShareDiv,
  TwitterShareDiv,
  LinkedInShareDiv,
} from './shared/Sharing';

const withLogoutMutation = graphql(logoutMutation, {
  name: 'logoutMutation',
});

const withUser = graphql(User, {
  options: ({ id }) => ({ variables: { id } }),
  props: ({ data: { loading, error, user } }) => {
    return {
      loading,
      error,
      user: user,
    };
  },
  skip: ({ id }) => !id,
});

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      displayShareMenu: false,
      settingsSection: '',
      scrollFixed: false,
      showSettings: true,
      showSettingsOption: false,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.showDropdownSubMenu = this.showDropdownSubMenu.bind(this);
    this.showDropdownSettings = this.showDropdownSettings.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    const wrapper = document.querySelector('.wrapper');
    wrapper.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateWindowWidth);
  }
  
  componentWillUnmount() {
    const wrapper = document.querySelector('.wrapper');
    wrapper.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.updateWindowWidth);
  }
  
  handleScroll() {
    const wrapper = document.querySelector('.wrapper');
      if (wrapper.scrollTop > 50) {
          this.setState({ scrollFixed: true });
      } else {
          this.setState({ scrollFixed: false });
      }
  }

  showDropdownMenu(e) {
    e.stopPropagation();
    this.setState({ ...this.state, displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ ...this.state, displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  showDropdownSubMenu(e) {
    e.stopPropagation();
    const { displayShareMenu } = this.state;
    this.setState({ displayShareMenu: !displayShareMenu });
  }
  
  showDropdownSettings(e) {
    e.stopPropagation();
    const { showSettings } = this.state;
    this.setState({ showSettings: !showSettings });
  }

  updateWindowWidth() {
    const bigScreen = window.innerWidth >= 1024;
    this.setState({ showSettings: bigScreen, showSettingsOption: !bigScreen });
  }

  renderNavButtons() {
    let profileFlag = !!this.props.user && !!this.props.user.profileFlag;
    const surveyBadge =
      !this.props.location.pathname.includes('/surveys') &&
      this.props.userSurveys &&
      this.props.userSurveys.length > 0 ? (
        <b className={'has-survey'}>
          {this.props.userSurveys && this.props.userSurveys.length}
        </b>
      ) : (
        ''
      );
    return this.props.user && profileFlag ? (
      <ul className="nav-links">
        <li>
          <NavLink
            to="/dashboard"
            className="menu-link dropdown-link"
            activeClassName="active-link"
            isActive={() => this.props.location.pathname.includes('dashboard')}
            onClick={() =>
              MixpanelService.track(MixpanelEvents.TrackTabTapped)
            }>
            <TrackIcon className="icon page-icon-nav" />
            <span className="nav-titles dropdown-link">Track</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/trends"
            className="menu-link dropdown-link"
            activeClassName="active-link"
            isActive={() => this.props.location.pathname.includes('trends')}
            onClick={() =>
              MixpanelService.track(MixpanelEvents.LearnTabTapped, {
                DateSelected: moment.utc().format(MIXPANEL_DAY_FORMAT),
                TimeSelected: moment.utc().format(MIXPANEL_TIME_FORMAT),
              })
            }>
            <LearnIcon className="icon page-icon-nav" />
            <span className="nav-titles dropdown-link">Learn</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/provider-prep"
            className="menu-link dropdown-link"
            activeClassName="active-link"
            onClick={() =>
              MixpanelService.track(MixpanelEvents.ManageTabTapped)
            }>
            <ManageIcon className="icon page-icon-nav" />
            <span className="nav-titles dropdown-link">Manage</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/surveys"
            activeClassName="active-link"
            className={`menu-link dropdown-link`}
            onClick={() =>
              MixpanelService.track(MixpanelEvents.SurveyTabTapped)
            }>
            <NewSurveyIcon className="icon page-icon-nav" />
            <span className="nav-titles dropdown-link">Surveys</span>
            {surveyBadge}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/resources/recommended"
            activeClassName="active-link"
            className={`menu-link dropdown-link`}
            isActive={() => this.props.location.pathname.includes('resources')}
            onClick={() => {}}>
            <NewsIcon className="icon page-icon-nav" />
            <span className="nav-titles dropdown-link">News</span>
          </NavLink>
        </li>
        <li style={{display: this.state.showSettingsOption ? '' : 'none'}}>
          <div className="profile-visible">
            <div
              onClick={this.showDropdownSettings}
              className="menu-link dropdown-link">
              <Unicons.UilSetting size="18" /> 
              <span className="nav-titles dropdown-link">Settings</span>
              {this.state.showSettings ? 
                (<Unicons.UilAngleUp size="27" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>) :
                (<Unicons.UilAngleDown size="27" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>)
              }

            </div>
          </div>
        </li>
      </ul>
    ) : profileFlag ? (
      <ul className="nav-links">
        <li>
          <NavLink
            to="/about"
            className="menu-link dropdown-link"
            activeClassName="active-link">
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className="menu-link dropdown-link"
            activeClassName="active-link">
            Contact
          </NavLink>
        </li>
      </ul>
    ) : null;
  }

  logoutUser(e) {
    analytics().logEvent('log_out', {});
    MixpanelService.track(MixpanelEvents.LogOutTapped);

    e.preventDefault();
    e.stopPropagation();
    return this.props
      .logoutMutation({
        variables: {
          userid: this.props.id,
        },
      })
      .then(() => {
        MixpanelService.reset();
        logout(this.props.client, this.props.history);
      });
  }

  adminNav() {
    let navItem = this.props.user.admin ? (
      <li>
        <NavLink
          to="/admin/users"
          className="menu-link dropdown-link"
          activeClassName="active-link">
          <Unicons.UilFileShieldAlt size="18" /> 
          <span className="nav-titles dropdown-link">Admin</span>
          <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
        </NavLink>
      </li>
    ) : null;

    return navItem;
  }

  handleInstagramClick() {
    MixpanelService.track(MixpanelEvents.InstagramTaps);
    MixpanelService.setIncrementProfileProperty('InstagramTaps', 1);

    window.open('https://www.instagram.com/eczemawise/', '_blank');

    return false;
  }

  updateSettingsSection(section) {
    this.setState({settingsSection: section})
  }

  renderProfileOptions() {
    //this prevents login button from flashing on screen while user is still being fetched from db
    if (this.props.loading) {
      return null;
    }

    const surveyBadge =
      !this.props.location.pathname.includes('/surveys') &&
      this.props.userSurveys &&
      this.props.userSurveys.length > 0 ? (
        <b className={'has-survey'}>
          {this.props.userSurveys && this.props.userSurveys.length}
        </b>
      ) : (
        ''
      );

    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        this.showDropdownMenu(event);
      }
    };



    return this.props.user ? (
      <div className="profile-visible">
        <div
          tabIndex="0"
          role="button"
          aria-label="Menu"
          onKeyDown={handleKeyDown}
          onClick={this.showDropdownMenu}
          className="profile-desktop">
          <p>
            <span className="full-name-user">{this.props.user.name || ''}</span>
          </p>
          <HamburgerMenuBars />
          {!this.state.displayMenu && surveyBadge}
        </div>
        {this.state.displayMenu ? (
          <div className={`profile-dropdown ${this.state.scrollFixed ? 'scroll-fixed' : ''}`}>
            {this.renderNavButtons()}
            {this.state.showSettings ? 
              (<ul className="profile-dropdown-list">
              <li className="section-title">
                Profile                   
              </li>
              {this.adminNav()}
              <li>
                <NavLink
                  to={{ pathname: `/settings`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'UserInformation' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('UserInformation');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'UserInformation'
                  }}
                >
                  <Unicons.UilUserCircle size="18" /> 
                  <span className="nav-titles dropdown-link">User information</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{ pathname: `/settings`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'AccountInformation' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('AccountInformation');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'AccountInformation'
                  }}
                >
                  <Unicons.UilFileBookmarkAlt size="18" /> 
                  <span className="nav-titles dropdown-link">Account information</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{ pathname: `/settings`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'ZipCode' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('ZipCode');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'ZipCode'
                  }}
                >
                  <Unicons.UilLocationPinAlt size="18" /> 
                  <span className="nav-titles dropdown-link">Zip code</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{ pathname: `/settings`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'EditTreatments' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('EditTreatments');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'EditTreatments'
                  }}
                >
                  <Unicons.UilHeartMedical size="18" /> 
                  <span className="nav-titles dropdown-link">Edit your treatments</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{ pathname: `/gallery`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'AccountInformation' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('AccountInformation');
                  }}
                >
                  <Unicons.UilImage size="18" /> 
                  <span className="nav-titles dropdown-link">Your eczema gallery</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li className="section-title">
                Settings                   
              </li>
              <li>
                <NavLink
                  to={{ pathname: `/settings`,
                  props: {
                    updateSettingsSection: (section) => this.updateSettingsSection(section), 
                    settingsSection: 'Password' 
                  }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('Password');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'Password'
                  }}
                >
                  <Unicons.UilPadlock size="18" /> 
                  <span className="nav-titles dropdown-link">Change your password</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{ 
                    pathname: `/settings`,
                    props: {
                      updateSettingsSection: (section) => this.updateSettingsSection(section), 
                      settingsSection: 'DeleteAccount' 
                    }}}
                  activeClassName="active-link"
                  className="menu-link dropdown-link"
                  onClick={() => {
                    MixpanelService.track(MixpanelEvents.SettingsCogTapped)
                    this.updateSettingsSection('DeleteAccount');
                  }}
                  isActive={() => {
                    const { settingsSection } = this.state;
                    return this.props.location.pathname.includes('settings') && settingsSection === 'DeleteAccount'
                  }}
                >
                  <Unicons.UilLockAccess size="18" /> 
                  <span className="nav-titles dropdown-link">Delete your account</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li className="section-title">
                More                   
              </li>
              <li>
                <NavLink
                  to="/help"
                  activeClassName="active-link"
                  className="menu-link dropdown-link">
                  <Unicons.UilQuestionCircle size="18" /> 
                  <span className="nav-titles dropdown-link">Help</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </NavLink>
              </li>
              <li className="section-title">
                Our app                   
              </li>
              <li>
                <div className="profile-visible">
                  <div
                    onClick={this.showDropdownSubMenu}
                    className="menu-link dropdown-link">
                    <Unicons.UilShareAlt size="18" /> 
                    <span className="nav-titles dropdown-link">Share App</span>
                    {this.state.displayShareMenu ? 
                      (<Unicons.UilAngleUp size="27" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>) :
                      (<Unicons.UilAngleDown size="27" style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>)
                    }
                    
                  </div>
                </div>
              </li>
              {this.state.displayShareMenu ? (
              <>
                <li>
                  <CopyLinkShareDiv className="menu-link dropdown-link" />
                </li>
                <li>
                  <FacebookShareDiv className="menu-link dropdown-link" />
                </li>
                <li>
                  <TwitterShareDiv className="menu-link dropdown-link" />
                </li>
                <li>
                  <LinkedInShareDiv className="menu-link dropdown-link" />
                </li>
                <li>
                  <WhatsappShareDiv className="menu-link  dropdown-link" />
                </li>
              </>) : null}
              <li>
                <Link
                  className="menu-link dropdown-link"
                  onClick={this.handleInstagramClick}>
                  <Unicons.UilInstagram size="18" /> 
                  <span className="nav-titles dropdown-link">Visit Instagram</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  onClick={this.logoutUser.bind(this)}
                  className="dropdown-link">
                  <Unicons.UilSignout size="18" /> 
                  <span className="nav-titles dropdown-link">Logout</span>
                  <Unicons.UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
                </Link>
              </li>
              </ul>) : 
              null
            }
            
          </div>
        ) : null}
      </div>
    ) : (
      <Fragment>
        <div className="nav-login__wrapper">
          <NavLink to="/login" className="button-border">
            login
          </NavLink>
          <NavLink
            to="/signup/registration"
            className="button-border"
            onClick={() => MixpanelService.track(MixpanelEvents.SignUpTapped)}>
            sign up
          </NavLink>
        </div>
        <div className="profile-visible">
          <div onClick={this.showDropdownMenu} className="profile-desktop">
            <HamburgerMenuBars />
          </div>
          {this.state.displayMenu ? (
            <div className="profile-dropdown">
              <ul className="profile-dropdown-list">
                <li>
                  <NavLink
                    to="/signup/registration"
                    className="button-border-inverted"
                    onClick={() =>
                      MixpanelService.track(MixpanelEvents.SignUpTapped)
                    }>
                    sign up
                  </NavLink>
                </li>
                <br/>
                <li>
                  <NavLink to="/login" className="button-border-inverted">
                    login
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <header className="header">
        <div className="site-logo-wrapper">
          <Link
            to="/dashboard"
            onClick={() =>
              MixpanelService.track(MixpanelEvents.TrackTabTapped)
            }>
            <img
              src={LogoNEA}
              className={'logo-nea__horizontal_new'}
              alt="logo"
            />
          </Link>
        </div>

        <div className="desktop-nav-wrapper">
          <nav className="desktop-nav">
            <div>{this.renderNavButtons()}</div>
          </nav>
        </div>
        <div className="profile-options">{this.renderProfileOptions()}</div>
      </header>
    );
  }
}

Navigation.defaultProps = {
  user: null,
};

Navigation.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  surveyCount: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  client: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default compose(
  withApollo,
  withRouter,
  withUser,
  withLogoutMutation,
)(Navigation);
