import React from 'react';

export default function BathingIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#1D62ED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8661 16.7346H12.1343C12.0552 16.7346 11.9911 16.6705 11.9911 16.5914C11.9911 16.5124 12.0552 16.4483 12.1343 16.4483H19.8661C19.9451 16.4483 20.0093 16.5124 20.0093 16.5914C20.0093 16.6705 19.9451 16.7346 19.8661 16.7346ZM21.7275 15.3028H11.4184L11.4235 12.4392C11.4241 12.044 11.6246 11.6591 11.9911 11.4472C12.4968 11.1551 13.1285 11.2966 13.4722 11.7496L13.4115 11.7845C13.3571 11.816 13.3296 11.8802 13.3445 11.942L13.5884 12.9581C13.6108 13.0514 13.7162 13.0967 13.7992 13.0485L15.451 12.095C15.534 12.0474 15.5472 11.9334 15.4779 11.867L14.7196 11.1477C14.6738 11.1041 14.6039 11.0961 14.5495 11.1276L14.4641 11.1769C13.8003 10.178 12.4722 9.847 11.4184 10.4552C10.6847 10.8791 10.2838 11.6482 10.2827 12.4392L10.2729 15.3028C9.95678 15.3028 9.7002 15.5594 9.7002 15.8755C9.7002 16.1917 9.95678 16.4483 10.2729 16.4483V18.7392C10.2729 19.8113 11.0123 20.7042 12.0066 20.9539C12.0031 20.9802 11.9911 21.0032 11.9911 21.0301C11.9911 21.3462 12.2477 21.6028 12.5638 21.6028C12.88 21.6028 13.1366 21.3462 13.1366 21.0301H18.8638C18.8638 21.3462 19.1204 21.6028 19.4366 21.6028C19.7527 21.6028 20.0093 21.3462 20.0093 21.0301C20.0093 21.0032 19.9973 20.9802 19.9938 20.9539C20.9881 20.7042 21.7275 19.8113 21.7275 18.7392V16.4483C22.0436 16.4483 22.3002 16.1917 22.3002 15.8755C22.3002 15.5594 22.0436 15.3028 21.7275 15.3028Z"
        fill="white"
      />
    </svg>
  );
}
