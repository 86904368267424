import React from 'react';

//SVGs
import { PoscoradIndicator, ProgressCircle } from '../../assets/icons';
const SEVERITIES = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

const MAX_POSCORAD_VALUE = 103;

const getSeverityLabel = level => {
  if (!level) {
    return '';
  }
  if (level <= 25) {
    return SEVERITIES.Mild;
  }
  if (level <= 50) {
    return SEVERITIES.Moderate;
  }
  return SEVERITIES.Severe;
};

const PoScoradSeverity = props => {
  const {
    dateText,
    skinData,
    itchData,
    sleepData,
    poscoradData,
    newClassName,
  } = props;
  const completedTrackers =
    (skinData ? 1 : 0) + (itchData ? 1 : 0) + (sleepData ? 1 : 0);

  const poscoradPercentage =
    poscoradData != null ? (poscoradData.level * 103) / MAX_POSCORAD_VALUE : 0;

  return (
    <div className={'poscorad-tracker-element ' + newClassName}>
      <div className="poscorad-progress-icon">
        <ProgressCircle progress={completedTrackers} />
      </div>
      {poscoradData ? (
        <div className="poscorad-tracker-text">
          <h4 className="headers headers__black100">
            Your PO-SCORAD {dateText}
          </h4>
          <div className="poscorad-tracker-value-text">
            <span
              className={`poscorad-item-number poscorad-item-severity ${getSeverityLabel(
                poscoradData.level,
              )}`}></span>
            <span className="poscorad-item-number">{poscoradData.level}</span>
            <span className="poscorad-item-severity-text">
              {getSeverityLabel(poscoradData.level)}
            </span>
          </div>
        </div>
      ) : (
        <div className="poscorad-tracker-text">
          <h4 className="headers headers__black100">
            {' '}
            Keep tracking your PO-SCORAD
          </h4>
          <p>
            PO-SCORAD is a measure of eczema severity and is generated when you
            complete the skin, itch, and sleep trackers in the same day.
          </p>
        </div>
      )}
    </div>
    // <div className={'poscorad-tracker-element ' + newClassName}>
    //   {poscoradData ? (
    //     <>
    //       <div className="poscorad-tracker-text">
    //         <h4 className="headers headers__black100">
    //           Your PO-SCORAD {dateText}
    //         </h4>
    //         <div className="poscorad-tracker-value-text" style={{width: '640px'}}>
    //           <span
    //             className={`poscorad-item-number poscorad-item-severity ${getSeverityLabel(
    //               poscoradData.level,
    //             )}`}></span>
    //           <span className="poscorad-item-number-info">
    //             {poscoradData.level}
    //           </span>
    //           <span className="poscorad-item-severity-text-info">
    //             {getSeverityLabel(poscoradData.level)}
    //           </span>
    //           <div className="poscorad-tracker-text poscorad-history" style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
    //             <div className="poscorad-item poscorad-item-scale">
    //               <div className="poscorad-scales">
    //                 <div
    //                   className="poscorad-scales-indicator"
    //                   style={{ left: poscoradPercentage + '%', bottom: '5px' }}>
    //                   <PoscoradIndicator percentage={poscoradPercentage} />
    //                 </div>
    //                 <div className="poscorad-scales-item"></div>
    //                 <div className="poscorad-scales-item Moderate"></div>
    //                 <div className="poscorad-scales-item Severe"></div>
    //                 <div className="poscorad-scales-item Severe"></div>
    //               </div>
    //               <div className="poscorad-labels">
    //                 <label>0</label>
    //                 <label className={`severity ${SEVERITIES.Mild}`}>
    //                   {SEVERITIES.Mild}
    //                 </label>
    //                 <label className="number-center">25</label>
    //                 <label className={`severity ${SEVERITIES.Moderate}`}>
    //                   {SEVERITIES.Moderate}
    //                 </label>
    //                 <label className="number-center">50</label>
    //                 <label className={`severity ${SEVERITIES.Severe}`}>
    //                   {SEVERITIES.Severe}
    //                 </label>
    //                 <label className="number-right">103</label>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ) : (
    //     <div className={'poscorad-tracker-element ' + newClassName}>
    //       <div className="poscorad-progress-icon">
    //         <ProgressCircle progress={completedTrackers} />
    //       </div>
    //       <div className="poscorad-tracker-text">
    //         <h4 className="headers headers__black100">
    //           {' '}
    //           Keep tracking your PO-SCORAD
    //         </h4>
    //         <p>
    //           PO-SCORAD is a measure of eczema severity and is generated when
    //           you complete the skin, itch, and sleep trackers in the same day.
    //         </p>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default PoScoradSeverity;
