import React, { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-dropdown';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

const DateSelection = ({ onDateChange }) => {
  const DatesOptions = [
    { value: '2week', label: 'Last 2 weeks' },
    { value: 'month', label: 'Last month' },
    { value: 'year', label: 'Last year' },
    { value: 'today', label: 'Today' },
    { value: 'all', label: 'All' },
    { value: 'custom', label: 'Custom' },
  ];
  const wrapperRef = useRef(null); 

  const [selectedTime, setSelectedTime] = useState(DatesOptions[0]);
  const [dateFrom, setDateFrom] = useState(moment().subtract(14, 'd').toDate());
  const [dateTo, setDateTo] = useState(new Date());
  const [showCustomDatePickers, setShowCustomDatePickers] = useState(false);

  useEffect(() => {
    let fromDate = dateFrom;
    let toDate = new Date();
    switch (selectedTime.value) {
      case '2week':
        fromDate = moment().subtract(14, 'days').toDate();
        break;
      case 'month':
        fromDate = moment().subtract(1, 'months').toDate();
        break;
      case 'year':
        fromDate = moment().subtract(1, 'years').toDate();
        break;
      case 'today':
        fromDate = moment().toDate();
        break;
      case 'all':
        fromDate = moment().subtract(100, 'years').toDate();
        break;
      case 'custom':
        {
            fromDate = moment().subtract(14, 'days').toDate();
            setDateFrom(fromDate);
            setShowCustomDatePickers(true);
            return;
        }
      default:
        break;
    }

    setDateFrom(fromDate);
    setDateTo(toDate);
    setShowCustomDatePickers(false);
    onDateChange(fromDate, toDate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  const handleDropdownChange = (option) => {
    setSelectedTime(option);
  };

  const handleDatePickerChange = (dates) => {
    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);
    if (start && end) {
      onDateChange(start, end);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && 
        !wrapperRef.current.contains(event.target) &&
        event.target.className !== 'Dropdown-option') {
         setShowCustomDatePickers(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className='gallery-datepicker'>
      <Dropdown
        className="gallery-day-picker"
        classNamePrefix="form-select"
        options={DatesOptions}
        onChange={handleDropdownChange}
        value={selectedTime}
      />
      {showCustomDatePickers && (
        <div className="custom-date-picker" ref={wrapperRef}>
            <DatePicker
              inline
              selected={dateFrom}
              onChange={handleDatePickerChange}
              selectsRange
              startDate={dateFrom}
              endDate={dateTo}
              dateFormat="yyyy-MM-dd"
            />
        </div>
      )}
    </div>
  );
};

export default DateSelection;
