import React from 'react';

export const SleepIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__sleep">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__sleep tracker-icon-image"
        transform="translate(10, 10) scale(.9)">
        <path d="M9,11.25 C9,6.4395 12.763125,2.49075 17.566875,2.26125 C17.6265,2.257875 17.67825,2.217375 17.695125,2.16 C17.712,2.1015 17.6895,2.039625 17.64,2.005875 C15.751125,0.693 13.541625,0 11.25,0 C5.04675,0 0,5.04675 0,11.25 C0,17.45325 5.04675,22.5 11.25,22.5 C13.541625,22.5 15.751125,21.807 17.64,20.494125 C17.6895,20.460375 17.712,20.3985 17.695125,20.34 C17.67825,20.282625 17.6265,20.242125 17.566875,20.23875 C12.763125,20.00925 9,16.0605 9,11.25" />
        <path d="M22.359375,13.1067 L19.546875,13.1067 L22.401,9.34695 C22.419,9.3222 22.429125,9.291825 22.429125,9.26145 L22.429125,8.016075 C22.429125,7.93845 22.366125,7.87545 22.2885,7.87545 L17.398125,7.87545 C17.3205,7.87545 17.2575,7.93845 17.2575,8.016075 L17.2575,9.25245 C17.2575,9.330075 17.3205,9.393075 17.398125,9.393075 L20.129625,9.393075 L17.28675,13.15395 C17.267625,13.177575 17.2575,13.20795 17.2575,13.238325 L17.2575,14.484825 C17.2575,14.561325 17.3205,14.62545 17.398125,14.62545 L22.359375,14.62545 C22.437,14.62545 22.5,14.561325 22.5,14.484825 L22.5,13.247325 C22.5,13.1697 22.437,13.1067 22.359375,13.1067" />
        <path d="M11.8125,12.5299125 L11.8125,13.2825375 C11.8125,13.3601625 11.8755,13.4231625 11.953125,13.4231625 L15.046875,13.4231625 C15.1245,13.4231625 15.1875,13.3601625 15.1875,13.2825375 L15.1875,12.5861625 C15.1875,12.5085375 15.1245,12.4455375 15.046875,12.4455375 L13.2885,12.4455375 L15.11325,10.0549125 C15.132375,10.0312875 15.141375,10.0009125 15.141375,9.9705375 L15.141375,9.2179125 C15.141375,9.1402875 15.078375,9.0772875 15.00075,9.0772875 L11.953125,9.0772875 C11.8755,9.0772875 11.8125,9.1402875 11.8125,9.2179125 L11.8125,9.9142875 C11.8125,9.9919125 11.8755,10.0549125 11.953125,10.0549125 L13.65975,10.0549125 L11.840625,12.4444125 C11.822625,12.4691625 11.8125,12.4995375 11.8125,12.5299125" />
      </g>
    </svg>
  );
};
