import { selectAll } from 'd3-selection';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ActionSvg, FullArrowForward, QuestionMark } from '../../assets/icons';

class NoDataPrompt extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  clickNoData = () => {
    if (this.state.selected)
      this.setState({
        selected: false,
      });
    else {
      selectAll('.linechart__nodata').classed('selected', false); // clear all prompts; this is a hacky way to do it for componentization
      this.setState({
        selected: true,
      });
    }
  };

  render() {
    let missingIndicatorsFormatted = this.props.missingIndicators;

    return (
      <div
        className={
          this.state.selected
            ? 'linechart__nodata selected'
            : 'linechart__nodata'
        }
        style={{ top: this.props.y + 'px', left: this.props.x + 'px' }}>
        <button
          onClick={this.clickNoData}
          className="button button-action button-action__circle linechart__nodata__icon">
          <QuestionMark className="svg-button__shadow" />
          <ActionSvg className="svg-button__shadow" />
        </button>
        <div className="linechart__nodata__prompt shadow-default">
          <h4>No Data</h4>
          <p>
            Data for {missingIndicatorsFormatted} has not been logged today.
          </p>
          <Link
            className="link link__cobalt link__uppercase button__cobalt-text"
            to="/dashboard">
            Go to tracking <FullArrowForward />
          </Link>
        </div>
      </div>
    );
  }
}

export default NoDataPrompt;
