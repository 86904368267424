import React from 'react';

export default function MorningIcon({ isSelect, errorSelect }) {

  let fillCircle = isSelect ? '#1D62ED' : '#E7EAF1';
  let fillPath = isSelect ? '#E7EAF1' : errorSelect ? '#E95D42' : '#1D62ED';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fillCircle} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6256 9.58902C16.624 9.56836 16.6317 9.54923 16.6279 9.52857L16.1496 6.4677C16.1312 6.34679 16.0356 6.24502 15.9078 6.22513C15.7494 6.20064 15.6009 6.3093 15.5757 6.4677L15.0974 9.52857C15.0951 9.5477 15.099 9.56913 15.0974 9.58902C15.3484 9.55382 15.6025 9.52857 15.8627 9.52857C16.1228 9.52857 16.3753 9.55306 16.6256 9.58902Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5217 10.6286C12.5294 10.647 12.5432 10.6631 12.5523 10.6814C12.7513 10.5246 12.9587 10.3761 13.1844 10.246C13.4094 10.1159 13.6412 10.0111 13.8754 9.91697C13.8632 9.90014 13.8609 9.87948 13.8471 9.86341L11.9027 7.45144C11.8254 7.35656 11.693 7.316 11.5721 7.36268C11.4221 7.42083 11.3479 7.58918 11.406 7.7384L12.5217 10.6286Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8416 12.8695C10.8569 12.8818 10.8775 12.8886 10.8944 12.9001C10.9885 12.6644 11.0941 12.4326 11.2242 12.2068C11.3535 11.9819 11.5019 11.7745 11.6573 11.5763C11.6389 11.5671 11.6259 11.5518 11.6068 11.5442L8.71654 10.4277C8.60253 10.3833 8.46708 10.4155 8.38597 10.5165C8.28573 10.6412 8.30486 10.8241 8.42959 10.9243L10.8416 12.8695Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5065 15.6504C10.5256 15.6534 10.5471 15.6488 10.5677 15.6504C10.5318 15.3994 10.5065 15.1453 10.5065 14.8852C10.5065 14.6257 10.531 14.3725 10.567 14.1222C10.5463 14.1238 10.5272 14.1169 10.5065 14.1199L7.44565 14.5982C7.32474 14.6173 7.22297 14.7122 7.20384 14.84C7.17859 14.9984 7.28725 15.1476 7.44565 15.1721L10.5065 15.6504Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2803 14.598L21.2194 14.1197C21.2003 14.1167 21.1789 14.1213 21.1582 14.1197C21.1942 14.3707 21.2194 14.6248 21.2194 14.8849C21.2194 15.1444 21.1949 15.3976 21.159 15.6479C21.1796 15.6463 21.1988 15.6532 21.2194 15.6502L24.2803 15.1719C24.4012 15.1528 24.5022 15.0579 24.5221 14.9301C24.5474 14.7717 24.4387 14.6225 24.2803 14.598Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0664 11.5747C20.2233 11.7736 20.3717 11.981 20.5018 12.2067C20.6319 12.4317 20.7367 12.6636 20.8309 12.8977C20.8477 12.8855 20.8684 12.8824 20.8844 12.8694L23.2964 10.925C23.3913 10.8477 23.4311 10.7146 23.3844 10.5944C23.327 10.4444 23.1586 10.3702 23.0094 10.4276L20.1192 11.544C20.1008 11.5517 20.0848 11.5655 20.0664 11.5747Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1717 10.6796C19.1809 10.6612 19.1962 10.6482 19.2038 10.6291L20.3203 7.73884C20.3647 7.62405 20.3325 7.48938 20.2315 7.40826C20.1068 7.30725 19.9239 7.32715 19.8237 7.45188L17.8785 9.86385C17.8662 9.87915 17.8593 9.89905 17.8486 9.91588C18.0836 10.01 18.3154 10.1156 18.5412 10.2465C18.7661 10.3758 18.9735 10.5235 19.1717 10.6796Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.863 18.7109L13.1618 21.4122C12.9376 21.6364 12.9376 21.9998 13.1618 22.2241C13.386 22.4483 13.7487 22.4483 13.9729 22.2241L15.2891 20.9079V24.1141C15.2891 24.4309 15.5462 24.6881 15.863 24.6881C16.1798 24.6881 16.4369 24.4309 16.4369 24.1141V20.9079L17.7531 22.2241C17.8648 22.3358 18.0118 22.3924 18.1587 22.3924C18.3056 22.3924 18.4525 22.3358 18.5642 22.2241C18.7885 21.9998 18.7885 21.6364 18.5642 21.4122L15.863 18.7109Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7067 16.7979H8.01971C7.70291 16.7979 7.4458 17.055 7.4458 17.3718C7.4458 17.6886 7.70291 17.9457 8.01971 17.9457H23.7067C24.0235 17.9457 24.2806 17.6886 24.2806 17.3718C24.2806 17.055 24.0235 16.7979 23.7067 16.7979Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.863 10.6763C13.5383 10.6763 11.6543 12.5602 11.6543 14.885C11.6543 15.1467 11.6818 15.4015 11.727 15.6502H19.999C20.0441 15.4015 20.0717 15.1467 20.0717 14.885C20.0717 12.5602 18.1877 10.6763 15.863 10.6763Z"
        fill={fillPath}
      />
    </svg>
  );
}
