import React from 'react';
import moment from 'moment';
import GalleryIcon from '../../assets/icons/gallery-icon.svg'; 
import { useHistory } from "react-router-dom";

const getSeverityLabel = (severity) => {
  if (!severity) return '';
  if (severity <= 25) return 'Mild';
  if (severity <= 50) return 'Moderate';
  return 'Severe';
};

const ImageDetails = ({ selectedImage }) => {
  const history = useHistory();

  if (!selectedImage) {
    return (
      <div className='default-details'>
        <div className='default-img-container'>
          <img src={GalleryIcon} alt="No selected" />
        </div>
        <span>Select a photo to view the details</span>
      </div>
    );
  }

  return (
    <div className='details'>
      <div className='image-details'>
        <img src={`/img/${selectedImage.imageid}`} alt={selectedImage.area_affected} />
      </div>
      <h4>Date</h4>
      <span>
        {moment(selectedImage.tracker_date).utc().format('ddd, MMMM DD, YYYY')}
        &nbsp;
        <span 
          className="details-link" 
          onClick={e => {
            e.preventDefault();
            history.push({
              pathname: '/trends',
              state: { selectedDate: moment(selectedImage.tracker_date).utc().format('YYYY-MM-DD') },
            });
          }}
        >
          <span className="nav-titles dropdown-link">Visit day</span>
        </span>
      </span>
      <h4>Body Part</h4>
      <span>{selectedImage.area_affected.split('_').map(s => s[0].toUpperCase() + s.substr(1)).join(' ')}</span>
      {selectedImage.level && (
        <>
          <h4>PO-Scorad severity</h4>
          <span>{getSeverityLabel(selectedImage.level)}</span>
        </>
      )}
    </div>
  );
};

export default ImageDetails;
