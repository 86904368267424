import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// REDUCERS
import appointments from './appointments';
import dietTracker from './diet-tracker';
import triggerTracker from './trigger-tracker';
import dailyUserData from './daily-user-data';
import flareTracker from './flare-tracker';


// CONFIGURE STORE
const store = configureStore({
  reducer: {
    appointments,
    dietTracker,
    triggerTracker,
    dailyUserData,
    flareTracker
  },
});

// EXPORTS

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export default store;
