import React from 'react';

export default function Write() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="12.5" fill="rgba(42, 41, 44, 0.25)" />
      <path
        d="M19.6667 8.8266C19.6672 8.73886 19.6503 8.65188 19.6172 8.57066C19.584 8.48943 19.5351 8.41556 19.4733 8.35326L16.6467 5.5266C16.5844 5.46481 16.5105 5.41593 16.4293 5.38275C16.348 5.34957 16.2611 5.33276 16.1733 5.33326C16.0856 5.33276 15.9986 5.34957 15.9174 5.38275C15.8362 5.41593 15.7623 5.46481 15.7 5.5266L13.8133 7.41326L6.52666 14.6999C6.46487 14.7622 6.41599 14.8361 6.38281 14.9173C6.34963 14.9985 6.33282 15.0855 6.33332 15.1733V17.9999C6.33332 18.1767 6.40356 18.3463 6.52859 18.4713C6.65361 18.5964 6.82318 18.6666 6.99999 18.6666H9.82666C9.91994 18.6717 10.0133 18.6571 10.1005 18.6238C10.1878 18.5905 10.2671 18.5392 10.3333 18.4733L17.58 11.1866L19.4733 9.33326C19.5342 9.26865 19.5838 9.19428 19.62 9.11326C19.6264 9.06012 19.6264 9.0064 19.62 8.95326C19.6231 8.92223 19.6231 8.89096 19.62 8.85993L19.6667 8.8266ZM9.55332 17.3333H7.66666V15.4466L14.2867 8.8266L16.1733 10.7133L9.55332 17.3333ZM17.1133 9.77326L15.2267 7.8866L16.1733 6.9466L18.0533 8.8266L17.1133 9.77326Z"
        fill="rgba(247, 247, 254, 1)"
      />
    </svg>
  );
}
