import gql from 'graphql-tag';

export const AllUserData = gql`
  query allUserData($id: ID!, $startDate: Date!, $endDate: Date!) {
    allUserData(id: $id, startDate: $startDate, endDate: $endDate) {
      id
      created_date
      tracker_date
      itchData {
        level
      }
      painData {
        level
      }
      sleepData {
        level
      }
      stressData {
        level
      }
      skinData {
        score
      }
      treatmentData {
        id
      }
      triggerData {
        data {
          selected
        }
      }
      dietData {
        id
      }
    }
  }
`;

export const DailyUserData = gql`
  query dailyUserData($id: ID!, $today: String!, $readOnly: Boolean) {
    dailyUserData(id: $id, today: $today, readOnly: $readOnly) {
      id
      updated_date
      tracker_date
      itchData {
        level
        most_recent_save_date
      }
      painData {
        level
        most_recent_save_date
      }
      sleepData {
        level
        most_recent_save_date
      }
      stressData {
        level
        most_recent_save_date
      }
      poscoradData {
        level
      }
      treatmentData {
        id
        data {
          type
          name
          id
          selected
          morning
          afternoon
          evening
          bedtime
        }
        notes
        id
      }
      weatherData {
        temp_hi
        temp_low
        zip
        ragweed
        grass
        mold
        humidity
        city
        state
      }
      triggerData {
        data {
          type
          typeid
          selected
          trigger
          trigger_id
          trigger_selected
        }
      }
      skinData {
        id
        dryness
        redness
        swelling
        oozing_scabs
        scratch_marks
        thickening_skin
        areas_selected
        infections
        infections_notes
        notes
        areas_affected
        score
      }
      dietData {
        id
        foods
        cups_water
        custom_foods
      }
      pictures {
          area_affected
          images {
            id
            uri
          }
        }
    }

    triggerOptions {
      id
      type
      options {
        trigger_name
        typeid
        id
      }
    }
  }
`;

export const triggerOptions = gql`
  query triggerOptions {
    triggerOptions {
      id
      type
      options {
        trigger_name
        typeid
        id
      }
    }
  }
`;

export const PictureFormOptions = gql`
  query pictureFormOptions {
    pictureForm {
      options {
        id
        option
        selection
      }
      optionValues {
        label
        value
      }
    }
  }
`;

export const UserSurveys = gql`
  query userSurveys($userid: ID!) {
    userSurveys(userid: $userid) {
      id
      name
      uri
      name
      description
      time_to_complete
      started
      completed
      available_on
      end_date
    }
  }
`;

export const TreatmentGroups = gql`
  query TreatmentGroups {
    availableTreatmentOptionsGroups {
      id
      treatment_group
      optionNames
    }
  }
`;

export const TreatmentOptionsAndMetadata = gql`
  query TreatmentOptionsAndMetadata {
    availableTreatmentOptions {
      id
      selectionId
      name
      pronunciation
      generic
      default_checked,
      drug_names
      brand_names
      group_id
      class_id
      group
      class
      metadata_id
      formulation
    }

    availableTreatmentOptionsMetadata {
      id
      who
      how
      use
      effects
      efficacy
      cost
    }

    availableTreatmentOptionsClasses {
      id
      treatment_group_id
      treatment_class
      formulation
    }

    availableTreatmentOptionsGroups {
      id
      treatment_group
    }
  }
`;

export const AllUserSkinData = gql`
  query allUserData($id: ID!, $startDate: Date!, $endDate: Date!) {
    allUserData(id: $id, startDate: $startDate, endDate: $endDate) {
      id
      created_date
      tracker_date
      skinData {
        id
        areas_affected
      }
    }
  }
`;