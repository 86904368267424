import React from 'react';

export default function Download() {
  return (
    <svg className="icon icon-download" viewBox="0 0 28 30">
      <g className="icon-fill" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M25.5,27 L1.5,27 C0.6705,27 0,27.672 0,28.5 C0,29.328 0.6705,30 1.5,30 L25.5,30 C26.3295,30 27,29.328 27,28.5 C27,27.672 26.3295,27 25.5,27"
          id="Fill-1"
        />
        <path
          d="M12.438,23.55915 C12.4395,23.55915 12.4395,23.56065 12.441,23.56065 L13.5,24.62115 L14.559,23.56065 C14.5605,23.56065 14.5605,23.55915 14.562,23.55915 L22.0605,16.06065 C22.647,15.47415 22.647,14.52615 22.0605,13.93965 C21.474,13.35315 20.526,13.35315 19.9395,13.93965 L15,18.87915 L15,1.50015 C15,0.67215 14.3295,0.00015 13.5,0.00015 C12.6705,0.00015 12,0.67215 12,1.50015 L12,18.87915 L7.0605,13.93965 C6.474,13.35315 5.526,13.35315 4.9395,13.93965 C4.353,14.52615 4.353,15.47415 4.9395,16.06065 L12.438,23.55915 Z"
          id="Fill-3"
        />
      </g>
    </svg>
  );
}
