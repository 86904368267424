import React, { Fragment } from 'react';
import { useQuery } from 'react-apollo';
import ReactTable from 'react-table';
import { AdminStats } from '../../libs/graphql/queries/admin';

const statMap = stat => {
  let label;
  switch (stat) {
    case 'active_users':
      label = 'Active Users';
      break;
    case 'user_profiles':
      label = 'User Profiles';
      break;
    case 'registered_users':
      label = 'Registered Users';
      break;
    case 'deleted_users':
      label = 'Deleted Users';
      break;
    case 'itch_level':
      label = 'Itch Level';
      break;
    case 'pain_level':
      label = 'Pain Level';
      break;
    case 'sleep_level':
      label = 'Sleep Level';
      break;
    case 'stress_level':
      label = 'Stress Level';
      break;
  }
  return label;
};

const AdminStatsComponent = ({}) => {
  const { loading, error, data } = useQuery(AdminStats);

  if (loading || error) return null;

  const { user, tracker, activity } = data.adminStats;

  return (
    <Fragment>
      <h2 className="title title__cobalt title__semibold">Tracker Activity</h2>
      <ReactTable
        data={activity}
        className="-striped -highlight"
        defaultPageSize={10}
        columns={[
          {
            id: 'trackerDate',
            Header: 'Date',
            accessor: d => d.tracker_date,
          },
          {
            id: 'statValue',
            Header: 'Number of Tracker Entries',
            accessor: d => d.count,
          },
        ]}
      />
      <h2 className="title title__cobalt title__semibold">
        Tracker Statistics
      </h2>
      <ReactTable
        data={tracker}
        defaultPageSize={tracker.length}
        showPaginationBottom={false}
        className="-striped -highlight"
        columns={[
          {
            id: 'statType',
            Header: 'Variable',
            accessor: d => statMap(d.stat),
          },
          {
            id: 'statValue',
            Header: 'Average',
            accessor: d => (d.avg_level ? d.avg_level.toFixed(3) : 'N/A'),
          },
          {
            id: 'sigma',
            Header: 'Stddev',
            accessor: d => (d.sigma ? d.sigma.toFixed(3) : 'N/A'),
          },
          {
            id: 'sampleSize',
            Header: 'Sample Size',
            accessor: d => d.count,
          },
        ]}
      />
      <h2 className="title title__cobalt title__semibold">User Statistics</h2>
      <ReactTable
        data={user}
        defaultPageSize={user.length}
        showPaginationBottom={false}
        className="-striped -highlight"
        columns={[
          {
            id: 'statType',
            Header: 'Variable',
            accessor: d => statMap(d.stat),
          },
          {
            id: 'statValue',
            Header: 'Count',
            accessor: d => d.count,
          },
        ]}
      />
    </Fragment>
  );
};

export default AdminStatsComponent;
