import React from 'react';

export default function Temperature() {
  return (
    <svg
      className="icon icon-temperature"
      width="14px"
      height="24px"
      viewBox="0 0 14 24">
      <g className="svg-group">
        <path
          d="M7.0002,20.5 C5.0702,20.5 3.5002,18.93 3.5002,17 C3.5002,15.42 4.5592,14.097 6.0002,13.663 L6.0002,3.5 C6.0002,2.949 6.4492,2.5 7.0002,2.5 C7.5512,2.5 8.0002,2.949 8.0002,3.5 L8.0002,13.663 C9.4402,14.097 10.5002,15.42 10.5002,17 C10.5002,18.93 8.9302,20.5 7.0002,20.5 M10.5002,10.944 L10.5002,3.5 C10.5002,1.567 8.9332,0 7.0002,0 C5.0672,0 3.5002,1.567 3.5002,3.5 L3.5002,10.944 C1.4102,12.155 0.0002,14.411 0.0002,17 C0.0002,20.866 3.1342,24 7.0002,24 C10.8662,24 14.0002,20.866 14.0002,17 C14.0002,14.411 12.5902,12.155 10.5002,10.944"
          id="Fill-1"
        />
        <polygon points="5.376 5.5 8.626 5.5 8.626 4.5 5.376 4.5" />
        <polygon points="5.376 8.5 8.626 8.5 8.626 7.5 5.376 7.5" />
        <polygon points="5.376 11.5 8.626 11.5 8.626 10.5 5.376 10.5" />
      </g>
    </svg>
  );
}
