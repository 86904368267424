import React from 'react';

export default function ActionSvg() {
  return (
    <svg
      className="button-action__transparent svg-button__shadow"
      viewBox="0 0 41 41">
      <g
        className="svg-action__circle-outline"
        strokeWidth="1"
        fillRule="evenodd"
        transform="translate(0.53000000, 0.53000000)">
        <circle
          className="svg-action__circle-background svg-button__background"
          cx="20"
          cy="20"
          r="20"
        />
        <g
          transform="translate(12.000000, 12.000000)"
          strokeLinecap="round"
          strokeWidth="1">
          <path
            className="svg-action__horizontal svg-action__add-lines"
            d="M0,8 L16,8"
            id="Line-5"
          />
          <path
            className="svg-action__vertical svg-action__add-lines"
            d="M8,16 L8,0"
            id="Line-5"
          />
          <path
            className="svg-action__x-left-right svg-action__delete-lines"
            d="M0,0 L15.8788891,15.8788891"
          />
          <path
            className="svg-action__x-right-left svg-action__delete-lines"
            d="M0,0 L15.8788891,15.8788891"
            transform="translate(7.939445, 7.939445) scale(-1, 1) translate(-7.939445, -7.939445)"
          />
        </g>
      </g>
    </svg>
  );
}
