import React from 'react';
import BodyPart from './SkinBodyPart';

export default function SkinBodyBack(props) {
  return (
    <svg className="svg-skin-body svg-skin-body__back" viewBox="0 0 266 556">
      <g
        className="svg-skin-body__back-group"
        transform="translate(1.000000, 1.000000)"
        strokeWidth="0.5">
        <BodyPart
          side="back"
          id="posterior_left_ankle_foot"
          className="foot__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M86.2451,469.4603 C88.5091,480.8753 91.1121,490.9893 91.9351,499.6373 C92.0781,501.1303 93.9661,516.3833 93.9981,521.1793 C93.4311,520.5023 92.4141,519.3403 91.5861,518.7873 C89.2561,517.2343 87.6751,519.7313 87.5281,520.2623 C87.4861,520.4153 87.4581,520.5883 87.4531,520.7673 C86.6531,520.2293 85.9721,520.1463 84.9611,520.6003 C84.5581,520.7813 84.3441,521.2973 84.2671,521.8343 C83.3061,521.2443 82.2041,521.2793 81.6771,521.6953 C81.2391,522.0413 81.0611,522.5743 81.0981,523.1063 C80.7961,522.9493 80.4151,522.8473 79.8771,522.8293 C78.9211,522.7973 78.4101,523.8693 78.6001,524.6353 C78.3461,524.5713 78.1111,524.5413 77.8901,524.5333 C77.8641,524.5253 77.8401,524.5123 77.8131,524.5063 C76.9231,524.3053 75.9341,524.7603 75.8341,525.6483 C75.6111,526.4913 75.9951,527.7893 77.6691,528.7463 C78.7401,529.3583 79.7831,531.9733 82.3361,534.0373 C85.3441,536.4723 86.5861,535.9123 91.1691,540.0793 C93.9811,542.6363 98.3771,552.3293 101.2111,553.6623 C104.0441,554.9963 110.8361,553.7463 113.6691,551.0793 C116.5021,548.4123 114.2461,543.3453 113.6691,539.7463 C113.0921,536.1483 114.3731,534.7413 114.3101,528.3043 C114.2981,527.0283 113.8461,520.0773 113.9981,517.3663 C114.3091,511.8213 117.8411,488.7933 120.3221,469.9693 C116.2981,471.0323 110.8621,471.9713 104.1851,471.9713 C96.4551,471.9713 90.3811,470.7133 86.2451,469.4603"
        />
        <BodyPart
          side="back"
          id="posterior_left_leg"
          className="knee__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M78.6099,349.179 C78.7079,350.054 78.7999,350.926 78.9079,351.804 C81.2269,370.744 84.4409,390.27 85.7809,398.162 C85.6589,400.082 85.4949,402.282 85.2689,404.804 C84.2689,415.971 81.2689,425.804 82.9359,446.804 C83.5729,454.832 84.8439,462.394 86.2449,469.46 C90.3809,470.713 96.4549,471.971 104.1859,471.971 C110.8619,471.971 116.2979,471.033 120.3219,469.969 C120.8339,466.09 121.3009,462.386 121.6859,459.054 C123.5189,443.169 124.0189,419.721 121.2689,412.554 C123.0189,406.554 123.6979,399.443 123.2689,392.554 C123.3609,386.027 122.5719,376.943 123.6579,367.304 C124.3519,361.149 125.1049,355.906 125.8459,351.054 C112.6819,355.971 93.9709,355.485 78.6099,349.179"
        />
        <BodyPart
          side="back"
          id="left_hand"
          className="hand__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M39.8941,275.054 C43.0711,276.561 42.7691,282.29 42.7691,284.304 C42.7691,287.217 41.3571,291.364 40.1761,294.327 C40.1021,295.284 39.9361,296.966 39.6021,298.429 C39.1021,300.616 38.2901,302.741 38.2901,302.741 C38.2901,302.741 38.6021,307.22 38.1861,309.804 C37.7691,312.387 38.6021,321.22 35.9361,321.22 C33.2691,321.22 33.3521,318.637 33.2691,314.804 C33.1921,311.251 32.9231,309.194 32.4621,306.12 C32.3011,306.094 32.1371,306.074 31.9701,306.067 C31.9461,307.585 31.8501,312.11 31.5401,313.866 C31.1651,315.991 30.8521,318.679 30.7901,322.241 C30.7271,325.804 29.5401,327.116 27.2901,327.179 C25.0401,327.241 25.4151,323.116 25.5401,320.241 C25.6651,317.366 25.4771,317.554 25.4151,313.616 C25.3521,309.679 25.7601,306.714 25.7991,306.246 C25.7991,306.246 25.7981,306.246 25.7971,306.246 C25.3481,307.313 24.0771,310.645 23.4771,312.804 C22.8521,315.054 21.2271,319.616 20.4771,320.804 C19.7271,321.991 19.4771,327.929 16.4151,327.366 C13.3521,326.804 15.3521,321.991 15.9771,319.804 C16.6021,317.616 17.4771,314.616 17.7901,312.741 C18.1021,310.866 19.9501,304.578 20.0331,304.346 C19.9711,304.423 17.2901,308.554 16.5401,309.929 C15.7901,311.304 14.9151,314.429 14.1651,317.054 C13.4151,319.679 11.9771,323.491 9.6651,322.929 C7.3521,322.366 8.7271,317.929 9.1651,316.304 C9.6021,314.679 11.2271,310.991 11.4771,308.304 C11.7271,305.616 12.9771,302.366 12.9771,302.366 C12.9771,302.366 13.4921,299.303 14.1351,296.949 C14.3101,295.106 15.1021,292.471 15.1021,292.471 C15.1021,292.471 13.7691,293.471 12.1021,294.304 C10.4361,295.137 7.2691,299.137 4.1021,299.304 C0.9361,299.47 -0.3979,298.137 0.1021,296.637 C0.6021,295.137 4.7691,292.971 7.6021,289.637 C10.4361,286.304 15.2691,281.304 19.1021,278.804 C20.1621,278.113 20.5831,277.803 23.0111,276.347 C26.9771,273.971 34.1861,272.346 39.8941,275.054"
        />
        <BodyPart
          side="back"
          id="posterior_left_forearm"
          className="forearm__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M34.1026,230.054 C31.0196,240.221 27.3526,262.554 26.4356,268.137 C26.0326,270.595 24.3966,273.951 23.0596,276.321 C27.0346,273.959 34.2076,272.356 39.8946,275.054 C41.0696,275.612 41.7666,276.748 42.1816,278.06 C45.1266,270.748 50.8386,256.961 56.6026,245.054 C61.1416,235.677 64.4846,227.85 66.8736,221.406 C57.6956,219.766 49.0836,216.735 40.9376,212.555 C37.5506,218.78 36.1496,223.302 34.1026,230.054"
        />
        <BodyPart
          side="back"
          id="posterior_left_upper_arm"
          className="elbow__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M54.02,152.0369 L51.769,168.6379 C50.269,180.8039 48.201,192.2729 47.793,201.5169 C44.791,205.9479 42.623,209.4579 40.937,212.5559 C49.083,216.7349 57.696,219.7659 66.873,221.4059 C69.133,215.3089 70.54,210.4479 71.269,206.7209 C71.659,204.7269 72.506,202.3719 72.269,200.0539 C73.943,195.6679 74.883,190.8639 76.269,186.8039 C77.715,182.5699 79.496,177.9389 81.233,173.1999 C71.508,168.1189 62.472,160.9589 54.02,152.0369"
        />
        <BodyPart
          side="back"
          id="posterior_left_shoulder"
          className="shoulder__left"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M86.8047,126.4232 C86.5137,116.7622 85.6547,107.0362 84.4077,97.3492 C84.0437,97.4812 83.6877,97.6082 83.3527,97.7202 C75.1857,100.4702 67.0187,105.5542 63.5187,110.8042 C60.0187,116.0542 56.1107,124.5522 55.7687,139.1372 L54.0197,152.0372 C62.4717,160.9592 71.5077,168.1182 81.2327,173.2002 C82.3537,170.1432 83.4547,167.0422 84.4357,163.9702 C84.4487,164.1512 84.4677,164.3442 84.4837,164.5292 C86.5687,152.1192 87.1937,139.3312 86.8047,126.4232"
        />
        <BodyPart
          side="back"
          id="posterior_right_ankle_foot"
          className="foot__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M177.959,469.4603 C175.696,480.8753 173.092,490.9893 172.269,499.6373 C172.126,501.1303 170.239,516.3833 170.207,521.1793 C170.774,520.5023 171.791,519.3403 172.619,518.7873 C174.949,517.2343 176.529,519.7313 176.676,520.2623 C176.718,520.4153 176.746,520.5883 176.751,520.7673 C177.551,520.2293 178.233,520.1463 179.244,520.6003 C179.647,520.7813 179.861,521.2973 179.938,521.8343 C180.898,521.2443 182,521.2793 182.528,521.6953 C182.965,522.0413 183.143,522.5743 183.107,523.1063 C183.408,522.9493 183.79,522.8473 184.327,522.8293 C185.283,522.7973 185.795,523.8693 185.605,524.6353 C185.859,524.5713 186.094,524.5413 186.314,524.5333 C186.341,524.5253 186.364,524.5123 186.391,524.5063 C187.281,524.3053 188.271,524.7603 188.371,525.6483 C188.593,526.4913 188.21,527.7893 186.535,528.7463 C185.464,529.3573 184.422,531.9733 181.869,534.0373 C178.86,536.4713 177.619,535.9123 173.035,540.0793 C170.223,542.6363 165.827,552.3293 162.994,553.6623 C160.16,554.9963 153.369,553.7463 150.535,551.0793 C147.702,548.4123 149.958,543.3443 150.535,539.7463 C151.113,536.1483 149.832,534.7413 149.894,528.3043 C149.906,527.0283 150.358,520.0773 150.207,517.3663 C149.896,511.8213 146.363,488.7933 143.882,469.9693 C147.907,471.0323 153.343,471.9713 160.019,471.9713 C167.75,471.9713 173.823,470.7133 177.959,469.4603"
        />
        <BodyPart
          side="back"
          id="posterior_right_leg"
          className="knee__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M185.5947,349.179 C185.4967,350.054 185.4047,350.926 185.2967,351.804 C182.9777,370.744 179.7637,390.27 178.4237,398.162 C178.5457,400.082 178.7097,402.282 178.9357,404.804 C179.9357,415.971 182.9357,425.804 181.2687,446.804 C180.6317,454.832 179.3607,462.394 177.9587,469.46 C173.8237,470.713 167.7497,471.971 160.0187,471.971 C153.3427,471.971 147.9067,471.033 143.8827,469.969 C143.3707,466.09 142.9037,462.386 142.5187,459.054 C140.6857,443.169 140.1857,419.721 142.9357,412.554 C141.1857,406.554 140.5067,399.443 140.9357,392.554 C140.8437,386.027 141.6327,376.943 140.5467,367.304 C139.8527,361.149 139.0997,355.905 138.3587,351.054 C151.5227,355.971 170.2337,355.485 185.5947,349.179"
        />
        <BodyPart
          side="back"
          id="right_hand"
          className="hand__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M224.3106,275.054 C221.1336,276.561 221.4356,282.291 221.4356,284.304 C221.4356,287.217 222.8476,291.364 224.0286,294.327 C224.1026,295.284 224.2686,296.967 224.6026,298.429 C225.1026,300.616 225.9146,302.741 225.9146,302.741 C225.9146,302.741 225.6026,307.22 226.0186,309.804 C226.4356,312.387 225.6026,321.22 228.2686,321.22 C230.9356,321.22 230.8526,318.637 230.9356,314.804 C231.0126,311.251 231.2816,309.194 231.7426,306.12 C231.9036,306.094 232.0676,306.074 232.2346,306.067 C232.2586,307.585 232.3546,312.11 232.6646,313.866 C233.0396,315.991 233.3526,318.679 233.4146,322.241 C233.4776,325.804 234.6646,327.116 236.9146,327.179 C239.1646,327.241 238.7896,323.116 238.6646,320.241 C238.5396,317.366 238.7276,317.554 238.7896,313.616 C238.8526,309.679 238.4446,306.714 238.4056,306.246 C238.4056,306.246 238.4066,306.246 238.4076,306.246 C238.8566,307.313 240.1276,310.645 240.7276,312.804 C241.3526,315.054 242.9776,319.616 243.7276,320.804 C244.4776,321.991 244.7276,327.929 247.7896,327.366 C250.8526,326.804 248.8526,321.991 248.2276,319.804 C247.6026,317.616 246.7276,314.616 246.4146,312.741 C246.1026,310.866 244.2546,304.578 244.1716,304.346 C244.2336,304.423 246.9146,308.554 247.6646,309.929 C248.4146,311.304 249.2896,314.429 250.0396,317.054 C250.7896,319.679 252.2276,323.491 254.5396,322.929 C256.8526,322.366 255.4776,317.929 255.0396,316.304 C254.6026,314.679 252.9776,310.991 252.7276,308.304 C252.4776,305.616 251.2276,302.366 251.2276,302.366 C251.2276,302.366 250.7126,299.303 250.0696,296.949 C249.8946,295.106 249.1026,292.471 249.1026,292.471 C249.1026,292.471 250.4356,293.471 252.1026,294.304 C253.7686,295.137 256.9356,299.137 260.1026,299.304 C263.2696,299.47 264.6026,298.137 264.1026,296.637 C263.6026,295.137 259.4356,292.971 256.6026,289.637 C253.7686,286.304 248.9356,281.304 245.1026,278.804 C244.0426,278.113 243.6216,277.803 241.1936,276.347 C237.2276,273.971 230.0186,272.346 224.3106,275.054"
        />
        <BodyPart
          side="back"
          id="posterior_right_forearm"
          className="forearm__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M230.1021,230.054 C233.1851,240.221 236.8521,262.554 237.7691,268.137 C238.1721,270.595 239.8081,273.951 241.1451,276.321 C237.1701,273.959 229.9971,272.356 224.3101,275.054 C223.1351,275.612 222.4381,276.748 222.0231,278.06 C219.0771,270.748 213.3661,256.961 207.6021,245.054 C203.0631,235.677 199.7201,227.85 197.3311,221.406 C206.5091,219.766 215.1211,216.735 223.2671,212.555 C226.6541,218.78 228.0551,223.303 230.1021,230.054"
        />
        <BodyPart
          side="back"
          id="posterior_right_upper_arm"
          className="elbow__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M210.1846,152.0369 L212.4356,168.6379 C213.9356,180.8039 216.0036,192.2729 216.4116,201.5169 C219.4136,205.9479 221.5816,209.4579 223.2676,212.5559 C215.1216,216.7349 206.5086,219.7659 197.3306,221.4059 C195.0716,215.3079 193.6646,210.4479 192.9356,206.7209 C192.5456,204.7269 191.6986,202.3719 191.9356,200.0539 C190.2616,195.6679 189.3216,190.8639 187.9356,186.8039 C186.4896,182.5699 184.7076,177.9389 182.9716,173.1999 C192.6966,168.1189 201.7326,160.9589 210.1846,152.0369"
        />
        <BodyPart
          side="back"
          id="posterior_right_shoulder"
          className="shoulder__right"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M208.4356,139.1375 C208.0936,124.5525 204.1856,116.0545 200.6856,110.8045 C197.1856,105.5545 189.0186,100.4705 180.8526,97.7205 C180.5166,97.6075 180.1616,97.4815 179.7966,97.3485 C178.5496,107.0365 177.6906,116.7625 177.3996,126.4235 C177.0106,139.3315 177.6356,152.1185 179.7216,164.5295 C179.7366,164.3445 179.7556,164.1515 179.7686,163.9705 C180.7496,167.0425 181.8516,170.1435 182.9716,173.2005 C192.6966,168.1185 201.7326,160.9585 210.1846,152.0365 L208.4356,139.1375 Z"
        />
        <g
          className={`svg-skin__body-part ${checkSelected('buttocks', props.areas_affected)
            ? 'svg-skin__body-part-selected'
            : ''
            } svg-skin__body-part-back svg-skin__back-thighs-area`}>
          <BodyPart
            side="back"
            id="buttocks"
            className="thighs"
            onSelect={props.onSelect}
            areas_affected={props.areas_affected}
            drawn="M185.7969,282.804 C185.2749,276.389 184.0389,268.858 182.6039,260.722 C176.8019,264.15 161.5569,270.971 132.1029,270.971 C102.6489,270.971 87.4029,264.15 81.6009,260.722 C80.1659,268.858 78.9299,276.389 78.4079,282.804 C76.7209,303.533 75.9739,325.661 78.6099,349.179 C93.9709,355.485 112.6819,355.971 125.8459,351.053 C127.2409,341.914 128.5909,334.189 129.4079,324.554 C130.6579,309.804 130.7409,300.22 130.7409,300.22 L132.1019,297.596 L133.4639,300.22 C133.4639,300.22 133.5469,309.804 134.7969,324.554 C135.6129,334.189 136.9639,341.914 138.3589,351.053 C151.5229,355.971 170.2339,355.485 185.5949,349.179 C188.2309,325.661 187.4839,303.533 185.7969,282.804"
          />
          <BodyPart
            side="back"
            id="buttocks"
            className="butt-line"
            onSelect={props.onSelect}
            areas_affected={props.areas_affected}
            drawn="M180.784,296.7 C180.784,296.7 175.927,309.45 156.513,309.45 C137.099,309.45 132.242,296.7 132.242,296.7 C132.242,296.7 127.385,309.45 107.971,309.45 C88.558,309.45 83.7,296.7 83.7,296.7"
          />
        </g>
        <BodyPart
          side="back"
          id="posterior_waist"
          className="abdomen"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M175.7588,200.7122 C169.2108,204.4132 155.6338,209.8042 132.1028,209.8042 C108.5708,209.8042 94.9938,204.4132 88.4458,200.7122 C89.3138,219.8032 84.9238,241.8882 81.6008,260.7222 C87.4028,264.1502 102.6488,270.9702 132.1028,270.9702 C161.5568,270.9702 176.8018,264.1502 182.6038,260.7222 C179.2808,241.8882 174.8908,219.8032 175.7588,200.7122"
        />
        <BodyPart
          side="back"
          id="posterior_lower_torso"
          className="chest"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M177.3999,126.4232 C171.6399,128.8472 157.8149,133.1372 132.1029,133.1372 C106.3899,133.1372 92.5649,128.8472 86.8049,126.4232 C87.1939,139.3312 86.5689,152.1192 84.4829,164.5292 C85.0669,171.5192 87.4249,181.7772 88.4079,199.8042 C88.4239,200.1052 88.4319,200.4102 88.4459,200.7122 C94.9939,204.4132 108.5709,209.8042 132.1029,209.8042 C155.6339,209.8042 169.2109,204.4132 175.7589,200.7122 C175.7729,200.4102 175.7799,200.1052 175.7969,199.8042 C176.7799,181.7772 179.1379,171.5192 179.7209,164.5292 C177.6359,152.1192 177.0109,139.3312 177.3999,126.4232"
        />
        <BodyPart
          side="back"
          id="posterior_upper_torso"
          className="neck"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M152.2276,83.804 C151.1376,82.996 148.6026,80.804 148.2276,74.554 C148.1056,72.525 148.6906,67.539 148.9656,65.361 C145.4656,69.574 140.3046,72.468 132.1026,72.468 C123.8996,72.468 118.7386,69.574 115.2396,65.361 C115.5136,67.539 116.0996,72.525 115.9776,74.554 C115.6026,80.804 113.0676,82.996 111.9776,83.804 C108.7416,86.201 92.9046,94.265 84.4076,97.349 C85.6546,107.036 86.5136,116.762 86.8046,126.423 C92.5646,128.847 106.3896,133.137 132.1026,133.137 C157.8146,133.137 171.6406,128.847 177.3996,126.423 C177.6906,116.762 178.5496,107.036 179.7966,97.349 C171.2996,94.265 155.4636,86.201 152.2276,83.804"
        />
        <BodyPart
          side="back"
          id="posterior_head"
          className="head"
          onSelect={props.onSelect}
          areas_affected={props.areas_affected}
          drawn="M161.3853,32.7572 C160.2413,32.2182 159.1003,32.2272 158.0063,32.6102 C158.1173,25.0542 157.1563,16.2432 152.9353,9.7202 C149.0403,3.7002 142.4353,0.0002 132.1023,0.0002 C121.7693,0.0002 115.1643,3.7002 111.2693,9.7202 C107.0483,16.2432 106.0873,25.0542 106.1983,32.6102 C105.1043,32.2272 103.9633,32.2182 102.8193,32.7572 C100.6913,33.7592 102.1093,37.9562 102.6483,41.0202 C103.1873,44.0852 103.1623,47.5132 104.6653,49.7802 C105.9533,51.7232 107.4133,51.8912 108.4953,50.7812 C111.5693,61.2382 115.9353,72.4672 132.1023,72.4672 C148.2693,72.4672 152.6353,61.2382 155.7093,50.7812 C156.7913,51.8912 158.2513,51.7232 159.5393,49.7802 C161.0433,47.5132 161.0173,44.0852 161.5563,41.0202 C162.0953,37.9562 163.5133,33.7592 161.3853,32.7572"
        />
      </g>
    </svg>
  );
}

const checkSelected = (id, selectedArray) => {
  return selectedArray.includes(id);
};
