import React from 'react';
import { ProgressDot, ProgressStep } from '../../assets/icons/';

//total steps
//current step
//completed
//required

const ProgressBar = props => {
  let steps = buildSteps(props.totalSteps, props.currentStep, props.dot);
  return <ul className="progress-bar-wrapper">{steps}</ul>;
};

const buildSteps = (steps, currentStep, dotComponent) => {
  let list = [];
  for (let i = 1; i <= steps; i++) {
    list.push(
      <li
        className={`progress-bar-item
          ${i > currentStep ? 'progress-bar-item__pending progress' : ''}
          ${i === currentStep ? 'progress-bar-item__active progress' : ''} ${
          i < currentStep ? 'progress-bar-item__completed progress' : ''
        }`}
        key={i}>
        <span
          className={`${
            i === currentStep ? 'svg-wrapper__shadow-extreme' : ''
          }`}>
          {dotComponent === 'number' ? (
            <ProgressStep step={i} />
          ) : (
            <ProgressDot />
          )}
        </span>
      </li>,
    );
  }
  return list;
};

export default ProgressBar;
