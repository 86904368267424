import React from 'react';
import FadeIn from '../components/shared/FadeIn';
import ScrollToTop from '../components/shared/ScrollToTop';


export default () => {
  const focusElement = React.useRef(null);

  React.useEffect(() => {
    if (focusElement.current)
      focusElement.current.focus();
  }, []);

  return (
    <ScrollToTop>
      <FadeIn>
        <div className="main-content content-body page-privacy">
          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h1 className="title__mega-large title__cobalt title__semibold" tabindex="0" ref={focusElement}>
                  Privacy Policy
                </h1>
                <h2 className="title title__large title__cobalt title__semibold">
                  About this platform
                </h2>
                <p className="paragraph">
                  EczemaWise, www.EczemaWise.org, is a tool for individuals to
                  track their symptoms, triggers, treatments, and quality of life
                  impacts to 1) document and understand the profile of their
                  eczema, and 2) work with their healthcare providers to best
                  manage their condition.
                </p>
                <p className="paragraph">
                  If you are the parent or guardian of a patient with Eczema, you
                  will be providing information about their condition and your
                  consent is both for your participation utilizing the platform
                  and to provide the information on behalf of the person you care
                  for.
                </p>
                <p className="paragraph">
                  EczemaWise is not a medical app. It is not intended to diagnose,
                  treat or prevent any disease. Nor does it endorse any particular
                  treatment or therapy.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  EczemaWise research registry
                </h2>
                <p className="paragraph">
                  The information collected in EczemaWise forms a type of research
                  registry. A research registry collects information about people
                  who have a particular disease and makes it available to
                  researchers. The EczemaWise research registry collects
                  user-generated, health-related data and shares it anonymously
                  and confidentially with scientists studying eczema.
                </p>
                <p className="paragraph">
                  By agreeing to use the EczemaWise (website and/or iOS/Android
                  app), you are also consenting to be a participant in the
                  research registry. Additionally, we may send you surveys via
                  email or through the platform about certain aspects of living
                  with eczema intended to supplement the information collected in
                  the registry.
                </p>
                <p className="paragraph">
                  Your participation in the EczemaWise research registry is
                  completely voluntary. You may decide not to participate or you
                  may leave the study/registry at any time. Your decision will not
                  result in any penalty or loss of benefits to which you are
                  entitled.
                </p>
                <p className="paragraph">
                  By agreeing to this privacy policy and terms of use, you consent
                  to participate in the registry. Your alternative is to choose
                  not to participate in the registry and platform. There is no
                  specific timeframe at which your participation will end unless
                  you stop using the platform and withdraw your consent.
                </p>
                <p className="paragraph">
                  There is no compensation to participate in the EczemaWise
                  research registry nor will you be charged to use the platform.
                  You can opt out of participation at any time by deleting your
                  account.
                </p>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Benefits of participation
                </h3>
                <p className="paragraph">
                  By using the EczemaWise platform on a regular basis, you are
                  providing researchers with important insights on treating and
                  managing eczema. Called patient-generated health data (PGHD),
                  this information helps to fill critical gaps in our
                  understanding of how eczema affects individuals in their daily
                  lives, not just in clinical trials or in the healthcare setting.
                </p>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Risks of participation
                </h3>
                <p className="paragraph">
                  We use industry standards alongside the latest technology to
                  prevent data loss and theft, as well as unauthorized access,
                  disclosure, copying, use or modification of stored data.
                  However, no method of electronic transmission or data storage is
                  100% secure and we cannot guarantee absolute data security. We
                  also cannot guarantee the security of any data from this
                  platform that you provide to others.
                </p>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Questions
                </h3>
                <p className="paragraph">
                  If you have questions, concerns, or complaints, or think this
                  research has hurt you or made you sick, talk to the research
                  team at National Eczema Association at{' '}
                  <a className="link__underline" href="tel:+18008187546">(800) 818-7546</a>.
                </p>

                <p className="paragraph">
                  This research is being overseen by an Institutional Review Board
                  (“IRB”). An IRB is a group of people who perform independent
                  review of research studies.
                </p>

                <p className="paragraph">
                  <span className="text__bold">IRB Protocol Title:</span>{' '}
                  EczemaWise: A Patient-Centric Digital Health Platform and
                  Research Registry
                </p>
                <p className="paragraph">
                  <span className="text__bold">IRB Protocol No.:</span> WIRB
                  Protocol #20190350
                </p>
                <p className="paragraph">
                  <span className="text__bold">Sponsor:</span> National Eczema
                  Association, 505 San Marin Drive #B300, Novato, CA 95945
                </p>
                <p className="paragraph">
                  You may talk to them at{' '}
                  <a className="link__underline" href="tel:+18005624789">(800) 562-4789</a>,{' '}
                  <a className="link__underline" href="mailto:help@wirb.com">help@wirb.com</a> if:
                </p>
                <ul className="list" style={{ listStyleType: 'disc' }}>
                  <li className="paragraph">
                    You have questions, concerns, or complaints that are not being
                    answered by the research team.
                  </li>
                  <li className="paragraph">
                    You are not getting answers from the research team.
                  </li>
                  <li className="paragraph">
                    You cannot reach the research team.
                  </li>
                  <li className="paragraph">
                    You want to talk to someone else about the research.
                  </li>
                  <li className="paragraph">
                    You have questions about your rights as a research subject.
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Information we collect
                </h2>
                <p className="paragraph">
                  Your privacy is important to us. This information is intended to
                  detail in a clear and transparent manner, what data we collect
                  on the EczemaWise platform and how we protect it.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Personal information
                </h3>

                <p className="paragraph">
                  We ask you for the following personal information when you sign
                  up for an account:
                </p>
                <ul className="list" style={{ listStyleType: 'disc' }}>
                  <li className="paragraph">Email</li>
                  <li className="paragraph">
                    Birth Year (of the person with eczema)
                  </li>
                  <li className="paragraph">Zip Code</li>
                  <li className="paragraph">
                    Gender and Race/Ethnicity (of the person with eczema)
                  </li>
                  <li className="paragraph">
                    Relation to Eczema (patient or caregiver)
                  </li>
                </ul>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Health-related data
                </h3>

                <p className="paragraph">
                  We collect and store the following user-provided health-related
                  data in the EczemaWise research registry:
                </p>
                <ul className="list" style={{ listStyleType: 'disc' }}>
                  <li className="paragraph">Triggers</li>
                  <li className="paragraph">Symptoms</li>
                  <li className="paragraph">Treatment types</li>
                  <li className="paragraph">Treatment times</li>
                  <li className="paragraph">Quality of life assessments</li>
                  <li className="paragraph">Reason for provider appointment</li>
                  <li className="paragraph">Treatment preferences</li>
                  <li className="paragraph">
                    Notes (including user photographs of eczema-affected areas)
                  </li>
                </ul>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Log Data
                </h3>

                <p className="paragraph">
                  When you visit EczemaWise, our servers may automatically log the
                  standard data provided by your web browser. It may include your
                  device’s Internet Protocol (IP) address, your browser type and
                  version or device os and version, the pages you visit on
                  EczemaWise, the time and date of your visit, the time spent on
                  each page, and other details.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Device Data
                </h3>

                <p className="paragraph">
                  We may also collect data about the device you’re using to access
                  our website. This data may include the device type, operating
                  system, unique device identifiers, device settings, and
                  geo-location data. What we collect can depend on the individual
                  settings of your device and software. We recommend checking the
                  policies of your device manufacturer or software provider to
                  learn what information they make available to us.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  How we use your data
                </h2>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  How we use your personal information
                </h3>
                <p className="paragraph">
                  We use your email and ZIP code for the following non-exclusive
                  activities:
                </p>
                <ol type="a" className="list">
                  <li className="paragraph">
                    To facilitate the creation of your account and identify you as
                    a registered user;
                  </li>
                  <li className="paragraph">
                    To send you communications related to your EczemaWise account,
                    e.g. platform announcements, tutorials and reminders, for the
                    explicit purpose of improving your experience with the
                    platform;
                  </li>
                  <li className="paragraph">
                    To fulfill a specific request from you, e.g. platform support
                    or account deletion;
                  </li>
                  <li className="paragraph">
                    To serve a business function such as platform administration,
                    research and development, scientific surveys, marketing, and
                    to protect our legal rights and interests;
                  </li>
                  <li className={'paragraph'}>
                    To comply with a legal obligation, or as required by law.
                  </li>
                </ol>

                <p className="paragraph">
                  We do not sell or trade your email address.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  How we use your health-related data
                </h3>
                <p className="paragraph">
                  We make available to qualified scientists, anonymized platform
                  data for use in research. The process of anonymization removes
                  any and all identifiable personal information from the data
                  prior to its release. Researchers using anonymized data are not
                  able to identify who contributed the information.
                </p>
                <p className="paragraph">
                  By allowing your anonymous health-related data to be used in
                  eczema research, you are helping scientists better understand
                  eczema leading to improved care, treatments and ultimately – a
                  cure.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  How we use your log and device data
                </h3>
                <p className="paragraph">
                  From time to time we may use your log and device data, combined
                  with that of all platform users, to improve the functionality of
                  the site.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  How we protect your data
                </h2>
                <p className="paragraph">
                  We follow industry security standards and use the latest
                  technology to prevent data loss and theft, as well as
                  unauthorized access, disclosure, copying, use or modification of
                  stored data.
                </p>
                <p className="paragraph">
                  We do not share personally identifiable data with employers,
                  insurers or other third parties. The personal information we
                  collect is stored and processed in the United States. The
                  information may however also be reviewed by the IRB overseeing
                  this research.
                </p>
                <p className="paragraph">
                  We process your data lawfully, fairly and in a transparent
                  manner. We collect and process information about you only where
                  we have legal basis for doing so. Legal basis depends on the
                  services you use and how you use them, meaning we collect and
                  use your data only when you consent to our use of your
                  information for a specific purpose by agreeing to this privacy
                  policy and terms up service when you sign up for an account on
                  EczemaWise.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Your rights and controlling your information
                </h2>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Choice and consent
                </h3>
                <p className="paragraph">
                  By providing personal information to us, you consent to us
                  collecting, holding, using and disclosing your personal
                  information in accordance with this privacy policy/consent
                  document.
                </p>
                <p className="paragraph">
                  We are unable to prevent children under the age of 18 from
                  visiting the platform. However, no part of our platform is
                  directed at or intended for use by persons under the age of 18.
                  If you are under the age of 18, please do not access the web
                  site at any time or in any manner. You may discuss your parent
                  creating an account and working with them to access available
                  information.
                </p>
                <p className="paragraph">
                  In order to comply with the Children's Online Privacy Protection
                  Act, we will not knowingly collect personal information from
                  children under the age of 13. However, we are also not enrolling
                  anyone in this platform that is under the age of 18. By
                  providing personal information through the platform, you
                  represent and warrant that you are at least 18 years of age and
                  that you agree to comply with this privacy policy. If, at any
                  time, we learn that personal information has been collected from
                  persons under the age of 18, we will take the appropriate steps
                  to delete such information.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Information from third parties
                </h3>
                <p className="paragraph">
                  If we receive personal information about you from a third party,
                  we will protect it as set out in this privacy policy. If you are
                  a third party providing personal information about somebody
                  else, you represent and warrant that you have such person’s
                  consent to provide the personal information to us.
                </p>

              <h3 className="headers headers__cobalt headers__italic headers__semibold">
                Restrictions
              </h3>
              <p className="paragraph">
                You may choose to restrict the collection or use of your personal 
                information or related EczemaWise data at any time. All personal 
                identifying information is scrambled upon deletion of your EczemaWise 
                account, which can be done at any time in the EczemaWise App. 
                When a user chooses to delete their account, 
                the information already collected following initial consent will 
                continue to be housed and used for research purposes. If you would 
                like to erase all data associated with your account, you may do so at 
                any time by contacting us using the information below and requesting 
                deletion of your data. Your decision to do so will not affect your 
                relationship with us.
              </p>

              <h3 className="headers headers__cobalt headers__italic headers__semibold">
                Access and data portability
              </h3>
              <p className="paragraph">
                You may request details of the personal information that we hold
                about you. Where possible, we will provide this information in
                CSV format or other easily readable machine format. You may
                request that we delete the Personal Identifiable Information we
                hold about you at any time. You can do this by going to Settings
                and then Delete Your Account.
              </p>

              <h3 className="headers headers__cobalt headers__italic headers__semibold">
                Correction
              </h3>
              <p className="paragraph">
                If you believe that any information we hold about you is
                inaccurate, out of date, incomplete, irrelevant or misleading,
                please contact us using the details below. We will take
                reasonable steps to correct any information found to be
                inaccurate, incomplete, misleading or out of date.
              </p>

              <h3 className="headers headers__cobalt headers__italic headers__semibold">
                Data storage term limits
              </h3>
              <p className="paragraph">
                If you believe that we have breached a relevant data protection
                law and wish to make a complaint, please&nbsp;
                <a className="link__underline" href="https://www.tfaforms.com/4848315" target="_blank">contact us</a> and
                provide us with full details of the alleged breach. We will
                promptly investigate your complaint and respond to you, in
                writing, setting out the outcome of our investigation and the
                steps we will take to deal with your complaint. You also have
                the right to contact a regulatory body or data protection
                authority in relation to your complaint.
              </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Unsubscribe
                </h3>
                <p className="paragraph">
                  To unsubscribe from our e-mail database or opt-out of
                  communications (including marketing communications), please use
                  the opt-out facilities provided in the communication.
                </p>
              </div >
            </div >
          </section >

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">Cookies</h2>
                <p className="paragraph">
                  We use “cookies” to collect information about you and your
                  activity across our site. A cookie is a small piece of data that
                  our website stores on your computer, and accesses each time you
                  visit, so we can understand how you use our site. This helps us
                  serve you content based on preferences you have specified.
                  Information on our cookie policy can be found further down this
                  page, under Terms of Service.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Limits of our policy
                </h2>
                <p className="paragraph">
                  Our website may link to external sites that are not operated by
                  us. Please be aware that we have no control over the content and
                  policies of those sites and cannot accept responsibility or
                  liability for their respective privacy practices.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Changes to this policy
                </h2>
                <p className="paragraph">
                  At our discretion, we may change our privacy policy to reflect
                  current acceptable practices. We will notify you of these
                  changes and we will ask you to re-consent to the amended privacy
                  policy.
                </p>
              </div>
            </div>
          </section>
          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h1 className="title__mega-large title__cobalt title__semibold">
                  Terms of Service
                </h1>
                <p className="title">
                  By accessing EczemaWise at www.EczemaWise.org or via iOS or
                  Android app, you are agreeing to be bound by these terms of
                  service, all applicable laws and regulations, and agree that you
                  are responsible for compliance with any applicable local laws.
                  If you do not agree with any of these terms, you are prohibited
                  from using or accessing this site. The materials contained in
                  this website are protected by applicable copyright and trademark
                  law.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Use License
                </h2>
                <p className="paragraph">
                  Permission is granted to temporarily download one copy of the
                  materials (information or software) on the EczemaWise website or
                  app for personal, non-commercial transitory viewing only. This
                  is the grant of a license, not a transfer of title, and under
                  this license you may not:
                </p>
                <ul className="list" style={{ listStyleType: 'disc' }}>
                  <li className="paragraph">Modify or copy the materials;</li>
                  <li className="paragraph">
                    Use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li className="paragraph">
                    Attempt to decompile or reverse engineer any software
                    contained on the EczemaWise website;
                  </li>
                  <li className="paragraph">
                    Remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li className="paragraph">
                    Transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ul>
                <p className="paragraph">
                  This license shall automatically terminate if you violate any of
                  these restrictions and may be terminated by the National Eczema
                  Association at any time. Upon terminating your viewing of these
                  materials or upon the termination of this license, you must
                  destroy any downloaded materials in your possession whether in
                  electronic or printed format.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Disclaimer
                </h2>
                <p className="paragraph">
                  The materials on EczemaWise are provided on an 'as is' basis.
                  The National Eczema Association makes no warranties, expressed
                  or implied.
                </p>
                <p className="paragraph">
                  Further, the National Eczema Association does not warrant or
                  make any representations concerning the accuracy, likely
                  results, or reliability of the use of the materials on its
                  website or otherwise relating to such materials or on any sites
                  linked to this site.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Accuracy of materials
                </h2>

                <p className="paragraph">
                  The materials appearing on EczemaWise could include technical,
                  typographical, or photographic errors. The National Eczema
                  Association does not warrant that any of the materials on
                  EczemaWise are accurate, complete or current. The National
                  Eczema Association may make changes to the materials contained
                  on its website at any time without notice. However, the National
                  Eczema Association does not make any commitment to update the
                  materials.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">Links</h2>
                <p className="paragraph">
                  The National Eczema Association has not reviewed all of the
                  sites linked EczemaWise and is not responsible for the contents
                  of any such linked site. The inclusion of any link does not
                  imply endorsement by the National Eczema Association of the
                  site. Use of any such linked website is at the user's own risk.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Modifications
                </h2>
                <p className="paragraph">
                  The National Eczema Association may revise these terms of
                  service for EczemaWise at any time. We will notify you of these
                  changes via our website, and we will ask you to re-consent to
                  the amended privacy policy.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational content__center">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h2 className="title title__cobalt title__semibold">
                  Cookie Policy
                </h2>
                <p className="paragraph">
                  We use cookies to help improve your experience of
                  www.EczemaWise.org. This cookie policy is part of the National
                  Eczema Association's privacy policy and covers the use of
                  cookies between your device and our site. We also provide basic
                  information on third-party services we may use, who may also use
                  cookies as part of their service, though they are not covered by
                  our policy.
                </p>
                <p className="paragraph">
                  If you don’t wish to accept cookies from us, you should instruct
                  your browser to refuse cookies from www.EczemaWise.org, with the
                  understanding that we may be unable to provide you with some of
                  your desired content and services.
                </p>
                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  What is a cookie?
                </h3>
                <p className="paragraph">
                  A cookie is a small piece of data that a website stores on your
                  device when you visit, typically containing information about
                  the website itself, a unique identifier that allows the site to
                  recognize your web browser when you return, additional data that
                  serves the purpose of the cookie, and the lifespan of the cookie
                  itself.
                </p>
                <p className="paragraph">
                  Cookies are used to enable certain features (e.g. logging in),
                  to track site usage (e.g. analytics), to store your user
                  settings (e.g. time zone, notification preferences), and to
                  personalize your content (e.g. advertising, language).
                </p>
                <p className="paragraph">
                  Cookies set by the website you are visiting are normally
                  referred to as “first-party cookies”, and typically only track
                  your activity on that particular site. Cookies set by other
                  sites and companies (i.e. third parties) are called “third-party
                  cookies,” and can be used to track you on other websites that
                  use the same third-party service.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Types of cookies and how we use them
                </h3>
                <ul className="list">
                  <li>
                    <h4 className="paragraph paragraph__cobalt">
                      Essential Cookies
                    </h4>
                    <p className="paragraph">
                      Essential cookies are crucial to your experience of a
                      website, enabling core features like user logins, account
                      management, shopping carts and payment processing. We use
                      essential cookies to enable certain functions on our
                      website.
                    </p>
                  </li>

                  <li>
                    <h4 className="paragraph paragraph__cobalt">
                      Performance cookies
                    </h4>
                    <p className="paragraph">
                      Performance cookies are used in the tracking of how you use
                      a website during your visit, without collecting personal
                      information about you. Typically, this information is
                      anonymous and aggregated with information tracked across all
                      site users, to help companies understand visitor usage
                      patterns, identify and diagnose problems or errors their
                      users may encounter, and make better strategic decisions in
                      improving their audience’s overall website experience. These
                      cookies may be set by the website you’re visiting
                      (first-party) or by third-party services. We use performance
                      cookies on our site.
                    </p>
                  </li>

                  <li>
                    <h4 className="paragraph paragraph__cobalt">
                      Functionality cookies
                    </h4>
                    <p className="paragraph">
                      Functionality cookies are used in collecting information
                      about your device and any settings you may configure on the
                      website you’re visiting (like language and time zone
                      settings). With this information, websites can provide you
                      with customized, enhanced or optimized content and services.
                      These cookies may be set by the website you’re visiting
                      (first-party) or by third-party service. We use
                      functionality cookies for selected features on our site.
                    </p>
                  </li>

                  <li>
                    <h4 className="paragraph paragraph__cobalt">
                      Targeting/advertising cookies
                    </h4>
                    <p className="paragraph">
                      Targeting/advertising cookies are used in determining what
                      promotional content is more relevant and appropriate to you
                      and your interests. Websites may use them to deliver
                      targeted advertising or to limit the number of times you see
                      an advertisement. This helps companies improve the
                      effectiveness of their campaigns and the quality of content
                      presented to you. These cookies may be set by the website
                      you’re visiting (first-party) or by third-party services.
                      Targeting/advertising cookies set by third-parties may be
                      used to track you on other websites that use the same
                      third-party service. We do not use this type of cookie on
                      our site.
                    </p>
                  </li>

                  <li>
                    <h4 className="paragraph paragraph__cobalt">
                      Third-party cookies on our site
                    </h4>
                    <p className="paragraph">
                      We may employ third-party companies and individuals on our
                      websites—for example, analytics providers and content
                      partners. We grant these third parties access to selected
                      information to perform specific tasks on our behalf. They
                      may also set third-party cookies in order to deliver the
                      services they are providing. Third-party cookies can be used
                      to track you on other websites that use the same third-party
                      service. As we have no control over third-party cookies,
                      they are not covered by the National Eczema Association's
                      cookie policy.
                    </p>
                  </li>
                </ul>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  Our third-party privacy promise
                </h3>
                <p className="paragraph">
                  We review the privacy policies of all our third-party providers
                  before enlisting their services to ensure their practices align
                  with ours. We will never knowingly include third-party services
                  that compromise or violate the privacy of our users.
                </p>

                <h3 className="headers headers__cobalt headers__italic headers__semibold">
                  How you can control or opt out of cookies
                </h3>
                <p className="paragraph">
                  If you do not wish to accept cookies from us, you can instruct
                  your browser to refuse cookies from our website. Most browsers
                  are configured to accept cookies by default, but you can update
                  these settings to either refuse cookies altogether, or to notify
                  you when a website is trying to set or update a cookie.
                </p>
                <p className="paragraph">
                  If you browse websites from multiple devices, you may need to
                  update your settings on each individual device.
                </p>
                <p className="paragraph">
                  Although some cookies can be blocked with little impact on your
                  experience of a website, blocking all cookies may mean you are
                  unable to access certain features and content across the sites
                  you visit.
                </p>
              </div>
            </div>
          </section>
        </div >
      </FadeIn >
    </ScrollToTop >
  );
}