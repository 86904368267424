import React, { Component } from 'react';
import { FullArrowForward, OpenClose } from '../../assets/icons';

class TreatmensTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsSelected: {},
      selectionId: undefined,
    };
  }


  render() {
    return <div>{this._buildTree(this.props.options)}</div>;
  }

  _buildTree(options) {
    const tree = options.map(option => {
      let subMenu;
      let hasChildren = option.children && option.children.length;
      let selectionIdentifier = option.id + option.name; //need to make sure these are unique. a group and a class could have the same id
      if (!hasChildren && !option.metadata_id) return null;
      const heading = (
        <div
          tabIndex="0"
          onClick={
            hasChildren
              ? this._toggleSelect.bind(this, option)
              : this.selectOption.bind(this, option)
          }
          onKeyDown={
            hasChildren
              ? event => this.handleKeyDown(this._toggleSelect.bind(this, option),event)
              : event => this.handleKeyDown(this.selectOption.bind(this, option, event),event)
          }
          className={
            'card options-tree__header' +
            (hasChildren
              ? ' options-tree__has-children'
              : ' options-tree__no-children') +
            (this.state.optionsSelected[selectionIdentifier] ||
            (this.state.selectionId &&
              this.state.selectionId === option.selectionId)
              ? ' options-tree__active'
              : '')
          }>
          <span className="paragraph">{option.name}</span>
          {hasChildren ? <OpenClose /> : <FullArrowForward />}
        </div>
      );
      if (hasChildren && this.state.optionsSelected[selectionIdentifier]) {
        subMenu = this._buildTree(option.children);
      }
      return (
        <li className="options-tree-item" key={option.name}>
          {heading}
          {subMenu}
        </li>
      );
    });
    return <ul className="options-tree">{tree}</ul>;
  }

  _toggleSelect(option, e) {
    let updatedSelections = this.state.optionsSelected;
    let selectionIdentifier = option.id + option.name; //need to make sure these are unique. a group and a class could have the same id
    updatedSelections[selectionIdentifier] =
      typeof updatedSelections[selectionIdentifier] === 'boolean'
        ? !updatedSelections[selectionIdentifier]
        : true; // handle initial selection
    this.setState({
      optionsSelected: updatedSelections,
    });
  }

  selectOption(option, e) {
    //this will need to talk to parent component. So likely calling a callback
    e.preventDefault();
    this.props.handleTreatmentSelection(option);
    this.setState({
      selectionId: option.selectionId,
    });
  }

  handleKeyDown(callback, e){
    if (e.key === 'Enter') {
      callback();
    }
  };
}

export default TreatmensTree;
