import React from 'react';

export default function fullArrowForward() {
  return (
    <svg
      className="icon-full-arrow-forward icon-full-arrow"
      viewBox="0 0 18 10">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round">
        <g
          className="svg-full-arrow-forward__stroke svg-stroke"
          transform="translate(-70.000000, -12.000000)">
          <g id="Group-13-Copy" transform="translate(31.000000, 7.000000)">
            <g
              id="ICON/platform/full-arrow/white"
              transform="translate(38.000000, 0.000000)">
              <g transform="translate(2.000000, 5.000000)">
                <path d="M0,5 L16,5" id="Line-4" />
                <polyline
                  id="Path-2"
                  points="11.3333333 0 16.3333333 5 11.3333333 10"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
