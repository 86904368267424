import React from 'react';

export default function OtherIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8461 16.4848C14.8945 16.4848 14.123 15.6169 14.123 14.5463C14.123 13.4758 14.8945 12.6079 15.8461 12.6079C16.7976 12.6079 17.5691 13.4758 17.5691 14.5463C17.5691 15.6169 16.7976 16.4848 15.8461 16.4848ZM19.1845 9.7002H12.5076C12.4481 9.7002 12.3999 9.75447 12.3999 9.82135V10.6694L13.236 18.6656H18.4561L19.2922 10.6694V9.82135C19.2922 9.75447 19.244 9.7002 19.1845 9.7002Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.492 21.3309C16.492 20.9301 16.2021 20.604 15.8458 20.604C15.4896 20.604 15.1997 20.9301 15.1997 21.3309C15.1997 21.4647 15.1032 21.5732 14.9843 21.5732H13.2612V22.179C13.2612 22.2459 13.3095 22.3002 13.3689 22.3002H18.3228C18.3822 22.3002 18.4305 22.2459 18.4305 22.179V21.5732H16.7074C16.5885 21.5732 16.492 21.4647 16.492 21.3309Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2612 21.0889H14.7905C14.8908 20.5364 15.3255 20.1196 15.8458 20.1196C16.3662 20.1196 16.8009 20.5364 16.9012 21.0889H18.4305V19.1504H13.2612V21.0889Z"
        fill="white"
      />
    </svg>
  );
}
