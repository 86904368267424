import React from 'react';

export default function DietBlueIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4297 9.35625C14.4032 9.33345 14.3634 9.33398 14.3375 9.35731L10.8171 12.5999C10.7964 12.619 10.789 12.6476 10.7985 12.6741C10.8075 12.7006 10.8319 12.7181 10.86 12.7202L12.3732 12.8109L10.5308 15.4649C10.5106 15.4941 10.5159 15.5333 10.5425 15.5567C10.5552 15.5683 10.5716 15.5736 10.5881 15.5736C10.6045 15.5736 10.6215 15.5673 10.6347 15.5551L14.1545 12.313C14.1752 12.2945 14.1826 12.2653 14.1731 12.2388C14.1641 12.2128 14.1397 12.1948 14.1121 12.1932L12.599 12.1026L14.4408 9.44797C14.4615 9.41934 14.4562 9.37958 14.4297 9.35625Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.0286 10.5072C7.57123 10.5072 7.19917 10.8792 7.19917 11.3366C7.19917 11.5328 7.03992 11.6921 6.84371 11.6921C6.64749 11.6921 6.48824 11.5328 6.48824 11.3366C6.48824 10.4873 7.17927 9.79622 8.0286 9.79622C8.22482 9.79622 8.38407 9.95547 8.38407 10.1517C8.38407 10.3479 8.22482 10.5072 8.0286 10.5072ZM11.7421 8.78906C10.8174 8.78906 10.4192 9.15638 9.68746 9.15638C8.95566 9.15638 8.55754 8.78906 7.63285 8.78906C5.6171 8.78906 5.20428 10.6868 5.51425 12.5689C5.84792 14.5951 6.53753 16.3724 8.04377 16.3724C9.06278 16.3724 9.25947 15.9458 9.68746 15.9458C10.1154 15.9458 10.3121 16.3724 11.3311 16.3724C12.8374 16.3724 13.527 14.5951 13.8607 12.5689C14.1706 10.6868 13.7578 8.78906 11.7421 8.78906Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8193 7.84046C12.5966 7.06364 12.5297 5.70954 12.5297 5.70954C12.5297 5.70954 11.1761 5.64319 10.3988 6.42001C9.62199 7.19682 9.68882 8.55092 9.68882 8.55092C9.68882 8.55092 11.0424 8.61728 11.8193 7.84046Z"
        fill="white"
      />
    </svg>
  );
}
