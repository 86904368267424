import React from 'react';

export default function BedtimeIcon({ isSelect, errorSelect }) {
  let fillCircle = isSelect ? '#1D62ED' : '#E7EAF1';
  let fillPath = isSelect ? '#E7EAF1' : errorSelect ? '#E95D42' : '#1D62ED';
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fillCircle} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4665 18.036C22.4384 18.011 22.3981 18.0041 22.3646 18.0193C21.6923 18.3136 20.9782 18.4627 20.2413 18.4627C18.3446 18.4627 16.5788 17.4444 15.6319 15.8048C14.4144 13.6951 14.781 11.0349 16.5232 9.33524C16.5499 9.30862 16.559 9.26908 16.5461 9.23409C16.5331 9.19911 16.5004 9.17477 16.4632 9.17249C16.3019 9.16109 16.14 9.15576 15.9818 9.15576C14.787 9.15576 13.6098 9.47061 12.5763 10.0668C9.30844 11.9544 8.18443 16.1485 10.0712 19.4171C11.2903 21.5282 13.5634 22.8401 16.0046 22.8401C17.1993 22.8401 18.3804 22.523 19.4207 21.9222C20.8756 21.0826 21.9661 19.738 22.4931 18.1357C22.5045 18.0999 22.4946 18.0611 22.4665 18.036Z"
        fill={fillPath}
      />
    </svg>
  );
}
