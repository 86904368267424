import gql from 'graphql-tag';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Select from 'react-select';

const tzOptions = moment.tz.names().map(tz => ({ label: tz, value: tz }));

const AdminSurveyEditor = props => {
  const { data, loading, error } = useQuery(gql`
    query {
      getSkindexQuestions {
        header
        description
        scale
        questions {
          text
          description
          id
          scale
        }
        footer
      }
    }
  `);

  const [
    setSkindexQuestions,
    { loading: setSkindexIsLoading },
  ] = useMutation(gql`
    mutation($payload: String!) {
      adminSetSetting(
        setting_name: "skindex_questions"
        setting_value: $payload
      ) {
        setting_name
        setting_value
      }
    }
  `);

  const [timezone, setTimezone] = useState('America/Los_Angeles');
  const [setGlobalTimezone, {}] = useMutation(gql`
    mutation($payload: String!) {
      adminSetSetting(setting_name: "timezone", setting_value: $payload) {
        setting_name
        setting_value
      }
    }
  `);

  const [localSkindex, setLocalSkindex] = useState({
    header: '',
    scale: '6',
    description: '0 = not at all, 6 = extremely',
    questions: [
      {
        text: '',
        id: '',
      },
      {
        text: '',
        id: '',
      },
      {
        text: '',
        id: '',
      },
    ],
    footer: '',
  });
  useEffect(() => {
    if (data && data.getSkindexQuestions)
      setLocalSkindex(data.getSkindexQuestions);
  }, [data]);

  if (error || loading) return null;

  return (
    <Fragment>
      <div className={'card card__active treatment-tile'}>
        <h2>{'Big Picture Questions (Manage Tab)'}</h2>
        <ul>
          <h5>Header</h5>
          <li>
            <label>Text</label>
            <textarea
              rows={4}
              type={'text'}
              value={localSkindex.header || ''}
              name={'header'}
              onChange={e =>
                setLocalSkindex({
                  ...localSkindex,
                  header: e.target.value,
                })
              }
            />
          </li>
          <h5>Meta</h5>
          <li>
            <label>Description:</label>
            <input
              type={'text'}
              value={localSkindex.description || ''}
              name={'header'}
              onChange={e => {
                setLocalSkindex({
                  ...localSkindex,
                  description: e.target.value,
                });
              }}
            />
          </li>
          <li>
            <label>Scale:</label>
            <input
              type={'number'}
              value={localSkindex.scale || '6'}
              name={'header'}
              onChange={e => {
                setLocalSkindex({
                  ...localSkindex,
                  scale: e.target.value,
                });
              }}
            />
          </li>
          {localSkindex.questions &&
            localSkindex.questions.map((q, i) => {
              return (
                <Fragment key={i}>
                  <h5>Q{i + 1}</h5>
                  <li>
                    <label>id:</label>
                    <input
                      disabled={true}
                      type={'text'}
                      value={q.id}
                      name={'id'}
                    />
                  </li>
                  <li>
                    <label>Question:</label>
                    <textarea
                      rows={4}
                      type={'text'}
                      value={q.text || ''}
                      name={'header'}
                      onChange={e => {
                        const questions = localSkindex.questions.slice(0);
                        questions[i] = {
                          ...questions[i],
                          text: e.target.value,
                        };
                        setLocalSkindex({
                          ...localSkindex,
                          questions,
                        });
                      }}
                    />
                  </li>
                </Fragment>
              );
            })}
          <h5>Footer</h5>
          <li>
            <label>Text</label>
            <textarea
              type={'text'}
              rows={6}
              value={localSkindex.footer || ''}
              name={'footer'}
              onChange={e =>
                setLocalSkindex({
                  ...localSkindex,
                  ...{ footer: e.target.value },
                })
              }
            />
          </li>
        </ul>
        <div className={'treatment_actions'}>
          <div className="stretch" />
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              setLocalSkindex(data.getSkindexQuestions);
            }}>
            Cancel
          </button>
          <button
            disabled={setSkindexIsLoading}
            className={'button button-regular button__green'}
            onClick={() => {
              const value = {
                header: localSkindex.header,
                footer: localSkindex.footer,
                description: localSkindex.description,
                scale: localSkindex.scale,
                questions: localSkindex.questions.map(question => {
                  return {
                    ...question,
                    scale: localSkindex.scale,
                    description: localSkindex.description,
                  };
                }),
              };
              setSkindexQuestions({
                variables: { payload: JSON.stringify(value) },
              });
            }}>
            Save
          </button>
        </div>
      </div>
      <div className={'card card__active treatment-tile'}>
        <h2>Global Timezone</h2>
        <ul>
          <li>
            <label>Timezone:</label>
            <Select
              className="treatment-tile-dropdown"
              value={{ value: timezone, label: timezone }}
              isSearchable={true}
              onChange={v => setTimezone(v.value)}
              options={tzOptions}
            />
          </li>
        </ul>
        <div className={'treatment_actions'}>
          <div className="stretch" />
          <button
            className={'button button-regular button__gray'}
            onClick={() => {}}>
            Cancel
          </button>
          <button
            disabled={false}
            className={'button button-regular button__green'}
            onClick={() =>
              setGlobalTimezone({
                variables: { payload: JSON.stringify({ timezone }) },
              })
            }>
            Save
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminSurveyEditor;
