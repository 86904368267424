import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { graphql } from 'react-apollo';
import config from '../../config';

// Defaults for setting initial state
export const trendsViewDefaults = {
  trendsView: {
    __typename: 'trendsView',
    value: 'week',
    label: 'Week',
    startDate: config.trendsConfig.startDate,
    endDate: config.trendsConfig.endDate,
    selectedDate: moment().format('YYYY-MM-DD'),
    selectedIndicators: ['skin', 'itch', 'pain', 'stress', 'sleep'],
    selectedTrackers: ['treatment', 'triggers', 'stress', 'diet', 'sleep'],
  },
};

//Queries
const trendsViewQuery = gql`
  query trendsView {
    trendsView @client {
      value
      label
      startDate
      endDate
      selectedDate
      selectedIndicators
      selectedTrackers
    }
  }
`;

//Mutations

//Resolvers

//store
const store = {
  defaults: trendsViewDefaults,
};

//wrappers
const withTrendsView = compose(
  graphql(trendsViewQuery, {
    props: ({ data: { loading, error, trendsView } }) => ({
      loading,
      error,
      trendsView,
    }),
  }),
);

export { store, withTrendsView };
