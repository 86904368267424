import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import analytics from '../../libs/analytics';
// Queries
import { DailyUserData } from '../../libs/graphql/queries/data';
import MixpanelService, { MixpanelEvents, MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT } from '../../libs/mixpanel';
import Directions from '../shared/Directions';
import SubmitBar from '../shared/submit-bar';
import TrackerHeader from '../shared/tracker-header';
import TrackerInstructions from '../shared/TrackerInstructions';
import ReadView from './TreatmentTrackerReadView';
import WriteView from './TreatmentTrackerWriteView';

// Mutations

class TreatmentTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      write: true,
      instructions: false,
      notes: '',
      treatmentData: {},
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    let newState = {
      treatmentData: {},
      write: true,
    };

    newState = Object.assign(newState, this._buildTreatmentData(nextProps));
    newState.notes = nextProps.notes;
    this.setState(newState);
  }

  componentDidMount() {
    let newState = {
      treatmentData: {},
      write: true,
    };

    newState = Object.assign(newState, this._buildTreatmentData(this.props));
    newState.notes = this.props.notes;
    this.setState(newState);
  }

  submitData() {
    let badDataFlag = false;
    let data = this._flattenTreatments(this.state.treatmentData);
    let newErrors = this.state.errors;
    data.forEach(todData => {
      let invalid = Object.values(
        this.state.treatmentData[todData.treatmentID].metadata,
      ).every(bool => {
        return !bool;
      });
      if (todData.selected && invalid) {
        badDataFlag = true;
        newErrors[todData.treatmentID] = true;
      }
    });

    if (badDataFlag) {
      this.setState({
        errors: newErrors,
      });
      return;
    }

    return this.props
      .changeHandler({
        variables: {
          id: this.props.id,
          notes: this.state.notes,
          client_date: this.props.date || moment().format('YYYY-MM-DD'),
          data: data,
        },
        refetchQueries: () => [
          {
            query: DailyUserData,
            variables: {
              id: this.props.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
              readOnly: true,
            },
            fetchPolicy: 'cache-and-network',
          },
        ],
      })
      .then(res => {
        const Morning = [];
        const Afternoon = [];
        const Evening = [];
        const Bedtime = [];
        const Notes = this.state.notes;

        Object.values(this.state.treatmentData).forEach(treatment => {
          const { type, name, selected, metadata } = treatment;
          const { morning, afternoon, evening, bedtime } = metadata;
          if (morning) Morning.push(`${name}`);
          if (afternoon) Afternoon.push(`${name}`);
          if (evening) Evening.push(`${name}`);
          if (bedtime) Bedtime.push(`${name}`);
        });
        MixpanelService.track(MixpanelEvents.TreatmentTrackerUpdated, {
          Morning: Morning.join(', '),
          Afternoon: Afternoon.join(', '),
          Evening: Evening.join(', '),
          Bedtime: Bedtime.join(', '),
          TreatmentDate: moment.utc(this.props.date).format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`),
          Notes,
        });
        analytics().logEvent('any_track_item_updated', {});

        this.setState({
          write: false,
          errors: {},
        });
      });
  }

  onCancel() {
    let newState = {};
    // this means that there is already data in db and this was an update
    // we need to toggle back to read state with the previously submitted level
    if (this.props.data.length) {
      this.setState({
        write: false,
      });
    } else {
      newState = this._buildTreatmentData(this.props, 'treatments');
      newState.notes = '';
      this.setState(newState);
    }
  }

  // Prevent opening write view if instructions are open
  _toggleWrite() {
    if (!this.state.write && !this.state.instructions) {
      this.setState({
        write: true,
      });
    }
  }

  toggleInstructions() {
    let instructions = !this.state.instructions;
    this.setState({
      instructions,
    });
  }

  //essentially handles checkbox
  treatmentHandler(treatment, e) {
    let selected = e.target.checked;
    let newErrors = this.state.errors;
    let updatedTreatmentData = Object.assign({}, this.state.treatmentData);
    updatedTreatmentData[treatment].selected = selected;

    if (newErrors[treatment]) {
      delete newErrors[treatment];
    }
    this.setState({
      treatmentData: updatedTreatmentData,
      errors: newErrors,
    });
  }
  //using this to format data array before mutation
  _flattenTreatments(obj) {
    let treatments = Object.values(obj);
    let treatmentsArray = treatments.map(treatment => {
      let flat = Object.assign({}, treatment.metadata, {
        selected: treatment.selected,
        treatmentID: treatment.treatmentID,
      });
      return flat;
    });
    return treatmentsArray;
  }

  //handles the metadata toggling
  treatmentMetadataHandler(treatment, tod, e) {
    e.preventDefault();
    let newErrors = this.state.errors;
    let currentTodState = this.state.treatmentData[treatment].metadata[tod];
    let newCurrentTodState = !currentTodState;
    if (newErrors[treatment]) {
      delete newErrors[treatment];
    }
    let updatedTreatmentData = Object.assign({}, this.state.treatmentData);
    updatedTreatmentData[treatment].metadata[tod] = newCurrentTodState;
    this.setState({
      treatmentData: updatedTreatmentData,
      errors: newErrors,
    });
  }

  updateNotesInput(e) {
    e.preventDefault();
    let notes = e.target.value;
    this.setState({
      notes: notes,
    });
  }

  progressBadge() {
    if (!this.state.write) {
      return 'tracker-progress-complete';
    } else {
      return 'tracker-progress-incomplete';
    }
  }

  render() {
    const { write, treatmentData, settings, instructions } = this.state;
    return (
      <div
        className={`card tracker tracker-loaded tracker-${
          this.props.theme.title
        } ${this.progressBadge()} ${this.props.theme.class || ''} ${
          write
            ? this.props.theme.color + ' card__active tracker__active'
            : this.props.theme.color + ' tracker__inactive'
        }`}
        >
        <TrackerHeader
          title="Treatments"
          write={write}
          icon={this.props.icon}
          settings={this.props.settings}
          edit={this._toggleWrite.bind(this)}
          instructions={this.toggleInstructions.bind(this)}
          instructionsClass={instructions ? 'tracker-header__instructions' : ''}
        />
        <div
          className="tracker-body tracker-body__scroll"
          onClick={this._toggleWrite.bind(this)}>
          {instructions ? (
            <TrackerInstructions
              instructions={this.props.theme.instructionsText}
            />
          ) : null}
          {write && (
            <Directions directionText={this.props.theme.directionText} />
          )}
          <div
            className={`form tracker-body-form treatment ${
              write ? '' : 'tracker-body__grid'
            }`}>
            {!instructions ? (
              write ? (
                <WriteView
                  treatmentData={treatmentData}
                  errors={this.state.errors}
                  notes={this.state.notes}
                  treatmentHandler={this.treatmentHandler.bind(this)}
                  treatmentMetadataHandler={this.treatmentMetadataHandler.bind(
                    this,
                  )}
                  updateNotesInput={this.updateNotesInput.bind(this)}
                />
              ) : (
                <ReadView
                  treatmentData={treatmentData}
                  errors={this.state.errors}
                  notes={this.state.notes}
                />
              )
            ) : null}
          </div>
        </div>
        {write && (
          <SubmitBar
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.submitData.bind(this)}
            disableSubmit={this.state.instructions}
            disableCancel={this.state.instructions}
          />
        )}
      </div>
    );
  }

  _buildTreatmentData(props) {
    let newState = {
      treatmentData: {},
      write: true,
    };
    let userTreatments = props.treatments.reduce((acc, t) => {
      acc[t.id] = t;
      return acc;
    }, {});
    let existingTreatmentData = props.data;
    props.treatments.forEach(treatment => {
      let matchingTreatment;
      let formattedTreatment;
      if (!existingTreatmentData.length) {
        //no data submitted just need to render list of treatments from treatment list
        formattedTreatment = {
          type: treatment.type,
          name: treatment.name,
          selected: !!treatment.selected,
          treatmentID: treatment.id,
          active: treatment.active,
          metadata: {
            morning: !!treatment.morning,
            afternoon: !!treatment.afternoon,
            evening: !!treatment.evening,
            bedtime: !!treatment.bedtime,
          },
        };
        newState.treatmentData[treatment.id] = formattedTreatment;
      } else {
        matchingTreatment = existingTreatmentData.find(
          treat => treat.id === treatment.id,
        );
        formattedTreatment = {
          type: treatment.type,
          name: treatment.name,
          selected: matchingTreatment ? !!matchingTreatment.selected : false,
          treatmentID: treatment.id,
          active: treatment.active,
          metadata: {
            morning: matchingTreatment ? !!matchingTreatment.morning : false,
            afternoon: matchingTreatment
              ? !!matchingTreatment.afternoon
              : false,
            evening: matchingTreatment ? !!matchingTreatment.evening : false,
            bedtime: matchingTreatment ? !!matchingTreatment.bedtime : false,
          },
        };
        newState.treatmentData[treatment.id] = formattedTreatment;
        if (matchingTreatment && matchingTreatment.selected && newState.write) {
          //if we have a selected item, this means we need to go into read state
          newState.write = false;
        }
      }
    });
    return newState;
  }
}

export default TreatmentTracker;
