import React from 'react';

export default function WaterGlass() {
  return (
    <svg className="svg water-glass-svg" viewBox="0 0 40 60">
      <g>
        <path
          className="svg-water-glass__glass"
          d="M19.998529,0 C8.95400803,0 0,1.97884751 0,4.41904313 C0,5.25335847 5.33294106,56.0184421 5.33294106,56.7590738 C5.33294106,58.5487862 11.8995691,60 19.998529,60 C28.0983776,60 34.6641169,58.5487862 34.6641169,56.7590738 C34.6641169,56.0184421 39.9970579,5.25335847 39.9970579,4.41904313 C39.9970579,1.97884751 31.0439387,0 19.998529,0 Z"
          fill="#E6E6E8"
        />
        <path
          className="svg-water-glass__water"
          d="M31.194297,12.777647 C25.5351577,12.777647 25.5351577,11 19.8777961,11 C14.2222121,11 14.2222121,12.777647 8.56751691,12.777647 C5.14199111,12.777647 3.78920172,12.1270282 2,11.6123994 C2.51196234,16.740911 3.51811055,26.5170808 4.43715406,35.445313 C5.53662874,46.1360821 6.18369226,52.4511732 6.55255401,56.1077931 C6.55255401,56.1077931 9.51855806,58.6258301 19.8822402,58.6258301 C30.2476999,58.6258301 33.2101487,56.1077931 33.2101487,56.1077931 C33.5790105,52.4511732 34.226074,46.1360821 35.3246598,35.445313 C36.245481,26.5046373 37.252518,16.7115799 37.7644804,11.6123994 C35.9743898,12.1270282 34.6198228,12.777647 31.194297,12.777647"
          fillOpacity="0.599999964"
          fill="#2FC6F4"
        />
      </g>
    </svg>
  );
}
