import React from 'react';

export default function TreatmentsBlueIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4297 9.35625C14.4032 9.33345 14.3634 9.33398 14.3375 9.35731L10.8171 12.5999C10.7964 12.619 10.789 12.6476 10.7985 12.6741C10.8075 12.7006 10.8319 12.7181 10.86 12.7202L12.3732 12.8109L10.5308 15.4649C10.5106 15.4941 10.5159 15.5333 10.5425 15.5567C10.5552 15.5683 10.5716 15.5736 10.5881 15.5736C10.6045 15.5736 10.6215 15.5673 10.6347 15.5551L14.1545 12.313C14.1752 12.2945 14.1826 12.2653 14.1731 12.2388C14.1641 12.2128 14.1397 12.1948 14.1121 12.1932L12.599 12.1026L14.4408 9.44797C14.4615 9.41934 14.4562 9.37958 14.4297 9.35625Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8039 12.4569L12.6277 12.3097L12.4805 11.1335C12.4781 11.1144 12.4631 11.0987 12.4433 11.0963C12.4197 11.0932 12.3982 11.11 12.3952 11.1335L12.248 12.3097L11.0718 12.4569C11.0526 12.4593 11.0369 12.4743 11.0345 12.4941C11.0315 12.5177 11.0482 12.5392 11.0718 12.5423L12.248 12.6895L12.3952 13.8657C12.3975 13.8848 12.4126 13.9005 12.4324 13.9029C12.4559 13.906 12.4775 13.8892 12.4805 13.8657L12.6277 12.6895L13.8039 12.5423C13.823 12.5399 13.8387 12.5249 13.8411 12.5051C13.8442 12.4815 13.8275 12.46 13.8039 12.4569Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1571 13.5669L10.5878 13.4958L10.5167 12.9265C10.5143 12.9074 10.4993 12.8917 10.4795 12.8893C10.4559 12.8862 10.4344 12.9029 10.4314 12.9265L10.3603 13.4958L9.79101 13.5669C9.77188 13.5692 9.75617 13.5843 9.75378 13.6041C9.75071 13.6276 9.76744 13.6492 9.79101 13.6522L10.3603 13.7233L10.4314 14.2926C10.4337 14.3117 10.4488 14.3274 10.4686 14.3298C10.4921 14.3329 10.5137 14.3161 10.5167 14.2926L10.5878 13.7233L11.1571 13.6522C11.1762 13.6498 11.1919 13.6348 11.1943 13.615C11.1974 13.5914 11.1806 13.5699 11.1571 13.5669Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8042 8.0704C10.8142 8.07654 10.8264 8.07928 10.8377 8.0844C10.8722 7.88563 10.9497 7.69814 11.0655 7.53319C11.0539 7.52909 11.045 7.52123 11.0327 7.51816L9.68919 7.19269C9.65367 7.18416 9.61508 7.19884 9.59493 7.23163C9.57034 7.27227 9.58298 7.32555 9.62362 7.35048L10.8042 8.0704Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.43665 8.74289L7.18215 8.96112L7.40038 10.7066C7.40516 10.7449 7.43522 10.7763 7.47517 10.7814C7.5223 10.7872 7.56533 10.7537 7.57114 10.7066L7.78937 8.96112L9.53487 8.74289C9.57312 8.73811 9.60454 8.70806 9.60966 8.6681C9.61547 8.62097 9.582 8.57794 9.53487 8.57213L7.78937 8.3539L7.57114 6.60841C7.56636 6.57016 7.5363 6.53874 7.49635 6.53361C7.44922 6.52781 7.40619 6.56128 7.40038 6.60841L7.18215 8.3539L5.43665 8.57213C5.3984 8.57692 5.36698 8.60697 5.36186 8.64693C5.35605 8.69406 5.38952 8.73709 5.43665 8.74289Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.057 10.4408C9.90331 10.2872 9.78822 10.1376 9.69908 9.99414L5.42294 14.1091L5.42191 14.1101L5.41884 14.1128L5.41918 14.1132C5.35737 14.175 5.30614 14.2488 5.27096 14.3345C5.1265 14.6828 5.29214 15.0824 5.64048 15.2269C5.90311 15.3358 6.19477 15.2679 6.38465 15.0787L6.38499 15.079L10.504 10.7981C10.3603 10.7089 10.211 10.5945 10.057 10.4408Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8183 7.95478L10.0782 9.62925C10.15 9.7662 10.2579 9.91749 10.4191 10.0787C10.5803 10.2395 10.7316 10.3478 10.8685 10.4192L12.543 8.67949H12.5426L12.543 8.67914C12.7431 8.47936 12.7431 8.15491 12.543 7.95478C12.3429 7.75466 12.0184 7.75466 11.8183 7.95478Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3952 7.20173C11.4761 7.14469 11.5629 7.09449 11.6578 7.05487C11.7524 7.01594 11.8491 6.98998 11.9457 6.97325C11.9409 6.96232 11.9402 6.95105 11.9341 6.94046L11.2138 5.76018C11.195 5.7291 11.1571 5.71202 11.1199 5.72125C11.0735 5.73252 11.0451 5.77896 11.0561 5.82541L11.3815 7.16928C11.3846 7.18021 11.3911 7.1908 11.3952 7.20173Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9793 7.16943L13.3052 5.82556C13.3137 5.79004 13.299 5.75145 13.2659 5.73164C13.2252 5.70671 13.172 5.71935 13.1474 5.76033L12.4271 6.94061C12.4213 6.95052 12.4182 6.96315 12.4135 6.97408C12.6119 7.00858 12.7994 7.0861 12.9643 7.20187C12.9684 7.1906 12.9766 7.18138 12.9793 7.16943Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4426 7.79416C13.4819 7.88876 13.5075 7.98541 13.5246 8.08206C13.5352 8.07728 13.5468 8.07659 13.557 8.07045L14.7377 7.35019C14.7687 7.3314 14.7855 7.29349 14.7763 7.25627C14.7653 7.20982 14.7185 7.18148 14.6721 7.1924L13.3286 7.51821C13.3173 7.52094 13.307 7.52743 13.2958 7.53153C13.3528 7.61247 13.4034 7.69922 13.4426 7.79416Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7034 9.57923C12.6088 9.6185 12.5122 9.64377 12.4155 9.66085C12.4203 9.67178 12.421 9.68339 12.4271 9.69329L13.1474 10.8739C13.1662 10.905 13.2041 10.9221 13.2413 10.9129C13.2878 10.9016 13.3161 10.8548 13.3052 10.8087L12.9794 9.46482C12.9766 9.45389 12.9702 9.4433 12.9661 9.43237C12.8851 9.48941 12.7984 9.53961 12.7034 9.57923Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7376 9.28373L13.5569 8.56381C13.547 8.55766 13.5347 8.55493 13.5235 8.5498C13.489 8.74823 13.4115 8.93572 13.296 9.10067C13.3073 9.10511 13.3162 9.11297 13.3285 9.11604L14.6723 9.44151C14.7079 9.45005 14.7461 9.43536 14.7663 9.40257C14.7912 9.36193 14.7782 9.30866 14.7376 9.28373Z"
        fill="white"
      />
    </svg>
  );
}
