import React from 'react';

const ChangeZipCode = ({
  zip,
  timezone,
  newZipCode,
  updateInput,
  updateZipCode,
  clearInput,
}) => {
  return (
    <>
      <h3 className="title">
        Change ZIP Code
      </h3>
      <p className='description'>
        Your zip code is used to see how environmental and weather forecasts
        might affect symptoms. Currently, only US ZIP codes can be used.
      </p>
      <h3 className="title">
        ZIP Code: {zip} <span className="red-asterisk">*</span>
      </h3>
      <form className="form">
        <div className="form-item form__zip">
          <label className="form-input-label-settings text__left">New Zip Code</label>
          <input
            className="form-input-field"
            maxLength={5}
            placeholder="US Zip Code"
            value={newZipCode}
            onChange={updateInput}
          />
        </div>
      </form>
      <div className="form-item-right card-footer">
        <button
          className="button button__secondary100-inverted  button__btn-14-600 button-rectangle-medium"
          onClick={clearInput}>
          Cancel
        </button>
        <button
          className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
          disabled={!/\d{5}/.test(newZipCode)}
          onClick={updateZipCode}>
          Save
        </button>
      </div>
    </>
  );
};

export default ChangeZipCode;
