import React from 'react';

export default function EveningIcon({ isSelect, errorSelect }) {
  let fillCircle = isSelect ? '#1D62ED' : '#E7EAF1';
  let fillPath = isSelect ? '#E7EAF1' : errorSelect ? '#E95D42' : '#1D62ED';
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fillCircle} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6256 10.1393C16.624 10.1187 16.6317 10.0995 16.6279 10.0789L16.1496 7.01799C16.1312 6.89709 16.0356 6.79531 15.9078 6.77542C15.7494 6.75093 15.6009 6.85959 15.5757 7.01799L15.0974 10.0789C15.0951 10.098 15.099 10.1194 15.0974 10.1393C15.3484 10.1041 15.6025 10.0789 15.8627 10.0789C16.1228 10.0789 16.3753 10.1033 16.6256 10.1393Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5217 11.1789C12.5294 11.1973 12.5432 11.2134 12.5523 11.2317C12.7513 11.0748 12.9587 10.9264 13.1844 10.7963C13.4094 10.6662 13.6412 10.5614 13.8754 10.4673C13.8632 10.4504 13.8609 10.4298 13.8471 10.4137L11.9027 8.00174C11.8254 7.90685 11.693 7.86629 11.5721 7.91297C11.4221 7.97113 11.3479 8.13948 11.406 8.28869L12.5217 11.1789Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8416 13.4198C10.8569 13.432 10.8775 13.4389 10.8944 13.4504C10.9885 13.2147 11.0941 12.9829 11.2242 12.7571C11.3535 12.5321 11.5019 12.3248 11.6573 12.1266C11.6389 12.1174 11.6259 12.1021 11.6068 12.0944L8.71654 10.978C8.60253 10.9336 8.46708 10.9657 8.38597 11.0668C8.28573 11.1915 8.30486 11.3744 8.42959 11.4746L10.8416 13.4198Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5065 16.2007C10.5256 16.2037 10.5471 16.1991 10.5677 16.2007C10.5318 15.9497 10.5065 15.6956 10.5065 15.4354C10.5065 15.176 10.531 14.9227 10.567 14.6725C10.5463 14.6741 10.5272 14.6672 10.5065 14.6702L7.44565 15.1485C7.32474 15.1676 7.22297 15.2625 7.20384 15.3903C7.17859 15.5487 7.28725 15.6979 7.44565 15.7224L10.5065 16.2007Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2803 15.1483L21.2194 14.67C21.2003 14.667 21.1789 14.6716 21.1582 14.67C21.1942 14.921 21.2194 15.1751 21.2194 15.4352C21.2194 15.6946 21.1949 15.9479 21.159 16.1982C21.1796 16.1966 21.1988 16.2035 21.2194 16.2005L24.2803 15.7222C24.4012 15.7031 24.5022 15.6082 24.5221 15.4804C24.5474 15.322 24.4387 15.1728 24.2803 15.1483Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0664 12.1249C20.2233 12.3239 20.3717 12.5313 20.5018 12.757C20.6319 12.982 20.7367 13.2139 20.8309 13.448C20.8477 13.4358 20.8684 13.4327 20.8844 13.4197L23.2964 11.4753C23.3913 11.398 23.4311 11.2648 23.3844 11.1447C23.327 10.9947 23.1586 10.9205 23.0094 10.9779L20.1192 12.0943C20.1008 12.102 20.0848 12.1158 20.0664 12.1249Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1717 11.2299C19.1809 11.2115 19.1962 11.1985 19.2038 11.1794L20.3203 8.28913C20.3647 8.17435 20.3325 8.03967 20.2315 7.95856C20.1068 7.85755 19.9239 7.87744 19.8237 8.00217L17.8785 10.4141C17.8662 10.4294 17.8593 10.4493 17.8486 10.4662C18.0836 10.5603 18.3154 10.6659 18.5412 10.7967C18.7661 10.9261 18.9735 11.0738 19.1717 11.2299Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.863 25.2383L18.5642 22.5371C18.7885 22.3129 18.7885 21.9494 18.5642 21.7252C18.34 21.501 17.9773 21.501 17.7531 21.7252L16.4369 23.0414V19.8351C16.4369 19.5183 16.1798 19.2612 15.863 19.2612C15.5462 19.2612 15.2891 19.5183 15.2891 19.8351V23.0414L13.9729 21.7252C13.8612 21.6135 13.7143 21.5569 13.5674 21.5569C13.4205 21.5569 13.2735 21.6135 13.1618 21.7252C12.9376 21.9494 12.9376 22.3129 13.1618 22.5371L15.863 25.2383Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7067 17.3481H8.01971C7.70291 17.3481 7.4458 17.6053 7.4458 17.9221C7.4458 18.2389 7.70291 18.496 8.01971 18.496H23.7067C24.0235 18.496 24.2806 18.2389 24.2806 17.9221C24.2806 17.6053 24.0235 17.3481 23.7067 17.3481Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.863 11.2266C13.5383 11.2266 11.6543 13.1105 11.6543 15.4353C11.6543 15.697 11.6818 15.9518 11.727 16.2005H19.999C20.0441 15.9518 20.0717 15.697 20.0717 15.4353C20.0717 13.1105 18.1877 11.2266 15.863 11.2266Z"
        fill={fillPath}
      />
    </svg>
  );
}
