import React from 'react';
import { UilMultiply } from '@iconscout/react-unicons';

const FilterTag = ({ name, onDelete }) => {
  return (
    <span className='filter-list-item'>
      {name}
      <UilMultiply onClick={onDelete} size='14' className='tag-delete' />
    </span>
  );
};

const FiltersList = ({
  severityFilters,
  toggleSeverityFilter,
  favoriteFilter,
  toggleFavoriteFilter,
  bodyPartFilter,
  toggleBodyPartFilter,
  resetAll,
}) => {
  return (
    <div className='filter-list-container'>
      <span className='filter-list-title'>Filters applied:</span>
      <div className='filter-list-buttons'>
        {Object.keys(severityFilters).map(key => {
          if (severityFilters[key]) {
            return (
              <FilterTag
                key={key}
                name={key}
                onDelete={() => toggleSeverityFilter(key)}
              />
            );
          }
          return null;
        })}
        {favoriteFilter && 
        <FilterTag
          name={'Favorites'}
          onDelete={() => toggleFavoriteFilter()}
        />}
        {bodyPartFilter.map(idBodyPart => {
          return <FilterTag
            key={idBodyPart}
            name={idBodyPart.split('_').map(s => s[0].toUpperCase() + s.substr(1)).join(' ')}
            onDelete={() => toggleBodyPartFilter(idBodyPart)}
          />
        })}
        <span
          className='filter-list-remove'
          onClick={resetAll}
        >
          Remove all
        </span>
      </div>
    </div>
  );
};

export default FiltersList;
