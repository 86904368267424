import React, { Fragment } from 'react';
import { WaterGlass } from '../../assets/icons';
import Directions from '../shared/Directions';

const DietReadView = props => {
  return (
    <Fragment>
      <Directions directionText="My Foods" />
      <ul className="diet-read-list">
        {displayFoods(props.data.foods, props.data.custom_foods)}
      </ul>
      <hr />
      <div className="diet-water-wrapper diet-water-wrapper__read">
        {/* <div className="diet-water__glasses"> */}
        <div
          className={
            'diet-water__glasses' +
            (props.data.cups_water === 0 ? '-empty' : '')
          }>
          {displayGlasses(props.data.cups_water)}
        </div>
        <div className="diet-water__count">
          <span className="glass__count title title__orange title__semibold">
            {props.data.cups_water} cups
          </span>
          <span className="glass__text paragraph">of water</span>
        </div>
      </div>
    </Fragment>
  );
};

export default DietReadView;

const displayGlasses = n => {
  let icons = [];
  if (n === 0) {
    icons.push(
      <span className="water-glass-item__empty" key={n}>
        <WaterGlass />
      </span>,
    );
  } else {
    for (let i = 1; i <= n; i++) {
      icons.push(
        <span className="water-glass-item" key={i}>
          <WaterGlass />
        </span>,
      );
    }
  }

  return icons;
};

const displayFoods = (foods, custom_foods) => {
  let allFoods = (foods && foods.concat(custom_foods)) || [];
  let renderFoods = allFoods.map((food, i) => {
    return (
      <li
        className="title title__semibold title__orange title__centered diet-food-item"
        key={i}>
        {food}
      </li>
    );
  });
  return renderFoods;
};
