import React from 'react';

export default function PhototherapyIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#1D62ED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2651 11.1284H9.56308C9.17558 11.1284 8.86108 10.8139 8.86108 10.4264V9.7244C8.86108 9.33689 9.17558 9.0224 9.56308 9.0224H10.2651C10.6526 9.0224 10.9671 9.33689 10.9671 9.7244V10.4264C10.9671 10.8139 10.6526 11.1284 10.2651 11.1284Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.616 5.86331C10.616 6.25082 10.3015 6.56531 9.91403 6.56531C9.52653 6.56531 9.21204 6.25082 9.21204 5.86331C9.21204 5.47581 9.52653 5.16132 9.91403 5.16132C10.3015 5.16132 10.616 5.47581 10.616 5.86331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4403 7.79365C10.4403 8.08463 10.2048 8.32015 9.91383 8.32015C9.62285 8.32015 9.38733 8.08463 9.38733 7.79365C9.38733 7.50267 9.62285 7.26715 9.91383 7.26715C10.2048 7.26715 10.4403 7.50267 10.4403 7.79365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21204 14.2874C9.21204 13.8999 9.52653 13.5854 9.91403 13.5854C10.3015 13.5854 10.616 13.8999 10.616 14.2874C10.616 14.6749 10.3015 14.9894 9.91403 14.9894C9.52653 14.9894 9.21204 14.6749 9.21204 14.2874Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38733 12.3566C9.38733 12.0657 9.62285 11.8301 9.91383 11.8301C10.2048 11.8301 10.4403 12.0657 10.4403 12.3566C10.4403 12.6476 10.2048 12.8831 9.91383 12.8831C9.62285 12.8831 9.38733 12.6476 9.38733 12.3566Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43174 6.60043C7.70587 6.87456 7.70587 7.31893 7.43174 7.59306C7.15761 7.86719 6.71325 7.86719 6.43912 7.59306C6.16499 7.31893 6.16499 6.87456 6.43912 6.60043C6.71325 6.3263 7.15761 6.3263 7.43174 6.60043Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6729 8.08981C8.87859 8.29514 8.87859 8.62894 8.6729 8.83428C8.46757 9.03996 8.13377 9.03996 7.92843 8.83428C7.72275 8.62894 7.72275 8.29514 7.92843 8.08981C8.13377 7.88413 8.46757 7.88413 8.6729 8.08981Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3961 13.5501C12.122 13.276 12.122 12.8317 12.3961 12.5575C12.6703 12.2834 13.1146 12.2834 13.3888 12.5575C13.6629 12.8317 13.6629 13.276 13.3888 13.5501C13.1146 13.8243 12.6703 13.8243 12.3961 13.5501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1551 12.061C10.9494 11.8556 10.9494 11.5218 11.1551 11.3165C11.3605 11.1108 11.6943 11.1108 11.8996 11.3165C12.1053 11.5218 12.1053 11.8556 11.8996 12.061C11.6943 12.2666 11.3605 12.2666 11.1551 12.061Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.702 9.37341C6.0895 9.37341 6.40399 9.68791 6.40399 10.0754C6.40399 10.4629 6.0895 10.7774 5.702 10.7774C5.31449 10.7774 5 10.4629 5 10.0754C5 9.68791 5.31449 9.37341 5.702 9.37341Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63233 9.54865C7.92331 9.54865 8.15883 9.78417 8.15883 10.0751C8.15883 10.3661 7.92331 10.6016 7.63233 10.6016C7.34135 10.6016 7.10583 10.3661 7.10583 10.0751C7.10583 9.78417 7.34135 9.54865 7.63233 9.54865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1262 10.7774C13.7387 10.7774 13.4242 10.4629 13.4242 10.0754C13.4242 9.68791 13.7387 9.37341 14.1262 9.37341C14.5137 9.37341 14.8282 9.68791 14.8282 10.0754C14.8282 10.4629 14.5137 10.7774 14.1262 10.7774Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1953 10.6016C11.9043 10.6016 11.6688 10.3661 11.6688 10.0751C11.6688 9.78417 11.9043 9.54865 12.1953 9.54865C12.4863 9.54865 12.7218 9.78417 12.7218 10.0751C12.7218 10.3661 12.4863 10.6016 12.1953 10.6016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43912 12.5575C6.71325 12.2834 7.15761 12.2834 7.43174 12.5575C7.70587 12.8317 7.70587 13.276 7.43174 13.5502C7.15761 13.8243 6.71325 13.8243 6.43912 13.5502C6.16499 13.276 6.16499 12.8317 6.43912 12.5575Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92843 11.3165C8.13377 11.1108 8.46757 11.1108 8.6729 11.3165C8.87859 11.5218 8.87859 11.8556 8.6729 12.061C8.46757 12.2666 8.13377 12.2666 7.92843 12.061C7.72275 11.8556 7.72275 11.5218 7.92843 11.3165Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3888 7.59306C13.1146 7.86719 12.6703 7.86719 12.3961 7.59306C12.122 7.31893 12.122 6.87456 12.3961 6.60043C12.6703 6.3263 13.1146 6.3263 13.3888 6.60043C13.6629 6.87456 13.6629 7.31893 13.3888 7.59306Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8996 8.83428C11.6943 9.03996 11.3605 9.03996 11.1551 8.83428C10.9494 8.62894 10.9494 8.29514 11.1551 8.08981C11.3605 7.88413 11.6943 7.88413 11.8996 8.08981C12.1053 8.29514 12.1053 8.62894 11.8996 8.83428Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2651 11.1284H9.56308C9.17558 11.1284 8.86108 10.8139 8.86108 10.4264V9.7244C8.86108 9.33689 9.17558 9.0224 9.56308 9.0224H10.2651C10.6526 9.0224 10.9671 9.33689 10.9671 9.7244V10.4264C10.9671 10.8139 10.6526 11.1284 10.2651 11.1284Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.616 5.86331C10.616 6.25082 10.3015 6.56531 9.91403 6.56531C9.52653 6.56531 9.21204 6.25082 9.21204 5.86331C9.21204 5.47581 9.52653 5.16132 9.91403 5.16132C10.3015 5.16132 10.616 5.47581 10.616 5.86331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4403 7.79365C10.4403 8.08463 10.2048 8.32015 9.91383 8.32015C9.62285 8.32015 9.38733 8.08463 9.38733 7.79365C9.38733 7.50267 9.62285 7.26715 9.91383 7.26715C10.2048 7.26715 10.4403 7.50267 10.4403 7.79365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21204 14.2874C9.21204 13.8999 9.52653 13.5854 9.91403 13.5854C10.3015 13.5854 10.616 13.8999 10.616 14.2874C10.616 14.6749 10.3015 14.9894 9.91403 14.9894C9.52653 14.9894 9.21204 14.6749 9.21204 14.2874Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38733 12.3566C9.38733 12.0657 9.62285 11.8301 9.91383 11.8301C10.2048 11.8301 10.4403 12.0657 10.4403 12.3566C10.4403 12.6476 10.2048 12.8831 9.91383 12.8831C9.62285 12.8831 9.38733 12.6476 9.38733 12.3566Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.43174 6.60043C7.70587 6.87456 7.70587 7.31893 7.43174 7.59306C7.15761 7.86719 6.71325 7.86719 6.43912 7.59306C6.16499 7.31893 6.16499 6.87456 6.43912 6.60043C6.71325 6.3263 7.15761 6.3263 7.43174 6.60043Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6729 8.08981C8.87859 8.29514 8.87859 8.62894 8.6729 8.83428C8.46757 9.03996 8.13377 9.03996 7.92843 8.83428C7.72275 8.62894 7.72275 8.29514 7.92843 8.08981C8.13377 7.88413 8.46757 7.88413 8.6729 8.08981Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3961 13.5501C12.122 13.276 12.122 12.8317 12.3961 12.5575C12.6703 12.2834 13.1146 12.2834 13.3888 12.5575C13.6629 12.8317 13.6629 13.276 13.3888 13.5501C13.1146 13.8243 12.6703 13.8243 12.3961 13.5501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1551 12.061C10.9494 11.8556 10.9494 11.5218 11.1551 11.3165C11.3605 11.1108 11.6943 11.1108 11.8996 11.3165C12.1053 11.5218 12.1053 11.8556 11.8996 12.061C11.6943 12.2666 11.3605 12.2666 11.1551 12.061Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.702 9.37341C6.0895 9.37341 6.40399 9.68791 6.40399 10.0754C6.40399 10.4629 6.0895 10.7774 5.702 10.7774C5.31449 10.7774 5 10.4629 5 10.0754C5 9.68791 5.31449 9.37341 5.702 9.37341Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63233 9.54865C7.92331 9.54865 8.15883 9.78417 8.15883 10.0751C8.15883 10.3661 7.92331 10.6016 7.63233 10.6016C7.34135 10.6016 7.10583 10.3661 7.10583 10.0751C7.10583 9.78417 7.34135 9.54865 7.63233 9.54865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1262 10.7774C13.7387 10.7774 13.4242 10.4629 13.4242 10.0754C13.4242 9.68791 13.7387 9.37341 14.1262 9.37341C14.5137 9.37341 14.8282 9.68791 14.8282 10.0754C14.8282 10.4629 14.5137 10.7774 14.1262 10.7774Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1953 10.6016C11.9043 10.6016 11.6688 10.3661 11.6688 10.0751C11.6688 9.78417 11.9043 9.54865 12.1953 9.54865C12.4863 9.54865 12.7218 9.78417 12.7218 10.0751C12.7218 10.3661 12.4863 10.6016 12.1953 10.6016Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43912 12.5575C6.71325 12.2834 7.15761 12.2834 7.43174 12.5575C7.70587 12.8317 7.70587 13.276 7.43174 13.5502C7.15761 13.8243 6.71325 13.8243 6.43912 13.5502C6.16499 13.276 6.16499 12.8317 6.43912 12.5575Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92843 11.3165C8.13377 11.1108 8.46757 11.1108 8.6729 11.3165C8.87859 11.5218 8.87859 11.8556 8.6729 12.061C8.46757 12.2666 8.13377 12.2666 7.92843 12.061C7.72275 11.8556 7.72275 11.5218 7.92843 11.3165Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3888 7.59306C13.1146 7.86719 12.6703 7.86719 12.3961 7.59306C12.122 7.31893 12.122 6.87456 12.3961 6.60043C12.6703 6.3263 13.1146 6.3263 13.3888 6.60043C13.6629 6.87456 13.6629 7.31893 13.3888 7.59306Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8996 8.83428C11.6943 9.03996 11.3605 9.03996 11.1551 8.83428C10.9494 8.62894 10.9494 8.29514 11.1551 8.08981C11.3605 7.88413 11.6943 7.88413 11.8996 8.08981C12.1053 8.29514 12.1053 8.62894 11.8996 8.83428Z"
        fill="white"
      />
    </svg>
  );
}
