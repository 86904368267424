import React from 'react';

export default function DoubleAngleRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M6.34505 6.21752C6.20382 6.07231 6.01069 5.98915 5.80815 5.98634C5.60561 5.98353 5.41025 6.06129 5.26505 6.20252C5.11984 6.34374 5.03668 6.53687 5.03387 6.73941C5.03106 6.94195 5.10882 7.13731 5.25005 7.28252L7.00505 9.00002L5.25005 10.7175C5.17975 10.7872 5.12395 10.8702 5.08588 10.9616C5.0478 11.053 5.0282 11.151 5.0282 11.25C5.0282 11.349 5.0478 11.4471 5.08588 11.5384C5.12395 11.6298 5.17975 11.7128 5.25005 11.7825C5.31977 11.8528 5.40272 11.9086 5.49411 11.9467C5.58551 11.9848 5.68354 12.0044 5.78255 12.0044C5.88156 12.0044 5.97959 11.9848 6.07098 11.9467C6.16237 11.9086 6.24532 11.8528 6.31505 11.7825L8.56505 9.53252C8.63534 9.46279 8.69114 9.37984 8.72922 9.28845C8.76729 9.19705 8.7869 9.09903 8.7869 9.00002C8.7869 8.90101 8.76729 8.80298 8.72922 8.71158C8.69114 8.62019 8.63534 8.53724 8.56505 8.46752L6.34505 6.21752ZM12.72 8.46752L10.47 6.21752C10.3288 6.07629 10.1373 5.99695 9.93755 5.99695C9.73782 5.99695 9.54627 6.07629 9.40505 6.21752C9.26382 6.35874 9.18448 6.55029 9.18448 6.75002C9.18448 6.94974 9.26382 7.14129 9.40505 7.28252L11.13 9.00002L9.40505 10.7175C9.33475 10.7872 9.27895 10.8702 9.24088 10.9616C9.2028 11.053 9.1832 11.151 9.1832 11.25C9.1832 11.349 9.2028 11.4471 9.24088 11.5384C9.27895 11.6298 9.33475 11.7128 9.40505 11.7825C9.47477 11.8528 9.55772 11.9086 9.64911 11.9467C9.74051 11.9848 9.83854 12.0044 9.93755 12.0044C10.0366 12.0044 10.1346 11.9848 10.226 11.9467C10.3174 11.9086 10.4003 11.8528 10.47 11.7825L12.72 9.53252C12.7923 9.4648 12.8505 9.38342 12.8912 9.2931C12.9319 9.20278 12.9542 9.10529 12.957 9.00627C12.9598 8.90725 12.943 8.80866 12.9074 8.71619C12.8719 8.62372 12.8184 8.5392 12.75 8.46752H12.72Z"
        fill="#092A6D"
      />
    </svg>
  );
}
