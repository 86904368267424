import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';
import ProgressBar from '../shared/ProgressBar';

const AppointmentProgress = props => {
  const { appointmentId, appointmentDate, metadata_added, disableLink } = {
    ...props,
  }; // Workaround for possible undefined properties.
  return (
    <Fragment>
      <div className="skin-progress tracker-theme-secondary100">
        {!metadata_added ? (
          <Fragment>
            <p className="paragraph paragraph__centered">
              Preparation is key to success when working with your provider.
              Follow these steps to get the most out of your next appointment.
            </p>
            <br />
            <ProgressBar
              className="skin-progress"
              totalSteps={5}
              currentStep={1}
              dot="number"
            />
          </Fragment>
        ) : null}
      </div>
      <div className="button-row">
        <Link
          to={`/provider-prep/appointments/${appointmentId}/surveys`}
          onClick={() => {
            if (metadata_added)
              MixpanelService.track(MixpanelEvents.ViewSummaryTapped, {
                AppointmentID: appointmentId,
              });
            else
              MixpanelService.track(MixpanelEvents.GetStartedTapped, {
                AppointmentDate: appointmentDate,
                AppointmentID: appointmentId,
              });
          }}>
          <button
            className={
              disableLink
                ? 'disabled-link button button__secondary100 button__btn-14-600 button-rectangle-medium'
                : 'button button__secondary100 button__btn-14-600 button-rectangle-medium'
            }>
            {metadata_added ? 'VIEW SUMMARY' : 'GET STARTED'}
          </button>
        </Link>
      </div>
    </Fragment>
  );
};

export default AppointmentProgress;
