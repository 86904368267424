import React from 'react';

export default function ProgressDot() {
  return (
    <svg width="12px" height="12px" viewBox="0 0 12 12">
      <circle
        className="progress-dot__background"
        cx="4"
        cy="4"
        r="4"
        transform="translate(2,2)"
      />
      <circle
        className="progress-dot__inner"
        cx="3"
        cy="3"
        r="3"
        transform="translate(3, 3)"
      />
    </svg>
  );
}
