import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
//SVGs
import {
  FullArrowForward,
  Grass,
  Humidity,
  Location,
  Mold,
  OpenClose,
  RagWeed,
  Settings,
  Temperature,
  VerticalLine,
} from '../../assets/icons';
import WeatherLogo from '../../assets/images/AW_RGB_Small.png';
import analytics from '../../libs/analytics';

class WeatherTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      write: props.write || false,
    };
  }

  _toggleWrite(e) {
    e.preventDefault();
    let currentState = this.state.write;
    this.setState({
      write: !currentState,
    });
  }

  trackLocationChangeEvent(e) {
    analytics().logEvent('any_track_item_updated', {});
  }

  render() {
    const { write } = this.state;
    let weatherData = this.props.data || {};
    return (
      <div
        className={`card tracker tracker-no-border tracker-loaded tracker-weather ${
          write
            ? 'tracker-weather__inactive'
            : 'card__active tracker-weather__active'
        }`}>
        <div className="tracker-header">
          <div className="header-item-wrapper">
            <Location />
            <h2 className="subtext subtext__black100">
              <span className="weather-location__label">Conditions for </span>
              <span className="weather-location__zip">{weatherData.zip}</span>
            </h2>
          </div>
          <div className="tracker-header__buttons header-icons__right weather-buttons">
            <Link
              to="/settings"
              className="button button-action"
              onClick={this.trackLocationChangeEvent.bind(this)}>
              <Settings />
            </Link>
            <button
              title="Minimize Weather Tracker"
              className="button button-action"
              onClick={this._toggleWrite.bind(this)}>
              <OpenClose />
            </button>
          </div>
        </div>
        {write ? null : (
          <Fragment>
            <div className="tracker-body">
              <div className="weather-item-wrapper weather-item-wrapper__city">
                <div className="weather-item">
                  <h3 className="headers headers__semibold headers__capitalize headers__navy">
                    {weatherData.city
                      ? weatherData.city + ', '
                      : 'US location not found'}
                    {weatherData.state || ''}
                  </h3>
                </div>
                <div className="weather-item">
                  <span className="weather-item-data">
                    <Link
                      className="subtext subtext__disabled subtext__uppercase"
                      to="/settings"
                      onClick={this.trackLocationChangeEvent.bind(this)}>
                      Change location <FullArrowForward />
                    </Link>
                  </span>
                </div>
                <div className="weather-logo">
                  <img src={WeatherLogo} />
                </div>
              </div>

              <div className="weather-item-wrapper weather-item-wrapper__temp">
                <Temperature />
                <div className="item-wrapper">
                  <div className="weather-item weather-item__odd">
                    <h4 className="subtext subtext__semibold subtext__uppercase subtext__light-text temp__subtext">
                      Temperature
                    </h4>
                    <h6 className="subtext subtext__semibold subtext__light-text">
                      High/Low
                    </h6>
                  </div>
                  <div className="weather-item weather-item__even">
                    <span className="weather-item-data weather-item-data__temp-hi">
                      {weatherData.temp_hi || '--'}
                      &#176;
                    </span>
                    <span className="weather-item-data weather-item-data__temp-lo">
                      /{weatherData.temp_low || '--'}
                      &#176;
                    </span>
                  </div>
                </div>
              </div>

              <div className="weather-item-wrapper weather-item-wrapper__humidity">
                <div className="weather-item weather-item__odd">
                  <Humidity />
                  <h4 className="subtext subtext__semibold subtext__uppercase subtext__light-text">
                    Humidity
                  </h4>
                </div>
                <div className="weather-item weather-item__even">
                  <span className="weather-item-data">
                    {weatherData.humidity || '--'}%
                  </span>
                </div>
              </div>
              <VerticalLine />

              <div className="weather-item-wrapper weather-item-wrapper__mold">
                <div className="weather-item weather-item__odd">
                  <Mold />
                  <h4 className="subtext subtext__semibold subtext__uppercase subtext__light-text">
                    Mold
                  </h4>
                </div>
                <div className="weather-item weather-item__even">
                  <span className="weather-item-data">
                    {weatherData.mold || '--'}
                  </span>
                </div>
              </div>

              <div className="weather-item-wrapper weather-item-wrapper__grass">
                <div className="weather-item weather-item__odd">
                  <Grass />
                  <h4 className="subtext subtext__semibold subtext__uppercase subtext__light-text">
                    grass
                  </h4>
                </div>
                <div className="weather-item weather-item__even">
                  <span className="weather-item-data">
                    {weatherData.grass || '--'}
                  </span>
                </div>
              </div>

              <div className="weather-item-wrapper weather-item-wrapper__ragweed">
                <div className="weather-item weather-item__odd">
                  <RagWeed />
                  <h4 className="subtext subtext__semibold subtext__uppercase subtext__light-text">
                    Ragweed
                  </h4>
                </div>
                <div className="weather-item weather-item__even">
                  <span className="weather-item-data">
                    {weatherData.ragweed || '--'}
                  </span>
                </div>
              </div>
            </div>
            <div className="weather-footer">
              <img className="weather-logo" src={WeatherLogo} alt="" aria-hidden="true" />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default WeatherTracker;
