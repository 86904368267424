import React from 'react';

export default function TrendsIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#1D62ED" />
      <path
        d="M8.66667 14.6666C8.93188 14.6666 9.18624 14.5612 9.37377 14.3737C9.56131 14.1862 9.66667 13.9318 9.66667 13.6666C9.66992 13.6333 9.66992 13.5998 9.66667 13.5666L11.5267 11.7066H11.68H11.8333L12.9067 12.7799C12.9067 12.7799 12.9067 12.8133 12.9067 12.8333C12.9067 13.0985 13.012 13.3528 13.1996 13.5404C13.3871 13.7279 13.6414 13.8333 13.9067 13.8333C14.1719 13.8333 14.4262 13.7279 14.6138 13.5404C14.8013 13.3528 14.9067 13.0985 14.9067 12.8333V12.7799L17.3333 10.3333C17.5311 10.3333 17.7245 10.2746 17.8889 10.1647C18.0534 10.0548 18.1815 9.89866 18.2572 9.71594C18.3329 9.53321 18.3527 9.33214 18.3141 9.13816C18.2755 8.94418 18.1803 8.766 18.0404 8.62615C17.9006 8.48629 17.7224 8.39105 17.5284 8.35247C17.3344 8.31388 17.1334 8.33368 16.9507 8.40937C16.7679 8.48506 16.6117 8.61323 16.5019 8.77768C16.392 8.94213 16.3333 9.13547 16.3333 9.33325C16.3301 9.36651 16.3301 9.4 16.3333 9.43325L13.9267 11.8399H13.82L12.6667 10.6666C12.6667 10.4014 12.5613 10.147 12.3738 9.95948C12.1862 9.77194 11.9319 9.66659 11.6667 9.66659C11.4014 9.66659 11.1471 9.77194 10.9596 9.95948C10.772 10.147 10.6667 10.4014 10.6667 10.6666L8.66667 12.6666C8.40145 12.6666 8.1471 12.7719 7.95956 12.9595C7.77202 13.147 7.66667 13.4014 7.66667 13.6666C7.66667 13.9318 7.77202 14.1862 7.95956 14.3737C8.1471 14.5612 8.40145 14.6666 8.66667 14.6666ZM17.6667 17.3333H6.33333V5.99992C6.33333 5.82311 6.2631 5.65354 6.13807 5.52851C6.01305 5.40349 5.84348 5.33325 5.66667 5.33325C5.48986 5.33325 5.32029 5.40349 5.19526 5.52851C5.07024 5.65354 5 5.82311 5 5.99992V17.9999C5 18.1767 5.07024 18.3463 5.19526 18.4713C5.32029 18.5963 5.48986 18.6666 5.66667 18.6666H17.6667C17.8435 18.6666 18.013 18.5963 18.1381 18.4713C18.2631 18.3463 18.3333 18.1767 18.3333 17.9999C18.3333 17.8231 18.2631 17.6535 18.1381 17.5285C18.013 17.4035 17.8435 17.3333 17.6667 17.3333Z"
        fill="white"
      />
    </svg>
  );
}
