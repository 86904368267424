const validatePassword = (password, password_confirm) => {
  let longEnough,
    numberRegex,
    specialCharacterRegex,
    upperCaseRegex,
    lowerCaseRegex;
  let rulesTestResults = {};
  // test match
  let match = (password === '' && password_confirm === '') || (password && password_confirm && password === password_confirm);
  rulesTestResults.match = match;
  //test length
  longEnough = password.length >= 8;
  rulesTestResults.length = longEnough;
  //test password rules from Cognito
  numberRegex = /[0-9]/;
  rulesTestResults.num = numberRegex.test(password);
  specialCharacterRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
  rulesTestResults.special = specialCharacterRegex.test(password);
  upperCaseRegex = /[A-Z]/;
  rulesTestResults.upper = upperCaseRegex.test(password);
  lowerCaseRegex = /[a-z]/;
  rulesTestResults.lower = lowerCaseRegex.test(password);

  return rulesTestResults;
};

export default validatePassword;
