import React from 'react';
import { ProgressStep } from '../../assets/icons/';

const SkinTrackerProcess = props => {
  return (
    <div className="half-content-child">
      <div className="skin-content__grid">
        <div className="progress-bar-item progress-bar-item__active">
          <ProgressStep step={props.page} />
        </div>
        <h3 className="headers headers__neutral-black">{props.title}</h3>
        <h5 className="help-text">{props.instructions}</h5>
        <p className="paragraph skin-tracker__location">{props.location}</p>
        {props.children}
      </div>
    </div>
  );
};

export default SkinTrackerProcess;
