import PropTypes from 'prop-types';
import React from 'react';
import { ApolloProvider } from 'react-apollo';

const Provider = ({ children, client }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

Provider.propTypes = {
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
};

export default Provider;
