import React, { Component } from 'react';
import Appointments from '../components/providerprep/Appointments';
import analytics from '../libs/analytics';

class AppointmentsContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    analytics().setCurrentScreen('manage_open');
  }

  render() {
    return <Appointments {...this.props} />;
  }
}

export default AppointmentsContainer;
