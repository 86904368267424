import React, { useState, useEffect, useRef } from 'react';
import { HelperIcon, IconTrends, TrendsIcon } from '../../assets/icons';
import TrendsChart from './TrendsChart';
import TrendsFilters from './TrendsFilters';
import TrendsSummary from './TrendsSummary';
import TrendsViewPicker from './TrendsViewPicker';
import Tutorial from '../shared/Tutorial';

const Trends = ({ selectedDate, ...props }) => {
  const [activeTutorialId, setActiveTutorialId] = useState(null);
  const [isTrendsSummaryLoaded, setIsTrendsSummaryLoaded] = useState(false);
  const [isTrendsChartLoaded, setIsTrendsChartLoaded] = useState(false);
  const trendsSummaryRef = useRef(null);

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  useEffect(() => {
    if (selectedDate && isTrendsSummaryLoaded && isTrendsChartLoaded && trendsSummaryRef.current) {
      trendsSummaryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedDate, isTrendsSummaryLoaded, isTrendsChartLoaded]);

  const TrendsTutorial = {
    titles: ['Learn About Your Trends'],
    subtitles: [``],
    texts: [
      'Visit the Learn tab to see snapshots of each week you tracked. Changes in your symptoms can reveal patterns in trigger exposure and treatment effects.',
    ],
    urlsGif: [''],
  };

  const handleTrendsSummaryLoad = () => {
    setIsTrendsSummaryLoaded(true);
  };

  const handleTrendsChartLoad = () => {
    setIsTrendsChartLoaded(true);
  };

  return (
    <div className="trends">
      <header className="trends-header">
        <div className="sidebar-title trends-sidebar-header">
          <TrendsIcon />
          <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
            Trends
          </h1>
          <Tutorial
            titles={TrendsTutorial.titles}
            texts={TrendsTutorial.texts}
            subtitles={TrendsTutorial.subtitles}
            urlsGif={TrendsTutorial.urlsGif}
            onClickNext={activeTutorialUpdate.bind(this)}>
            <div className="tooltip-trigger">
              <span className="icon-instructions">
                <HelperIcon />
              </span>
            </div>
          </Tutorial>
        </div>
        <div className="trends-header-content">
          <TrendsViewPicker selectedDate={selectedDate} />
        </div>
      </header>
      <div className="trends-content">
        <aside className="trends-sidebar">
          <TrendsFilters />
        </aside>
        <div className="trends-data">
          <div className="trends-data-card">
            <div className="trendschart">
              <TrendsChart onLoad={handleTrendsChartLoad}/>
            </div>
          </div>
          <div className="trends-data-card summary" ref={trendsSummaryRef}>
            <TrendsSummary onLoad={handleTrendsSummaryLoad} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;
