import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, Route } from 'react-router-dom';
import {
  AngleLeftIcon,
  BackArrow,
  HelperIcon,
  Instructions,
  SurveyIcon,
  SurveysNewIcon,
  SurveysPhotoIcon,
} from '../assets/icons';
import analytics from '../libs/analytics';
import mixpanel from 'mixpanel-browser';
import MixpanelService from '../libs/mixpanel';
import Tutorial from '../components/shared/Tutorial';

const Survey = ({ match, location, refetch }) => {
  analytics().setCurrentScreen('survey_open');
  if (location.state.name) {
    MixpanelService.track('SurveySelected', {
      SurveyName: location.state.name,
    });
  }

  useEffect(() => {
    window.document.documentElement.style.overflowY = 'hidden';
    return () => {
      window.document.documentElement.style.overflowY = 'scroll';
      refetch();
    };
  });

  const { surveyId } = match.params;
  return (
    <iframe
      title={'Survey'}
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      src={`/api/surveys/${surveyId}`}
      // src={`http://localhost:8060/api/surveys/${surveyId}`} //Only for localhost
      frameBorder="0"
    />
  );
};

const SurveyCard = ({
  name,
  completed,
  description,
  started,
  time_to_complete,
  uri,
  id,
  setHeader,
  end_date,
  ...props
}) => {
  return (
    <NavLink
      disabled={completed}
      className={`survey-card${completed ? ' completed' : ' incomplete'}`}
      to={{ pathname: `/surveys/${id}`, state: { name } }}
      onClick={e => {
        setHeader(name);
      }}>
      <h1>{name}</h1>
      <h4>{description}</h4>
      <h5>
        {time_to_complete || null}
        {end_date
          ? (time_to_complete ? ', ' : '') +
            'Complete by: ' +
            moment(end_date).format('MM/DD/YYYY')
          : ''}
      </h5>
    </NavLink>
  );
};

const SurveyList = ({ me, setHeader, userSurveys, refetch }) => {
  analytics().setCurrentScreen('survey_page_open');

  if (userSurveys.length === 0)
    return (
      <div className="surveys">
        <br />
        <div className={'no-surveys'}>
          <SurveysPhotoIcon />
          <h1 className={'title'}>You’re all set!</h1>
          <h2 className={'sub-title'}>
            You don’t have any assigned surveys to complete. New surveys will
            appear here.
          </h2>
        </div>
      </div>
    );

  return (
    <Fragment>
      <Route
        exact
        path="/surveys"
        render={() => (
          <div className="surveys">
            <div className={'survey-count'}>
              <h3>{userSurveys.length} total surveys!</h3>
            </div>
            {userSurveys.map((survey, i) => (
              <SurveyCard setHeader={setHeader} {...survey} key={i} />
            ))}
          </div>
        )}
      />
      <Route
        exact
        path="/surveys/:surveyId"
        render={({ match, location }) => (
          <Survey match={match} location={location} refetch={refetch} />
        )}
      />
    </Fragment>
  );
};

const Surveys = ({ me, location, userSurveys, refetch }) => {
  const [header, setHeader] = useState('');
  const [activeTutorialId, setActiveTutorialId] = useState(null);

  useEffect(() => {
    setHeader('');
  }, []);

  const activeTutorialUpdate = id => {
    setActiveTutorialId(id);
  };

  const SurveysTutorial = {
    titles: ['Surveys', 'Surveys'],
    subtitles: [``, `Find your surveys`],
    texts: [
      'You live with your eczema every day, but how well do you really know it? Get a new understanding of it and how to manage it.',
      'If you have a new survey available, a notification will appear in the menu so you can access it easily. Surveys are clearly labeled with topics and descriptions for you to browse.',
    ],
    urlsGif: ['', ''],
  };

  return (
    <>
      <div className="main-dashboard">
        <div className="main-dashboard__content">
          <div className={'survey-dashboard-main'}>
            <header>
              <div className="date-bar">
                <div className="main-dashboard__sidebar">
                  <div className="sidebar-title">
                    <SurveysNewIcon />
                    <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                      Surveys
                    </h1>
                    <Tutorial
                      titles={SurveysTutorial.titles}
                      texts={SurveysTutorial.texts}
                      subtitles={SurveysTutorial.subtitles}
                      urlsGif={SurveysTutorial.urlsGif}
                      onClickNext={activeTutorialUpdate.bind(this)}>
                      <div className="tooltip-trigger">
                        <span className="icon-instructions">
                          <HelperIcon />
                        </span>
                      </div>
                    </Tutorial>
                  </div>
                </div>
                <div className={`sidebar-title sidebar-margins`}>
                  {header && (
                    <div className={`button__wrapper`}>
                      <button
                        title="Back to survey list"
                        className="transparent-button"
                        onClick={e => {
                          setHeader('');
                          window.history.back();
                        }}>
                        <AngleLeftIcon />
                      </button>
                      <span className="date-bar__intro">{header}</span>
                    </div>
                  )}
                </div>
              </div>
            </header>
            <Route path="/surveys">
              <SurveyList
                setHeader={setHeader}
                me={me}
                userSurveys={userSurveys}
                refetch={refetch}
              />
            </Route>
          </div>
        </div>
      </div>
    </>
  );
};

export default Surveys;
