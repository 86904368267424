import React, { Component, Fragment } from 'react';
import { WaterGlass } from '../assets/icons';
import Incrementor from '../components/shared/Incrementor';
import DietOptions from '../components/trackers/DietOptions';

class DietTrackerWriteContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length) {
      //hydrate the store
      this.props.hydrateDietStore({
        variables: {
          cups: this.props.data.cups_water,
          foods: this.props.data.foods,
          customFoods: this.props.data.custom_foods,
        },
      });
    }
  }

  render() {
    if (!this.props.store) return null; //need to wait until store gets hydrated
    return (
      <Fragment>
        <DietOptions
          checkHandler={this.updateFoodSelections.bind(this)}
          foodList={this.props.store.foods}
          customFoodList={this.props.store.customFoods}
          addCustomFood={this.props.addCustomFood}
          deleteCustomFood={this.props.deleteCustomFood}
          favoriteFoods={this.props.favoriteFoods}
        />
        <div className="diet-water-wrapper">
          <Incrementor
            value={this.props.store.cups_water}
            cb={this.updateCupsWater.bind(this)}
          />
          <div className="diet-water__glasses">
            {buildGlassIcons(this.props.store.cups_water)}
          </div>
        </div>
      </Fragment>
    );
  }

  updateCupsWater(value) {
    this.props.waterHandler({
      variables: {
        cups: value,
      },
    });
  }

  updateFoodSelections(selection, e) {
    this.props.foodHandler({
      variables: {
        food: selection,
        checked: e.target.checked,
      },
    });
  }
}

export default DietTrackerWriteContainer;

const buildGlassIcons = n => {
  let icons = [];

  for (let i = 1; i <= n; i++) {
    icons.push(
      <span className="water-glass-item" key={i}>
        <WaterGlass />
      </span>,
    );
  }

  return icons;
};
