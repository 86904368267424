import React from 'react';
import config from '../../config';

import { DietIcon } from '../../assets/icons/trackers';

const TrendsSummaryDiet = ({ dietData }) => {
  const renderList = () => {
    let foods = (dietData && dietData.foods) || [];
    let customFoods = (dietData && dietData.custom_foods) || [];
    let foodsList = foods.concat(customFoods).map((food, index) => {
      return (
        <li key={index}>
          <span className="trends-summary-text__14p600">{food}</span>
        </li>
      );
    });
    return foodsList;
  };

  return (
    <div className={`trends-summary-diet ${config.trackerTheme.diet}`}>
      <ul>
        {renderList()}
      </ul>
    </div>
  );
};

export default TrendsSummaryDiet;
