import { useEffect, useRef } from 'react';

import ECWDesktop from '../../assets/images/newBrandImages/ECW-Desktop.png';
import ECWMobile from '../../assets/images/newBrandImages/ECW-Phone.png';
import ECWWinner from '../../assets/images/newBrandImages/ECW-Winner.png';
import PlatformOptions from './PlatformOptions';

function Home() {
  const focusElement = useRef(null);

  useEffect(() => {
    if (focusElement.current) {
      focusElement.current.focus();
    }
  }, []);

  return (
    <section className="content-section content__center home-page__section-one">
      <div className="content-wrapper">
        <div className="content-image__hero">
          <div className="image__eczemawise image__eczemawise-award appear-to-left">
            <img src={ECWWinner} alt="" aria-hidden="true" />
          </div>
          <img
            className="image__eczemawise image__eczemawise-desktop appear-to-right"
            src={ECWDesktop}
            alt=""
            aria-hidden="true"
          />
          <img
            className="image__eczemawise image__eczemawise-mobile appear-to-top"
            src={ECWMobile}
            alt=""
            aria-hidden="true"
          />
        </div>
        <div className="content-section content__width">
          <h1
            className="title__homepage-large title__primary100"
            tabindex="0"
            ref={focusElement}>
            Get to know your
            <br />
            <span className="text__secondary100">eczema.</span>
          </h1>
          <p className="paragraph">
            You live with your eczema every day, but how well do you really know
            it? EczemaWise helps you see the bigger picture for better eczema
            management.
          </p>
          <PlatformOptions />
        </div>
      </div>
    </section>
  );
}

export default Home;
