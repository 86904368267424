import moment from 'moment-timezone';

const SEVERITIES = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

const PoscoradHistory = props => {
  const { history, averageLevel } = props;
  const lastPoscorad = history?.[0]?.level || null;
  let lastPoscoradDate = history?.[0]?.tracker_date || null;
  const averagePoscorad = averageLevel || null;

  const todayLabel = moment(lastPoscoradDate).isSameOrAfter(moment(), 'day')
  ? 'Today'
  : moment(lastPoscoradDate).format('MMM D, YYYY');

  const getSeverityLabel = level => {
    if (!level) {
      return '';
    }
    if (level <= 25) {
      return SEVERITIES.Mild;
    }
    if (level <= 50) {
      return SEVERITIES.Moderate;
    }
    return SEVERITIES.Severe;
  };

  return (
    <div className="poscorad-history">
      <div className="poscorad-item">
        <h4 className="poscorad-item-title">Last recorded</h4>
        <h4 className="poscorad-item-title">({todayLabel || '-'})</h4>
        <h3 className="poscorad-item-number">{lastPoscorad || '-'}</h3>
        <span
          className={`poscorad-item-severity ${getSeverityLabel(
            lastPoscorad,
          )}`}>
          {getSeverityLabel(lastPoscorad) || 'Not enough data'}
        </span>
      </div>
      <div className="poscorad-item">
        <h4 className="poscorad-item-title">Past 90 days</h4>
        <h4 className="poscorad-item-title">(average)</h4>
        <h3 className="poscorad-item-number">{averagePoscorad || '-'}</h3>
        <span
          className={`poscorad-item-severity ${getSeverityLabel(
            averagePoscorad,
          )}`}>
          {getSeverityLabel(averagePoscorad) || 'Not enough data'}
        </span>
      </div>
      <div className="poscorad-item poscorad-item-scale">
        <h4 className="poscorad-item-title">PO-SCORAD scale</h4>
        <div className="poscorad-scales">
          <div className="poscorad-scales-item"></div>
          <div className="poscorad-scales-item Moderate"></div>
          <div className="poscorad-scales-item Severe"></div>
          <div className="poscorad-scales-item Severe"></div>
        </div>
        <div className="poscorad-labels">
          <label>0</label>
          <label className={`severity ${SEVERITIES.Mild}`}>{SEVERITIES.Mild}</label>
          <label className="number-center">25</label>
          <label className={`severity ${SEVERITIES.Moderate}`}>{SEVERITIES.Moderate}</label>
          <label className="number-center">50</label>
          <label className={`severity ${SEVERITIES.Severe}`}>{SEVERITIES.Severe}</label>
          <label className="number-right">103</label>
        </div>
      </div>
    </div>
  );
};

export default PoscoradHistory;
