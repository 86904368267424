import { flowRight as compose } from 'lodash';
import React from 'react';
import { withFocus } from '../../apollo/stores/appointments';
import { ProgressStep } from '../../assets/icons';
import CheckboxList from '../shared/CheckboxList';
import SortableList from '../shared/SortableList';

const options = [
  'Talk about how to manage my symptoms',
  'Get or change my medication or treatment',
  'Get medical tests',
  'Discuss new treatment options',
  'Talk about a new health problem',
  'Discuss interest in clinical trials',
  'Other',
];

const FindYourFocus = props => {
  const focusElement = React.useRef(null);

  React.useEffect(() => {
    if (focusElement.current)
      focusElement.current.focus();
  }, []);
      
  let items = options.map(option => {
    return {
      changeHandler: checkBoxHandler.bind(null, props.updateIssues, option),
      checked: props.focusForm.issues.includes(option),
      label: option,
    };
  });

  return (
    <div className="tracker-body survey__find-your-focus">
      <div className="survey-title">
        <div className="survey-title__row">
          <ProgressStep step="1" />

          <h2 className="title title__black100" tabindex="0" ref={focusElement}>Find Focus</h2>
        </div>
        <p className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
          The more your provider knows about your medical situation, the better.
          Help them along by answering these questions.
        </p>
      </div>
      <form className="form">
        <div className="form-section form__border-top section__find-focus">
          <h3 className="title">1. At my appointment, I want to:</h3>
          <p className="help-text">Select all that apply</p>
          <CheckboxList list={items} />
        </div>
        <div className="form-section form__border-top section__sortable">
          <h3 className="title">
            2. What is important to you when considering an eczema treatment?
          </h3>

          <p className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
            Drag and drop in order of most important to least important
          </p>

          <div className="form-item-row">
            <div className="form-item">
              <SortableList
                items={props.focusForm.priorities}
                handleListSort={listSortHandler.bind(
                  null,
                  props.updatePriorities,
                )}
              />
            </div>

            <div className="form-item">
              <label className="form-input-label text__left">Notes</label>
              <textarea
                className="form-input-field"
                value={props.focusForm.notes}
                maxLength={250}
                onChange={inputHandler.bind(
                  null,
                  props.updateFocusNotes,
                  'notes',
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default compose(withFocus)(FindYourFocus);

const listSortHandler = (mutation, list) => {
  return mutation({
    variables: {
      priorities: list,
    },
  });
};

const checkBoxHandler = (mutation, issue, e) => {
  return mutation({
    variables: {
      issue: issue,
      checked: e.target.checked,
    },
  });
};

const inputHandler = (mutation, name, e) => {
  return mutation({
    variables: {
      name: name,
      value: e.target.value,
    },
  });
};
