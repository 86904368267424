import React, { Component } from 'react';
import { SkinBodyBack, SkinBodyFront } from '../../assets/images';

class SkinTrackerAreasAffected extends Component {
  render() {
    return (
      <div className="skin-areas-wrapper">
        <div className="skin-areas__single skin-areas__front">
          <SkinBodyFront
            onSelect={this.props.handleAreaSelected}
            areas_affected={this.props.areas_affected}
          />
          <p className="skin-areas-caption subtext subtext__centered subtext__capitalize subtext__semibold subtext__light-text">
            front
          </p>
        </div>
        <div className="skin-areas__single skin-areas__back">
          <SkinBodyBack
            onSelect={this.props.handleAreaSelected}
            areas_affected={this.props.areas_affected}
          />
          <p className="skin-areas-caption subtext subtext__centered subtext__capitalize subtext__semibold subtext__light-text">
            back
          </p>
        </div>
      </div>
    );
  }
}

export default SkinTrackerAreasAffected;
