import gql from 'graphql-tag';

export const AdminStats = gql`
  query AdminStats {
    adminStats {
      user {
        id
        count
        stat
      }
      tracker {
        id
        count
        stat
        avg_level
        sigma
      }
      activity {
        id
        count
        tracker_date
      }
    }
  }
`;
export const Users = gql`
  query AdminUsers {
    adminUsers {
      id
      name
      active
      blocked
      email
      admin
      notification_count
      profile {
        zip
        timezone
        persona_slug
      }
      updated_date
      created_date
      role_id
    }
  }
`;

export const Roles = gql`
  query AdminRoles {
    adminRoles {
      id
      name
      description
      active
      admin
    }
  }
`;
