import { createSlice } from '@reduxjs/toolkit';
import { fetchAppointments } from './appointments-thunks';

const initialState = {
  appointments: [],
  loading: false,
  error: null,
};

const appointmentsSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    setAppointments(state, action) {
      state.appointments = action.payload;
    },
    updateAppointment(state, action) {
      state.appointments = state.appointments.map(appointment => {
        if (appointment.id === action.payload.id) {
          return {
            ...appointment,
            ...action.payload,
          };
        }
        return appointment;
      });
    },
    deleteAppointment(state, action) {
      state.appointments = state.appointments.filter(
        appointment => appointment.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAppointments.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      state.appointments = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const appointmentActions = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
