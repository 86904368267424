import React from 'react';

export const UserInformation = ({ email, username }) => {
    return (
        <>
            <h3 className="title">
              User information
            </h3>
            <p className='description'>
              The email and name you provided during account creation. This cannot be changed.
            </p>
            <form className="form">
              <div className='form-item-row'>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    Email<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    placeholder="Email"
                    value={email}
                  />
                </div>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    Username<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    placeholder="Username"
                    value={username}
                  />
                </div>
              </div>
            </form>
        </>
    );
};
