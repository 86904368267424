import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { graphql } from 'react-apollo';

// Defaults for setting initial state
const trackerDefaults = {
  trackerDateStore: {
    __typename: 'trackerDate',
    id: 1,
    currentTrackerDate: moment().format('YYYY-MM-DD'),
  },
  triggerStore: {
    __typename: 'triggerTracker',
    data: [],
    selections: [],
  },
  skinStore: {
    __typename: 'skinTracker',
    dryness: 0,
    redness: 0,
    swelling: 0,
    oozing_scabs: 0,
    scratch_marks: 0,
    thickening_skin: 0,
    areas_affected: [],
    areas_selected: true, //??false or undefined???
    notes: '',
    infections: null,
    infections_notes: '',
    pictures: [],
  },
  dietStore: {
    __typename: 'dietTracker',
    foods: [],
    customFoods: [],
    cups_water: 0,
  },
  skinTrackerNavigation: {
    __typename: 'skinTrackerNavigation',
    currentPage: 1,
    subPage: 1,
  },
};

//Queries
const trackerDateStore = gql`
  query trackerDateStore {
    trackerDateStore @client {
      currentTrackerDate
    }
  }
`;

const triggerStore = gql`
  query triggerStore {
    triggerStore @client {
      data {
        typeid
        type
        trigger_id
        trigger
        selected
        trigger_selected
      }
      selections {
        id
        typeid
        checked
        __typename
      }
    }
  }
`;

const skinStore = gql`
  query skinStore {
    skinStore @client {
      dryness
      redness
      swelling
      oozing_scabs
      scratch_marks
      thickening_skin
      areas_affected
      areas_selected
      infections
      infections_notes
      notes
      pictures {
        __typename
        area_affected
        images {
          __typename
          id
          uri
        }
      }
    }
    skinTrackerNavigation @client {
      currentPage
      subPage
    }
  }
`;

const dietStore = gql`
  query dietStore {
    dietStore @client {
      foods
      cups_water
      customFoods
    }
  }
`;

const navQuery = gql`
  query skinTrackerNavigation {
    skinTrackerNavigation @client {
      __typename
      currentPage
      subPage
    }
  }
`;

//Mutations

const setTrackerDateMutation = gql`
  mutation setTrackerDate($date: String!) {
    setTrackerDate(date: $date) @client
  }
`;

const changeTrackerDateMutation = gql`
  mutation changeTrackerDate($direction: String!, $today: Boolean) {
    changeTrackerDate(direction: $direction, today: $today) @client
  }
`;

const resetTrackerDateMutation = gql`
  mutation resetTrackerDate {
    resetTrackerDate @client
  }
`;

const updateFoodsMutation = gql`
  mutation updateFoods($food: String!, $checked: Boolean!) {
    updateFoods(food: $food, checked: $checked) @client
  }
`;

const addCustomFoodMutation = gql`
  mutation addCustomFood($food: String!) {
    addCustomFood(food: $food) @client
  }
`;

const deleteCustomFoodMutation = gql`
  mutation deleteCustomFood($food: String!) {
    deleteCustomFood(food: $food) @client
  }
`;

const updateCupsWaterMutation = gql`
  mutation updateCupsWater($cups: Int!) {
    updateCupsWater(cups: $cups) @client
  }
`;

const hydrateDietStoreMutation = gql`
  mutation hydrateDietStore(
    $cups: Int!
    $foods: [String]
    $customFoods: [String]
  ) {
    hydrateDietStore(cups: $cups, foods: $foods, customFoods: $customFoods)
      @client
  }
`;

const resetDietStoreMutation = gql`
  mutation resetDietStore {
    resetDietStore @client
  }
`;

const updateTriggersSelectionsMutation = gql`
  mutation updateTriggersSelections(
    $typeid: ID!
    $type: String!
    $trigger_id: ID!
    $trigger: String!
    $trigger_selected: Boolean!
  ) {
    updateTriggersSelections(
      typeid: $typeid
      type: $type
      trigger_id: $trigger_id
      trigger: $trigger
      trigger_selected: $trigger_selected
    ) @client
  }
`;

const updateTriggerTypeSelectionMutation = gql`
  mutation updateTriggerTypeSelection($typeid: ID!, $checked: Boolean!) {
    updateTriggerTypeSelection(typeid: $typeid, checked: $checked) @client
  }
`;

const resetTriggerStoreMutation = gql`
  mutation resetTriggerStore {
    resetTriggerStore @client
  }
`;

const deleteTriggerMutation = gql`
  mutation deleteTrigger(
    $trigger_id: ID!
    $typeid: ID!
    $trigger: String!
    $selected: Boolean!
    $type: String!
  ) {
    deleteTrigger(
      trigger_id: $trigger_id
      typeid: $typeid
      trigger: $trigger
      selected: $selected
      type: $type
    ) @client
  }
`;

const hydrateSkinStoreMutation = gql`
  mutation hydrateSkinStore(
    $dryness: Int
    $redness: Int
    $swelling: Int
    $oozing_scabs: Int
    $scratch_marks: Int
    $thickening_skin: Int
    $areas_affected: [String]
    $areas_selected: Boolean
    $notes: String
    $infections: Boolean
    $infections_notes: String
    $pictures: [SkinDataImages]
  ) {
    hydrateSkinStore(
      dryness: $dryness
      redness: $redness
      swelling: $swelling
      oozing_scabs: $oozing_scabs
      scratch_marks: $scratch_marks
      thickening_skin: $thickening_skin
      areas_affected: $areas_affected
      areas_selected: $areas_selected
      notes: $notes
      infections: $infections
      infections_notes: $infections_notes
      pictures: $pictures
    ) @client
  }
`;

const updateInputMutation = gql`
  mutation UpdateInput($name: String!, $value: String!) {
    updateInputSkin(name: $name, value: $value) @client
  }
`;

const updateSkinScaleValueMutation = gql`
  mutation updateSkinScaleValue($name: String!, $value: Int!) {
    updateSkinScaleValue(name: $name, value: $value) @client
  }
`;

const navigateNextSkinTrackerMutation = gql`
  mutation navigateNextSkinTracker($subPageStart: Int!, $lastSubPage: Int) {
    navigateNextSkinTracker(
      subPageStart: $subPageStart
      lastSubPage: $lastSubPage
    ) @client
  }
`;

const navigateBackSkinTrackerMutation = gql`
  mutation navigateBackSkinTracker($subPageStart: Int!, $lastSubPage: Int) {
    navigateBackSkinTracker(
      subPageStart: $subPageStart
      lastSubPage: $lastSubPage
    ) @client
  }
`;

const handleAreaSelectedMutation = gql`
  mutation handleAreaSelected($id: String!) {
    handleAreaSelected(id: $id) @client
  }
`;

const handleAffectedAreasCheckBoxMutation = gql`
  mutation handleAffectedAreasCheckBox($checked: Boolean!) {
    handleAffectedAreasCheckBox(checked: $checked) @client
  }
`;

const handleInfectionsRadioMutation = gql`
  mutation handleInfectionsRadio($selection: Boolean!) {
    handleInfectionsRadio(selection: $selection) @client
  }
`;

const handlePicturesMutation = gql`
  mutation handlePictures($pictures: [SkinDataImages]!) {
    handlePictures(pictures: $pictures) @client
  }
`;

const resetSkinStoreMutation = gql`
  mutation resetSkinStore {
    resetSkinStore @client
  }
`;

const resetSkinNavigationMutation = gql`
  mutation resetSkinNavigation {
    resetSkinNavigation @client
  }
`;
//Fragments
const triggerFragment = gql`
  fragment trigger on triggerSelection {
    typeid
    type
    trigger_id
    trigger
    selected
    trigger_selected
  }
`;

const triggerTypeFragment = gql`
  fragment type on typeSelection {
    id
    typeid
    checked
    __typename
  }
`;

//Resolvers

const changeTrackerDate = (_, { direction, today }, { cache }) => {
  let currentTrackerDate = cache.readQuery({ query: trackerDateStore })
    .trackerDateStore.currentTrackerDate;
  let newTrackerDate =
    direction === 'back'
      ? moment(currentTrackerDate).subtract(1, 'days').format('YYYY-MM-DD')
      : today
      ? moment().format('YYYY-MM-DD')
      : moment(currentTrackerDate).add(1, 'days').format('YYYY-MM-DD');
  cache.writeData({
    data: {
      trackerDateStore: {
        __typename: 'trackerDate',
        id: 1,
        currentTrackerDate: newTrackerDate,
      },
    },
  });
  return null;
};

const setTrackerDate = (_, { date }, { cache }) => {
  cache.writeData({
    data: {
      trackerDateStore: {
        __typename: 'trackerDate',
        id: 1,
        currentTrackerDate: date,
      },
    },
  });
  return null;
};

const resetTrackerDate = (_, args, { cache }) => {
  cache.writeData({
    data: {
      trackerDateStore: {
        __typename: 'trackerDate',
        id: 1,
        currentTrackerDate: moment().format('YYYY-MM-DD'),
      },
    },
  });
  return null;
};

//update foods in diet tracker
const updateFoods = (_, { food, checked }, { cache }) => {
  let index;
  let currentFoodsList = cache.readQuery({ query: dietStore }).dietStore.foods;
  let newFoodsList = [];
  currentFoodsList.forEach(f => {
    newFoodsList.push(f);
  });
  if (checked) {
    newFoodsList.push(food);
  } else {
    index = newFoodsList.indexOf(food);
    newFoodsList.splice(index, 1);
  }
  cache.writeData({
    data: {
      dietStore: {
        __typename: 'dietTracker',
        foods: newFoodsList,
      },
    },
  });
  return null;
};

const addCustomFood = (_, { food }, { cache }) => {
  let currentFoodsList = cache.readQuery({ query: dietStore }).dietStore
    .customFoods;
  let newFoodsList = [];
  currentFoodsList.forEach(f => {
    newFoodsList.push(f);
  });
  newFoodsList.push(food);
  cache.writeData({
    data: {
      dietStore: {
        __typename: 'dietTracker',
        customFoods: newFoodsList,
      },
    },
  });
  return null;
};

const deleteCustomFood = (_, { food }, { cache }) => {
  let currentFoodsList = cache.readQuery({ query: dietStore }).dietStore
    .customFoods;
  let newFoodsList = [];
  currentFoodsList.forEach(f => {
    if (f !== food) {
      newFoodsList.push(f);
    }
  });

  cache.writeData({
    data: {
      dietStore: {
        __typename: 'dietTracker',
        customFoods: newFoodsList,
      },
    },
  });
  return null;
};

const updateCupsWater = (_, { cups }, { cache }) => {
  cache.writeData({
    data: {
      dietStore: {
        __typename: 'dietTracker',
        cups_water: cups,
      },
    },
  });

  return null;
};

const hydrateDietStore = (_, { cups, foods, customFoods }, { cache }) => {
  cache.writeData({
    data: {
      dietStore: {
        __typename: 'dietTracker',
        cups_water: cups,
        foods: foods,
        customFoods: customFoods,
      },
    },
  });
  return null;
};

const resetDietStore = (_, args, { cache }) => {
  cache.writeData({
    data: {
      dietStore: trackerDefaults.dietStore,
    },
  });
  return null;
};

//updates list of triggers selected
const updateTriggersSelections = (
  _,
  {
    typeid,
    type,
    trigger_id,
    trigger,
    selected = true,
    trigger_selected = true,
  },
  { cache },
) => {
  let currentTriggerStore = cache.readQuery({ query: triggerStore });
  let triggerSelection = cache.readFragment({
    fragment: triggerFragment,
    id: `triggerSelection:${typeid}${trigger_id}`,
  });
  if (!triggerSelection) {
    let currentTriggers = currentTriggerStore.triggerStore.data;
    let newTriggers = [];
    currentTriggers.forEach(trig => {
      newTriggers.push(trig);
    });
    newTriggers.push({
      typeid,
      type,
      trigger_id,
      trigger,
      selected,
      trigger_selected,
      __typename: 'triggerSelection',
    });
    cache.writeData({
      data: {
        triggerStore: {
          __typename: 'triggerTracker',
          data: newTriggers,
        },
      },
    });
  } else {
    cache.writeFragment({
      id: `triggerSelection:${typeid}${trigger_id}`,
      fragment: triggerFragment,
      data: {
        id: `triggerSelection:${typeid}${trigger_id}`,
        typeid,
        type,
        trigger_id,
        trigger,
        selected,
        trigger_selected,
        __typename: 'triggerSelection',
      },
    });
  }

  return null;
};

const updateTriggerTypeSelection = (_, { typeid, checked }, { cache }) => {
  let currentTriggerStore = cache.readQuery({ query: triggerStore });
  let typeSelection = cache.readFragment({
    fragment: triggerTypeFragment,
    id: `typeSelection:${typeid}`,
  });
  if (!typeSelection) {
    let currentSelections = currentTriggerStore.triggerStore.selections;
    let newSelections = [];
    currentSelections.forEach(selection => {
      newSelections.push(selection);
    });
    newSelections.push({
      id: typeid,
      typeid,
      checked,
      __typename: 'typeSelection',
    });
    cache.writeData({
      data: {
        triggerStore: {
          __typename: 'triggerTracker',
          selections: newSelections,
        },
      },
    });
  } else {
    //update existing object
    cache.writeFragment({
      id: `typeSelection:${typeid}`,
      fragment: triggerTypeFragment,
      data: {
        id: `typeSelection:${typeid}`,
        typeid,
        checked,
        __typename: 'typeSelection',
      },
    });
    if (!checked) {
      currentTriggerStore.triggerStore.data.forEach(selection => {
        if (selection.typeid === typeid) {
          //unselect all triggers for type that got unselected
          cache.writeFragment({
            id: `triggerSelection:${typeid}${selection.trigger_id}`,
            fragment: triggerFragment,
            data: {
              id: `triggerSelection:${typeid}${selection.trigger_id}`,
              typeid,
              type: selection.type,
              trigger_id: selection.trigger_id,
              trigger: selection.trigger,
              selected: false,
              trigger_selected: false,
              __typename: 'triggerSelection',
            },
          });
        }
      });
    }
  }

  return null;
};

const deleteTrigger = (
  _,
  { typeid, trigger_id, trigger, selected, type },
  { cache },
) => {
  cache.writeFragment({
    id: `triggerSelection:${typeid}${trigger_id}`,
    fragment: triggerFragment,
    data: {
      id: `triggerSelection:${typeid}${trigger_id}`,
      typeid,
      type,
      trigger_id,
      trigger,
      selected,
      trigger_selected: false,
      __typename: 'triggerSelection',
    },
  });

  return null;
};

const resetTriggerStore = (_, args, { cache }) => {
  let currentTriggerStore = cache.readQuery({ query: triggerStore });
  //unselect or uncheck all previously selected items
  currentTriggerStore.triggerStore.data.forEach(trigger => {
    updateTriggerTypeSelection(
      _,
      { typeid: trigger.typeid, checked: false },
      { cache },
    );
  });
  currentTriggerStore.triggerStore.selections.forEach(type => {
    updateTriggerTypeSelection(
      _,
      { typeid: type.typeid, checked: false },
      { cache },
    );
  });
  return null;
};

const resetSkinStore = (_, args, { cache }) => {
  cache.writeData({
    data: {
      skinStore: trackerDefaults.skinStore,
      skinTrackerNavigation: trackerDefaults.skinTrackerNavigation,
    },
  });
  return null;
};
const resetSkinNavigation = (_, args, { cache }) => {
  cache.writeData({
    data: {
      skinTrackerNavigation: trackerDefaults.skinTrackerNavigation,
    },
  });
  return null;
};

const updateInputSkin = (_, { name, value }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore[name] = value;
  cache.writeData({ data: updatedState });

  return null;
};

const hydrateSkinStore = (_, data, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState, { skinStore: data });
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore.__typename = 'skinTracker';
  cache.writeData({ data: updatedState });
  return null;
};

const updateSkinScaleValue = (_, { name, value }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore[name] = value;
  updatedState.skinStore.__typename = 'skinTracker';
  cache.writeData({ data: updatedState });
  return null;
};

const handleAreaSelected = (_, { id }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  let deletion = false;
  let newAreasSelected = [];
  currentState.skinStore.areas_affected.forEach(area => {
    if (id === area) {
      //this is a delete
      deletion = true;
    } else {
      newAreasSelected.push(area);
    }
  });

  if (!deletion) {
    newAreasSelected.push(id);
  }
  updatedState.skinStore.areas_affected = newAreasSelected;
  cache.writeData({ data: updatedState });
  return null;
};

const handleAffectedAreasCheckBox = (_, { checked }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore.areas_selected = !checked;
  if (checked) updatedState.skinStore.areas_affected = [];
  cache.writeData({ data: updatedState });
  return null;
};

const handleInfectionsRadio = (_, { selection }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore.infections = selection;
  cache.writeData({ data: updatedState });
  return null;
};

const handlePictures = (_, { pictures }, { cache }) => {
  let currentState = cache.read({ query: skinStore });
  let updatedState = Object.assign({}, currentState);
  updatedState.skinStore = Object.assign({}, updatedState.skinStore);
  updatedState.skinStore.pictures = pictures.map(picture => ({
    __typename: 'SkinDataImages',
    area_affected: picture.area_affected,
    images: picture.images.map(image => ({
      __typename: 'Image',
      ...image,
    })),
  }));
  cache.writeData({ data: updatedState });
  return null;
};

const navigateNextSkinTracker = (
  _,
  { subPageStart, lastSubPage },
  { cache },
) => {
  let currentState = cache.read({ query: navQuery });
  let currentPage = currentState.skinTrackerNavigation.currentPage;
  let currentSubPage = currentState.skinTrackerNavigation.subPage;
  let updatedState = Object.assign({}, currentState);

  if (
    currentPage < subPageStart ||
    currentPage > subPageStart ||
    (currentPage === subPageStart && currentSubPage === lastSubPage)
  ) {
    //if we are before the step with subpages increment current page by 1
    updatedState.skinTrackerNavigation.currentPage = currentPage + 1;
  } else if (currentPage === subPageStart && currentSubPage !== lastSubPage) {
    //if we are on the step with sub steps but not last substep, increment the substeps
    updatedState.skinTrackerNavigation.subPage = currentSubPage + 1;
  }
  cache.writeData({ data: updatedState });
  return null;
};

const navigateBackSkinTracker = (
  _,
  { subPageStart, lastSubPage },
  { cache },
) => {
  let currentState = cache.read({ query: navQuery });
  let currentPage = currentState.skinTrackerNavigation.currentPage;
  let currentSubPage = currentState.skinTrackerNavigation.subPage;
  let updatedState = Object.assign({}, currentState);

  if (
    currentPage < subPageStart ||
    currentPage > subPageStart ||
    (currentPage === subPageStart && currentSubPage === 1)
  ) {
    //if we are before the step with subpages increment current page by 1
    updatedState.skinTrackerNavigation.currentPage = currentPage - 1;
  } else if (currentPage === subPageStart && currentSubPage !== 1) {
    //if we are on the step with sub steps but not last substep, increment the substeps
    updatedState.skinTrackerNavigation.subPage = currentSubPage - 1;
  }
  cache.writeData({ data: updatedState });
  return null;
};

//store
const store = {
  defaults: trackerDefaults,
  mutations: {
    updateTriggersSelections,
    updateTriggerTypeSelection,
    deleteTrigger,
    resetTriggerStore,
    updateSkinScaleValue,
    navigateNextSkinTracker,
    navigateBackSkinTracker,
    resetSkinStore,
    handleAreaSelected,
    handleAffectedAreasCheckBox,
    handleInfectionsRadio,
    handlePictures,
    hydrateSkinStore,
    resetSkinNavigation,
    updateInputSkin,
    updateFoods,
    addCustomFood,
    deleteCustomFood,
    updateCupsWater,
    hydrateDietStore,
    resetDietStore,
    changeTrackerDate,
    setTrackerDate,
    resetTrackerDate,
  },
};

//wrappers
const withTrackerDate = compose(
  graphql(trackerDateStore, {
    props: ({ data: { loading, error, trackerDateStore } }) => ({
      loading,
      error,
      trackerDateStore,
    }),
  }),
  graphql(changeTrackerDateMutation, { name: 'changeTrackerDate' }),
  graphql(setTrackerDateMutation, { name: 'setTrackerDate' }),
  graphql(resetTrackerDateMutation, { name: 'resetTrackerDate' }),
);

const withDietTracker = compose(
  graphql(dietStore, {
    props: ({ data: { loading, error, dietStore } }) => ({
      loading,
      error,
      dietStore,
    }),
  }),
  graphql(updateFoodsMutation, { name: 'updateFoods' }),
  graphql(addCustomFoodMutation, { name: 'addCustomFood' }),
  graphql(deleteCustomFoodMutation, { name: 'deleteCustomFood' }),
  graphql(updateCupsWaterMutation, { name: 'updateCupsWater' }),
  graphql(hydrateDietStoreMutation, { name: 'hydrateDietStore' }),
  graphql(resetDietStoreMutation, { name: 'resetDietStore' }),
);

const withTriggerTracker = compose(
  graphql(triggerStore, {
    props: ({ data: { loading, error, triggerStore } }) => ({
      loading,
      error,
      triggerStore,
    }),
  }),
  graphql(updateTriggersSelectionsMutation, {
    name: 'updateTriggersSelections',
  }),
  graphql(updateTriggerTypeSelectionMutation, {
    name: 'updateTriggerTypeSelection',
  }),
  graphql(deleteTriggerMutation, { name: 'deleteTrigger' }),
  graphql(resetTriggerStoreMutation, { name: 'resetTriggerStore' }),
);

const withSkinTracker = compose(
  graphql(skinStore, {
    props: ({
      data: { loading, error, skinStore, skinTrackerNavigation },
    }) => ({
      loading,
      error,
      skinStore,
      skinTrackerNavigation,
    }),
  }),
  graphql(updateSkinScaleValueMutation, { name: 'updateSkinScaleValue' }),
  graphql(navigateNextSkinTrackerMutation, { name: 'navigateNextSkinTracker' }),
  graphql(navigateBackSkinTrackerMutation, { name: 'navigateBackSkinTracker' }),
  graphql(resetSkinStoreMutation, { name: 'resetSkinStore' }),
  graphql(handleAreaSelectedMutation, { name: 'handleAreaSelected' }),
  graphql(handleAffectedAreasCheckBoxMutation, {
    name: 'handleAffectedAreasCheckBox',
  }),
  graphql(handleInfectionsRadioMutation, { name: 'handleInfectionsRadio' }),
  graphql(handlePicturesMutation, { name: 'handlePictures' }),
  graphql(hydrateSkinStoreMutation, { name: 'hydrateSkinStore' }),
  graphql(resetSkinNavigationMutation, { name: 'resetSkinNavigation' }),
  graphql(updateInputMutation, { name: 'updateInput' }),
);

export {
  store,
  withTriggerTracker,
  withSkinTracker,
  withDietTracker,
  withTrackerDate,
};
