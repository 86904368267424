import React from 'react';
import {
  Allergens,
  Chemicals,
  Irritants,
  Metals,
  Other,
} from '../../assets/icons';
import TrackerListItem from '../shared/TrackerListItem';
import TrackerListItemSelect from '../shared/TrackerListItemSelect';

//trigger type and trigger values
const Trigger = props => {
  let triggerImage;
  switch (props.type.toLowerCase()) {
    case 'environmental allergens':
      triggerImage = <Allergens />;
      break;
    case 'chemicals':
      triggerImage = <Chemicals />;
      break;
    case 'irritants':
      triggerImage = <Irritants />;
      break;
    case 'metals':
      triggerImage = <Metals />;
      break;
    default:
      triggerImage = <Other />;
  }

  return (
    <TrackerListItem
      image={triggerImage}
      label={props.write ? props.type : props.value}>
      {props.write && props.selected ? (
        <div className="tracker-list-item__select form-item-select">
          <TrackerListItemSelect
            type={props.type} //trigger type
            value={props.value} //the current value of the select
            addItem={props.changeHandler} // handler for the add button
            deleteHandler={props.deleteHandler} //need to add a function to delete triggers from list
            values={props.values} //trigger options for a given type
            placeholder={props.placeholder} //placeholder text for the select
            addedItems={props.alreadySelectedTriggers} //items that have already been added to list (i.e. triggers)
          />
        </div>
      ) : null}
    </TrackerListItem>
  );
};

export default Trigger;
