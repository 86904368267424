import React, { useState } from 'react';

const GroupEditor = ({ id, treatment_group, onSubmit, onCancel }) => {
  const [editing, setEditing] = useState(id === null);

  const original = {
    id,
    treatment_group,
  };

  const [localValues, setNewValue] = useState({
    id,
    treatment_group,
  });

  const [edited, hasBeenEdited] = useState(false);

  const updateValue = e => {
    const newVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    hasBeenEdited(true);
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  if (!editing) {
    return (
      <div
        className={'card card__active treatment-tile editing'}
        onClick={e => setEditing(true)}>
        <h2>{localValues.treatment_group || ''}</h2>
      </div>
    );
  }

  return (
    <div className={'card card__active treatment-tile'}>
      <h2>{localValues.treatment_group || ''}</h2>
      <ul>
        <li>
          <label>Group Name</label>
          <input
            type={'text'}
            value={localValues.treatment_group || ''}
            name={'treatment_group'}
            onChange={updateValue}
          />
        </li>
      </ul>
      {editing && (
        <div className={'treatment_actions'}>
          <div className="stretch"></div>
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              setNewValue(original);
              setEditing(false);
              onCancel();
            }}>
            Cancel
          </button>
          <button
            disabled={!edited}
            className={'button button-regular button__green'}
            onClick={e => {
              onSubmit(localValues);
              setEditing(false);
            }}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default GroupEditor;
