import React, { Component } from 'react';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value }) => {
  return (
    <li className="sortable-list-item-wrapper">
      <span className="sortable-list-item">
        <span className="sortable-list__label">{value}</span>
      </span>
    </li>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul className="sortable-list-wrapper">
      {items.map((value, index) => {
        return (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        );
      })}
    </ul>
  );
});

class SortableComponent extends Component {
  state = {
    items: [],
  };

  componentDidMount() {
    let sortList = this.props.items;
    if (sortList.length) {
      this.setState({
        items: sortList,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state;

    this.setState(
      {
        items: arrayMove(items, oldIndex, newIndex),
      },
      () => {
        this.props.handleListSort(this.state.items);
      },
    );
  };

  render() {
    const { items } = this.state;

    return (
      <div className="sortable-container">
        <h4 className="extra-info extra-info__cobalt extra-info__regular">
          Most Important
        </h4>
        <div className="sortable-list-container">
          <SortableList
            items={items}
            onSortEnd={this.onSortEnd}
            helperClass="sortable-list-item-helper"
          />
        </div>
        <h4 className="extra-info extra-info__cobalt extra-info__regular">
          Least Important
        </h4>
      </div>
    );
  }
}

export default SortableComponent;
