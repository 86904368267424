import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

const TreatmentDetails = props => {
  if (!Object.keys(props.treatment).length) return null;
  return (
    <div className="treatment-details__body">
       <ReactTooltip
        type="light"
        multiline={true}
        className={'pricingTooltip'}
        place="top"
        html={true}
      />
      <div className="treatment-details__title-section">
        {buildName(props.treatment)}
        <form className="form">
          <div className="form-checkbox-wrapper">
            <input
              className="form-input-checkbox"
              type="checkbox"
              onChange={handleTreatmentSave.bind(
                null,
                props.treatment.selectionId,
                props.handleTreatmentSave,
              )}
              checked={props.saved}
            />
            <span className="headers headers__uppercase headers__semibold headers__light-text">
              SAVE
            </span>
          </div>
        </form>
      </div>
      <div className="treatment-details__names">
        {props.treatment.brand_names && props.treatment.brand_names.length
          ? buildDetails(props.treatment)
          : null}
        {props.treatment.generic ? (
          <h5 className="subtext subtext__uppercase subtext__semibold treatment-details__generic">
            Generic Available
          </h5>
        ) : null}
      </div>
      <div className="treatment-details__details">
        <div className="treatment-details__details-row">
          <h5 className="subtext subtext__uppercase subtext__semibold">
            How does it work?
          </h5>
          <p className="paragraph">{props.metadata && props.metadata.how}</p>
        </div>
        <div>
          <h5 className="subtext subtext__uppercase subtext__semibold">
            Who is it for?
          </h5>
          <p className="paragraph">{props.metadata && props.metadata.who}</p>
        </div>
        <div>
          <h5 className="subtext subtext__uppercase subtext__semibold">
            How do I use it?
          </h5>
          <p className="paragraph">{props.metadata && props.metadata.use}</p>
        </div>
        <div>
          <h5 className="subtext subtext__uppercase subtext__semibold">
            How well does it work?
          </h5>
          <p className="paragraph">
            {props.metadata && props.metadata.efficacy}
          </p>
        </div>
        <div>
          <h5 className="subtext subtext__uppercase subtext__semibold">
            What are common side effects?
          </h5>
          <p className="paragraph">
            {props.metadata && props.metadata.effects}
          </p>
        </div>
        <div>
          <h5 className="subtext subtext__uppercase subtext__semibold">
            How much does it cost?
            <span
              data-tip={PRICING_SCALE_TOOLTIP}
              className="admin-help material-icons">
              help_outline
            </span>
          </h5>
          <p className="paragraph">
            {buildCostIcons(props.metadata && props.metadata.cost)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TreatmentDetails;

const handleTreatmentSave = (id, cb, e) => {
  let checked = e.target.checked;
  cb(id, checked);
};

const buildCostIcons = cost => {
  if (!cost) {
    return <span>0</span>;
  }
  let icons = [];
  for (let i = 1; i <= cost; i++) icons.push(<span key={i}>$</span>);
  return icons;
};

const buildName = treatment => {
  if (treatment.group === 'Topicals' && treatment.class === 'Steroids') {
    return (
      <Fragment>
        <span className="title title__cobalt title__semibold">Steroid</span>
        <h1 className="title__large title__cobalt title__semibold">
          {treatment.formulation}
        </h1>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <h1 className="title__large title__cobalt title__semibold treatment-detail__name">
          {treatment.name}
        </h1>
        <span className="title title__italic title__light-text treatment-detail__pronunciation">
          {treatment.pronunciation}
        </span>
      </Fragment>
    );
  }
};

const buildDetails = treatment => {
  if (treatment.group === 'Topicals' && treatment.class === 'Steroids') {
    return (
      <div className="option-names-container">
        <div>
          <h5 className="subtext subtext__semibold subtext__uppercase">
            Names
          </h5>
          {treatment.drug_names.map((names, index) => (
            <p key={index + names}>{names}</p>
          ))}
        </div>
        <div>
          <h5 className="subtext subtext__semibold subtext__uppercase">
            Brand names
          </h5>
          {treatment.brand_names.map((names, index) => (
            <p key={index + names}>{names}</p>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <h5 className="subtext subtext__semibold subtext__uppercase">
          Brand names
        </h5>
        {treatment.brand_names.map((names, index) => (
          <p key={index + names}>{names}</p>
        ))}
      </Fragment>
    );
  }
};

const PRICING_SCALE_TOOLTIP = `
  <h4>HOW MUCH DOES IT COST?</h4>
  <ul class="treatment_pricing_scales">
    <li>
      <span class="pricing_scale">0</span>
      <span class="pricing_scale_text"> = No cost</span>
    </li>
    <li>
      <span class="pricing_scale">$</span>
      <span class="pricing_scale_text"> = $1 - $100</span>
    </li>
    <li>
      <span class="pricing_scale">$$</span>
      <span class="pricing_scale_text"> = $101 - $500</span>
    </li>
    <li>
      <span class="pricing_scale">$$$</span>
      <span class="pricing_scale_text"> = $501 - $1,000</span>
    </li>
    <li>
      <span class="pricing_scale">$$$$</span>
      <span class="pricing_scale_text"> = $1,001+</span>
    </li>
  </ul>
`;