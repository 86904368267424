import { flowRight as compose } from 'lodash'; //I think this will eventually need to call login
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import analytics from '../../libs/analytics';
import { createUserProfile } from '../../libs/graphql/mutations/user';
import { Me } from '../../libs/graphql/queries/me';
import { User } from '../../libs/graphql/queries/user';
import PersonalDetails from './PersonalDetails';
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';

const withCreateUserProfileMutation = graphql(createUserProfile, {
  name: 'createUserProfile',
});

class AccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      hasCompletedOnboardingScreen1: false,
      errors: {},
      zipValidationFailure: false,
      raceSpecifiedFailure: false,
      loading: false,
    };
    this.focusElement = React.createRef();
  }

  componentDidMount() {
    analytics().logEvent('open_account_setup', {});
    this.focusElement.current?.focus()
  }

  render() {
    if (this.props.loading) return null;
    return (
      <>
      
      <div className="login-card login-registration">
        <div className="login-title">
          <h2 className="login-title-text title__black100 text__left" tabindex="0" ref={this.focusElement}>Account set up</h2>
        </div>
        <PersonalDetails
          userInformation={this.props.userInformation}
          saveInfo={this.saveInfo.bind(this)}
          userName={this.props.me.name}
          zipValidationFailure={this.state.zipValidationFailure}
        />
        {this._forwardActionButton()}
      </div>
      </>
    );
  }

  _forwardActionButton() {
    let disabled;
    let {
      gender,
      birthYear,
      race,
      race_specified,
      hispanic,
      zip,
      relation,
      feelings,
      nea_familiarity,
      was_referred,
    } = this.props.userInformation;

    disabled = !(
      gender &&
      birthYear &&
      race &&
      (race === 'multiple' || race === 'other' ? race_specified : true) &&
      typeof hispanic === 'boolean' &&
      zip &&
      relation &&
      feelings && 
      nea_familiarity && 
      typeof was_referred === 'boolean'
    );

    return (
      <button
        className="button button__secondary100 button-rectangle-large"
        disabled={!!disabled || this.state.loading}
        onClick={this.createUserProfile.bind(this, false)}
      >
        Complete
      </button>
    );
  }

  saveInfo(name, selectFlag, e) {
    
    let value;

    if (selectFlag) {
      value = e.value;
    } else {
      value = e.target.value
    }
    value = name === 'hispanic' || name === 'was_referred' ? value === 'true' : value;
    
    let {
      gender,
      birthYear,
      zip,
      race,
      race_specified,
      feelings,
      nea_familiarity,
      was_referred,
      hispanic,
      relation,
    } = this.props.userInformation;

    let variables = Object.assign(
      {
        gender,
        birthYear,
        zip,
        race,
        race_specified,
        feelings,
        nea_familiarity,
        was_referred,
        hispanic,
        relation,
      },
      { [name]: value }
    );

    if (name === 'zip' && this.state.zipValidationFailure) {
      this.setState({
        zipValidationFailure: !/\d{5}/.test(value),
      });
    }

    if (name === 'relation' && value === 'self') {
      let currentYear = new Date().getFullYear();
      variables.birthYear = variables.birthYear >= currentYear - 16 ? undefined : variables.birthYear;
    }

    return this.props
      .saveEssentialInformationMutation({
        variables: variables,
      })
  }

  createUserProfile() {
    if (this.state.loading) {
      return false;
    }

    this.setState({ loading: true });
    const {
        hispanic,
        birthYear,
        zip,
        race,
        race_specified,
        feelings,
        nea_familiarity,
        was_referred,
        relation,
        gender
    } = this.props.userInformation;

    if (!/\d{5}/.test(zip)) {
      this.setState({
        zipValidationFailure: true,
      });
      this.setState({ loading: false });
      return;
    }

    let data = {
        hispanic,
        birth_year: String(birthYear),
        zip: String(zip),
        ethnicity: race,
        ethnicity_specified: race_specified,
        feelings,
        nea_familiarity,
        was_referred,
        eczema_relation: relation,
        gender: gender.toUpperCase()
    };

    return this.props
      .createUserProfile({
        variables: {
          userid: this.props.me.id,
          data: data,
        },
        refetchQueries: () => [
          {
            query: Me,
            fetchPolicy: 'network-only',
          },
          {
            query: User,
            variables: {
              id: this.props.me.id,
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then(res => {

        const currentYear = new Date().getFullYear();
        const age = currentYear - Number(birthYear);

        MixpanelService.track(MixpanelEvents.RegistrationCompleted, {
          EczemaRelation: relation,
          Gender: gender,
          BirthYear: birthYear,
          Age: age,
          Race: race.toLowerCase() === 'other' || race.toLowerCase() === 'multiple' ? race_specified : race,
          ZipCode: zip,
          HispanicEthnicity: hispanic ? 'Yes' : 'No',
          EczemaSentiment: this.props.userInformation.feelings,
          NEAFamiliar: this.props.userInformation.nea_familiarity,
          HealthcareReferral: this.props.userInformation.was_referred,
        });

        MixpanelService.registerSuperProps({
          ZipCode: zip,
          Age: age,
          EczemaRelation: relation,
        });

        MixpanelService.setProfileProperty(
          'Race',
          race.toLowerCase() === 'other' || race.toLowerCase() === 'multiple' ? race_specified : race
        );
        MixpanelService.setProfileProperty('Gender', gender);
        MixpanelService.setProfileProperty('HispanicEthnicity', hispanic ? 'Yes' : 'No');
        MixpanelService.setProfileProperty('ZipCode', zip);
        MixpanelService.setProfileProperty('Age', age);
        MixpanelService.setProfileProperty('BirthYear', birthYear);
        MixpanelService.setProfileProperty('EczemaRelation', relation);
           

        MixpanelService.registerSuperProps({
          EczemaSentiment: feelings,
          NEAFamiliar: nea_familiarity,
          Persona: res.data.createUserProfile.persona_slug,
        });

        MixpanelService.setProfileProperty('EczemaSentiment', feelings);
        MixpanelService.setProfileProperty('NEAFamiliar', nea_familiarity);
        MixpanelService.setProfileProperty('HealthcareReferral', was_referred);
        MixpanelService.setProfileProperty('Persona', res.data.createUserProfile.persona_slug);

        analytics().logEvent('successfully_complete_signup', {});
        this.props.history.push('/onboarding');
      })
      .finally(() => {
        this.setState({ loading: false });
        analytics().logEvent('complete_account_setup', {});
      });
  }
}

export default compose(withRouter, withCreateUserProfileMutation)(AccountSetup);
