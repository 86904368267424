import React from 'react';

export default function TrackersLoading() {
  return (
    <div className="health-tracker health-tracker__loading health-tracker__grid">
      <div className="card tracker tracker__loading tracker-weather tracker-weather__loading">
        {' '}
      </div>
      <div className="card tracker tracker__loading card__width-2 tracker-skin">
        {' '}
      </div>
      <div className="card tracker tracker__loading tracker"> </div>
      <div className="card tracker tracker__loading tracker"> </div>
      <div className="card tracker tracker__loading card__width-2"> </div>
      <div className="card tracker tracker__loading card__width-2"> </div>
      <div className="card tracker tracker__loading"> </div>
      <div className="card tracker tracker__loading"> </div>
      <div className="card tracker tracker__loading card__width-2"> </div>
    </div>
  );
}
