import React from 'react';

export default function ItchOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64719C15.8834 9.62353 15.8428 9.62082 15.8111 9.63975L11.2265 12.3624L11.6436 5.69406C11.6456 5.65011 11.6152 5.61022 11.5719 5.60211C11.5273 5.59399 11.4854 5.61901 11.4712 5.66093L7.86086 16.492C7.84936 16.5265 7.86086 16.565 7.88925 16.5887C7.91765 16.6117 7.95754 16.6144 7.98999 16.5954L12.1365 14.1338L11.6849 21.9609C11.6822 22.0049 11.7133 22.0441 11.7565 22.0529C11.7619 22.0542 11.7673 22.0549 11.7727 22.0549C11.8106 22.0549 11.8444 22.0306 11.8566 21.9941L15.9402 9.74387C15.9524 9.70872 15.9402 9.67018 15.9118 9.64719"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3995C20.1646 12.3675 20.1089 12.3683 20.0725 12.4009L15.144 16.9405C15.1151 16.9672 15.1047 17.0073 15.118 17.0444C15.1307 17.0816 15.1648 17.106 15.2041 17.109L17.3225 17.2359L14.7432 20.9516C14.715 20.9924 14.7224 21.0474 14.7595 21.08C14.7774 21.0964 14.8004 21.1038 14.8234 21.1038C14.8464 21.1038 14.8701 21.0949 14.8887 21.0778L19.8165 16.539C19.8454 16.513 19.8558 16.4722 19.8424 16.4351C19.8298 16.3987 19.7957 16.3734 19.7571 16.3712L17.6387 16.2443L20.2173 12.5279C20.2462 12.4878 20.2388 12.4321 20.2017 12.3995"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4231 17.9211L16.9288 15.3553L20.4524 14.4375C20.4938 14.4267 20.521 14.3873 20.5162 14.3452C20.5114 14.3025 20.4768 14.2699 20.4341 14.2685L16.6811 14.1165L21.0728 9.87407C21.1047 9.84352 21.1081 9.79397 21.081 9.76003C21.0545 9.72473 21.0056 9.71591 20.9683 9.73899L15.7199 12.9795L17.0055 6.16926C17.0137 6.12582 16.9879 6.08305 16.9451 6.07083C16.903 6.05862 16.8582 6.07966 16.8419 6.12106L14.2211 12.6035L12.1203 8.42825C12.1013 8.38956 12.0565 8.37259 12.0158 8.38549C11.975 8.39975 11.9506 8.44183 11.9594 8.48391L12.9253 13.1573L6.6588 9.88085C6.61944 9.86049 6.57192 9.87271 6.54681 9.91004C6.52305 9.9467 6.53119 9.99557 6.56513 10.0234L12.0402 14.4484L7.39189 15.3607C7.34981 15.3688 7.31994 15.4068 7.32266 15.4496C7.32537 15.493 7.35931 15.5277 7.40207 15.5304L11.8481 15.8358L6.72329 20.2737C6.69003 20.3029 6.6846 20.3525 6.70971 20.3884C6.726 20.4115 6.75248 20.4244 6.77963 20.4244C6.79388 20.4244 6.80882 20.421 6.82171 20.4136L12.8242 17.0074L11.5746 22.2442C11.5644 22.2863 11.5875 22.3298 11.6282 22.3447C11.6384 22.3481 11.6486 22.3501 11.6581 22.3501C11.6893 22.3501 11.7198 22.3325 11.7348 22.3033L14.1635 17.5192L15.1714 20.3633C15.183 20.3973 15.2156 20.4203 15.2515 20.4203C15.2563 20.4203 15.2617 20.4197 15.2665 20.419C15.3079 20.4115 15.3378 20.3755 15.3378 20.3341L15.3215 17.3862L20.1666 22.1336C20.1829 22.1499 20.2053 22.158 20.227 22.158C20.2467 22.158 20.2657 22.1513 20.282 22.1384C20.316 22.1098 20.3227 22.061 20.2976 22.0243L16.6974 16.7325L21.3565 18.0779C21.3654 18.0799 21.3728 18.0813 21.381 18.0813C21.4142 18.0813 21.4461 18.0616 21.4597 18.029C21.4767 17.9889 21.4611 17.9428 21.4231 17.9211"
        fill="#F9FAFA"
      />
    </svg>
  );
}
