import React from 'react';
import InfectionsSvg from '../../assets/icons/infectionsSvg';
import DragScale from '../shared/DragScale';
import SkinTrackerAreasAffected from './SkinTrackerAreasAffected';

const SYMPTOMS = [
  {
    value: 'dryness',
    label: 'dryness',
  },
  {
    value: 'redness',
    label: 'redness',
  },
  {
    value: 'swelling',
    label: 'swelling',
  },
  {
    value: 'oozing_scabs',
    label: 'oozing scabs',
  },
  {
    value: 'scratch_marks',
    label: 'scratch marks',
  },
  {
    value: 'thickening_skin',
    label: 'thickening skin',
  },
];

const SkinTrackerReadView = props => {
  if (!Object.keys(props.data).length) return null;
  return (
    <div
      className="tracker-body tracker-body__scroll"
      onClick={props.toggleWrite}>
      <div className="skin-tracker__half-graphic">
        <h4 className="subtext-trackers subtext-trackers__black100  subtext-trackers__mediumbold subtext-trackers__left">
          Affected areas
        </h4>
        <SkinTrackerAreasAffected areas_affected={props.data.areas_affected} />
      </div>

      <div className="skin-tracker__half-content">
        <div className="half-content__container">
          <div className="half-content-child">
            <h4 className="subtext-trackers subtext-trackers__black100  subtext-trackers__mediumbold subtext-trackers__left">
              Intensity of symptoms
            </h4>
            <ul>{buildSymptoms(SYMPTOMS, props.data)}</ul>
          </div>

          <div className="half-content-child subtext__mediumbold half-content-child__row">
            <div className="flex-child">
              <InfectionsSvg />
            </div>

            <div className="flex-child">
              <h4 className="subtext subtext__neutral-black">
                {props.data.infections
                  ? 'You have an infection'
                  : 'You have no infections'}
              </h4>
              <p className="paragraph">{props.data.infections_notes}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="tracker-grid-item tracker-notes tracker-notes__inactive">
        <h4 className="subtext subtext__uppercase subtext__skin">Notes</h4>
        <p>{props.data.notes}</p>
      </div>
    </div>
  );
};

export default SkinTrackerReadView;

const buildSymptoms = (symptoms, data) => {
  if (!data.areas_selected) {
    symptoms = [
      {
        value: 'dryness',
        label: 'dryness',
      },
    ]; //override symptoms array if we only care about dryness
  }
  let scales = symptoms.map((symptom, i) => {
    return (
      <li className="symptom-scale-row" key={i}>
        <span className="paragraph paragraph__12p600 paragraph__capitalized">
          {symptom.label}
        </span>
        <div className="symptom-scale-wrapper">
          <span className="trackers-dots">
            {data[symptom.value]}
          </span>
          <DragScale
            maxValue={3}
            editingMode={false}
            level={data[symptom.value]}
            simpleDesign={true}
            withoutRadius={true}
          />
        </div>
      </li>
    );
  });

  return scales;
};
