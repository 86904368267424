import React from 'react';

export default function Metals() {
  return (
    <svg viewBox="0 0 14 24">
      <g className="icon-fill" strokeWidth="1" fillRule="evenodd">
        <path
          d="M2.8149,17.4511 L2.8149,17.9241 C2.8149,17.9731 2.8299,18.0211 2.8569,18.0621 L6.6069,23.6871 C6.6529,23.7571 6.7319,23.7991 6.8149,23.7991 C6.8979,23.7991 6.9769,23.7571 7.0229,23.6871 L10.7729,18.0621 C10.7999,18.0211 10.8149,17.9731 10.8149,17.9241 L10.8149,15.9511 L2.8149,17.4511 Z"
          id="Fill-3"
        />
        <polygon
          id="Fill-1"
          points="10.8149 11.9511 2.8149 13.4511 2.8149 15.9251 10.8149 14.4251"
        />
        <polygon
          id="Fill-2"
          points="2.8149 11.9248 10.8149 10.4248 10.8149 7.9508 2.8149 9.4508"
        />
        <path
          d="M13.5967,0.125 C13.5527,0.048 13.4697,0 13.3797,0 L7.5647,0 L7.5647,1 C7.5647,1.414 7.2287,1.75 6.8147,1.75 C6.4007,1.75 6.0647,1.414 6.0647,1 L6.0647,0 L0.2497,0 C0.1607,0 0.0777,0.048 0.0337,0.125 C-0.0113,0.203 -0.0113,0.299 0.0337,0.376 L2.8147,5.143 L2.8147,7.925 L10.8147,6.425 L10.8147,5.143 L13.5957,0.376 C13.6407,0.299 13.6407,0.203 13.5967,0.125"
          id="Fill-6"
        />
      </g>
    </svg>
  );
}
