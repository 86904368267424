import React from 'react';
import Sidebar from '../shared/Sidebar';
import Checkbox from '../shared/Checkbox'
import SkinTrackerAreasAffected from '../trackers/SkinTrackerAreasAffected';
//Tracker color themes
import config from '../../config';

const FilterSortControls = ({ 
  showFilters, 
  sortCriteria,
  setSortCriteria, 
  severityFilters, 
  toggleSeverityFilter, 
  favoriteFilter, 
  toggleFavoriteFilter,
  bodyPartFilter, 
  toggleBodyPartFilter,
  onClose }) => {

  return (
    <Sidebar isOpen={showFilters} onClose={onClose} header='Filters'>
      <div className="controls-container">
        <div className="sorting-controls form-item-radio">
          <ul>
            <li className='controls-title'>
              <h4>Sort by</h4>
            </li>
            <li>
              <div className="multi-option">
            <label className="radio-container">
              <input
                type="radio"
                name="sortCriteria"
                value="newest"
                checked={sortCriteria === 'newest'}
                onChange={() => setSortCriteria('newest')}
              />
              <span>Newest to Oldest</span>
              <span className="radio-checkmark"></span>
            </label>
              </div>
            </li>
            <li>
              <div className="multi-option">
            <label className="radio-container">
              <input
                type="radio"
                name="sortCriteria"
                value="oldest"
                checked={sortCriteria === 'oldest'}
                onChange={() => setSortCriteria('oldest')}
              />
              <span>Oldest to Newest</span>
              <span className="radio-checkmark"></span>
            </label>
              </div>
            </li>
            <li>
              <div className="multi-option">
            <label className="radio-container">
              <input
                type="radio"
                name="sortCriteria"
                value="highestPoScorad"
                checked={sortCriteria === 'highestPoScorad'}
                onChange={() => setSortCriteria('highestPoScorad')}
              />
              <span>Higher to lower PO-SCORAD</span>
              <span className="radio-checkmark"></span>
            </label>
              </div>
            </li>
            <li>
              <div className="multi-option">
            <label className="radio-container">
              <input
                type="radio"
                name="sortCriteria"
                value="lowestPoScorad"
                checked={sortCriteria === 'lowestPoScorad'}
                onChange={() => setSortCriteria('lowestPoScorad')}
              />
              <span>Lower to higher PO-SCORAD</span>
              <span className="radio-checkmark"></span>
            </label>
              </div>
            </li>
          </ul>
        </div>
        <div className="filter-controls">
          <ul>
            <li className='controls-title'>
              <h4>PO-Scorad severity</h4>
            </li>
            <li>
              <Checkbox
                label="Mild"
                checked={severityFilters.Mild}
                onChange={() => toggleSeverityFilter('Mild')}
              />
            </li>
            <li>
              <Checkbox
                label="Moderate"
                checked={severityFilters.Moderate}
                onChange={() => toggleSeverityFilter('Moderate')}
              />
            </li>
            <li>
              <Checkbox
                label="Severe"
                checked={severityFilters.Severe}
                onChange={() => toggleSeverityFilter('Severe')}
              />
            </li>
            <li className='controls-title'>
              <h4>Favorites</h4>
            </li>
            <li>
              <Checkbox
                label="Show only favorites"
                checked={favoriteFilter}
                onChange={() => toggleFavoriteFilter()}
              />
            </li>
          </ul>
        </div>
        <div className="body-controls">
          <ul>
            <li className='controls-title'>
              <h4>Body parts</h4>
            </li>
          </ul>
          <div className={`tracker-skin tracker__active ${config.trackerTheme.skin} card__active`}>
            <SkinTrackerAreasAffected
              areas_affected={bodyPartFilter}
              handleAreaSelected={(e) => {
                  const bodyPartID = e.target.id;
                  toggleBodyPartFilter(bodyPartID);
              }}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default FilterSortControls;
