import React from 'react';

export default function AngleLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M6.3749 9.59998L10.6499 13.8C10.9499 14.1 11.3999 14.1 11.6999 13.8C11.9999 13.5 11.9999 13.05 11.6999 12.75L8.0249 8.99998L11.6999 5.24998C11.9999 4.94998 11.9999 4.49998 11.6999 4.19998C11.5499 4.04998 11.3999 3.97498 11.1749 3.97498C10.9499 3.97498 10.7999 4.04998 10.6499 4.19998L6.3749 8.39997C6.0749 8.77497 6.0749 9.22498 6.3749 9.59998C6.3749 9.52498 6.3749 9.52498 6.3749 9.59998Z"
        fill="#092A6D"
      />
    </svg>
  );
}
