import React, { Component } from 'react';
import TriggerWriteView from '../components/trackers/TriggerWriteView';

class TriggerTracker extends Component {
  componentWillReceiveProps(nextProps) {
    let { triggerStore } = nextProps;
    if (triggerStore && triggerStore.data && triggerStore.data.length) {
      this._prepState(triggerStore.data);
    }
  }

  componentDidMount() {
    let { data } = this.props;
    if (data && data.data && data.data.length) {
      this._prepState(data.data);
    }
  }

  _prepState(data) {
    //need to "hydrate" the cache with existing data
    data.forEach(selection => {
      if (selection.selected) {
        this.props
          .updateTriggerTypeSelection({
            variables: {
              typeid: selection.typeid,
              checked: true,
            },
          })
          .then(() => {
            let option = this.props.options.find(
              opt => opt.id === selection.typeid,
            );

            this.props.updateTriggersSelections({
              variables: {
                typeid: selection.typeid,
                trigger_id: selection.trigger_id,
                trigger: selection.trigger,
                type: option.type,
                trigger_selected: selection.trigger_selected,
              },
            });
          });
      }
    });
  }

  render() {
    const { options, triggerStore } = this.props;
    if (!triggerStore) return null;
    return (
      <div>
        <div className="tracker-body tracker-body__scroll">
          <div className="form tracker-body-form tracker-body__grid">
            <TriggerWriteView
              data={triggerStore.data}
              options={options}
              checkboxHandler={this.triggerTypeSelect.bind(this)}
              changeHandler={this._updateTriggersSelections.bind(this)}
              deleteHandler={this.deleteTrigger.bind(this)}
              typesSelected={triggerStore.selections}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Actions -------------------------------------------------------------------
   */
  deleteTrigger(typeid, trigger_id, selected, type, trigger, e) {
    this.props.deleteTrigger({
      variables: {
        typeid: typeid,
        trigger_id: trigger_id,
        trigger: trigger,
        selected: selected,
        type: type,
      },
    });
  }

  _updateTriggersSelections(typeid, e) {
    let option = this.props.options.find(opt => opt.id === typeid);
    this.props.updateTriggersSelections({
      variables: {
        typeid: typeid,
        trigger_id: e.value,
        trigger: e.label,
        type: option.type,
      },
    });
  }

  triggerTypeSelect(typeid, e) {
    let checked = e.target.checked;
    this.props.updateTriggerTypeSelection({
      variables: {
        typeid,
        checked: checked,
      },
    });
  }
}

export default TriggerTracker;
