import React from 'react';

export default function CompleteTrackerIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5.5" fill="#34A853" stroke="white" />
      <path
        d="M7.86149 4.37105C7.83327 4.34259 7.79969 4.32001 7.7627 4.3046C7.72571 4.28918 7.68603 4.28125 7.64595 4.28125C7.60588 4.28125 7.5662 4.28918 7.52921 4.3046C7.49222 4.32001 7.45864 4.34259 7.43042 4.37105L5.16881 6.63569L4.21863 5.68247C4.18933 5.65417 4.15474 5.63191 4.11684 5.61698C4.07894 5.60204 4.03846 5.59471 3.99773 5.59542C3.957 5.59612 3.9168 5.60484 3.87944 5.62108C3.84208 5.63732 3.80828 5.66076 3.77997 5.69006C3.75167 5.71936 3.72941 5.75395 3.71448 5.79186C3.69954 5.82976 3.69221 5.87023 3.69292 5.91097C3.69362 5.9517 3.70234 5.99189 3.71858 6.02926C3.73482 6.06662 3.75826 6.10042 3.78756 6.12872L4.95328 7.29444C4.9815 7.32289 5.01507 7.34548 5.05207 7.36089C5.08906 7.3763 5.12874 7.38423 5.16881 7.38423C5.20889 7.38423 5.24857 7.3763 5.28556 7.36089C5.32255 7.34548 5.35613 7.32289 5.38435 7.29444L7.86149 4.8173C7.8923 4.78887 7.9169 4.75437 7.93372 4.71597C7.95054 4.67756 7.95922 4.63609 7.95922 4.59417C7.95922 4.55225 7.95054 4.51078 7.93372 4.47238C7.9169 4.43397 7.8923 4.39947 7.86149 4.37105Z"
        fill="white"
      />
    </svg>
  );
}
