import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';

// Defaults for setting initial state
const settingsDefaults = {
  userTreatmentsData: {
    __typename: 'userTreatments',
    userTreatments: [],
  },
};

//Queries
const userTreatmentsQuery = gql`
  query {
    userTreatmentsData @client {
      userTreatments {
        __typename
        id
        active
        name
        type
      }
    }
  }
`;

//Mutations
const hydrateTreatmentStoreMutation = gql`
  mutation hydrateTreatmentStore($data: [ClientTreatment!]) {
    hydrateTreatmentStore(data: $data) @client
  }
`;

const addUserTreatmentMutation = gql`
  mutation addUserTreatment($treatment: String!, $name: String!) {
    addUserTreatment(treatment: $treatment, name: $name) @client
  }
`;
const deleteUserTreatmentMutation = gql`
  mutation deleteUserTreatment($id: ID!) {
    deleteUserTreatment(id: $id) @client
  }
`;

const bulkDeleteUserTreatmentsMutation = gql`
  mutation bulkDeleteTreatments($type: String!) {
    bulkDeleteTreatments(type: $type) @client
  }
`;

const resetUserTreatmentListMutation = gql`
  mutation resetUserTreatmentList {
    resetUserTreatmentList @client
  }
`;

//Resolvers
const addUserTreatment = (_, { treatment, name }, { cache }) => {
  let currentTreatmentsList = cache.readQuery({ query: userTreatmentsQuery })
    .userTreatmentsData.userTreatments;
  let newTreatmentsList = [];
  currentTreatmentsList.forEach(trt => {
    newTreatmentsList.push(trt);
  });
  // if treatment with same type and name as previous treatment (inactive) is added, we should just make old treatment active again
  newTreatmentsList.push({
    id: `temp-${treatment}-${name}`,
    type: treatment,
    name: name,
    active: true,
    __typename: 'userTreatment',
  });

  cache.writeData({
    data: {
      userTreatmentsData: {
        __typename: 'userTreatments',
        userTreatments: newTreatmentsList,
      },
    },
  });
  return null;
};

const deleteUserTreatment = (_, { id }, { cache }) => {
  let currentTreatmentsList = cache.readQuery({ query: userTreatmentsQuery })
    .userTreatmentsData.userTreatments;
  let newTreatmentsList = [];
  currentTreatmentsList.forEach(trt => {
    if (trt.id !== id) {
      newTreatmentsList.push(trt);
    } else {
      newTreatmentsList.push(Object.assign({}, trt, { active: false }));
    }
  });

  cache.writeData({
    data: {
      userTreatmentsData: {
        __typename: 'userTreatments',
        userTreatments: newTreatmentsList,
      },
    },
  });
  return null;
};

//when a treatment type is deselected, want to delete any temp added treatments and set any existing treatments to inactive
const bulkDeleteTreatments = (_, { type }, { cache }) => {
  let currentTreatmentsList = cache.readQuery({ query: userTreatmentsQuery })
    .userTreatmentsData.userTreatments;
  let newTreatmentsList = [];
  currentTreatmentsList.forEach(trt => {
    if (trt.type !== type) {
      newTreatmentsList.push(trt);
    } else if (!/^temp.*/.test(trt.id)) {
      newTreatmentsList.push(Object.assign({}, trt, { active: false }));
    }
  });

  cache.writeData({
    data: {
      userTreatmentsData: {
        __typename: 'userTreatments',
        userTreatments: newTreatmentsList,
      },
    },
  });
  return null;
};

const resetUserTreatmentList = (_, args, { cache }) => {
  let currentTreatmentsList = cache.readQuery({ query: userTreatmentsQuery })
    .userTreatmentsData.userTreatments;
  let newTreatmentsList = [];
  currentTreatmentsList.forEach(trt => {
    if (!/^temp.*/.test(trt.id)) {
      if (!trt.active) {
        newTreatmentsList.push(Object.assign({}, trt, { active: true }));
      } else {
        newTreatmentsList.push(trt);
      }
    }
  });

  cache.writeData({
    data: {
      userTreatmentsData: {
        __typename: 'userTreatments',
        userTreatments: newTreatmentsList,
      },
    },
  });
  return null;
};

const hydrateTreatmentStore = (_, { data }, { cache }) => {
  let treatments = data.map(treatment => {
    return {
      id: treatment.id,
      name: treatment.name,
      type: treatment.type,
      active: treatment.active,
      __typename: 'userTreatment',
    };
  });
  cache.writeData({
    data: {
      userTreatmentsData: {
        __typename: 'userTreatments',
        userTreatments: treatments,
      },
    },
  });
  return null;
};

//store
const store = {
  defaults: settingsDefaults,
  mutations: {
    addUserTreatment,
    deleteUserTreatment,
    resetUserTreatmentList,
    hydrateTreatmentStore,
    bulkDeleteTreatments,
  },
};

//wrappers
const withSettings = compose(
  graphql(userTreatmentsQuery, {
    props: ({ data: { loading, error, userTreatmentsData } }) => ({
      loading,
      error,
      userTreatmentsData,
    }),
  }),
  graphql(hydrateTreatmentStoreMutation, { name: 'hydrateTreatmentStore' }),
  graphql(addUserTreatmentMutation, { name: 'addUserTreatment' }),
  graphql(deleteUserTreatmentMutation, { name: 'deleteUserTreatment' }),
  graphql(resetUserTreatmentListMutation, { name: 'resetUserTreatmentList' }),
  graphql(bulkDeleteUserTreatmentsMutation, { name: 'bulkDeleteTreatments' }),
);

export { store, withSettings };
