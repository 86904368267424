import React, { Fragment } from 'react';
import CheckboxList from '../shared/CheckboxList';
import TrackerListItemInput from '../shared/TrackerListItemInput';

const foods = [
  'Cobalt',
  'Eggs',
  'Fish',
  'Gluten',
  'Milk',
  'Nickel',
  'Peanuts',
  'Shellfish',
  'Soy',
  'Tree Nuts',
];

const DietOptions = props => {
  let items = foods.map(food => {
    return {
      changeHandler: props.checkHandler.bind(null, food),
      checked: props.foodList.includes(food),
      label: food,
    };
  });

  const addCustomFoodHandler = food => {
    props.addCustomFood({
      variables: {
        food,
      },
    });
  };

  const deleteCustomFoodHandler = food => {
    props.deleteCustomFood({
      variables: {
        food: food,
      },
    });
  };
  return (
    <Fragment>
      <div className="form form__border-bottom tracker-body__grid">
        <CheckboxList list={items} />
        <TrackerListItemInput
          addedItems={props.customFoodList}
          addItem={addCustomFoodHandler}
          favoriteFoods={props.favoriteFoods}
          deleteHandler={deleteCustomFoodHandler}
        />
      </div>
    </Fragment>
  );
};

export default DietOptions;
