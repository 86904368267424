import { flowRight as compose } from 'lodash';
import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import ErrorAlert from '../components/shared/ErrorAlert';
import { forgotPassword } from '../libs/graphql/mutations/user';
import { UilLock, UilArrowLeft } from '@iconscout/react-unicons';
import { PasswordFormIcon } from '../assets/icons';

const withForgotPassword = graphql(forgotPassword, {
  name: 'forgotPassword',
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      emailValidationFailure: false,
      emailValidationFailureMessage: '',
    };
    this.focusElement = React.createRef();
  }

  componentDidMount() {
    this.focusElement.current?.focus()
  };

  render() {
    return (
      <div className="page page-login page__cobalt">
        <div className="login-card login-registration">
          <div className="icon-background-container" >
            <PasswordFormIcon />
          </div>
          <div className="login-title">
            <h2 className="login-title-text  login-title-text-16p title__black100" tabindex="0" ref={this.focusElement}>
              Forgot password
            </h2>
          </div>
          <p className="login-paragraph-forgot-password">
            If you've forgotten your password, enter your email address below.
            We'll email you a link to a page where you can easily create a new
            password.
          </p>
          <div className="alert-space">
            {this.state.emailValidationFailure ? (
              <ErrorAlert message={this.state.emailValidationFailureMessage} />
            ) : null}
            {this.state.success ? this._notification() : null}
          </div>
          <form className="login-form form">
            <div className="form-item">
              <label className="form-input-label-login text__left">Email<span className="red-asterisk">*</span></label>
              <input
                className={`form-input-field ${this.state.emailValidationFailure
                  ? 'form-input-field__error'
                  : ''
                  }`}
                onChange={this._updateInput.bind(this, 'email')}
                placeholder="Enter your email"
              />{' '}
            </div>
            <br/>
            <div className="form-item">
              <button
                className="button button__secondary100 button-rectangle-large"
                disabled={!this.state.email}
                onClick={this._forgotPassword.bind(this)}>
                Submit
              </button>
            </div>
          </form>
          <div className="form-item text-icon">
            <Link className="link link__secondary-100" to="/login">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    );
  }

  _notification() {
    return (
      <div className="alert alert-positive alert-positive__column" role='alert'>
        <h4 className="subtext" >AN EMAIL HAS BEEN SENT</h4>
        <p className="alert-paragraph">
          Check your email for a verification code. Not seeing an email in your
          inbox?{' '}
          <Link to="/help" className="link link__underline">
            Go to Help
          </Link>{' '}
          now.
        </p>
      </div>
    );
  }

  _validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  _updateInput(name, e) {
    e.preventDefault();
    let newState = {};
    let value = e.target.value;
    newState[name] = value;
    if (name === 'email' && this.state.emailValidationFailure) {
      newState.emailValidationFailure = false; //reset validation failure when user starts editing input field
      newState.emailValidationFailureMessage = '';
    }
    this.setState(newState);
  }

  _forgotPassword(e) {
    e.preventDefault();
    let email = this.state.email;
    if (this._validateEmail(email)) {
      this.props
        .forgotPassword({
          variables: {
            email: email,
          },
        })
        .then(res => {
          this.setState({ success: true });
        })
        .catch(e => {
          let message = 'Unexpected error. Please try again.';
          if (e.graphQLErrors && e.graphQLErrors.length) {
            message = e.graphQLErrors[0].message;
          }
          this.setState({
            emailValidationFailure: true,
            emailValidationFailureMessage: message,
          });
        });
    } else {
      this.setState({
        success: false,
        emailValidationFailure: true,
        emailValidationFailureMessage: 'Email format invalid.',
      });
    }
  }
}

export default compose(
  withRouter,
  withApollo,
  withForgotPassword,
)(ForgotPassword);
