import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import React from 'react';
import { graphql, useQuery } from 'react-apollo';
import { withAllMetadata } from '../../apollo/stores/appointments';
import { Download, ProgressStep } from '../../assets/icons';
import analytics from '../../libs/analytics';
import { TreatmentOptionsAndMetadata } from '../../libs/graphql/queries/data';
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';
import { buildSavedTreatments } from '../../libs/treatmentOptionsUtils';
import IconList from '../shared/IconList';
import { UserPoScoradHistory } from '../../libs/graphql/queries/user';
import PoscoradHistory from './PoscoradHistory';
import ReactTooltip from 'react-tooltip';

const composeWithTreatmentOptionsAndMetadata = graphql(
  TreatmentOptionsAndMetadata,
  {
    props: ({ data: { loading, error, availableTreatmentOptions, availableTreatmentOptionsMetadata } }) => ({
      loading,
      error,
      availableTreatmentOptions,
      availableTreatmentOptionsMetadata,
    }),
  },
);

const withUserPoScoradHistory = graphql(UserPoScoradHistory, {
  options: props => {
    const endDate = moment(props.date).isSameOrAfter(moment(), 'day')
      ? moment()
      : moment(props.date);
    return {
      variables: {
        payload: {
          userid: props.me.id,
          startDate: moment(endDate).subtract(90, 'd').format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        },
      },
    };
  },
  props: ({ data: { getPoScoradHistory } }) => {
    return {
      PoScoradHistory: getPoScoradHistory,
    };
  },
});

const POSCORAD_TOOLTIP = `
  <h4>PO-SCORAD</h4>
  <p>Patient-Oriented SCORAD ("PO-SCORAD") is a validated score that measures the severity of the patient’s eczema.</p>
  <p>The score is generated when you track skin, itch and sleep in the same day.</p>
`;

const AppointmentSummary = props => {
  const focusElement = React.useRef(null);

  React.useEffect(() => {
    if (focusElement.current)
      focusElement.current.focus();
  }, []);
  
  analytics().setCurrentScreen('manage_view_summary_open');

  let items =
    props.focusForm.issues &&
    props.focusForm.issues.map((option, index) => {
      return (
        <li className="paragraph" key={index}>
          {option}
        </li>
      );
    });

  let importanceList =
    props.focusForm.priorities &&
    props.focusForm.priorities.map((item, index) => {
      return (
        <li className="paragraph" key={index}>
          {item}
        </li>
      );
    });

  const {
    data: skindexQuestions,
    loading,
    error,
  } = useQuery(gql`
    query {
      getSkindexQuestions {
        header
        scale
        description
        questions {
          text
          description
          id
          scale
        }
        footer
      }
    }
  `);

  const { endDate } = props.trendsEndDate;
  const startDate = moment(endDate).subtract(90, 'd').format('YYYY-MM-DD');

  if (props.loading) return null;

  let pictureList = (
    skindexQuestions ? skindexQuestions.getSkindexQuestions.questions : []
  ).map((question, i) => {
    return (
      <li key={i}>
        <p className="paragraph paragraph__semibold">{question.text}</p>
        <p className="paragraph paragraph__italic paragraph__cobalt paragraph__semibold">
          {typeof props.pictureForm[question.id] === 'number'
            ? props.pictureForm[question.id]
            : 'Not answered.'}
        </p>
      </li>
    );
  });

  return (
    <div className="tracker-body survey__appointments-summary">
      <ReactTooltip
        type="light"
        multiline={true}
        className={'PoScoradTooltip'}
        place="right"
        html={true}
      />
      <div id="all-but-trends">
        <div className="survey-title">
          <div className="survey-title__row">
            <ProgressStep step="4" />
            <h2 className="title title__cobalt" tabindex="0" ref={focusElement}>Appointment Summary</h2>
          </div>
        </div>
        <div id="divIdToPrint">
          <div className="summary__find-focus">
            <h2 className="title title__cobalt">Find Focus</h2>

            <div className="form-item">
              <h3 className="paragraph paragraph__semibold">
                At my appointment, I want to:
              </h3>

              <ul className="list">{items}</ul>
            </div>

            <div className="form-item">
              <h3 className="paragraph paragraph__semibold">
                What is important to you when considering an eczema treatment?
              </h3>
              <ol className="list">{importanceList}</ol>
            </div>

            <div className="form-item summary__notes">
              <h3 className="paragraph paragraph__semibold">Notes</h3>
              <p className="paragraph">{props.focusForm.notes}</p>
            </div>
          </div>

          <div className="summary__paint-picture">
            <div>
              <h2 className="title title__cobalt">Think Big Picture</h2>
            </div>
            <div className="form-item">
              <h3 className="help-text help-text__light-text">
                {skindexQuestions
                  ? skindexQuestions.getSkindexQuestions.description
                  : '0 = Never bothered, 6 = Always bothered'}
              </h3>

              <ul className="list list__big-picture">{pictureList}</ul>
            </div>
          </div>

          <div className="summary__treatment-options">
            <div>
              <h2 className="title title__cobalt">Treatment Options</h2>
              <p>Costs are an estimate based on market drug price search.</p>
            </div>

            <div class="pricing_scales">
              <div class="price_scale">
                <strong>0 =</strong> No cost
              </div>
              <div class="price_scale">
                <strong>$ =</strong> $1 - $100
              </div>
              <div class="price_scale">
                <strong>$$ =</strong> $101 - $500
              </div>
              <div class="price_scale">
                <strong>$$$ =</strong> $501 - $1,000
              </div>
              <div class="price_scale">
                <strong>$$$$ =</strong> $1,001+
              </div>
            </div>

            <div>
              <ul className="list">
                {buildSavedTreatments(
                  props.treatmentOptions.selectedOptions,
                  props.availableTreatmentOptions,
                  false,
                  props.treatmentOptions.selectedOptionsNames,
                  props.availableTreatmentOptionsMetadata
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="summary__trends-data">
        <h2 className="title title__cobalt">
          My PO-SCORAD
          <span
            data-tip={POSCORAD_TOOLTIP}
            className="admin-help material-icons">
            help_outline
          </span>
        </h2>
        <PoscoradHistory
          history={props.PoScoradHistory?.History}
          averageLevel={props.PoScoradHistory?.averageLevel}
        ></PoscoradHistory>
      </div>
      <div className="summary__trends-data">
        <div>
          <h2 className="title title__cobalt">Historical Trends Data</h2>
          <h2 className="title title__cobalt">{startDate + ' To ' + endDate}</h2>
        </div>
        <AppointmentChart
          endDate={endDate}
          userid={props.me.id}
        />
      </div>
      {props.readOnly ? (
        <div className="button-row">
          <a
            href={`/api/appointment/${props.apptId}.pdf`}
            className="button button-regular button__green button-regular__icon-first fill__white"
            onClick={e => {
              analytics().logEvent('manage_download_pdf', {});
              MixpanelService.track(MixpanelEvents.DownloadSummaryTapped, {
                AppointmentID: props.apptId,
              });
            }}
            download={true}>
            <Download />{' '}
            <span>
              Download <span className="text-hidden">Summary as PDF</span>
            </span>
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default compose(
  withAllMetadata,
  composeWithTreatmentOptionsAndMetadata,
  withUserPoScoradHistory,
)(AppointmentSummary);

const AppointmentChart = ({ endDate, userid }) => (
  <div className="trends">
    <div className="trends-content">
      <IconList exclude={['treatment', 'trigger', 'diet']} />
      <div className="trends-data">
        <div className="card chart">
          <div className="trendschart">
            <img
              width={600}
              height={400}
              src={`/api/charts/trends?startDate=${moment(endDate)
                .subtract(90, 'd')
                .format('YYYY-MM-DD')}&endDate=${endDate}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
