import React from 'react';

export const SkinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__skin">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__skin tracker-icon-image"
        transform="translate(6.25, 5.5) scale(.9)">
        <path d="M25.5194944,8.5782047 C24.9704953,8.39006456 24.3711199,8.66867646 24.162418,9.21047894 L21.5716358,15.9156702 C21.2333947,15.5661202 20.8129067,15.2463848 20.315312,14.9554358 L22.2964379,5.96891684 C22.4208366,5.40449642 22.075399,4.84213218 21.5140628,4.69922792 C20.9547828,4.55632366 20.3811096,4.88428379 20.2207279,5.43945142 L17.7543443,13.9386566 C17.495266,13.8697747 17.2361878,13.8008928 16.9524355,13.7402356 C16.7231718,13.6919154 16.5113856,13.6600447 16.294459,13.6230335 L17.7409791,4.23247587 C17.8283666,3.66188692 17.4469459,3.12316871 16.8784131,3.01830371 C16.3098803,2.91241062 15.7608812,3.27635385 15.6375107,3.84180236 L13.5309579,13.4698484 C13.053925,13.5140561 12.6190437,13.5993875 12.2252859,13.7237861 L12.6406336,5.43431098 C12.66942,4.85549732 12.2365949,4.35790263 11.6598374,4.30547014 C11.0820518,4.25406573 10.5680077,4.66838527 10.4929573,5.24205849 L8.97549913,17.0362863 C8.97241486,17.0455391 8.9693306,17.05582 8.96521824,17.0650728 L4.17329914,13.0616973 C3.71682798,12.6813047 3.04857065,12.7018665 2.6167736,13.1100175 C2.18806082,13.5150842 2.12740362,14.177173 2.47592552,14.6531779 L9.47823426,24.2205667 C10.5032382,25.7184912 12.1163086,26.8524725 14.0768728,27.2698763 C17.9980012,28.1026277 21.8204331,25.7503619 22.6141172,22.0142894 L26.2052293,9.90649465 C26.3697234,9.35132702 26.0674655,8.76531675 25.5194944,8.5782047" />
      </g>
    </svg>
  );
};
