import React from 'react';

const Modal = ({ isOpen, header, footer, children, onClose, size = 'M'}) => {
  if (!isOpen) return null;

  // Define classes based on size
  const sizeClass = `modal ${size.toLowerCase()}`;

  return (
    <div className="modal-overlay">
      <div className={sizeClass}>
        <div className="modal-header">
            <span>{header}</span>
            <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-content">{children}</div>
        {footer && <div className="modal-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
