import { scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
// Queries
import { withTrendsView } from '../../apollo/stores/trendsView';
import config from '../../config';
import DayBrowser from './DayBrowser';
import NoDataPrompt from './NoDataPrompt';

const dateArray = (startDate, endDate) => {
  let result = [];
  let currentDate = new Date(startDate.getTime());

  while (currentDate && currentDate <= endDate) {
    result.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return result;
};

class LineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.rootNode = React.createRef();
    this.height = 405;
    this.yLabelWidth = 50;
    this.graphHeight = this.height - 20;
    this.standardRadius = 11;
    this.state = {
      noDatas: [],
    };
  }

  updateDimensions() {
    if (this.rootNode.current && !this._unmounted) {
      this.componentDidUpdate();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    this._unmounted = true;
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  selectDate(d) {
    // clear any currently selected days
    this.chart.selectAll('.day.selected').classed('selected', false);

    // select this day
    this.chart
      .selectAll('.day')
      .filter(day => {
        return day === d;
      })
      .classed('selected', true);

    // and save it to the store
    this.props.client.writeData({
      //todo: gotta get to this.props.client instead
      data: {
        trendsView: {
          __typename: 'trendsView',
          selectedDate: d,
        },
      },
    });
  }

  componentDidUpdate() {
    this.width = this.rootNode.current.parentNode.offsetWidth;
    this.today = moment().format('YYYY-MM-DD');

    select(this.rootNode.current).selectAll('*').remove(); //clear
    this.chart = select(this.rootNode.current)
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform', 'translate(50,10)');

    // set up the x/y scale functions
    const x = scaleTime()
      .domain([
        moment(this.props.startDate).toDate(),
        moment(this.props.endDate).add(1, 'days').toDate(),
      ])
      .range([0, this.width - this.yLabelWidth * 2]);
    const y = scaleLinear().domain([0, 10]).range([this.graphHeight, 0]);
    const dayWidth = x(moment(this.props.startDate).add(1, 'days').toDate());

    // Generate coordinates and line styles ahead of time so we can skip days if needed - using D3's .append is inflexible
    // This returns points but also inserts coordinates for "no data" prompts into state
    const chartPoints = this.generateChartPoints(x, y, dayWidth);

    // Build SVG
    this.drawDayColumns(x, dayWidth);
    this.drawYGridAndLabels(y);
    const graph = this.chart
      .selectAll('.graph')
      .data(chartPoints)
      .enter()
      .append('g')
      .attr('class', d => {
        return 'graph ' + config.trackerTheme[d.key];
      });
    this.plotChartPoints(chartPoints, graph);
  }

  generateChartPoints(xScale, yScale, dayWidth) {
    let freshChartPoints = [];
    if (this.props.data) {
      let newNoDatas = [];
      this.props.data.forEach(indicator => {
        let indicatorPoints = [];
        let chartData = indicator.values;
        if (!chartData.length) return;

        let currentStyle = null;
        let lastX = xScale(moment(chartData[0].date).toDate()) + dayWidth * 0.5;
        let lastY = yScale(chartData[0].value);

        for (var i = 0; i < chartData.length; i++) {
          if (chartData[i].value === null) {
            currentStyle = '4,4';
            if (i < chartData.length - 1) continue;
          }
          indicatorPoints.push({
            date: chartData[i].date,
            style: currentStyle,
            value: chartData[i].value,
            x2: xScale(moment(chartData[i].date).toDate()) + dayWidth * 0.5,
            y2:
              chartData[i].value !== null ? yScale(chartData[i].value) : lastY, // if null value, we assume it's the end of visible line, so trail out horizontally
            x1: lastX, // grab the previous end point
            y1: lastY,
          });

          //setup for next segment
          currentStyle = null;
          lastX = xScale(moment(chartData[i].date).toDate()) + dayWidth * 0.5;
          lastY =
            chartData[i].value !== null ? yScale(chartData[i].value) : lastY;
        }

        // if there isn't a value for today, add one to extend the line, and add a "no data" prompt
        if (
          chartData[chartData.length - 1].date < this.today ||
          chartData[chartData.length - 1].value === null
        ) {
          indicatorPoints.push({
            date: this.today,
            style: '4,4',
            value: null,
            x2: xScale(moment(this.today).toDate()) + dayWidth * 0.5,
            y2: lastY,
            x1: lastX,
            y1: lastY,
          });
          newNoDatas.push({
            key: indicator.key,
            x: xScale(moment(this.today).toDate()) + dayWidth * 0.5 + 50,
            y: lastY + 60, //adding 60 to x/y to match the SVG's translation. Not ideal way to do it.
          });
        }

        freshChartPoints.push({
          key: indicator.key,
          values: indicatorPoints,
        });
      });

      if (JSON.stringify(this.state.noDatas) !== JSON.stringify(newNoDatas)) {
        this.setState({
          noDatas: newNoDatas,
        });
      }
    }
    return freshChartPoints;
  }

  // Generate day columns for selection
  drawDayColumns(xScale, dayWidth) {
    if (this.props.data.length) {
      // For the chart, need to create our own starting point based on week/month view, not by what pagination says.
      // const startDate = this.props.trendsView.value==='week'
      const dates = dateArray(
        moment(this.props.startDate).toDate(),
        moment(this.props.endDate).toDate(),
      );
      const days = this.chart
        .append('g')
        .attr('class', 'days')
        .selectAll('.days')
        .data(dates)
        .enter();
      days
        .append('rect')
        .attr('class', d => {
          let c = 'day';
          if (d === this.today) c += ' today';
          if (d === this.props.trendsView.selectedDate) c += ' selected';
          return c;
        })
        .attr('x', d => {
          return xScale(moment(d).toDate());
        })
        .attr('y', -10)
        .attr('width', dayWidth)
        .attr('height', this.graphHeight + 20)
        .attr('rx', 5)
        .attr('ry', 5)
        .on('click', d => {
          this.selectDate(d);
        });
    }
  }

  drawYGridAndLabels(yScale) {
    // Generate chart grid
    const gridlines = this.chart
      .append('g')
      .attr('class', 'gridlines')
      .selectAll('.gridlines')
      .data([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      .enter();
    gridlines
      .append('line')
      .attr('class', d => {
        return d % 5 ? 'minor' : 'major';
      })
      .attr('x1', -10)
      .attr('y1', d => {
        return yScale(d);
      })
      .attr('x2', this.width - 100 + 10)
      .attr('y2', d => {
        return yScale(d);
      });

    // Generate Y-axis labels on either side
    const yAxisX = [-30, this.width - this.yLabelWidth - 20];
    yAxisX.forEach(x => {
      const yaxisdots = gridlines
        .filter((d, i) => {
          return !(i % 5);
        })
        .append('g')
        .attr('class', 'numberdot')
        .attr('transform', d => {
          return 'translate(' + x + ',' + yScale(d) + ')';
        });
      yaxisdots.append('circle').attr('r', this.standardRadius);
      yaxisdots
        .append('text')
        .text(d => {
          return d;
        })
        .attr('y', 4);
    });
  }

  plotChartPoints(chartPoints, graph) {
    // the chart itself
    graph
      .append('g')
      .attr('class', 'line')
      .selectAll('line')
      .data(d => {
        return d.values;
      })
      .enter()
      .append('line')
      .attr('x2', d => {
        if (d.date > this.props.trendsView.endDate) {
          return this.width - 100 + 10;
        }
        return d.x2;
      })
      .attr('y2', d => {
        return d.y2;
      })
      .attr('x1', d => {
        if (d.x1 > this.width - 90) {
          return this.width - 100 + 10;
        }
        return d.x1;
      })
      .attr('y1', d => {
        return d.y1;
      });

    // the dots on the lines
    graph
      .append('g')
      .attr('class', 'datapoints')
      .selectAll('.datapoints')
      .data(d => {
        return d.values.filter(d => {
          return (
            d.date >= this.props.trendsView.startDate &&
            d.date <= this.props.trendsView.endDate
          );
        });
      })
      .enter()
      .append('circle')
      .attr('class', d => {
        if (d.date === this.props.trendsView.selectedDate)
          return 'datapoint selected';
        return 'datapoint';
      })
      .attr('cx', d => {
        return d.x2;
      })
      .attr('cy', d => {
        return d.y2;
      })
      .attr('r', 3)
      .on('click', d => {
        this.selectDate(d.date);
      });
  }

  isTodayNull(d) {
    return d.value === null && d.date === moment().format('YYYY-MM-DD');
  }

  render() {
    let missingIndicators = this.state.noDatas
      .map(nod => {
        return nod.key;
      })
      .join(', ');
    let noDatas = this.state.noDatas.map(nod => {
      return (
        <NoDataPrompt
          x={nod.x}
          y={nod.y}
          missingIndicators={missingIndicators}
          key={nod.key}
        />
      );
    });

    return (
      <div className="linechart">
        <DayBrowser data={this.props.data} yLabelWidth={this.yLabelWidth} />
        <svg className="linechart__svg" ref={this.rootNode} />
        {noDatas}
      </div>
    );
  }
}

export default compose(withRouter, withTrendsView, withApollo)(LineChart);
