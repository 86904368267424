import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { graphql } from 'react-apollo';

// Defaults for setting initial state
const appointmentDefaults = {
  appointmentForm: {
    __typename: 'appointmentForm',
    date: null,
    time: null,
    notes: '',
    provider_first_name: '',
    provider_last_name: '',
    provider_phone: '',
    provider_address_1: '',
    provider_address_2: '',
    provider_city: '',
    provider_state: '',
    provider_zip: '',
  },
  focusForm: {
    __typename: 'focusForm',
    issues: [],
    priorities: [
      'Avoiding the risk of serious side effects',
      'Getting quick results',
      'Convenience',
      'Cost',
      'How well it works',
      'How much time it takes to do treatment',
    ],
    notes: '',
  },
  pictureForm: {
    __typename: 'pictureForm',
    self: null,
    social: null,
    work: null,
  },
  affectedAreasForm: {
    __typename: 'affectedAreasForm',
    images: [],
  },
  treatmentOptions: {
    __typename: 'treatmentOptions',
    selectedOptions: [],
    selectedOptionsNames: [],
  },
  trendsEndDate: {
    __typename: 'trendsEndDate',
    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  },
  surveysNavigation: {
    __typename: 'surveysNavigation',
    currentPage: 1,
  },
};

//Queries
const appointmentData = gql`
  query appointmentForm {
    appointmentForm @client {
      date
      time
      notes
      provider_first_name
      provider_last_name
      provider_phone
      provider_address_1
      provider_address_2
      provider_city
      provider_state
      provider_zip
    }
  }
`;

const allMetadataQuery = gql`
  query allMetadata {
    focusForm @client {
      issues
      priorities
      notes
    }
    pictureForm @client {
      self
      social
      work
    }
    affectedAreasForm @client {
      images
    }
    treatmentOptions @client {
      selectedOptions
      selectedOptionsNames
    }
    trendsEndDate @client {
      endDate
    }
  }
`;

const focusFormData = gql`
  query focusForm {
    focusForm @client {
      issues
      priorities
      notes
    }
  }
`;

const pictureFormData = gql`
  query pictureForm {
    pictureForm @client {
      self
      social
      work
    }
  }
`;

const affectedAreasFormData = gql`
  query affectedAreasForm {
    affectedAreasForm @client {
      images
    }
  }
`;

const treatmentOptionsData = gql`
  query treatmentOptions {
    treatmentOptions @client {
      selectedOptions
    }
  }
`;

const surveysNavigationQuery = gql`
  query surveysNavigation {
    surveysNavigation @client {
      __typename
      currentPage
    }
  }
`;

//Mutations
const updateInputMutation = gql`
  mutation UpdateInput($name: String!, $value: String!) {
    updateInputAppointments(name: $name, value: $value) @client
  }
`;

const updateFocusNotesMutation = gql`
  mutation UpdateFocusNotes($name: String!, $value: String!) {
    updateFocusNotes(name: $name, value: $value) @client
  }
`;

const updatePictureScaleValueMutation = gql`
  mutation UpdatePictureScaleValue($name: String!, $value: Int!) {
    updatePictureScaleValue(name: $name, value: $value) @client
  }
`;

const updateAffectedAreaImagesMutation = gql`
  mutation UpdateAffectedAreaImages($images: [ID]) {
    updateAffectedAreaImages(images: $images) @client
  }
`;

const hydrateAppointmentFormMutation = gql`
  mutation hydrateAppointmentFormMutation(
    $date: String
    $time: String
    $notes: String
    $provider_first_name: String
    $provider_last_name: String
    $provider_phone: String
    $provider_address_1: String
    $provider_address_2: String
    $provider_city: String
    $provider_state: String
    $provider_zip: String
  ) {
    hydrateAppointmentForm(
      date: $date
      time: $time
      notes: $notes
      provider_first_name: $provider_first_name
      provider_last_name: $provider_last_name
      provider_phone: $provider_phone
      provider_address_1: $provider_address_1
      provider_address_2: $provider_address_2
      provider_city: $provider_city
      provider_state: $provider_state
      provider_zip: $provider_zip
    ) @client
  }
`;

const hydrateMetadataMutation = gql`
  mutation hydrateMetadata(
    $issues: [String]
    $priorities: [String]
    $notes: String
    $self: Int
    $social: Int
    $work: Int
    $selectedOptions: [Int]
    $selectedOptionsNames: [String]
    $images: [Id]
    $trendsEndDate: Date
  ) {
    hydrateMetadata(
      issues: $issues
      priorities: $priorities
      notes: $notes
      self: $self
      social: $social
      work: $work
      images: $images
      selectedOptions: $selectedOptions
      selectedOptionsNames: $selectedOptionsNames
      trendsEndDate: $trendsEndDate
    ) @client
  }
`;

const updateIssuesMutation = gql`
  mutation updateIssues($issue: String!, $checked: Boolean!) {
    updateIssues(issue: $issue, checked: $checked) @client
  }
`;

const updatePrioritiesMutation = gql`
  mutation updatePriorities($priorities: [String!]) {
    updatePriorities(priorities: $priorities) @client
  }
`;

const updateTreatmentOptionsMutation = gql`
  mutation updateTreatmentOptions($selectedOptions: [ID!]) {
    updateTreatmentOptions(selectedOptions: $selectedOptions) @client
  }
`;

const updateOptionSelectionsMutation = gql`
  mutation updateOptionSelections($optionid: ID!, $value: ID!) {
    updateOptionSelections(optionid: $optionid, value: $value) @client
  }
`;

const clearForm = gql`
  mutation {
    resetAppointmentForm @client
  }
`;

const navigateNextSurveysMutation = gql`
  mutation navigateNextSurveys {
    navigateNextSurveys @client
  }
`;

const navigateBackSurveysMutation = gql`
  mutation navigateBackSurveys {
    navigateBackSurveys @client
  }
`;

const resetSurveysMutation = gql`
  mutation resetSurveys($page: Int!) {
    resetSurveys(page: $page) @client
  }
`;

//Resolvers
const navigateNextSurveys = (_, args, { cache }) => {
  let currenState = cache.read({ query: surveysNavigationQuery });
  let currentPage = currenState.surveysNavigation.currentPage;
  let updatedState = Object.assign({}, currenState);
  updatedState.surveysNavigation = Object.assign(
    {},
    updatedState.surveysNavigation,
  );
  updatedState.surveysNavigation.currentPage = currentPage + 1;
  cache.writeData({ data: updatedState });
  return null;
};

const navigateBackSurveys = (_, args, { cache }) => {
  let currenState = cache.read({ query: surveysNavigationQuery });
  let currentPage = currenState.surveysNavigation.currentPage;
  let updatedState = Object.assign({}, currenState);
  updatedState.surveysNavigation = Object.assign(
    {},
    updatedState.surveysNavigation,
  );
  updatedState.surveysNavigation.currentPage = currentPage - 1;
  cache.writeData({ data: updatedState });
  return null;
};

const resetSurveys = (_, { page }, { cache }) => {
  let resetData = {};
  switch (page) {
    case 1:
      resetData = {
        focusForm: appointmentDefaults.focusForm,
        pictureForm: appointmentDefaults.pictureForm,
        treatmentOptions: appointmentDefaults.treatmentOptions,
      };
      break;
    case 2:
      resetData = {
        pictureForm: appointmentDefaults.pictureForm,
      };
      break;
    case 3:
      resetData = {
        treatmentOptions: appointmentDefaults.treatmentOptions,
      };
      break;
    case 4:
      resetData = {
        affectedAreasForm: appointmentDefaults.affectedAreasForm,
      };
      break;
    case 5:
      resetData = {
        focusForm: appointmentDefaults.focusForm,
        pictureForm: appointmentDefaults.pictureForm,
        affectedAreasForm: appointmentDefaults.affectedAreasForm,
        treatmentOptions: appointmentDefaults.treatmentOptions,
        surveysNavigation: appointmentDefaults.surveysNavigation,
      };
      break;
    default:
      break;
  }

  cache.writeData({
    data: resetData,
  });

  return null;
};

const resetAppointmentForm = (_, args, { cache }) => {
  cache.writeData({
    data: {
      appointmentForm: appointmentDefaults.appointmentForm,
    },
  });
  return null;
};

const hydrateAppointmentForm = (_, data, { cache }) => {
  let currenState = cache.read({ query: appointmentData });
  let updatedState = Object.assign({}, currenState, { appointmentForm: data });
  updatedState.appointmentForm = Object.assign(
    {},
    updatedState.appointmentForm,
  );
  updatedState.appointmentForm.__typename = 'appointmentForm';
  cache.writeData({ data: updatedState });
  return null;
};

const hydrateMetadata = (_, metadata, { cache }) => {
  let updatedState = {
    focusForm: {
      __typename: 'focusForm',
      issues: metadata.issues,
      priorities: metadata.priorities,
      notes: metadata.notes,
    },
    pictureForm: {
      __typename: 'pictureForm',
      self: metadata.self,
      social: metadata.social,
      work: metadata.work,
    },
    affectedAreasForm: {
      __typename: 'affectedAreasForm',
      images: metadata.images,
    },
    treatmentOptions: {
      __typename: 'treatmentOptions',
      selectedOptions: metadata.selectedOptions,
      selectedOptionsNames: metadata.selectedOptionsNames,
    },
  };
  if (metadata.trendsEndDate) {
    updatedState.trendsEndDate = {
      __typename: 'trendsEndDate',
      endDate: moment(metadata.trendsEndDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
    };
  }
  cache.writeData({ data: updatedState });
  return null;
};

const updateInputAppointments = (_, { name, value }, { cache }) => {
  let currenState = cache.read({ query: appointmentData });
  let updatedState = Object.assign({}, currenState);
  updatedState.appointmentForm = Object.assign(
    {},
    updatedState.appointmentForm,
  );
  updatedState.appointmentForm[name] = value;
  cache.writeData({ data: updatedState });

  return null;
};

const updateIssues = (_, { issue, checked }, { cache }) => {
  let index;
  let currentIssuesList = cache.readQuery({ query: focusFormData }).focusForm
    .issues;
  let newIssuesList = [];
  currentIssuesList.forEach(i => {
    newIssuesList.push(i);
  });
  if (checked) {
    newIssuesList.push(issue);
  } else {
    index = newIssuesList.indexOf(issue);
    newIssuesList.splice(index, 1);
  }
  cache.writeData({
    data: {
      focusForm: {
        __typename: 'focusForm',
        issues: newIssuesList,
      },
    },
  });
  return null;
};

const updatePriorities = (_, { priorities }, { cache }) => {
  cache.writeData({
    data: {
      focusForm: {
        __typename: 'focusForm',
        priorities: priorities,
      },
    },
  });
  return null;
};

const updateTreatmentOptions = (_, { selectedOptions }, { cache }) => {
  cache.writeData({
    data: {
      treatmentOptions: {
        __typename: 'treatmentOptions',
        selectedOptions: selectedOptions,
      },
    },
  });
  return null;
};

const updateFocusNotes = (_, { name, value }, { cache }) => {
  let currenState = cache.read({ query: focusFormData });
  let updatedState = Object.assign({}, currenState);
  updatedState.focusForm = Object.assign({}, updatedState.focusForm);
  updatedState.focusForm[name] = value;
  cache.writeData({ data: updatedState });
  return null;
};

const updatePictureScaleValue = (_, { name, value }, { cache }) => {
  let currenState = cache.read({ query: pictureFormData });
  let updatedState = Object.assign({}, currenState);
  updatedState.pictureForm = Object.assign({}, updatedState.pictureForm);
  updatedState.pictureForm[name] = value;
  cache.writeData({ data: updatedState });
  return null;
};

const updateAffectedAreaImages = (_, { images }, { cache }) => {
  let currenState = cache.read({ query: affectedAreasFormData });
  let updatedState = Object.assign({}, currenState);
  updatedState.affectedAreasForm = Object.assign(
    {},
    updatedState.affectedAreasForm,
  );
  updatedState.affectedAreasForm.images = images;
  cache.writeData({ data: updatedState });
  return null;
};

const updateOptionSelections = (_, { optionid, value }, { cache }) => {
  let currenState = cache.read({ query: pictureFormData });
  let updatedState = Object.assign({}, currenState);
  updatedState.pictureForm = Object.assign({}, updatedState.pictureForm);
  let newOptionsArray = updatedState.pictureForm.options.map(option => {
    if (option.id === optionid) {
      option.selection = parseInt(value);
    }
    return option;
  });

  updatedState.pictureForm.options = newOptionsArray;
  cache.writeData({ data: updatedState });
  return null;
};

//store
const store = {
  defaults: appointmentDefaults,
  mutations: {
    updateInputAppointments,
    resetAppointmentForm,
    updateOptionSelections,
    hydrateAppointmentForm,
    updateIssues,
    updateFocusNotes,
    updatePictureScaleValue,
    updateAffectedAreaImages,
    navigateNextSurveys,
    navigateBackSurveys,
    resetSurveys,
    updatePriorities,
    updateTreatmentOptions,
    hydrateMetadata,
  },
};

//wrappers
const withAppointments = compose(
  graphql(appointmentData, {
    props: ({ data: { loading, error, appointmentForm } }) => ({
      loading,
      error,
      appointmentForm,
    }),
  }),
  graphql(pictureFormData, {
    props: ({ data: { loading, error, pictureForm, me } }) => ({
      loading,
      error,
      pictureForm,
    }),
  }),
  graphql(updateInputMutation, { name: 'updateInputAppointment' }),
  graphql(clearForm, { name: 'resetAppointmentForm' }),
  graphql(updateOptionSelectionsMutation, { name: 'updateOptionSelections' }),
  graphql(hydrateAppointmentFormMutation, { name: 'hydrateAppointmentForm' }),
);

const withFocus = compose(
  graphql(focusFormData, {
    props: ({ data: { loading, error, focusForm } }) => ({
      loading,
      error,
      focusForm,
    }),
  }),
  graphql(updateIssuesMutation, { name: 'updateIssues' }),
  graphql(updatePrioritiesMutation, { name: 'updatePriorities' }),
  graphql(updateFocusNotesMutation, { name: 'updateFocusNotes' }),
);

const withBigPicture = compose(
  graphql(pictureFormData, {
    props: ({ data: { loading, error, pictureForm } }) => ({
      loading,
      error,
      pictureForm,
    }),
  }),
  graphql(updatePictureScaleValueMutation, { name: 'updatePictureScaleValue' }),
);

const withAppointmentImages = compose(
  graphql(affectedAreasFormData, {
    props: ({ data: { loading, error, images } }) => ({
      loading,
      error,
      images,
    }),
  }),
  graphql(updateAffectedAreaImagesMutation, {
    name: 'updateAffectedAreaImages',
  }),
);

const withTreatmentOptions = compose(
  graphql(treatmentOptionsData, {
    props: ({ data: { loading, error, treatmentOptions } }) => ({
      loading,
      error,
      treatmentOptions,
    }),
  }),
  graphql(updateTreatmentOptionsMutation, { name: 'updateTreatmentOptions' }),
);

const withSurveysNagation = compose(
  graphql(surveysNavigationQuery, {
    props: ({ data: { loading, error, surveysNavigation } }) => ({
      loading,
      error,
      surveysNavigation,
    }),
  }),
  graphql(navigateNextSurveysMutation, { name: 'navigateNextSurveys' }),
  graphql(navigateBackSurveysMutation, { name: 'navigateBackSurveys' }),
  graphql(resetSurveysMutation, { name: 'resetSurveys' }),
);

const withAllMetadata = compose(
  graphql(allMetadataQuery, {
    props: ({
      data: {
        loading,
        error,
        focusForm,
        pictureForm,
        affectedAreasForm,
        treatmentOptions,
        trendsEndDate,
      },
    }) => ({
      loading,
      error,
      focusForm,
      pictureForm,
      affectedAreasForm,
      treatmentOptions,
      trendsEndDate,
    }),
  }),
  graphql(hydrateMetadataMutation, { name: 'hydrateMetadata' }),
);

export {
  store,
  withAppointments,
  withFocus,
  withBigPicture,
  withAppointmentImages,
  withTreatmentOptions,
  withAllMetadata,
  withSurveysNagation,
};
