import React from 'react';

export default function StressOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64621C15.8834 9.62255 15.8428 9.61985 15.8111 9.63878L11.2265 12.3614L11.6436 5.69308C11.6456 5.64913 11.6152 5.60924 11.5719 5.60113C11.5273 5.59302 11.4854 5.61803 11.4712 5.65995L7.86086 16.491C7.84936 16.5255 7.86086 16.564 7.88925 16.5877C7.91765 16.6107 7.95754 16.6134 7.98999 16.5945L12.1365 14.1328L11.6849 21.96C11.6822 22.0039 11.7133 22.0431 11.7565 22.0519C11.7619 22.0533 11.7673 22.0539 11.7727 22.0539C11.8106 22.0539 11.8444 22.0296 11.8566 21.9931L15.9402 9.7429C15.9524 9.70774 15.9402 9.6692 15.9118 9.64621Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2012 12.3985C20.1641 12.3666 20.1084 12.3673 20.0721 12.4L15.1435 16.9395C15.1146 16.9663 15.1042 17.0063 15.1176 17.0435C15.1302 17.0806 15.1643 17.1051 15.2037 17.108L17.322 17.235L14.7427 20.9506C14.7145 20.9915 14.7219 21.0464 14.7591 21.0791C14.7769 21.0954 14.7999 21.1028 14.8229 21.1028C14.8459 21.1028 14.8696 21.0939 14.8882 21.0768L19.816 16.538C19.8449 16.512 19.8553 16.4712 19.842 16.4341C19.8293 16.3977 19.7952 16.3725 19.7566 16.3702L17.6382 16.2433L20.2168 12.5269C20.2457 12.4868 20.2383 12.4311 20.2012 12.3985Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0725 8.92954C19.0862 8.91542 19.1038 8.90803 19.1162 8.8919L20.5583 7.05062C20.6066 6.99014 20.6085 6.90144 20.5583 6.83827C20.5015 6.7657 20.3983 6.75427 20.3278 6.81274L18.5398 8.29786C18.5248 8.30995 18.5118 8.32541 18.498 8.34019C18.735 8.484 18.9315 8.6856 19.0725 8.92954Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0774 8.16026C18.0781 8.14077 18.0846 8.12263 18.0827 8.10247L17.8379 5.75047C17.8294 5.67319 17.77 5.60935 17.6916 5.60061C17.6015 5.59053 17.5206 5.65773 17.5115 5.75047L17.2667 8.10247C17.2647 8.12128 17.266 8.14211 17.2667 8.1616C17.3979 8.12733 17.533 8.10247 17.6747 8.10247C17.8144 8.10247 17.9482 8.12599 18.0774 8.16026Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2317 8.89186C16.2441 8.90664 16.2585 8.92008 16.2728 8.93419C16.4125 8.69026 16.6084 8.48798 16.8453 8.3435C16.8323 8.32872 16.8238 8.31058 16.8088 8.29781L15.0201 6.81269C14.9613 6.76363 14.8752 6.76229 14.8138 6.81269C14.744 6.87182 14.7322 6.978 14.789 7.05058L16.2317 8.89186Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5909 9.61439L19.3061 9.36239C19.2879 9.36038 19.2676 9.36172 19.248 9.36239C19.2826 9.49746 19.3061 9.63657 19.3061 9.78239C19.3061 9.9262 19.2833 10.064 19.25 10.197C19.2689 10.1977 19.2866 10.2044 19.3061 10.2024L21.5909 9.95039C21.666 9.94233 21.728 9.88118 21.7365 9.80054C21.7463 9.7078 21.681 9.62447 21.5909 9.61439Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1166 10.6737C19.1042 10.6589 19.0891 10.6448 19.0754 10.6313C18.9357 10.8753 18.7392 11.0776 18.5029 11.222C18.516 11.2368 18.5238 11.255 18.5395 11.2677L20.3275 12.7522C20.3869 12.8012 20.4731 12.8033 20.5344 12.7522C20.6043 12.6937 20.616 12.5875 20.5586 12.515L19.1166 10.6737Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6154 12.8276C9.90569 12.8276 8.51457 14.2597 8.51457 16.0196C8.51457 16.2978 8.29523 16.5236 8.02497 16.5236C7.75471 16.5236 7.53537 16.2978 7.53537 16.0196C7.53537 13.7039 9.36582 11.8196 11.6154 11.8196C11.8856 11.8196 12.105 12.0454 12.105 12.3236C12.105 12.6018 11.8856 12.8276 11.6154 12.8276ZM17.328 9.42598L16.0923 10.6981C15.899 10.5435 15.7012 10.4131 15.5113 10.3291C15.1953 10.19 14.9649 10.1954 14.8467 10.3177L14.2122 10.9709C12.1154 9.82716 9.45656 10.1632 7.69139 11.9802C5.52475 14.2113 5.52475 17.828 7.69139 20.0584C9.85934 22.2894 13.3721 22.2894 15.5393 20.0584C17.3045 18.2413 17.6309 15.5049 16.5199 13.3464L17.1544 12.6932C17.2725 12.5716 17.2784 12.3344 17.1433 12.0091C17.0617 11.8136 16.9344 11.6093 16.7849 11.4104L18.0206 10.1396C18.2113 9.94274 18.2113 9.62287 18.0206 9.42598C17.8294 9.22975 17.5186 9.22975 17.328 9.42598Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
