import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withDietTracker } from '../../apollo/stores/trackers';
import DietTrackerWriteContainer from '../../containers/DietTrackerWriteContainer';
import analytics from '../../libs/analytics';
// Queries
import { DailyUserData } from '../../libs/graphql/queries/data';
import MixpanelService, {MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT} from '../../libs/mixpanel';
import Directions from '../shared/Directions';
import SubmitBar from '../shared/submit-bar';
import TrackerHeader from '../shared/tracker-header';
import TrackerInstructions from '../shared/TrackerInstructions';
import DietReadView from './DietReadView';

class DietTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      write: true,
      instructions: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    let { data } = nextProps;
    // not sure if we will need this method or not
    if (
      data &&
      Object.keys(data).length &&
      this.props.data.foods &&
      (data.foods.length !== this.props.data.foods.length ||
        data.cups_water !== this.props.data.cups_water ||
        data.custom_foods.length !== this.props.data.custom_foods.length)
    ) {
      //might need this method
      this.setState({ write: false });
    }
  }

  componentDidMount() {
    let { data, favoriteFoods } = this.props;
    //if data already exists, go straight to read state
    if (data && Object.keys(data).length) {
      //might need this method
      this.setState({ write: false });
    }
  }

  componentWillUnmount() {
    //reset diet store if user navigates away from page
    this.props.resetDietStore({
      variables: {},
    });
  }

  render() {
    const {
      data,
      dietStore,
      hydrateDietStore,
      updateFoods,
      updateCupsWater,
      addCustomFood,
      deleteCustomFood,
    } = this.props;

    const { write, instructions } = this.state;
    if (!data) return null;
    return (
      <div
        className={`card tracker tracker-loaded tracker-${
          this.props.theme.title
        } ${this.progressBadge()} ${this.props.theme.class || ''} ${
          write
            ? this.props.theme.color + ' card__active tracker__active'
            : this.props.theme.color +
              ' tracker__inactive tracker__inactive__tall'
        } card__width-2`}>
        <TrackerHeader
          title={this.props.theme.title}
          write={write}
          icon={this.props.icon}
          edit={this._toggleWrite.bind(this)}
          instructions={this.toggleInstructions.bind(this)}
          instructionsClass={instructions ? 'tracker-header__instructions' : ''}
        />
        <div
          className="tracker-body tracker-body__scroll"
          onClick={this._toggleWrite.bind(this)}>
          {instructions ? (
            <TrackerInstructions
              instructions={this.props.theme.instructionsText}
            />
          ) : null}
          {write && (
            <Directions directionText={this.props.theme.directionText} />
          )}
          <div className="tracker-body-form">
            {!instructions ? (
              write ? (
                <DietTrackerWriteContainer
                  data={data}
                  foodHandler={updateFoods}
                  waterHandler={updateCupsWater}
                  addCustomFood={addCustomFood}
                  deleteCustomFood={deleteCustomFood}
                  hydrateDietStore={hydrateDietStore}
                  store={dietStore}
                  favoriteFoods={this.props.favoriteFoods}
                />
              ) : (
                <DietReadView data={data} />
              )
            ) : null}
          </div>
        </div>
        {write ? (
          <SubmitBar
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.submitData.bind(this)}
            disableSubmit={this.state.instructions}
            disableCancel={this.state.instructions}
          />
        ) : null}
      </div>
    );
  }

  progressBadge() {
    if (!this.state.write) {
      return 'tracker-progress-complete';
    } else {
      return 'tracker-progress-incomplete';
    }
  }

  // Prevent opening write view if instructions are open
  _toggleWrite() {
    if (!this.state.write && !this.state.instructions) {
      this.setState({
        write: true,
      });
    }
  }

  toggleInstructions() {
    let instructions = !this.state.instructions;
    this.setState({
      instructions,
    });
  }

  onCancel() {
    let { data } = this.props;
    if (data && Object.keys(data).length) {
      //might need this method
      this.setState({ write: false });
    } else {
      // need to reset diet tracker form items
      this.props.resetDietStore({
        variables: {},
      });
    }
  }

  submitData() {
    let { foods, customFoods, cups_water } = this.props.dietStore;
    this.props
      .changeHandler({
        variables: {
          id: this.props.id,
          foods,
          custom_foods: customFoods,
          cups_water: cups_water,
          client_date: this.props.date || moment().format('YYYY-MM-DD'),
        },
        refetchQueries: () => [
          {
            query: DailyUserData,
            variables: {
              id: this.props.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
              readOnly: true,
            },
            fetchPolicy: 'cache-and-network',
          },
        ],
      })
      .then(res => {
        //console.log(foods);
        analytics().logEvent('any_track_item_updated', {});
        MixpanelService.track('DietTrackerUpdated', {
          WaterDrank: cups_water,
          FoodType: [...foods,...customFoods],
          DietDate: moment.utc(this.props.date).format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`) || moment.utc().format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`),
        });
        this.setState({
          write: false,
        });
      });
  }
}

export default compose(withDietTracker)(DietTracker);
