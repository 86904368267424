import React, { useState } from 'react';
import { MobileOnboarding, LaptopOnboarding } from '../assets/icons';
import { useHistory } from "react-router-dom";

const Onboarding = () => {
  const [isWeb, setIsWeb] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  let history = useHistory();

  const OnboardingElements = {
    titles: [
      'Get to know your eczema',
      'Track your symptoms',
      'Record your treatments',
      'Learn about your treatments',
      'Manage your appointments',
      'Explore new topics',
    ],
    texts: [
      `You live with your eczema every day, but how well do you really know
      it? Get a new understanding of it and how to manage it.`,
      `Customizable tools help you track the severity of your symptoms and monitor triggers over 48 hours.`,
      `Edit, add or delete your treatment options in the Treatment Tracker.`,
      `Visit the Learn tab to see snapshots of each week you tracked. Changes in your symptoms can reveal patterns in trigger exposures and treatment effects.`,
      `Get the most out of your next doctor’s visit with an EczemaWise Appointment Summary. The PO-SCORAD is a severity score based on body surface area and intensity of symptoms, which can help guide discussions with your doctor.`,
      `Browse the latest eczema insights, stories and research.`,
    ],
    urlsWebGif: [
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/Main.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/TrackSymtoms.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/Treatments.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/Learn.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/Manage.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Web/news.gif',
    ],
    urlsMobileGif: [
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/Main.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/TrackYourSymtoms.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/RecordTreatments.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/Learn.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/ManageAppointment.gif',
      'https://s3.us-west-2.amazonaws.com/www.eczemawise.org/Mobile/News.gif',
    ],
  };

  const showGif = () => {
    if (isWeb) {
      setIsWeb(!isWeb);
      setIsMobile(!isMobile);
    } else {
      setIsWeb(!isWeb);
      setIsMobile(!isMobile);
    }
  };

  const nextItem = () => {
    if (currentIndex < OnboardingElements.titles.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousItem = () => {
    if (currentIndex < OnboardingElements.titles.length + 1) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const lastItem = () => {
    setCurrentIndex(OnboardingElements.titles.length - 1);
  };

  const finishOnboarding = () => {
     history.push("/dashboard");
  };

  return (
    <div className={'onboarding-content'}>
      <div className="button-top-container">
        <button
          className={`button-options button-options-web
        ${isWeb && 'button-options-web-selected'}`}
          onClick={showGif}
          disabled={isWeb}>
          <LaptopOnboarding color={isWeb ? '#092A6D' : '#2D2926'} />
        </button>
        <button
          className={`button-options button-options-mobile 
        ${isMobile && 'button-options-mobile-selected'}`}
          onClick={showGif}
          disabled={isMobile}>
          <MobileOnboarding color={isMobile ? '#092A6D' : '#2D2926'} />
        </button>
      </div>
      <div style={{ marginTop: '-30px' }}>
        {isWeb && (
          <div style={{ zIndex: 1 }}>
            <img
              src={OnboardingElements.urlsWebGif[currentIndex]}
              alt="NEA Web gif"
              style={{ zIndex: 1 }}
            />
          </div>
        )}
        {isMobile && (
          <div>
            <img
              src={OnboardingElements.urlsMobileGif[currentIndex]}
              alt="NEA Mobile gif"
              className="mobile-gif"
            />
          </div>
        )}
      </div>
      <div className="onboarding-info-container">
        <p className="onboarding-titles">
          {OnboardingElements.titles[currentIndex]}
        </p>
        <p className={'onboarding-info'}>
          {OnboardingElements.texts[currentIndex]}
        </p>
      </div>
      {currentIndex > 0 && (
        <div className="stepper-points">
          <div className="container-dots">
            <div className={currentIndex === 1 ? 'circle1' : 'circle2'} />
            <div className={currentIndex === 2 ? 'circle1' : 'circle2'} />
            <div className={currentIndex === 3 ? 'circle1' : 'circle2'} />
            <div className={currentIndex === 4 ? 'circle1' : 'circle2'} />
            <div className={currentIndex === 5 ? 'circle1' : 'circle2'} />
          </div>
        </div>
      )}

      {currentIndex < OnboardingElements.titles.length - 1 ? (
        <div className={currentIndex > 0 ? "onboarding-buttons-actions-carrousel onboarding-buttons-actions-carrousel-margin" : "onboarding-buttons-actions-carrousel"}>
          <div>
            <button
              className="button-actions button-actions-nobg"
              onClick={lastItem}>
              Skip All
            </button>
          </div>
          <div>
            {currentIndex > 0 && (
              <button
                className="button-actions button-actions-nobg"
                onClick={previousItem}>
                Back
              </button>
            )}
            <button
              className="button-actions button-actions-bg"
              onClick={nextItem}>
              {currentIndex > 0 ? 'Next' : 'Let’s GET started'}
            </button>
          </div>
        </div>
      ) : (
        <div className="onboarding-buttons-actions-finish">
          <div>
            <button
              className="button-actions button-actions-nobg"
              onClick={previousItem}>
              Back
            </button>
            <button
              className="button-actions button-actions-bg"
              onClick={finishOnboarding}>
              Finish
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding;
