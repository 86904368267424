import React, { useCallback, useEffect } from 'react';

// APOLLO

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchAppointments } from '../../store/appointments/appointments-thunks';

const Manage = props => {
  const dispatch = useAppDispatch();
  const { appointments, loading, error } = useAppSelector(
    state => state.appointments,
  );

  const handleDelete = async id => {};

  const handleCreate = async () => {};

  const fetch = useCallback(() => {
    dispatch(fetchAppointments());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div style={{ padding: '30px' }}>
      <h2>Appointment2 {loading && <strong>...</strong>}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={() => fetch()}>Refetch</button>{' '}
      <button onClick={handleCreate}>Create appointment</button>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>provider</th>
            <th>date</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map(appointment => (
            <tr>
              <td>{appointment.id}</td>
              <td>
                <strong>
                  {appointment.provider_first_name}{' '}
                  {appointment.provider_last_name}
                </strong>
              </td>
              <td>{appointment.date}</td>
              <td>
                <button onClick={() => handleDelete(appointment.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Manage;
