import { flowRight as compose } from 'lodash';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import FadeIn from '../components/shared/FadeIn';
import ScrollToTop from '../components/shared/ScrollToTop';
import analytics from '../libs/analytics';

// Components
import Hero from '../components/home/Hero';
import ExplainerVideo from '../components/home/ExplainerVideo';
import Features from '../components/home/Features';
import Testimonials from '../components/home/Testimonials';
import TutorialVideo from '../components/home/TutorialVideo';

import MixpanelService, { MixpanelEvents } from '../libs/mixpanel';

const Home = props => {
  const { me, loading } = props;
  if (loading) return null;
  if (me && me.id) 
  {              
    MixpanelService.track(MixpanelEvents.TrackTabTapped)
    return <Redirect to={'/dashboard'} />
  };

  analytics().setCurrentScreen('home_page_open');

  return (
    <ScrollToTop>
      <FadeIn>
        <div className="main-content content-body page-home web-margin">
          <Hero />
          <ExplainerVideo />
          <Testimonials />
          <Features />
          <TutorialVideo />
        </div>
      </FadeIn>
    </ScrollToTop>
  );
};

export default compose(withRouter)(Home);
