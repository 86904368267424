import { useMutation, useQuery } from '@apollo/react-hooks';
import { capitalCase } from 'change-case';
import { Chart } from 'chart.js';
import gql from 'graphql-tag';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import SurveyTester from './SurveyTester';

const TRIGGER_MECHANISMS_HELP = `Trigger mechanisms are used to assign the surveys
automatically based on server events (e.g. User signup).<br/>
If you manually trigger a survey, all trigger mechanisms are ignored except 'Available for';
meaning that all users that qualify will be assigned the survey for the 'Available for' amount of
time.`;

const ACTION_HELP = `The action is the server event that will automatically assign the suvey.
If action is None, the survey will stop being assigned automatically.`;

const CHECK_AFTER_HELP = `If we pass this date (inclusive), assign the survey. If not set, always assign.`;

const CHECK_BEFORE_HELP = `If we have not passed this date (inclusive), assign the survey. If not set, always assign.`;

const DELAY_HELP = `Assign the survey N days after the Action listed (retroactive).`;

const AVAILABLE_FOR_HELP = `How long will the survey be visible for the user to take?`;

const REASSIGNABLE_FOR_HELP = `This survey can still be assigned 1, 2 or more times to the same user as long as he/she fulfils the qualifiers.`;

const DESCRIPTORS_HELP = `Descriptors are used to give the user information about the survey they're
about to take as well push notification (iOS/Andorid) information.`;

const NOTIFICATION_HELP = `What should the notification say?`;

const DESCRIPTION_HELP = `The description of the survey, call to action.`;

const TIME_TO_COMPLETE_HELP = `How long will this take the average user to complete?`;

const QUALIFIERS_HELP = `Qualifiers are used to assign the survey to users based on profile
characteristics. If the user qualifies, they will be assigned the survey automatically on the
server if the Trigger Mechanisms are set up or manually if you Manually Trigger.
<br/>
* Qualifiers are a logical AND; this means that a user must qualify in each filter to be assigned.
<br/>
* A Qualifier is a logical OR; this means that a user only has to match at least ONE condition under a specific qualifier.`;

const USER_ID_HELP = `A comma-separated list of user ids (not profile id). If left blank, this qualifier will not be checked.`;

const ZIP_HELP = `A comma-separated list of zip codes. If left blank, this qualifier will not be checked.`;

const MIN_AGE_HELP = `The minimum age of the participant. If left blank, this qualifier will not be checked.`;

const MAX_AGE_HELP = `The maximum age of the participant. If left blank, this qualifier will not be checked.`;

const genderOptions = [
  { label: 'Non-binary', value: 'non_binary' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

const raceOptions = [
  {
    value: 'white',
    label: 'White',
  },
  {
    value: 'black',
    label: 'Black or African American',
  },
  {
    value: 'asian',
    label: 'Asian or Asian American',
  },
  {
    value: 'native',
    label: 'Native',
  },

  {
    value: 'islander',
    label: 'Islander',
  },
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'multiple',
    label: 'Multiracial',
  },
  {
    value: 'none',
    label: 'Unknown',
  },
];

const referredOptions = [
  { label: 'Referred', value: true },
  { label: 'Not Referred', value: false },
];

const hispanicOptions = [
  { label: 'Hispanic', value: true },
  { label: 'Not hispanic', value: false },
];

const relationOptions = [
  { value: 'self', label: 'Self' },
  { value: 'caregiver_child', label: 'Care Giver' },
  { value: 'caregiver_other', label: 'Other' },
];

const Expandable = ({ header, content, expanded }) => {
  const [show, setShow] = useState(!!expanded);
  return (
    <div className={'expander' + (show ? ' showing' : '')}>
      <button
        onClick={e => {
          setShow(!show);
        }}>
        {header}
        {show ? (
          <span className="material-icons">keyboard_arrow_down</span>
        ) : (
          <span className="material-icons">keyboard_arrow_left</span>
        )}
      </button>
      <div style={{ display: show ? 'block' : 'none' }}>{content}</div>
    </div>
  );
};

const SurveyEditor = ({
  id,
  uri,
  name,
  ownerId,
  isActive,
  lastModified,
  creationDate,
  trigger_check_after,
  trigger_check_before,
  qualifiers,
  available_for,
  description,
  time_to_complete,
  notification_message,
  trigger,
  trigger_delay,
  is_reassignable,
}) => {
  const [succesfulSave, setSuccesfulSave] = useState(false);
  const [editing, setEditing] = useState(id === null);
  const [testing, setTesting] = useState(false);

  const { data: surveyTriggerData } = useQuery(gql`
    query {
      adminGetSurveyTriggerNames
    }
  `);

  // chart.js stuff

  let { refetch: refetchMeta, data: surveyMeta } = useQuery(
    gql`
      query($id: ID!) {
        adminGetSurveyMeta(id: $id) {
          complete
          started
          qualifies
          viewable
          expired
          total
        }
      }
    `,
    { variables: { id }, skip: !editing },
  );

  const [showChart, setShowChart] = useState(false);
  const informationChart = useRef(null);
  useEffect(() => {
    if (informationChart.current) {
      // And for a doughnut chart
      const { adminGetSurveyMeta } = surveyMeta;
      if (!adminGetSurveyMeta) return;

      let labels = ['qualifies', 'viewable', 'started', 'complete', 'expired'];

      let sum = 0;
      let _data = labels.map(k => {
        sum += adminGetSurveyMeta[k];
        return adminGetSurveyMeta[k];
      });

      let backgroundColor = [
        '#89cff0',
        '#FCE883',
        '#aae9aa',
        '#00c200',
        'indianred',
      ];

      if (sum === 0) {
        _data = [];
      }
      setShowChart(true);

      const data = {
        datasets: [
          {
            data: _data,
            backgroundColor,
          },
        ],
        labels: labels.map(l => capitalCase(l)),
      };

      var myDoughnutChart = new Chart(
        informationChart.current.getContext('2d'),
        {
          type: 'doughnut',
          data,
          options: {
            legend: {
              position: 'left',
              align: 'start',
            },
            tooltips: {
              callbacks: {},
            },
            cutoutPercentage: 60,
          },
        },
      );
    }
  }, [informationChart.current]);

  // manual trigger

  const [
    adminManuallyTriggerSurvey,
    { loading: loading2, error: error2 },
  ] = useMutation(
    gql`
      mutation adminManuallyTriggerSurvey($id: ID!) {
        adminManuallyTriggerSurvey(id: $id)
      }
    `,
    {
      onCompleted: res => {
        refetchMeta();
        alert(`Assigned surveys to ${res.adminManuallyTriggerSurvey} users.`);
      },
    },
  );

  // updates

  const [setSurvey, { loading, error }] = useMutation(
    gql`
      mutation adminSetSurvey($payload: SurveyPayload!) {
        adminSetSurvey(payload: $payload) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        refetchMeta();
        setSuccesfulSave(true);
        setEditedSurvey(false);
        setSuccesfulSave(false);
      },
    },
  );

  const [personaTypes, setPersonaTypes] = useState([]);
  useEffect(() => {
    const res = {
      page: 1,
      per_page: 10,
      total: 4,
      items: [
        {
          id: 1655,
          name: 'nea all the way',
          slug: 'nea',
          count: 4,
          url: 'https://nationaleczema.org/?taxonomy=nea__persona&term=nea',
          color: 'tan',
          parent: 0,
        },
        {
          id: 1656,
          name: 'passionate parent',
          slug: 'parent',
          count: 3,
          url: 'https://nationaleczema.org/?taxonomy=nea__persona&term=parent',
          color: 'tan',
          parent: 0,
        },
        {
          id: 1657,
          name: 'theres help',
          slug: 'help',
          count: 3,
          url: 'https://nationaleczema.org/?taxonomy=nea__persona&term=help',
          color: 'tan',
          parent: 0,
        },
        {
          id: 1658,
          name: 'whats the point',
          slug: 'point',
          count: 0,
          url: 'https://nationaleczema.org/?taxonomy=nea__persona&term=point',
          color: 'tan',
          parent: 0,
        },
      ],
    };
    setPersonaTypes([
      ...res.items.map(i => ({ label: i.name, value: `${i.id}` })),
      { label: 'other', value: 'other' },
    ]);
    if (!qualifiers)
      _setLocalQualifiers({
        ...localQualifiers,
        persona: [...res.items.map(i => `${i.id}`), 'other'],
      });
  }, []);

  const options = {
    races: raceOptions,
    genders: genderOptions,
    referred: referredOptions,
    hispanic: hispanicOptions,
    relation: relationOptions,
    persona: personaTypes,
  };

  /** Only editable values + id */
  const original = {
    id,
    trigger_check_after,
    trigger_check_before,
    available_for,
    description,
    time_to_complete,
    notification_message,
    trigger,
    trigger_delay,
    is_reassignable,
    qualifiers,
  };

  /** Only editable values + id */
  const [localValues, setNewValue] = useState({
    id,
    trigger_check_after,
    trigger_check_before,
    available_for,
    description,
    time_to_complete,
    notification_message,
    trigger,
    trigger_delay,
    is_reassignable,
  });

  const updateValue = e => {
    let newVal = null;
    switch (e.target.type) {
      case 'checkbox':
        newVal = e.target.checked;
        break;
      default:
        newVal = e.target.value;
        break;
    }
    setEditedSurvey(true);
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  if (qualifiers)
    Object.entries(qualifiers).forEach(([name, value]) => {
      if (!value) delete qualifiers[name];
    });

  const [editedSurvey, setEditedSurvey] = useState(false);
  const [localQualifiers, _setLocalQualifiers] = useState(
    Object.assign(
      {
        userIds: '',
        zipCodes: '',
        minAge: '0',
        maxAge: '999',
        races: raceOptions.map(o => o.value),
        genders: genderOptions.map(o => o.value),
        referred: referredOptions.map(o => o.value),
        hispanic: hispanicOptions.map(o => o.value),
        relation: relationOptions.map(o => o.value),
        persona: personaTypes.map(o => o.value),
      },
      qualifiers,
    ),
  );

  const setLocalQualifiers = localQualifiers => {
    setEditedSurvey(true);
    _setLocalQualifiers(localQualifiers);
  };

  const status = isActive ? (
    <span className={'status'}>
      Active <i className="material-icons active">check</i>
    </span>
  ) : (
    <span className={'status'}>
      Inactive <i className="material-icons inactive">close</i>
    </span>
  );

  if (!editing) {
    return (
      <div
        className={'card card__active treatment-tile editing'}
        onClick={e => setEditing(true)}>
        <h2 className={isActive ? 'active' : ''}>
          {name} {status}
        </h2>
      </div>
    );
  }

  const triggers = [{ label: 'None', value: null }];
  if (surveyTriggerData) {
    surveyTriggerData.adminGetSurveyTriggerNames.forEach(triggerName => {
      triggers.push({ label: triggerName, value: triggerName });
    });
  }

  const canvas = (
    <div style={{ display: showChart ? 'flex' : 'none' }}>
      <div style={{ flex: 3, overflow: 'hidden' }}>
        <canvas ref={informationChart} />
      </div>
      {surveyMeta &&
        surveyMeta.adminGetSurveyMeta &&
        (() => {
          const { adminGetSurveyMeta: data } = surveyMeta;
          return (
            <div className={'info-table'}>
              <table>
                <tbody>
                  <tr>
                    <td>Qualify for this survey</td>
                    <td>{data.qualifies}</td>
                  </tr>
                  <tr>
                    <td>In Queue</td>
                    <td>
                      {data.viewable + data.started} [{data.started} started]
                    </td>
                  </tr>
                  <tr>
                    <td>Completed</td>
                    <td>
                      {data.complete} /{' '}
                      {data.complete + data.viewable + data.started}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>{`${data.total}`}</td>
                  </tr>
                </tbody>
              </table>
              <div className={'notes'}>
                Notes:
                <ul>
                  <li>
                    The total is the number of users who have ever triggered the
                    survey (whether manually or not).
                  </li>
                  <li>
                    Users that qualify excludes users who have already triggered
                    the survey (cannot trigger the same survey twice).
                  </li>
                </ul>
              </div>
            </div>
          );
        })()}
    </div>
  );

  if (testing)
    return (
      <SurveyTester
        id={id}
        name={name}
        description={description}
        onCancel={() => {
          setTesting(false);
        }}
      />
    );

  return (
    <div className={'card card__active treatment-tile'}>
      <ReactTooltip
        type={'dark'}
        multiline={true}
        className={'admin-tooltip'}
      />
      <h2 className={isActive ? 'active' : ''}>
        {name}
        {status}
      </h2>
      <Expandable
        expanded={true}
        header={<h5>Info</h5>}
        content={
          <Fragment>
            {!showChart && (
              <h4>
                Set up some qualifiers to see how many users currently can take
                this survey.
              </h4>
            )}
            {canvas}
          </Fragment>
        }
      />
      <Expandable
        expanded={false}
        header={
          <h5>
            Trigger Mechanisms
            <span
              data-tip={TRIGGER_MECHANISMS_HELP}
              className="admin-help material-icons">
              help_outline
            </span>
          </h5>
        }
        content={
          <ul>
            <li>
              <label>
                Action
                <span
                  data-tip={ACTION_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <Select
                className="treatment-tile-dropdown"
                value={
                  triggers.find(o => o.value === localValues.trigger) ||
                  triggers[0]
                }
                isSearchable={false}
                onChange={v =>
                  updateValue({ target: { name: 'trigger', value: v.value } })
                }
                options={triggers}
              />
            </li>
            <li>
              <label>
                Trigger, Check After
                <span
                  data-tip={CHECK_AFTER_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'date'}
                value={localValues.trigger_check_after || ''}
                name={'trigger_check_after'}
                onChange={updateValue}
              />
              {localValues.trigger_check_after && (
                <button
                  onClick={e => {
                    updateValue({
                      target: { name: 'trigger_check_after', value: null },
                    });
                  }}>
                  <i className="material-icons">cancel</i>
                </button>
              )}
            </li>
            <li>
              <label>
                Trigger, Check Before
                <span
                  data-tip={CHECK_BEFORE_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'date'}
                value={localValues.trigger_check_before || ''}
                name={'trigger_check_before'}
                onChange={updateValue}
              />
              {localValues.trigger_check_before && (
                <button
                  onClick={e => {
                    updateValue({
                      target: { name: 'trigger_check_before', value: null },
                    });
                  }}>
                  <i className="material-icons">cancel</i>
                </button>
              )}
            </li>
            <li>
              <label>
                Trigger Delay
                <span
                  data-tip={DELAY_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'number'}
                value={localValues.trigger_delay || '0'}
                name={'trigger_delay'}
                onChange={updateValue}
              />
              <span className={'qualifier'}>days.</span>
            </li>
            <li>
              <label>
                Available for
                <span
                  data-tip={AVAILABLE_FOR_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'number'}
                value={localValues.available_for || '0'}
                name={'available_for'}
                onChange={updateValue}
              />
              <span className={'qualifier'}>days.</span>
            </li>
            <li>
              <label>
                It is reassignable
                <span
                  data-tip={REASSIGNABLE_FOR_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'checkbox'}
                checked={localValues.is_reassignable}
                name={'is_reassignable'}
                onChange={updateValue}
              />
            </li>
          </ul>
        }
      />
      <Expandable
        expanded={false}
        header={
          <h5>
            Descriptors
            <span
              data-tip={DESCRIPTORS_HELP}
              className="admin-help material-icons">
              help_outline
            </span>
          </h5>
        }
        content={
          <ul>
            <li>
              <label>
                Notification Message
                <span
                  data-tip={NOTIFICATION_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'text'}
                value={localValues.notification_message || ''}
                name={'notification_message'}
                onChange={updateValue}
              />
            </li>
            <li>
              <label>
                Description
                <span
                  data-tip={DESCRIPTION_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'text'}
                value={localValues.description || ''}
                name={'description'}
                onChange={updateValue}
              />
            </li>
            <li>
              <label>
                Time To Complete
                <span
                  data-tip={TIME_TO_COMPLETE_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'text'}
                value={localValues.time_to_complete || ''}
                name={'time_to_complete'}
                onChange={updateValue}
              />
            </li>
          </ul>
        }
      />
      <Expandable
        expanded={false}
        header={
          <h5>
            Qualifiers
            <span
              data-tip={QUALIFIERS_HELP}
              className="admin-help material-icons">
              help_outline
            </span>
          </h5>
        }
        content={
          <ul>
            <li>
              <label>
                User IDs
                <span
                  data-tip={USER_ID_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <div className={'multi-option'}>
                <textarea
                  placeholder={'1, 2, 3...'}
                  value={localQualifiers.userIds}
                  name={'userIds'}
                  rows={6}
                  onChange={e => {
                    setLocalQualifiers({
                      ...localQualifiers,
                      userIds: e.target.value,
                    });
                  }}
                />
                {
                  localQualifiers.userIds
                    .split(',')
                    .filter(
                      id => id.trim() !== '' && Number.isInteger(+id.trim()),
                    ).length
                }{' '}
                User IDs found.
              </div>
            </li>
            <li>
              <label>
                Zip Codes
                <span data-tip={ZIP_HELP} className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <div className={'multi-option'}>
                <textarea
                  placeholder={'12345, 12346...'}
                  value={localQualifiers.zipCodes}
                  name={'zipCodes'}
                  rows={6}
                  onChange={e => {
                    setLocalQualifiers({
                      ...localQualifiers,
                      zipCodes: e.target.value,
                    });
                  }}
                />
                {
                  localQualifiers.zipCodes
                    .split(',')
                    .filter(
                      id =>
                        id.trim().length === 5 && Number.isInteger(+id.trim()),
                    ).length
                }{' '}
                ZIP Codes found.
              </div>
            </li>

            <li>
              <label>
                Minimum Age
                <span
                  data-tip={MIN_AGE_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'number'}
                value={localQualifiers.minAge}
                name={'minAge'}
                onChange={e => {
                  setLocalQualifiers({
                    ...localQualifiers,
                    minAge: e.target.value,
                  });
                }}
              />
            </li>
            <li>
              <label>
                Maximum Age
                <span
                  data-tip={MAX_AGE_HELP}
                  className="admin-help material-icons">
                  help_outline
                </span>
              </label>
              <input
                type={'number'}
                value={localQualifiers.maxAge}
                name={'maxAge'}
                onChange={e => {
                  setLocalQualifiers({
                    ...localQualifiers,
                    maxAge: e.target.value,
                  });
                }}
              />
            </li>

            {Object.keys(options).map((name, i) => (
              <li key={i}>
                <label style={{ textTransform: 'capitalize' }}>
                  {capitalCase(name === 'races' ? 'ethnicities' : name)}
                </label>
                <div className={'multi-option'}>
                  {options[name].map((option, i) => (
                    <div key={i} className={'option'}>
                      <input
                        onChange={e => {
                          const _temp = localQualifiers[name].filter(
                            o => o !== option.value,
                          );
                          if (_temp.length === localQualifiers[name].length)
                            _temp.push(option.value);
                          setLocalQualifiers({
                            ...localQualifiers,
                            [name]: _temp,
                          });
                        }}
                        type="checkbox"
                        id={`${name}:${option.value}`}
                        checked={localQualifiers[name].includes(option.value)}
                      />
                      <label htmlFor={`${name}:${option.value}`}>
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        }
      />
      {editing && (
        <div className={'treatment_actions'}>
          <div className="stretch" />
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              if (
                editedSurvey &&
                window.confirm(
                  "This will lose any changes you've made to this survey, lose changes?",
                )
              ) {
                setNewValue(original);
                setEditedSurvey(false);
                setEditing(false);
              } else if (!editedSurvey) {
                setEditing(false);
              }
            }}>
            Cancel
          </button>
          {isActive && (
            <Fragment>
              <button
                disabled={editedSurvey || succesfulSave}
                className={'button button-regular button__gray'}
                onClick={e => {
                  adminManuallyTriggerSurvey({ variables: { id } });
                }}>
                Manually Trigger (
                {(surveyMeta &&
                  surveyMeta.adminGetSurveyMeta &&
                  surveyMeta.adminGetSurveyMeta.qualifies) ||
                  0}
                )
              </button>
              <button
                className={'button button-regular button__cobalt'}
                onClick={e => {
                  setTesting(true);
                }}>
                Test Survey
              </button>
            </Fragment>
          )}
          <button
            disabled={succesfulSave}
            className={'button button-regular button__green'}
            onClick={e => {
              setSurvey({
                variables: {
                  payload: {
                    ...localValues,
                    available_for: +localValues.available_for,
                    trigger_delay: +localValues.trigger_delay,
                    qualifiers: JSON.stringify(localQualifiers),
                  },
                },
              });
            }}>
            Save {succesfulSave && <i className={'material-icons'}>check</i>}
          </button>
        </div>
      )}
    </div>
  );
};

export default SurveyEditor;
