import React from 'react';

export default function InfectionsSvg() {
  return (
    <svg className="infections-svg" viewBox="0 0 50 50">
      <g>
        <path d="M42.1,21.1c-1.4-0.7-2.9-1.2-4.3-1.4c0.8-1.7,1.2-3.6,1.2-5.7c0-4.1-1.7-7.7-4.5-10.3c0,0.1,0,0.2,0,0.3v7.4   c0,5.3-4.3,9.5-9.5,9.5c-5.2,0-9.5-4.3-9.5-9.5V4.1c0-0.3,0-0.5,0-0.8c-3,2.6-4.9,6.4-4.9,10.7c0,2,0.4,3.9,1.2,5.7   c-1.3,0.2-2.7,0.7-4,1.3C4.3,23,1.9,26.3,1,30c0.2-0.1,0.4-0.3,0.6-0.4l6.3-3.3c4.5-2.3,10-0.6,12.3,3.8c2.3,4.4,0.6,9.9-3.8,12.3   L10,45.7c-0.1,0.1-0.2,0.1-0.3,0.1c3.4,1.2,7.2,1,10.7-0.8c1.8-1,3.4-2.3,4.6-3.9c1.2,1.6,2.7,2.9,4.5,3.9c3.4,1.8,7.3,2,10.7,0.8   c-0.1,0-0.2-0.1-0.3-0.1l-6.3-3.3c-4.5-2.3-6.2-7.8-3.8-12.3c2.3-4.5,7.8-6.2,12.3-3.8l6.3,3.3c0.2,0.1,0.4,0.2,0.6,0.4   C48.1,26.3,45.7,23,42.1,21.1z" />
        <path d="M12.3,27.7c0.3,4.1,2.6,7.6,5.8,9.7C20.3,33.1,17.3,27.8,12.3,27.7z" />
        <path d="M19,15.4c2.8,4.3,9.1,4.3,11.9,0C27.2,13.4,22.6,13.5,19,15.4z" />
        <path d="M31.9,37.4c3.3-2.1,5.5-5.6,5.8-9.7C32.6,27.8,29.7,33.3,31.9,37.4z" />
      </g>
    </svg>
  );
}
