import { flowRight as compose } from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Eye } from '../assets/icons/';
import ValidationIcon from '../assets/icons/validation';
import ErrorAlert from '../components/shared/ErrorAlert';
import { resetPassword } from '../libs/graphql/mutations/user';
import passwordValidation from '../libs/passwordValidation';
import eczemaLogo from '../assets/logos/EczemaLogoHorizontal.png';

const passwordRulesMessages = {
  length: 'At least 8 characters',
  upper: 'At least 1 uppercase letter',
  lower: 'At least 1 lowercase letter',
  special: 'At least 1 special character',
  num: 'At least 1 number',
  match: 'Passwords must match',
};

const withResetPassword = graphql(resetPassword, {
  name: 'resetPassword',
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
      password: '',
      password_confirm: '',
      rulesTracker: {},
      showPassword: false,
      showPasswordConfirm: false,
      resetFailed: false,
      resetFailedMessage: '',
    };
  }

  componentWillMount() {
    let { location } = this.props.history;
    if (location.search) {
      let queryObj = queryString.parse(location.search);
      this.setState({
        email: queryObj.user || '',
      });
    }
  }

  render() {
    return (
      <div className="page page-reset-password page-login page__cobalt">
        <div className="reset-password login-card">
          <div className="login-logo site-logo-wrapper site-logo-wrapper__column">
            <img src={eczemaLogo} alt="logo" />
          </div>
          <div className="login-title">
            <h2 className="login-title-content title title__cobalt">
              Change Your Password
            </h2>
          </div>
          <div className="alert-space">
            {this.state.resetFailed ? (
              <ErrorAlert message={this.state.resetFailedMessage} />
            ) : null}
          </div>
          <form className="login-form form">
            <div className="form-item">
              <div className="label-wrapper__code">
                <label className="form-input-label">Your Code</label>
              </div>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'code')}
                placeholder="Enter your unique code"
              />{' '}
            </div>
            <div className="form-item">
              <div className="label-wrapper__password">
                <label className="form-input-label">New Password</label>
                <button
                  className={`button password__visible ${this.state.showPassword
                    ? 'password__visible__show'
                    : 'password__visible__hide'
                    }`}
                  onClick={this._togglePassword.bind(this, 'password')}>
                  <Eye />
                </button>
              </div>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'password')}
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Type your password"
              />{' '}
            </div>
            <div className="form-item">
              <div className="label-wrapper__password">
                <label className="form-input-label">Verify Password</label>
                <button
                  className={`button password__visible ${this.state.showPasswordConfirm
                    ? 'password__visible__show'
                    : 'password__visible__hide'
                    }`}
                  onClick={this._togglePassword.bind(this, 'password_confirm')}>
                  <Eye />
                </button>
              </div>
              <input
                className="form-input-field"
                onChange={this._updateInput.bind(this, 'password_confirm')}
                type={this.state.showPasswordConfirm ? 'text' : 'password'}
                placeholder="Retype your password"
              />{' '}
            </div>
            <div className="form-item">{this._rulesCheck()}</div>
            <div className="form-item">
              <button
                className="button button-regular button__cobalt"
                disabled={this._checkButtonState() || !this.state.code}
                onClick={this._resetPassword.bind(this)}>
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  _updateInput(name, e) {
    e.preventDefault();
    let newState = {};
    let value = e.target.value;
    newState[name] = value;
    if (name === 'code' && this.state.resetFailed) {
      newState.resetFailed = false; //reset validation failure when user starts editing input field
      newState.resetFailedMessage = '';
    }
    this.setState(newState, () => {
      if (name === 'password' || name === 'password_confirm') {
        this._validatePassword();
      }
    });
  }

  _checkButtonState() {
    let { rulesTracker } = this.state;
    return !(
      rulesTracker.match &&
      rulesTracker.length &&
      rulesTracker.num &&
      rulesTracker.special &&
      rulesTracker.upper &&
      rulesTracker.lower
    );
  }

  _resetPassword(e) {
    e.preventDefault();
    let email = this.state.email;
    let password = this.state.password;
    let code = this.state.code.replace(/\s/g, ''); //strip out any whitespace that might have accidentally been copied in with code
    // run validation
    let keepGoing = this._validatePassword();
    if (keepGoing) {
      this.props
        .resetPassword({
          variables: {
            email: email,
            password: password,
            code: code,
          },
        })
        .then(res => {
          //redirect to login page
          this.props.history.push('/login');
        })
        .catch(e => {
          let message = 'Unexpected error. Please try again.';
          if (e.graphQLErrors && e.graphQLErrors.length) {
            message = e.graphQLErrors[0].message;
          }
          this.setState({
            resetFailed: true,
            resetFailedMessage: message,
          });
        });
    }
  }

  _validatePassword() {
    let password = this.state.password;
    let password_confirm = this.state.password_confirm;
    let rulesTestResults = passwordValidation(password, password_confirm);
    this.setState({
      rulesTracker: rulesTestResults,
    });

    return (
      rulesTestResults.match &&
      rulesTestResults.length &&
      rulesTestResults.num &&
      rulesTestResults.special &&
      rulesTestResults.upper &&
      rulesTestResults.lower
    );
  }

  _togglePassword(field, e) {
    e.preventDefault();
    let currentState;
    if (field === 'password') {
      currentState = this.state.showPassword;
      this.setState({
        showPassword: !currentState,
      });
    } else if (field === 'password_confirm') {
      currentState = this.state.showPasswordConfirm;
      this.setState({
        showPasswordConfirm: !currentState,
      });
    }
  }

  _rulesCheck() {
    const renderRules = () => {
      let rules = [];
      Object.keys(passwordRulesMessages).forEach((error, index) => {
        rules.push(
          <li
            key={index}
            className={
              this.state.rulesTracker[error] ? (
                'subtext validation-item__uppercase validation-item validation-item__passing'
              ) : (
                'subtext validation-item__uppercase validation-item'
              )
            }
            aria-label={(this.state.rulesTracker[error] ? 
              'The password meets the requirement: ' : 'Password does not meet the requirement: ') 
              + passwordRulesMessages[error]}
          >
            <span className="validation-icon-wrapper" aria-hidden="true">
              <ValidationIcon />
            </span>
            <span aria-hidden="true">
              {passwordRulesMessages[error]}
            </span>
          </li>
        );
      });
      return rules;
    };

    return (
      <div className="validation">
        <ul aria-label="Password requirements" className="validation-list">{renderRules()}</ul>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  withResetPassword,
)(ResetPassword);
