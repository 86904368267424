import React from 'react';
import { TreatmentIcon } from '../../assets/icons/trackers';
import config from '../../config';

const TrendsSummaryTreatment = ({ treatmentData, treatments }) => {
  const renderValues = val => {
    if (treatmentData && treatmentData.data) {
      const items = treatmentData.data.filter(item => {
        return item[val] === true;
      });
      return items.map((item, index) => {
        let label = treatments.find(treatment => {
          return treatment.id === item.id;
        });
        return (
          <li key={index} className="trends-treatment-list-item-value">
            {label.name || item.type}
          </li>
        );
      });
    }
  };

  return (
    <div
      className={`trends-summary-treatment ${config.trackerTheme.treatment}`}>
      <ol className="trends-treatment-list">
        <li className="trends-treatment-list-item">
          <div className="trends-treatment-list-item-title ">
            <span className="trends-summary-text__14p600">Morning</span>
          </div>
          <ul className="trends-treatment-list-item-values trends-summary-text__16p600">
            {renderValues('morning')}
          </ul>
        </li>
        <li className="trends-treatment-list-item">
          <div className="trends-treatment-list-item-title">
            <span className="trends-summary-text__14p600">Afternoon</span>
          </div>
          <ul className="trends-treatment-list-item-values trends-summary-text__16p600">
            {renderValues('afternoon')}
          </ul>
        </li>
        <li className="trends-treatment-list-item">
          <div className="trends-treatment-list-item-title">
            <span className="trends-summary-text__14p600">Evening</span>
          </div>
          <ul className="trends-treatment-list-item-values trends-summary-text__16p600">
            {renderValues('evening')}
          </ul>
        </li>
        <li className="trends-treatment-list-item">
          <div className="trends-treatment-list-item-title">
            <span className="trends-summary-text__14p600">Bedtime</span>
          </div>
          <ul className="trends-treatment-list-item-values trends-summary-text__16p600">
            {renderValues('bedtime')}
          </ul>
        </li>
      </ol>
      {treatmentData && treatmentData.notes && (
        <div className="tracker-notes">
          <h3 className="trends-summary-text__14p600">Notes</h3>
          <p>{treatmentData.notes}</p>
        </div>
      )}
    </div>
  );
};

export default TrendsSummaryTreatment;
