import React from 'react';

export default function DietOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64621C15.8834 9.62255 15.8428 9.61985 15.8111 9.63878L11.2265 12.3614L11.6436 5.69308C11.6456 5.64913 11.6152 5.60924 11.5719 5.60113C11.5273 5.59302 11.4854 5.61803 11.4712 5.65995L7.86086 16.491C7.84936 16.5255 7.86086 16.564 7.88925 16.5877C7.91765 16.6107 7.95754 16.6134 7.98999 16.5945L12.1365 14.1328L11.6849 21.96C11.6822 22.0039 11.7133 22.0431 11.7565 22.0519C11.7619 22.0533 11.7673 22.0539 11.7727 22.0539C11.8106 22.0539 11.8444 22.0296 11.8566 21.9931L15.9402 9.7429C15.9524 9.70774 15.9402 9.6692 15.9118 9.64621Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3985C20.1646 12.3666 20.1089 12.3673 20.0725 12.4L15.144 16.9395C15.1151 16.9663 15.1047 17.0063 15.118 17.0435C15.1307 17.0806 15.1648 17.1051 15.2041 17.108L17.3225 17.235L14.7432 20.9506C14.715 20.9915 14.7224 21.0464 14.7595 21.0791C14.7774 21.0954 14.8004 21.1028 14.8234 21.1028C14.8464 21.1028 14.8701 21.0939 14.8887 21.0768L19.8165 16.538C19.8454 16.512 19.8558 16.4712 19.8424 16.4341C19.8298 16.3977 19.7957 16.3725 19.7571 16.3702L17.6387 16.2433L20.2173 12.5269C20.2462 12.4868 20.2388 12.4311 20.2017 12.3985Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2402 14.0103C10.5999 14.0103 10.079 14.5312 10.079 15.1715C10.079 15.4462 9.85609 15.6692 9.58138 15.6692C9.30668 15.6692 9.08373 15.4462 9.08373 15.1715C9.08373 13.9825 10.0512 13.015 11.2402 13.015C11.5149 13.015 11.7379 13.238 11.7379 13.5127C11.7379 13.7874 11.5149 14.0103 11.2402 14.0103ZM16.4391 11.605C15.1445 11.605 14.5871 12.1192 13.5626 12.1192C12.5381 12.1192 11.9808 11.605 10.6862 11.605C7.86414 11.605 7.28619 14.2618 7.72015 16.8967C8.18728 19.7334 9.15274 22.2216 11.2615 22.2216C12.6881 22.2216 12.9635 21.6245 13.5626 21.6245C14.1618 21.6245 14.4372 22.2216 15.8638 22.2216C17.9725 22.2216 18.938 19.7334 19.4051 16.8967C19.8391 14.2618 19.2611 11.605 16.4391 11.605Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5471 10.2768C17.6353 9.18922 17.5418 7.29348 17.5418 7.29348C17.5418 7.29348 15.6467 7.20059 14.5585 8.28813C13.471 9.37568 13.5645 11.2714 13.5645 11.2714C13.5645 11.2714 15.4596 11.3643 16.5471 10.2768Z"
        fill="white"
      />
    </svg>
  );
}
