import React from 'react';
import Button from './button';

const SubmitBar = ({
  onCancel,
  onSubmit,
  submitLabel = 'Save',
  cancelLabel = 'Cancel',
  disableSubmit = false,
  disableCancel = false,
}) => (
  <div className="tracker-footer">
    <div className="button-row-right">
      <Button
        className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer button-row-space"
        onClick={onCancel}
        text={cancelLabel}
        disabled={disableCancel}
      />
      <Button
        onClick={onSubmit}
        className="button button__secondary100 button__btn-14-600 button-rectangle-skin-footer"
        text={submitLabel}
        disabled={disableSubmit}
      />
    </div>
  </div>
);

export default SubmitBar;
