import React from 'react';

export default function MoisturizingIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#1D62ED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0298 20.3908C15.019 20.3908 14.4834 19.4057 14.4834 18.5197C14.4834 16.6045 16.0298 15.0455 16.0298 15.0455C16.0298 15.0455 17.5761 16.6045 17.5761 18.5197C17.5761 19.4057 17.0406 20.3908 16.0298 20.3908ZM19.2735 15.821C19.2081 14.6532 18.5153 13.6922 17.5761 13.3142V12.5633C17.5761 12.2472 17.3452 11.9906 17.0607 11.9906H16.803V10.5588C16.803 10.0846 16.4571 9.69971 16.0298 9.69971C15.6468 9.69971 15.3313 10.0101 15.2695 10.4156H13.4525C12.9551 10.4156 12.5505 10.8658 12.5505 11.4179V11.9906H13.3236V11.4179C13.3236 11.3394 13.3814 11.2747 13.4525 11.2747H15.2566V11.9906H14.9989C14.7143 11.9906 14.4834 12.2472 14.4834 12.5633V13.3142C13.5443 13.6922 12.8515 14.6532 12.786 15.821L12.4556 21.6915C12.4371 22.0214 12.6731 22.2997 12.97 22.2997H19.0895C19.3864 22.2997 19.6225 22.0214 19.6039 21.6915L19.2735 15.821Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4285 18.5199C15.4285 18.3114 15.4564 18.0915 15.5115 17.867C15.5492 17.7146 15.4682 17.5571 15.3306 17.5153C15.1945 17.4729 15.0517 17.5634 15.0141 17.7164C14.9471 17.9901 14.9131 18.2604 14.9131 18.5199C14.9131 19.0588 15.2162 19.9139 16.0301 19.9139C16.1723 19.9139 16.2878 19.7856 16.2878 19.6275C16.2878 19.4689 16.1723 19.3412 16.0301 19.3412C15.5388 19.3412 15.4285 18.7329 15.4285 18.5199Z"
        fill="white"
      />
    </svg>
  );
}
