import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import ReactMarkdown from 'react-markdown';
import { withBigPicture } from '../../apollo/stores/appointments';
import { ProgressStep } from '../../assets/icons';
import DragScale from '../shared/DragScale';

const PaintBigPicture = props => {
  const focusElement = React.useRef(null);

  React.useEffect(() => {
    if (focusElement.current)
      focusElement.current.focus();
  }, []);
  
  const { data, loading, error } = useQuery(gql`
    query {
      getSkindexQuestions {
        header
        questions {
          text
          description
          id
          scale
        }
        footer
      }
    }
  `);

  if (loading || error) return null;

  const { getSkindexQuestions } = data;

  let items = getSkindexQuestions.questions.map((question, index) => {
    return (
      <div
        className="skin-scale-wrapper form-section form__border-top tracker-theme-cobalt"
        key={index}>
        <div className="form-item-row">
          <div className="form-item survey__question">
            <p className="title">
              {index + 1}) {question.text}
            </p>
            <p className="help-text">{question.description}</p>
          </div>
          <div className="form-item">
            <DragScale
              maxValue={question.scale}
              onIncrease={incrementFunction.bind(
                null,
                question.id,
                props.pictureForm[question.id],
                props.updatePictureScaleValue,
              )}
              onDecrease={decrementFunction.bind(
                null,
                question.id,
                props.pictureForm[question.id],
                props.updatePictureScaleValue,
              )}
              onSetDirectly={setDirectly.bind(
                null,
                question.id,
                props.updatePictureScaleValue,
              )}
              level={props.pictureForm[question.id] || 0}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="tracker-body survey__paint-big-picture">
      <div className="survey-title">
        <div className="survey-title__row">
          <ProgressStep step="2" />
          <h2 className="title title__cobalt" tabindex="0" ref={focusElement}>Think Big Picture</h2>
        </div>
        <p className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
          The more your provider knows about your medical situation, the better.
          Help them along by answering these questions.
        </p>
      </div>
      <div
        className="form-item survey__question"
        style={{ marginLeft: '24px' }}>
        <p className="title">{getSkindexQuestions.header}</p>
      </div>
      <div className="form">{items}</div>
      <span className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
        <ReactMarkdown
          source={getSkindexQuestions.footer}
          renderers={{
            link: ({ children, href }) => {
              return (
                <a href={href} target={'_blank'} rel="noreferrer">
                  {children}
                </a>
              );
            },
          }}
        />
      </span>
    </div>
  );
};

export default compose(withBigPicture)(PaintBigPicture);

const setDirectly = (symptom, storeHandler, currentValue) => {
  let value = currentValue + 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: value,
    },
  }).catch(e => {
    console.log(e);
  });
};

const incrementFunction = (symptom, currentValue, storeHandler) => {
  let incrementedValue = currentValue === 10 ? 10 : currentValue + 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: incrementedValue,
    },
  }).catch(e => {
    console.log(e);
  });
};

const decrementFunction = (symptom, currentValue, storeHandler) => {
  let incrementedValue = !currentValue ? 0 : currentValue - 1;
  return storeHandler({
    variables: {
      name: symptom,
      value: incrementedValue,
    },
  }).catch(e => {
    console.log(e);
  });
};
