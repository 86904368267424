import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import * as Unicons from '@iconscout/react-unicons';
import Resizer from 'react-image-file-resizer';

// Queries
import { galleryImages } from '../libs/graphql/queries/image';
// Mutation
import { updateFavoritesImages, updateImage } from '../libs/graphql/mutations/image';

// Components
import DateSelection from '../components/gallery/dateSelection';
import ImageGallery from '../components/gallery/imageGallery';
import ImageDetails from '../components/gallery/imageDetails';
import UploadImage from '../components/gallery/uploadImage';
import DeleteImages from '../components/gallery/deleteImages';
import Button from '../components/shared/button';
import FilterSortControls from '../components/gallery/FilterSortControls';
import FiltersList from '../components/gallery/FiltersList';


const SORT_BY_NEWEST = 'newest';

const Gallery = ({ me }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState(new Set([]));
  const [multiSelectActive, setMultiSelectActive] = useState(false);
  const [onlyFavorites, setOnlyFavorites] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showDeleteImages, setShowDeleteImages] = useState(false);
  const [multipleDeletion, setMultipleDeletion] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY_NEWEST);
  const [severityFilters, setSeverityFilters] = useState({
    Mild: false,
    Moderate: false,
    Severe: false,
  });
  const [bodyPartFilter, setBodyPartFilter] = useState(new Set([]));
  const [dateRange, setDateRange] = useState({ fromDate: new Date(), toDate: new Date() });
  const [numberOfFiltersActive, setNumberOfFiltersActive] = useState(0);

  //GraphQL
  const [getGalleryImages, { data: galleryImagesData }] = useLazyQuery(galleryImages, {
    fetchPolicy: 'no-cache', // Consider your caching policies as needed
  });
  const [updateFavorites] = useMutation(updateFavoritesImages);
  const [update] = useMutation(updateImage);

  useEffect(() => {
    if (galleryImagesData) {
      setImages(galleryImagesData.getEczemaGalleryImages);
    }
  },
  [galleryImagesData]);

  useEffect(() => {
    setNumberOfFiltersActive(
      bodyPartFilter.size + 
      onlyFavorites + 
      severityFilters.Mild + 
      severityFilters.Moderate + 
      severityFilters.Severe);

      setSelectedImages(new Set([]));
  },
  [severityFilters, onlyFavorites, bodyPartFilter]);

  const handleDateChange = async (fromDate, toDate) => {
    fromDate = fromDate.toISOString().split('T')[0];
    toDate = toDate.toISOString().split('T')[0];
    setDateRange({ fromDate, toDate });
    setSelectedImages(new Set([]));

    // Refetch data using new date ranges
    await getGalleryImages({
      variables: {
        userid: me.id,
        date_from: fromDate,
        date_to: toDate,
      }
    });
  };

  const handleToggleFavorite = async () => {
    if (selectedImage) {
      try {
        await updateFavorites({
          variables: {
            userid: +me.id,
            imagesID: [+selectedImage.imageid],
            asFavorites: !(selectedImage.favorite)
          }
        });

        selectedImage.favorite = !(selectedImage.favorite)
        setSelectedImage(selectedImage)

      } catch (error) {
        console.error("Error updating favorite:", error);
      }
    }
  };

  function triggerDownload(href, filename) {
    const downloadLink = document.createElement('a');
    downloadLink.href = href;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const downloadImage = () => {
    if (selectedImage) {
      const filename = `${selectedImage.area_affected}_${selectedImage.imageid}.png`;
      triggerDownload(`/img/${selectedImage.imageid}`, filename);
    }
  };

  const downloadImages = () => {
    selectedImages.forEach(image => {
      const filename = `body_${image}.png`;
      triggerDownload(`/img/${image}`, filename);
    });
  };

  const handleEditClick = () => {
    document.getElementById('edit-skin-images').click();
  };

  const onUpdateImage = e => {
    const formData = new FormData();
    Promise.all(
      Object.keys(e.target.files)
        .map(key => e.target.files[key])
        .map(file => {
          formData.append(file.name, file);
          return new Promise((res, rej) => {
            Resizer.imageFileResizer(
              file,
              600,
              600,
              'JPEG',
              100,
              0,
              blob => {
                res(new File([blob], file.name));
              },
              'blob',
            );
          });
        }),
    ).then(async files => {
      try {
        const result = await update({
          variables: {
            userid: +me.id,
            imageid: +selectedImage.imageid,
            file: files[0]
          }
        });

        const newImage = result.data.updateImage;
        selectedImage.imageid = newImage.id;
        setSelectedImage(selectedImage)

      } catch (error) {
        console.error("Error updating favorite:", error);
      }
    })
  };

  const selectAllImages = () => {
    const newImages = new Set();

    images.forEach(image => {
        newImages.add(image.imageid);
    });

    setSelectedImages(new Set([...newImages]));
  };

  const toggleImageSelection = (imageId) => {
    if (!multiSelectActive) return;

    setSelectedImages(prevSelectedImages => {
      const updatedImages = new Set(prevSelectedImages);
      if (updatedImages.has(imageId)) {
        updatedImages.delete(imageId);
      } else {
        updatedImages.add(imageId);
      }
      return updatedImages;
    });
  };

  const imagesOnClick = (image) => {
    toggleImageSelection(image.imageid);
    setSelectedImage(image);
  };

  const refreshGallery = async () => {
    const { fromDate, toDate } = dateRange;
    await getGalleryImages({
      variables: {
        userid: me.id,
        date_from: fromDate,
        date_to: toDate,
      }
    });
  };

  const toggleSeverityFilter = (severity) => {
    setSeverityFilters((prevFilters) => ({
      ...prevFilters,
      [severity]: !prevFilters[severity],
    }));
  };

  const toggleBodyPartFilter = (bodyPartId) => {
      setBodyPartFilter(prevSelectedBodyPart => {
        const updatedBodyPart = new Set(prevSelectedBodyPart);
        if (updatedBodyPart.has(bodyPartId)) {
          updatedBodyPart.delete(bodyPartId);
        } else {
          updatedBodyPart.add(bodyPartId);
        }
        return updatedBodyPart;
      });
  };

  const resetAllFilters = () => {
      setOnlyFavorites(false);
      setSeverityFilters({
        Mild: false,
        Moderate: false,
        Severe: false,
      });
      setBodyPartFilter(new Set([]));
  }

  return (
    <div className='page-gallery'>
      <header>
        <div className="date-bar">
          <div className="main-dashboard__sidebar">
            <div className="sidebar-title">
              <Unicons.UilImage size="18" />
              <h1 className="headers headers__black100 headers__mediumbold">
                My Eczema Gallery
              </h1>
            </div>
          </div>

          <div className='button__wrapper' >
            <div className="stretch"></div>
            <DateSelection onDateChange={handleDateChange} />
            <div className='filter-container' onClick={() => { setShowFilters(!showFilters); }}>
              <Unicons.UilFilter size="20" className='icon-button'/>
              { numberOfFiltersActive > 0 && (<span className='filter-number'>{numberOfFiltersActive}</span>) }
            </div>
            <Button
              className="button button__secondary100 button__btn-14-600 button-rectangle-medium button-row-space"
              onClick={() => setShowUploadImage(!showUploadImage)}
              text={'Upload photo'}
            />
          </div>
        </div>
      </header>
      <div className='card-left'>
        <div className='card-title'>
          <h4>All photos {selectedImages.size > 0 ? ` - (Selected ${selectedImages.size})` : ''}</h4>
          <div className='card-buttons'>
            {
              multiSelectActive ?
                (<div className='select-menu'>
                  <Unicons.UilTrashAlt disable={true} size="20" className='icon-button' onClick={() => {
                    if (selectedImages.size > 0) {
                      setShowDeleteImages(!showDeleteImages);
                      setMultipleDeletion(true);
                    }
                  }} />
                  <Unicons.UilDownloadAlt size="20" className='icon-button' onClick={downloadImages} />
                  <Unicons.UilStar size="20" className={`icon-button ${onlyFavorites && 'icon-blue'}`} onClick={() => setOnlyFavorites(!onlyFavorites)} />
                  <span onClick={selectAllImages}>
                    Select all
                  </span>
                </div>)
                :
                (<span onClick={() => { setMultiSelectActive(!multiSelectActive) }}>
                  Select photos
                </span>)
            }
          </div>
        </div>
        <div className='gallery'>
        {
         numberOfFiltersActive > 0 && 
         <FiltersList 
          severityFilters={severityFilters}
          toggleSeverityFilter={toggleSeverityFilter}
          favoriteFilter={onlyFavorites}
          toggleFavoriteFilter={() => { setOnlyFavorites(!onlyFavorites) }}
          bodyPartFilter={Array.from(bodyPartFilter)}
          toggleBodyPartFilter={toggleBodyPartFilter}
          resetAll={resetAllFilters}
         />
        }
          <ImageGallery
            images={images}
            multiSelectActive={multiSelectActive}
            selectedImages={selectedImages}
            imagesOnClick={imagesOnClick}
            sortCriteria={sortBy} 
            severityFilters={severityFilters}
            favoriteFilter={onlyFavorites}
            bodyPartFilter={bodyPartFilter}
          />
        </div>
      </div>
      <div className='card-right'>
        <div className='card-title'>
          <h4>Photo Details</h4>
          {selectedImage && (
            <div className='card-buttons'>
              <Unicons.UilStar size="20" className='icon-button' onClick={handleToggleFavorite} />
              <Unicons.UilDownloadAlt size="20" className='icon-button' onClick={downloadImage} />
              <Unicons.UilPen size="20" className='icon-button' onClick={handleEditClick} />
              <Unicons.UilTrashAlt size="20" className='icon-button' onClick={() => {
                setShowDeleteImages(!showDeleteImages);
                setMultipleDeletion(false);
              }} />
              <input
                type="file"
                name="file1"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
                onChange={onUpdateImage}
                id="edit-skin-images"
              />
            </div>
          )}
        </div>
        <div className='gallery'>
          <ImageDetails selectedImage={selectedImage} />
        </div>
      </div>
      <UploadImage
        me={me}
        isModalOpen={showUploadImage}
        onClose={() => setShowUploadImage(!showUploadImage)}
        onUpload={() => {
          refreshGallery();
          setShowUploadImage(!showUploadImage);
        }}
      />
      <DeleteImages
        me={me}
        isModalOpen={showDeleteImages}
        onClose={() => setShowDeleteImages(!showDeleteImages)}
        onDelete={() => {
          refreshGallery();
          setShowDeleteImages(!showDeleteImages);
          setSelectedImage(null)
        }}
        imagesIds={multipleDeletion ? Array.from(selectedImages).map(str => parseInt(str)) : [+selectedImage?.imageid]}
      />
      <FilterSortControls
        showFilters={showFilters}
        onClose={() => { setShowFilters(false); }}
        sortCriteria={sortBy}
        setSortCriteria={setSortBy}
        severityFilters={severityFilters}
        toggleSeverityFilter={toggleSeverityFilter}
        favoriteFilter={onlyFavorites}
        bodyPartFilter={Array.from(bodyPartFilter)}
        toggleBodyPartFilter={toggleBodyPartFilter}
        toggleFavoriteFilter={() => { setOnlyFavorites(!onlyFavorites) }}
      />
    </div>
  );
}

export default Gallery;
