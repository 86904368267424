import React from 'react';

export default function SystemicsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.94684 6.68176C8.94684 6.73685 8.90212 6.78157 8.84703 6.78157C8.79193 6.78157 8.74721 6.73685 8.74721 6.68176V5.88325C8.74721 5.82816 8.79193 5.78344 8.84703 5.78344C8.90212 5.78344 8.94684 5.82816 8.94684 5.88325V6.68176ZM8.14858 6.68176C8.14858 6.73685 8.10386 6.78157 8.04876 6.78157C7.99367 6.78157 7.94895 6.73685 7.94895 6.68176V5.88325C7.94895 5.82816 7.99367 5.78344 8.04876 5.78344C8.10386 5.78344 8.14858 5.82816 8.14858 5.88325V6.68176ZM7.35032 6.68176C7.35032 6.73685 7.3056 6.78157 7.2505 6.78157C7.19541 6.78157 7.15069 6.73685 7.15069 6.68176V5.88325C7.15069 5.82816 7.19541 5.78344 7.2505 5.78344C7.3056 5.78344 7.35032 5.82816 7.35032 5.88325V6.68176ZM13.5384 5.48386H6.55147C6.49598 5.48386 6.45166 5.52857 6.45166 5.58367V6.98105C6.45166 7.03615 6.49598 7.08086 6.55147 7.08086H13.5384C13.5935 7.08086 13.6382 7.03615 13.6382 6.98105V5.58367C13.6382 5.52857 13.5935 5.48386 13.5384 5.48386Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.121 9.82605C10.121 9.6388 9.98082 9.51263 9.77281 9.51263H9.25378V10.1395H9.77281C9.98082 10.1395 10.121 10.0133 10.121 9.82605Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75952 9.12753C8.75952 9.09998 8.78188 9.07762 8.80943 9.07762H9.84629C10.313 9.07762 10.6264 9.37866 10.6264 9.82662C10.6264 10.1939 10.4316 10.4367 10.0635 10.5293L10.4523 11.1086L10.7821 10.6119C10.7917 10.598 10.8073 10.5896 10.8241 10.5896H11.1966C11.2149 10.5896 11.2317 10.6 11.2405 10.6163C11.2489 10.6327 11.2477 10.6527 11.2373 10.6678L10.7278 11.4081L11.2756 12.1934C11.286 12.2086 11.2876 12.2285 11.2788 12.2449C11.27 12.2613 11.2532 12.2716 11.2345 12.2716H10.852C10.8356 12.2716 10.8201 12.2633 10.8109 12.2497L10.4472 11.7115L10.081 12.2497C10.0715 12.2633 10.0559 12.2716 10.0395 12.2716H9.66982C9.65105 12.2716 9.63428 12.2613 9.6259 12.2449C9.61711 12.2285 9.61831 12.2086 9.62909 12.1934L10.1653 11.4152L9.58917 10.5752H9.2538V11.3506C9.2538 11.3781 9.23184 11.4005 9.20389 11.4005H8.80943C8.78188 11.4005 8.75952 11.3781 8.75952 11.3506V9.12753ZM7.05054 14.4674C7.05054 14.5776 7.13957 14.667 7.25016 14.667H12.8397C12.9495 14.667 13.0393 14.5776 13.0393 14.4674V7.48047H7.05054V14.4674Z"
        fill="white"
      />
    </svg>
  );
}
