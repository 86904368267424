import React from 'react';

export default function OverTheCounterIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1209 10.4534C9.28587 10.4534 8.60887 9.77638 8.60887 8.94139C8.60887 8.10639 9.28587 7.42939 10.1209 7.42939C10.9559 7.42939 11.6329 8.10639 11.6329 8.94139C11.6329 9.77638 10.9559 10.4534 10.1209 10.4534ZM13.0503 5.16129H7.1913C7.13914 5.16129 7.0968 5.20362 7.0968 5.25578V5.91728L7.8305 12.1543H12.4111L13.1448 5.91728V5.25578C13.1448 5.20362 13.1024 5.16129 13.0503 5.16129Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6878 14.2333C10.6878 13.9207 10.4334 13.6664 10.1208 13.6664C9.80817 13.6664 9.55378 13.9207 9.55378 14.2333C9.55378 14.3377 9.4691 14.4223 9.36478 14.4223H7.85278V14.8948C7.85278 14.947 7.89512 14.9893 7.94728 14.9893H12.2943C12.3464 14.9893 12.3888 14.947 12.3888 14.8948V14.4223H10.8768C10.7724 14.4223 10.6878 14.3377 10.6878 14.2333Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.85278 14.0441H9.19468C9.28275 13.6131 9.66415 13.2881 10.1208 13.2881C10.5774 13.2881 10.9588 13.6131 11.0469 14.0441H12.3888V12.5321H7.85278V14.0441Z"
        fill="white"
      />
    </svg>
  );
}
