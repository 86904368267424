import React from 'react';

export default function Humidity() {
  return (
    <svg
      className="icon icon-humidity"
      width="22px"
      height="25px"
      viewBox="0 0 22 25">
      <g className="svg-group">
        <path d="M1,18 C1.561,18 1.801,18.217 2.291,18.707 C2.836,19.252 3.583,20 4.997,20 C6.41,20 7.157,19.252 7.702,18.707 C8.192,18.217 8.432,18 8.993,18 C9.555,18 9.795,18.217 10.285,18.707 C10.831,19.252 11.577,20 12.991,20 C14.406,20 15.154,19.253 15.7,18.708 C16.191,18.217 16.432,18 16.996,18 C17.559,18 17.8,18.217 18.292,18.708 C18.837,19.253 19.585,20 21,20 C21.552,20 22,19.552 22,19 C22,18.448 21.552,18 21,18 C20.437,18 20.196,17.783 19.705,17.292 C19.159,16.747 18.411,16 16.996,16 C15.581,16 14.833,16.747 14.287,17.292 C13.796,17.783 13.555,18 12.991,18 C12.43,18 12.189,17.783 11.7,17.293 C11.154,16.748 10.407,16 8.993,16 C7.58,16 6.833,16.748 6.287,17.293 C5.797,17.783 5.558,18 4.997,18 C4.436,18 4.195,17.783 3.706,17.293 C3.16,16.748 2.414,16 1,16 C0.448,16 0,16.448 0,17 C0,17.552 0.448,18 1,18" />
        <path d="M21,23 C20.437,23 20.196,22.783 19.705,22.292 C19.159,21.747 18.411,21 16.996,21 C15.581,21 14.833,21.747 14.287,22.292 C13.796,22.783 13.555,23 12.991,23 C12.43,23 12.189,22.783 11.7,22.293 C11.154,21.748 10.407,21 8.993,21 C7.58,21 6.833,21.748 6.287,22.293 C5.797,22.783 5.558,23 4.997,23 C4.436,23 4.195,22.783 3.706,22.293 C3.16,21.748 2.414,21 1,21 C0.448,21 0,21.448 0,22 C0,22.552 0.448,23 1,23 C1.561,23 1.801,23.217 2.291,23.707 C2.836,24.252 3.583,25 4.997,25 C6.41,25 7.157,24.252 7.702,23.707 C8.192,23.217 8.432,23 8.993,23 C9.555,23 9.795,23.217 10.285,23.707 C10.831,24.252 11.577,25 12.991,25 C14.406,25 15.154,24.253 15.7,23.708 C16.191,23.217 16.432,23 16.996,23 C17.559,23 17.8,23.217 18.292,23.708 C18.837,24.253 19.585,25 21,25 C21.552,25 22,24.552 22,24 C22,23.448 21.552,23 21,23" />
        <path d="M8.2861,7.0605 C8.3591,6.7935 8.6361,6.6395 8.9001,6.7095 C9.1671,6.7835 9.3241,7.0585 9.2511,7.3245 C9.0841,7.9325 9.0001,8.5295 9.0001,9.1005 C9.0001,9.7225 9.3671,11.4995 11.0001,11.4995 C11.2761,11.4995 11.5001,11.7235 11.5001,11.9995 C11.5001,12.2765 11.2761,12.4995 11.0001,12.4995 C8.8141,12.4995 8.0001,10.4145 8.0001,9.1005 C8.0001,8.4405 8.0961,7.7545 8.2861,7.0605 M11.0001,13.9995 C13.9421,13.9995 15.5001,11.4195 15.5001,9.1005 C15.5001,4.0835 11.0001,-0.0005 11.0001,-0.0005 C11.0001,-0.0005 6.5001,4.0835 6.5001,9.1005 C6.5001,11.4195 8.0571,13.9995 11.0001,13.9995" />
      </g>
    </svg>
  );
}
