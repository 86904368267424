// src/Sidebar.js
import React, { useState, useEffect, useRef } from 'react';
import { UilMultiply } from '@iconscout/react-unicons';

const Sidebar = ({ header, children, isOpen, onClose, fromFlare = false }) => {
  const [scrollFixed, setScrollFixed] = useState(false);
  const wrapperRef = useRef(null);

  const handleScroll = () => {
    const wrapper = document.querySelector('.wrapper');
    if (wrapper.scrollTop > 50) {
      setScrollFixed(true);
    } else {
      setScrollFixed(false);
    }
  };

  useEffect(() => {
    const wrapper = document.querySelector('.wrapper');
    wrapper.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();

        // if (localStorage.getItem('firstLoginFlareTracker') === true) {
        //   localStorage.setItem('firstLoginFlareTracker', false);
        // }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={`${fromFlare ? 'sidebar-flare' : 'sidebar'}  ${
        isOpen ? 'open' : ''
      } ${scrollFixed ? 'scroll-fixed' : ''} `}
      ref={wrapperRef}>
      <header className="sidebar-header">
        <h4>{header || ''}</h4>
        <UilMultiply
          onClick={onClose}
          size="18"
          className="sidebar-close"></UilMultiply>
      </header>
      <nav className="sidebar-nav">{children}</nav>
    </div>
  );
};

export default Sidebar;
