import gql from 'graphql-tag';

export const updateTreatment = gql`
  mutation updateTreatment(
    $payload: TreatmentOptionPayload!
    $shouldDelete: Boolean
  ) {
    updateTreatment(payload: $payload, shouldDelete: $shouldDelete)
  }
`;

export const updateTreatmentClass = gql`
  mutation updateTreatmentClass($payload: TreatmentClassPayload!) {
    updateTreatmentClass(payload: $payload)
  }
`;

export const updateTreatmentGroup = gql`
  mutation updateTreatmentGroup($payload: TreatmentGroupPayload!) {
    updateTreatmentGroup(payload: $payload)
  }
`;

export const updateTreatmentMetadata = gql`
  mutation updateTreatmentMetadata($payload: TreatmentMetadataPayload!) {
    updateTreatmentMetadata(payload: $payload)
  }
`;
