import React from 'react';

export default function Location() {
  return (
    <svg
      className="icon icon-location"
      width="14px"
      height="20px"
      viewBox="0 0 14 20">
      <g className="svg-group" transform="translate(-3.000000, 0.000000)">
        <path d="M10,9.62478971 C8.550125,9.62478971 7.375,8.44966471 7.375,6.99978971 C7.375,5.54991471 8.550125,4.37478971 10,4.37478971 C11.449875,4.37478971 12.625,5.54991471 12.625,6.99978971 C12.625,8.44966471 11.449875,9.62478971 10,9.62478971 M10.373625,0.00941470884 C6.427375,-0.197960291 3,3.04828971 3,6.99978971 C3,12.7056647 8.865125,18.2295397 9.85825,19.1237897 C9.941375,19.1981647 10.0595,19.1981647 10.142625,19.1229147 C11.1375,18.2269147 17,12.7056647 17,6.99978971 C17,3.25916471 14.06525,0.203664709 10.373625,0.00941470884" />
      </g>
    </svg>
  );
}
