import React from 'react';

export default function StressTrendsIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#B33D26" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4296 9.35637C14.4031 9.33357 14.3634 9.3341 14.3374 9.35743L10.817 12.6C10.7964 12.6191 10.7889 12.6477 10.7985 12.6742C10.8075 12.7007 10.8319 12.7182 10.86 12.7203L12.3731 12.811L10.5307 15.4651C10.5106 15.4942 10.5159 15.5335 10.5424 15.5568C10.5551 15.5684 10.5716 15.5737 10.588 15.5737C10.6044 15.5737 10.6214 15.5674 10.6346 15.5552L14.1545 12.3132C14.1752 12.2946 14.1826 12.2654 14.173 12.2389C14.164 12.213 14.1396 12.1949 14.1121 12.1933L12.599 12.1027L14.4408 9.44809C14.4615 9.41946 14.4562 9.3797 14.4296 9.35637Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#B33D26" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6233 6.87803C13.6331 6.86795 13.6457 6.86267 13.6546 6.85115L14.6846 5.53595C14.7191 5.49275 14.7205 5.42939 14.6846 5.38427C14.644 5.33243 14.5704 5.32427 14.52 5.36603L13.2429 6.42683C13.2321 6.43547 13.2228 6.44651 13.213 6.45707C13.3823 6.55979 13.5226 6.70379 13.6233 6.87803Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9121 6.32879C12.9125 6.31487 12.9172 6.30191 12.9158 6.28751L12.741 4.60751C12.7349 4.55231 12.6925 4.50671 12.6365 4.50047C12.5722 4.49327 12.5143 4.54127 12.5078 4.60751L12.333 6.28751C12.3316 6.30095 12.3325 6.31583 12.333 6.32975C12.4267 6.30527 12.5232 6.28751 12.6244 6.28751C12.7242 6.28751 12.8198 6.30431 12.9121 6.32879Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5939 6.85112C11.6027 6.86168 11.613 6.87128 11.6232 6.88136C11.723 6.70712 11.8629 6.56264 12.0322 6.45944C12.0228 6.44888 12.0168 6.43592 12.0061 6.4268L10.7284 5.366C10.6865 5.33096 10.6249 5.33 10.5811 5.366C10.5312 5.40824 10.5228 5.48408 10.5634 5.53592L11.5939 6.85112Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.422 7.36753L13.79 7.18753C13.777 7.18609 13.7625 7.18705 13.7485 7.18753C13.7732 7.28401 13.79 7.38337 13.79 7.48753C13.79 7.59025 13.7737 7.68865 13.7499 7.78369C13.7635 7.78417 13.776 7.78897 13.79 7.78753L15.422 7.60753C15.4757 7.60177 15.52 7.55809 15.526 7.50049C15.533 7.43425 15.4864 7.37473 15.422 7.36753Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6548 8.12399C13.6459 8.11343 13.6352 8.10335 13.6254 8.09375C13.5256 8.26799 13.3853 8.41247 13.2165 8.51567C13.2258 8.52623 13.2314 8.53919 13.2426 8.54831L14.5198 9.60863C14.5622 9.64367 14.6237 9.64511 14.6676 9.60863C14.7175 9.56687 14.7259 9.49103 14.6848 9.43919L13.6548 8.12399Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29661 9.66257C7.07541 9.66257 6.08176 10.6854 6.08176 11.9426C6.08176 12.1413 5.92508 12.3026 5.73204 12.3026C5.539 12.3026 5.38233 12.1413 5.38233 11.9426C5.38233 10.2885 6.68979 8.94257 8.29661 8.94257C8.48966 8.94257 8.64633 9.10385 8.64633 9.30257C8.64633 9.50129 8.48966 9.66257 8.29661 9.66257ZM12.3771 7.23281L11.4944 8.14145C11.3564 8.03105 11.2151 7.93793 11.0794 7.87793C10.8537 7.77857 10.6891 7.78241 10.6047 7.86977L10.1515 8.33633C8.65379 7.51937 6.75461 7.75937 5.49377 9.05729C3.94617 10.6509 3.94617 13.2342 5.49377 14.8274C7.0423 16.421 9.55139 16.421 11.0995 14.8274C12.3603 13.5294 12.5934 11.5749 11.7998 10.0331L12.253 9.56657C12.3374 9.47969 12.3416 9.31025 12.2451 9.07793C12.1868 8.93825 12.0959 8.79233 11.9891 8.65025L12.8718 7.74257C13.008 7.60193 13.008 7.37345 12.8718 7.23281C12.7352 7.09265 12.5132 7.09265 12.3771 7.23281Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
