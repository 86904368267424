import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { withTrendsView } from '../../apollo/stores/trendsView';
import config from '../../config';
import { AllUserData } from '../../libs/graphql/queries/data';
import { Me } from '../../libs/graphql/queries/me';
import LineChart from './LineChart';

const TrendsChart = ({ onLoad, trendsView }) => {
  const [dataset, setDataset] = useState([]);

  const { loading: loadingMe, error: errorMe, data: meData } = useQuery(Me, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading: loadingAllUserData, error: errorAllUserData, data: allUserData } = useQuery(AllUserData, {
    variables: {
      id: meData?.me?.id,
      startDate: trendsView ? trendsView.startDate : config.trendsConfig.startDate,
      endDate: trendsView
        ? moment(trendsView.endDate).add(1, 'days').format('YYYY-MM-DD')
        : moment(config.trendsConfig.endDate).add(1, 'days').format('YYYY-MM-DD'),
    },
    skip: !meData?.me?.id, // Skip the query until meData is available
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loadingMe && !loadingAllUserData && allUserData && trendsView) {
      updateChartState();
      // Llamar a la función de callback una vez que los datos estén cargados y el componente esté listo.
      onLoad();
    }    
  }, [loadingMe, loadingAllUserData, allUserData, trendsView, onLoad]);

  const updateChartState = () => {
    const dailyUserData = (key) => {
      if (!allUserData) {
        return [];
      }
      return allUserData.allUserData.map((userData) => {
        let value = null;
        if (userData[key]) {
          if (!Number.isNaN(+userData[key].level)) value = userData[key].level;
          else if (!Number.isNaN(+userData[key].score)) value = userData[key].score;
        }
        return {
          date: moment(userData.tracker_date).format('YYYY-MM-DD'),
          value,
        };
      });
    };

    const dataset = trendsView.selectedIndicators.map((filterName) => {
      const dailyData = dailyUserData(filterName + 'Data');
      const setup = { key: filterName, values: dailyData };
      return setup;
    });

    setDataset(dataset);
  };

  const formattedDates = () => {
    let startDate, endDate;
    const sameYear =
      moment(trendsView.startDate).year() ===
      moment(trendsView.endDate).year();
    const dateFormat = {
      start: trendsView.value === 'week' ? 'MMM D' : 'MMM',
      newYear: trendsView.value === 'week' ? 'MMM D, YYYY' : 'MMM, YYYY',
      end: trendsView.value === 'week' ? 'MMM D, YYYY' : 'MMM, YYYY',
    };
    if (sameYear) {
      startDate = moment(trendsView.startDate).format(dateFormat.start);
    } else {
      startDate = moment(trendsView.startDate).format(dateFormat.newYear);
    }
    endDate = moment(trendsView.endDate).format(dateFormat.end);
    return `${startDate} – ${endDate}`;
  };

  if (loadingMe || loadingAllUserData) {
    return <div>Loading...</div>;
  }

  if (errorMe || errorAllUserData) {
    return <div>Error loading data...</div>;
  }

  return (
    <React.Fragment>
      <div className="trendschart-date-view">
        <h1 className="title">{formattedDates()}</h1>
      </div>
      <LineChart
        data={dataset}
        startDate={trendsView.startDate}
        endDate={trendsView.endDate}
      />
    </React.Fragment>
  );
};

export default compose(
  withTrendsView,
)(TrendsChart);
