import React from 'react';
import config from '../../config';

import { TriggersIcon } from '../../assets/icons/trackers';
import {
  Allergens,
  Chemicals,
  Irritants,
  Metals,
  Other,
} from '../../assets/icons';

const RenderTriggers = ({ label, type }) => {
  let TriggerIcon;
  switch (type.toLowerCase()) {
    case 'chemicals':
      TriggerIcon = <Chemicals />;
      break;
    case 'irritants':
      TriggerIcon = <Irritants />;
      break;
    case 'environmental allergens':
      TriggerIcon = <Allergens />;
      break;
    case 'metals':
      TriggerIcon = <Metals />;
      break;
    default:
      TriggerIcon = <Other />;
  }
  return (
    <li>
      <span className="trends-summary-text__14p600">{label}</span>
    </li>
  );
};

const TrendsSummaryTriggers = ({ triggerData }) => {
  let TriggerArray = triggerData
    ? triggerData.data.map((t, i) => {
        return <RenderTriggers label={t.trigger} type={t.type} key={i} />;
      })
    : [];

  return (
    <div className="trends-summary-triggers">
      <ul
        className={`trends-summary-triggers-list ${config.trackerTheme.trigger}`}>
        {TriggerArray}
      </ul>
    </div>
  );
};

export default TrendsSummaryTriggers;
