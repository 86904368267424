import gql from 'graphql-tag';

export const scaleTracker = gql`
  mutation Tracker(
    $id: ID!
    $tracker: TRACKER_NAMES
    $level: Int
    $client_date: String!
  ) {
    trackerData(
      id: $id
      tracker: $tracker
      level: $level
      client_date: $client_date
    )
  }
`;

export const treatmentTracker = gql`
  mutation TreatmentTracker(
    $id: ID
    $notes: String!
    $client_date: String!
    $data: [TreatmentPayload]
  ) {
    treatmentTrackerData(
      id: $id
      notes: $notes
      client_date: $client_date
      data: $data
    )
  }
`;

export const triggerTracker = gql`
  mutation TriggerTracker(
    $id: ID
    $client_date: String
    $data: [TriggerPayload]
  ) {
    triggerTrackerData(id: $id, client_date: $client_date, data: $data)
  }
`;

export const skinTracker = gql`
  mutation SkinTracker($id: ID, $client_date: String, $data: SkinPayload) {
    skinTrackerData(id: $id, client_date: $client_date, data: $data)
  }
`;

export const dietTracker = gql`
  mutation DietTracker(
    $id: ID
    $client_date: String
    $foods: [String]
    $custom_foods: [String]
    $cups_water: Int
  ) {
    dietTrackerData(
      id: $id
      client_date: $client_date
      foods: $foods
      custom_foods: $custom_foods
      cups_water: $cups_water
    )
  }
`;

export const flareTracker = gql`
  mutation FlareTracker($userid: ID!, $flare: Boolean!, $clientDate: String!) {
    flareData(userid: $userid, flare: $flare, client_date: $clientDate) {
      flare_day
      days_streak
      id
    }
  }
`;
