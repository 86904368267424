import React from 'react';

export default function PrescriptionTopicalIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.97769 14.1687C9.0698 13.7161 9.4708 13.3747 9.95041 13.3747C10.4296 13.3747 10.8306 13.7161 10.9227 14.1687H12.3326V12.5806H7.56824V14.1687H8.97769Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7441 14.5656C10.6345 14.5656 10.5456 14.4767 10.5456 14.3671C10.5456 14.0387 10.2784 13.7715 9.95005 13.7715C9.6217 13.7715 9.3545 14.0387 9.3545 14.3671C9.3545 14.4767 9.26557 14.5656 9.15599 14.5656H7.56787V15.0619C7.56787 15.1167 7.61234 15.1611 7.66713 15.1611H12.233C12.2878 15.1611 12.3322 15.1167 12.3322 15.0619V14.5656H10.7441Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.6802 7.65399H9.16406V8.27693H9.6802C9.88705 8.27693 10.0264 8.15187 10.0264 7.96566C10.0264 7.77906 9.88705 7.65399 9.6802 7.65399"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.133 10.3973H10.7527C10.736 10.3973 10.7205 10.389 10.7114 10.3755L10.3501 9.84027L9.98601 10.3755C9.97688 10.389 9.9614 10.3973 9.94512 10.3973H9.57747C9.55881 10.3973 9.54214 10.387 9.5334 10.3707C9.52467 10.3544 9.52586 10.3346 9.53658 10.3195L10.0698 9.54568L9.49727 8.70993H9.16377V9.48136C9.16377 9.50875 9.14153 9.53099 9.11414 9.53099H8.72148C8.69408 9.53099 8.67185 9.50875 8.67185 9.48136V7.2707C8.67185 7.24331 8.69408 7.22107 8.72148 7.22107H9.75256C10.2167 7.22107 10.5288 7.52043 10.5288 7.9655C10.5288 8.33077 10.335 8.57256 9.96894 8.66427L10.3553 9.24036L10.6836 8.74646C10.6927 8.73296 10.7082 8.72462 10.7249 8.72462H11.0957C11.114 8.72462 11.131 8.73494 11.1398 8.75122C11.1481 8.7675 11.1469 8.78696 11.1366 8.80204L10.6292 9.53813L11.1739 10.3195C11.1842 10.3346 11.1854 10.3544 11.1771 10.3707C11.1684 10.387 11.1513 10.3973 11.133 10.3973M13.0274 4.83868H6.87343C6.81824 4.83868 6.77417 4.88275 6.77417 4.93794V5.63274L7.5448 12.1837H12.356L13.1266 5.63274V4.93794C13.1266 4.88275 13.0822 4.83868 13.0274 4.83868"
        fill="white"
      />
    </svg>
  );
}
