import React from 'react';

export default function SkinTrendsIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4296 9.35637C14.4031 9.33357 14.3634 9.3341 14.3374 9.35743L10.817 12.6C10.7964 12.6191 10.7889 12.6477 10.7985 12.6742C10.8075 12.7007 10.8319 12.7182 10.86 12.7203L12.3731 12.811L10.5307 15.4651C10.5106 15.4942 10.5159 15.5335 10.5424 15.5568C10.5551 15.5684 10.5716 15.5737 10.588 15.5737C10.6044 15.5737 10.6214 15.5674 10.6346 15.5552L14.1545 12.3132C14.1752 12.2946 14.1826 12.2654 14.173 12.2389C14.164 12.213 14.1396 12.1949 14.1121 12.1933L12.599 12.1027L14.4408 9.44809C14.4615 9.41946 14.4562 9.3797 14.4296 9.35637Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7281 8.00296C14.465 7.92065 14.1778 8.04255 14.0778 8.27958L12.8364 11.2131C12.6743 11.0602 12.4729 10.9203 12.2344 10.793L13.1837 6.8614C13.2433 6.61447 13.0778 6.36843 12.8088 6.30591C12.5408 6.24339 12.2659 6.38687 12.1891 6.62976L11.0073 10.3482C10.8831 10.318 10.759 10.2879 10.623 10.2614C10.5132 10.2402 10.4117 10.2263 10.3078 10.2101L11.0009 6.10171C11.0428 5.85208 10.86 5.61639 10.5876 5.57051C10.3152 5.52418 10.0521 5.6834 9.99297 5.93079L8.98358 10.1431C8.75501 10.1624 8.54663 10.1997 8.35795 10.2542L8.55697 6.62751C8.57076 6.37428 8.36337 6.15658 8.08701 6.13364C7.81015 6.11115 7.56384 6.29242 7.52788 6.5434L6.80076 11.7034C6.79928 11.7074 6.7978 11.7119 6.79583 11.716L4.49971 9.96449C4.28098 9.79807 3.96077 9.80707 3.75387 9.98563C3.54845 10.1628 3.51938 10.4525 3.68638 10.6608L7.04165 14.8465C7.5328 15.5018 8.30573 15.998 9.24517 16.1806C11.124 16.5449 12.9556 15.5158 13.3359 13.8813L15.0567 8.58409C15.1355 8.34121 14.9907 8.08483 14.7281 8.00296Z"
        fill="white"
      />
    </svg>
  );
}
