import { flowRight as compose } from 'lodash';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { withRegistration } from '../apollo/stores/registration';
import AccountSetup from '../components/registration/AccountSetup';
import Confirmation from '../components/registration/Confirmation';
import EmailVerification from '../components/registration/EmailVerification';
import SignUp from '../components/registration/SignUp';
import mixpanel from 'mixpanel-browser';

const UserRegistration = props => {
  let loggedIn = !!props.me.id;
  let profile = !!props.me.profileFlag;
  return (
    <div className="page page-registration page-reset-password page-login page__cobalt">
      <Switch>
        <Route
          path="/signup/registration"
          render={() => {
            return loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <SignUp {...props} />
            );
          }}
        />
        <Route
          path="/signup/confirmation"
          render={props =>
            loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <Confirmation {...props} />
            )
          }
        />
        <Route
          path="/signup/emailVerification/:status"
          render={props =>
            loggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              <EmailVerification {...props} />
            )
          }
        />
        <Route
          path="/signup/profile"
          render={() =>
            loggedIn ? (
              profile ? (
                <Redirect to="/dashboard" />
              ) : (
                <AccountSetup {...props} />
              )
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      </Switch>
    </div>
  );
};

export default compose(withRouter, withRegistration)(UserRegistration);
