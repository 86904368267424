import React, { Fragment } from 'react';
//This component renders the read view for tracker list items
//an image and label are required
const TrackerListItem = ({ image, label, children }) => {
  return (
    <Fragment>
      <span className="tracker-list-item tracker-list-item__flex-row">
        <div className="tracker-list-item-content-wrapper tracker-list-item__opaque">
          <div className="tracker-list-image">{image}</div>
          <div className="tracker-list-item-description-wrapper">
            <p className="tracker-list-item-description text__left">{label}</p>
          </div>
          {children ? children : null}
        </div>
      </span>
    </Fragment>
  );
};

export default TrackerListItem;
