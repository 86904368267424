import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import CreateClientStore from './CreateClientStore';

//export an Apollo Client to use in client and one to use in server for SSR
export const createAppClient = () => {
  // eslint-disable-line
  let cache = new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'triggerSelection':
          return `triggerSelection:${object.typeid}${object.trigger_id}`; // use `key` as the primary key
        case 'typeSelection':
          return `typeSelection:${object.typeid}`;
        case 'Data':
          return `dailyUserData:${object.id}`;
        case 'Appointment':
          return `appt:${object.id}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
  });

  const uploadLink = createUploadLink({
    uri: '/graphql',
  });

  const httpLink = new HttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  });

  const stateLink = CreateClientStore(cache);

  const client = new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([stateLink, uploadLink, httpLink]),
    connectToDevTools: true,
    cache,
  });

  client.onResetStore(stateLink.writeDefaults);

  return client;
};
