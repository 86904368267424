import React from 'react';

export const TreatmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__treatment">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__treatment tracker-icon-image"
        transform="translate(8.5, 8) scale(.9)">
        <path d="M22.8936268,17.9683929 L19.7571268,17.575875 L19.3646089,14.439375 C19.3582339,14.388375 19.3181625,14.3464821 19.2653411,14.3401071 C19.2025018,14.3319107 19.1451268,14.3765357 19.1369304,14.439375 L18.7444125,17.575875 L15.6079125,17.9683929 C15.5569125,17.9747679 15.5150196,18.0148393 15.5086446,18.0676607 C15.5004482,18.1305 15.5450732,18.187875 15.6079125,18.1960714 L18.7444125,18.5885893 L19.1369304,21.7250893 C19.1433054,21.7760893 19.1833768,21.8179821 19.2361982,21.8243571 C19.2990375,21.8325536 19.3564125,21.7879286 19.3646089,21.7250893 L19.7571268,18.5885893 L22.8936268,18.1960714 C22.9446268,18.1896964 22.9865196,18.149625 22.9928946,18.0968036 C23.0010911,18.0339643 22.9564661,17.9765893 22.8936268,17.9683929" />
        <path d="M15.8355911,20.9282143 L14.3174304,20.7387857 L14.1280018,19.220625 C14.1216268,19.169625 14.0815554,19.1277321 14.0287339,19.1213571 C13.9658946,19.1131607 13.9085196,19.1577857 13.9003232,19.220625 L13.7108946,20.7387857 L12.1927339,20.9282143 C12.1417339,20.9345893 12.0998411,20.9746607 12.0934661,21.0274821 C12.0852696,21.0903214 12.1298946,21.1476964 12.1927339,21.1558929 L13.7108946,21.3453214 L13.9003232,22.8634821 C13.9066982,22.9144821 13.9467696,22.956375 13.9995911,22.96275 C14.0624304,22.9709464 14.1198054,22.9263214 14.1280018,22.8634821 L14.3174304,21.3453214 L15.8355911,21.1558929 C15.8865911,21.1495179 15.9284839,21.1094464 15.9348589,21.056625 C15.9430554,20.9937857 15.8984304,20.9364107 15.8355911,20.9282143" />
        <path d="M14.8946411,6.27090536 C14.9210518,6.28729821 14.9538375,6.29458393 14.9838911,6.30824464 C15.0758732,5.77820893 15.2826054,5.27822679 15.5913375,4.83835179 C15.5603732,4.82742321 15.5366946,4.80647679 15.5039089,4.79828036 L11.9211589,3.93036964 C11.8264446,3.90760179 11.7235339,3.9467625 11.6698018,4.03419107 C11.6042304,4.14256607 11.6379268,4.2846375 11.7463018,4.35111964 L14.8946411,6.27090536 Z" />
        <path d="M0.581126786,8.064375 L5.2357875,8.64632143 L5.81773393,13.3009821 C5.83048393,13.4029821 5.91062679,13.4867679 6.01718036,13.5004286 C6.14285893,13.5159107 6.25760893,13.4266607 6.27309107,13.3009821 L6.8550375,8.64632143 L11.5096982,8.064375 C11.6116982,8.051625 11.6954839,7.97148214 11.7091446,7.86492857 C11.7246268,7.73925 11.6353768,7.6245 11.5096982,7.60901786 L6.8550375,7.02707143 L6.27309107,2.37241071 C6.26034107,2.27041071 6.18019821,2.186625 6.07364464,2.17296429 C5.94796607,2.15748214 5.83321607,2.24673214 5.81773393,2.37241071 L5.2357875,7.02707143 L0.581126786,7.60901786 C0.479126786,7.62176786 0.395341071,7.70191071 0.381680357,7.80846429 C0.366198214,7.93414286 0.455448214,8.04889286 0.581126786,8.064375" />
        <path d="M12.9019982,12.5921732 C12.4921768,12.1823518 12.1852661,11.7834589 11.9475696,11.4009589 L0.544516071,22.3741554 L0.541783929,22.3768875 L0.5335875,22.3841732 L0.534498214,22.3850839 C0.369658929,22.5499232 0.233051786,22.7466375 0.139248214,22.9752268 C-0.245983929,23.9041554 0.1957125,24.9696911 1.12464107,25.3549232 C1.82498036,25.6454411 2.60273036,25.4642089 3.1090875,24.9596732 L3.10999821,24.9605839 L14.0941232,13.5447804 C13.7107125,13.3070839 13.3127304,13.0019946 12.9019982,12.5921732" />
        <path d="M17.5987339,5.96281071 L12.9586446,10.4280429 C13.1498946,10.7932393 13.4376804,11.1966857 13.8675375,11.6265429 C14.2973946,12.0554893 14.7008411,12.3441857 15.0660375,12.534525 L19.5312696,7.89534643 L19.5303589,7.89534643 L19.5312696,7.89443571 C20.0649482,7.36166786 20.0649482,6.49648929 19.5312696,5.96281071 C18.9975911,5.42913214 18.1324125,5.42913214 17.5987339,5.96281071 Z" />
        <path d="M16.4705411,3.95459464 C16.6863804,3.80250536 16.9177018,3.66863036 17.1708804,3.5629875 C17.4231482,3.45916607 17.6808804,3.38995179 17.9386125,3.34532679 C17.9258625,3.31618393 17.9240411,3.28613036 17.9076482,3.25789821 L15.9869518,0.110469643 C15.9368625,0.0275946429 15.8357732,-0.0179410714 15.7365054,0.00664821429 C15.6126482,0.0367017857 15.5370589,0.160558929 15.5662018,0.284416071 L16.4341125,3.86807679 C16.4423089,3.89721964 16.4596125,3.92545179 16.4705411,3.95459464" />
        <path d="M20.6950714,3.86835 L21.5638929,0.284689286 C21.5866607,0.189975 21.5475,0.0870642857 21.4591607,0.0342428571 C21.3507857,-0.0322392857 21.2087143,0.00145714286 21.1431429,0.110742857 L19.2224464,3.25817143 C19.2069643,3.28458214 19.1987679,3.31827857 19.1860179,3.34742143 C19.7151429,3.43940357 20.215125,3.64613571 20.655,3.95486786 C20.6659286,3.92481429 20.6877857,3.900225 20.6950714,3.86835" />
        <path d="M21.9304554,5.53450179 C22.0351875,5.78676964 22.1034911,6.04450179 22.1490268,6.30223393 C22.1772589,6.28948393 22.2082232,6.2876625 22.2355446,6.27126964 L25.3838839,4.35057321 C25.4667589,4.30048393 25.5113839,4.19939464 25.4867946,4.10012679 C25.4576518,3.97626964 25.3328839,3.90068036 25.2090268,3.92982321 L21.6262768,4.79864464 C21.5962232,4.80593036 21.5689018,4.82323393 21.5388482,4.8341625 C21.6909375,5.05000179 21.8257232,5.28132321 21.9304554,5.53450179" />
        <path d="M19.9591232,10.2944411 C19.7068554,10.3991732 19.4491232,10.4665661 19.1913911,10.5121018 C19.2041411,10.5412446 19.2059625,10.5722089 19.2223554,10.5986196 L21.1430518,13.7469589 C21.1931411,13.8298339 21.2942304,13.8753696 21.3934982,13.8507804 C21.5173554,13.8207268 21.5929446,13.6959589 21.5638018,13.5730125 L20.6949804,9.98935179 C20.6876946,9.96020893 20.6703911,9.93197679 20.6594625,9.90283393 C20.4436232,10.0549232 20.2123018,10.1887982 19.9591232,10.2944411" />
        <path d="M25.3834286,9.50649107 L22.2350893,7.58670536 C22.2086786,7.5703125 22.1758929,7.56302679 22.1458393,7.54936607 C22.0538571,8.07849107 21.847125,8.57847321 21.5393036,9.01834821 C21.5693571,9.0301875 21.5930357,9.05113393 21.6258214,9.05933036 L25.2094821,9.92724107 C25.3041964,9.95000893 25.4061964,9.91084821 25.4599286,9.82341964 C25.5264107,9.71504464 25.4918036,9.57297321 25.3834286,9.50649107" />
      </g>
    </svg>
  );
};
