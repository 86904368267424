import React, { Component } from 'react';

class RadioButtonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: undefined,
    };
  }

  render() {
    let radioButtonOptions = this.props.options.map(val => {
      return this._buildRadioInput(val);
    });
    return <form className="radio-wrapper">{radioButtonOptions}</form>;
  }

  _buildRadioInput(option) {
    let identifier = 'option-' + option.value + this.props.id;
    return (
      <div className="radio" key={identifier}>
        <input
          type="radio"
          value={option.value}
          id={identifier}
          onChange={this.handleOptionChange.bind(this, this.props.id)}
          checked={this.props.selected === option.value}
        />
        <label htmlFor={identifier}>{option.label}</label>
      </div>
    );
  }

  handleOptionChange(optionid, e) {
    this.props.changeHandler(optionid, e);
  }
}

export default RadioButtonForm;
