import React, { Fragment } from 'react';
import Trigger from './Trigger';

const TriggerReadView = props => {
  return (
    <Fragment>
      <h3 className="headers headers__neutral-black headers__centered">
        My Exposure:
      </h3>
      {buildReadTriggers(props.data, props.options)}
    </Fragment>
  );
};

export default TriggerReadView;

const buildReadTriggers = (triggerData = [], triggerOptions) => {
  let triggerComponents = [];
  triggerData.forEach((selection, index) => {
    let { type, trigger: name } = selection;
    if (selection.selected && selection.trigger_selected) {
      triggerComponents.push(
        <Trigger
          key={index}
          type={type}
          placeholder={`Select ${type}`}
          value={name}
          values={triggerOptions}
          write={false}
          changeHandler={() => {}}
        />,
      );
    }
  });
  return triggerComponents;
};
