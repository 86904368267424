import React from 'react';

const relationOptions = {
  self: 'Mine',
  caregiver_child: 'My child',
  caregiver_other: 'Someone else'
};

const genderOptions = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-binary',
  OTHER: 'Other'
};

const raceOptions = {
  white: 'White',
  black: 'Black or African American',
  asian: 'Asian or Asian American',
  native: 'American Indian or Alaskan Native',
  islander: 'Native Hawaiian or other Pacific Islander',
  other: 'Some other race or ethnicity (please specify below)',
  multiple: 'Multiracial (please specify below)',
  none: 'I don’t know/Prefer not to answer'
};

const nea_familiarityOptions = {
  primary_resource: "I'm familiar with NEA and it is my go-to resource",
  familiar: "I'm familiar with NEA and its resources",
  heard_of: "I've heard of NEA, but don't know much about it",
  new: 'I have not heard of NEA until now'
};

const binaryOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const AccountInformation = ({
  eczema_relation,
  gender, 
  birth_year, 
  feelings, 
  nea_familiarity, 
  ethnicity, 
  ethnicity_specified,
  was_referred
}) => {
  let mostlyOnTopOf = "I'm mostly on top of my eczema";
  let needHelp = "I'm trying to manage my eczema, but I need help";
  let newTo = 'I’m new to eczema and need help';
  

  switch (eczema_relation) {
    case 'caregiver_child':
      mostlyOnTopOf = "I'm mostly on top of my child's eczema";
      needHelp = "I'm trying to manage their eczema, but I need help";
      newTo = "I'm new to eczema and need help";
      break;
    case 'caregiver_other':
      mostlyOnTopOf = "I'm mostly on top of their eczema";
      needHelp = "I'm trying to manage their eczema, but I need help";
      newTo = "I'm new to eczema and need help";
      break;
    default:
      break;
  }

  const feelingOptions = {
    on_top_of: mostlyOnTopOf,
    used_to: "I'm used to eczema but could manage it better",
    new_to: newTo,
    trying_to_manage: needHelp,
    nothing_works: 'I have tried everything and nothing works'
  };

  return (
        <>
            <h3 className="title">
              Essential information
            </h3>
            <p className='description'>
              This information cannot be edited.
            </p>
            <form className="form">
              <div className='form-item-row'>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    Whose eczema do you track?<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={relationOptions[eczema_relation]}
                  />
                </div>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    What is your gender?<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={genderOptions[gender]}
                  />
                </div>
              </div>
              <div className='form-item-row'>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    What year were you born?<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={birth_year}
                  />
                </div>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    Your predominantly identify as Hispanic or Latinx<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={ethnicity_specified || raceOptions[ethnicity]}
                  />
                </div>
              </div>
              <hr/>
              <h3 className="title">
                Additional information
              </h3>
              <div className='form-item-row'>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    What best describes your feelings about eczema?<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={feelingOptions[feelings]}
                  />
                </div>
                <div className="form-item">
                  <label className="form-input-label-settings text__left">
                    How familiar are you with the National Eczema Association?<span className="red-asterisk">*</span>
                  </label>
                  <input
                    disabled={true}
                    className="form-input-field"
                    value={nea_familiarityOptions[nea_familiarity]}
                  />
                </div>
              </div>
              <div className="form-item-radio">
                <label className="form-input-label-login text__left">
                  Were you referred to EczemaWise by a healthcare provider?<span className="red-asterisk">*</span>
                </label>
                <div className="multi-option">
                  {binaryOptions.map((option, i) => (
                    <div key={i} className="option">
                      <label className="radio-container">
                        <input
                          disabled={true}
                          name={'referred'}
                          value={option.value}
                          type="radio"
                          id={`referred:${option.value}`}
                          checked={option.value === was_referred}
                        />
                        <span className="radio-checkmark"></span>
                        <span >{option.label}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </form>
        </>
  );
};
