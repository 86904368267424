import React from 'react';
import config from '../../config';
import DragScale from '../shared/DragScale';
import {
  ItchIcon,
  PainIcon,
  StressIcon,
  SleepIcon,
} from '../../assets/icons/trackers';

const TrendsSummaryTrackers = ({ userData }) => {
  const returnValue = val => {
    return userData[val]?.level !== undefined && userData[val]?.level !== null ? userData[val].level : null;
  };

  return (
    <div className="trends-summary-trackers">
      <ol className="trends-summary-datatable">
        <li className={`datatable-item ${config.trackerTheme.itch}`}>
            <span className="label trends-summary-text__14p600">Itch</span>
          {returnValue('itchData') !== null ? (
            <React.Fragment>
              <span className="trends-dots">{returnValue('itchData')}</span>
              <DragScale
                scaleWidth={0.4}
                editingMode={false}
                level={returnValue('itchData')}
                simpleDesign
              />
            </React.Fragment>
          ) : (
            <span className="trends-summary-text__14p600">-- Not set</span>
          )}
        </li>
        <li className={`datatable-item ${config.trackerTheme.pain}`}>

            <span className="label trends-summary-text__14p600">Pain</span>
          {returnValue('painData') !== null ? (
            <React.Fragment>
              <span className="trends-dots">{returnValue('painData')}</span>
              <DragScale
                scaleWidth={0.4}
                editingMode={false}
                level={returnValue('painData')}
                simpleDesign
              />
            </React.Fragment>
          ) : (
            <span className="trends-summary-text__14p600">-- Not set</span>
          )}
        </li>
        <li className={`datatable-item ${config.trackerTheme.stress}`}>
            <span className="label trends-summary-text__14p600">Stress</span>
          {returnValue('stressData') !== null ? (
            <React.Fragment>
              <span className="trends-dots">{returnValue('stressData')}</span>
              <DragScale
                scaleWidth={0.4}
                editingMode={false}
                level={returnValue('stressData')}
                simpleDesign
              />
            </React.Fragment>
          ) : (
            <span className="trends-summary-text__14p600">-- Not set</span>
          )}
        </li>
        <li className={`datatable-item ${config.trackerTheme.sleep}`}>
            <span className="label trends-summary-text__14p600">Sleep</span>
          {returnValue('sleepData') !== null ? (
            <React.Fragment>
              <span className="trends-dots">{returnValue('sleepData')}</span>
              <DragScale
                scaleWidth={0.4}
                editingMode={false}
                level={returnValue('sleepData')}
                simpleDesign
              />
            </React.Fragment>
          ) : (
            <span className="trends-summary-text__14p600">-- Not set</span>
          )}
        </li>
      </ol>
    </div>
  );
};

export default TrendsSummaryTrackers;
