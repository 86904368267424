import gql from 'graphql-tag';

export const Me = gql`
  query me {
    me {
      id
      name
      email
      admin
      profileFlag
      profile {
        id
        zip
        timezone
        birth_year
        eczema_relation
        feelings
        nea_familiarity
        persona
        persona_slug
        favoriteFoods
        ethnicity
        ethnicity_specified
        was_referred
        gender
        userTreatments {
          id
          type
          name
          active
        }
      }
    }
  }
`;
