import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import FadeIn from '../components/shared/FadeIn';
import ScrollToTop from '../components/shared/ScrollToTop';
import analytics from '../libs/analytics';
import mixpanel from 'mixpanel-browser';
import MixpanelService from '../libs/mixpanel';
import { useIsVisible } from '../components/shared/hooks/useIsVisible';

//Images
import ECWFAQS from '../assets/images/newBrandImages/ECWFAQS.svg';
import ECWCreated from '../assets/images/newBrandImages/ECWCreated.svg';
import ECWCaregivers from '../assets/images/newBrandImages/ECWCaregivers.svg';
import ECWTrackSymptoms from '../assets/images/newBrandImages/ECWTrackSymptoms.png';
import ECWPoScorad from '../assets/images/newBrandImages/ECWPoScorad.svg';
import ECWHealthcare from '../assets/images/newBrandImages/ECWHealthcare.png';
import ECWProvider from '../assets/images/newBrandImages/ECWProvider.svg';
import ECWContributing from '../assets/images/newBrandImages/ECWContributing.svg';
import ECWAppStores from '../assets/images/newBrandImages/ECWAppStores.svg';
import ECWLoginInfo from '../assets/images/newBrandImages/ECWLoginInfo.png';
import ECWForgotPassword from '../assets/images/newBrandImages/ECWForgotPassword.svg';
import ECWCookiesJS from '../assets/images/newBrandImages/ECWCookiesJS.svg';
import ECWFeedback from '../assets/images/newBrandImages/ECWFeedback.svg';

const Help = () => {
  const focusElement = useRef(null);
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (focusElement.current) focusElement.current.focus();
  }, []);

  return (
    <ScrollToTop>
      <FadeIn>
        <div className="main-content content-body web-margin section_bg_white">
          <section className="content-section content__center help-bg-primary100">
            <div className="content-section__inner content__column">
              <div className="content-text-wrapper content-text-wrapper__left">
                <h1
                  className="help-main-title help-main-title__center title__white"
                  tabindex="0"
                  ref={focusElement}>
                  <span className="content__column">
                    Frequently Asked Questions
                  </span>
                </h1>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__faqs ${isVisible ? 'visible' : ''}`}
                  src={ECWFAQS}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <ul className="list help-list-dot">
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#why-EczemaWise"
                      className="link link__secondary-100 link__underline">
                      Why was EczemaWise created?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#caregivers"
                      className="link link__secondary-100 link__underline">
                      How can caregivers use EczemaWise?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#track-symptoms"
                      className="link link__secondary-100 link__underline">
                      How often should I track my symptoms in EczemaWise?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#POSCORAD"
                      className="link link__secondary-100 link__underline">
                      What is the PO-SCORAD (severity score)?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#healthcare-provider"
                      className="link link__secondary-100 link__underline">
                      How can I use EczemaWise with my healthcare provider?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#access-health-information"
                      className="link link__secondary-100 link__underline">
                      Does my healthcare provider have direct access to my
                      health information on the app?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#research-registry"
                      className="link link__secondary-100 link__underline">
                      What is a research registry and how am I contributing to
                      an eczema cure?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#browsers-compatible"
                      className="link link__secondary-100 link__underline">
                      Which web browsers are compatible with EczemaWise?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#help-mobile-tablet"
                      className="link link__secondary-100 link__underline">
                      Can I access EczemaWise on my mobile or tablet?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#help-login"
                      className="link link__secondary-100 link__underline">
                      I signed up for EczemaWise but I’m not able to login right
                      away. What’s going on?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="/help#help-forgot-password"
                      className="link link__secondary-100 link__underline">
                      I forgot my password -- what now?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="#help-delete-account"
                      className="link link__secondary-100 link__underline">
                      How can I delete my account?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="#help-cookies"
                      className="link link__secondary-100 link__underline">
                      Why do I need to enable cookies?
                    </HashLink>
                  </li>
                  <li className="help-list">
                    <HashLink
                      smooth
                      to="#help-javascript"
                      className="link link__secondary-100 link__underline">
                      Why do I need to enable javascript?
                    </HashLink>
                  </li>
                  <li className="help-list ">
                    <HashLink
                      smooth
                      to="#help-form"
                      className="link link__secondary-100 link__underline">
                      How can I contact someone for support and feedback?
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title help-title__left title__secondary100">
                  <span id="why-EczemaWise" tabindex="0">
                    Why was EczemaWise created?
                  </span>
                </h2>
                <p className="help-paragraph">
                  EczemaWise was created to help people understand and manage
                  their eczema. Developed  in collaboration with the eczema
                  community and leading healthcare providers, EczemaWise is
                  designed to help users track the daily happenings that
                  research has shown may cause eczema to flare or get worse.
                </p>
                <p className="help-paragraph">
                  EczemaWise also helps you work with your doctor to get the
                  most out of your appointment. The platform takes you through a
                  step-by-step process, called shared decision making, to best
                  prepare you for your visit. EczemaWise helps you inform your
                  doctor of the reason for your visit and your preferences
                  around eczema treatment. It helps you research your medication
                  options and choose the ones you’d like to discuss with your
                  provider at the appointment. EczemaWise compiles all this
                  information, along with your tracking data, to create a health
                  report you can download and send to your provider.
                </p>
                <p className="help-paragraph">
                  By helping you keep track of your daily life with eczema,
                  EczemaWise is your tool for better health. Research shows that
                  people who manage their chronic disease using a paper or
                  digital tracking method experience improved health and more
                  control over symptoms.{' '}
                </p>
                <p className="help-paragraph">
                  <Link className="link link__secondary-100" to="/about">
                    Read more about EczemaWise.
                  </Link>
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__created ${isVisible ? 'visible' : ''}`}
                  src={ECWCreated}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__caregivers ${isVisible ? 'visible' : ''}`}
                  src={ECWCaregivers}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="caregivers" tabindex="0">
                    How can caregivers use EczemaWise?
                  </span>
                </h2>
                <p className="help-paragraph">
                  EczemaWise can also help caregivers of eczema patients track
                  symptoms outside the doctor’s office so that this information
                  is all in one place and can be used at the point of care to
                  facilitate care conversations and treatment decision making.
                  Adult caregivers and adult patients 18+ years old can sign up
                  for EczemaWise. A caregiver is any non-healthcare provider
                  looking after the care of someone with eczema, such as a
                  parent, spouse or other family member or guardian. When
                  caregivers use EczemaWise to track a patient’s eczema by
                  proxy, it can have the same benefits to the patient (such as
                  better management and improved patient outcomes) as well as
                  benefits to the caregiver (reduced caregiver burden).
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title title__secondary100">
                  <span id="track-symptoms" tabindex="0">
                    How often should I track my symptoms in EczemaWise?
                  </span>
                </h2>
                <p className="help-paragraph">
                  Everyone’s eczema is different, so you may find you need to
                  track your symptoms more often or less often than other
                  people. Your needs may even change over time! We recommend
                  starting out by tracking at least twice a month for two months
                  so you can see your eczema trends. You should track even if
                  you’re having a great skin day — having a record of your
                  better days helps to characterize your relatively worse days.
                </p>
                <p className="help-paragraph">
                  We also recommend talking with your healthcare provider about
                  what health outcomes are important to you so you can track the
                  most relevant symptoms. If reducing itch is a major goal, then
                  make sure to use the Itch tracker. You don’t need to fill out
                  all the trackers every time! However, completing Skin, Itch
                  and Sleep are important for generating your PO-SCORAD score,
                  which describes your eczema severity. 
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__track-symptoms ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWTrackSymptoms}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__poscorad ${isVisible ? 'visible' : ''}`}
                  src={ECWPoScorad}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="POSCORAD" tabindex="0">
                    What is the PO-SCORAD (severity score)?
                  </span>
                </h2>
                <p className="help-paragraph">
                  The patient-oriented scoring for atopic dermatitis (PO-SCORAD)
                  severity score is based on your body surface area affected by
                  eczema, severity of skin signs and symptoms, itch severity and
                  sleep disturbance. It’s a great way to summarize how you’re
                  doing in one number and can help you see any changes over
                  time. This is helpful for your healthcare provider since many
                  doctors already use the clinician version of this score
                  (SCORAD) when you come in for office visits.
                </p>
                <p className="help-paragraph">
                  To calculate your PO-SCORAD, complete the Skin, Itch and Sleep
                  trackers in your EczemaWise app for one day.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10 help-bottom-margin__3rem">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title title__secondary100">
                  <span id="healthcare-provider" tabindex="0">
                    How can I use EczemaWise with my healthcare provider?
                  </span>
                </h2>
                <p className="help-paragraph ">
                  EczemaWise is meant to support your conversations about
                  your/your child’s eczema care and management with your
                  healthcare provider. Tracking your eczema between visits can
                  show your healthcare provider how you’re really doing outside
                  the doctor’s office. Here are some ways you can use the app
                  with a healthcare provider:
                </p>
                <ul className="list help-list-dot">
                  <li className="help-paragraph ">
                    Show your healthcare provider your trends on the Learn tab
                    in the app. Talk through how your symptoms have been
                    changing (or staying the same) in response to your eczema
                    treatments and/or triggers.
                  </li>
                  <br />
                  <li className="help-paragraph ">
                    Show your best tracked day and your worst tracked day in the
                    app, including photos you took and the PO-SCORAD score or
                    other symptoms tracked that day. This can give your
                    healthcare provider an idea of how much your eczema has
                    changed (or not changed).
                  </li>
                  <br />
                  <li className="help-paragraph">
                    Complete the appointment summary in the Manage tab in the
                    app. Print out or send it to your healthcare provider ahead
                    of your appointment(s) to help guide your discussion.
                  </li>
                  <br />
                  <li className="help-paragraph">
                    At your appointment, consider talking about the health
                    outcomes that are important to you, new and current
                    treatments and clinical trial opportunities.
                  </li>
                </ul>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__healthcare ${isVisible ? 'visible' : ''}`}
                  src={ECWHealthcare}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bottom-margin__3rem">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__provider ${isVisible ? 'visible' : ''}`}
                  src={ECWProvider}
                  alt=""
                />
              </div>
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="access-health-information" tabindex="0">
                    Does my healthcare provider have direct access to my health
                    information on the app?
                  </span>
                </h2>
                <p className="help-paragraph">
                  No. Only the account holder can access user data in
                  EczemaWise. However, we encourage you <br /> to communicate
                  how you’re doing with your healthcare provider. One way to do
                  that is by completing
                  <br />
                  the appointment summary in the Manage tab that summarizes your
                  trends and outlines topics you
                  <br />
                  wish to cover for your upcoming office visit.
                </p>
                <p className="help-paragraph">
                  Once completed, you can download and share the appointment
                  summary PDF that
                  <br />
                  gets generated via your healthcare provider’s secure portal or
                  print it out. 
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title title__secondary100">
                  <span id="research-registry" tabindex="0">
                    What is a research registry, and how am I contributing to an
                    eczema cure?
                  </span>
                </h2>
                <p className="help-paragraph">
                  A research registry collects information about people who have
                  a particular medical condition and makes it available to
                  researchers who are studying that condition in an effort to
                  find better treatments and, hopefully one day, a cure. The
                  information you — and other app users — enter in EczemaWise
                  forms a research registry.
                </p>
                <p className="help-paragraph">
                  Even though the data you enter into the app is helping
                  scientists do better research, your data is always private and
                  confidential. Data collected is de-identified, meaning
                  identifying personal information is not shared and researchers
                  cannot link any data back to a particular individual. Your
                  data is also never sold for commercial purposes.
                </p>
                <p className="help-paragraph">
                  Read more about EczemaWise as a research registry{' '}
                  <HashLink
                    smooth
                    to="/about"
                    className="link link__secondary-100">
                    here
                  </HashLink>
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__contributing ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWContributing}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__compatibility ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWAppStores}
                  alt=""
                />
              </div>
              <br />
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="browsers-compatible" tabindex="0">
                    Which web browsers are compatible with EczemaWise?
                  </span>
                </h2>
                <p className="help-paragraph">
                  EczemaWise is built to work with the latest version of Chrome,
                  Safari, Firefox and Edge. EczemaWise does not work with
                  Internet Explorer, Opera or other browsers not mentioned here.
                </p>

                <p className="help-paragraph">
                  If you are having trouble using EczemaWise and are on an older
                  version of one of the browsers mentioned above, we recommend
                  you upgrade your browser to the latest version.
                </p>

                <h2 className="help-title title__secondary100">
                  <span id="help-mobile-tablet" tabindex="0">
                    Can I access EczemaWise on my mobile or tablet?
                  </span>
                </h2>
                <p className="help-paragraph">
                  Yes. EczemaWise is available as a free download from the{' '}
                  <a
                    className="link link__secondary-100"
                    onClick={e => {
                      analytics().logEvent('ios_dl_page_from_help', {});
                    }}
                    href="https://apps.apple.com/us/app/id1522743215"
                    target="_blank"
                    rel="noreferrer">
                    Apple App Store
                  </a>{' '}
                  and{' '}
                  <a
                    className="link link__secondary-100"
                    onClick={e => {
                      analytics().logEvent('android_dl_page_from_help', {});
                    }}
                    href="https://play.google.com/store/apps/details?id=com.eczemawiseapp"
                    target="_blank"
                    rel="noreferrer">
                    Google Play
                  </a>
                  .
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title title__secondary100">
                  <span id="help-login" tabindex="0">
                    I signed up for EczemaWise but I’m not able to log in right
                    away. What’s going on? 
                  </span>
                </h2>
                <p className="help-paragraph">
                  EczemaWise requires you to verify the email address associated
                  with your account before you can log in for the first time. To
                  verify your email address, click on the “verify email” link in
                  the email sent to you when you signed up. If you didn’t
                  receive this verification email, check your spam folder. If
                  you still can’t find your verification email, please{' '}
                  <a
                    className="link link__secondary-100 link__underline"
                    href="https://www.tfaforms.com/4848315"
                    target="_blank"
                    rel="noreferrer">
                    contact us
                  </a>{' '}
                  for help.
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__login-info ${isVisible ? 'visible' : ''}`}
                  src={ECWLoginInfo}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__forgot-password ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWForgotPassword}
                  alt=""
                />
              </div>
              <br />
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="help-forgot-password" tabindex="0">
                    I forgot my password — now what?
                  </span>
                </h2>
                <p className="paragraph">
                  If you’re unable to log in because of a forgotten password,
                  please{' '}
                  <Link
                    className="link link__secondary-100"
                    to="/forgot-password">
                    reset your password here
                  </Link>{' '}
                </p>

                <h2 className="help-title title__secondary100">
                  <span id="help-delete-account" tabindex="0">
                    How can I delete my account?
                  </span>
                </h2>
                <p className="help-paragraph">
                  To delete your account using the website, log in to{' '}
                  <a
                    className="link link__secondary-100"
                    href="https://www.eczemawise.org/"
                    target="_blank"
                    rel="noreferrer">
                    EczemaWise.org
                  </a>{' '}
                  Go to the Settings page. Near the bottom of the page, follow
                  the instructions in the section titled "Delete Your Account." 
                </p>
                <p className="help-paragraph">
                  If you are using the mobile app, go to the Your Account
                  section by tapping on the gear icon in the upper right corner
                  of your screen and then select “Delete Your Account.”
                </p>
                <p className="help-paragraph">
                  If logging in is not an option, please{' '}
                  <a
                    className="link link__secondary-100"
                    href="https://www.tfaforms.com/4848315"
                    target="_blank"
                    rel="noreferrer">
                    contact us here.
                  </a>{' '}
                  Please provide the email associated with the account with your
                  account deletion request.
                </p>
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational help-bg-secondary10">
            <div className="content__row content__space-between">
              <div className="content-help-wrapper content-help-wrapper__left-section">
                <h2 className="help-title title__secondary100">
                  <span id="help-cookies" tabindex="0">
                    Why do I need to enable cookies?
                  </span>
                </h2>
                <p className="help-paragraph">
                  Cookies are small bits of code that allows your browser to
                  communicate with EczemaWise. The EczemaWise platform requires
                  cookies in order to function. You will not be able to create
                  an account or log in without cookies enabled.
                </p>
                <h2 className="help-title title__secondary100">
                  <span id="help-javascript" tabindex="0">
                    Why do I need to enable javascript?
                  </span>
                </h2>
                <p className="help-paragraph">
                  EczemaWise is built using modern JavaScript libraries and
                  tools. JavaScript is a coding language used on many websites.
                  If you can read this page, you already have JavaScript
                  enabled.
                </p>
              </div>
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__cookies ${isVisible ? 'visible' : ''}`}
                  src={ECWCookiesJS}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="content-section content-section__informational">
            <div className="content__row content__space-between">
              <div className="images-wrapper" ref={ref}>
                <img
                  className={`image__feedback ${
                    isVisible ? 'visible' : ''
                  }`}
                  src={ECWFeedback}
                  alt=""
                />
              </div>
              <br />
              <div className="content-about-wrapper content-about-wrapper__right-section">
                <h2 className="help-title title__secondary100">
                  <span id="help-form" tabindex="0">
                    How can I contact someone for support and feedback?
                  </span>
                </h2>
                <p className="help-paragraph">
                  If you need additional support or wish to give feedback,
                  please contact us{' '}
                  <a
                    className="link link__secondary-100"
                    href="https://www.tfaforms.com/4848315"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => MixpanelService.track('FeedbackTapped')}>
                    here
                  </a>
                  .
                </p>
              </div>
            </div>
          </section>
        </div>
      </FadeIn>
    </ScrollToTop>
  );
};

export default Help;
