import '@firebase/analytics';
import firebase from '@firebase/app';
import config from '../config';

// firebase analytics
if (!firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
  firebase.analytics();
}

export default process.env.NODE_ENV === 'development'
  ? () => {
      //if we're in dev just log the events
      return {
        setCurrentScreen: console.info,
        logEvent: console.info,
      };
    }
  : firebase.analytics;
