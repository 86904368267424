import { flowRight as compose } from 'lodash';
import React, { Fragment } from 'react';
import { graphql } from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import { ActionSvg, TrashNewIcon } from '../../assets/icons';
import { QuestionMark } from '../../assets/icons/';
import { TreatmentGroups } from '../../libs/graphql/queries/data';
import {
  getSuggestions,
  getSuggestionValue,
  renderSuggestion,
} from '../../libs/treatmentOptions';
import Treatment from '../trackers/Treatment';
import Tooltip from 'react-tooltip'; 

const composeWithTreatmentGroups = graphql(TreatmentGroups, {
  props: ({ data: { loading, error, availableTreatmentOptionsGroups } }) => ({
    loading,
    error,
    availableTreatmentOptionsGroups,
  }),
});

const hoverTextOptions = {
  Topicals: 'Medications applied to the skin',
  Phototherapy: 'Skin is exposed to UVB light using a machine',
  Systemics: 'Medication taken by mouth or injection under the skin',
  Biologic:
    'Medication made from living cells and taken by injection under the skin',
  OTC: 'Medications purchased from a store without a prescription',
};

const errorMessages = {
  duplicate: 'Treatment name already exists',
  missing_name: 'Missing medication name',
  name_not_added: 'Press "+" to add name',
};

//Decision was made to change OTC to Over The Counter. Since OTC was already being used for treatment type saving,
//I just added a mapping to a different label for that treatment type
const treatmentLabel = type => {
  let label;
  switch (type) {
    case 'OTC':
      label = 'Over The Counter';
      break;
    case 'Topicals':
      label = 'Prescription Topical';
      break;
    default:
      label = type;
  }
  return label;
};

const TreatmentSelection = ({
  type,
  loading,
  userTreatments,
  typeSelected,
  errors,
  namesToAdd,
  nameHandler,
  addHandler,
  deleteHandler,
  availableTreatmentOptionsGroups,
}) => {
  let canName = type !== 'Phototherapy';
  let hoverText = hoverTextOptions[type];

  availableTreatmentOptionsGroups = availableTreatmentOptionsGroups || [];
  let suggestions = [];
  availableTreatmentOptionsGroups.forEach(group => {
    let _type = type;
    switch (_type) {
      case 'Biologic':
        _type = 'Biologics';
        break;
      case 'Prescription Topical':
        _type = 'Topicals';
        break;
      case 'Over The Counter':
        _type = 'OTC';
    }
    if (group.treatment_group === _type) {
      suggestions = group.optionNames;
    }
  });

  suggestions = [
    ...new Set(
      suggestions
        .slice()
        .sort((a, b) => b.length - a.length)
        .reverse(),
    ),
  ].map(name => ({
    name,
  }));

  return (
    <Fragment>
      <span className="tracker-list-item registration-tracker-item">
        {hoverText && (
          <span className="tooltip-wrapper">
            <span className="tooltip_popup_thing">
              <QuestionMark />
              <span className="tooltip-popup">
                <span className="tooltip-text">
                  {treatmentLabel(type)}
                  {': '}
                  {hoverText}
                </span>
              </span>
            </span>
          </span>
        )}
        <span className="list-content-wrapper list-content-wrapper__column registration-tracker-content">
          <Treatment treatment={type} read={true} name={treatmentLabel(type)} />
          {canName && typeSelected ? (
            <span>
              {userTreatments.map(({ name, id, active }, i) => {
                if (!active) return null;
                return (
                  <span
                    className="registration-treatment-item-wrapper form-item form-item-row"
                    key={i}>
                    <span className="registration-treatment-item paragraph">
                      {name}
                    </span>
                    <Tooltip 
                      id={`tooltip-delete-${id}`} 
                      place="right" 
                      effect="solid" 
                      type="dark" 
                      delayShow={500} 
                      delayHide={200} 
                      trigger="hover"
                    >
                      Remove
                    </Tooltip>
                      <button
                        className="button button-action button-action__delete"
                        onClick={e => {
                          e.preventDefault();
                          deleteHandler(id);
                        }}
                        data-tip 
                        data-for={`tooltip-delete-${id}`}
                      >
                        <ActionSvg />
                      </button>
                  </span>
                );
              })}
              <span className="registration-treatment-item-wrapper form-item">
                {errors[type] ? (
                  <span className="alert alert-negative alert-negative__with-icon">
                    <span className="alert-paragraph">
                      {errorMessages[errors[type].type]}
                    </span>
                  </span>
                ) : null}
                <label className="form-input-label">Name</label>
                <span className="form-item-row">
                  <Autosuggest
                    id={type}
                    suggestions={getSuggestions(
                      namesToAdd[type] || '',
                      suggestions,
                    )}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={() => {}}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={(e, val) => {
                      e.preventDefault();
                      addHandler(type, val.suggestionValue);
                    }}
                    inputProps={{
                      autoFocus: true,
                      value: namesToAdd[type] || '',
                      placeholder: `${type} name`,
                      onKeyPress: e => {
                        if (e.which === 13) {
                          e.preventDefault();
                          if (e.target.value)
                            addHandler(type, namesToAdd[type]);
                        }
                      },
                      onChange: (e, val) => {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                          // up down navigation
                          e.preventDefault();
                        } else {
                          nameHandler(type, val.newValue);
                        }
                      },
                      className:
                        'registration-treatment-field form-input-field' +
                        (errors[type] ? ' form-input-field__error' : ''),
                    }}
                  />
                  <Tooltip 
                    id={`tooltip-Add-${treatmentLabel(type)}`} 
                    place="right" 
                    effect="solid" 
                    type="dark" 
                    delayShow={500} 
                    delayHide={200} 
                    trigger="hover"
                  >
                    Add
                  </Tooltip>
                  <button
                    data-tip 
                    data-for={`tooltip-Add-${treatmentLabel(type)}`}
                    disabled={!namesToAdd[type]}
                    className="button button-action button-action__add"
                    onClick={addHandler.bind(null, type, namesToAdd[type])}>
                    <ActionSvg />
                  </button>
                </span>
              </span>
            </span>
          ) : null}
        </span>
        {hoverText && (
          <>
            <Tooltip 
              id={`tooltip-Question-${treatmentLabel(type)}`} 
              place="top" 
              effect="solid" 
              type="dark" 
              delayShow={500} 
              delayHide={200} 
              trigger="hover"
            >
              <span className="tooltip-popup-content__insert headers">
                {treatmentLabel(type)}
              </span>
              <br/>
              {hoverText}
            </Tooltip>
            <span className="tooltip-wrapper" data-tip data-for={`tooltip-Question-${treatmentLabel(type)}`} >
              <span className="tooltip_popup_thing">
                <QuestionMark 
                />
              </span>
            </span>
          </>
        )}
      </span>
    </Fragment>
  );
};

export default compose(composeWithTreatmentGroups)(TreatmentSelection);
