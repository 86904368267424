// QUERIES
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAppointments } from '../../libs/graphql/queries/user';

// ACTIONS

export const fetchAppointments = createAsyncThunk(
  'appointments/fetchAppointments',
  async userid => {
    try {
      // QUERY
      const delay2sec = new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
      await delay2sec;

      return [];
    } catch (error) {
      throw new Error('Failed to fetch appointments');
    }
  },
);
