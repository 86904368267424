import React, { Fragment } from 'react';
import config from '../../config';
import DragScale from '../shared/DragScale';
import SkinTrackerAreasAffected from '../trackers/SkinTrackerAreasAffected';
import { SkinIcon } from '../../assets/icons/trackers';

const SYMPTOMS = [
  { value: 'dryness', label: 'dryness' },
  { value: 'redness', label: 'redness' },
  { value: 'swelling', label: 'swelling' },
  { value: 'oozing_scabs', label: 'oozing/scabs' },
  { value: 'scratch_marks', label: 'scratch marks' },
  { value: 'thickening_skin', label: 'thickening skin' },
];

const TrendsSummarySkin = ({ skinData = {}, pictures = [] }) => {
  return (
    <div
      className={`trends-summary-skin tracker-skin ${config.trackerTheme.skin}`}>
      <h3 className="trends-summary-text trends-summary-text__16p400 subtext__left">
        Affected Areas
      </h3>
      <SkinTrackerAreasAffected
        areas_affected={skinData ? skinData.areas_affected : []}
      />
      <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
        Intensity of symptoms
      </h3>
      <ol className="trends-summary-datatable">
        {skinData ? buildSymptoms(SYMPTOMS, skinData) : null}
      </ol>
      <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
        Infections
      </h3>
      <div>
        <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
          Do you have any infection(s)?
        </h3>
        <p className="trends-summary-text trends-summary-text__16p400 subtext__left">
          {skinData && skinData.infections ? 'Yes' : 'No'}
        </p>
        <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
          Notes
        </h3>
        <p className="trends-summary-text trends-summary-text__16p400 subtext__left">
          {skinData ? skinData.infections_notes : ''}
        </p>
      </div>
      <div>
        <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
          Notes
        </h3>
        <p className="trends-summary-text trends-summary-text__16p400 subtext__left">
          {skinData ? skinData.notes : ''}
        </p>
      </div>
      <h3 className="trends-summary-text trends-summary-text__16p subtext__left">
        Photos
      </h3>
      <div className="tracker-images">
        {pictures
          ? pictures.map((picture, i) => {
              return (
                <Fragment key={i}>
                  <span className="picture-header">
                    {picture.area_affected
                      .split('_')
                      .map(s => s[0].toUpperCase() + s.substr(1))
                      .join(' ')}
                  </span>
                  {picture.images.map((img, i) => {
                    return (
                      <img
                        key={i}
                        className="affected-area-image"
                        src={img.uri}
                        alt={picture.area_affected}
                      />
                    );
                  })}
                </Fragment>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default TrendsSummarySkin;

const buildSymptoms = (symptoms, data) => {
  if (!data.areas_selected) {
    symptoms = [{ value: 'dryness', label: 'dryness' }]; //override symptoms array if we only care about dryness
  }
  let scales = symptoms.map((symptom, i) => {
    return (
      <li className="symptom-scale-row datatable-item" key={i}>
        <span className="paragraph paragraph__capitalized label">
          {symptom.label}
        </span>
        <span className="trends-dots">
          {data[symptom.value]}
        </span>
        <div className="symptom-scale-wrapper">
          <DragScale
            maxValue={3}
            editingMode={false}
            level={data[symptom.value]}
            simpleDesign
            withoutRadius={true}
          />
        </div>
      </li>
    );
  });

  return scales;
};
