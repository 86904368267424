import React from 'react';

export default function Validation() {
  return (
    <svg viewBox="0 0 10 10">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(1, 1)">
        <g className="icon-validation-circle">
          <circle id="Oval" cx="4" cy="4" strokeWidth="1" r="4" />
          <g
            className="icon-validation-check"
            transform="translate(2.000000, 2.000000)"
            strokeLinecap="round"
            strokeLinejoin="round">
            <polyline points="0.4 2.11930185 1.4438924 3.12 3.6 1.2" />
          </g>
        </g>
      </g>
    </svg>
  );
}
