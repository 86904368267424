import gql from 'graphql-tag';

export const galleryImages = gql`
  query getEczemaGalleryImages($userid:ID!, $date_from: Date!, $date_to: Date!) {
    getEczemaGalleryImages(userid: $userid, date_from:$date_from, date_to:$date_to) {
        imageid
        tracker_date
        level
        area_affected
        mimetype
        favorite
    }
  }
`;