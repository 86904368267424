import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import {
  withAllMetadata,
  withSurveysNagation,
} from '../apollo/stores/appointments';
import SurveysContentWrapper from '../components/providerprep/SurveysContentWrapper';
import ProviderPrepHeader from '../components/shared/ProviderPrepHeader';
import SkinTrackerNavigation from '../components/trackers/SkinTrackerNavigation';
import { addAppointmentMetadata } from '../libs/graphql/mutations/user';
import {
  UserAppointments,
  UserAppointmentsMetadata,
} from '../libs/graphql/queries/user';

const composeWithMetadata = graphql(UserAppointmentsMetadata, {
  props: ({ data: { loading, error, getAppointmentMetadata } }) => ({
    loading,
    error,
    getAppointmentMetadata,
  }),
  options: props => ({
    variables: {
      appointmentId: props.match.params.id,
    },
    fetchPolicy: 'no-cache',
  }),
});

const withMetadataMutation = graphql(addAppointmentMetadata, {
  name: 'addAppointmentMetadata',
});

class SurveysContainer extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.getAppointmentMetadata &&
      nextProps.getAppointmentMetadata.appointment.metadata_added
    ) {
      //hydrate the surveys store with existing data
      this.props.hydrateMetadata({
        variables: {
          issues: nextProps.getAppointmentMetadata.metadata.issues,
          priorities: nextProps.getAppointmentMetadata.metadata.priorities,
          notes: nextProps.getAppointmentMetadata.metadata.focus_notes,
          self: nextProps.getAppointmentMetadata.metadata.picture_self,
          social: nextProps.getAppointmentMetadata.metadata.picture_social,
          work: nextProps.getAppointmentMetadata.metadata.picture_work,
          selectedOptions:
            nextProps.getAppointmentMetadata.metadata.treatments.map(id =>
              parseInt(id),
            ),
          selectedOptionsNames:
            nextProps.getAppointmentMetadata.metadata.treatments_names,
          trendsEndDate:
            nextProps.getAppointmentMetadata.metadata.trends_end_date,
        },
      });
    }
  }

  componentDidMount() {
    if (
      this.props.getAppointmentMetadata &&
      this.props.getAppointmentMetadata.appointment.metadata_added
    ) {
      //hydrate the surveys store with existing data
      this.props.hydrateMetadata({
        variables: {
          issues: this.props.getAppointmentMetadata.metadata.issues,
          priorities: this.props.getAppointmentMetadata.metadata.priorities,
          notes: this.props.getAppointmentMetadata.metadata.focus_notes,
          self: this.props.getAppointmentMetadata.metadata.picture_self,
          social: this.props.getAppointmentMetadata.metadata.picture_social,
          work: this.props.getAppointmentMetadata.metadata.picture_work,
          selectedOptions:
            this.props.getAppointmentMetadata.metadata.treatments &&
            this.props.getAppointmentMetadata.metadata.treatments.map(id =>
              parseInt(id),
            ),
          selectedOptionsNames:
            this.props.getAppointmentMetadata.metadata.treatments_names,
          trendsEndDate:
            this.props.getAppointmentMetadata.metadata.trends_end_date,
        },
      });
    }
  }

  componentWillUnmount() {
    // clear out client cache when component unmounts so form values don't populate in other appointments
    // page 1 or 4 clears out all the forms
    this.props.resetSurveys({
      variables: {
        page: 5,
      },
    });
  }

  render() {
    if (this.props.loading) return null;
    let apptId = this.props.match.params.id;
    let readOnly = this.props.getAppointmentMetadata.appointment.metadata_added;

    const date = moment.utc(this.props.getAppointmentMetadata.appointment.date);
    const time = moment.utc(this.props.getAppointmentMetadata.appointment.time);
    return (
      <div>
        <header>
          <div className="date-bar">
            <div className="sidebar-title">
              <Link className="link link__cobalt" to="/provider-prep">
                <h1 className="headers headers__cobalt headers__uppercase headers__semibold">
                  &larr; Back To Appointments
                </h1>
              </Link>
            </div>
          </div>
        </header>
        <div className="surveys-wrapper card appointment-item shadow-active">
          <ProviderPrepHeader
            date={date}
            time={time}
            name={`${this.props.getAppointmentMetadata.appointment.provider_first_name} ${this.props.getAppointmentMetadata.appointment.provider_last_name}`}
            step={this.props.surveysNavigation.currentPage}
            readOnly={readOnly}
          />
          <SurveysContentWrapper
            apptId={apptId}
            me={this.props.me}
            page={this.props.surveysNavigation.currentPage}
            readOnly={readOnly}
            date={date}
            time={time}
            name={`${this.props.getAppointmentMetadata.appointment.provider_first_name} ${this.props.getAppointmentMetadata.appointment.provider_last_name}`}
          />
          {!readOnly ? (
            <SkinTrackerNavigation
              onCancel={this.onCancel.bind(this)}
              altCancelText={
                this.props.surveysNavigation.currentPage === 1 ||
                this.props.surveysNavigation.currentPage === 5
                  ? 'Cancel'
                  : 'Clear'
              }
              onNext={this.navigateNext.bind(this)}
              onSubmit={this.submitMetadata.bind(this, apptId)}
              onBack={this.navigateBack.bind(this)}
              showBack={!(this.props.surveysNavigation.currentPage === 1)}
              lastPage={this.props.surveysNavigation.currentPage === 5}
              disableSave={this.props.focusForm.issues.length === 0}
            />
          ) : null}
        </div>
      </div>
    );
  }

  submitMetadata(id) {
    let { focusForm, pictureForm, treatmentOptions, affectedAreasForm } =
      this.props;
    let metadata = {
      appointment_id: id,
      issues: focusForm.issues,
      priorities: focusForm.priorities,
      focus_notes: focusForm.notes,
      picture_self: pictureForm.self,
      picture_social: pictureForm.social,
      picture_work: pictureForm.work,
      treatments: treatmentOptions.selectedOptions,
      images: affectedAreasForm.images,
    };
    return this.props
      .addAppointmentMetadata({
        variables: {
          metadata,
        },
        refetchQueries: () => [
          {
            query: UserAppointments,
            variables: { userid: this.props.me.id },
          },
        ],
      })
      .then(() => {
        this.props.history.push('/provider-prep');
      });
  }

  navigateNext() {
    this.props.navigateNextSurveys();
  }

  navigateBack() {
    this.props.navigateBackSurveys();
  }

  onCancel() {
    let page = this.props.surveysNavigation.currentPage;

    //step 1, (find your focus) cancel will just navigate back to /provider-prep and clear all survey forms
    //all other steps, cancel will clear the form of the step they are currently on

    // for summary page, cancel should trigger an alert warning that all data will be lost and navigate to appointments
    this.props
      .resetSurveys({
        variables: { page },
      })
      .then(() => {
        if (page === 1 || page === 5) {
          this.props.history.push('/provider-prep');
        }
      });
  }
}

export default compose(
  withRouter,
  withSurveysNagation,
  withAllMetadata,
  composeWithMetadata,
  withMetadataMutation,
)(SurveysContainer);
