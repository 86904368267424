import gql from 'graphql-tag';

export const DietDailyData = gql`
  query dailyUserData($id: ID!, $today: String!, $readOnly: Boolean) {
    dailyUserData(id: $id, today: $today, readOnly: $readOnly) {
      id
      updated_date
      tracker_date
      dietData {
        id
        foods
        cups_water
        custom_foods
      }
    }
  }
`;