import React from 'react';

export const DietIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__diet">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__diet tracker-icon-image"
        transform="translate(11, 8) scale(.9)">
        <path d="M5.95833333,11.3020833 C4.91291667,11.3020833 4.0625,12.1525 4.0625,13.1979167 C4.0625,13.6464167 3.6985,14.0104167 3.25,14.0104167 C2.8015,14.0104167 2.4375,13.6464167 2.4375,13.1979167 C2.4375,11.2565833 4.017,9.67708333 5.95833333,9.67708333 C6.40683333,9.67708333 6.77083333,10.0410833 6.77083333,10.4895833 C6.77083333,10.9380833 6.40683333,11.3020833 5.95833333,11.3020833 M14.44625,7.375 C12.3326667,7.375 11.4226667,8.21458333 9.75,8.21458333 C8.07733333,8.21458333 7.16733333,7.375 5.05375,7.375 C0.446333333,7.375 -0.49725,11.7126667 0.21125,16.0145833 C0.973916667,20.6458333 2.55016667,24.7083333 5.993,24.7083333 C8.32216667,24.7083333 8.77175,23.7333333 9.75,23.7333333 C10.72825,23.7333333 11.1778333,24.7083333 13.507,24.7083333 C16.9498333,24.7083333 18.5260833,20.6458333 19.28875,16.0145833 C19.99725,11.7126667 19.0536667,7.375 14.44625,7.375" />
        <path d="M14.6228333,5.20670833 C16.3995,3.431125 16.24675,0.336041667 16.24675,0.336041667 C16.24675,0.336041667 13.15275,0.184375 11.3760833,1.95995833 C9.6005,3.73554167 9.75325,6.830625 9.75325,6.830625 C9.75325,6.830625 12.84725,6.98229167 14.6228333,5.20670833" />
      </g>
    </svg>
  );
};
