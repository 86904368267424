import React from 'react';

export default function Irritants() {
  return (
    <svg viewBox="0 0 24 23">
      <g className="icon-fill" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M21.8167,20.4024 L18.9377,17.5234 C19.2377,17.1484 19.5187,16.7564 19.7577,16.3314 C16.7037,15.0714 13.3217,14.6974 9.9927,15.3034 C11.0147,17.3054 12.3717,19.1214 14.0167,20.6604 C15.3037,20.3494 16.4967,19.7534 17.5227,18.9364 L20.4027,21.8164 L22.0497,22.3664 C22.2447,22.4304 22.4307,22.2454 22.3657,22.0494 L21.8167,20.4024 Z"
          id="Fill-8"
        />
        <path
          d="M7.9109,8.0752 C7.9779,8.8592 8.1079,9.6272 8.2649,10.3852 C12.5009,9.1992 16.9629,9.5632 20.8919,11.3532 C20.8259,10.3282 20.5899,9.3342 20.1979,8.4102 C16.3129,6.8242 11.9579,6.6802 7.9109,8.0752"
          id="Fill-6"
        />
        <path
          d="M9.5559,14.3711 C13.1759,13.6601 16.8649,14.0481 20.1869,15.4281 C20.3969,14.9331 20.5789,14.4221 20.7009,13.8791 C20.8059,13.4081 20.8709,12.9371 20.8999,12.4721 C17.0679,10.5991 12.6619,10.1791 8.4889,11.3611 C8.7599,12.3981 9.1239,13.4021 9.5559,14.3711"
          id="Fill-4"
        />
        <path
          d="M23.7615,2.5479 L21.2865,0.0729 C21.1885,-0.0241 21.0305,-0.0241 20.9325,0.0729 L19.8725,1.1339 C19.7745,1.2319 19.7745,1.3899 19.8725,1.4869 L20.4025,2.0179 L17.5285,4.8919 C16.4915,4.0629 15.2615,3.4429 13.8785,3.1339 C11.8115,2.6719 9.7635,2.9829 7.9975,3.8429 C7.8575,4.9019 7.8165,5.9729 7.8495,7.0429 C11.6855,5.7819 15.7685,5.8209 19.5115,7.0869 C19.3385,6.8159 19.1475,6.5559 18.9455,6.3029 L21.8165,3.4319 L22.3465,3.9619 C22.4445,4.0599 22.6025,4.0599 22.7005,3.9619 L23.7615,2.9019 C23.8585,2.8039 23.8585,2.6459 23.7615,2.5479"
          id="Fill-11"
        />
        <path
          d="M6.9251,4.46 C6.7171,4.6 6.5141,4.748 6.3171,4.902 L3.4321,2.018 L3.9621,1.487 C4.0601,1.39 4.0601,1.231 3.9621,1.134 L2.9021,0.073 C2.8041,-0.024 2.6461,-0.024 2.5481,0.073 L0.0731,2.548 C-0.0239,2.646 -0.0239,2.804 0.0731,2.901 L1.1341,3.962 C1.2321,4.06 1.3901,4.06 1.4881,3.962 L2.0181,3.432 L4.8951,6.31 C4.8201,6.403 4.7391,6.492 4.6681,6.589 C4.6471,7.199 4.6461,7.812 4.6821,8.43 C4.8341,11.058 5.5111,13.55 6.6051,15.815 L5.8571,16.564 C4.6141,14.102 3.8511,11.372 3.6841,8.488 C3.6801,8.425 3.6811,8.363 3.6781,8.3 C3.4491,8.824 3.2631,9.376 3.1331,9.956 C2.5151,12.724 3.2521,15.471 4.8941,17.526 L2.0181,20.402 L1.4691,22.05 C1.4041,22.245 1.5901,22.431 1.7851,22.366 L3.4321,21.816 L6.3071,18.941 C6.8451,19.371 7.4341,19.748 8.0721,20.049 C7.6451,19.51 7.2561,18.943 6.8841,18.364 L7.6081,17.641 C8.2891,18.732 9.0661,19.763 9.9541,20.7 C9.9541,20.7 9.9551,20.701 9.9561,20.701 C10.9111,20.914 11.8631,20.962 12.7871,20.872 C9.3251,17.385 7.1511,12.698 6.8561,7.606 C6.7951,6.557 6.8251,5.504 6.9251,4.46"
          id="Fill-1"
        />
      </g>
    </svg>
  );
}
