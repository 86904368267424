import { Component } from 'react';

class FadeIn extends Component {
  componentDidMount() {
    setTimeout(() => {
      const e = document.querySelector('.content-body');
      if (e) e.classList.add('fade-in');
    }, 0);
  }

  render() {
    return this.props.children;
  }
}

export default FadeIn;
