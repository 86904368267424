import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
//client store
import { withSettings } from '../apollo/stores/settings';
import * as Unicons from '@iconscout/react-unicons';
import { withRouter } from 'react-router-dom';
import { OptionElement } from '../components/settings/OptionElement';
import { UserInformation } from '../components/settings/UserInformation';
import { AccountInformation } from '../components/settings/AccountInformation';
import EditTreatments from '../components/settings/EditTreatments';
import ChangePassword from '../components/settings/ChangePassword';
import ChangeZipCode from '../components/settings/ChangeZipCode';
import DeleteAccount from '../components/settings/DeleteAccount';
import analytics from '../libs/analytics';

// Mutations
import {
  changePassword,
  deleteUser,
  updateUserTreatments,
  updateUserZip,
} from '../libs/graphql/mutations/user';
import { DailyUserData } from '../libs/graphql/queries/data';
// Queries
import { Me } from '../libs/graphql/queries/me';
import MixpanelService from '../libs/mixpanel';
import { profileActiveTreatmentCategories } from '../libs/treatmentOptionsUtils';

const withUserZipMutation = graphql(updateUserZip, { name: 'updateUserZip' });
const withChangePasswordMutation = graphql(changePassword, {
  name: 'changePassword',
});

const withDeleteUserMutation = graphql(deleteUser, { name: 'deleteUser' });
const withUpdateUserTreatmentsMutation = graphql(updateUserTreatments, {
  name: 'updateUserTreatments',
});

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsSection: 'UserInformation',
      newZipCode: '',
      editTreatments: false,
      namesToAdd: {},
      errors: {},
      selectedTreatments: [],
    };
  }

  componentWillReceiveProps(nextProps) {

    const { location } = nextProps;

    if(location?.props?.settingsSection)
    this.setState({settingsSection: location?.props?.settingsSection});

    let activeTreatments;
    let treatmentTracker = [];
    let existingTreatments = this.state.selectedTreatments;
    //watch for store "readiness" or for a change in saved user treatments on profile
    const profileTreatmentsUpdated = () => {
      let statusChange = false;
      let treatmentAdded =
        this.props.me.profile.userTreatments.length !==
        nextProps.me.profile.userTreatments.length;
      if (treatmentAdded) {
        return treatmentAdded;
      } else {
        //check for treatment changing active state
        nextProps.me.profile.userTreatments.forEach(newTreatment => {
          let oldTreatment = this.props.me.profile.userTreatments.find(
            ot => ot.id === newTreatment.id,
          );
          if (oldTreatment && newTreatment.active !== oldTreatment.active) {
            statusChange = true;
          }
        });
        return statusChange;
      }
    };

    if (profileTreatmentsUpdated() || !this.props.userTreatmentsData) {
      //second conditional handles initial load
      //hydrate settings store
      activeTreatments = nextProps.me.profile.userTreatments.filter(
        t => t.active,
      );
      let data = activeTreatments.map(t => {
        if (!treatmentTracker.includes(t.type)) {
          treatmentTracker.push(t.type);
        }
        return {
          id: t.id,
          name: t.name,
          type: t.type,
          active: t.active,
        };
      });
      return this.props
        .hydrateTreatmentStore({
          variables: {
            data: data,
          },
        })
        .then(() => {
          this.setState({
            selectedTreatments: treatmentTracker,
          });
        });
    }

    if (
      !this.state.editTreatments &&
      nextProps.userTreatmentsData &&
      nextProps.userTreatmentsData.userTreatments
    ) {
      //check to see if selectedtreatments in state jives with what client store says
      nextProps.userTreatmentsData.userTreatments.forEach(t => {
        if (!this.state.selectedTreatments.includes(t.type) && t.active) {
          existingTreatments.push(t.type);
        }
      });
      this.setState({
        selectedTreatments: existingTreatments,
      });
    }
  }

  componentDidMount() {
    analytics().setCurrentScreen('profile_open');
    
    const { location } = this.props;

    const settingsSection = location?.props?.settingsSection ?? 'UserInformation';

    this.setState({settingsSection});
    window.scrollTo(0, 0);
    let activeTreatments;
    let treatmentTracker = [];
    //hydrate settings store
    activeTreatments = this.props.me.profile.userTreatments.filter(
      t => t.active,
    );
    let data = activeTreatments.map(t => {
      if (!treatmentTracker.includes(t.type)) {
        treatmentTracker.push(t.type);
      }

      return {
        id: t.id,
        name: t.name,
        type: t.type,
        active: t.active,
      };
    });
    return this.props
      .hydrateTreatmentStore({
        variables: {
          data: data,
        },
      })
      .then(() => {
        return this.setState({
          selectedTreatments: treatmentTracker,
        });
      });
  }

  getBody() {
    
    const { settingsSection } = this.state;
    
    switch (settingsSection) {
      case 'UserInformation':
          return <UserInformation username={this.props.me.name} email={this.props.me.email}></UserInformation>
      case 'AccountInformation':
          return <AccountInformation 
            eczema_relation={this.props.me.profile.eczema_relation} 
            gender={this.props.me.profile.gender} 
            birth_year={this.props.me.profile.birth_year} 
            ethnicity={this.props.me.profile.ethnicity} 
            ethnicity_specified={this.props.me.profile.ethnicity_specified} 
            feelings={this.props.me.profile.feelings} 
            nea_familiarity={this.props.me.profile.nea_familiarity} 
            was_referred={this.props.me.profile.was_referred}           
          />
      case 'ZipCode':
          return <ChangeZipCode
              zip={this.props.me.profile.zip}
              timezone={this.props.me.profile.timezone}
              newZipCode={this.state.newZipCode}
              updateInput={this._updateInput.bind(this, 'newZipCode')}
              updateZipCode={this._updateZipCode.bind(this)}
              clearInput={()=>{this.setState({newZipCode:''})}}
            />    
      case 'EditTreatments':
          return (
              <EditTreatments
                treatments={
                  this.props.userTreatmentsData
                    ? this.props.userTreatmentsData.userTreatments
                    : []
                }
                selectedTreatments={this.state.selectedTreatments}
                updateTreatments={this.updateTreatments.bind(this)} //the server call to update treatments
                addTreatmentToggle={this.editTreatments.bind(this)} //this toggles to write state
                editFlag={this.state.editTreatments} //flag for write state
                addNameToTreatment={this.addUserTreatment.bind(this)} //adds name/treatment to store
                deleteTreatment={this.deleteUserTreatment.bind(this)} //"delete" treatment from cache (if it is an existing treatment, set active to false)
                resetTreatmentList={this.resetTreatmentList.bind(this)}
              />)
      case 'Password':
        return <ChangePassword changePassword={this.props.changePassword} />
      case 'DeleteAccount':
        return <DeleteAccount deleteUser={this.props.deleteUser} />
      default:
          return <UserInformation username={this.props.me.name} email={this.props.me.email}></UserInformation>
    }
  }

  updateSection(section) {
    this.setState({ settingsSection: section });
    
    if(this.props?.location?.props)
    {
      const { updateSettingsSection } = this.props.location.props;
      updateSettingsSection && updateSettingsSection(section);
    }
    
  }

  render() {
    const { settingsSection } = this.state;

    return (
      <div className="main-dashboard page-settings">
        <header>
          <div className="date-bar">
            <div className="main-dashboard__sidebar">
              <div className="sidebar-title">
                <Unicons.UilUser size="18" />
                <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                  MY ACCOUNT
                </h1>
              </div>
            </div>
          </div>
        </header>
        <div className="main-dashboard__content card-container">
          <div className="card-left" >
            <span className={`option`} >Profile</span>
            <OptionElement 
              icon={<Unicons.UilUserCircle size="24" style={{marginRight:'10px'}}/>}
              text={'User information'} 
              className={`option ${settingsSection === 'UserInformation' ? 'active' : '' }`} 
              onClick={() => this.updateSection('UserInformation')} 
            />
            <OptionElement 
              icon={<Unicons.UilFileBookmarkAlt size="24" style={{marginRight:'10px'}}/>}
              text={'Account information'} 
              className={`option ${settingsSection === 'AccountInformation' ? 'active' : '' }`} 
              onClick={() => this.updateSection('AccountInformation')} 
            />
            <OptionElement 
              icon={<Unicons.UilLocationPinAlt size="24" style={{marginRight:'10px'}}/>}
              text={'Zip code'} 
              className={`option ${settingsSection === 'ZipCode' ? 'active' : '' }`} 
              onClick={() => this.updateSection('ZipCode')} 
            />
            <hr/>
            <span className={`option`} >Settings</span>
            <OptionElement 
              icon={<Unicons.UilHeartMedical size="24" style={{marginRight:'10px'}}/>}
              text={'Edit your Treatments'} 
              className={`option ${settingsSection === 'EditTreatments' ? 'active' : '' }`} 
              onClick={() => this.updateSection('EditTreatments')} 
            />
            <OptionElement 
              icon={<Unicons.UilPadlock size="24" style={{marginRight:'10px'}}/>}
              text={'Change your Password'} 
              className={`option ${settingsSection === 'Password' ? 'active' : '' }`} 
              onClick={() => this.updateSection('Password')} 
            />
            <OptionElement 
              icon={<Unicons.UilLockAccess size="24" style={{marginRight:'10px'}}/>}
              text={'Delete your account'} 
              className={`option ${settingsSection === 'DeleteAccount' ? 'active' : '' }`} 
              onClick={() => this.updateSection('DeleteAccount')} 
            />
          </div>
          <div className="card-right">
              {this.getBody()}
          </div>
        </div>
      </div>
    );
  }

  _updateInput(name, e) {
    let newState = {};
    newState[name] = e.target.value;

    this.setState(newState);
  }

  _updateZipCode(e) {
    e.preventDefault();
    return this.props
      .updateUserZip({
        variables: {
          profileid: this.props.me.profile.id,
          zip: this.state.newZipCode,
        },
        refetchQueries: () => [
          {
            query: Me,
            options: {
              fetchPolicy: 'network-only',
            },
          },
          {
            query: DailyUserData,
            variables: {
              id: this.props.me.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
            },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then(() => {
        MixpanelService.track('ZipCodeChanged', {
          ZipCode: this.state.newZipCode,
        });
        MixpanelService.registerSuperProps({ ZipCode: this.state.newZipCode });
        MixpanelService.setProfileProperty('ZipCode', this.state.newZipCode);
        analytics().logEvent('update_profile_zip', {});

        this.setState({
          newZipCode: '',
        });
      });
  }

  editTreatments() {
    let treatments, profileid;
    let currentState = this.state.editTreatments;
    if (this.state.editTreatments) {
      profileid = this.props.me.profile.id;
      treatments = this.props.userTreatmentsData.userTreatments.map(trt => {
        return {
          profileid: profileid,
          type: trt.type,
          name: trt.name,
          active: trt.active,
          id: trt.id,
        };
      });

      return this.props
        .updateUserTreatments({
          variables: {
            treatments: treatments,
          },
          refetchQueries: () => [
            {
              query: Me,
              options: {
                fetchPolicy: 'network-only',
              },
            },
            {
              query: DailyUserData,
              variables: {
                id: this.props.me.id,
                today: this.props.date || moment().format('YYYY-MM-DD'),
              },
              fetchPolicy: 'network-only',
            },
          ],
        })
        .then(() => {
          const TopicalAdded = [];
          let PhototherapyAdded = false;
          const SystemicsAdded = [];
          const BiologicAdded = [];
          const OverTheCounterAdded = [];
          const OtherAdded = [];

          this.props.userTreatmentsData.userTreatments.forEach(trt => {
            if (!trt.active) return;
            switch (trt.type) {
              case 'Phototherapy':
                PhototherapyAdded = true;
                break;
              case 'Topicals':
                TopicalAdded.push(trt.name);
                break;
              case 'Biologic':
                BiologicAdded.push(trt.name);
                break;
              case 'OTC':
                OverTheCounterAdded.push(trt.name);
                break;
              case 'Other':
                OtherAdded.push(trt.name);
                break;
              case 'Systemics':
                SystemicsAdded.push(trt.name);
                break;
              default:
                break;
            }
          });

          MixpanelService.track('EditTreatments', {
            TopicalAdded,
            PhototherapyAdded,
            SystemicsAdded,
            BiologicAdded,
            OverTheCounterAdded,
            OtherAdded,
          });

          const treatmentCategories = profileActiveTreatmentCategories(
            this.props.userTreatmentsData.userTreatments,
          );
          MixpanelService.registerSuperProps(treatmentCategories);

          analytics().logEvent('update_profile', {});
          this.setState({
            editTreatments: !currentState,
          });
        });
    } else {
      this.setState(
        {
          editTreatments: !currentState,
        },
        () => {
          analytics().logEvent('update_profile', {});
        },
      );
    }
  }

  updateTreatments(treatment, e) {
    let newSelectedTreatments;
    let existingSelectedTreatments = this.state.selectedTreatments;
    if (existingSelectedTreatments.includes(treatment)) {
      //this is a deselect
      newSelectedTreatments = existingSelectedTreatments.filter(
        t => t !== treatment,
      );
      //need to handle deselect in store
      this.props.bulkDeleteTreatments({
        variables: {
          type: treatment,
        },
      });
    } else {
      newSelectedTreatments = existingSelectedTreatments;
      newSelectedTreatments.push(treatment);
      if (treatment === 'Phototherapy') {
        this.addUserTreatment(treatment, treatment);
      }
    }
    this.setState({
      selectedTreatments: newSelectedTreatments,
    });
  }

  addUserTreatment(treatment, name) {
    this.props.addUserTreatment({
      variables: {
        treatment,
        name,
      },
    });
  }

  deleteUserTreatment(id) {
    this.props.deleteUserTreatment({
      variables: {
        id,
      },
    });
  }

  resetTreatmentList(e) {
    e.preventDefault();
    this.props.resetUserTreatmentList({
      variables: {},
    });
    this.setState({
      editTreatments: false,
    });
  }
}

export default compose(
  withUserZipMutation,
  withUpdateUserTreatmentsMutation,
  withChangePasswordMutation,
  withDeleteUserMutation,
  withSettings,
  withRouter,
)(Settings);
