import React, { useState } from 'react';

const MetaEditor = ({
  id,
  cost,
  effects,
  efficacy,
  how,
  use,
  who,
  onSubmit,
  metadata,
  classes,
  groups,
  onCancel,
}) => {
  const [editing, setEditing] = useState(id === null);

  const original = {
    id,
    cost,
    effects,
    efficacy,
    how,
    use,
    who,
    id,
  };

  const [localValues, setNewValue] = useState({
    id,
    cost,
    effects,
    efficacy,
    how,
    use,
    who,
    id,
  });

  const [edited, hasBeenEdited] = useState(false);

  const updateValue = e => {
    const newVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    hasBeenEdited(true);
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  if (!editing) {
    return (
      <div
        className={'card card__active treatment-tile editing'}
        onClick={e => setEditing(true)}>
        <h1>{localValues.id}</h1>
        <h2>{how || ''}</h2>
      </div>
    );
  }

  return (
    <div className={'card card__active treatment-tile'}>
      <h2>{localValues.id || ''}</h2>
      <ul>
        <li>
          <label>Cost</label>
          <input
            type="number"
            value={localValues.cost || 0}
            name={'cost'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Effects</label>
          <textarea
            value={localValues.effects || ''}
            name={'effects'}
            onChange={updateValue}
            rows={3}
          />
        </li>
        <li>
          <label>Efficacy</label>
          <input
            type="text"
            value={localValues.efficacy || ''}
            name={'efficacy'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>How</label>
          <textarea
            value={localValues.how || ''}
            name={'how'}
            onChange={updateValue}
            rows={6}
          />
        </li>
        <li>
          <label>Who</label>
          <input
            type="text"
            value={localValues.who || ''}
            name={'who'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Use</label>
          <textarea
            value={localValues.use || ''}
            name={'use'}
            onChange={updateValue}
            rows={3}
          />
        </li>
      </ul>
      {editing && (
        <div className={'treatment_actions'}>
          <div className="stretch"></div>
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              setNewValue(original);
              setEditing(false);
              onCancel();
            }}>
            Cancel
          </button>
          <button
            disabled={!edited}
            className={'button button-regular button__green'}
            onClick={e => {
              onSubmit({ ...localValues, cost: +localValues.cost });
              setEditing(false);
            }}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default MetaEditor;
