import React from 'react';

export const renderSuggestion = (suggestion, icon) => (
  <div>{suggestion.name}</div>
);

export const getSuggestions = (value, suggestions) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const ret =
    inputLength === 0
      ? []
      : suggestions.filter(
          suggestion =>
            suggestion.name.trim().toLowerCase().slice(0, inputLength) ===
            inputValue,
        );

  return ret.slice(0, 6);
};

export const getSuggestionValue = suggestion => {
  return suggestion.name;
};
