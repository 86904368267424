import analytics from '../../libs/analytics';
import { Link } from 'react-router-dom';
import AppleStore from '../../assets/images/home/app-store.svg';
import GoogleStore from '../../assets/images/home/google-play.svg';
import ArrowRight from '../../assets/icons/arrow-right-white.svg';

function PlatformOptions() {
  return (
    <div>
      <div className="try-button-container">
        <Link className="try-button" to="/signup/registration">
          <img src={ArrowRight} alt="" />
          <span>Try web now</span>
        </Link>
      </div>
      <div className="app-stores-wrapper">
        <a
          className="store-button"
          onClick={e => {
            analytics().logEvent('ios_dl_page_from_home', {});
          }}
          href="https://apps.apple.com/us/app/id1522743215"
          target="_blank"
          rel="noreferrer">
          <img src={AppleStore} alt="Download on the Apple Store" />
        </a>
        <a
          className="store-button"
          onClick={e => {
            analytics().logEvent('android_dl_page_from_home', {});
          }}
          href="https://play.google.com/store/apps/details?id=com.eczemawiseapp"
          target="_blank"
          rel="noreferrer">
          <img src={GoogleStore} alt="Get it on Google Play" />
        </a>
      </div>
    </div>
  );
}

export default PlatformOptions;
