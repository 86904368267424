import React from 'react';

const PoscoradIndicator = ({ percentage }) => {
  
  const fillPath = percentage <= 25 ? '#FF9672' : percentage <= 50  ? '#E95D42' : '#B33D26'; 

  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.79289 7.79289L1.70711 2.70711C1.07714 2.07714 1.52331 1 2.41421 1H12.5858C13.4767 1 13.9229 2.07714 13.2929 2.70711L8.20711 7.79289C7.81658 8.18342 7.18342 8.18342 6.79289 7.79289Z"
        fill={fillPath}
        stroke="white"
      />
    </svg>
  );
};

export default PoscoradIndicator;
