import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { UilEnvelopeUpload } from '@iconscout/react-unicons';
import { EmailFormIcon } from '../../assets/icons';

const Confirmation = ({ history }) => {
  const focusElement = React.useRef(null);

  React.useEffect(() => {
    if (focusElement.current) focusElement.current.focus();
  }, []);

  const notification = () => {
    return (
      <Fragment>
        <div className="login-form form">
          <div className="form-item">
            <span className="paragraph login-link login-link-info-text text__centered">
              Please check your email for a link to verify your account.
            </span>
          </div>
          <br/>
          <div className="form-item">
            <button
              className="button button__secondary100 button-rectangle-large"
              onClick={e => {
                e.preventDefault();
                history.push({
                  pathname: '/login',
                  state: {
                    email: history.location.state
                      ? history.location.state.email
                      : '',
                  },
                });
              }}>
              Back to login
            </button>
          </div>
          <br/>
          <div className="form-item">
            <span className="login-link">
              Not seeing an email in your inbox? Go to{' '}
              <Link
                className="link link__secondary-100"
                to="/help">
                Help
              </Link>
            </span>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className="login-card login-registration">
      <div className="icon-background-container">
        <EmailFormIcon />
      </div>
      <div className="login-title">
        <h2 className="login-title-text login-title-text-16p title__black100">
          Verify your email
        </h2>
      </div>
      {notification()}
    </div>
  );
};

export default Confirmation;
