import React from 'react';

export default function BackCircleArrow() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="16.0711" cy="16" rx="15.9644" ry="16" fill="#E7EAF1" />
      <path
        d="M15.3627 15.9999L18.8949 12.4599C19.0807 12.2726 19.185 12.0191 19.185 11.7549C19.185 11.4908 19.0807 11.2373 18.8949 11.0499C18.8021 10.9562 18.6918 10.8818 18.5702 10.8311C18.4486 10.7803 18.3182 10.7542 18.1864 10.7542C18.0547 10.7542 17.9243 10.7803 17.8027 10.8311C17.6811 10.8818 17.5708 10.9562 17.478 11.0499L13.2474 15.2899C13.1539 15.3829 13.0797 15.4935 13.029 15.6154C12.9784 15.7372 12.9523 15.8679 12.9523 15.9999C12.9523 16.132 12.9784 16.2627 13.029 16.3845C13.0797 16.5064 13.1539 16.617 13.2474 16.7099L17.478 20.9999C17.5713 21.0926 17.6818 21.166 17.8034 21.2157C17.925 21.2655 18.0551 21.2907 18.1864 21.2899C18.3178 21.2907 18.4479 21.2655 18.5695 21.2157C18.6911 21.166 18.8016 21.0926 18.8949 20.9999C19.0807 20.8126 19.185 20.5591 19.185 20.2949C19.185 20.0308 19.0807 19.7773 18.8949 19.5899L15.3627 15.9999Z"
        fill="#1D62ED"
      />
    </svg>
  );
}
