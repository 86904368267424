import React from 'react';
import { Route } from 'react-router-dom';
import TrackersContainer from './TrackersContainer';

const Dashboard = ({ me, ...props }) => {
  return (
    <div className="main-dashboard">
      <div className="main-dashboard__content">
        <Route
          path="/dashboard/:focusedTracker?"
          render={props => {
            return (
              <TrackersContainer
                selectedDate={
                  props.location.state
                    ? props.location.state.selectedDate
                    : null
                }
                me={me}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default Dashboard;
