import { debounce, flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Fragment, useState } from 'react';
import { graphql } from 'react-apollo';
import ReactTable from 'react-table';
import {
  adminBlockUser,
  adminDeleteUser,
  adminToggleAdminUser,
  adminUpdateUserRole,
  forgotPassword,
  adminSendNotification,
} from '../../libs/graphql/mutations/user';
import { Users } from '../../libs/graphql/queries/admin';
import { Roles } from '../../libs/graphql/queries/admin';

const withUsers = graphql(Users, {
  props: ({ data: { loading, error, adminUsers } }) => ({
    loading,
    error,
    adminUsers,
  }),
});

const withRoles = graphql(Roles, {
  props: ({ data: { loading, error, adminRoles } }) => ({
    loading,
    error,
    adminRoles,
  }),
});

const withDeleteUserMutation = graphql(adminDeleteUser, {
  name: 'adminDeleteUser',
});
const withBlockUserMutation = graphql(adminBlockUser, {
  name: 'adminBlockUser',
});
const withAdminToggleAdminUser = graphql(adminToggleAdminUser, {
  name: 'adminToggleAdminUser',
});
const withAdminUpdateUserRole = graphql(adminUpdateUserRole, {
  name: 'adminUpdateUserRole',
});
const withAdminSendNotification = graphql(adminSendNotification, {
  name: 'adminSendNotification',
});

const withForgotPasswordMutation = graphql(forgotPassword, {
  name: 'forgotPassword',
});

const AdminUsers = ({
  loading,
  error,
  adminUsers,
  adminRoles,
  me,
  adminDeleteUser,
  adminBlockUser,
  forgotPassword,
  adminToggleAdminUser,
  adminUpdateUserRole,
  adminSendNotification,
}) => {
  const [userSearch, setUserSearch] = useState('');
  const [_userSearch, _setUserSearch] = useState('');

  if (error || loading || !adminUsers || !adminRoles) return null;

  return (
    <Fragment>
      <input
        className={'user-search'}
        placeholder={'Search'}
        type="search"
        value={userSearch}
        onChange={e => {
          const val = e.target.value;
          setUserSearch(val);
          debounce(() => {
            _setUserSearch(val);
          }, 600)();
        }}
      />
      <ReactTable
        data={adminUsers.filter(
          user =>
            !_userSearch ||
            user.email.includes(_userSearch) ||
            user.name.includes(_userSearch),
        )}
        className="-striped -highlight"
        defaultPageSize={20}
        columns={[
          {
            id: 'id',
            Header: 'ID',
            accessor: d => d.id,
            sortMethod: (a, b) => Number(a) - Number(b),
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: d => {
              if (!d.active) return '--';
              return `${d.id === me.id ? '👤 ' : ''}${d.name}`;
            },
          },
          {
            id: 'email',
            Header: 'Email',
            accessor: d => {
              if (!d.active) return '--';
              return d.email;
            },
          },
          {
            id: 'persona_slug',
            Header: 'Persona',
            accessor: d => d.profile.persona_slug,
          },
          {
            id: 'zip',
            Header: 'Zip',
            accessor: d => d.profile.zip,
          },
          {
            id: 'timezone',
            Header: 'Timezone',
            accessor: d => d.profile.timezone,
          },
          {
            id: 'notification_count',
            Header: '📬',
            Cell: ({ original }) => (
              <button
                onClick={e => {
                  if (window.confirm(`Send the user a test notification?`))
                    adminSendNotification({
                      variables: {
                        userid: original.id,
                      },
                      refetchQueries: () => [
                        {
                          query: Users,
                          options: {
                            fetchPolicy: 'network-only',
                          },
                        },
                      ],
                    })
                      .then(() => {
                        console.log('Sent!');
                      })
                      .catch(e => {
                        console.log(e);
                      });
                }}>
                {original.notification_count || 0}
              </button>
            ),
          },
          {
            id: 'created_date',
            Header: 'Joined',
            accessor: d => moment(d.created_date).fromNow(),
          },
          {
            Header: 'Blocked',
            Cell: ({ original }) => (
              <input
                checked={original.blocked}
                type="checkbox"
                disabled={original.id === me.id || !original.active}
                onChange={e => {
                  adminBlockUser({
                    variables: {
                      userid: original.id,
                      newStatus: !original.blocked,
                    },
                    refetchQueries: () => [
                      {
                        query: Users,
                        options: {
                          fetchPolicy: 'network-only',
                        },
                      },
                    ],
                  })
                    .then(() => {
                      console.log('Blocked user!');
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }}
              />
            ),
          },
          {
            Header: 'Role',
            Cell: ({ original }) => (
              <select
                value={original.role_id}
                disabled={original.id === me.id || !original.active}
                onChange={e => {
                  const selectedRole = e.target.selectedOptions[0].text;
                  if (
                    window.confirm(
                      `This will change the role for ${original.email} to "${selectedRole}". Are you sure you want to do this?`,
                    )
                  ) {
                    adminUpdateUserRole({
                      variables: {
                        userid: original.id,
                        newRoleId: +e.target.value,
                      },
                      refetchQueries: () => [
                        {
                          query: Users,
                          options: {
                            fetchPolicy: 'network-only',
                          },
                        },
                      ],
                    })
                      .then(() => {
                        console.log('Role changed!');
                      })
                      .catch(e => {
                        console.log(e);
                      });
                  }
                }}>
                {adminRoles.map(role => (
                  <option
                    value={role.id}
                    key={role.id}
                    title={role.description}>
                    {role.name}
                  </option>
                ))}
              </select>
            ),
          },
          {
            id: 'admin',
            Header: 'Admin',
            Cell: ({ original }) => (
              <input
                checked={original.admin}
                type="checkbox"
                disabled
                onChange={e => {
                  if (
                    window.confirm(
                      `Checking this box will make ${original.email} a${
                        original.admin ? ' non-' : 'n '
                      }admin account. Are you sure you want to do this?`,
                    )
                  )
                    adminToggleAdminUser({
                      variables: {
                        userid: original.id,
                        newStatus: !original.admin,
                      },
                      refetchQueries: () => [
                        {
                          query: Users,
                          options: {
                            fetchPolicy: 'network-only',
                          },
                        },
                      ],
                    })
                      .then(() => {
                        console.log('Admined user!');
                      })
                      .catch(e => {
                        console.log(e);
                      });
                }}
              />
            ),
          },

          {
            Header: 'Active',
            Cell: ({ original }) => (
              <input
                checked={original.active}
                type="checkbox"
                disabled={!original.active || original.id === me.id}
                onChange={e => {
                  if (
                    window.confirm(
                      'Unchecking this box will permanently delete this user, are you sure you want to do this?',
                    )
                  )
                    adminDeleteUser({
                      variables: {
                        userid: original.id,
                      },
                      refetchQueries: () => [
                        {
                          query: Users,
                          options: {
                            fetchPolicy: 'network-only',
                          },
                        },
                      ],
                    })
                      .then(() => {
                        console.log('Deleted user!');
                      })
                      .catch(e => {
                        console.log(e);
                      });
                }}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};

export default compose(
  withUsers,
  withRoles,
  withDeleteUserMutation,
  withBlockUserMutation,
  withForgotPasswordMutation,
  withAdminToggleAdminUser,
  withAdminUpdateUserRole,
  withAdminSendNotification,
)(AdminUsers);
