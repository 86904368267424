import { useRef } from 'react';
import Hero from '../../assets/images/home/ew-set.png';
import ECWUser from '../../assets/images/newBrandImages/ECWUser.svg'
import { Carousel } from 'react-responsive-carousel';
import { useIsVisible } from '../shared/hooks/useIsVisible';

const data = [
  {
    testimonial:
      'EczemaWise helps patients understand and communicate a much broader story of their disease, which helps us tailor their treatment plan accordingly.',
    user: 'Dr. Lawrence Eichenfield, MD, FAAD, FAAP and Dr. Eric Simpson, MD, MCR',
  },
  {
    testimonial:
      'EczemaWise has most information compiled in one location instead of surfing the web for details. Really like the tracking of symptoms for future reference.',
    user: 'EczemaWise user',
  },
  {
    testimonial:
      'This app is just what I’ve needed for years! It’s so hard to remember what I ate or did that causes my eczema to flare.',
    user: 'EczemaWise user',
  },
  {
    testimonial:
      'It helps me log and track my eczema flares and triggers so that I can manage my eczema better.',
    user: 'EczemaWise user',
  },
];

function Testimonials() {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  return (
    <section className="home-page__testimonials-section">
      <div className="content-wrapper">
        <div className="image-section" ref={ref}>
          <img className={`image__user ${isVisible ? 'visible' : ''}`} src={ECWUser} alt="" />
        </div>
        <div className="content-section">
          <p className="paragraph">
            EczemaWise helps you record your skin health by tracking your eczema
            symptoms and triggers when you’re flaring — or not flaring —
            including, skin, itch, pain, sleep, stress, treatments, triggers,
            diet and weather. Keeping a record of your symptoms and possible
            eczema triggers can help you and your doctor better understand your
            condition.
          </p>
          <div class="carousel-container">
            <Carousel
              showArrows={false}
              swipeable={true}
              autoPlay={true}
              interval={5000}
              infiniteLoop={true}
              showStatus={false}
              dynamicHeight={false}>
              {data.map((item, index) => (
                <div className="testimonial" key={index}>
                  <div className="testimonial-quote"></div>
                  <div className="testimonial-content">
                    <p className="testimonial-text">{item.testimonial}</p>
                    <p className="testimonial-user">— {item.user}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
