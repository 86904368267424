import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dailyUserData: [],
  loading: false,
  error: null,
};

const dailyUserDataSlice = createSlice({
  name: 'dailyUserData',
  initialState,
  reducers: {
    setDietTracker(state, action) {
      state.dailyUserData = action.payload;
    },
    updateDietTracker(state, action) {
      state.dailyUserData = state.dailyUserData.map(dus => {
        if (dus.id === action.payload.id) {
          return {
            ...dus,
            ...action.payload,
          };
        }
        return dus;
      });
    },
    deleteDietTracker(state, action) {
      state.dailyUserData = state.dailyUserData.filter(
        dus => dus.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const dailyUserDataActions = dailyUserDataSlice.actions;

export default dailyUserDataSlice.reducer;
