import React from 'react';

export default function TriggersBlueIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4297 9.35625C14.4032 9.33345 14.3634 9.33398 14.3375 9.35731L10.8171 12.5999C10.7964 12.619 10.789 12.6476 10.7985 12.6741C10.8075 12.7006 10.8319 12.7181 10.86 12.7202L12.3732 12.8109L10.5308 15.4649C10.5106 15.4941 10.5159 15.5333 10.5425 15.5567C10.5552 15.5683 10.5716 15.5736 10.5881 15.5736C10.6045 15.5736 10.6215 15.5673 10.6347 15.5551L14.1545 12.313C14.1752 12.2945 14.1826 12.2653 14.1731 12.2388C14.1641 12.2128 14.1397 12.1948 14.1121 12.1932L12.599 12.1026L14.4408 9.44797C14.4615 9.41934 14.4562 9.37958 14.4297 9.35625Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00119 9.67654C9.00119 10.265 8.52265 10.7435 7.93421 10.7435C7.34577 10.7435 6.86722 10.265 6.86722 9.67654C6.86722 9.0881 7.34577 8.60956 7.93421 8.60956C8.52265 8.60956 9.00119 9.0881 9.00119 9.67654ZM12.7356 9.67654C12.7356 10.265 12.2571 10.7435 11.6687 10.7435C11.0802 10.7435 10.6017 10.265 10.6017 9.67654C10.6017 9.0881 11.0802 8.60956 11.6687 8.60956C12.2571 8.60956 12.7356 9.0881 12.7356 9.67654ZM9.80142 4.875C7.15424 4.875 5 7.02924 5 9.67642V15.2477C5 15.3005 5.03094 15.348 5.07842 15.3693C5.12697 15.3906 5.18299 15.3826 5.22193 15.3474L6.20035 14.4778L7.31215 15.4664C7.36283 15.5112 7.43859 15.5112 7.48927 15.4664L8.60106 14.4778L9.71286 15.4664C9.76354 15.5112 9.83929 15.5112 9.88998 15.4664L11.0018 14.4778L12.1136 15.4664C12.1386 15.4888 12.1701 15.5 12.2021 15.5C12.2341 15.5 12.2656 15.4888 12.2907 15.4664L13.4025 14.4778L14.3809 15.3474C14.4198 15.3826 14.4759 15.3906 14.5244 15.3693C14.5719 15.348 14.6028 15.3005 14.6028 15.2477V9.67642C14.6028 7.02924 12.4486 4.875 9.80142 4.875Z"
        fill="white"
      />
    </svg>
  );
}
