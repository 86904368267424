import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  triggers: [],
  loading: false,
  error: null,
};

const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    setTriggers(state, action) {
      state.triggers = action.payload;
    },
    updateTriggers(state, action) {
      state.triggers = state.triggers.map(trigger => {
        if (trigger.id === action.payload.id) {
          return {
            ...trigger,
            ...action.payload,
          };
        }
        return trigger;
      });
    },
    deleteTrigger(state, action) {
      state.triggers = state.triggers.filter(
        trigger => trigger.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const triggersActions = triggersSlice.actions;

export default triggersSlice.reducer;
