import React from 'react';

export default function Eye() {
  return (
    <svg className="svg-eye" width="20px" height="20px" viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(3.000000, 3.000000)">
          <g transform="translate(0.333333, 0.333333)">
            <g
              className="svg-eye__eye-shape"
              transform="translate(0.000000, 2.500000)">
              <path
                className="svg-eye__cross-line"
                d="M8.39212697,0.0 L4.54120637,9.1061722"
                strokeLinecap="round"
                transform="translate(.2, -.4)"
              />
              <path d="M6.66666667,7.5 C2.5,7.5 1.0025,4.16666667 1.0025,4.16666667 C1.0025,4.16666667 2.5,0.833333333 6.66666667,0.833333333 C10.8333333,0.833333333 12.3308333,4.16666667 12.3308333,4.16666667 C12.3308333,4.16666667 10.8333333,7.5 6.66666667,7.5 M6.66666667,0 C1.66666667,0 0,4.16666667 0,4.16666667 C0,4.16666667 1.66666667,8.33333333 6.66666667,8.33333333 C11.6666667,8.33333333 13.3333333,4.16666667 13.3333333,4.16666667 C13.3333333,4.16666667 11.6666667,0 6.66666667,0" />
              <path d="M6.66666667,5.83333333 C5.74666667,5.83333333 5,5.08666667 5,4.16666667 C5,3.24666667 5.74666667,2.5 6.66666667,2.5 C7.58666667,2.5 8.33333333,3.24666667 8.33333333,4.16666667 C8.33333333,5.08666667 7.58666667,5.83333333 6.66666667,5.83333333 M6.66666667,1.66666667 C5.28666667,1.66666667 4.16666667,2.78666667 4.16666667,4.16666667 C4.16666667,5.54666667 5.28666667,6.66666667 6.66666667,6.66666667 C8.04666667,6.66666667 9.16666667,5.54666667 9.16666667,4.16666667 C9.16666667,2.78666667 8.04666667,1.66666667 6.66666667,1.66666667" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
