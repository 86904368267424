import React, { useState } from 'react';
import SelectDropDown from './SelectDropDown';

const ClassEditor = ({
  id,
  formulation,
  treatment_class,
  treatment_group_id,
  onSubmit,
  groups,
  onCancel,
}) => {
  formulation = formulation || '';
  treatment_group_id = treatment_group_id || groups[0].id;

  const [editing, setEditing] = useState(id === null);

  const original = {
    id,
    formulation,
    treatment_class,
    treatment_group_id,
  };

  const [localValues, setNewValue] = useState({
    id,
    formulation,
    treatment_class,
    treatment_group_id,
  });

  const [edited, hasBeenEdited] = useState(false);

  const updateValue = e => {
    const newVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    hasBeenEdited(true);
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  const title = `${localValues.treatment_class}${
    localValues.formulation ? ` (${localValues.formulation})` : ''
  }`;

  if (!editing) {
    return (
      <div
        className={'card card__active treatment-tile editing'}
        onClick={e => setEditing(true)}>
        <h2>{title || ''}</h2>
      </div>
    );
  }

  return (
    <div className={'card card__active treatment-tile'}>
      <h2>{title || ''}</h2>
      <ul>
        <li>
          <label>Class Name</label>
          <input
            type={'text'}
            value={localValues.treatment_class || ''}
            name={'treatment_class'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Formulation</label>
          <input
            type={'text'}
            value={localValues.formulation}
            name={'formulation'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Group</label>
          <SelectDropDown
            options={groups}
            defineLabel={option => option.treatment_group}
            value={localValues.treatment_group_id}
            onChange={val => {
              updateValue({
                target: {
                  type: 'text',
                  name: 'treatment_group_id',
                  value: val.value,
                },
              });
            }}
          />
        </li>
      </ul>
      {editing && (
        <div className={'treatment_actions'}>
          <div className="stretch"></div>
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              setNewValue(original);
              setEditing(false);
              onCancel();
            }}>
            Cancel
          </button>
          <button
            disabled={!edited}
            className={'button button-regular button__green'}
            onClick={e => {
              onSubmit(localValues);
              setEditing(false);
            }}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassEditor;
