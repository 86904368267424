import React from 'react';

export default function SleepTrendsIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#F8BED6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.99994 10.5C8.99994 8.362 10.6724 6.607 12.8074 6.505C12.8339 6.5035 12.8569 6.4855 12.8644 6.46C12.8719 6.434 12.8619 6.4065 12.8399 6.3915C12.0004 5.808 11.0184 5.5 9.99994 5.5C7.24294 5.5 4.99994 7.743 4.99994 10.5C4.99994 13.257 7.24294 15.5 9.99994 15.5C11.0184 15.5 12.0004 15.192 12.8399 14.6085C12.8619 14.5935 12.8719 14.566 12.8644 14.54C12.8569 14.5145 12.8339 14.4965 12.8074 14.495C10.6724 14.393 8.99994 12.638 8.99994 10.5Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9374 11.3252H13.6874L14.9559 9.65424C14.9639 9.64324 14.9684 9.62974 14.9684 9.61624V9.06274C14.9684 9.02824 14.9404 9.00024 14.9059 9.00024H12.7324C12.6979 9.00024 12.6699 9.02824 12.6699 9.06274V9.61224C12.6699 9.64674 12.6979 9.67474 12.7324 9.67474H13.9464L12.6829 11.3462C12.6744 11.3567 12.6699 11.3702 12.6699 11.3837V11.9377C12.6699 11.9717 12.6979 12.0002 12.7324 12.0002H14.9374C14.9719 12.0002 14.9999 11.9717 14.9999 11.9377V11.3877C14.9999 11.3532 14.9719 11.3252 14.9374 11.3252Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2499 11.0689V11.4034C10.2499 11.4379 10.2779 11.4659 10.3124 11.4659H11.6874C11.7219 11.4659 11.7499 11.4379 11.7499 11.4034V11.0939C11.7499 11.0594 11.7219 11.0314 11.6874 11.0314H10.9059L11.7169 9.96892C11.7254 9.95842 11.7294 9.94492 11.7294 9.93142V9.59692C11.7294 9.56242 11.7014 9.53442 11.6669 9.53442H10.3124C10.2779 9.53442 10.2499 9.56242 10.2499 9.59692V9.90642C10.2499 9.94092 10.2779 9.96892 10.3124 9.96892H11.0709L10.2624 11.0309C10.2544 11.0419 10.2499 11.0554 10.2499 11.0689Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
