import gql from 'graphql-tag';

/**
 * uploadImages(userid: ID!, files: [Upload]): [Image],
 */
export const UPLOAD_IMAGES = gql`
  mutation uploadImages($userid: ID!, $files: [Upload]) {
    uploadImages(userid: $userid, files: $files) {
      id
      userid
      filename
      mimetype
      encoding
      uri
    }
  }
`;

export const updateImage = gql`
  mutation updateImage($imageid: ID!, $userid: ID!, $file: Upload!) {
    updateImage(imageid: $imageid, userid: $userid, file: $file) {
      id
      userid
      filename
      mimetype
      encoding
      uri
    }
  }
`;

export const updateFavoritesImages = gql`
  mutation updateFavoritesImages($userid: ID!, $imagesID: [ID]!, $asFavorites:Boolean) {
    updateFavoritesImages(userid: $userid, imagesID: $imagesID, asFavorites: $asFavorites) {
      id
    }
  }
`;

export const assignImagesToSkinTracker = gql`
  mutation assignImagesToSkinTracker($payload: AssignImagesToSkinTrackerInput!) {
    assignImagesToSkinTracker(payload: $payload) {
      id
      userid
      filename
      mimetype
      encoding
      uri
    }
  }
`;


export const deleteImages = gql`
  mutation deleteImages($userid:ID! $imagesIds:[ID]!) {
    deleteImages(userid:$userid imagesIds:$imagesIds) 
  }
`;

export const assignImagesToTrackingData = gql`
  mutation AssignImagesToTrackingData($payload: AssignImagesToTrackingDataInput!) {
    assignImagesToTrackingData(payload: $payload) {
      id
      userid
      filename
      mimetype
      encoding
      uri
      for_print
    }
  }
`;
