import React from 'react';

export default function OpenClose() {
  return (
    <svg
      className="svg-open-close"
      width="20px"
      height="20px"
      viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle
          className="svg-open-close__background"
          fillRule="evenodd"
          cx="10"
          cy="10"
          r="10"
        />
        <g
          className="svg-open-close__open"
          strokeLinecap="round"
          strokeWidth="1"
          transform=" translate(15,6) rotate(90.0)">
          <polyline points="3 1 7 5 3 9" />
        </g>
        <g
          className="svg-open-close__close"
          transform="translate(5.000000, 5.000000)"
          strokeLinecap="round"
          strokeWidth="1">
          <path d="M1,5 L9,5" id="Line-5" />
        </g>
      </g>
    </svg>
  );
}
