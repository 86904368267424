import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { ActionSvg } from '../../assets/icons';
import config from '../../config';
import MixpanelService, {
  MIXPANEL_DAY_FORMAT,
  MIXPANEL_TIME_FORMAT,
  MixpanelEvents
} from '../../libs/mixpanel';
import analytics from '../../libs/analytics';
import { UserAppointments } from '../../libs/graphql/queries/user';
import Input from '../shared/forms/input';
import TextArea from '../shared/forms/textarea';
import SubmitBar from '../shared/submit-bar';
import ErrorAlert from '../shared/ErrorAlert';

const maxInputLength = '36';

class AppointmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: {},
      deleteAlert: false,
      errorMessage: '',
    };
  }

  handleDateTimeChange(name, e) {
    let value = moment(e).format();
    return this.props
      .inputChangeHandler({
        variables: {
          name: name,
          value: value,
        },
      })
      .then(() => {
        this.resetErrorState(name);
      });
  }

  handleInputChange(name, e) {
    let value = e.target ? e.target.value : e.value; //handle regular inputs and react select
    if (
      (name === 'provider_zip' || name === 'provider_phone') &&
      value &&
      !/^\d+$/.test(value)
    ) {
      // zip and phone should only contain numbers in the input
      return;
    }
    return this.props
      .inputChangeHandler({
        variables: {
          name: name,
          value: value,
        },
      })
      .then(() => {
        this.resetErrorState(name);
      });
  }

  resetErrorState(name) {
    let newInvalid = this.state.invalid;
    if (this.state.invalid[name]) {
      newInvalid[name] = false;
      this.setState({
        ...this.state,
        invalid: newInvalid,
        errorMessage: ''
      });
    }
  }

  createAppointment(e) {
    e.event.preventDefault(); //this is kind of strange
    let errorState = {};
    let { userid, appointmentId } = this.props;
    let {
      date,
      time,
      notes,
      provider_first_name,
      provider_last_name,
      provider_phone,
      provider_address_1,
      provider_address_2,
      provider_city,
      provider_state,
      provider_zip,
    } = this.props.formData;

    if (!date) {
      errorState.date = true;
      this.setState({
        ...this.state,
        errorMessage: 'Date field does not contain a valid value.',
      });
    }
    if (!time) {
      errorState.time = true;
      this.setState({
        ...this.state,
        errorMessage: 'Time field does not contain a valid value.',
      });
    }
    if (!provider_first_name) {
      errorState.provider_first_name = true;
      this.setState({
        ...this.state,
        errorMessage: 'Provider First Name field does not contain a valid value.',
      });
    }
    if (!provider_last_name) {
      errorState.provider_last_name = true;
      this.setState({
        ...this.state,
        errorMessage: 'Provider Last Name field does not contain a valid value.',
      });
    }
    if (Object.keys(errorState).length) {
      this.setState({
        ...this.state,
        invalid: errorState,
      });
      return;
    }

    return this.props
      .createAppointment({
        variables: {
          appointmentId,
          userid,
          date,
          time,
          notes,
          provider_first_name,
          provider_last_name,
          provider_phone,
          provider_address_1,
          provider_address_2,
          provider_city,
          provider_state,
          provider_zip,
        },
        refetchQueries: () => [
          {
            query: UserAppointments,
            variables: {
              userid: userid,
            },
          },
        ],
      })
      .then((data) => {
        const newAppointmentID = data.data.createAppointment;
        let eventName = MixpanelEvents.AppointmentCreated;
        if(this.props.edit) {
          eventName = MixpanelEvents.AppointmentEdited;
        }
        MixpanelService.track(eventName, {
          AppointmentDate: moment.utc(date).format(MIXPANEL_DAY_FORMAT),
          AppointmentTime: moment.utc(time).format(MIXPANEL_TIME_FORMAT),
          ProviderFirstName: provider_first_name,
          ProviderLastName: provider_last_name,
          ProviderPhoneNumber: provider_phone,
          ProviderStreetAddress1: provider_address_1,
          ProviderStreetAddress2: provider_address_2,
          ProviderCity: provider_city,
          ProviderState: provider_state,
          ProviderZip: provider_zip,
          AppointmentNotes: notes,
          DateAppointmentCreated: moment.utc().format(
            `${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`,
          ),
          AppointmentID: newAppointmentID,
        });
        analytics().logEvent('manage_finish_appointment', {});
        this.props.resetForm();
      });
  }

  deleteAppointment() {
    let { appointmentId, userid, deleteAppointment, resetForm } = this.props;

    return deleteAppointment({
      variables: {
        appointmentId,
      },
      refetchQueries: () => [
        {
          query: UserAppointments,
          variables: {
            userid: userid,
          },
        },
      ],
    }).then(() => {
      resetForm();
    });
  }

  findStateOption(state) {
    return config.states.find(option => {
      return option.value === state;
    });
  }

  verifyDelete(e) {
    e.preventDefault();
    this.setState({
      ...this.state,
      deleteAlert: true,
    });
  }

  dismissAlert() {
    this.setState({
      ...this.state,
      deleteAlert: false,
    });
  }

  render() {
    return (
      <Fragment>
        <div className="card-header">
          <h2 className="title title__cobalt">
            {this.props.edit ? 'Edit Appointment' : 'Create a New Appointment'}
          </h2>
          {this.props.edit ? (
            <div className="header-item__wrapper">
              <h3 className="subtext subtext__red appointment-delete">
                Delete <span>Appointment</span>
              </h3>
              <button
                className="button button-action button-action__delete-appointment"
                onClick={this.verifyDelete.bind(this)}>
                <ActionSvg />
              </button>
              {this.state.deleteAlert ? (
                <div className="action-alert">
                  <h2 className="action-warning headers__red headers__bold">
                    Warning
                  </h2>
                  <p className="subtext__semibold">
                    This action is permanent and cannot be undone.
                  </p>
                  <SubmitBar
                    onCancel={this.dismissAlert.bind(this)}
                    onSubmit={this.deleteAppointment.bind(this)}
                    submitLabel="Delete"
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="alert-space">
          {this.state.errorMessage ? (
            <ErrorAlert message={this.state.errorMessage} />
          ) : null}
        </div>
        <form className="form">
          <fieldset disabled={this.state.deleteAlert}>
            <div className="form-item-row form-date-time-row">
              <div className="form-item form-item__datepicker-date">
                <label className="form-input-label text__left">
                  Date <span className="field__required">*</span>
                </label>
                <DatePicker
                  className={`form-input-field ${
                    this.state.invalid['date'] ? 'form-input-field__error' : ''
                  }`}
                  selected={
                    this.props.formData.date
                      ? new Date(
                          moment(
                            this.props.formData.date,
                            'YYYY-MM-DDTHH:mm:ss[Z]',
                          ).format(),
                        )
                      : new Date()
                  }
                  onChange={this.handleDateTimeChange.bind(this, 'date')}
                  minDate={new Date()}
                  dateFormat="M/d/yyyy"
                  placeholderText="Select Date"
                />
              </div>
              <div className="form-item form-item__datepicker-time">
                <label className="form-input-label text__left">
                  Time <span className="field__required">*</span>
                </label>
                <DatePicker
                  className={`form-input-field ${
                    this.state.invalid['time'] ? 'form-input-field__error' : ''
                  }`}
                  selected={
                    this.props.formData.time
                      ? new Date(
                          moment(
                            this.props.formData.time,
                            'YYYY-MM-DDTHH:mm:ss[Z]',
                          )
                            .local(true)
                            .format(),
                        )
                      : undefined
                  }
                  onChange={this.handleDateTimeChange.bind(this, 'time')}
                  showTimeSelect
                  showTimeSelectOnly
                  dateFormat={'p'}
                  timeCaption="Time"
                  placeholderText="Select Time"
                />
              </div>
            </div>
            <h3 className="paragraph paragraph__cobalt">Appointment Details</h3>
            <div className="appointment">
              <div className="form-item">
                <label className="form-input-label text__left">
                  Provider First Name <span className="field__required">*</span>
                </label>
                <Input
                  name="provider_first_name"
                  inputType="text"
                  content={this.props.formData.provider_first_name}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_first_name',
                  )}
                  placeholder="Enter Provider's First Name"
                  maxLength={maxInputLength}
                  errorClass={
                    this.state.invalid['provider_first_name']
                      ? 'form-input-field__error'
                      : ''
                  }
                />
              </div>
              <div className="form-item">
                <label className="form-input-label text__left">
                  Provider Last Name <span className="field__required">*</span>
                </label>
                <Input
                  name="provider_last_name"
                  inputType="text"
                  content={this.props.formData.provider_last_name}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_last_name',
                  )}
                  placeholder="Enter Provider's Last Name"
                  maxLength={maxInputLength}
                  errorClass={
                    this.state.invalid['provider_last_name']
                      ? 'form-input-field__error'
                      : ''
                  }
                />
              </div>
              <div className="form-item">
                <label className="form-input-label text__left">
                  Phone Number
                </label>
                <Input
                  name="phone"
                  inputType="tel"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  content={this.props.formData.provider_phone}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_phone',
                  )}
                  placeholder="Enter Phone Number"
                  maxLength={'10'}
                />
              </div>
              <div className="form-item">
                <label className="form-input-label text__left">
                  Street Address 1
                </label>
                <Input
                  name="address_1"
                  inputType="text"
                  content={this.props.formData.provider_address_1}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_address_1',
                  )}
                  placeholder="Enter Street Address"
                  maxLength={maxInputLength}
                />
              </div>
              <div className="form-item">
                <label className="form-input-label text__left">
                  Street Address 2
                </label>
                <Input
                  name="address_2"
                  inputType="text"
                  content={this.props.formData.provider_address_2}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_address_2',
                  )}
                  placeholder="Enter Street Address (optional)"
                  maxLength={maxInputLength}
                />
              </div>
              <div className="form-item">
                <label className="form-input-label text__left">City</label>
                <Input
                  name="city"
                  inputType="text"
                  content={this.props.formData.provider_city}
                  controlFunc={this.handleInputChange.bind(
                    this,
                    'provider_city',
                  )}
                  placeholder="Enter City"
                  maxLength={maxInputLength}
                />
              </div>
              <div className="form-item state-zip">
                <div className="form-item">
                  <label className="form-input-label text__left">State</label>
                  <Select
                    className="form-select-wrapper form-item-select"
                    classNamePrefix="form-select"
                    instanceId={this.props.type || 3}
                    value={
                      this.props.formData.provider_state
                        ? this.findStateOption(
                            this.props.formData.provider_state,
                          )
                        : undefined
                    }
                    onChange={this.handleInputChange.bind(
                      this,
                      'provider_state',
                    )}
                    options={config.states}
                    placeholder="Select State"
                  />
                </div>
                <div className="form-item">
                  <label className="form-input-label text__left">Zip</label>
                  <Input
                    name="zip"
                    inputType="text"
                    content={this.props.formData.provider_zip}
                    controlFunc={this.handleInputChange.bind(
                      this,
                      'provider_zip',
                    )}
                    placeholder="Enter Zip"
                    maxLength={'5'}
                  />
                </div>
              </div>
              <div className="form-item appt-notes">
                <label className="form-input-label text__left">
                  Appointment Notes
                </label>
                <TextArea
                  className="form-input-field"
                  rows={7}
                  name="notes"
                  content={this.props.formData.notes}
                  resize={false}
                  placeholder="Add notes for this appointment (optional)"
                  controlFunc={this.handleInputChange.bind(this, 'notes')}
                  maxLength={'250'}
                />
              </div>
            </div>
          </fieldset>
        </form>
        <SubmitBar
          onCancel={this.props.resetForm}
          onSubmit={this.createAppointment.bind(this)}
          disableSubmit={this.state.deleteAlert}
          disableCancel={this.state.deleteAlert}
        />
      </Fragment>
    );
  }
}

export default AppointmentForm;
