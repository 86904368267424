import React, { useEffect } from 'react';

// APOLLO
import { useMutation, useQuery } from 'react-apollo';
import { UserAppointments } from '../../libs/graphql/queries/user';
import {
  createUserAppointment,
  deleteAppointment,
} from '../../libs/graphql/mutations/user';

// REDUX
import { useAppDispatch, useAppSelector } from '../../store';
import { appointmentActions } from '../../store/appointments';

const Manage = props => {
  const dispatch = useAppDispatch();
  const appointments = useAppSelector(state => state.appointments.appointments);

  // Queries for appointments
  const { loading, error, data, refetch } = useQuery(UserAppointments, {
    variables: { userid: props.me.id },
  });

  // Mutations for appointments
  const [createUserAppointmentMutation] = useMutation(createUserAppointment);
  const [deleteAppointmentMutation] = useMutation(deleteAppointment);

  useEffect(() => {
    dispatch(
      appointmentActions.setAppointments(data?.getAppointmentsByUserId || []),
    );
  }, [dispatch, data]);

  const handleDelete = async id => {
    await deleteAppointmentMutation({
      variables: { appointmentId: id },
    });
    dispatch(appointmentActions.deleteAppointment(id));
  };

  const handleCreate = async () => {
    await createUserAppointmentMutation({
      variables: {
        userid: props.me.id,
        date: new Date().toISOString(),
        time: new Date().toISOString(),
        notes: 'Test',
        provider_first_name: 'Jhon',
        provider_last_name: 'Doe',
        provider_phone: '123456789',
        provider_address_1: '123 Main St',
        provider_address_2: 'Av',
        provider_city: 'Los Angeles',
        provider_state: 'CA',
        provider_zip: '90001',
      },
    });
    refetch();
  };

  return (
    <div style={{ padding: '30px' }}>
      <h2>Appointments {loading && <strong>...</strong>}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={() => refetch()}>Refetch</button>{' '}
      <button onClick={handleCreate}>Create appointment</button>
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>provider</th>
            <th>date</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map(appointment => (
            <tr>
              <td>{appointment.id}</td>
              <td>
                <strong>
                  {appointment.provider_first_name}{' '}
                  {appointment.provider_last_name}
                </strong>
              </td>
              <td>{appointment.date}</td>
              <td>
                <button onClick={() => handleDelete(appointment.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Manage;
