import { flowRight as compose } from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withAppointments } from '../../apollo/stores/appointments';
import {
  ActionSvg,
  HelperIcon,
  ProviderPrepIcon,
  UserBlueIcon,
} from '../../assets/icons';
import analytics from '../../libs/analytics';
import {
  createUserAppointment,
  deleteAppointment,
} from '../../libs/graphql/mutations/user';
import AppointmentForm from '../forms/Appointment';
import Appointment from './Appointment';
import Tutorial from '../shared/Tutorial';

const createUserAppointmentMutation = graphql(createUserAppointment, {
  name: 'createUserAppointmentMutation',
});

const deleteAppointmentMutation = graphql(deleteAppointment, {
  name: 'deleteAppointmentMutation',
});

const ManageTutorial = {
  titles: ['Appointment Summary'],
  subtitles: [``],
  texts: [
    `This tool helps you prepare for doctor’s visits and save appointment prep summaries all in one place.
    Once you’ve added your appointment information, you’ll receive a PDF summary you can share with your doctor to help you make informed decisions together.`,
  ],
  urlsGif: [''],
};
class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAppointment: false,
      appointmentInEdit: undefined,
      activeTutorialId: null,
    };
  }

  setEditId(id) {
    this.setState({
      appointmentInEdit: id,
    });
  }

  createAppointment() {
    this.setState({
      addAppointment: true,
    });
    analytics().setCurrentScreen('manage_start_add_appointment');
  }

  handleCreateAppointmentCancel() {
    this.setState({
      addAppointment: false,
    });
    this.props.resetAppointmentForm();
  }

  activeTutorialUpdate(id) {
    this.setState({
      activeTutorialId: id,
    });
  }

  render() {
    let appointments = this.props.getAppointmentsByUserId || [];

    return (
      <div className="provider-prep-container">
        <header>
          <div className="date-bar">
            <div className="main-dashboard__sidebar">
              <div className="sidebar-title">
                <UserBlueIcon />
                <h1 className="headers headers__black100 headers__uppercase headers__mediumbold">
                  Provider Prep
                </h1>
                <Tutorial
                  titles={ManageTutorial.titles}
                  texts={ManageTutorial.texts}
                  subtitles={ManageTutorial.subtitles}
                  urlsGif={ManageTutorial.urlsGif}
                  onClickNext={this.activeTutorialUpdate.bind(this)}>
                  <div className="tooltip-trigger">
                    <span className="icon-instructions">
                      <HelperIcon />
                    </span>
                  </div>
                </Tutorial>
              </div>
            </div>

            {appointments.length > 0 ? (
              <>
                <div className="button__wrapper">
                  <button
                    className="button button-action button-action__square button-action__add"
                    onClick={this.createAppointment.bind(this)}
                    disabled={!!this.state.appointmentInEdit}
                    title="Add new">
                    <ActionSvg />
                  </button>
                </div>
                <span className="headers headers__semibold headers__uppercase headers__cobalt">
                  Add New Appointment
                </span>
              </>
            ) : null}
          </div>
        </header>
        <div className={'appointments-container'}>
          {this.state.addAppointment ? (
            <div className="appointment-item card card__active">
              <AppointmentForm
                userid={this.props.me.id}
                createAppointment={this.props.createUserAppointmentMutation}
                deleteAppointment={this.props.deleteAppointmentMutation}
                formData={this.props.appointmentForm}
                inputChangeHandler={this.props.updateInputAppointment}
                resetForm={this.handleCreateAppointmentCancel.bind(this)}
              />
            </div>
          ) : null}
          {this._buildAppointmentList()}
        </div>
      </div>
    );
  }

  _buildAppointmentList() {
    let appointments = this.props.getAppointmentsByUserId || [];

    if (!appointments.length && !this.state.addAppointment) {
      return (
        <div className="provider-prep__empty appointment-item card card__active">
          <div className="card-header">
            <h2 className="title title__large-bold title__black100">
              Get Ready for Your Appointment
            </h2>
          </div>
          <div className="provider-prep__empty-content">
            <p className="title">
              Once you’ve created an appointment, complete five easy steps to
              prepare for your visit:
            </p>
            <div>
              <ol className="list_card-manage-appointment-list">
                <li className="paragraph">Record topics to discuss</li>
                <li className="paragraph">Evaluate recent symptoms</li>
                <li className="paragraph">Assess treatment options</li>
                <li className="paragraph">
                  Snap or select photos of your skin
                </li>
                <li className="paragraph">Save your information</li>
              </ol>
            </div>
            <p className="title">
              You’ll receive an appointment summary to show your doctor and help
              guide your discussion.
            </p>
          </div>
          <div className="tracker-footer">
            <div className="tracker-footer-items button-row">
              <button
                className="button button__secondary100 button__btn-14-600 button-rectangle-large"
                onClick={this.createAppointment.bind(this)}>
                <span className="button-text-copy">Get started</span>
              </button>
            </div>
          </div>
        </div>
      );
    }

    let appointmentsList = appointments.map(appt => {
      return (
        <Appointment
          details={appt}
          hydrateMutation={this.props.hydrateAppointmentForm}
          userid={this.props.me.id}
          createAppointmentMutation={this.props.createUserAppointmentMutation}
          deleteAppointment={this.props.deleteAppointmentMutation}
          appointmentForm={this.props.appointmentForm}
          updateInputMutation={this.props.updateInputAppointment}
          resetForm={this.props.resetAppointmentForm}
          appointmentInEdit={
            this.state.addAppointment ? 'all' : this.state.appointmentInEdit
          } //want to disable editing of all appointments if a new appointment is being added
          setEditId={this.setEditId.bind(this)}
          key={appt.id}
        />
      );
    });

    return appointmentsList;
  }
}

export default compose(
  withRouter,
  withAppointments,
  createUserAppointmentMutation,
  deleteAppointmentMutation,
)(Appointments);
