import React from 'react';

export default function TriggersOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64621C15.8834 9.62255 15.8428 9.61985 15.8111 9.63878L11.2265 12.3614L11.6436 5.69308C11.6456 5.64913 11.6152 5.60924 11.5719 5.60113C11.5273 5.59302 11.4854 5.61803 11.4712 5.65995L7.86086 16.491C7.84936 16.5255 7.86086 16.564 7.88925 16.5877C7.91765 16.6107 7.95754 16.6134 7.98999 16.5945L12.1365 14.1328L11.6849 21.96C11.6822 22.0039 11.7133 22.0431 11.7565 22.0519C11.7619 22.0533 11.7673 22.0539 11.7727 22.0539C11.8106 22.0539 11.8444 22.0296 11.8566 21.9931L15.9402 9.7429C15.9524 9.70774 15.9402 9.6692 15.9118 9.64621Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3985C20.1646 12.3666 20.1089 12.3673 20.0725 12.4L15.144 16.9395C15.1151 16.9663 15.1047 17.0063 15.118 17.0435C15.1307 17.0806 15.1648 17.1051 15.2041 17.108L17.3225 17.235L14.7432 20.9506C14.715 20.9915 14.7224 21.0464 14.7595 21.0791C14.7774 21.0954 14.8004 21.1028 14.8234 21.1028C14.8464 21.1028 14.8701 21.0939 14.8887 21.0768L19.8165 16.538C19.8454 16.512 19.8558 16.4712 19.8424 16.4341C19.8298 16.3977 19.7957 16.3725 19.7571 16.3702L17.6387 16.2433L20.2173 12.5269C20.2462 12.4868 20.2388 12.4311 20.2017 12.3985Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6017 12.8472C12.6017 13.671 11.9317 14.3409 11.1079 14.3409C10.2841 14.3409 9.61411 13.671 9.61411 12.8472C9.61411 12.0233 10.2841 11.3534 11.1079 11.3534C11.9317 11.3534 12.6017 12.0233 12.6017 12.8472ZM17.8299 12.8472C17.8299 13.671 17.1599 14.3409 16.3361 14.3409C15.5123 14.3409 14.8423 13.671 14.8423 12.8472C14.8423 12.0233 15.5123 11.3534 16.3361 11.3534C17.1599 11.3534 17.8299 12.0233 17.8299 12.8472ZM13.722 6.125C10.0159 6.125 7 9.14093 7 12.847V20.6467C7 20.7207 7.04332 20.7871 7.10979 20.817C7.17776 20.8469 7.25618 20.8357 7.3107 20.7864L8.6805 19.569L10.237 20.9529C10.308 21.0157 10.414 21.0157 10.485 20.9529L12.0415 19.569L13.598 20.9529C13.669 21.0157 13.775 21.0157 13.846 20.9529L15.4025 19.569L16.959 20.9529C16.9941 20.9843 17.0382 21 17.083 21C17.1278 21 17.1719 20.9843 17.207 20.9529L18.7635 19.569L20.1333 20.7864C20.1878 20.8357 20.2662 20.8469 20.3342 20.817C20.4006 20.7871 20.444 20.7207 20.444 20.6467V12.847C20.444 9.14093 17.428 6.125 13.722 6.125Z"
        fill="white"
      />
    </svg>
  );
}
