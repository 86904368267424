import React from 'react';

export default function TreatmentsOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64621C15.8834 9.62255 15.8428 9.61985 15.8111 9.63878L11.2265 12.3614L11.6436 5.69308C11.6456 5.64913 11.6152 5.60924 11.5719 5.60113C11.5273 5.59302 11.4854 5.61803 11.4712 5.65995L7.86086 16.491C7.84936 16.5255 7.86086 16.564 7.88925 16.5877C7.91765 16.6107 7.95754 16.6134 7.98999 16.5945L12.1365 14.1328L11.6849 21.96C11.6822 22.0039 11.7133 22.0431 11.7565 22.0519C11.7619 22.0533 11.7673 22.0539 11.7727 22.0539C11.8106 22.0539 11.8444 22.0296 11.8566 21.9931L15.9402 9.7429C15.9524 9.70774 15.9402 9.6692 15.9118 9.64621Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2012 12.3985C20.1641 12.3666 20.1084 12.3673 20.0721 12.4L15.1435 16.9395C15.1146 16.9663 15.1042 17.0063 15.1176 17.0435C15.1302 17.0806 15.1643 17.1051 15.2037 17.108L17.322 17.235L14.7427 20.9506C14.7145 20.9915 14.7219 21.0464 14.7591 21.0791C14.7769 21.0954 14.7999 21.1028 14.8229 21.1028C14.8459 21.1028 14.8696 21.0939 14.8882 21.0768L19.816 16.538C19.8449 16.512 19.8553 16.4712 19.842 16.4341C19.8293 16.3977 19.7952 16.3725 19.7566 16.3702L17.6382 16.2433L20.2168 12.5269C20.2457 12.4868 20.2383 12.4311 20.2012 12.3985Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3259 16.7393L17.6792 16.5333L17.4731 14.8866C17.4698 14.8598 17.4487 14.8378 17.421 14.8345C17.388 14.8302 17.3579 14.8536 17.3536 14.8866L17.1475 16.5333L15.5009 16.7393C15.4741 16.7427 15.4521 16.7637 15.4488 16.7915C15.4444 16.8245 15.4679 16.8546 15.5009 16.8589L17.1475 17.0649L17.3536 18.7116C17.3569 18.7384 17.378 18.7604 17.4057 18.7637C17.4387 18.768 17.4688 18.7446 17.4731 18.7116L17.6792 17.0649L19.3259 16.8589C19.3526 16.8555 19.3746 16.8345 19.378 16.8068C19.3823 16.7738 19.3589 16.7436 19.3259 16.7393Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6204 18.2933L14.8234 18.1939L14.7239 17.3969C14.7206 17.3701 14.6995 17.3481 14.6718 17.3447C14.6388 17.3404 14.6087 17.3639 14.6044 17.3969L14.5049 18.1939L13.7079 18.2933C13.6811 18.2967 13.6591 18.3177 13.6558 18.3455C13.6515 18.3785 13.6749 18.4086 13.7079 18.4129L14.5049 18.5123L14.6044 19.3094C14.6077 19.3361 14.6288 19.3581 14.6565 19.3615C14.6895 19.3658 14.7196 19.3424 14.7239 19.3094L14.8234 18.5123L15.6204 18.4129C15.6472 18.4095 15.6692 18.3885 15.6725 18.3608C15.6768 18.3278 15.6534 18.2977 15.6204 18.2933Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1266 10.5983C15.1405 10.6069 15.1577 10.6108 15.1735 10.6179C15.2218 10.3397 15.3303 10.0772 15.4924 9.84624C15.4761 9.84051 15.4637 9.82951 15.4465 9.82521L13.5655 9.36955C13.5158 9.3576 13.4618 9.37816 13.4336 9.42406C13.3991 9.48096 13.4168 9.55554 13.4737 9.59045L15.1266 10.5983Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61148 11.5402L10.0552 11.8457L10.3607 14.2894C10.3674 14.343 10.4095 14.387 10.4654 14.3941C10.5314 14.4023 10.5916 14.3554 10.5998 14.2894L10.9053 11.8457L13.349 11.5402C13.4025 11.5335 13.4465 11.4914 13.4537 11.4355C13.4618 11.3695 13.415 11.3093 13.349 11.3011L10.9053 10.9956L10.5998 8.55191C10.5931 8.49836 10.551 8.45438 10.4951 8.44721C10.4291 8.43908 10.3688 8.48593 10.3607 8.55191L10.0552 10.9956L7.61148 11.3011C7.55793 11.3078 7.51395 11.3499 7.50678 11.4058C7.49865 11.4718 7.5455 11.5321 7.61148 11.5402Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0802 13.9169C13.865 13.7017 13.7039 13.4923 13.5791 13.2915L7.5925 19.0524L7.59107 19.0539L7.58677 19.0577L7.58724 19.0582C7.5007 19.1447 7.42898 19.248 7.37974 19.368C7.17749 19.8557 7.40938 20.4151 7.89707 20.6173C8.26475 20.7699 8.67307 20.6747 8.9389 20.4098L8.93938 20.4103L14.706 14.417C14.5048 14.2922 14.2958 14.132 14.0802 13.9169Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5464 10.4367L14.1104 12.7809C14.2108 12.9727 14.3618 13.1845 14.5875 13.4102C14.8132 13.6354 15.025 13.7869 15.2167 13.8868L17.561 11.4513H17.5605L17.561 11.4508C17.8412 11.1711 17.8412 10.7169 17.561 10.4367C17.2808 10.1565 16.8266 10.1565 16.5464 10.4367Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9536 9.38232C16.067 9.30247 16.1884 9.23219 16.3213 9.17672C16.4538 9.12222 16.5891 9.08588 16.7244 9.06245C16.7177 9.04715 16.7167 9.03137 16.7081 9.01655L15.6998 7.36415C15.6735 7.32064 15.6204 7.29674 15.5683 7.30965C15.5032 7.32542 15.4636 7.39045 15.4789 7.45547L15.9345 9.3369C15.9388 9.3522 15.9479 9.36702 15.9536 9.38232Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1712 9.33693L18.6273 7.45551C18.6392 7.40579 18.6187 7.35176 18.5723 7.32403C18.5154 7.28912 18.4408 7.30682 18.4064 7.36419L17.398 9.01659C17.3899 9.03046 17.3856 9.04815 17.3789 9.06345C17.6567 9.11174 17.9192 9.22027 18.1501 9.38236C18.1559 9.36658 18.1673 9.35367 18.1712 9.33693Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8199 10.2119C18.8748 10.3444 18.9107 10.4797 18.9346 10.615C18.9494 10.6083 18.9657 10.6074 18.98 10.5987L20.6329 9.59038C20.6764 9.56409 20.6998 9.51101 20.6869 9.4589C20.6716 9.39387 20.6061 9.35419 20.5411 9.36949L18.6602 9.82562C18.6444 9.82944 18.63 9.83853 18.6143 9.84427C18.6941 9.95758 18.7649 10.079 18.8199 10.2119Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7849 12.711C17.6525 12.7659 17.5171 12.8013 17.3818 12.8252C17.3885 12.8405 17.3895 12.8568 17.3981 12.8707L18.4065 14.5235C18.4328 14.567 18.4858 14.591 18.5379 14.578C18.603 14.5623 18.6427 14.4968 18.6274 14.4322L18.1712 12.5508C18.1674 12.5355 18.1583 12.5207 18.1526 12.5054C18.0393 12.5852 17.9178 12.6555 17.7849 12.711Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6334 12.297L18.9805 11.2891C18.9667 11.2805 18.9494 11.2767 18.9337 11.2695C18.8854 11.5473 18.7768 11.8098 18.6152 12.0407C18.631 12.047 18.6434 12.058 18.6607 12.0623L20.5421 12.5179C20.5918 12.5299 20.6454 12.5093 20.6736 12.4634C20.7085 12.4065 20.6903 12.3319 20.6334 12.297Z"
        fill="white"
      />
    </svg>
  );
}
