import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { withSkinTracker } from '../apollo/stores/trackers';
import SkinTrackerAreasAffected from '../components/trackers/SkinTrackerAreasAffected';
import SkinTrackerContentWrapper from '../components/trackers/SkinTrackerContentWrapper';
import SkinTrackerImages from '../components/trackers/SkinTrackerImages';
import SkinTrackerNavigation from '../components/trackers/SkinTrackerNavigation';
import SkinTrackerScaleTracker from '../components/trackers/SkinTrackerScaleTracker';
import analytics from '../libs/analytics';
import { DailyUserData } from '../libs/graphql/queries/data';
import MixpanelService from '../libs/mixpanel';
import { MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT } from '../libs/mixpanel';
import { capitalCase } from 'change-case';

const SYMPTOMS = [
  {
    value: 'dryness',
    label: 'dryness',
  },
  {
    value: 'redness',
    label: 'redness',
  },
  {
    value: 'swelling',
    label: 'swelling',
  },
  {
    value: 'oozing_scabs',
    label: 'oozing scabs',
  },
  {
    value: 'scratch_marks',
    label: 'scratch marks',
  },
  {
    value: 'thickening_skin',
    label: 'thickening skin',
  },
];

const BODY_AREAS = {
  HEAD_AND_NECK: [ 'anterior_head', 'posterior_head' ],
  UPPER_LEFT_LIMBS: [
    'left_palm',
    'left_hand',
    'anterior_left_forearm',
    'anterior_left_upper_arm',
    'anterior_left_shoulder',
    'posterior_left_forearm',
    'posterior_left_upper_arm',
    'posterior_left_shoulder',
  ],
  UPPER_RIGHT_LIMBS: [
    'right_palm',
    'right_hand',
    'anterior_right_forearm',
    'anterior_right_upper_arm',
    'anterior_right_shoulder',
    'posterior_right_forearm',
    'posterior_right_upper_arm',
    'posterior_right_shoulder',
  ],
  LOWER_LEFT_LIMBS: [
    'anterior_left_ankle_foot',
    'anterior_left_leg',
    'anterior_left_thigh',
    'posterior_left_ankle_foot',
    'posterior_left_leg',
  ],
  LOWER_RIGHT_LIMBS: [
    'anterior_right_ankle_foot',
    'anterior_right_leg',
    'anterior_right_thigh',
    'posterior_right_ankle_foot',
    'posterior_right_leg',
  ],
  ANTERIOR_TRUNK: [ 'anterior_lower_torso', 'anterior_upper_torso', 'anterior_waist' ],
  BACK: [ 'buttocks', 'posterior_waist', 'posterior_lower_torso', 'posterior_upper_torso' ],
  GENITALS: [ 'genitals' ],
};

class SkinTrackerWriteContainer extends Component {
  constructor(props) {
    super(props);
    this.hydrateSkinStore = this.hydrateSkinStore.bind(this);
  }

  componentDidMount() {
    return this.hydrateSkinStore();
  }

  hydrateSkinStore() {
    //hydrate skin store with any existing data from server

    if (this.props.data && Object.keys(this.props.data).length && !this.props.loading) {
      return this.props.hydrateSkinStore({
        variables: {
          dryness: this.props.data.dryness,
          redness: this.props.data.redness || 0, //since we can save null values for these, want to make sure we set to 0 in store
          swelling: this.props.data.swelling || 0, //since we can save null values for these, want to make sure we set to 0 in store
          oozing_scabs: this.props.data.oozing_scabs || 0, //since we can save null values for these, want to make sure we set to 0 in store
          scratch_marks: this.props.data.scratch_marks || 0, //since we can save null values for these, want to make sure we set to 0 in store
          thickening_skin: this.props.data.thickening_skin || 0, //since we can save null values for these, want to make sure we set to 0 in store
          areas_affected: this.props.data.areas_affected,
          areas_selected: this.props.data.areas_selected,
          notes: this.props.data.notes,
          infections: this.props.data.infections,
          infections_notes: this.props.data.infections_notes,
          pictures: this.props.data.pictures || [],
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.resetSkinStore();
  }

  render() {
    if (this.props.loading || !this.props.skinStore) return null;
    return (
      <Fragment>
        {this._componentFromProcessStep()}
        <SkinTrackerNavigation
          onCancel={this.onCancel.bind(this)}
          onNext={this.navigateNext.bind(this)}
          onSubmit={this.submitData.bind(this)}
          onBack={this.navigateBack.bind(this)}
          showBack={!(this.props.skinTrackerNavigation.currentPage === 1)}
          lastPage={this.props.skinTrackerNavigation.currentPage === 4}
          disableSave={
            (this.props.skinTrackerNavigation.currentPage === 3 &&
            !(typeof this.props.skinStore.infections === 'boolean')) || (!this.props.skinStore.areas_affected.length > 0 && this.props.skinStore.areas_selected)
          }
        />
      </Fragment>
    );
  }

  submitData() {
    return this.props
      .submit({
        variables: {
          id: this.props.id,
          client_date: this.props.date || moment().format('YYYY-MM-DD'),
          data: {
            swelling: this.props.skinStore.areas_selected ? this.props.skinStore.swelling : null,
            oozing_scabs: this.props.skinStore.areas_selected ? this.props.skinStore.oozing_scabs : null,
            dryness: this.props.skinStore.dryness,
            redness: this.props.skinStore.areas_selected ? this.props.skinStore.redness : null,
            scratch_marks: this.props.skinStore.areas_selected ? this.props.skinStore.scratch_marks : null,
            thickening_skin: this.props.skinStore.areas_selected ? this.props.skinStore.thickening_skin : null,
            areas_affected: this.props.skinStore.areas_affected,
            areas_selected: this.props.skinStore.areas_selected,
            notes: this.props.skinStore.notes,
            infections: this.props.skinStore.infections,
            infections_notes: this.props.skinStore.infections_notes,
            pictures: this.props.skinStore.pictures.map(region => ({
              area_affected: region.area_affected,
              images: region.images.map(image => image.id),
            })),
          },
        },
        refetchQueries: () => [
          {
            query: DailyUserData,
            variables: {
              id: this.props.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
              readOnly: true,
            },
            options: {
              fetchPolicy: 'network-only',
            },
          },
        ],
      })
      .then(() => {
        let bodySurfaceAreaAffected = 0;
        let hasHeadAndNeck = false;
        let hasUpperLeftLimbs = false;
        let hasUpperRightLimbs = false;
        let hasLowerLeftLimbs = false;
        let hasLowerRightLimbs = false;
        let hasAnteriorTrunk = false;
        let hasBack = false;
        let hasGenitals = false;

        this.props.skinStore.areas_affected.forEach(area => {
          if (BODY_AREAS.HEAD_AND_NECK.includes(area)) {
            hasHeadAndNeck = true;
          }
          if (BODY_AREAS.UPPER_LEFT_LIMBS.includes(area)) {
            hasUpperLeftLimbs = true;
          }
          if (BODY_AREAS.UPPER_RIGHT_LIMBS.includes(area)) {
            hasUpperRightLimbs = true;
          }
          if (BODY_AREAS.LOWER_LEFT_LIMBS.includes(area)) {
            hasLowerLeftLimbs = true;
          }
          if (BODY_AREAS.LOWER_RIGHT_LIMBS.includes(area)) {
            hasLowerRightLimbs = true;
          }
          if (BODY_AREAS.ANTERIOR_TRUNK.includes(area)) {
            hasAnteriorTrunk = true;
          }
          if (BODY_AREAS.BACK.includes(area)) {
            hasBack = true;
          }
          if (BODY_AREAS.GENITALS.includes(area)) {
            hasGenitals = true;
          }
        });

        // Head and neck 9%
        if (hasHeadAndNeck) {
          bodySurfaceAreaAffected += 9;
        }
        // Upper limbs 9% each
        if (hasUpperLeftLimbs) {
          bodySurfaceAreaAffected += 9;
        }
        if (hasUpperRightLimbs) {
          bodySurfaceAreaAffected += 9;
        }
        // Lower limbs 18% each
        if (hasLowerLeftLimbs) {
          bodySurfaceAreaAffected += 18;
        }
        if (hasLowerRightLimbs) {
          bodySurfaceAreaAffected += 18;
        }
        // Anterior trunk 18%
        if (hasAnteriorTrunk) {
          bodySurfaceAreaAffected += 18;
        }
        // Back 18%
        if (hasBack) {
          bodySurfaceAreaAffected += 18;
        }
        // Genitals 1%
        if (hasGenitals) {
          bodySurfaceAreaAffected += 1;
        }

        const { date } = this.props;
        const currentTime = moment.utc().format(MIXPANEL_TIME_FORMAT);
        const selectedDayMoment = date ? moment.utc(date).format(MIXPANEL_DAY_FORMAT) : moment.utc().format(MIXPANEL_DAY_FORMAT);
        const skinDate = `${selectedDayMoment}T${currentTime}`;

        const skinData = {
          AffectedAreas: this.props.skinStore.areas_affected.map(capitalCase).join(', '),
          SkinDryness: this.props.skinStore.dryness,
          SkinRedness: this.props.skinStore.areas_selected ? this.props.skinStore.redness : null,
          SkinSwelling: this.props.skinStore.areas_selected ? this.props.skinStore.swelling : null,
          SkinOozingScabs: this.props.skinStore.areas_selected ? this.props.skinStore.oozing_scabs : null,
          SkinStretchMarks: this.props.skinStore.areas_selected ? this.props.skinStore.scratch_marks : null,
          SkinThickening: this.props.skinStore.areas_selected ? this.props.skinStore.thickening_skin : null,
          AdditionalSkinInfections: this.props.skinStore.infections ? 'Yes' : 'No',
          SkinNotes: this.props.skinStore.notes,
          BodySurfaceAreaAffected: `${bodySurfaceAreaAffected}%`,
          SkinDate: skinDate,
        };

        MixpanelService.track('SkinTrackerUpdated', skinData);
        if (selectedDayMoment === moment().format(MIXPANEL_DAY_FORMAT)) {
          // Update Profile Properties with the most recent tracker date
          MixpanelService.setProfileProperty({ SkinDate : skinDate });
        }

        analytics().logEvent('any_track_item_updated', {});

        this.props.resetSkinNavigation();
      });
  }

  navigateNext() {
    this.props.navigateNextSkinTracker({
      variables: {
        subPageStart: 2,
        lastSubPage: this.props.skinStore.areas_selected ? 6 : 1, //if no areas are selected we only present dryness tracker
      },
    });
  }

  navigateBack() {
    this.props.navigateBackSkinTracker({
      variables: {
        subPageStart: 2,
        lastSubPage: this.props.skinStore.areas_selected ? 6 : 1, //if no areas are selected we only present dryness tracker
      },
    });
  }

  onCancel() {
    //reset skin store and need to determine whether we toggle to read state or stay in write state
    this.props.resetSkinStore().then(this.props.handleWriteCancel);
  }

  handleAreaSelected(e) {
    let id = e.target.id;
    if (this.props.skinStore.areas_selected) {
      this.props.handleAreaSelected({
        variables: {
          id: id,
        },
      });
    }
  }

  handleCheckBox(e) {
    this.props.handleAffectedAreasCheckBox({
      variables: {
        checked: e.target.checked,
      },
    });
  }

  handleRadio(id, e) {
    this.props.handleInfectionsRadio({
      variables: {
        selection: e.target.value === 'yes',
      },
    });
  }

  _componentFromProcessStep() {
    //return the appropriate component based on the navigation step
    let symptom, component;
    let { currentPage, subPage } = this.props.skinTrackerNavigation;

    if (currentPage === 2) {
      symptom = SYMPTOMS[subPage - 1];
    }
    switch (currentPage) {
      case 1:
        component = (
          <SkinTrackerAreasAffected
            areas_affected={this.props.skinStore.areas_affected}
            handleAreaSelected={this.handleAreaSelected.bind(this)}
          />
        );
        break;
      case 2:
        component = (
          <SkinTrackerScaleTracker
            symptom={symptom}
            updateSkinScaleValue={this.props.updateSkinScaleValue}
            level={this.props.skinStore[symptom.value]}
            store={this.props.skinStore}
          />
        );
        break;
      case 3:
        component = (
          <div>
            <h4 className="subtext subtext__uppercase">notes</h4>
            <input
              className="form-input-field"
              value={this.props.skinStore.notes}
              placeholder="Add optional notes"
              onChange={e => {
                let value = e.target.value;
                this.props.updateInput({
                  variables: {
                    name: 'notes',
                    value: value,
                  },
                });
              }}
            />
          </div>
        );
        break;
      case 4:
        component = (
          <SkinTrackerImages
            id={this.props.id}
            skinPictureLocation={this.props.skinPictureLocation}
            pictures={this.props.skinStore.pictures}
            onChange={region => {
              let pictures = this.props.skinStore.pictures.filter(
                _region => _region.area_affected !== region.area_affected
              );
              pictures.push(region);
              pictures = pictures.filter(p => p.images.length !== 0);
              this.props.handlePictures({
                variables: { pictures },
              });
            }}
          />
        );
        break;
      default:
        break;
    }

    return (
      <SkinTrackerContentWrapper
        page={currentPage}
        subPage={subPage}
        data={this.props.skinStore}
        checkBoxHandler={this.handleCheckBox.bind(this)}
        radioHandler={this.handleRadio.bind(this)}
        updateInput={this.props.updateInput}
        changeSkinPictureLocation={this.props.changeSkinPictureLocation}
        skinPictureLocation={this.props.skinPictureLocation}
      >
        {component}
      </SkinTrackerContentWrapper>
    );
  }
}

export default compose(withSkinTracker)(SkinTrackerWriteContainer);
