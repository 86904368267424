import PropTypes from 'prop-types';
import React from 'react';

const SingleInput = props => (
  <div className="form-group">
    <input
      id={props.name}
      className={`form-input-field ${props.errorClass || ''}`}
      name={props.name}
      type={props.inputType}
      value={props.content}
      onChange={props.controlFunc}
      onKeyPress={props.handleKeyPress}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      key={props.name}
    />
  </div>
);

SingleInput.propTypes = {
  inputType: PropTypes.oneOf(['text', 'tel']).isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string]),
  maxLength: PropTypes.string,
  errorClass: PropTypes.string,
};

export default SingleInput;
