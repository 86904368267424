import React, { Component } from 'react';
import { ActionSvg } from '../../assets/icons';

class Incrementor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  componentDidMount() {
    let { value } = this.props;
    this.setState({ value });
  }

  increment() {
    this.state.value < (this.props.maxValue || 20) &&
      this.setState({ value: this.state.value + 1 }, () => {
        if (this.props.cb) {
          this.props.cb(this.state.value);
        }
      });
  }

  decrement() {
    this.state.value > 0 &&
      this.setState({ value: this.state.value - 1 }, () => {
        if (this.props.cb) {
          this.props.cb(this.state.value);
        }
      });
  }

  render() {
    return (
      <div className="incrementor-wrapper">
        <div className="incrementor__label">
          <span className="option-text">cups of water</span>
        </div>
        <div className="incrementor-grid">
          <div className="incrementor__minus">
            <button
              className="button button-action button-action__minus"
              onClick={this.decrement.bind(this)}
              disabled={!this.state.value}>
              <ActionSvg />
            </button>
          </div>
          <div className="incrementor__value">
            <div className="incrementor-value">
              <span className="title__large title__orange title__semibold">
                {this.state.value}
              </span>
            </div>
          </div>
          <div className="incrementor__add">
            <button
              className="button button-action button-action__add"
              onClick={this.increment.bind(this)}
              disabled={this.state.value === (this.props.maxValue || 20)}>
              <ActionSvg />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Incrementor;
