import moment from 'moment-timezone';
import React from 'react';
import ProgressBar from './ProgressBar';
const TOTAL_STEPS = 5;

const ProviderPrepHeader = props => {
  return (
    <div className="provider-prep__header">
      <div className="card-header">
        <h2 className="title title__black100 title__semibold">
          <span className="card-header__date">
            <span className="date__date">
              {moment(props.date).format('MMM. D, YYYY')}
            </span>
            <span className="date__at">at</span>
            <span className="date__time">
              {moment(props.time).format('h:mm A')}{' '}
            </span>
          </span>
          <span className="text__capitalize card-header__name">
            {props.name}
          </span>
        </h2>
      </div>
      <div className="skin-progress tracker-theme-secondary100">
        <div className="skin-progress">
          <ProgressBar
            className="skin-progress"
            totalSteps={TOTAL_STEPS}
            currentStep={!props.readOnly ? props.step || 1 : TOTAL_STEPS + 1}
            dot={'number'}
          />
        </div>
      </div>
    </div>
  );
};

export default ProviderPrepHeader;
