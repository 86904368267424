import React from 'react';
import {
  DietIcon,
  ItchIcon,
  PainIcon,
  SkinIcon,
  SleepIcon,
  StressIcon,
  TreatmentIcon,
  TriggersIcon,
} from '../../assets/icons/trackers';
import config from '../../config';

const IconList = ({ exclude }) => {
  const iconDictionary = {
    skin: <SkinIcon />,
    itch: <ItchIcon />,
    pain: <PainIcon />,
    treatment: <TreatmentIcon />,
    trigger: <TriggersIcon />,
    stress: <StressIcon />,
    sleep: <SleepIcon />,
    diet: <DietIcon />,
  };
  let iconList = !exclude
    ? Object.keys(iconDictionary)
    : Object.keys(iconDictionary).filter(icon => !exclude.includes(icon));
  let icons = iconList.map((icon, index) => {
    return (
      <li className={`filter-item ${config.trackerTheme[icon]}`} key={index}>
        {iconDictionary[icon]}
        <span className="headers headers__capitalized">{icon}</span>
      </li>
    );
  });
  return <ul className="aside-list__icons">{icons}</ul>;
};

export default IconList;
