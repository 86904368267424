import { flowRight as compose } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { graphql } from 'react-apollo';
import Resizer from 'react-image-file-resizer';
import Viewer from 'react-viewer';
import { UPLOAD_IMAGES } from '../../libs/graphql/mutations/image';

const withUploadImages = graphql(UPLOAD_IMAGES, {
  name: 'uploadImages',
});

/**
 * Maximum images allowed for the skin tracker is 8 (per region!)
 * @param {*} props
 */
const SkinTrackerImages = props => {
  const { pictures } = props;

  const onInputChange = e => {
    const formData = new FormData();
    Promise.all(
      Object.keys(e.target.files)
        .map(key => e.target.files[key])
        .map(file => {
          formData.append(file.name, file);
          return new Promise((res, rej) => {
            Resizer.imageFileResizer(
              file,
              600,
              600,
              'JPEG',
              100,
              0,
              blob => {
                res(new File([blob], file.name));
              },
              'blob',
            );
          });
        }),
    )
      .then(files => {
        return props.uploadImages({
          variables: {
            userid: props.id,
            files,
          },
        });
      })
      .then(res => {
        const images = res.data.uploadImages;
        pictures.forEach(region => {
          if (region.area_affected === props.skinPictureLocation)
            images.push(...region.images);
        });
        props.onChange({
          area_affected: props.skinPictureLocation,
          images,
        });
      })
      .catch(console.error);
  };

  const deleteImage = id => {
    pictures.forEach(region => {
      const images = region.images.filter(image => image.id !== id);
      if (images.length !== region.images.length)
        props.onChange({
          area_affected: region.area_affected,
          images,
        });
    });
  };

  const visiblePictures = [];
  pictures.forEach(region => {
    if (
      region.area_affected === props.skinPictureLocation ||
      props.skinPictureLocation === 'all'
    )
      visiblePictures.push(
        ...region.images.map(i => ({
          src: i.uri,
          id: i.id,
          alt: i.alt,
          downloadUrl: i.uri,
        })),
      );
  });

  const [showingPictures, setShowingPictures] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };

  useEffect(() => {
    const viewportmeta = document.querySelector('meta[name="viewport"]');
    viewportmeta.content =
      'user-scalable=NO, width=device-width, initial-scale=1.0';
    return () => {
      const viewportmeta = document.querySelector('meta[name="viewport"]');
      viewportmeta.content = 'width=device-width, initial-scale=1.0';
    };
  }, []);

  return (
    <Fragment>
      <div className="skin-images">
        {!isMobile.any() && (
          <Viewer
            visible={showingPictures && !!visiblePictures.length}
            images={visiblePictures}
            rotatable={false}
            downloadable={true}
            downloadInNewWindow={true}
            scalable={false}
            zoomSpeed={0.5}
            minScale={0.5}
            activeIndex={activeIndex}
            customToolbar={toolbar => {
              return toolbar.concat({
                key: 'delete',
                onClick: e => {
                  deleteImage(e.id);
                },
                render: <span className={'material-icons'}>delete</span>,
              });
            }}
            onClose={() => {
              setShowingPictures(false);
            }}
            onMaskClick={() => {
              setShowingPictures(false);
            }}
          />
        )}
        {props.skinPictureLocation !== 'all' && (
          <React.Fragment>
            <div className="" style={{ display: 'inline-block' }}>
              <label
                htmlFor="upload-skin-images"
                className={
                  'skin-file-upload button button-regular button__green'
                }>
                Upload
              </label>
              <input
                type="file"
                name="file1"
                id="upload-skin-images"
                className={'skin-file-upload'}
                accept="image/png, image/jpeg"
                multiple
                onChange={onInputChange}
              />
            </div>
          </React.Fragment>
        )}
        <div className={'images'}>
          {visiblePictures.map((image, i) => (
            <div key={i}>
              <img
                src={image.src}
                alt="temp"
                onClick={() => {
                  setActiveIndex(i);
                  setShowingPictures(true);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default compose(withUploadImages)(SkinTrackerImages);
