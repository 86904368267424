export const IconsName = {
  ItchIcon: 'Icon',
  PainIcon: 'Pain',
  SkinIcon: 'Skin',
  SleepIcon: 'Sleep',
  StressIcon: 'Stress',
  TreatmentIcon: 'Treatment',
  TriggersIcon: 'Triggers',
  DietIcon: 'Diet',
  MetalsIcon: 'Metals',
  ChemicalsIcon: 'Chemicals',
  IrritantsIcon: 'Irritants',
  EnvironmentalAllergensIcon: 'Environmental Allergens',
  OtherIcon: 'Other',
};

export const ButtonsLabel = {
  SaveLabel: 'Save',
  AddLabel: 'Add',
  CancelLabel: 'Cancel',
};

export const TrackersLabel = {
  CustomFood: '',
  FoodContaining: '',
};

export const BodyAreas = [
  { "value": "anterior_lower_torso", "label": "Anterior Lower Torso" },
  { "value": "anterior_upper_torso", "label": "Anterior Upper Torso" },
  { "value": "anterior_head", "label": "Anterior Head" },
  { "value": "anterior_right_shoulder", "label": "Anterior Right Shoulder" },
  { "value": "anterior_right_upper_arm", "label": "Anterior Right Upper Arm" },
  { "value": "right_palm", "label": "Right Palm" },
  { "value": "anterior_right_forearm", "label": "Anterior Right Forearm" },
  { "value": "anterior_waist", "label": "Anterior Waist" },
  { "value": "genitals", "label": "Genitals" },
  { "value": "anterior_right_thigh", "label": "Anterior Right Thigh" },
  { "value": "anterior_left_thigh", "label": "Anterior Left Thigh" },
  { "value": "anterior_right_leg", "label": "Anterior Right Leg" },
  { "value": "anterior_left_leg", "label": "Anterior Left Leg" },
  { "value": "anterior_right_ankle_foot", "label": "Anterior Right Ankle Foot" },
  { "value": "anterior_left_ankle_foot", "label": "Anterior Left Ankle Foot" },
  { "value": "anterior_left_shoulder", "label": "Anterior Left Shoulder" },
  { "value": "anterior_left_upper_arm", "label": "Anterior Left Upper Arm" },
  { "value": "anterior_left_forearm", "label": "Anterior Left Forearm" },
  { "value": "left_palm", "label": "Left Palm" },
  { "value": "posterior_upper_torso", "label": "Posterior Upper Torso" },
  { "value": "posterior_head", "label": "Posterior Head" },
  { "value": "posterior_left_shoulder", "label": "Posterior Left Shoulder" },
  { "value": "posterior_left_upper_arm", "label": "Posterior Left Upper Arm" },
  { "value": "posterior_left_forearm", "label": "Posterior Left Forearm" },
  { "value": "left_hand", "label": "Left Hand" },
  { "value": "buttocks", "label": "Buttocks" },
  { "value": "posterior_waist", "label": "Posterior Waist" },
  { "value": "posterior_lower_torso", "label": "Posterior Lower Torso" },
  { "value": "posterior_right_shoulder", "label": "Posterior Right Shoulder" },
  { "value": "posterior_right_upper_arm", "label": "Posterior Right Upper Arm" },
  { "value": "posterior_right_forearm", "label": "Posterior Right Forearm" },
  { "value": "right_hand", "label": "Right Hand" },
  { "value": "posterior_right_leg", "label": "Posterior Right Leg" },
  { "value": "posterior_left_leg", "label": "Posterior Left Leg" },
  { "value": "posterior_left_ankle_foot", "label": "Posterior Left Ankle Foot" },
  { "value": "posterior_right_ankle_foot", "label": "Posterior Right Ankle Foot" }
]
