import React from 'react';

export default function BiologicIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8145 8.76239L11.4941 6.44194C11.3337 6.28197 11.074 6.28197 10.9141 6.44194C10.7537 6.60192 10.7537 6.86198 10.9141 7.02195L6.63579 11.3002C6.59026 11.3458 6.56852 11.4106 6.57754 11.4742L6.79904 13.0259C6.79371 13.0316 6.78592 13.0341 6.781 13.0407L5.18577 14.9259C5.15377 14.9636 5.15254 15.0198 5.18536 15.0588C5.22227 15.1023 5.28708 15.1076 5.33056 15.0707L7.2158 13.4755C7.22236 13.4705 7.22769 13.4636 7.23385 13.4574L8.7819 13.6785C8.8463 13.6875 8.9107 13.6662 8.95623 13.6207L9.46405 13.1129L8.30362 11.9528C8.22363 11.8729 8.22363 11.7428 8.30362 11.6628C8.38402 11.5824 8.51364 11.5824 8.59363 11.6628L9.75405 12.8229L10.1889 12.3876L9.60885 11.8076C9.52886 11.7276 9.52886 11.598 9.60885 11.5176C9.68883 11.4376 9.81886 11.4376 9.89885 11.5176L10.4789 12.0976L10.9141 11.6628L9.75405 10.5024C9.67366 10.4224 9.67366 10.2928 9.75405 10.2124C9.83404 10.1324 9.96366 10.1324 10.0441 10.2124L11.2041 11.3728L11.6389 10.9376L11.0593 10.3576C10.9789 10.2772 10.9789 10.1476 11.0593 10.0676C11.1397 9.98721 11.2693 9.98721 11.3493 10.0676L11.9293 10.6476L12.3645 10.2124L11.2045 9.05239C11.1237 8.97199 11.1237 8.84237 11.2045 8.76239C11.2845 8.68199 11.4141 8.68199 11.4945 8.76239L12.6545 9.9224L13.2345 9.34239C13.3949 9.50278 13.6541 9.50278 13.8145 9.34239C13.9749 9.18201 13.9749 8.92236 13.8145 8.76239Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6547 5.86168L12.9447 6.15169L12.3643 6.7317L13.5247 7.89213L14.1047 7.31171L14.3947 7.60212C14.5551 7.76209 14.8147 7.76209 14.9747 7.60212C15.1351 7.44174 15.1351 7.18209 14.9747 7.0217L13.2347 5.28127C13.0743 5.12129 12.8151 5.12129 12.6547 5.28127C12.4943 5.44165 12.4943 5.7013 12.6547 5.86168Z"
        fill="white"
      />
    </svg>
  );
}
