import React from 'react';

export default function LearnIcon({className}) {
  return (
    <svg
      className={className || 'icon icon-survey page-icon'}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 15 15"
      fill="none">
      <path
        d="M4.66667 10.6668C4.93188 10.6668 5.18624 10.5615 5.37377 10.3739C5.56131 10.1864 5.66667 9.93205 5.66667 9.66683C5.66992 9.63358 5.66992 9.60008 5.66667 9.56683L7.52667 7.70683H7.68H7.83333L8.90667 8.78016C8.90667 8.78016 8.90667 8.8135 8.90667 8.8335C8.90667 9.09871 9.01202 9.35307 9.19956 9.5406C9.3871 9.72814 9.64145 9.8335 9.90667 9.8335C10.1719 9.8335 10.4262 9.72814 10.6138 9.5406C10.8013 9.35307 10.9067 9.09871 10.9067 8.8335V8.78016L13.3333 6.3335C13.5311 6.3335 13.7245 6.27485 13.8889 6.16497C14.0534 6.05508 14.1815 5.89891 14.2572 5.71618C14.3329 5.53345 14.3527 5.33239 14.3141 5.13841C14.2755 4.94442 14.1803 4.76624 14.0404 4.62639C13.9006 4.48654 13.7224 4.3913 13.5284 4.35271C13.3344 4.31413 13.1334 4.33393 12.9507 4.40962C12.7679 4.4853 12.6117 4.61348 12.5019 4.77793C12.392 4.94238 12.3333 5.13571 12.3333 5.3335C12.3301 5.36675 12.3301 5.40024 12.3333 5.4335L9.92667 7.84016H9.82L8.66667 6.66683C8.66667 6.40161 8.56131 6.14726 8.37377 5.95972C8.18624 5.77219 7.93188 5.66683 7.66667 5.66683C7.40145 5.66683 7.1471 5.77219 6.95956 5.95972C6.77202 6.14726 6.66667 6.40161 6.66667 6.66683L4.66667 8.66683C4.40145 8.66683 4.1471 8.77219 3.95956 8.95972C3.77202 9.14726 3.66667 9.40161 3.66667 9.66683C3.66667 9.93205 3.77202 10.1864 3.95956 10.3739C4.1471 10.5615 4.40145 10.6668 4.66667 10.6668ZM13.6667 13.3335H2.33333V2.00016C2.33333 1.82335 2.2631 1.65378 2.13807 1.52876C2.01305 1.40373 1.84348 1.3335 1.66667 1.3335C1.48986 1.3335 1.32029 1.40373 1.19526 1.52876C1.07024 1.65378 1 1.82335 1 2.00016V14.0002C1 14.177 1.07024 14.3465 1.19526 14.4716C1.32029 14.5966 1.48986 14.6668 1.66667 14.6668H13.6667C13.8435 14.6668 14.013 14.5966 14.1381 14.4716C14.2631 14.3465 14.3333 14.177 14.3333 14.0002C14.3333 13.8234 14.2631 13.6538 14.1381 13.5288C14.013 13.4037 13.8435 13.3335 13.6667 13.3335Z"
        fill="#0E1217"
      />
    </svg>
  );
}
