import React from 'react';

import { useMutation } from 'react-apollo';

import { deleteImages } from '../../libs/graphql/mutations/image';

import Modal from '../shared/Modal';
import Button from '../shared/button';
import { UilExclamation } from '@iconscout/react-unicons'

const DeleteImages = ({me, imagesIds, isModalOpen, onClose, onDelete}) => {
     
  const [deleteImgs] = useMutation(deleteImages);   

  const removeImage = async () => {
    try {
      await deleteImgs({
        variables: {
            userid: +me.id,
            imagesIds
          },
       });

       onDelete();
    } catch (error) {
      console.log(error);
    }
    
  };

  return (
    <Modal 
      size='s'
      isOpen={isModalOpen}
      header={'Confirmation'}
      footer={
        <div className="button-row">
          <Button
            className="button button__secondary100-inverted button__btn-14-600 button-rectangle-small button-row-space"
            onClick={removeImage}
            text={'Yes, delete'}
          />
          <Button
            onClick={onClose}
            className="button button__secondary100 button__btn-14-600 button-rectangle-small"
            text={'Cancel'}
          />
        </div>}
      onClose={onClose}
    >
      <div className='delete-photo'>
        <div className='icon-delete-container'>
            <UilExclamation size="50" style={{color:'white'}} ></UilExclamation>
        </div>
        <span className='title'>Are you sure to want to delete this?</span>
        <p className='description'>This item will be deleted. <br/> You can’t undo this action.</p>
      </div>
    </Modal>
  );
}

export default DeleteImages;
