import React, { Fragment } from 'react';
//Images
import {
  Afternoon,
  AfternoonIcon,
  Bathing,
  BathingIcon,
  Bedtime,
  BedtimeIcon,
  Biologic,
  BiologicIcon,
  Evening,
  EveningIcon,
  Moisturizing,
  MoisturizingIcon,
  Morning,
  MorningIcon,
  OTC,
  OtherIcon,
  OtherTreatment,
  OtherTreatmentIcon,
  OverTheCounterIcon,
  Phototherapy,
  PhototherapyIcon,
  PrescriptionTopicalIcon,
  Systemic,
  SystemicsIcon,
  Topical,
} from '../../assets/icons';

// treatment is typed, selected is checkbox state, metadata would have time of day

const Treatment = ({
  treatment,
  treatmentId,
  name,
  selected,
  metadata,
  treatmentHandler,
  metadataHandler,
  read,
  errors,
}) => {
  let treatmentImage;
  switch (treatment.toLowerCase()) {
    case 'moisturizing':
      treatmentImage = <MoisturizingIcon />;
      break;
    case 'bathing':
      treatmentImage = <BathingIcon />;
      break;
    case 'Topicals'.toLowerCase():
      treatmentImage = <PrescriptionTopicalIcon />;
      break;
    case 'Phototherapy'.toLowerCase():
      treatmentImage = <PhototherapyIcon />;
      break;
    case 'Systemics'.toLowerCase():
      treatmentImage = <SystemicsIcon />;
      break;
    case 'Biologic'.toLowerCase():
      treatmentImage = <BiologicIcon />;
      break;
    case 'OTC'.toLowerCase():
      treatmentImage = <OverTheCounterIcon />;
      break;
    case 'Other'.toLowerCase():
      treatmentImage = <OtherIcon />;
      break;
    default:
      treatmentImage = <MoisturizingIcon />;
  }

  return (
    <Fragment>
      <span className="tracker-list-item tracker-list-item__flex-row">
        {read ? null : (
          <input
            className="form-input-checkbox"
            type="checkbox"
            checked={!!selected}
            onChange={treatmentHandler}
          />
        )}
        <div className="tracker-list-item-content-wrapper">
          <div className="treatment-image-wrapper tracker-list-settings-image tracker-settings-list-settings-image">
            {treatmentImage}
          </div>
          <div className="tracker-list-item-description-wrapper">
            <p className={`settings-treatment-name text__left tracker-treatment-text`}>{name}</p>
          </div>
          {read ? null : (
            <div className="treatment-icons-container">
              <div className={'tracker-meta' + (selected ? '' : ' inactive')}>
                <button
                  onClick={metadataHandler.bind(null, 'morning')}
                  className={
                    'button button-icon icon-time-morning' +
                    (!!selected && errors[treatmentId]
                      ? ' icon-time__error'
                      : metadata.morning
                      ? ' icon-time__selected'
                      : ' icon-time__unselected')
                  }>
                  <MorningIcon
                    errorSelect={!!selected && errors[treatmentId]}
                    isSelect={metadata.morning}
                  />
                </button>

                <button
                  onClick={metadataHandler.bind(null, 'afternoon')}
                  className={
                    'button button-icon icon-time-afternoon' +
                    (!!selected && errors[treatmentId]
                      ? ' icon-time__error'
                      : metadata.afternoon
                      ? ' icon-time__selected'
                      : ' icon-time__unselected')
                  }>
                  <AfternoonIcon
                    errorSelect={!!selected && errors[treatmentId]}
                    isSelect={metadata.afternoon}
                  />
                </button>

                <button
                  onClick={metadataHandler.bind(null, 'evening')}
                  className={
                    'button button-icon icon-time-evening' +
                    (!!selected && errors[treatmentId]
                      ? ' icon-time__error'
                      : metadata.evening
                      ? ' icon-time__selected'
                      : ' icon-time__unselected')
                  }>
                  <EveningIcon
                    errorSelect={!!selected && errors[treatmentId]}
                    isSelect={metadata.evening}
                  />
                </button>

                <button
                  onClick={metadataHandler.bind(null, 'bedtime')}
                  className={
                    'button button-icon icon-time-bedtime'}>
                  <BedtimeIcon errorSelect={!!selected && errors[treatmentId]} isSelect={metadata.bedtime}/>
                </button>
              </div>
              {!!selected && errors[treatmentId] ? (
                <span className="alert-paragraph__select-error subtext tracker-list-item__alert">
                  Error: Assign time of day to this treatment
                </span>
              ) : null}
            </div>
          )}
        </div>
      </span>
    </Fragment>
  );
};

export default Treatment;
