import React from 'react';

export default function ManageIcon({className}) {
  return (
    <svg
      className={className || 'icon icon-survey page-icon'}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 15 15"
      fill="none">
      <path
        d="M12.6667 2.66683H11.3334V2.00016C11.3334 1.82335 11.2631 1.65378 11.1381 1.52876C11.0131 1.40373 10.8435 1.3335 10.6667 1.3335C10.4899 1.3335 10.3203 1.40373 10.1953 1.52876C10.0703 1.65378 10 1.82335 10 2.00016V2.66683H6.00004V2.00016C6.00004 1.82335 5.9298 1.65378 5.80478 1.52876C5.67975 1.40373 5.51019 1.3335 5.33337 1.3335C5.15656 1.3335 4.98699 1.40373 4.86197 1.52876C4.73695 1.65378 4.66671 1.82335 4.66671 2.00016V2.66683H3.33337C2.80294 2.66683 2.29423 2.87754 1.91916 3.25262C1.54409 3.62769 1.33337 4.1364 1.33337 4.66683V12.6668C1.33337 13.1973 1.54409 13.706 1.91916 14.081C2.29423 14.4561 2.80294 14.6668 3.33337 14.6668H12.6667C13.1971 14.6668 13.7058 14.4561 14.0809 14.081C14.456 13.706 14.6667 13.1973 14.6667 12.6668V4.66683C14.6667 4.1364 14.456 3.62769 14.0809 3.25262C13.7058 2.87754 13.1971 2.66683 12.6667 2.66683ZM13.3334 12.6668C13.3334 12.8436 13.2631 13.0132 13.1381 13.1382C13.0131 13.2633 12.8435 13.3335 12.6667 13.3335H3.33337C3.15656 13.3335 2.98699 13.2633 2.86197 13.1382C2.73695 13.0132 2.66671 12.8436 2.66671 12.6668V8.00016H13.3334V12.6668ZM13.3334 6.66683H2.66671V4.66683C2.66671 4.49002 2.73695 4.32045 2.86197 4.19543C2.98699 4.0704 3.15656 4.00016 3.33337 4.00016H4.66671V4.66683C4.66671 4.84364 4.73695 5.01321 4.86197 5.13823C4.98699 5.26326 5.15656 5.3335 5.33337 5.3335C5.51019 5.3335 5.67975 5.26326 5.80478 5.13823C5.9298 5.01321 6.00004 4.84364 6.00004 4.66683V4.00016H10V4.66683C10 4.84364 10.0703 5.01321 10.1953 5.13823C10.3203 5.26326 10.4899 5.3335 10.6667 5.3335C10.8435 5.3335 11.0131 5.26326 11.1381 5.13823C11.2631 5.01321 11.3334 4.84364 11.3334 4.66683V4.00016H12.6667C12.8435 4.00016 13.0131 4.0704 13.1381 4.19543C13.2631 4.32045 13.3334 4.49002 13.3334 4.66683V6.66683Z"
        fill="#0E1217"
      />
    </svg>
  );
}
