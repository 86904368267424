import React from 'react';

export default function HamburgerMenuBars() {
  return (
    <svg
      width="112"
      height="115"
      viewBox="0 0 112 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1226 22.5569L2.15767 73.3245C1.9179 74.3284 1.73382 75.3493 1.58547 76.3713C0.657222 80.7198 0.612569 85.2481 1.66648 89.6053C2.71187 93.9061 4.63071 97.7072 7.1645 100.94C10.7062 105.99 15.8045 109.912 22.4102 111.628C24.3873 112.143 26.4371 112.449 28.5127 112.579C31.8483 112.945 43.2993 105.992 46.7184 105.283C62.6724 101.98 70.6121 105.52 86.5711 102.217C105.97 98.2062 115.54 74.6465 106.233 57.7994C100.885 48.1242 87.5564 45.2261 80.2877 37.0351C74.126 30.0879 71.9134 17.5085 65.72 10.5961C51.0049 -5.82686 19.4145 0.0784025 14.1226 22.5569Z"
        fill="#C7EAF7"
      />
      <path
        d="M48.7793 63.8957C48.0044 63.8957 47.2612 64.2035 46.7133 64.7514C46.1654 65.2993 45.8576 66.0425 45.8576 66.8173V75.5824C45.8576 76.3573 46.1654 77.1004 46.7133 77.6484C47.2612 78.1963 48.0044 78.5041 48.7793 78.5041C49.5541 78.5041 50.2973 78.1963 50.8452 77.6484C51.3931 77.1004 51.701 76.3573 51.701 75.5824V66.8173C51.701 66.0425 51.3931 65.2993 50.8452 64.7514C50.2973 64.2035 49.5541 63.8957 48.7793 63.8957ZM63.3877 52.2089V46.3655C63.3877 42.4911 61.8486 38.7754 59.109 36.0358C56.3694 33.2962 52.6537 31.7571 48.7793 31.7571C44.9049 31.7571 41.1892 33.2962 38.4495 36.0358C35.7099 38.7754 34.1708 42.4911 34.1708 46.3655V52.2089C31.8462 52.2089 29.6168 53.1324 27.973 54.7761C26.3292 56.4199 25.4058 58.6493 25.4058 60.974V81.4258C25.4058 83.7504 26.3292 85.9799 27.973 87.6236C29.6168 89.2674 31.8462 90.1909 34.1708 90.1909H63.3877C65.7124 90.1909 67.9418 89.2674 69.5856 87.6236C71.2293 85.9799 72.1528 83.7504 72.1528 81.4258V60.974C72.1528 58.6493 71.2293 56.4199 69.5856 54.7761C67.9418 53.1324 65.7124 52.2089 63.3877 52.2089ZM40.0142 46.3655C40.0142 44.0409 40.9377 41.8115 42.5814 40.1677C44.2252 38.5239 46.4546 37.6005 48.7793 37.6005C51.1039 37.6005 53.3333 38.5239 54.9771 40.1677C56.6209 41.8115 57.5443 44.0409 57.5443 46.3655V52.2089H40.0142V46.3655ZM66.3094 81.4258C66.3094 82.2007 66.0016 82.9438 65.4537 83.4917C64.9057 84.0397 64.1626 84.3475 63.3877 84.3475H34.1708C33.3959 84.3475 32.6528 84.0397 32.1049 83.4917C31.557 82.9438 31.2491 82.2007 31.2491 81.4258V60.974C31.2491 60.1991 31.557 59.4559 32.1049 58.908C32.6528 58.3601 33.3959 58.0523 34.1708 58.0523H63.3877C64.1626 58.0523 64.9057 58.3601 65.4537 58.908C66.0016 59.4559 66.3094 60.1991 66.3094 60.974V81.4258Z"
        fill="#00A1D9"
      />
      <path
        d="M51.0659 63.8957C50.291 63.8957 49.5479 64.2035 48.9999 64.7514C48.452 65.2993 48.1442 66.0425 48.1442 66.8173V75.5824C48.1442 76.3573 48.452 77.1004 48.9999 77.6484C49.5479 78.1963 50.291 78.5041 51.0659 78.5041C51.8408 78.5041 52.5839 78.1963 53.1318 77.6484C53.6798 77.1004 53.9876 76.3573 53.9876 75.5824V66.8173C53.9876 66.0425 53.6798 65.2993 53.1318 64.7514C52.5839 64.2035 51.8408 63.8957 51.0659 63.8957ZM65.6743 52.2089V46.3655C65.6743 42.4911 64.1352 38.7754 61.3956 36.0358C58.656 33.2962 54.9403 31.7571 51.0659 31.7571C47.1915 31.7571 43.4758 33.2962 40.7362 36.0358C37.9965 38.7754 36.4574 42.4911 36.4574 46.3655V52.2089C34.1328 52.2089 31.9034 53.1324 30.2596 54.7761C28.6158 56.4199 27.6924 58.6493 27.6924 60.974V81.4258C27.6924 83.7504 28.6158 85.9799 30.2596 87.6236C31.9034 89.2674 34.1328 90.1909 36.4574 90.1909H65.6743C67.999 90.1909 70.2284 89.2674 71.8722 87.6236C73.5159 85.9799 74.4394 83.7504 74.4394 81.4258V60.974C74.4394 58.6493 73.5159 56.4199 71.8722 54.7761C70.2284 53.1324 67.999 52.2089 65.6743 52.2089ZM42.3008 46.3655C42.3008 44.0409 43.2243 41.8115 44.8681 40.1677C46.5118 38.5239 48.7413 37.6005 51.0659 37.6005C53.3905 37.6005 55.62 38.5239 57.2637 40.1677C58.9075 41.8115 59.831 44.0409 59.831 46.3655V52.2089H42.3008V46.3655ZM68.596 81.4258C68.596 82.2007 68.2882 82.9438 67.7403 83.4917C67.1924 84.0397 66.4492 84.3475 65.6743 84.3475H36.4574C35.6826 84.3475 34.9394 84.0397 34.3915 83.4917C33.8436 82.9438 33.5358 82.2007 33.5358 81.4258V60.974C33.5358 60.1991 33.8436 59.4559 34.3915 58.908C34.9394 58.3601 35.6826 58.0523 36.4574 58.0523H65.6743C66.4492 58.0523 67.1924 58.3601 67.7403 58.908C68.2882 59.4559 68.596 60.1991 68.596 60.974V81.4258Z"
        fill="#092A6D"
      />
    </svg>
  );
}
