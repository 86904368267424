import moment from 'moment-timezone';

export default {
  mixpanelToken:
    process.env.REACT_APP_SERVER_ENV === 'production'
      ? 'ec8f2775cfa44a735bc2db50aec33051'
      : 'bd28b9039a2b57710bcf16e5846415ef',
  firebaseConfig: {
    apiKey: 'AIzaSyAJuavLgJrLpv7OWzcUyhhO0ufHUaVolig',
    authDomain: 'eczemawise.firebaseapp.com',
    databaseURL: 'https://eczemawise.firebaseio.com',
    projectId: 'eczemawise',
    storageBucket: 'eczemawise.appspot.com',
    messagingSenderId: '42388738796',
    appId: '1:42388738796:web:1c34abc21e56158dd18bc9',
    measurementId: 'G-9JQEN8C7F0',
  },
  firebaseWebToken:
    'BCWDN5KQ-c2n2hii6aIWyOgenmoIFXBeKDAd4mSifYL5-mZI5pxIwu_B0LjpF0EdPkj7ROXL6gY1PCFN-ixgEfc',
  wordpress: {
    URL: 'https://nationaleczema.org/wp-json/nea/v1',
    perPage: 15,
  },
  trackerTheme: {
    diet: 'tracker-theme-diet',
    itch: 'tracker-theme-itch',
    pain: 'tracker-theme-pain',
    sleep: 'tracker-theme-sleep',
    skin: 'tracker-theme-skin',
    stress: 'tracker-theme-stress',
    treatment: 'tracker-theme-treatment',
    trigger: 'tracker-theme-trigger',
  },
  trendsConfig: {
    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  states: [
    {
      value: 'Alabama',
      label: 'AL',
    },
    {
      value: 'Alaska',
      label: 'AK',
    },
    {
      value: 'American Samoa',
      label: 'AS',
    },
    {
      value: 'Arizona',
      label: 'AZ',
    },
    {
      value: 'Arkansas',
      label: 'AR',
    },
    {
      value: 'California',
      label: 'CA',
    },
    {
      value: 'Colorado',
      label: 'CO',
    },
    {
      value: 'Connecticut',
      label: 'CT',
    },
    {
      value: 'Delaware',
      label: 'DE',
    },
    {
      value: 'District Of Columbia',
      label: 'DC',
    },
    {
      value: 'Federated States Of Micronesia',
      label: 'FM',
    },
    {
      value: 'Florida',
      label: 'FL',
    },
    {
      value: 'Georgia',
      label: 'GA',
    },
    {
      value: 'Guam Gu',
      label: 'GU',
    },
    {
      value: 'Hawaii',
      label: 'HI',
    },
    {
      value: 'Idaho',
      label: 'ID',
    },
    {
      value: 'Illinois',
      label: 'IL',
    },
    {
      value: 'Indiana',
      label: 'IN',
    },
    {
      value: 'Iowa',
      label: 'IA',
    },
    {
      value: 'Kansas',
      label: 'KS',
    },
    {
      value: 'Kentucky',
      label: 'KY',
    },
    {
      value: 'Louisiana',
      label: 'LA',
    },
    {
      value: 'Maine',
      label: 'ME',
    },
    {
      value: 'Marshall Islands',
      label: 'MH',
    },
    {
      value: 'Maryland',
      label: 'MD',
    },
    {
      value: 'Massachusetts',
      label: 'MA',
    },
    {
      value: 'Michigan',
      label: 'MI',
    },
    {
      value: 'Minnesota',
      label: 'MN',
    },
    {
      value: 'Mississippi',
      label: 'MS',
    },
    {
      value: 'Missouri',
      label: 'MO',
    },
    {
      value: 'Montana',
      label: 'MT',
    },
    {
      value: 'Nebraska',
      label: 'NE',
    },
    {
      value: 'Nevada',
      label: 'NV',
    },
    {
      value: 'New Hampshire',
      label: 'NH',
    },
    {
      value: 'New Jersey',
      label: 'NJ',
    },
    {
      value: 'New Mexico',
      label: 'NM',
    },
    {
      value: 'New York',
      label: 'NY',
    },
    {
      value: 'North Carolina',
      label: 'NC',
    },
    {
      value: 'North Dakota',
      label: 'ND',
    },
    {
      value: 'Northern Mariana Islands',
      label: 'MP',
    },
    {
      value: 'Ohio',
      label: 'OH',
    },
    {
      value: 'Oklahoma',
      label: 'OK',
    },
    {
      value: 'Oregon',
      label: 'OR',
    },
    {
      value: 'Palau',
      label: 'PW',
    },
    {
      value: 'Pennsylvania',
      label: 'PA',
    },
    {
      value: 'Puerto Rico',
      label: 'PR',
    },
    {
      value: 'Rhode Island',
      label: 'RI',
    },
    {
      value: 'South Carolina',
      label: 'SC',
    },
    {
      value: 'South Dakota',
      label: 'SD',
    },
    {
      value: 'Tennessee',
      label: 'TN',
    },
    {
      value: 'Texas',
      label: 'TX',
    },
    {
      value: 'Utah',
      label: 'UT',
    },
    {
      value: 'Vermont',
      label: 'VT',
    },
    {
      value: 'Virgin Islands',
      label: 'VI',
    },
    {
      value: 'Virginia',
      label: 'VA',
    },
    {
      value: 'Washington',
      label: 'WA',
    },
    {
      value: 'West Virginia',
      label: 'WV',
    },
    {
      value: 'Wisconsin',
      label: 'WI',
    },
    {
      value: 'Wyoming',
      label: 'WY',
    },
  ],
  environmentURL:
    process.env.REACT_APP_SERVER_ENV === 'production'
      ? 'https://www.eczemawise.org'
      : 'http://eczema-development.us-west-2.elasticbeanstalk.com',
  env: process.env.REACT_APP_SERVER_ENV,    
};
