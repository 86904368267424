import { flowRight as compose } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { withTriggerTracker } from '../../apollo/stores/trackers';
import TriggerTrackerWriteContainer from '../../containers/TriggerTrackerWriteContainer';
import analytics from '../../libs/analytics';
// Queries
import { DailyUserData } from '../../libs/graphql/queries/data';
import MixpanelService, { MixpanelEvents, MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT } from '../../libs/mixpanel';
import Directions from '../shared/Directions';
import SubmitBar from '../shared/submit-bar';
import TrackerHeader from '../shared/tracker-header';
import TrackerInstructions from '../shared/TrackerInstructions';
import TriggerReadView from './TriggerReadView';

class TriggerTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      write: true,
      instructions: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    let { data } = nextProps;
    //if number of triggers has changed or we have transitioned from selected to unselected go to read state
    if (
      data &&
      data.data &&
      this.props.data &&
      this.props.data.data &&
      data.data.length !== this.props.data.data.length
    ) {
      //might need this method
      this.setState({ write: false });
    }
  }

  componentDidMount() {
    let { data } = this.props;
    //if data already exists, go straight to read state
    if (data && data.data && data.data.length) {
      //might need this method
      this.setState({ write: false });
    }
  }

  componentWillUnmount() {
    this.props.resetTriggerStore({
      variables: {},
    });
  }

  render() {
    const {
      triggerOptions,
      data,
      triggerStore,
      deleteTrigger,
      updateTriggersSelections,
      updateTriggerTypeSelection,
    } = this.props;
    const { write, instructions } = this.state;
    if (!data || !triggerStore) return null;

    return (
      <div
        className={`card tracker tracker-loaded tracker-${
          this.props.theme.title
        } ${this.progressBadge()} ${this.props.theme.class || ''} ${
          write
            ? this.props.theme.color + ' card__active tracker__active'
            : this.props.theme.color +
              ' tracker__inactive tracker__inactive__tall'
        } card__width-2`}>
        <TrackerHeader
          title={this.props.theme.title}
          write={write}
          icon={this.props.icon}
          edit={this._toggleWrite.bind(this)}
          instructions={this.toggleInstructions.bind(this)}
          instructionsClass={instructions ? 'tracker-header__instructions' : ''}
        />
        <div
          className="tracker-body tracker-body__scroll"
          onClick={this._toggleWrite.bind(this)}>
          {instructions ? (
            <TrackerInstructions
              instructions={this.props.theme.instructionsText}
            />
          ) : null}
          {write && (
            <Directions directionText={this.props.theme.directionText} alignLeftText={true} />
          )}
          <div className="form tracker-body-form tracker-body__grid">
            {!instructions ? (
              write ? (
                <TriggerTrackerWriteContainer
                  data={data}
                  options={triggerOptions}
                  triggerStore={triggerStore}
                  deleteTrigger={deleteTrigger}
                  updateTriggersSelections={updateTriggersSelections}
                  updateTriggerTypeSelection={updateTriggerTypeSelection}
                />
              ) : (
                <TriggerReadView data={data.data} options={triggerOptions} />
              )
            ) : null}
          </div>
        </div>
        {write ? (
          <SubmitBar
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.submitData.bind(this)}
            disableSubmit={this.state.instructions}
            disableCancel={this.state.instructions}
          />
        ) : null}
      </div>
    );
  }

  progressBadge() {
    if (!this.state.write) {
      return 'tracker-progress-complete';
    } else {
      return 'tracker-progress-incomplete';
    }
  }

  // Prevent opening write view if instructions are open
  _toggleWrite() {
    if (!this.state.write && !this.state.instructions) {
      this.setState({
        write: true,
      });
    }
  }

  toggleInstructions() {
    let instructions = !this.state.instructions;
    this.setState({
      instructions,
    });
  }

  onCancel() {
    let { data } = this.props;
    if (data && data.data && data.data.length) {
      //might need this method
      this.setState({ write: false });
    } else {
      // need to uncheck or unselect any triggers and types that have been selected
      this.props.resetTriggerStore({
        variables: {},
      });
    }
  }

  submitData() {
    let triggerPayload = this.props.triggerStore.data.map(trigger => {
      if (trigger.trigger_id.startsWith('__'))
        return {
          trigger_type_id: trigger.typeid,
          selected: trigger.selected,
          trigger_name: trigger.trigger,
          trigger_selected: trigger.trigger_selected,
        };

      return {
        trigger_type_id: trigger.typeid,
        selected: trigger.selected,
        trigger_id: trigger.trigger_id,
        trigger_selected: trigger.trigger_selected,
      };
    });

    return this.props
      .changeHandler({
        variables: {
          id: this.props.id,
          data: triggerPayload,
          client_date: this.props.date || moment().format('YYYY-MM-DD'),
        },
        refetchQueries: () => [
          {
            query: DailyUserData,
            variables: {
              id: this.props.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
              readOnly: true,
            },
            fetchPolicy: 'cache-and-network',
          },
        ],
      })
      .then(res => {
        const MetalTrigger = [];
        const ChemicalTrigger = [];
        const IrritantsTrigger = [];
        const EnvironmentalTrigger = [];
        const OtherTrigger = [];

        this.props.triggerStore.data.forEach(trigger => {
          const { type, trigger: name, selected } = trigger;
          if (!selected) return;

          switch (type) {
            case 'Metals':
              MetalTrigger.push(name);
              break;
            case 'Chemicals':
              ChemicalTrigger.push(name);
              break;
            case 'Irritants':
              IrritantsTrigger.push(name);
              break;
            case 'Environmental Allergens':
              EnvironmentalTrigger.push(name);
              break;
            case 'Other':
              OtherTrigger.push(name);
              break;
            default:
              break;
          }
        });

        const triggerData = {
          MetalTrigger: MetalTrigger.join(', '),
          ChemicalTrigger: ChemicalTrigger.join(', '),
          IrritantsTrigger: IrritantsTrigger.join(', '),
          EnvironmentalTrigger: EnvironmentalTrigger.join(', '),
          OtherTrigger: OtherTrigger.join(', '),
          TriggerDate: moment.utc(this.props.date).format(`${MIXPANEL_DAY_FORMAT}T${MIXPANEL_TIME_FORMAT}`),
        };
        MixpanelService.track(MixpanelEvents.TriggerTrackerUpdated, triggerData);
        analytics().logEvent('any_track_item_updated', {});
        this.setState({
          write: false,
        });
      });
  }
}

export default compose(withTriggerTracker)(TriggerTracker);
