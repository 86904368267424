import React, { useState, useRef, useEffect } from 'react';
import { CloseIcon } from '../../assets/icons';

function Tutorial({
  children,
  titles,
  subtitles,
  texts,
  urlsGif,
  onClickNext,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const tooltipContentRef = useRef(null);
  const tooltipIndicatorRef = useRef(null);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
    setCurrentIndex(0);
    onClickNext(titles[0]);
  };

  const nextItem = () => {
    if (currentIndex < titles.length - 1) {
      setCurrentIndex(currentIndex + 1);
      onClickNext(titles[currentIndex + 1]);
      positionTooltip();
    }
  };

  const previousItem = () => {
    if (currentIndex < titles.length + 1) {
      setCurrentIndex(currentIndex - 1);
      onClickNext(titles[currentIndex - 1]);
      positionTooltip();
    }
  };

  useEffect(() => {
    positionTooltip();
  }, [showTooltip, currentIndex]);

  const positionTooltip = () => {
    const exceptionElement = document.querySelectorAll('.exception-element');
    const exceptionElementRect = exceptionElement[0]?.getBoundingClientRect();
    if (exceptionElementRect && tooltipContentRef.current) {
      const tooltipContentRect =
        tooltipContentRef.current.getBoundingClientRect();
      const tooltipWidth = 300; // Tooltip width
      const tooltipIndicatorPosition = { top: '10px', left: '-5px' };

      // Calculate the initial position
      let left = exceptionElementRect.left + exceptionElementRect.width + 9;
      let top = exceptionElementRect.top - 10;

      // Check if the tooltip goes out of the window on the X-axis
      if (left + tooltipWidth > window.innerWidth) {
        left = window.innerWidth / 2 - tooltipWidth / 2; // Center on the X-axis
        top = top - tooltipContentRect.height;
        tooltipIndicatorPosition.left = '50%';
        tooltipIndicatorPosition.top = 'calc(100% - 7px)';
      }

      // Check if the tooltip goes out of the window on the Y-axis (upwards)
      if (top < 0) {
        // Place it below in the center
        top = exceptionElementRect.bottom + 5;
        tooltipIndicatorPosition.left = '50%';
        tooltipIndicatorPosition.top = '-7px';
      }

      tooltipContentRef.current.style.left = left + 'px';
      tooltipContentRef.current.style.top = top + 'px';

      tooltipIndicatorRef.current.style.left = tooltipIndicatorPosition.left;
      tooltipIndicatorRef.current.style.top = tooltipIndicatorPosition.top;
    }
  };

  return (
    <>
      <div
        className={`overlay ${showTooltip ? 'visible' : ''}`}
        onClick={toggleTooltip}></div>
      <div
        onClick={toggleTooltip}
        className={`tooltip-container ${
          showTooltip && currentIndex === 0 && 'exception-element'
        }  exception-element-icon`}>
        {children}
      </div>
      {showTooltip &&
        (currentIndex !== 0 ? (
          <>
            <div className={'tooltip-white-content'} ref={tooltipContentRef}>
              <div
                className={'tooltip-content-indicator-white'}
                ref={tooltipIndicatorRef}></div>
              <div className="title-close-container">
                <h1 className={'title-white-card'}>{titles[currentIndex]}</h1>
                <button
                  className="close-button-tutorial"
                  onClick={toggleTooltip}>
                  <CloseIcon />
                </button>
              </div>
              {subtitles[currentIndex].length > 0 && (
                <>
                  <hr />
                  <img src={urlsGif[currentIndex]} alt="NEA gif" />
                  <br />
                  <p className="subtitles">{subtitles[currentIndex]}</p>
                </>
              )}
              <p className={'text-white-card'}>{texts[currentIndex]}</p>
              {subtitles[currentIndex].length > 0 && (
                <>
                  <br />
                  <br />
                  <div className="stepper-points">
                    {currentIndex === titles.length - 1 ? (
                      <div className="container-dots">
                        <div className="circle2"></div>
                        <div className="circle1"></div>
                      </div>
                    ) : (
                      <div className="container-dots">
                        <div className="circle1"></div>
                        <div className="circle2"></div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <hr />
              <div>
                {currentIndex === titles.length - 1 ? (
                  <div className="actions2">
                    <span
                      className="action-buttons margin-buttons"
                      onClick={previousItem}>
                      Previous
                    </span>
                    <span className="action-buttons" onClick={toggleTooltip}>
                      Finish
                    </span>
                  </div>
                ) : (
                  <div className="actions2">
                    <span
                      className="action-buttons margin-buttons"
                      onClick={previousItem}>
                      Previous
                    </span>
                    <span className="action-buttons" onClick={nextItem}>
                      Next step
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tooltip-white-content" ref={tooltipContentRef}>
              <div
                className="tooltip-content-indicator-white"
                ref={tooltipIndicatorRef}></div>
              <div className="title-close-container">
                <h1 className={'title-white-card'}>{titles[currentIndex]}</h1>
                <button
                  className="close-button-tutorial"
                  onClick={toggleTooltip}>
                  <CloseIcon />
                </button>
              </div>
              <p className="text-white-card">{texts[currentIndex]}</p>
              <hr />
              <div className="actions">
                {currentIndex < titles.length - 1 && (
                  <span className="action-buttons" onClick={nextItem}>
                    {currentIndex === 0 ? 'Find out how' : 'Next'}
                  </span>
                )}
              </div>
            </div>
          </>
        ))}
    </>
  );
}

export default Tutorial;
