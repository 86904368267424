import React, { Component, Fragment } from 'react';
import { TreatmentIcon } from '../../assets/icons/trackers';
import TreatmentSelection from '../shared/TreatmentSelection';
import Treatment from '../trackers/Treatment';
import CheckboxListReverse from '../shared/CheckboxListReverse';

const treatmentTypes = [
  'Topicals',
  'Phototherapy',
  'Systemics',
  'Biologic',
  'OTC',
  'Other',
];

class EditTreatments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      namesToAdd: {},
      errors: {},
    };
  }

  _readTreatments() {
    let readList = [];
    this.props.treatments.forEach((treat, i) => {
      readList.push(
        <Treatment
          treatment={treat.type}
          read={true}
          name={treat.name || treat.type}
          key={treat + i}
        />,
      );
    });
    return readList;
  }

  _formatTreatmentsForWrite() {
    let treatmentsObject = {};
    this.props.treatments.forEach(treatment => {
      if (!treatmentsObject[treatment.type]) {
        treatmentsObject[treatment.type] = [treatment];
      } else {
        treatmentsObject[treatment.type].push(treatment);
      }
    });

    return treatmentsObject;
  }

  _writeTreatments() {
    let treatmentsObject = this._formatTreatmentsForWrite();
    let items = treatmentTypes.map(treatment => {
      let checked = this.props.selectedTreatments.includes(treatment);
      let treatmentsOfType = treatmentsObject[treatment] || [];
      return {
        changeHandler: this.props.updateTreatments.bind(
          null,
          treatment,
          treatment,
        ),
        checked: checked,
        label: (
          <TreatmentSelection
            type={treatment}
            userTreatments={treatmentsOfType}
            typeSelected={checked}
            namesToAdd={this.state.namesToAdd}
            errors={this.state.errors}
            nameHandler={this.nameHandler.bind(this)}
            addHandler={this.addTreatment.bind(this)}
            deleteHandler={this.deleteTreatment.bind(this)}
          />
        ),
      };
    });

    return items;
  }

  render() {
    return (
      <>
        <div className="section-wrapper__inner">
          <div className="login login-registration">
            <h3 className="title">
              Edit Your Treatments
            </h3>
            <p className='description'>
              Edit, add or delete the Treatment options displayed in your
              Treatment Tracker. Reminders will be sent based on the
              timezone collected from your zip code.
            </p>
            <form className="form">
              {!this.props.editFlag ? (
                <div className="form-item">
                  <h4 className="subtext subtext__neutral-black subtext__uppercase">
                    Your treatment list
                  </h4>
                </div>
              ) : (
                <>
                  <h5 className="help-text">Select your type of treatment</h5>
                </>
              )}
              {!this.props.editFlag ? (
                <ul className="form-item">
                  {this._readTreatments()}
                </ul>
              ) : (
                <CheckboxListReverse
                  list={this._writeTreatments()}
                  classNameOverride="registration-treatment-checklist"
                />
              )}
            </form>
          </div>
        </div>
        <div className="form-item-right card-footer">
      {this.props.editFlag ? (
        <button
        className="button button__secondary100-inverted  button__btn-14-600 button-rectangle-medium"
        onClick={this.props.resetTreatmentList}>
          Cancel
        </button>
      ) : null}
      <button
        className="button button__secondary100 button__btn-14-600 button-rectangle-medium"
        onClick={this.treatmentToggle.bind(this)}>
        {!this.props.editFlag
          ? 'Edit Treatments List'
          : 'Update Treatments'}
      </button>
        </div>
      </>
    );
  }

  treatmentToggle(e) {
    e.preventDefault();
    let err;
    // check for errors before updating. if errors return
    if (this.props.editFlag) {
      err = this._errorChecks();
      if (Object.keys(err).length) {
        this.setState({
          errors: err,
        });
        return;
      }
    }
    this.props.addTreatmentToggle();
    this.setState({
      namesToAdd: {},
      errors: {},
    });
  }

  _errorChecks() {
    //error object
    let e = {};

    //check for names that have been typed into input but NOT added with + button
    if (Object.keys(this.state.namesToAdd).length) {
      Object.keys(this.state.namesToAdd).forEach(type => {
        if (this.state.namesToAdd[type]) {
          e[type] = { type: 'name_not_added' };
        }
      });
    }
    // check for selected items names if applicable. i.e. an item that has been selected but no name given
    this.props.selectedTreatments.forEach(t => {
      let active = false;
      //check to make sure there is a treatment for each selection
      active = !!this.props.treatments.find(trt => {
        return trt.type === t && trt.active;
      });

      if (!active && !e[t]) {
        e[t] = { type: 'missing_name' };
      }
    });
    return e;
  }

  nameHandler(type, value) {
    let newNamesToAdd = this.state.namesToAdd;
    newNamesToAdd[type] = value;
    this.setState({
      namesToAdd: newNamesToAdd,
    });
  }

  addTreatment(type, name) {
    const { treatments, addNameToTreatment } = this.props;  
    // Create a copy of the state
    let newState = { ...this.state };
    // Check for duplicate name for type
    const duplicate = treatments.find(t => t.type === type && t.name === name && t.active);
  
    if (duplicate) {
      newState.errors[type] = { type: 'duplicate' };
    } else {
      delete newState.errors[type];
      addNameToTreatment(type, name);
    }
    delete newState.namesToAdd[type];
    // Update the state
    this.setState(newState);
  }  

  deleteTreatment(id) {
    this.props.deleteTreatment(id);
  }
}

export default EditTreatments;
