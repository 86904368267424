import React from 'react';

export default function SurveysPhotoIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0645 19.1687H32.5881L30.9828 14.1816L18.0645 19.1687ZM34.3512 18.9828H37.4292V36H14.6552V32.1077L10.5 19.9869L31.9666 12L34.3512 18.9828ZM14.736 26.9611V20.104L12.618 20.875L14.736 26.9611ZM21.5242 23.5326C22.4642 23.5326 23.2082 24.3082 23.2082 25.2468C23.2082 26.1855 22.4642 26.9611 21.5242 26.9611C20.6239 26.9611 19.8799 26.1855 19.8799 25.2468C19.8799 24.3082 20.6239 23.5326 21.5242 23.5326ZM30.9358 23.5326L34.4035 28.2079V32.8832H18.0645V30.3633C19.2594 29.4284 20.6851 28.7371 22.2645 28.7371H27.081L30.9345 23.5339L30.9358 23.5326ZM16.249 20.4157H35.6139V34.13H16.249V20.4157Z"
        fill="white"
      />
    </svg>
  );
}
