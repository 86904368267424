import Complete from '../images/complete.png';

export default function ProgressStep({ step }) {
  return (
    <span>
      <img className="badge__complete" src={Complete} alt={'badge'} />
      <div className="counter-backline" />
      <svg
        className="progress-counter svg-progress-counter"
        width="20px"
        height="20px"
        viewBox="0 0 34 34">
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(1, 1)">
          <circle
            className="progress-counter__circle"
            strokeWidth="2"
            cx="16"
            cy="16"
            r="16"
          />
          <text
            className="progress-counter__number"
            fontSize="18"
            fontWeight="600">
            <tspan textAnchor="middle" x="15.7" y="21.4">
              {step}
            </tspan>
          </text>
        </g>
      </svg>
    </span>
  );
}
