import React from 'react';

const CheckboxList = props => {
  let items = props.list.map((item, index) => {
    return (
      <li className="form-item" key={index}>
        <div className="form-checkbox-wrapper">
          <input
            id={item.label}
            className="form-input-checkbox"
            type="checkbox"
            onChange={item.changeHandler}
            checked={item.checked}
          />{' '}
          <span
            htmlFor={item.label}
            className="option-text text__left list-content-wrapper">
            {item.label}
          </span>
        </div>
      </li>
    );
  });

  return (
    <ul
      className={
        'form-list__checkboxes checkbox-list checkbox-list__2columns ' +
        props.classNameOverride
      }>
      {items}
    </ul>
  );
};

export default CheckboxList;

//requires a handler for checkbox and a label and checked values
//label can be JSX not just text
