import React from 'react';

const ErrorAlert = props => {
  return (
    <div className="alert alert-negative">
      <p className="alert-paragraph" role='alert'>{props.message}</p>
    </div>
  );
};

export default ErrorAlert;
