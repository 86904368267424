import React, { Fragment } from 'react';
import { PersonalDetailsForm } from '../forms/EssentialInformation';
import ErrorAlert from '../shared/ErrorAlert';

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'non_binary', label: 'Non-binary' },
  { value: 'other', label: 'Other' },
];

const relationOptions = [
  { value: 'self', label: 'Mine' },
  { value: 'caregiver_child', label: 'My child' },
  { value: 'caregiver_other', label: 'Someone else' },
];

const generateYearList = (relation) => {
  let ageLimit = 16;
  if(relation && relation !== 'self' ){
    ageLimit = -1;
  }
  let currentYear = new Date().getFullYear();
  let list = [...Array(currentYear - ageLimit).keys()]
    .slice(currentYear - 100)
    .reverse()
    .map(year => {
      return {
        value: year,
        label: year,
      };
    });
  return list;
};

// Some other race or ethnicity (please specify below) : other
// Multiracial (please specify below) : multiple
// I don’t know/Prefer not to answer : none

const raceOptions = [
  {
    value: 'white',
    label: 'White',
  },
  {
    value: 'black',
    label: 'Black or African American',
  },
  {
    value: 'asian',
    label: 'Asian or Asian American',
    title:
      'Asian Indian, Pakistani, Chinese, Vietnamese, Korean, Japanese, Filipino, Cambodian, Hmong, etc.',
  },
  {
    value: 'native',
    label: 'American Indian or Alaskan Native',
  },

  {
    value: 'islander',
    label: 'Native Hawaiian or other Pacific Islander',
    title: 'Tongan, Fijian, Marshallese, etc.',
  },
  {
    value: 'other',
    label: 'Some other race or ethnicity (please specify below)',
  },
  {
    value: 'multiple',
    label: 'Multiracial (please specify below)',
  },
  {
    value: 'none',
    label: 'I don’t know/Prefer not to answer',
  },
];

const binaryOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const hispanicOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const nea_familiarityOptions = [
  {
    value: 'primary_resource',
    label: "I'm familiar with NEA and it is my go-to resource",
  },
  {
    value: 'familiar',
    label: "I'm familiar with NEA and its resources",
  },
  {
    value: 'heard_of',
    label: "I've heard of NEA, but don't know much about it",
  },
  {
    value: 'new',
    label: 'I have not heard of NEA until now',
  },
];

const referredOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const ReactSelectValue = (value, options) => {
  const val = options.find(option => {
    return option.value === value;
  });

  return val ?? '';
};

const PersonalDetails = props => {
  const raceOnChange = (e, label) => {
    if (e.nativeEvent) {
      // typed, so we specified race
      props.saveInfo('race_specified', true, { value: e.target.value });
    } else if (e.value === 'other' || e.value === 'multiple') {
      // we need to specify race
      props.saveInfo('race_specified', true, { value: 'abc' });
      props.saveInfo('race', true, e);
    } else {
      // we selected a valid race
      props.saveInfo('race_specified', true, { value: '' });
      props.saveInfo('race', true, e);
    }
  };

  let genderPrompt = 'What is your gender?';
  let racePrompt = 'I predominantly identify as...';
  let hispanicPrompt = 'Do you identify as Hispanic or Latinx?';
  let agePrompt = 'What year were you born?';
  let zipCodePrompt = 'What is your zip code?';
  let feelingPrompt = 'What best describes your feelings about your eczema?';
  let mostlyOnTopOf = "I'm mostly on top of my eczema";
  let needHelp = "I'm trying to manage my eczema, but I need help";
  let newTo = 'I’m new to eczema and need help';
  let relationPrompt = 'How familiar are you with the National Eczema Association?';
  

  switch (props.userInformation.relation) {
    case 'caregiver_child':
      genderPrompt = "What is your child's gender?";
      racePrompt = 'My child predominately identifies as...';
      hispanicPrompt = 'Your child identifies as Hispanic or Latinx?';
      agePrompt = 'What year was your child born?';
      zipCodePrompt = 'What is your zip code?';
      feelingPrompt = "What best describes your feelings about your child's eczema?";
      mostlyOnTopOf = "I'm mostly on top of my child's eczema";
      needHelp = "I'm trying to manage their eczema, but I need help";
      newTo = "I'm new to eczema and need help";
      break;
    case 'caregiver_other':
      genderPrompt = 'What is their gender?';
      racePrompt = 'This person predominately identifies as Hispanic or Latinx?';
      hispanicPrompt = 'This person identifies as...';
      agePrompt = 'What year was this person born?';
      zipCodePrompt = 'What is their zip code?';
      feelingPrompt = "What best describes your feelings about the person's eczema that you are tracking?";
      mostlyOnTopOf = "I'm mostly on top of their eczema";
      needHelp = "I'm trying to manage their eczema, but I need help";
      newTo = "I'm new to eczema and need help";
      break;
    default:
      break;
  }

  const feelingOptions = [
    { value: 'on_top_of', label: mostlyOnTopOf },
    {
      value: 'used_to',
      label: "I'm used to eczema but could manage it better",
    },
    { value: 'new_to', label: newTo },
    { value: 'trying_to_manage', label: needHelp },
    {
      value: 'nothing_works',
      label: 'I have tried everything and nothing works',
    },
  ];

  return (
    <Fragment>
      <form className="login-form form">
        <h3 className="login-title-text login-title-text-16p title__black100">Essential Information</h3>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={`Whose eczema do you want to track?`}
            value={ReactSelectValue(
              props.userInformation.relation,
              relationOptions,
            )}
            options={relationOptions}
            changeHandler={props.saveInfo.bind(null, 'relation', true)}
            placeholder="Pick One"
          />
        </div>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={genderPrompt}
            value={ReactSelectValue(
              props.userInformation.gender,
              genderOptions,
            )}
            options={genderOptions}
            changeHandler={props.saveInfo.bind(this, 'gender', true)}
            placeholder="Pick One"
          />
        </div>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={agePrompt}
            value={ReactSelectValue(
              props.userInformation.birthYear,
              generateYearList(props.userInformation.relation),
            )}
            options={generateYearList(props.userInformation.relation)}
            changeHandler={props.saveInfo.bind(null, 'birthYear', true)}
            placeholder="Year (e.g. 1975)"
          />
        </div>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={racePrompt}
            value={ReactSelectValue(props.userInformation.race, raceOptions)}
            options={raceOptions}
            changeHandler={raceOnChange}
            placeholder="Pick One"
          />
        </div>
        {(props.userInformation.race === 'other' ||
          props.userInformation.race === 'multiple') && (
          <div className="form-item" style={{ paddingLeft: '16px' }}>
            <label className="form-input-label text__left">
              Please specify here
            </label>
            <input
              className={`form-input-field`}
              placeholder="Race"
              maxLength={32}
              value={props.userInformation.race_specified}
              onChange={raceOnChange}
            />
          </div>
        )}
        <div className="form-item">
          <label className="form-input-label-login text__left">{hispanicPrompt} <span className="red-asterisk">*</span></label>
          <div className="multi-option">
            {hispanicOptions.map((option, i) => (
              <div key={i} className="option">
                <input
                  onChange={props.saveInfo.bind(null, 'hispanic', false)}
                  htmlFor={'hispanic'}
                  name={'hispanic'}
                  value={option.value}
                  type="radio"
                  id={`hispanic:${option.value}`}
                />
                <label htmlFor={`hispanic:${option.value}`} className="form-input-label-login text__left">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="alert-space">
          {props.zipValidationFailure ? (
            <ErrorAlert message={'Please provide a valid zip code.'} />
          ) : null}
        </div>
        <div className="form-item">
          <label className="form-input-label-login text__left">{zipCodePrompt} <span className="red-asterisk">*</span></label>
          <input
            className={`form-input-field ${
              props.zipValidationFailure ? 'form-input-field__error' : ''
            }`}
            placeholder="type ZIP"
            maxLength={5}
            value={props.userInformation.zip}
            onChange={props.saveInfo.bind(null, 'zip', false)}
          />
        </div>
        <h3 className="login-title-text login-title-text-16p title__black100">Additional information</h3>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={feelingPrompt}
            value={ReactSelectValue(
              props.userInformation.feelings,
              feelingOptions,
            )}
            options={feelingOptions}
            changeHandler={props.saveInfo.bind(null, 'feelings', true)}
            placeholder="SELECT"
          />
        </div>
        <div className="form-item form-item-select">
          <PersonalDetailsForm
            type={relationPrompt}
            value={ReactSelectValue(
              props.userInformation.nea_familiarity,
              nea_familiarityOptions,
            )}
            options={nea_familiarityOptions}
            changeHandler={props.saveInfo.bind(null, 'nea_familiarity', true)}
            placeholder="SELECT"
          />
        </div>
        <div className="form-item">
          <label className="form-input-label-login text__left">Were you referred to EczemaWise by a healthcare provider? <span className="red-asterisk">*</span></label>
          <div className="multi-option">
            {referredOptions.map((option, i) => (
              <div key={i} className="option">
                <input
                  onChange={props.saveInfo.bind(null, 'was_referred', false)}
                  htmlFor={'referred'}
                  name={'referred'}
                  value={option.value}
                  type="radio"
                  id={`referred:${option.value}`}
                />
                <label htmlFor={`referred:${option.value}`} className="form-input-label-login text__left">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default PersonalDetails;
