import React from 'react';
import { FullArrowBack } from '../../assets/icons/';
import Button from '../shared/button';

const SkinTrackerNavigation = ({
  onCancel,
  onNext,
  onSubmit,
  showBack,
  onBack,
  lastPage,
  disableSave,
  altCancelText,
}) => {
  return (
    <div className="tracker-footer">
      <div
        className={
          showBack
            ? 'tracker-footer-items skin-footer-items button-row'
            : 'tracker-footer-items skin-footer-items button-row-right'
        }>
        <div className="button-wrapper-skin">
          {showBack ? (
            <Button
              className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer "
              onClick={onBack}
              text="Back"
            />
          ) : null}
          <Button
            onClick={lastPage ? onSubmit : onNext}
            className={
              'button button__secondary100 button__btn-14-600 button-rectangle-skin-footer'
            }
            text={lastPage ? 'Save' : 'Next'}
            disabled={disableSave}
          />
        </div>
        <div className="button-wrapper-skin">
          <Button
            className="button button__secondary100-inverted button__btn-14-600 button-rectangle-skin-footer "
            onClick={onCancel}
            text={altCancelText || 'Cancel'}
          />
        </div>
      </div>
    </div>
  );
};

export default SkinTrackerNavigation;
