import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { ActionSvg } from '../../assets/icons';

// add an item
// delete an item
// display selected items
class TrackerListItemSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      inputValue: '',
    };
  }

  render() {
    return (
      <Fragment>
        <ul className="selected-item-list">
          {this.props.addedItems && this.props.addedItems.length
            ? this._buildAddedList()
            : null}
        </ul>

        {this.props.values.length ? (
          <span className="select-items__wrapper">
            <Select
              className="form-select-wrapper"
              instanceId={this.props.type}
              value={this.state.selection}
              onChange={this.handleSelect.bind(this)}
              options={this.props.values.map(val => ({
                value: val.id,
                label: val.trigger_name,
              }))}
              placeholder={this.props.placeholder}
            />
          </span>
        ) : (
          <span className="select-items__wrapper">
            <input
              className={'form-input-field'}
              value={this.state.inputValue}
              onChange={e => {
                this.setState({ inputValue: e.target.value });
              }}
              type="text"
            />
            <button
              disabled={!this.state.inputValue}
              className="button button-action button-action__add"
              onClick={e => {
                const value = this.state.inputValue;
                this.setState({ inputValue: '' });
                this.handleSelect({
                  // has no id, label will be inserted
                  value: `__${this.state.inputValue}`,
                  label: this.state.inputValue,
                });
              }}>
              <ActionSvg />
            </button>
          </span>
        )}
      </Fragment>
    );
  }

  handleSelect(e) {
    this.props.addItem({
      value: e.value,
      label: e.label,
    });
  }

  _buildAddedList() {
    return this.props.addedItems.map((item, i) => (
      <li className="selected-item" key={i}>
        <span>{item.trigger}</span>
        <button
          className="button button-action button-action__delete-appointment"
          onClick={this.props.deleteHandler.bind(
            null,
            item.typeid,
            item.trigger_id,
            item.selected,
            item.type,
            item.trigger,
          )}>
          <ActionSvg />
        </button>
      </li>
    ));
  }
}

export default TrackerListItemSelect;
