import React from 'react';

export default function SleepOrangeIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9118 9.64719C15.8834 9.62353 15.8428 9.62082 15.8111 9.63975L11.2265 12.3624L11.6436 5.69406C11.6456 5.65011 11.6152 5.61022 11.5719 5.60211C11.5273 5.59399 11.4854 5.61901 11.4712 5.66093L7.86086 16.492C7.84936 16.5265 7.86086 16.565 7.88925 16.5887C7.91765 16.6117 7.95754 16.6144 7.98999 16.5954L12.1365 14.1338L11.6849 21.9609C11.6822 22.0049 11.7133 22.0441 11.7565 22.0529C11.7619 22.0542 11.7673 22.0549 11.7727 22.0549C11.8106 22.0549 11.8444 22.0306 11.8566 21.9941L15.9402 9.74387C15.9524 9.70871 15.9402 9.67018 15.9118 9.64719Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2017 12.3995C20.1646 12.3675 20.1089 12.3683 20.0725 12.4009L15.144 16.9405C15.1151 16.9672 15.1047 17.0073 15.118 17.0444C15.1307 17.0816 15.1648 17.106 15.2041 17.109L17.3225 17.2359L14.7432 20.9516C14.715 20.9924 14.7224 21.0474 14.7595 21.08C14.7774 21.0964 14.8004 21.1038 14.8234 21.1038C14.8464 21.1038 14.8701 21.0949 14.8887 21.0778L19.8165 16.539C19.8454 16.513 19.8558 16.4722 19.8424 16.4351C19.8298 16.3987 19.7957 16.3734 19.7571 16.3712L17.6387 16.2443L20.2173 12.5279C20.2462 12.4878 20.2388 12.4321 20.2017 12.3995Z"
        fill="#F9FAFA"
      />
      <circle cx="14" cy="14" r="14" fill="#FF9672" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6 14C12.6 11.0068 14.9415 8.5498 17.9305 8.407C17.9676 8.4049 17.9998 8.3797 18.0103 8.344C18.0208 8.3076 18.0068 8.2691 17.976 8.2481C16.8007 7.4312 15.4259 7 14 7C10.1402 7 7 10.1402 7 14C7 17.8598 10.1402 21 14 21C15.4259 21 16.8007 20.5688 17.976 19.7519C18.0068 19.7309 18.0208 19.6924 18.0103 19.656C17.9998 19.6203 17.9676 19.5951 17.9305 19.593C14.9415 19.4502 12.6 16.9932 12.6 14Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9123 15.1554H19.1623L20.9382 12.816C20.9494 12.8006 20.9557 12.7817 20.9557 12.7628V11.9879C20.9557 11.9396 20.9165 11.9004 20.8682 11.9004H17.8253C17.777 11.9004 17.7378 11.9396 17.7378 11.9879V12.7572C17.7378 12.8055 17.777 12.8447 17.8253 12.8447H19.5249L17.756 15.1848C17.7441 15.1995 17.7378 15.2184 17.7378 15.2373V16.0129C17.7378 16.0605 17.777 16.1004 17.8253 16.1004H20.9123C20.9606 16.1004 20.9998 16.0605 20.9998 16.0129V15.2429C20.9998 15.1946 20.9606 15.1554 20.9123 15.1554Z"
        fill="#F9FAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3501 14.7967V15.265C14.3501 15.3133 14.3893 15.3525 14.4376 15.3525H16.3626C16.4109 15.3525 16.4501 15.3133 16.4501 15.265V14.8317C16.4501 14.7834 16.4109 14.7442 16.3626 14.7442H15.2685L16.4039 13.2567C16.4158 13.242 16.4214 13.2231 16.4214 13.2042V12.7359C16.4214 12.6876 16.3822 12.6484 16.3339 12.6484H14.4376C14.3893 12.6484 14.3501 12.6876 14.3501 12.7359V13.1692C14.3501 13.2175 14.3893 13.2567 14.4376 13.2567H15.4995L14.3676 14.7435C14.3564 14.7589 14.3501 14.7778 14.3501 14.7967Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
