import React from 'react';
import Select from 'react-select';

const SelectDropDown = ({ options, defineLabel, value, onChange }) => {
  const _options = options.map(option => ({
    value: option.id,
    label: defineLabel(option),
  }));
  value = _options.find(option => option.value === value);

  if (value && value.value === null) value.value = '';

  return (
    <Select
      className="treatment-tile-dropdown"
      name={'group_id'}
      value={value || _options[0]}
      isSearchable={false}
      onChange={onChange}
      options={_options}
    />
  );
};

export default SelectDropDown;
