import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MixpanelService from '../libs/mixpanel';
import ErrorAlert from './shared/ErrorAlert';

const maxInputLength = '50';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || '',
      password: '',
      emailValidationFailure: false,
      loginFailed: false,
      errorMessage: 'Please check email and password and try again.',
    };
    this.focusElement = React.createRef();
  }

  componentDidMount() {
    this.focusElement.current?.focus();
  }

  render() {
    return (
      <div className="login login-mt">
        <div className="login-title login-margin">
          <span
            className="login-title-text title__black100"
            tabindex="0"
            ref={this.focusElement}>
            Login In To Your Account
          </span>
        </div>
        <div className="alert-space">
          <br/>
          {this.state.loginFailed ? (
            <ErrorAlert
              message={
                this.state.errorMessage === 'User is not confirmed.'
                  ? 'Please click the link in your verification email.'
                  : this.state.errorMessage
              }
            />
          ) : null}
        </div>
        <form className="login-form form">
          <div className="form-item">
            <label className="form-input-label-login text__left">
              Email<span className="red-asterisk">*</span>
            </label>
            <input
              className={`form-input-field ${
                this.state.emailValidationFailure
                  ? 'form-input-field__error'
                  : ''
              }`}
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={this.updateInput.bind(this, 'email')}
              value={this.state.email}
              maxLength={maxInputLength}
            />{' '}
          </div>
          <div className="form-item">
            <label className="form-input-label-login text__left">
              Password<span className="red-asterisk">*</span>
            </label>
            <input
              className="form-input-field"
              type="password"
              placeholder="Enter password"
              onChange={this.updateInput.bind(this, 'password')}
              value={this.state.password}
              maxLength={maxInputLength}
            />{' '}
          </div>
          <div className="form-item form-item-row">
            <input
              type="checkbox"
              className="form-input-checkbox"
              id="rememberUser"
              name="rememberUser"
              value="remember"
            />
            <label className="form-input-label-login form-input-label-login-margin" for="rememberUser"> Remember me</label>
          </div>
          <br />
          <div className="form-item">
            <button
              className="button button__secondary100 button-rectangle-large"
              disabled={!this.state.password || !this.state.email}
              onClick={this._loginButtonClick.bind(this)}>
              Login
            </button>
          </div>
        </form>
        <div className="login-links">
          <Link className="link link__secondary-100" to="/forgot-password">
            Forgot Password
          </Link>
          <span className="login-link">
            Don't have an account?{' '}
            <Link
              className="link link__secondary-100"
              to="/signup/registration"
              onClick={() => MixpanelService.track('SignUpTapped')}>
              Sign up
            </Link>{' '}
          </span>
        </div>
      </div>
    );
  }

  updateInput(name, e) {
    e.preventDefault();
    let newState = {};
    let value = e.target.value;
    newState[name] = value;
    if (name === 'email' && this.state.emailValidationFailure) {
      newState.emailValidationFailure = false; //reset validation failure when user starts editing input field
    }
    if (this.state.loginFailed) {
      newState.loginFailed = false;
    }
    this.setState(newState);
  }

  _validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  _loginButtonClick(e) {
    e.preventDefault();
    let email = this.state.email;
    let password = this.state.password;
    if (this._validateEmail(email)) {
      return this.props.login(email, password).catch(e => {
        const errorMessage = String(e).replace('Error: GraphQL error: ', '');
        console.log(errorMessage);

        if (errorMessage === 'User is not confirmed.') {
          this.props.history.push({
            pathname: '/signup/emailVerification/pending',
            state: { email: this.state.email },
          });
        } else {
          this.setState({
            loginFailed: true,
            errorMessage,
          });
        }
      });
    } else {
      this.setState({
        loginFailed: true,
        emailValidationFailure: true,
        errorMessage: 'Email format invalid.',
      });
    }
  }
}

Login.propTypes = {
  email: PropTypes.string,
  login: PropTypes.func.isRequired,
};

export default Login;
