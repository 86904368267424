import React from 'react';

export default function Grass() {
  return (
    <svg
      className="icon icon-grass"
      width="18px"
      height="24px"
      viewBox="0 0 18 24">
      <g className="svg-group">
        <path d="M12.1074,0.3799 C11.7244,2.2139 11.5304,4.1259 11.3254,6.0019 C11.1404,7.8879 10.9824,9.7759 10.8784,11.6699 C10.7384,13.5589 10.6724,15.4569 10.5594,17.3519 C10.4784,19.2499 10.3734,21.1489 10.2284,23.0549 C10.1854,23.6179 9.6944,24.0399 9.1314,23.9969 C8.6214,23.9579 8.2274,23.5499 8.1894,23.0549 C8.1144,22.0889 8.1044,21.1219 8.0604,20.1539 C8.0584,19.1859 8.0644,18.2169 8.0924,17.2489 C8.1664,15.3109 8.3144,13.3759 8.5584,11.4469 C8.8084,9.5199 9.1354,7.5969 9.6104,5.7029 C10.0794,3.8059 10.6544,1.9389 11.5684,0.1589 C11.6414,0.0149 11.8174,-0.0411 11.9614,0.0319 C12.0804,0.0929 12.1394,0.2259 12.1134,0.3509 L12.1074,0.3799 Z" />
        <path d="M15.1577,5.4795 C14.8227,6.7655 14.6377,8.1615 14.4447,9.5095 C14.2707,10.8705 14.1087,12.2345 13.9967,13.6085 C13.8507,14.9765 13.7657,16.3545 13.6317,17.7325 C13.5307,19.1145 13.3947,20.4975 13.2157,21.8895 C13.1667,22.2715 12.8177,22.5405 12.4367,22.4915 C12.1167,22.4505 11.8757,22.1945 11.8347,21.8895 C11.7407,21.1835 11.7137,20.4745 11.6507,19.7645 C11.6257,19.0555 11.6147,18.3435 11.6187,17.6335 C11.6477,16.2105 11.7497,14.7885 11.9387,13.3715 C12.1347,11.9565 12.4047,10.5445 12.8117,9.1615 C13.2157,7.7735 13.7137,6.4255 14.5477,5.1565 C14.6517,4.9975 14.8657,4.9535 15.0247,5.0575 C15.1517,5.1415 15.2057,5.2935 15.1697,5.4325 L15.1577,5.4795 Z" />
        <path d="M4.0059,3.1685 C5.0769,4.5915 5.5309,6.1205 5.9699,7.6795 C6.3729,9.2375 6.6369,10.8155 6.8269,12.3975 C6.9969,13.9795 7.1269,15.5635 7.1409,17.1475 C7.1809,18.7315 7.1489,20.3125 7.0319,21.8895 C6.9969,22.3665 6.5819,22.7245 6.1059,22.6895 C5.6769,22.6575 5.3439,22.3185 5.3069,21.9035 L5.3059,21.8895 C5.1669,20.3325 5.1029,18.7795 5.0179,17.2295 L4.7369,12.5945 C4.6119,11.0575 4.4959,9.5215 4.3129,7.9995 C4.2179,7.2405 4.1209,6.4785 3.9829,5.7315 C3.8679,4.9935 3.6809,4.2055 3.4549,3.5845 L3.4189,3.4865 C3.3559,3.3115 3.4459,3.1185 3.6209,3.0545 C3.7639,3.0025 3.9189,3.0535 4.0059,3.1685" />
        <path d="M0.4546,8.9634 C1.6416,9.6384 2.3186,10.8664 2.8046,12.0144 C3.2726,13.1934 3.5756,14.4104 3.7496,15.6284 C4.1106,18.0644 4.1686,20.5134 3.8816,22.9254 C3.8416,23.2594 3.5386,23.4974 3.2056,23.4574 C2.9216,23.4234 2.7056,23.1974 2.6736,22.9254 C2.3856,20.5484 2.2426,18.1984 1.9416,15.8864 C1.7876,14.7334 1.6116,13.5914 1.3456,12.4904 C1.0776,11.3914 0.7526,10.3244 0.0836,9.4414 L0.0616,9.4114 C-0.0394,9.2774 -0.0134,9.0874 0.1206,8.9854 C0.2206,8.9094 0.3516,8.9054 0.4546,8.9634" />
        <path d="M17.98,9.6157 C17.694,10.6807 17.457,11.7947 17.252,12.8867 C17.033,13.9827 16.875,15.0897 16.701,16.1957 C16.536,17.3027 16.419,18.4207 16.286,19.5397 C16.16,20.6617 16.061,21.7887 15.901,22.9257 L15.9,22.9357 C15.854,23.2687 15.546,23.5007 15.213,23.4537 C14.934,23.4147 14.726,23.1927 14.693,22.9257 C14.55,21.7627 14.513,20.5917 14.545,19.4197 C14.592,18.2477 14.684,17.0737 14.893,15.9127 C15.078,14.7477 15.365,13.5957 15.759,12.4747 C16.184,11.3607 16.663,10.2717 17.461,9.3067 C17.568,9.1767 17.76,9.1587 17.89,9.2657 C17.984,9.3447 18.02,9.4677 17.99,9.5787 L17.98,9.6157 Z" />
      </g>
    </svg>
  );
}
