import React from 'react';

export default function TrackersBlueIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4297 9.35625C14.4032 9.33345 14.3634 9.33398 14.3375 9.35731L10.8171 12.5999C10.7964 12.619 10.789 12.6476 10.7985 12.6741C10.8075 12.7006 10.8319 12.7181 10.86 12.7202L12.3732 12.8109L10.5308 15.4649C10.5106 15.4941 10.5159 15.5333 10.5425 15.5567C10.5552 15.5683 10.5716 15.5736 10.5881 15.5736C10.6045 15.5736 10.6215 15.5673 10.6347 15.5551L14.1545 12.313C14.1752 12.2945 14.1826 12.2653 14.1731 12.2388C14.1641 12.2128 14.1397 12.1948 14.1121 12.1932L12.599 12.1026L14.4408 9.44797C14.4615 9.41934 14.4562 9.37958 14.4297 9.35625Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#1D62ED" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6235 6.87828C13.6333 6.8682 13.6458 6.86292 13.6547 6.8514L14.6847 5.5362C14.7192 5.493 14.7206 5.42964 14.6847 5.38452C14.6442 5.33268 14.5705 5.32452 14.5201 5.36628L13.243 6.42708C13.2323 6.43572 13.2229 6.44676 13.2131 6.45732C13.3824 6.56004 13.5227 6.70404 13.6235 6.87828Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9121 6.32891C12.9126 6.31499 12.9173 6.30203 12.9159 6.28763L12.741 4.60763C12.735 4.55243 12.6925 4.50683 12.6366 4.50059C12.5722 4.49339 12.5144 4.54139 12.5079 4.60763L12.333 6.28763C12.3316 6.30107 12.3325 6.31595 12.333 6.32987C12.4267 6.30539 12.5233 6.28763 12.6244 6.28763C12.7242 6.28763 12.8198 6.30443 12.9121 6.32891Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.594 6.85136C11.6028 6.86192 11.6131 6.87152 11.6234 6.8816C11.7231 6.70736 11.863 6.56288 12.0323 6.45968C12.023 6.44912 12.0169 6.43616 12.0062 6.42704L10.7286 5.36624C10.6866 5.3312 10.625 5.33024 10.5812 5.36624C10.5313 5.40848 10.5229 5.48432 10.5635 5.53616L11.594 6.85136Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4222 7.36765L13.7902 7.18765C13.7771 7.18621 13.7626 7.18717 13.7487 7.18765C13.7734 7.28413 13.7902 7.38349 13.7902 7.48765C13.7902 7.59037 13.7738 7.68877 13.7501 7.78381C13.7636 7.78429 13.7762 7.78909 13.7902 7.78765L15.4222 7.60765C15.4758 7.60189 15.5201 7.55821 15.5261 7.50061C15.5331 7.43437 15.4865 7.37485 15.4222 7.36765Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6549 8.12423C13.646 8.11367 13.6353 8.10359 13.6255 8.09399C13.5257 8.26823 13.3853 8.41271 13.2166 8.51591C13.2259 8.52647 13.2315 8.53943 13.2427 8.54855L14.5198 9.60887C14.5623 9.64391 14.6238 9.64535 14.6676 9.60887C14.7175 9.56711 14.7259 9.49127 14.6849 9.43943L13.6549 8.12423Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29667 9.66269C7.07547 9.66269 6.08182 10.6856 6.08182 11.9427C6.08182 12.1414 5.92515 12.3027 5.7321 12.3027C5.53906 12.3027 5.38239 12.1414 5.38239 11.9427C5.38239 10.2886 6.68985 8.94269 8.29667 8.94269C8.48972 8.94269 8.64639 9.10397 8.64639 9.30269C8.64639 9.50141 8.48972 9.66269 8.29667 9.66269ZM12.3771 7.23293L11.4945 8.14157C11.3564 8.03117 11.2152 7.93805 11.0795 7.87805C10.8538 7.77869 10.6892 7.78253 10.6048 7.86989L10.1516 8.33645C8.65385 7.51949 6.75467 7.75949 5.49383 9.05741C3.94623 10.651 3.94623 13.2344 5.49383 14.8275C7.04237 16.4211 9.55145 16.4211 11.0995 14.8275C12.3604 13.5296 12.5935 11.575 11.7999 10.0332L12.2531 9.56669C12.3375 9.47981 12.3417 9.31037 12.2452 9.07805C12.1869 8.93837 12.096 8.79245 11.9892 8.65037L12.8719 7.74269C13.008 7.60205 13.008 7.37357 12.8719 7.23293C12.7352 7.09277 12.5133 7.09277 12.3771 7.23293Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
