import { flowRight as compose } from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withTreatmentOptions } from '../../apollo/stores/appointments';
import { ProgressStep } from '../../assets/icons';
import { TreatmentOptionsAndMetadata } from '../../libs/graphql/queries/data';
import { formatOptions } from '../../libs/treatmentOptionsUtils';
import TreatmentDetails from '../providerprep/TreatmentDetails';
import TreatmentsTree from '../providerprep/TreatmentsTree';

const composeWithTreatmentOptionsAndMetadata = graphql(
  TreatmentOptionsAndMetadata,
  {
    props: ({
      data: {
        loading,
        error,
        availableTreatmentOptions,
        availableTreatmentOptionsMetadata,
      },
    }) => ({
      loading,
      error,
      availableTreatmentOptionsMetadata,
      availableTreatmentOptions,
    }),
  },
);

class TreatmentOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTreatment: {},
      selectedTreatmentMetadata: {},
      mobile: false,
      showTypes: true,
      defaultOptionsLoaded: false,
    };
    this.focusElement = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleMobile.bind(this));
    if (window.innerWidth < 661) {
      this.setState({
        mobile: true,
      });
    }
    this.focusElement.current?.focus()
    this.selectDefaultTreatmentOptions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._handleMobile.bind(this));
  }

  componentDidUpdate(prevProps) {
    this.selectDefaultTreatmentOptions();
  }

  _handleMobile(e) {
    if (e.currentTarget.innerWidth < 661 && !this.state.mobile) {
      this.setState({
        mobile: true,
      });
    }

    if (e.currentTarget.innerWidth > 661 && this.state.mobile) {
      this.setState({
        mobile: false,
      });
    }
  }

  render() {
    if (this.props.loading) return null;
    let OptsFromServer = formatOptions(this.props.availableTreatmentOptions);
    return (
      <div className="tracker-body survey__treatment-options">
        <div className="survey-title">
          <div className="survey-title__row">
            <ProgressStep step="3" />
            <h2 className="title title__cobalt" tabindex="0" ref={this.focusElement}>Review Treatments</h2>
          </div>
          <p className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
            Learn more about the available treatments for eczema. This tool will
            help you to make informed decisions about your care with your
            provider.
          </p>
        </div>
        <div className="treatment-options__toggle button-row">
          <button
            onClick={this._toggleTypes.bind(this)}
            className={`treatment-toggle__types button button__toggle ${
              this.state.showTypes
                ? 'button__toggle-active'
                : 'button__toggle-inactive'
            }`}>
            Treatment Types
          </button>
          <button
            onClick={this._toggleTypes.bind(this)}
            className={`treatment-toggle__types button button__toggle ${
              !this.state.showTypes
                ? 'button__toggle-active'
                : 'button__toggle-inactive'
            }`}>
            Treatment Details
          </button>
        </div>
        <div className="options-wrapper">
          <div
            className={`options-item options-item__treatment-types ${
              !this.state.mobile
                ? ''
                : this.state.showTypes
                ? ''
                : 'options-item__inactive'
            }`}>
            {!this.state.mobile ? (
              <h3 className="subtext subtext__uppercase subtext__semibold subtext__centered section-title">
                Treatment Types
              </h3>
            ) : null}
            <div className="options-item-section">
              <p className="help-text help-text__centered">
                {' '}
                Toggle through the different categories and select a treatment
                to view the details
              </p>
              <TreatmentsTree
                handleTreatmentSelection={this.handleTreatmentSelection.bind(
                  this,
                )}
                options={OptsFromServer}
              />
            </div>
          </div>
          <div
            className={`options-item options-item__treatment-details ${
              this.state.mobile
                ? this.state.showTypes
                  ? 'options-item__inactive'
                  : ''
                : ''
            }`}>
            {!this.state.mobile ? (
              <h3 className="subtext subtext__uppercase subtext__semibold subtext__centered section-title">
                Treatment Details
              </h3>
            ) : null}
            <div className="options-item-section">
              <p className="help-text help-text__centered">
                Select a treatment on the left to view the details
              </p>
              <TreatmentDetails
                treatment={this.state.selectedTreatment}
                metadata={this.state.selectedTreatmentMetadata}
                handleTreatmentSave={this.handleTreatmentSave.bind(this)}
                saved={this.props.treatmentOptions.selectedOptions.includes(
                  this.state.selectedTreatment.selectionId,
                )}
              />
            </div>
          </div>
          <div className="options-item options-item__saved-treatments">
            <h3 className="subtext subtext__uppercase subtext__semibold subtext__centered section-title">
              Saved Treatments
            </h3>
            <div className="options-item-section">
              <p className="help-text help-text__centered">
                Save treatment names to take with you to your appointment
              </p>
              <div className="saved-treatments__treatments-wrapper">
                {this.buildSavedTreatments()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _toggleTypes() {
    let currentState = this.state.showTypes;
    this.setState({
      showTypes: !currentState,
    });
  }

  buildSavedTreatments() {
    let savedTreatments = this.props.treatmentOptions.selectedOptions;
    if (!savedTreatments.length) {
      return null;
    }
    let treatmentObjects = [];
    savedTreatments.forEach(treatmentId => {
      let t = this.findSavedTreatment(treatmentId);
      treatmentObjects.push(t);
    });

    let savedTreatmentsList = treatmentObjects.map((trt, index) => {
      let id = trt.selectionId;
      let saved = savedTreatments.includes(id);
      return (
        <div className="form-checkbox-wrapper" key={index}>
          <input
            className="form-input-checkbox"
            type="checkbox"
            onChange={this.handleTreatmentSave.bind(this, id, !saved)}
            checked={saved}
          />
          <div className="card">
            <p className="paragraph">{trt.name}</p>
          </div>
        </div>
      );
    });

    return savedTreatmentsList;
  }

  findSavedTreatment(id) {
    let treatment;
    const findTreatment = options => {
      options.forEach(option => {
        if (option.selectionId === id) {
          treatment = option;
          return;
        }
        if (option.children) {
          findTreatment(option.children);
        }
      });
    };
    findTreatment(formatOptions(this.props.availableTreatmentOptions));
    return treatment;
  }

  handleTreatmentSelection(treatment) {
    this.setState({
      selectedTreatment: treatment,
      selectedTreatmentMetadata: this.props.availableTreatmentOptionsMetadata.find(
        metadata => metadata.id === treatment.metadata_id,
      ),
      showTypes: false,
    });
  }

  handleTreatmentSave(id, save) {
    let savedTreatments = this.props.treatmentOptions.selectedOptions;
    let newTreatments = savedTreatments.map(t => t);
    if (save) {
      newTreatments.push(id);
    } else {
      newTreatments = newTreatments.filter(treatmentId => {
        return treatmentId !== id;
      });
    }

    return this.props.updateTreatmentOptions({
      variables: {
        selectedOptions: newTreatments,
      },
    });
  }

  selectDefaultTreatmentOptions() {
    // Check if default treatment options have been loaded
    if (this.props.loading) return;

    // Check if treatment suggestions have been loaded
    if (this.state.defaultOptionsLoaded) return;
    this.setState({ defaultOptionsLoaded: true });

    // In case we go back and forth in steps 2 and 3, we don't want to overwrite the user's selections
    if (this.props.treatmentOptions.selectedOptions.length > 0) return;

    let savedTreatments = [...this.props.treatmentOptions.selectedOptions];
    this.props.availableTreatmentOptions.forEach(treatmentOption => {
      if (treatmentOption.default_checked) {
        savedTreatments.push(treatmentOption.id);
      }
    });
    
    this.props.updateTreatmentOptions({
      variables: {
        selectedOptions: savedTreatments,
      },
    });
  }
}

export default compose(
  withTreatmentOptions,
  composeWithTreatmentOptionsAndMetadata,
)(TreatmentOptions);
