import React from 'react';
import { ActionSvg } from '../../assets/icons';

export default ({ value, onChange }) => {
  const _onChange = (i, e) => {
    const newValue = value.slice(0);
    newValue[i] = e.target.value;
    onChange(newValue);
  };

  const _deleteRow = i => {
    const newValue = value.slice(0);
    newValue.splice(i, 1);
    onChange(newValue);
  };

  return (
    <div className={'list-input'}>
      {value.map((item, i) => (
        <div key={i} className={'list-input-row'}>
          <span className={'item-number'}>{i + 1}) </span>
          <input value={item} onChange={_onChange.bind(this, i)} />
          <button
            className="button button-action button-action__delete"
            onClick={_deleteRow.bind(this, i)}>
            <ActionSvg />
          </button>
        </div>
      ))}
    </div>
  );
};
