import React from 'react';

export default function Chemicals() {
  return (
    <svg viewBox="0 0 15 28">
      <g className="icon-fill" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M1.2373,8.9805 C1.2513,8.9805 1.2643,8.9795 1.2783,8.9775 C2.4413,8.7855 3.3373,7.3715 3.7363,5.0975 C3.7523,5.0045 3.7153,4.9115 3.6393,4.8545 L1.9993,3.6245 C1.9233,3.5675 1.8213,3.5585 1.7373,3.6005 C1.6533,3.6425 1.5993,3.7295 1.5993,3.8245 L1.5993,4.8335 C1.5993,7.3255 1.1533,8.3035 1.0163,8.5435 C0.9923,8.5865 0.9813,8.6345 0.9843,8.6835 L0.9873,8.7465 C0.9923,8.8165 1.0263,8.8815 1.0813,8.9255 C1.1263,8.9615 1.1813,8.9805 1.2373,8.9805"
          id="Fill-1"
        />
        <path
          d="M8,24.167 C5.791,24.167 4,22.376 4,20.167 C4,17.957 5.791,16.167 8,16.167 C10.209,16.167 12,17.957 12,20.167 C12,22.376 10.209,24.167 8,24.167 M11,10 L11,9.25 C11,9.112 10.888,9 10.75,9 L5.25,9 C5.112,9 5,9.112 5,9.25 L5,13 L1.219,17.727 C1.078,17.902 1,18.124 1,18.351 L1,27 C1,27.552 1.449,28 2,28 L14,28 C14.551,28 15,27.552 15,27 L15,18 C15,13.233 11.038,10.029 11,10"
          id="Fill-3"
        />
        <path
          d="M3,1.125 L7,1.125 C7.207,1.125 7.375,1.293 7.375,1.5 C7.375,1.707 7.207,1.875 7,1.875 L3,1.875 C2.793,1.875 2.625,1.707 2.625,1.5 C2.625,1.293 2.793,1.125 3,1.125 Z M1,2.75 C1,2.888 1.112,3 1.25,3 L2,3 L6,6 L6,7.75 C6,7.888 6.112,8 6.25,8 L9.75,8 C9.888,8 10,7.888 10,7.75 L10,6 L12.746,6 C12.813,6 12.878,5.973 12.926,5.924 C12.973,5.875 12.998,5.809 12.996,5.741 C12.876,2.463 10.538,0.43 10.071,0.055 C10.026,0.02 9.971,0 9.915,0 L1.25,0 C1.112,0 1,0.112 1,0.25 L1,0.5 L0.5,0.5 C0.224,0.5 0,0.725 0,1 L0,2 C0,2.275 0.224,2.5 0.5,2.5 L1,2.5 L1,2.75 Z"
          id="Fill-5"
        />
      </g>
    </svg>
  );
}
