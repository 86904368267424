import { Link } from 'react-router-dom';
import SeveralPhones from '../../assets/images/ew-phones.svg';
import ECWFeatures from '../../assets/images/newBrandImages/ECWFeatures.svg';
import ListQ from '../../assets/images/newBrandImages/ListQ.svg';
import { useIsVisible } from '../shared/hooks/useIsVisible';
import ArrowRight from '../../assets/icons/arrow-right-white.svg';
import { useRef } from 'react';

function Features() {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  return (
    <section className="home-page__section-three">
      <div className="content-wrapper">
        <div className="image__phones-wrapper" ref={ref}>
          <img
            className={`image__phones ${isVisible ? 'visible' : ''}`}
            src={ECWFeatures}
            alt=""
          />
        </div>
        <div className="content-section content__width">
          <h2 className="title__homepage-large title__primary100">
            Get the <span className="title__secondary100">most</span> out of your next appointment.
          </h2>
          <p className="paragraph">
            EczemaWise features custom tools to help you and your doctor
            determine the right care for you.
          </p>
          <ul className="homepage__list">
            <li>
              <span className="list__image">
                <img src={ListQ} alt="" aria-hidden="true" />
              </span>
              <span className="list__text">
                Save your information in one place — including symptoms,
                triggers and treatments.
              </span>
            </li>
            <li>
              <span className="list__image">
                <img src={ListQ} alt="" aria-hidden="true" />
              </span>
              <span className="list__text">
                Explore the latest eczema insights to stay informed.
              </span>
            </li>
            <li>
              <span className="list__image">
                <img src={ListQ} alt="" aria-hidden="true" />
              </span>
              <span className="list__text">
                Print your health data summary to share with your doctor at your
                next appointment.
              </span>
            </li>
          </ul>
          <div className="try-button-container">
            <Link to="/about" className="try-button">
              <img src={ArrowRight} alt="" />
              <span>Learn More</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
