import { flowRight as compose } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ErrorAlert from '../shared/ErrorAlert';
import { graphql } from 'react-apollo';
import { resendConfirmationEmail } from '../../libs/graphql/mutations/user';
import {
  CheckFormIcon,
  EmailErrorFormIcon,
  EmailFormIcon,
} from '../../assets/icons';

const withResendConfirmationEmail = graphql(resendConfirmationEmail, {
  name: 'resendConfirmationEmail',
});

const maxInputLength = '50';

const EmailVerification = ({ history, resendConfirmationEmail }) => {
  const { status } = useParams();
  const focusElement = React.useRef(null);

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [resentEmail, setResentEmail] = useState(false);

  React.useEffect(() => {
    if (focusElement.current) focusElement.current.focus();
  }, []);

  function handleInputChange(event) {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Regular expression to validate email format
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsValid(emailRegex.test(newEmail));
  }

  function handleInputBlur() {
    setIsBlurred(email !== '');
  }

  const icon = () => {
    switch (status) {
      case 'confirmed':
        return <CheckFormIcon />;
      case 'pending':
        return <EmailFormIcon />;
      default:
        return <EmailErrorFormIcon />;
    }
  };

  const title = () => {
    switch (status) {
      case 'confirmed':
        return 'User confirmed';
      case 'pending':
        return 'Verify your email';
      default:
        return 'Oh sorry, the link has expired';
    }
  };

  const notification = () => {
    switch (status) {
      case 'confirmed':
        return (
          <Fragment>
            <div className="form">
              <div className="form-item">
                <span className="login-title-text login-title-text-16p400 title__black100">
                  Your registration has been confirmed!
                </span>
              </div>
              <br />
              <div className="form-item">
                <Link to="/login">
                  <button className="button button__secondary100 button-rectangle-large">
                    Back to login
                  </button>
                </Link>
              </div>
            </div>
          </Fragment>
        );
      case 'pending':
        return (
          <Fragment>
            <div className="form">
              {resentEmail ? resentEmailAlert() : ''}
              <div className="form-item">
                <p className="paragraph login-link login-link-info-text text__centered">
                  Please check your email for a link to verify your account.
                </p>
              </div>
              <br />
              <div className="form-item">
                <Link to="/login">
                  <button className="button button__secondary100 button-rectangle-large">
                    Back to login
                  </button>
                </Link>
              </div>
              <br />
              <div className="form-item">
                <p className="paragraph login-link text__centered">
                  Didn’t receive an email?{' '}
                  <span
                    className="button link link__secondary-100"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEmailErr(false);
                      return resendConfirmationEmail({
                        variables: {
                          email: history.location.state
                            ? history.location.state.email
                            : '',
                        },
                      })
                        .then(() => {
                          setResentEmail(true);
                        })
                        .catch(e => {
                          setEmailErr(
                            String(e).replace('Error: GraphQL error: ', ''),
                          );
                        });
                    }}>
                    {' '}
                    Resend it{' '}
                  </span>
                </p>
              </div>
            </div>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <div className="login-form form">
              <div className="form-item">
                <p className="paragraph login-link login-link-info-text text__centered">
                  Please enter your email to get a new verification email.
                </p>
              </div>
              <div className="alert-space">
                {isBlurred && !isValid ? (
                  <ErrorAlert message="Please enter a valid email address" />
                ) : (
                  ''
                )}
                {emailErr ? <ErrorAlert message={emailErr} /> : ''}
              </div>
              <div className="form-item">
                <label className="form-input-label-login text__left">
                  Email<span className="red-asterisk">*</span>
                </label>
                <input
                  autoFocus
                  className={`form-input-field ${
                    isBlurred && !isValid ? 'form-input-field__error' : ''
                  }`}
                  name="email"
                  type={'email'}
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  maxLength={maxInputLength}
                />
              </div>
              <br />
              <div className="form-item">
                <button
                  className="button button__secondary100 button-rectangle-large"
                  disabled={!isValid}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEmailErr(false);
                    return resendConfirmationEmail({
                      variables: {
                        email,
                      },
                    })
                      .then(() => {
                        history.push({
                          pathname: '/signup/confirmation',
                          state: { email },
                        });
                      })
                      .catch(e => {
                        setEmailErr(
                          String(e).replace('Error: GraphQL error: ', ''),
                        );
                      });
                  }}>
                  Send verification email
                </button>
              </div>
              <br />
              <div className="form-item text-icon">
                <Link
                  className="link link__secondary-100 text__centered"
                  to="/login">
                  Back to login
                </Link>
              </div>
            </div>
          </Fragment>
        );
    }
  };

  const resentEmailAlert = () => {
    return (
      <div className="alert alert-positive alert-positive__column" role="alert">
        <h4 className="subtext">AN EMAIL HAS BEEN SENT</h4>
        <p className="alert-paragraph">
          Check your email for a verification code. Not seeing an email in your
          inbox?{' '}
          <Link to="/help" className="link link__underline">
            Go to Help
          </Link>{' '}
          now.
        </p>
      </div>
    );
  };

  return (
    <div className="login-card login-registration">
      <div className="icon-card-container">{icon()}</div>
      <br />
      <div className="login-title">
        <h2
          className="login-title-text login-title-text-16p title__black100"
          ref={focusElement}>
          {title()}
        </h2>
      </div>
      <br />
      {notification()}
    </div>
  );
};

export default compose(withResendConfirmationEmail)(EmailVerification);
