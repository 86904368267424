import React from 'react';

export default function PainTrendsIcon({active}) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#E95D42" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3454 7.37353 11.3164 7.3716 11.2937 7.38512L8.01897 9.32986L8.31694 4.56676C8.31839 4.53537 8.29665 4.50688 8.26575 4.50109C8.23387 4.49529 8.20393 4.51316 8.19379 4.5431L5.61497 12.2796C5.60676 12.3042 5.61497 12.3317 5.63525 12.3486C5.65553 12.3651 5.68402 12.367 5.70721 12.3535L8.66899 10.5951L8.3464 16.186C8.34446 16.2174 8.36668 16.2454 8.39759 16.2516C8.40145 16.2526 8.40531 16.2531 8.40918 16.2531C8.43622 16.2531 8.46037 16.2357 8.46906 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4296 9.35637C14.4031 9.33357 14.3634 9.3341 14.3374 9.35743L10.817 12.6C10.7964 12.6191 10.7889 12.6477 10.7985 12.6742C10.8075 12.7007 10.8319 12.7182 10.86 12.7203L12.3731 12.811L10.5307 15.4651C10.5106 15.4942 10.5159 15.5335 10.5424 15.5568C10.5551 15.5684 10.5716 15.5737 10.588 15.5737C10.6044 15.5737 10.6214 15.5674 10.6346 15.5552L14.1545 12.3132C14.1752 12.2946 14.1826 12.2654 14.173 12.2389C14.164 12.213 14.1396 12.1949 14.1121 12.1933L12.599 12.1027L14.4408 9.44809C14.4615 9.41946 14.4562 9.3797 14.4296 9.35637Z"
        fill="#F9FAFA"
      />
      <circle cx="10" cy="10.5" r="10" fill="#E95D42" />
      <rect
        width="13.3333"
        height="13.3333"
        transform="translate(3.33325 3.83325)"
        fill={!active ?  "#E95D42" : "#f2f2f4"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3656 7.39043C11.3453 7.37353 11.3163 7.3716 11.2936 7.38512L8.01891 9.32986L8.31688 4.56676C8.31832 4.53537 8.29659 4.50688 8.26569 4.50109C8.23381 4.49529 8.20387 4.51316 8.19373 4.5431L5.61491 12.2796C5.6067 12.3042 5.61491 12.3317 5.63519 12.3486C5.65547 12.3651 5.68396 12.367 5.70714 12.3535L8.66893 10.5951L8.34633 16.186C8.3444 16.2174 8.36662 16.2454 8.39752 16.2516C8.40139 16.2526 8.40525 16.2531 8.40911 16.2531C8.43616 16.2531 8.4603 16.2357 8.469 16.2096L11.3859 7.45949C11.3946 7.43438 11.3859 7.40685 11.3656 7.39043Z"
        fill="#F9FAFA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4296 9.35637C14.4031 9.33357 14.3633 9.3341 14.3373 9.35743L10.817 12.6C10.7963 12.6191 10.7889 12.6477 10.7984 12.6742C10.8074 12.7007 10.8318 12.7182 10.8599 12.7203L12.373 12.811L10.5307 15.4651C10.5105 15.4942 10.5158 15.5335 10.5423 15.5568C10.5551 15.5684 10.5715 15.5737 10.5879 15.5737C10.6044 15.5737 10.6213 15.5674 10.6346 15.5552L14.1544 12.3132C14.1751 12.2946 14.1825 12.2654 14.173 12.2389C14.164 12.213 14.1396 12.1949 14.112 12.1933L12.5989 12.1027L14.4407 9.44809C14.4614 9.41946 14.4561 9.3797 14.4296 9.35637Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
