import React from 'react';
import { UilAngleRightB } from '@iconscout/react-unicons'

export const OptionElement = ({ icon, text, className, onClick }) => {
    return (
            <div
                onClick={() => {
                    onClick();
                }}
                className={className}
                style={{ position: 'relative' }}
                >
                {icon} {text}
                <UilAngleRightB style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}/>
            </div>
    );
};
