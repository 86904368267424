import moment from 'moment-timezone';
import React, { Component } from 'react';
import analytics from '../../libs/analytics';
// Queries
import { DailyUserData } from '../../libs/graphql/queries/data';
import Directions from '../shared/Directions';
import DragScale from '../shared/DragScale';
import SubmitBar from '../shared/submit-bar';
import TrackerHeader from '../shared/tracker-header';
import TrackerInstructions from '../shared/TrackerInstructions';
import MixpanelService from '../../libs/mixpanel';
import { MIXPANEL_DAY_FORMAT, MIXPANEL_TIME_FORMAT } from '../../libs/mixpanel';

class ScaleTracker extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      write: props.write || true,
      level: 0,
      mostRecentSaveDate: undefined,
      instructions: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.data && nextProps.data && nextProps.data.level) ||
      (this.props.data && nextProps.data && this.props.data.level !== nextProps.data.level)
    ) {
      const { data, date } = nextProps;
      this.handleDataUpdate(date, data.level, data.most_recent_save_date);
    }
  }

  componentDidMount() {
    const { data, date } = this.props;
    if (data && data.level != null) {
      this.handleDataUpdate(date, data.level, data.most_recent_save_date);
    }
  }

  increment() {
    this.state.level < 10 && this.setState({ level: this.state.level + 1 });
  }

  decrement() {
    this.state.level > 0 && this.setState({ level: this.state.level - 1 });
  }

  setLevel = val => {
    this.setState({ level: val + 1 });
  };

  filterData(data) {
    return data[0].itchData[0];
  }

  handleDataUpdate = (date, level, most_recent_save_date) => {
    const newState = {
      level: level,
      write: false,
      mostRecentSaveDate: moment(most_recent_save_date).format(MIXPANEL_DAY_FORMAT),
    };
  
    this.setState(newState);
  };

  submitData() {
    return this.props
      .changeHandler({
        variables: {
          id: this.props.id,
          tracker: this.props.theme.title.toUpperCase(),
          level: this.state.level || 0,
          client_date: this.props.date || moment().format('YYYY-MM-DD'),
        },
        refetchQueries: () => [
          {
            query: DailyUserData,
            variables: {
              id: this.props.id,
              today: this.props.date || moment().format('YYYY-MM-DD'),
              readOnly: true,
            },
          },
        ],
      })
      .finally(() => {
        let key = '';
        let { date } = this.props;
        let { mostRecentSaveDate } = this.state;
        const currentTime = moment.utc().format(MIXPANEL_TIME_FORMAT);
        const selectedDayMoment = date ? moment.utc(date).format(MIXPANEL_DAY_FORMAT) : moment.utc().format(MIXPANEL_DAY_FORMAT);
        date = `${selectedDayMoment}T${currentTime}`;

        if (this.props.theme.title.toUpperCase() === 'PAIN') {
          key = 'Pain';
        } else if (this.props.theme.title.toUpperCase() === 'ITCH') {
          key = 'Itch';
        } else if (this.props.theme.title.toUpperCase() === 'SLEEP') {
          key = 'Sleep';
        } else if (this.props.theme.title.toUpperCase() === 'STRESS') {
          key = 'Stress';
        }

        const data = {
          [`${key}Level`]: this.state.level || 0,
          [`${key}Date`]: date,
        };

        // Update Profile Property ItchDate with the most recent tracker date
        if (selectedDayMoment >= mostRecentSaveDate) {
          MixpanelService.setProfileProperty(data);
        } 
        
        MixpanelService.track(`${key}TrackerUpdated`, data);

        analytics().logEvent('any_track_item_updated', {});
        this.setState({
          write: false,
        });
      });
  }

  onCancel() {
    // this means that there is already data in db and this was an update
    // we need to toggle back to read state with the previously submitted level
    if (this.props.data && this.props.data.level != null) {
      this.setState({
        write: false,
        level: this.props.data.level,
      });
    } else {
      this.setState({
        level: 0,
      });
    }
  }

  // Prevent opening write view if instructions are open
  _toggleWrite() {
    if (!this.state.write && !this.state.instructions) {
      this.setState({
        write: true,
      });
    }
  }

  toggleInstructions() {
    let instructions = !this.state.instructions;
    this.setState({
      instructions,
    });
  }

  //Check to see if there is any data for today, and assign a class accordingly
  progressBadge() {
    if (!this.state.write) {
      return 'tracker-progress-complete';
    } else {
      return 'tracker-progress-incomplete';
    }
  }

  render() {
    const { write, level, instructions } = this.state;
    return (
      <div
        className={`card tracker tracker-loaded tracker-${this.props.theme.title} ${this.progressBadge()} ${write
          ? this.props.theme.color + ' card__active tracker__active'
          : this.props.theme.color + ' tracker__inactive'}`}
      >
        <TrackerHeader
          title={this.props.theme.title}
          write={write}
          icon={this.props.icon}
          edit={this._toggleWrite.bind(this)}
          instructions={this.toggleInstructions.bind(this)}
          instructionsClass={instructions ? 'tracker-header__instructions' : ''}
        />
        <div className="tracker-body" onClick={this._toggleWrite.bind(this)}>
          {instructions ? <TrackerInstructions instructions={this.props.theme.instructionsText} /> : null}
          {write ? (
            <Directions directionText={this.props.theme.directionText} helpText={this.props.theme.helpText}  helpInstructionText={this.props.theme.helpInstructionText}/>
          ) : (
            <Directions directionText={this.props.theme.readText} />
          )}
          <DragScale
            onIncrease={this.increment.bind(this)}
            onDecrease={this.decrement.bind(this)}
            onSetDirectly={this.setLevel.bind(this)}
            editingMode={write}
            level={level}
          />
        </div>
        {write && (
          <SubmitBar
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.submitData.bind(this)}
            disableSubmit={this.state.instructions}
            disableCancel={this.state.instructions}
          />
        )}
      </div>
    );
  }
}

export default ScaleTracker;
