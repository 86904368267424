import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  flareTrackerData: [],
  loading: false,
  error: null,
};

const flareTrackerSlice = createSlice({
  name: 'flareTracker',
  initialState,
  reducers: {
    setFlareTracker(state, action) {
      state.flareTrackerData = action.payload;
    },
    updateFlareTracker(state, action) {
      state.flareTrackerData = state.flareTrackerData.map(ftd => {
        if (ftd.id === action.payload.id) {
          return {
            ...ftd,
            ...action.payload,
          };
        }
        return ftd;
      });
    },
    deleteFlareTracker(state, action) {
      state.flareTrackerData = state.flareTrackerData.filter(
        ftd => ftd.id !== action.payload,
      );
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const flareTrackerActions = flareTrackerSlice.actions;

export default flareTrackerSlice.reducer;
