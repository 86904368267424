import React, { useState } from 'react';
import { UilEyeSlash, UilEye } from '@iconscout/react-unicons'

const PasswordInputWithToggle = props => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div className="form-item">
      <label className="form-input-label-settings text__left">
        {props.labelText ?? 'Password'}<span className="red-asterisk">*</span>
      </label>
        <input
          className="form-input-field"
          type={showPassword ? 'text' : 'password'}
          value={props.value}
          onChange={handlePasswordChange}
          placeholder="Password"
        />
        <button
          type="button"
          className="password-toggle-button"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? (
            <UilEye />
          ) : (
            <UilEyeSlash />
          )}
        </button>
    </div>
  );
};

export default PasswordInputWithToggle;
