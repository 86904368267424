import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';

// Queries
import { Me } from '../../libs/graphql/queries/me';
import { withTrendsView } from '../../apollo/stores/trendsView';
import { DailyUserData } from '../../libs/graphql/queries/data';

import TrendsSummarySkin from './TrendsSummarySkin';
import TrendsSummaryTrackers from './TrendsSummaryTrackers';
import TrendsSummaryTriggers from './TrendsSummaryTriggers';
import TrendsSummaryTreatment from './TrendsSummaryTreatment';
import TrendsSummaryDiet from './TrendsSummaryDiet';
import { DietBlueIcon, SkinTrendsIcon, TrackersBlueIcon, TreatmentsBlueIcon, TriggersBlueIcon } from '../../assets/icons';

const TrendsSummary = (props) => {
  const [mode, setMode] = useState('desktop');
  const mediaQuery = window.matchMedia('(max-width: 1023px)'); // large-down

  const { loading: loadingMe, error: errorMe, data: meData } = useQuery(Me, {
    fetchPolicy: 'cache-and-network',
  });

  const { loading: loadingData, error: errorData, data: dailyUserData } = useQuery(DailyUserData, {
    variables: {
      id: meData?.me?.id,
      today: props.trendsView && props.trendsView.selectedDate
        ? moment(props.trendsView.selectedDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      readOnly: true,
    },
    skip: !meData?.me?.id, // Skip the query until meData is available
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const mediaQueryHandler = (e) => {
      if (e.matches) {
        setMode('tablet');
      } else {
        setMode('desktop');
      }
    };

    mediaQuery.addListener(mediaQueryHandler);
    mediaQueryHandler(mediaQuery); // Initialize mode

    return () => {
      mediaQuery.removeListener(mediaQueryHandler);
    };
  }, [mediaQuery]);

  const renderSummaryData = () => {
    let date = moment(props.trendsView.selectedDate);

    if (loadingData) {
      return <p>Loading...</p>;
    }

    if (errorData) {
      return <p>Error loading data.</p>;
    }

    if (!loadingMe && !errorMe && props.trendsView && props.trendsView.selectedDate) {
      props.onLoad();
    }
    

    if (!dailyUserData?.dailyUserData) {
      return (
        <p className={'no-trends-summary-data'}>
          Nothing Tracked for {date.format('MMMM D')}.{' '}
          {!(moment().diff(date, 'days') >= 7) && (
            <a
              className={'no-trends-link'}
              onClick={(e) => {
                e.preventDefault();
                props.history.push({
                  pathname: '/dashboard',
                  state: { selectedDate: props.trendsView.selectedDate },
                });
              }}>
              Add missing tracking data?
            </a>
          )}
        </p>
      );
    }

    if (mode === 'desktop') {
      return (
        <div className="trends-summary-data">
          <div>
            <h2 className="headers">
              <span className="icon-heading">
                <SkinTrendsIcon />{' '}
                <span className="trends-summary-text__16p">Skin</span>
              </span>
            </h2>
            <hr className="hr100" />
            <TrendsSummarySkin 
              skinData={dailyUserData.dailyUserData.skinData} 
              pictures={dailyUserData.dailyUserData.pictures}
            />
          </div>
          <div>
            <h2 className="headers">
              <span className="icon-heading">
                <TrackersBlueIcon />{' '}
                <span className="trends-summary-text__16p">Trackers</span>
              </span>
            </h2>
            <hr className="hr100" />
            <TrendsSummaryTrackers userData={dailyUserData.dailyUserData} />
            <h2 className="headers">
              <span className="icon-heading">
                <DietBlueIcon />{' '}
                <span className="trends-summary-text__16p">Diet</span>
              </span>
            </h2>
            <hr className="hr100" />
            <TrendsSummaryDiet dietData={dailyUserData.dailyUserData.dietData} />
            <h2 className="headers">
              <span className="icon-heading">
                <TriggersBlueIcon />{' '}
                <span className="trends-summary-text__16p">Triggers</span>
              </span>
            </h2>
            <hr className="hr100" />
            <TrendsSummaryTriggers triggerData={dailyUserData.dailyUserData.triggerData} />
          </div>
          <div>
            <h2 className="headers">
              <span className="icon-heading">
                <TreatmentsBlueIcon />{' '}
                <span className="trends-summary-text__16p">Treatments</span>
              </span>
            </h2>
            <hr className="hr100" />
            <TrendsSummaryTreatment
              treatmentData={dailyUserData.dailyUserData.treatmentData}
              treatments={meData.me.profile.userTreatments}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="trends-summary-data">
          <div>
            <TrendsSummarySkin 
              skinData={dailyUserData.dailyUserData.skinData} 
              pictures={dailyUserData.dailyUserData.pictures}
            />
            <TrendsSummaryTrackers userData={dailyUserData.dailyUserData} />
            <TrendsSummaryDiet dietData={dailyUserData.dailyUserData.dietData} />
          </div>
          <div>
            <TrendsSummaryTriggers triggerData={dailyUserData.dailyUserData.triggerData} />
            <TrendsSummaryTreatment
              treatmentData={dailyUserData.dailyUserData.treatmentData}
              treatments={meData.me.profile.userTreatments}
            />
          </div>
        </div>
      );
    }
  };

  if (loadingMe || errorMe || !props.trendsView || !props.trendsView.selectedDate) {
    return null;
  }

  const selectedDateFormatted = moment(props.trendsView.selectedDate).format('MMMM D');

  return (
    <div className="trends-summary">
      <header>
        <span className="trends-summary-text">
          Summary of {selectedDateFormatted}
        </span>
      </header>
      <hr />
      {renderSummaryData()}
    </div>
  );
};

export default compose(
  withRouter,
  withTrendsView,
)(TrendsSummary);
