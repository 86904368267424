import React from 'react';

export default function ForwardCircleArrow() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="15.9644"
        cy="16"
        rx="15.9644"
        ry="16"
        transform="matrix(-1 0 0 1 32.8933 0)"
        fill="#E7EAF1"
      />
      <path
        d="M17.6373 15.9999L14.1052 12.4599C13.9193 12.2726 13.815 12.0191 13.815 11.7549C13.815 11.4908 13.9193 11.2373 14.1052 11.0499C14.1979 10.9562 14.3083 10.8818 14.4299 10.8311C14.5515 10.7803 14.6819 10.7542 14.8136 10.7542C14.9453 10.7542 15.0757 10.7803 15.1973 10.8311C15.3189 10.8818 15.4293 10.9562 15.522 11.0499L19.7526 15.2899C19.8461 15.3829 19.9203 15.4935 19.971 15.6154C20.0216 15.7372 20.0477 15.8679 20.0477 15.9999C20.0477 16.132 20.0216 16.2627 19.971 16.3845C19.9203 16.5064 19.8461 16.617 19.7526 16.7099L15.522 20.9999C15.4288 21.0926 15.3182 21.166 15.1966 21.2157C15.0751 21.2655 14.9449 21.2907 14.8136 21.2899C14.6823 21.2907 14.5521 21.2655 14.4305 21.2157C14.309 21.166 14.1984 21.0926 14.1052 20.9999C13.9193 20.8126 13.815 20.5591 13.815 20.2949C13.815 20.0308 13.9193 19.7773 14.1052 19.5899L17.6373 15.9999Z"
        fill="#1D62ED"
      />
    </svg>
  );
}
