import React from 'react';

export default function ItchTrendsIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10.5" r="10" fill="#FF9672" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3023 13.3002L12.0921 11.4674L14.609 10.8119C14.6385 10.8042 14.6579 10.7761 14.6545 10.746C14.6512 10.7155 14.6264 10.6922 14.5959 10.6912L11.9152 10.5826L15.0521 7.55231C15.0749 7.53049 15.0773 7.4951 15.0579 7.47086C15.039 7.44565 15.0041 7.43934 14.9775 7.45583L11.2286 9.77048L12.1469 4.90602C12.1527 4.87499 12.1343 4.84444 12.1038 4.83572C12.0737 4.82699 12.0417 4.84202 12.0301 4.8716L10.1581 9.50188L8.65748 6.51959C8.6439 6.49195 8.6119 6.47983 8.58281 6.48904C8.55372 6.49923 8.53627 6.52929 8.54257 6.55935L9.23251 9.89751L4.7564 7.55716C4.72828 7.54261 4.69434 7.55134 4.6764 7.57801C4.65943 7.60419 4.66525 7.6391 4.68949 7.65898L8.60027 10.8197L5.28004 11.4713C5.24998 11.4771 5.22864 11.5043 5.23058 11.5348C5.23252 11.5659 5.25676 11.5906 5.28731 11.5925L8.46305 11.8107L4.80246 14.9806C4.77871 15.0015 4.77483 15.0369 4.79277 15.0626C4.8044 15.0791 4.82331 15.0883 4.84271 15.0883C4.85289 15.0883 4.86355 15.0859 4.87277 15.0805L9.16026 12.6476L8.26766 16.3882C8.26039 16.4182 8.27687 16.4492 8.30596 16.4599C8.31324 16.4623 8.32051 16.4638 8.3273 16.4638C8.3496 16.4638 8.37142 16.4512 8.38209 16.4303L10.1169 13.0131L10.8369 15.0446C10.8451 15.0689 10.8684 15.0854 10.8941 15.0854C10.8975 15.0854 10.9013 15.0849 10.9047 15.0844C10.9343 15.0791 10.9557 15.0534 10.9557 15.0238L10.944 12.9181L14.4049 16.3091C14.4165 16.3208 14.4325 16.3266 14.448 16.3266C14.4621 16.3266 14.4756 16.3217 14.4873 16.3125C14.5115 16.2922 14.5164 16.2572 14.4984 16.2311L11.9268 12.4512L15.2548 13.4122C15.2611 13.4136 15.2664 13.4146 15.2722 13.4146C15.296 13.4146 15.3188 13.4005 15.3285 13.3773C15.3406 13.3487 15.3295 13.3157 15.3023 13.3002Z"
        fill="#F9FAFA"
      />
    </svg>
  );
}
