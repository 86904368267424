import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ActionSvg } from '../../../assets/icons';
import ListInput from '../ListInput';
import SelectDropDown from './SelectDropDown';

const GROUP_DESCRIPTION = `Which Group is this Treatment Option?`;

const CLASS_DESCRIPTION = `Does this Treatment Option fall under Steroids or No Steroids?<br/>
If the Group is "Topicals" and Class is "Steroids" rendering under Manage Tab changes.`;

const DEFAULT_DESCRIPTION = `Should this treatment appear as selected by default in the Provider Prep?`;

const TreatmentEditor = ({
  id,
  name,
  pronunciation,
  generic,
  default_checked,
  drug_names,
  brand_names,
  group_id,
  class_id,
  metadata_id,
  onSubmit,
  onDelete,
  onCancel,
  metadata,
  classes,
  groups,
}) => {
  const [editing, setEditing] = useState(id === null);

  group_id = group_id || groups[0].id;
  class_id = class_id || null;
  metadata_id = metadata_id || metadata[0].id;

  drug_names = drug_names || [];
  brand_names = brand_names || [];

  const original = {
    id,
    name,
    pronunciation,
    generic,
    default_checked,
    drug_names,
    brand_names,
    group_id,
    class_id,
    metadata_id,
  };

  const [localValues, setNewValue] = useState({
    id,
    name,
    pronunciation,
    generic,
    default_checked,
    drug_names,
    brand_names,
    group_id,
    class_id,
    metadata_id,
  });

  const [edited, hasBeenEdited] = useState(false);

  const updateListValue = (name, value) => {
    hasBeenEdited(true);
    setNewValue({
      ...localValues,
      [name]: value,
    });
  };

  const updateValue = e => {
    const newVal =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    hasBeenEdited(true);
    setNewValue({
      ...localValues,
      [e.target.name]: newVal,
    });
  };

  if (!editing) {
    return (
      <div
        className={`card card__active treatment-tile editing`}
        onClick={e => setEditing(true)}>
        <h2>{name || ''}</h2>
      </div>
    );
  }

  return (
    <div className={`card card__active treatment-tile`}>
      <ReactTooltip
        type={'dark'}
        multiline={true}
        className={'admin-tooltip'}
      />
      <h2>{localValues.name || ''}</h2>
      <ul>
        <li>
          <label>Name</label>
          <input
            type="text"
            required={true}
            value={localValues.name || ''}
            name={'name'}
            onChange={updateValue}
          />
          <span
            style={{
              paddingLeft: '3px',
              fontSize: '24px',
              lineHeight: '32px',
            }}>
            *
          </span>
        </li>
        <li>
          <label>Pronunciation</label>
          <input
            type="text"
            value={localValues.pronunciation || ''}
            name={'pronunciation'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>Generic</label>
          <input
            type="checkbox"
            value={localValues.generic || false}
            checked={localValues.generic || false}
            name={'generic'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>
            Default
            <span
              data-tip={DEFAULT_DESCRIPTION}
              className="admin-help material-icons">
              help_outline
            </span>
          </label>
          <input
            type="checkbox"
            value={localValues.default_checked || false}
            checked={localValues.default_checked || false}
            name={'default_checked'}
            onChange={updateValue}
          />
        </li>
        <li>
          <label>
            <button
              className="button button-action button-action__add"
              onClick={e => {
                e.stopPropagation();
                const newValue = localValues.drug_names.slice(0);
                newValue.push('');
                updateListValue('drug_names', newValue);
              }}>
              <ActionSvg />
            </button>
            Drug Names
          </label>
          <ListInput
            value={localValues.drug_names || []}
            onChange={val => {
              updateListValue('drug_names', val);
            }}
          />
        </li>
        <li>
          <label>
            <button
              className="button button-action button-action__add"
              onClick={e => {
                e.stopPropagation();
                const newValue = localValues.brand_names.slice(0);
                newValue.push('');
                updateListValue('brand_names', newValue);
              }}>
              <ActionSvg />
            </button>
            Brand Names
          </label>
          <ListInput
            value={localValues.brand_names || []}
            onChange={val => {
              updateListValue('brand_names', val);
            }}
          />
        </li>
        <li>
          <label>
            Group
            <span
              data-tip={GROUP_DESCRIPTION}
              className="admin-help material-icons">
              help_outline
            </span>
          </label>
          <SelectDropDown
            options={groups}
            defineLabel={option => option.treatment_group}
            value={localValues.group_id}
            onChange={val => {
              updateValue({
                target: { type: 'text', name: 'group_id', value: val.value },
              });
            }}
          />
        </li>
        <li>
          <label>
            Class
            <span
              data-tip={CLASS_DESCRIPTION}
              className="admin-help material-icons">
              help_outline
            </span>
          </label>
          <SelectDropDown
            options={[{ id: null, label: '-' }, ...classes]}
            defineLabel={option => {
              return option.id
                ? `${option.treatment_class}${
                    option.formulation ? ` (${option.formulation})` : ''
                  }`
                : option.label;
            }}
            value={localValues.class_id}
            onChange={val => {
              updateValue({
                target: { type: 'text', name: 'class_id', value: val.value },
              });
            }}
          />
        </li>
        <li>
          <div style={{ padding: '32px 16px' }}>
            <p>
              For the <b>Manage Tab</b>, if an Option belongs to a Group... then
              it is put directly under that Group name. HOWEVER, if an Option
              belongs to a Class (it's possible for Class to be null) then that
              Option is organized under that Class's Group, not the Group listed
              here.
            </p>
            <p>
              The Option will always be categorized under Group for the{' '}
              <b>Smart Search</b> in the Account Settings page.
            </p>
          </div>
        </li>
        <li>
          <label>Metadata</label>
          <SelectDropDown
            options={metadata}
            defineLabel={option => option.id}
            value={localValues.metadata_id}
            onChange={val => {
              updateValue({
                target: { type: 'text', name: 'metadata_id', value: val.value },
              });
            }}
          />
        </li>
      </ul>
      {editing && (
        <div className={'treatment_actions'}>
          {id !== null && (
            <button
              className={'button button-regular button__red'}
              onClick={e => {
                if (
                  window.confirm('Are you sure you want to permanently delete?')
                ) {
                  onDelete(localValues);
                  setEditing(false);
                }
              }}>
              Delete
            </button>
          )}
          <div className="stretch"></div>
          <button
            className={'button button-regular button__gray'}
            onClick={e => {
              setNewValue(original);
              setEditing(false);
              onCancel();
            }}>
            Cancel
          </button>
          <button
            disabled={!edited || !localValues.name}
            className={'button button-regular button__green'}
            onClick={e => {
              onSubmit(localValues);
              setEditing(false);
            }}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default TreatmentEditor;
