import { flowRight as compose } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'react-apollo';
import { NavLink, Route, Switch, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  updateTreatment,
  updateTreatmentClass,
  updateTreatmentGroup,
  updateTreatmentMetadata,
} from '../../libs/graphql/mutations/admin';
import { TreatmentOptionsAndMetadata } from '../../libs/graphql/queries/data';
import ClassEditor from './treatments/ClassEditor';
import GroupEditor from './treatments/GroupEditor';
import MetaEditor from './treatments/MetaEditor';
import TreatmentEditor from './treatments/TreatmentEditor';

const GROUP_DESCRIPTION = `A Treatment Option is assigned to a Group
to render it properly in the Account Settings page as well as in the
Manage tab. Groups will automatically be rendered in the Manage tab
but not in the Account Settings page (Topicals, Systemics, OTC, Biologics, Other).<br/>
For recommendations (smart search) to work: the group must be the same name as
what is listed under Account Settings.`;

const CLASS_DESCRIPTION = `A treatment Class is used to further classify the Treatment Option
under the Manage tab. For the Class to work properly, name the Class either: 1)
"Steroids"; or 2) "No Steroids".<br/>
If the name for the Class is Steroids and the Group is Topicals, you'll render the
Class name and formulation rather than the Treatment Option (brand and drug names will
still be listed).`;

const METADATA_DESCRIPTION = `Additional descriptors for a Treatment Option.`;

const withTreatmentOptionsAndMetadata = graphql(TreatmentOptionsAndMetadata, {
  props: ({
    data: {
      loading,
      error,
      availableTreatmentOptions,
      availableTreatmentOptionsMetadata: metadata,
      availableTreatmentOptionsClasses: classes,
      availableTreatmentOptionsGroups: groups,
    },
  }) => ({
    loading,
    error,
    availableTreatmentOptions,
    metadata,
    classes,
    groups,
  }),
});

const withUpdateTreatment = graphql(updateTreatment, {
  name: 'updateTreatment',
});

const withUpdateTreatmentGroup = graphql(updateTreatmentGroup, {
  name: 'updateTreatmentGroup',
});

const withUpdateTreatmentClass = graphql(updateTreatmentClass, {
  name: 'updateTreatmentClass',
});

const withUpdateTreatmentMetadata = graphql(updateTreatmentMetadata, {
  name: 'updateTreatmentMetadata',
});

const AdminEditTreatments = ({
  loading,
  error,
  availableTreatmentOptions,
  metadata,
  classes,
  groups,
  updateTreatment,
  updateTreatmentClass,
  updateTreatmentGroup,
  updateTreatmentMetadata,
}) => {
  const [localTreatmentOptions, setLocalTreatmentOptions] = useState(
    availableTreatmentOptions,
  );
  useEffect(() => {
    setLocalTreatmentOptions(availableTreatmentOptions);
  }, [availableTreatmentOptions]);

  const [localMetadata, setLocalMetaData] = useState(metadata);
  useEffect(() => {
    setLocalMetaData(metadata);
  }, [metadata]);

  const [localTreatmentClasses, setLocalTreatmentClasses] = useState(classes);
  useEffect(() => {
    setLocalTreatmentClasses(classes);
  }, [classes]);

  const [localTreatmentGroups, setLocalTreatmentGroups] = useState(groups);
  useEffect(() => {
    setLocalTreatmentGroups(groups);
  }, [groups]);

  if (
    error ||
    loading ||
    !availableTreatmentOptions ||
    !metadata ||
    !classes ||
    !groups
  )
    return null;

  return (
    <Fragment>
      <ReactTooltip
        type={'dark'}
        multiline={true}
        className={'admin-tooltip'}
      />
      <nav className={'treatment-editor-navigation'}>
        <NavLink
          active={'active'}
          exact
          to={'/admin/treatments/groups'}
          className="title title__cobalt ">
          Group
          <span
            data-tip={GROUP_DESCRIPTION}
            className="admin-help material-icons">
            help_outline
          </span>
        </NavLink>
        <span className="material-icons">chevron_right</span>
        <NavLink
          active={'active'}
          exact
          to={'/admin/treatments/classes'}
          className="title title__cobalt ">
          Class
          <span
            data-tip={CLASS_DESCRIPTION}
            className="admin-help material-icons">
            help_outline
          </span>
        </NavLink>
        <span className="material-icons">chevron_right</span>
        <NavLink
          active={'active'}
          exact
          to={'/admin/treatments'}
          className="title title__cobalt ">
          Treatment Option
        </NavLink>
        <span className="material-icons">add</span>
        <NavLink
          active={'active'}
          exact
          to={'/admin/treatments/metadata'}
          className="title title__cobalt ">
          Metadata
          <span
            data-tip={METADATA_DESCRIPTION}
            className="admin-help material-icons">
            help_outline
          </span>
        </NavLink>
        <div style={{ flex: '1' }}></div>
        <Switch>
          <Route path={'/admin/treatments/groups'} exact>
            <button
              className="button button-regular button__green"
              onClick={e => {
                setLocalTreatmentGroups([
                  {
                    id: null,
                    name: null,
                    pronunciation: null,
                    generic: null,
                    drug_names: [],
                    brand_names: [],
                    group_id: null,
                    class_id: null,
                    metadata_id: null,
                  },
                  ...localTreatmentGroups,
                ]);
              }}>
              New <b>Group</b>
            </button>
          </Route>
          <Route path={'/admin/treatments/classes'} exact>
            <button
              className="button button-regular button__green"
              onClick={e => {
                setLocalTreatmentClasses([
                  {
                    id: null,
                    treatment_group_id: null,
                    treatment_class: null,
                    formulation: null,
                  },
                  ...localTreatmentClasses,
                ]);
              }}>
              New <b>Class</b>
            </button>
          </Route>
          <Route path={'/admin/treatments/metadata'} exact>
            <button
              className="button button-regular button__green"
              onClick={e => {
                setLocalMetaData([
                  {
                    id: null,
                    target_group: '',
                    action: '',
                    use: '',
                    efficacy: '',
                    side_effects: '',
                    cost: 0,
                  },
                  ...localMetadata,
                ]);
              }}>
              New <b>Metadata</b>
            </button>
          </Route>
          <Route path={'/admin/treatments'} exact>
            <button
              className="button button-regular button__green"
              onClick={e => {
                setLocalTreatmentOptions([
                  {
                    id: null,
                    name: null,
                    pronunciation: null,
                    generic: null,
                    drug_names: [],
                    brand_names: [],
                    group_id: null,
                    class_id: null,
                    metadata_id: null,
                  },
                  ...localTreatmentOptions,
                ]);
              }}>
              New <b>Treatment</b>
            </button>
          </Route>
        </Switch>
      </nav>
      <Switch>
        <Route path={'/admin/treatments/classes'} exact>
          <Fragment>
            {localTreatmentClasses &&
              localTreatmentClasses.map(
                (treatmentClass, i) =>
                  treatmentClass && (
                    <ClassEditor
                      key={treatmentClass.id || `_${i}`}
                      onCancel={() => {
                        if (treatmentClass.id === null) {
                          const _localTreatmentClasses =
                            localTreatmentClasses.slice(0);
                          _localTreatmentClasses.splice(i, 1);
                          setLocalTreatmentClasses(_localTreatmentClasses);
                        }
                      }}
                      onSubmit={payload => {
                        updateTreatmentClass({
                          variables: { payload },
                          refetchQueries: ['TreatmentOptionsAndMetadata'],
                        });
                      }}
                      {...treatmentClass}
                      groups={groups}
                    />
                  ),
              )}
          </Fragment>
        </Route>
        <Route path={'/admin/treatments/groups'} exact>
          <Fragment>
            {localTreatmentGroups &&
              localTreatmentGroups.map((group, i) => (
                <GroupEditor
                  key={group.id || `_${i}`}
                  onCancel={() => {
                    if (group.id === null) {
                      const _localTreatmentGroups =
                        localTreatmentGroups.slice(0);
                      _localTreatmentGroups.splice(i, 1);
                      setLocalTreatmentGroups(_localTreatmentGroups);
                    }
                  }}
                  onSubmit={payload => {
                    updateTreatmentGroup({
                      variables: { payload },
                      refetchQueries: ['TreatmentOptionsAndMetadata'],
                    });
                  }}
                  {...group}
                />
              ))}
          </Fragment>
        </Route>
        <Route path={'/admin/treatments/metadata'} exact>
          <Fragment>
            {localMetadata &&
              localMetadata.map((meta, i) => (
                <MetaEditor
                  key={`${meta.id}${i}`}
                  onCancel={() => {
                    if (meta.id === null) {
                      const _localMetadata = localMetadata.slice(0);
                      _localMetadata.splice(i, 1);
                      setLocalMetaData(_localMetadata);
                    }
                  }}
                  onSubmit={payload => {
                    updateTreatmentMetadata({
                      variables: { payload },
                      refetchQueries: ['TreatmentOptionsAndMetadata'],
                    });
                  }}
                  {...meta}
                />
              ))}
          </Fragment>
        </Route>
        <Route path={'/admin/treatments'} exact>
          <Fragment>
            {localTreatmentOptions &&
              localTreatmentOptions.map((treatmentOption, i) => {
                return (
                  <TreatmentEditor
                    key={treatmentOption.id || `_${i}`}
                    onCancel={() => {
                      if (treatmentOption.id === null) {
                        const _localTreatmentOptions =
                          localTreatmentOptions.slice(0);
                        _localTreatmentOptions.splice(i, 1);
                        setLocalTreatmentOptions(_localTreatmentOptions);
                      }
                    }}
                    onSubmit={payload => {
                      payload.brand_names = payload.brand_names.filter(
                        s => !!s,
                      );
                      payload.drug_names = payload.drug_names.filter(s => !!s);
                      updateTreatment({
                        variables: { payload },
                        refetchQueries: ['TreatmentOptionsAndMetadata'],
                      });
                    }}
                    onDelete={payload => {
                      updateTreatment({
                        variables: { payload, shouldDelete: true },
                        refetchQueries: ['TreatmentOptionsAndMetadata'],
                      });
                    }}
                    {...treatmentOption}
                    metadata={metadata}
                    classes={classes}
                    groups={groups}
                  />
                );
              })}
          </Fragment>
        </Route>
      </Switch>
    </Fragment>
  );
};

export default compose(
  withRouter,
  withTreatmentOptionsAndMetadata,
  withUpdateTreatment,
  withUpdateTreatmentGroup,
  withUpdateTreatmentClass,
  withUpdateTreatmentMetadata,
)(AdminEditTreatments);
