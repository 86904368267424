import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Login from '../components/login';
import { login } from '../libs/graphql/mutations/user';
import MixpanelService from '../libs/mixpanel';
import Hero from '../assets/images/home/ew-set.png';

const withMutation = graphql(login, {
  name: 'login',
});

const loginPage = props => {
  if (props.me && props.me.id) {
    props.history.push('dashboard');
  }
  const loginButtonClick = (email, password) => {
    return props
      .login({
        variables: {
          email: email,
          password: password,
        },
      })
      .then(res => {
        const {
          data: {
            authenticateUser: { token, profileFlag, id, name },
          },
        } = res;

        if (token) {
          MixpanelService.identify(id);
          MixpanelService.registerSuperProps({ UserId: id });
          MixpanelService.setProfileProperty('UserId', id);
          MixpanelService.setProfileProperty('$name', name);

          props.client.resetStore().then(() => {
            if (profileFlag) {
              props.history.push({
                pathname: '/dashboard',
                state: { from: 'login' },
              });
            } else {
              props.history.push('/signup/profile');
            }
          });
        }
      });
  };

  return (
    <div className="page page-login page__cobalt">
      <div className="content-wrapper">
        <div className="content-section">
          {!props.me || !props.me.id ? (
            <Login
              email={props.location.state ? props.location.state.email : ''}
              login={loginButtonClick}
              history={props.history}
            />
          ) : null}
        </div>
        <div className="image-section">
          <img src={Hero} alt="" />
        </div>
      </div>
    </div>
  );
};

loginPage.defaultProps = {
  me: null,
};

loginPage.propTypes = {
  login: PropTypes.func.isRequired,
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  me: PropTypes.objectOf(PropTypes.any),
};

export default compose(withRouter, withApollo, withMutation)(loginPage);
