import React, { Fragment } from 'react';
import Treatment from './Treatment';

const TreatmentTrackerWriteView = props => {
  let {
    treatmentData,
    errors,
    notes,
    treatmentHandler,
    treatmentMetadataHandler,
    updateNotesInput,
  } = props;
  let treatmentComponents = [];
  if (!Object.keys(treatmentData).length) return null;
  Object.keys(treatmentData).forEach(treatment => {
    let currentTreatment = treatmentData[treatment];
    if (currentTreatment.selected || currentTreatment.active) {
      treatmentComponents.push(
        <li key={treatment}>
          <Treatment
            treatment={currentTreatment.type}
            name={currentTreatment.name}
            selected={currentTreatment.selected}
            metadata={currentTreatment.metadata || {}}
            metadataHandler={treatmentMetadataHandler.bind(null, treatment)}
            treatmentId={treatment}
            treatmentHandler={treatmentHandler.bind(null, treatment)}
            errors={errors}
          />
        </li>,
      );
    }
  });

  return (
    <Fragment>
      <ul className="tracker-list">{treatmentComponents}</ul>
      <div className="form-item tracker-body-item tracker-notes">
        <label className="form-input-label">Notes</label>
        <input
          className="form-input form-input-field"
          type="text"
          value={notes}
          onChange={updateNotesInput}
          placeholder="Add notes (optional)"
        />
      </div>
    </Fragment>
  );
};

export default TreatmentTrackerWriteView;
