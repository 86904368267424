import React, { Fragment } from 'react';
import Treatment from './Treatment';

const TreatmentTrackerReadView = props => {
  let { treatmentData, errors, notes } = props;
  let treatmentComponentsGrouped = {
    morning: [],
    afternoon: [],
    evening: [],
    bedtime: [],
  };

  if (!Object.keys(treatmentData).length) return null;

  Object.keys(treatmentData).forEach(treatment => {
    let currentTreatment = treatmentData[treatment];

    Object.keys(currentTreatment.metadata).forEach(tod => {
      if (currentTreatment.selected && currentTreatment.metadata[tod]) {
        treatmentComponentsGrouped[tod].push(
          <Treatment
            name={currentTreatment.name}
            treatment={currentTreatment.type}
            selected={currentTreatment.selected}
            metadata={currentTreatment.metadata || {}}
            metadataHandler={() => {}}
            treatmentHandler={() => {}}
            key={tod + '-' + treatment}
            read={true}
            errors={errors}
          />,
        );
      }
    });
  });

  return (
    <Fragment>
      <div
        className={`tracker-grid-item${
          treatmentComponentsGrouped.morning.length === 0
            ? ` treatment__empty`
            : ``
        }`}>
        <div className="tracker-grid-item-title">
          <span className="morning" />
          <h4 className="subtext subtext__uppercase">Morning</h4>
        </div>
        <div>{treatmentComponentsGrouped.morning}</div>
      </div>

      <div
        className={`tracker-grid-item${
          treatmentComponentsGrouped.afternoon.length === 0
            ? ` treatment__empty`
            : ``
        }`}>
        <div className="tracker-grid-item-title">
          <span className="afternoon" />
          <h4 className="subtext subtext__uppercase">Afternoon</h4>
        </div>
        <div>{treatmentComponentsGrouped.afternoon}</div>
      </div>

      <div
        className={`tracker-grid-item${
          treatmentComponentsGrouped.evening.length === 0
            ? ` treatment__empty`
            : ``
        }`}>
        <div className="tracker-grid-item-title">
          <span className="meta evening" />
          <h4 className="subtext subtext__uppercase">Evening</h4>
        </div>
        <div>{treatmentComponentsGrouped.evening}</div>
      </div>

      <div
        className={`tracker-grid-item${
          treatmentComponentsGrouped.bedtime.length === 0
            ? ` treatment__empty`
            : ``
        }`}>
        <div className="tracker-grid-item-title">
          <span className="meta bedtime" />
          <h4 className="subtext subtext__uppercase">Bedtime</h4>
        </div>
        <div>{treatmentComponentsGrouped.bedtime}</div>
      </div>

      <div
        className={`tracker-grid-item tracker-notes tracker-notes__inactive`}>
        <h4 className="subtext subtext__uppercase">Notes</h4>
        <p>{notes}</p>
      </div>
    </Fragment>
  );
};

export default TreatmentTrackerReadView;
