import { capitalCase } from 'change-case';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import ReactDatePicker from 'react-datepicker';
import { withAppointmentImages } from '../../apollo/stores/appointments';
import { ProgressStep } from '../../assets/icons';

const AppointmentImages = props => {  
  const [date, setDate] = useState(new Date());
  const { data, loading, error } = useQuery(
    gql`
      query dailyUserData($id: ID!, $today: String!, $readOnly: Boolean) {
        dailyUserData(id: $id, today: $today, readOnly: $readOnly) {
          id
          updated_date
          tracker_date
          pictures {
            area_affected
            images {
              id
              uri
            }
          }
        }
      }
    `,
    {
      variables: {
        today: date,
        readOnly: true,
        id: props.me.id,
      },
    },
  );

  // we want the images list, or an empty array
  const images =
    loading || error
      ? []
      : data && data.dailyUserData ? data.dailyUserData.pictures
      : [];

  const [availableAreasAffected, setAvailableAreasAffected] = useState([]);
  useEffect(() => {
    setAvailableAreasAffected(
      images.map((_region, i) => {
        return _region.area_affected;
      }),
    );
  }, [data]);

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    props.updateAffectedAreaImages({
      variables: { images: selectedImages.map(i => i.id) },
    });
  }, [selectedImages]);

  const focusElement = React.useRef(null);

  useEffect(() => {
    if (focusElement.current)
      focusElement.current.focus();
  }, []);

  return (
    <div className="tracker-body survey__find-your-focus">
      <div className="survey-title">
        <div className="survey-title__row">
          <ProgressStep step="4" />
          <h2 className="title title__cobalt" tabindex="0" ref={focusElement}>Affected Areas</h2>
        </div>
        <p className="paragraph paragraph__semibold paragraph__italic paragraph__light-text">
          Add photos to your appointment summary to show progress and status.
        </p>
      </div>
      <div className="form">
        <div className="form-section form__border-top section__find-focus">
          <h3 className="title">
            1. Select date:{' '}
            <ReactDatePicker
              className={`form-input-field`}
              selected={date}
              onChange={setDate}
              maxDate={new Date()}
              dateFormat="M/d/yyyy"
              placeholderText="Select Date"
            />
          </h3>
        </div>
        <div className="form-section form__border-top section__find-focus">
          <h3 className="title">2. Select Images:</h3>
          {images.length === 0 ? (
            <div>No images were found for the given date.</div>
          ) : (
            <div>
              {images.map((_region, i) => {
                return (
                  <Fragment key={i}>
                    <h1>{capitalCase(_region.area_affected)}</h1>
                    {_region.images.map((image, i) => (
                      <button
                        key={i}
                        onClick={e => {
                          const _selectedImages = selectedImages.filter(
                            s => s.id !== image.id,
                          );
                          if (
                            _selectedImages.length === selectedImages.length
                          ) {
                            _selectedImages.push(image);
                          }
                          setSelectedImages(_selectedImages);
                        }}>
                        <img width={100} height={100} src={image.uri} alt="" />
                      </button>
                    ))}
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>
        <div className="form-section form__border-top section__find-focus">
          {selectedImages.map((image, i) => (
            <button
              onClick={e => {
                setSelectedImages(
                  selectedImages.filter(s => s.id !== image.id),
                );
              }}>
              <img width={60} height={60} key={i} src={image.uri} alt="" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default compose(withAppointmentImages)(AppointmentImages);
