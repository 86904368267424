import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import SurveyEditor from './survey/SurveyEditor';

const AdminSurveyEditor = props => {
  const { data, loading, error } = useQuery(gql`
    query {
      adminGetSurveys {
        id
        uri
        name
        ownerId
        lastModified
        creationDate
        isActive
        trigger_check_after
        trigger_check_before
        available_for
        description
        time_to_complete
        notification_message
        trigger
        trigger_delay
        is_reassignable
        qualifiers {
          races
          genders
          referred
          hispanic
          relation
          userIds
          zipCodes
          persona
          maxAge
          minAge
          currentTreatments
          treatmentTime
        }
      }
    }
  `);

  if (error || loading) return null;
  // const { adminGetSurveys } = data

  return data.adminGetSurveys.map((survey, i) => (
    <SurveyEditor key={i} {...survey} />
  ));
};

export default AdminSurveyEditor;
