import React from 'react';

const Checkbox = ({ label, checked, onChange, className }) => {
  return (
    <label className={`custom-checkbox ${className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="custom-checkbox-input"
      />
      <span className="custom-checkbox-span"></span>
      {label}
    </label>
  );
};

export default Checkbox;