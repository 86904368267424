import React, { useEffect } from 'react';
import PoScoradScale from '../shared/PoScoradScale';
import PoScoradSeverity from '../shared/PoScoradSeverity';
import moment from 'moment-timezone';
import FlareTrackerInfo from '../shared/FlareTrackerInfo';

const PoScoradTracker = props => {
  const {
    skinData,
    itchData,
    sleepData,
    SeverityClassName,
    poscoradData,
    userId,
    date,
    ScaleClassName,
  } = props;
  const now = moment().format('YYYY-MM-DD');
  const dateText = now !== date ? date : 'Today';

  // if (localStorage.getItem('firstLoginFlareTracker') === null) {
  //   localStorage.setItem('firstLoginFlareTracker', true);
  // } else {
  //   localStorage.setItem('firstLoginFlareTracker', false);
  // }

  return (
    <div className="card tracker tracker-no-shadow tracker-no-border tracker-poscorad">
      <div className="tracker-body">
        <PoScoradSeverity
          dateText={dateText}
          skinData={skinData}
          itchData={itchData}
          sleepData={sleepData}
          poscoradData={poscoradData}
          newClassName={SeverityClassName ?? ''}
        />
        <PoScoradScale
          dateText={dateText}
          skinData={skinData}
          itchData={itchData}
          sleepData={sleepData}
          poscoradData={poscoradData}
          newClassName={ScaleClassName ?? ''}
        />
        {/* <FlareTrackerInfo
          poscoradData={poscoradData}
          userId={userId}
          date={date}
        /> */}
      </div>
    </div>
  );
};

export default PoScoradTracker;
