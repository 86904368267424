import React from 'react';

export const TriggersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      height="36"
      width="36"
      className="icon icon-tracker tracker-icon  tracker-icon__trigger">
      <circle className="tracker-icon-background" cx="20" cy="20" r="20" />
      <g
        className="icon-image icon__trigger tracker-icon-image"
        transform="translate(7, 6) scale(.9)">
        <path d="M13.125,13.95 C13.125,15.10815 12.18315,16.05 11.025,16.05 C9.86685,16.05 8.925,15.10815 8.925,13.95 C8.925,12.79185 9.86685,11.85 11.025,11.85 C12.18315,11.85 13.125,12.79185 13.125,13.95 M20.475,13.95 C20.475,15.10815 19.53315,16.05 18.375,16.05 C17.21685,16.05 16.275,15.10815 16.275,13.95 C16.275,12.79185 17.21685,11.85 18.375,11.85 C19.53315,11.85 20.475,12.79185 20.475,13.95 M14.7,4.5 C9.4899,4.5 5.25,8.7399 5.25,13.95 L5.25,24.91515 C5.25,25.0191 5.3109,25.11255 5.40435,25.15455 C5.4999,25.19655 5.61015,25.1808 5.6868,25.1115 L7.6125,23.4 L9.8007,25.34565 C9.90045,25.43385 10.04955,25.43385 10.1493,25.34565 L12.3375,23.4 L14.5257,25.34565 C14.62545,25.43385 14.77455,25.43385 14.8743,25.34565 L17.0625,23.4 L19.2507,25.34565 C19.30005,25.38975 19.362,25.4118 19.425,25.4118 C19.488,25.4118 19.54995,25.38975 19.5993,25.34565 L21.7875,23.4 L23.7132,25.1115 C23.78985,25.1808 23.9001,25.19655 23.99565,25.15455 C24.0891,25.11255 24.15,25.0191 24.15,24.91515 L24.15,13.95 C24.15,8.7399 19.9101,4.5 14.7,4.5" />
      </g>
    </svg>
  );
};
