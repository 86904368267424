import React from 'react';

export default function Allergens() {
  return (
    <svg viewBox="0 0 26 23">
      <g className="icon-fill" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M16.8965,14.5529 C15.6545,16.7039 12.9025,17.4419 10.7505,16.1989 C8.5985,14.9569 7.8615,12.2049 9.1035,10.0529 C10.3455,7.9019 13.0975,7.1639 15.2495,8.4069 C17.4015,9.6489 18.1385,12.4009 16.8965,14.5529 M26.0005,12.3029 C26.0005,12.3029 24.7305,8.8029 20.4905,8.8029 C19.9565,8.8029 19.4255,8.8359 18.9065,8.8929 C19.2155,8.4719 19.5085,8.0289 19.7765,7.5669 C21.8965,3.8939 19.5005,1.0439 19.5005,1.0439 C19.5005,1.0439 15.8345,0.3939 13.7135,4.0669 C13.4465,4.5289 13.2105,5.0039 13.0005,5.4829 C12.7895,5.0039 12.5535,4.5289 12.2865,4.0669 C10.1665,0.3939 6.5005,1.0439 6.5005,1.0439 C6.5005,1.0439 4.1035,3.8939 6.2235,7.5669 C6.4915,8.0289 6.7845,8.4719 7.0935,8.8929 C6.5745,8.8359 6.0435,8.8029 5.5095,8.8029 C1.2695,8.8029 0.0005,12.3029 0.0005,12.3029 C0.0005,12.3029 1.2695,15.8029 5.5095,15.8029 C6.0435,15.8029 6.5745,15.7699 7.0935,15.7129 C6.7845,16.1339 6.4905,16.5769 6.2235,17.0389 C4.1035,20.7119 6.5005,23.5609 6.5005,23.5609 C6.5005,23.5609 10.1665,24.2119 12.2865,20.5389 C12.5535,20.0769 12.7895,19.6009 13.0005,19.1229 C13.2105,19.6009 13.4465,20.0769 13.7135,20.5389 C15.8345,24.2119 19.5005,23.5609 19.5005,23.5609 C19.5005,23.5609 21.8965,20.7119 19.7765,17.0389 C19.5095,16.5769 19.2155,16.1339 18.9065,15.7129 C19.4255,15.7699 19.9565,15.8029 20.4905,15.8029 C24.7305,15.8029 26.0005,12.3029 26.0005,12.3029"
          id="Fill-3"
        />
        <path
          d="M14.5,9.7052 C13.065,8.8762 11.23,9.3682 10.402,10.8032 C9.573,12.2372 10.065,14.0722 11.5,14.9002 C12.935,15.7292 14.77,15.2372 15.598,13.8032 C16.427,12.3682 15.935,10.5332 14.5,9.7052"
          id="Fill-1"
        />
      </g>
    </svg>
  );
}
