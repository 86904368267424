import React from 'react';
import AppointmentImages from '../forms/AppointmentImages';
import FocusForm from '../forms/FindYourFocus';
import PictureForm from '../forms/PaintBigPicture';
import TreatmentOptions from '../forms/TreatmentOptions';
import AppointmentSummary from './AppointmentSummary';

const SurveysContentWrapper = props => {
  let component;
  if (props.readOnly) {
    return (
      <AppointmentSummary
        apptId={props.apptId}
        me={props.me}
        date={props.date}
        time={props.time}
        name={props.name}
        readOnly={props.readOnly}
      />
    );
  }

  switch (props.page) {
    case 1:
      component = <FocusForm />;
      break;
    case 2:
      component = <PictureForm />;
      break;
    case 3:
      component = <TreatmentOptions />;
      break;
    case 4:
      component = <AppointmentImages me={props.me} />;
      break;
    case 5:
      component = <AppointmentSummary apptId={props.apptId} me={props.me} />;
  }
  return component;
};

export default SurveysContentWrapper;
