import gql from 'graphql-tag';

export const TriggerData = gql`
  query dailyUserData($id: ID!, $today: String!, $readOnly: Boolean) {
    dailyUserData(id: $id, today: $today, readOnly: $readOnly) {
      id
      updated_date
      tracker_date
      triggerData {
        data {
          type
          typeid
          selected
          trigger
          trigger_id
          trigger_selected
        }
      }
    }
  }
`;