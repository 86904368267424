import React, { Component } from 'react';
import SkinTrackerWriteContainer from '../../containers/SkinTrackerWriteContainer';
import TrackerHeader from '../shared/tracker-header';
import TrackerInstructions from '../shared/TrackerInstructions';
import SkinTrackerReadView from './SkinTrackerReadView';

// Mutations

class SkinTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      write: true,
      instructions: false,
      notes: '',
      treatmentData: {},
      errors: {},
      skinPictureLocation: 'all',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && Object.keys(nextProps.data).length) {
      //go to read state
      this.setState({
        write: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length) {
      //go to read state
      this.setState({
        write: false,
      });
    }
  }

  changeSkinPictureLocation(skinPictureLocation) {
    this.setState({ skinPictureLocation });
  }

  //handler passed to the write container
  handleWriteCancel() {
    let newState = {};
    // this means that there is already data in db and this was an update
    // we need to toggle back to read state with the previously submitted level
    if (this.props.data && Object.keys(this.props.data).length) {
      this.setState({
        write: false,
      });
    } else {
      this.setState({
        write: true,
      });
    }
  }

  // Prevent opening write view if instructions are open
  _toggleWrite() {
    if (!this.state.write && !this.state.instructions) {
      this.setState({
        write: true,
      });
    }
  }

  toggleInstructions() {
    let instructions = !this.state.instructions;
    this.setState({
      instructions,
    });
  }

  progressBadge() {
    if (!this.state.write) {
      return 'tracker-progress-complete';
    } else {
      return 'tracker-progress-incomplete';
    }
  }

  render() {
    const { write, instructions } = this.state;
    return (
      <div
        className={`card tracker tracker-loaded tracker-${
          this.props.theme.title
        } ${this.progressBadge()} ${this.props.theme.class || ''} ${
          write
            ? this.props.theme.color + ' card__active tracker__active'
            : this.props.theme.color + ' tracker__inactive'
        }`}>
        <TrackerHeader
          title={this.props.theme.title}
          write={write}
          icon={this.props.icon}
          edit={this._toggleWrite.bind(this)}
          instructions={this.toggleInstructions.bind(this)}
          instructionsClass={instructions ? 'tracker-header__instructions' : ''}
        />
        {instructions ? (
          <TrackerInstructions
            instructions={this.props.theme.instructionsText}
            tracker="skin"
          />
        ) : null}
        {!instructions ? (
          write ? (
            <SkinTrackerWriteContainer
              handleWriteCancel={this.handleWriteCancel.bind(this)}
              submit={this.props.changeHandler}
              date={this.props.date}
              data={this.props.data}
              id={this.props.id}
              changeSkinPictureLocation={this.changeSkinPictureLocation.bind(
                this,
              )}
              skinPictureLocation={this.state.skinPictureLocation}
            />
          ) : (
            <SkinTrackerReadView
              data={this.props.data}
              toggleWrite={this._toggleWrite.bind(this)}
              changeSkinPictureLocation={this.changeSkinPictureLocation.bind(
                this,
              )}
              skinPictureLocation={this.state.skinPictureLocation}
            />
          )
        ) : null}
      </div>
    );
  }
}

export default SkinTracker;
