import React from 'react';
import { UilLinkAlt, UilCheck, UilFacebook, UilWhatsappAlt, UilTwitter, UilLinkedin } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import MixpanelService, { MixpanelEvents } from '../../libs/mixpanel';
import config from '../../config';

const sharingCountMixpanel = (SharedTo) => {
    MixpanelService.track(MixpanelEvents.AppShared,{SharedTo});
    MixpanelService.setIncrementProfileProperty('AppShared',1);
}

const SharingURL = config.environmentURL;
const SharingTextEncodeURI = encodeURI('Sign up for EczemaWise to start tracking your eczema today! ' + SharingURL)
const sharingIconStyle = {
    color: '#00216c'
}
const ShareDiv = ({ icon, text, onClick, className }) => {
    return (
            <div
                onClick={() => {
                    onClick();
                    sharingCountMixpanel(text);
                }}
                className={className}
                >
                {icon} {text}
            </div>
    );
};


export const CopyLinkShareDiv = ({className}) => {
    return <ShareDiv 
        text='Copy link'
        icon={<UilLinkAlt size="18" style={sharingIconStyle} />} 
        onClick={
            () => {
                toast.success('Link copied', {
                    position: "top-center",
                    closeButton: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    icon: <UilCheck size="18" />
                });
                navigator.clipboard.writeText(SharingURL)
            }
        } 
        className={className}/>;
}

export const FacebookShareDiv = ({className}) => {
    return <ShareDiv 
        text='Facebook' 
        icon={<UilFacebook size="18" style={sharingIconStyle}/>} 
        onClick={
            () => {
                const text = encodeURI(SharingURL)
                window.open('https://www.facebook.com/sharer/sharer.php?u='+text, "_blank",
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=750');
            }
        } 
        className={className}/>;
}

export const TwitterShareDiv = ({className}) => {
    return <ShareDiv 
        text='Twitter' 
        icon={<UilTwitter size="18" style={sharingIconStyle} />} 
        onClick={
            () => {
                window.open('https://twitter.com/intent/tweet?text=' + SharingTextEncodeURI, "_blank",
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=750');
            }
        } 
        className={className}/>;
}

export const LinkedInShareDiv = ({className}) => {
    return <ShareDiv 
        text='LinkedIn' 
        icon={<UilLinkedin size="18" style={sharingIconStyle}/>} 
        onClick={
            () => {
                const text = encodeURI(SharingURL)
                window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + text, "_blank",
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=750');
            }
        } 
        className={className}/>;
}

export const WhatsappShareDiv = ({className}) => {
    return <ShareDiv 
        text='Whatsapp' 
        icon={<UilWhatsappAlt size="18" style={sharingIconStyle}/>} 
        onClick={
            () => {
                window.open('https://api.whatsapp.com/send?text=' + SharingTextEncodeURI, "_blank",
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=750');
            }
        } 
        className={className}/>;
}

export default ShareDiv;
