import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { BackArrow, BackCircleArrow, ForwardArrow, ForwardCircleArrow } from '../../assets/icons';

/**
 * PageButton:
 * A reusable component that adusts the date window we want to look at in the
 * application, moving a week at a time
 * @param direction String
 * -------------------------------------------------------------------------- */
class PageButton extends PureComponent {
  render() {
    // if current date is on this page, or after, don't disable forward arrow
    let forwardEnabled =
      this.props.direction !== 'back' &&
      this.props.trendsView &&
      this.props.trendsView.endDate === moment().format('YYYY-MM-DD') &&
      this.props.trendsView.endDate === this.props.trendsView.selectedDate;

    let arrowComponent =
      this.props.direction === 'back' ? <BackCircleArrow /> : <ForwardCircleArrow />;
    return (
      <div className={'pagebutton pagebutton-' + this.props.direction}>
        <button
          className="scale-button button button-action button-action__square"
          onClick={() => this.props.onClick(this.props.direction)}
          disabled={forwardEnabled}>
          {arrowComponent}
        </button>
      </div>
    );
  }
}

PageButton.defaultProps = {
  direction: 'back',
};

export default PageButton;
