import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { ActionSvg } from '../../assets/icons';

import {
  getSuggestions,
  getSuggestionValue,
  renderSuggestion,
} from '../../libs/dietOptions';

// add an item
// delete an item
// display selected items
class TrackerListItemInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: '',
    };
  }

  render() {
    return (
      <div>
        {/* <span className="selected-item">{this.props.selected}</span> */}
        <ul className="selected-item-list form-list__checkboxes checkbox-list checkbox-list__2columns">
          {this.props.addedItems && this.props.addedItems.length
            ? this._buildAddedList()
            : null}
        </ul>
        <span className="select-items__wrapper">
          <div className="stretch" />
          <Autosuggest
            suggestions={this.props.favoriteFoods
              .filter(name =>
                name.toLowerCase().includes(this.state.item.toLowerCase()),
              )
              .map(name => ({
                name,
                value: name,
              }))}
            onSuggestionsFetchRequested={() => {}}
            onSuggestionsClearRequested={() => {}}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(e, val) => {
              e.preventDefault();
              this.addItem(val.suggestionValue);
            }}
            inputProps={{
              value: this.state.item,
              placeholder: `Food to add...`,
              onChange: (e, val) => {
                this.setState({ item: e.target.value });
              },
              onKeyPress: e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  this.addItem(this.state.item);
                }
              },
              className: 'registration-treatment-field form-input-field',
            }}
          />
          <button
            className="button button-action button-action__add"
            onClick={() => this.addItem(this.state.item)}>
            <ActionSvg />
          </button>
        </span>
      </div>
    );
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.addItem(e.target.value);
    }
  }
  _updateInput(e) {
    e.preventDefault();
    this.setState({
      item: e.target.value,
    });
  }

  addItem(val) {
    if (val) {
      this.props.addItem(val);
      this.setState({
        item: '',
      });
    }
  }

  deleteItem(item, e) {
    e.preventDefault();
    this.props.deleteHandler(item);
  }

  _buildAddedList() {
    return this.props.addedItems.map((item, i) => (
      <li className="selected-item" key={i}>
        <span className="paragraph paragraph__orange paragraph__semibold paragraph__capitalized">
          {item}
        </span>
        <button
          className="button button-action button-action__delete"
          onClick={this.deleteItem.bind(this, item)}>
          <ActionSvg />
        </button>
      </li>
    ));
  }
}

export default TrackerListItemInput;
