import { flowRight as compose } from 'lodash';
import React, { PureComponent } from 'react';
import { withApollo } from 'react-apollo';
import {
  DietIcon,
  ItchIcon,
  PainIcon,
  SkinIcon,
  SleepIcon,
  StressIcon,
  TreatmentIcon,
  TriggersIcon,
} from '../../assets/icons/trackers';
import config from '../../config';
import { ItchTrendsIcon, PainTrendsIcon, SkinTrendsIcon, SleepTrendsIcon, StressTrendsIcon } from '../../assets/icons';

const _delete_me_filters = [
  { toggled: true, label: 'Skin', icon: 'skin', type: 'indicator' },
  { toggled: true, label: 'Itch', icon: 'itch', type: 'indicator' },
  { toggled: true, label: 'Pain', icon: 'pain', type: 'indicator' },
  { toggled: true, label: 'Stress', icon: 'stress', type: 'indicator' },
  { toggled: true, label: 'Sleep', icon: 'sleep', type: 'indicator' },
];

/**
 * A simple list item that displays a toggleable filter
 * @param index Number
 * @param filter Object
 * @param onClick function(event)
 * -------------------------------------------------------------------------- */
const FilterItem = ({ index, filter, onClick }) => {
  const activeClass = !filter.toggled ? '__disabled' : '';
  const iconDictionary = {
    skin: <SkinTrendsIcon />,
    itch: <ItchTrendsIcon />,
    pain: <PainTrendsIcon active={!filter.toggled} />,
    treatment: <TreatmentIcon />,
    trigger: <TriggersIcon />,
    stress: <StressTrendsIcon />,
    sleep: <SleepTrendsIcon />,
    diet: <DietIcon />,
  };
  return (
    <li
      className={`filter-item ${
        config.trackerTheme[filter.icon]
      } ${activeClass}`}
      key={index}
      onClick={onClick}>
      <span className="tracker-icon-wrapper svg-wrapper__shadow">
        {iconDictionary[filter.icon]}
      </span>
      <span className="headers">{filter.label}</span>
    </li>
  );
};

/**
 * TrendsFilters
 * Renders a list of FilterItem's from the application state
 * Clicking a FilterItem will toggle its `toggled` state in the app
 * -------------------------------------------------------------------------- */
class TrendsFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: _delete_me_filters,
    };
  }

  updateFilters(filter) {
    let newFilters = this.state.filters.filter(item => {
      if (item === filter) item.toggled = !item.toggled;
      return true;
    });
    this.setState({
      filters: newFilters,
    });

    let selectedIndicators = newFilters
      .filter(item => {
        return item.toggled && item.type === 'indicator';
      })
      .map(item => {
        return item.icon;
      });
    let selectedTrackers = newFilters
      .filter(item => {
        return item.toggled && item.type === 'tracker';
      })
      .map(item => {
        return item.icon;
      });
    this.props.client.writeData({
      data: {
        trendsView: {
          selectedIndicators: selectedIndicators,
          selectedTrackers: selectedTrackers,
          __typename: 'trendsView',
        },
      },
    });
  }

  renderIndicators() {
    return this.state.filters.map((filter, index) => {
      if (filter.type === 'indicator') {
        return (
          <FilterItem
            key={`indicator-${index}`}
            index={index}
            filter={filter}
            onClick={() => this.updateFilters(filter)}
          />
        );
      }
    });
  }

  renderFilters() {
    return this.state.filters.map((filter, index) => {
      if (filter.type === 'tracker') {
        return (
          <FilterItem
            key={`tracker-${index}`}
            index={index}
            filter={filter}
            onClick={() => this.updateFilters(filter)}
          />
        );
      }
    });
  }

  render() {
    return (
      <div className="trends-filters">
        <span className="filter-instructions help-text filter-instructions-text">
          Tap the tracker below to reveal and hide
        </span>
        <ol className="filter-toggle-list indicators">
          {this.renderIndicators()}
        </ol>
        <ol className="filter-toggle-list filters">{this.renderFilters()}</ol>
      </div>
    );
  }
}

export default compose(withApollo)(TrendsFilters);
