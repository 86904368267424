import React from 'react';

export default function AfternoonIcon({ isSelect, errorSelect }) {
  let fillCircle = isSelect ? '#1D62ED' : '#E7EAF1';
  let fillPath = isSelect ? '#E7EAF1' : errorSelect ? '#E95D42' : '#1D62ED';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fillCircle} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0717 15.863C20.0717 18.1877 18.1877 20.0717 15.863 20.0717C13.5383 20.0717 11.6543 18.1877 11.6543 15.863C11.6543 13.5383 13.5383 11.6543 15.863 11.6543C18.1877 11.6543 20.0717 13.5383 20.0717 15.863Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.863 10.5065C16.1224 10.5065 16.3757 10.531 16.6259 10.567C16.6244 10.5463 16.6313 10.5272 16.6282 10.5065L16.1499 7.44565C16.1308 7.32398 16.0359 7.22297 15.9081 7.20384C15.7497 7.17859 15.6005 7.28725 15.576 7.44565L15.0978 10.5065C15.0947 10.5256 15.0993 10.5471 15.0978 10.5677C15.3487 10.5318 15.6028 10.5065 15.863 10.5065Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8632 21.2194C15.6038 21.2194 15.3505 21.1949 15.1003 21.159C15.1018 21.1796 15.0949 21.1988 15.098 21.2194L15.5762 24.2803C15.5954 24.4012 15.6902 24.5022 15.818 24.5221C15.9764 24.5474 16.1256 24.4387 16.1501 24.2803L16.6284 21.2194C16.6315 21.2003 16.6269 21.1789 16.6284 21.1582C16.3774 21.1942 16.1234 21.2194 15.8632 21.2194Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1848 11.2245C13.4097 11.0944 13.6416 10.9896 13.875 10.8955C13.8635 10.8787 13.8604 10.858 13.8474 10.8419L11.903 8.42996C11.8257 8.33507 11.6926 8.29452 11.5724 8.34119C11.4225 8.39935 11.3482 8.5677 11.4056 8.71692L12.5221 11.6071C12.529 11.6255 12.5443 11.6416 12.5527 11.6599C12.7516 11.5031 12.959 11.3546 13.1848 11.2245Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5413 20.5018C18.3163 20.6319 18.0845 20.7367 17.8511 20.8309C17.8626 20.8477 17.8656 20.8684 17.8786 20.8844L19.823 23.2964C19.9003 23.3913 20.0335 23.4318 20.1536 23.3852C20.3036 23.327 20.3778 23.1586 20.3204 23.0094L19.204 20.1192C19.1971 20.1008 19.1818 20.0848 19.1734 20.0664C18.9744 20.2233 18.767 20.3717 18.5413 20.5018Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2242 13.1849C11.3535 12.9599 11.5019 12.7533 11.6573 12.5543C11.6389 12.5459 11.6259 12.5298 11.6068 12.5222L8.71654 11.4057C8.60253 11.3621 8.46708 11.3943 8.38597 11.4945C8.28573 11.6192 8.30486 11.8021 8.42959 11.9031L10.8416 13.8475C10.8569 13.8598 10.8775 13.8667 10.8944 13.8782C10.9885 13.6432 11.0941 13.4106 11.2242 13.1849Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.502 18.5414C20.3726 18.7664 20.2242 18.973 20.0688 19.172C20.0872 19.1804 20.1002 19.1965 20.1194 19.2041L23.0096 20.3206C23.1236 20.3642 23.259 20.332 23.3402 20.2318C23.4404 20.1071 23.4213 19.9242 23.2965 19.8232L20.8846 17.8788C20.8693 17.8665 20.8486 17.8596 20.8318 17.8481C20.7376 18.0831 20.632 18.3157 20.502 18.5414Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5065 15.8632C10.5065 15.6038 10.531 15.3505 10.567 15.1003C10.5463 15.1018 10.5272 15.0949 10.5065 15.098L7.44565 15.5762C7.32474 15.5954 7.22297 15.6902 7.20384 15.818C7.17859 15.9764 7.28725 16.1256 7.44565 16.1501L10.5065 16.6284C10.5256 16.6315 10.5471 16.6269 10.5677 16.6284C10.5318 16.3774 10.5065 16.1234 10.5065 15.8632Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2194 15.863C21.2194 16.1224 21.1949 16.3757 21.159 16.6259C21.1796 16.6244 21.1988 16.6313 21.2194 16.6282L24.2803 16.1499C24.402 16.1308 24.503 16.0359 24.5221 15.9081C24.5474 15.7497 24.4387 15.6005 24.2803 15.576L21.2194 15.0978C21.2003 15.0947 21.1789 15.0993 21.1582 15.0978C21.1942 15.3487 21.2194 15.6028 21.2194 15.863Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2245 18.5413C11.0944 18.3163 10.9896 18.0845 10.8955 17.8511C10.8787 17.8626 10.858 17.8656 10.8419 17.8786L8.42996 19.823C8.33507 19.9003 8.29452 20.0335 8.34119 20.1536C8.39935 20.3036 8.5677 20.3778 8.71692 20.3204L11.6071 19.204C11.6255 19.1963 11.6416 19.1818 11.6599 19.1734C11.5031 18.9744 11.3546 18.767 11.2245 18.5413Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5018 13.1848C20.6319 13.4097 20.7367 13.6416 20.8309 13.875C20.8477 13.8635 20.8684 13.8604 20.8844 13.8474L23.2964 11.903C23.3913 11.8257 23.4318 11.6926 23.3852 11.5724C23.327 11.4225 23.1586 11.3482 23.0094 11.4056L20.1192 12.5221C20.1008 12.529 20.0848 12.5443 20.0664 12.5527C20.2233 12.7516 20.3717 12.959 20.5018 13.1848Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1849 20.502C12.9599 20.3726 12.7533 20.2242 12.5543 20.0688C12.5459 20.0872 12.5298 20.1002 12.5222 20.1194L11.4057 23.0096C11.3621 23.1236 11.3943 23.259 11.4945 23.3402C11.6192 23.4404 11.8021 23.4213 11.9031 23.2965L13.8475 20.8846C13.8598 20.8693 13.8667 20.8486 13.8782 20.8318C13.6432 20.7376 13.4106 20.632 13.1849 20.502Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5414 11.2242C18.7664 11.3535 18.973 11.5019 19.172 11.6573C19.1804 11.6389 19.1965 11.6259 19.2041 11.6068L20.3206 8.71654C20.3642 8.60253 20.332 8.46708 20.2318 8.38597C20.1071 8.28573 19.9242 8.30486 19.8232 8.42959L17.8788 10.8416C17.8665 10.8569 17.8596 10.8775 17.8481 10.8944C18.0831 10.9885 18.3157 11.0941 18.5414 11.2242Z"
        fill={fillPath}
      />
    </svg>
  );
}
